export type {
  Size,
  Position,
  Object as VirtualOfficeObject,
  MeetingRoomObject as MeetingRoomVirtualOfficeObject,
  WhiteBoardObject as WhiteBoardVirtualOfficeObject,
  YoutubeVideoObject as YoutubeVideoVirtualOfficeObject,
  ScreenShareObject as ScreenShareVirtualOfficeObject,
  TextEditorObject as TextEditorVirtualOfficeObject,
  ObjectType as VirtualOfficeObjectType,
  ObjectPermissions as VirtualOfficeObjectPermissions,
  PermissionType as VirtualOfficePermissionType,
  DeltaUpdate as VirtualObjectDeltaUpdate,
  DeltaStack as VirtualObjectDeltaStack,
  UrlObject as UrlVirtualOfficeObject,
};

export { ObjectPermission as VirtualOfficeObjectPermission };

export type ObjectType =
  | "meeting-room"
  | "white-board"
  | "youtube-video"
  | "screen-share"
  | "text-editor"
  | "url-object"
  | "iframe-object";

export enum ObjectPermission {
  None = 0x0000,
  ObjectOwner = 0x0001,
  WorkspaceManager = 0x0002,
  WorkspaceMember = 0x0004,
  All = ObjectOwner | WorkspaceManager | WorkspaceMember,
}

interface Position {
  x: number;
  y: number;
}

interface Size {
  width?: number;
  height?: number;
}

export interface CursorObject {
  userId: number;
  userName: string;
  objectId: string;
  position: Position;
}

type Object =
  | MeetingRoomObject
  | WhiteBoardObject
  | YoutubeVideoObject
  | ScreenShareObject
  | TextEditorObject
  | UrlObject;

interface MeetingRoomContent {
  voiceChannelId: number;
}

interface WhiteBoardContent {
  /**
   * Example data:
   * - https://github.com/nakusyat/react-sketch/blob/master/examples/data.json.js
   * - https://github.com/nakusyat/react-sketch/blob/master/examples/data.json.controlled.js
   *
   * In case the links above breaks, replace `master` for `0d4b07131ca02be9acc8d7b98f1b9c9628620894`
   */
  sketch: {
    objects: any[];
    background?: string;
    backgroundImage?: any;
  };
  originCanvasSize?: Size;
  backgroundColor?: string;
}

interface YoutubeVideoContent {
  url: string | null;
  offset: number;
  startedAt: number | null;
}

export interface UrlContent {
  iframe?: string;
  url?: string;
  name?: string;
  image?: string;
  file?: string;
  originalFile?: string;
  objectSize?: Size;
  transparent: boolean;
}

interface ScreenShareContent extends WhiteBoardContent {
  userId: number;
  screenIndex: number;
  isDrawingAllowed: boolean;
}

interface TextEditorContent {
  groupPadId: string;
}

interface MeetingRoomObject extends ObjectBase<MeetingRoomContent> {
  type: "meeting-room";
}

interface WhiteBoardObject extends ObjectBase<WhiteBoardContent> {
  type: "white-board";
}

interface TextEditorObject extends ObjectBase<TextEditorContent> {
  type: "text-editor";
}

interface YoutubeVideoObject extends ObjectBase<YoutubeVideoContent> {
  type: "youtube-video";
}

interface UrlObject extends ObjectBase<UrlContent> {
  type: "url-object";
}

interface ScreenShareObject extends ObjectBase<ScreenShareContent> {
  type: "screen-share";
}

interface ObjectBase<T> {
  type: ObjectType;
  voiceChannelId: number;
  id: string;
  ownerUserId: number;
  properties: ObjectProperties<T>;
  permissions: ObjectPermissions;
}

interface ObjectProperties<T> {
  content: T;
  size?: Size;
  position?: Position;
  hideFrame?: boolean;
}

type ObjectPermissions = {
  list: ObjectPermission;
  read: ObjectPermission;
  write: ObjectPermission;
  move: ObjectPermission;
  resize: ObjectPermission;
  remove: ObjectPermission;
  changePermission: ObjectPermission;
};

type PermissionType = keyof ObjectPermissions;

type DeltaUpdate<T extends { id: string }> = { add: T } | { update: T } | { remove: T };

type DeltaStack<T extends { id: string }> = { add: T[]; update: { before: T; after: T }[]; remove: T[] };
