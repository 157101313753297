import { createSelector } from "reselect";
import { State } from "../../state";
import { ActivityDetectionState } from "./reducer";

export function getActivityDetection(state: State) {
  return state.backgroundChecks.activityDetection;
}

export const whyAmIAway = createSelector(getActivityDetection, (activityDetection: ActivityDetectionState) => {
  return activityDetection.reasonForBeingSetAway;
});

export const wasIWorkingBeforeAway = createSelector(
  getActivityDetection,
  (activityDetection: ActivityDetectionState) => {
    return activityDetection.isWorkingBeforeAway;
  },
);
