import { ActiveAppActionTypes as ActionTypes, ActiveAppActions as Actions } from "./actions";

export type { State as ActiveAppState };
export { initialState as activeAppInitialState, reducer as activeAppReducer };

const initialState = {
  activeWindowFullName: null as string | null,
};

type State = typeof initialState;

function reducer(state: State = initialState, action: ActionTypes): State {
  switch (action.type) {
    case Actions.UPDATE_WINDOW_FULL_NAME:
      return {
        ...state,
        activeWindowFullName: action.payload.activeWindowFullName,
      };
    default:
      return state;
  }
}
