import { MeetingRecordingActions, MeetingRecordingActionTypes } from ".";
import { MeetingRecording } from "../types";

export const meetinRecordingInitialState = {
  meetingRecordings: [] as MeetingRecording[],
  isMeetingRecordingOn: false,
  loading: false,
};
export type MeetingRecordingState = typeof meetinRecordingInitialState;

export const meetinRecordingReducer = (
  state: MeetingRecordingState = meetinRecordingInitialState,
  action: MeetingRecordingActionTypes,
): MeetingRecordingState => {
  switch (action.type) {
    case MeetingRecordingActions.GOT_MEETING_RECORDINGS:
      return {
        ...state,
        meetingRecordings: action.payload.meetingRecordings as MeetingRecording[],
      };
    case MeetingRecordingActions.SET_MEETING_RECORDING_ON_OFF_STATUS:
      return {
        ...state,
        isMeetingRecordingOn: action.payload.status,
      };
    case MeetingRecordingActions.SET_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload.status,
      };
    default: {
      return state;
    }
  }
};
