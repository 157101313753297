import * as Sentry from "@sentry/browser";

export default function logError(description: string, error?: any) {
  if (error) {
    if (typeof error === "object") {
      error.info = description;
      Sentry.captureException(error);
      console.error(description, error);
    } else {
      Sentry.captureMessage(description, scope => scope.setExtra("error", error));
      console.error(description, error);
    }
  } else {
    Sentry.captureMessage(description);
    console.error(description);
  }
}
