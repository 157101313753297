import React from "react";
import { FormattedMessage } from "react-intl";
import { generateListenerJoinWithParam, history, WORKSPACE_BASE_ROUTE } from "../../routes";
import {
  AppThunk,
  ChannelData,
  PIPWindowNotificationTypes,
  ScheduleMeetingSlot,
  ScheduleMeetingParticipant,
  UpdateVoiceChannelModalData,
} from "../types";
import { ChannelActions, CHANNEL_EVENT_TYPES } from "./actions-enum";
import {
  getWorkspaceDashboardUrl,
  getOnlineMembersById,
  copyToClipboard,
  getDeviceInfo,
  generateChannelURL,
  getAllMembersById,
} from "../../utils/helpers";
import {
  showRequiredMicrophonePermissionModal,
  loadPermission,
  getPermissionStateMicrophone,
  playSoundEffect,
  reloadApp,
  getWebCameraStatus,
} from "../app";
import { getNoiseReductionSetting } from "../devices";
import {
  getAllVoiceChannels,
  isUserInChannel,
  ongoingChannelCreationRequest,
  getPendingInvitations,
  getRestartVADStatus,
  getCurrentVoiceChannel,
  getInvitationChannel,
  getInvitationUser,
  getIsChannelJoining,
  getKnockersByVoiceChannelId,
  getMyKnock,
  getMeetingRoomById,
  getAllFloorMembersForInvite,
  getAllWorkspaceMembersForInvite,
  getValidOfflineMembersForInvite,
} from "./selectors";
import { getWorkspaceChannelParams } from "../../utils/parserUrl";
import { intl } from "../../i18n";
import {
  changeMicStatus,
  changeSilenceStatus,
  changeSelfVideoStatus,
  getMyMember,
  getOnlineWorkspaceUsers,
  getWorkspaceUsers,
  removeUsersFromChannel,
  resetEmojiAction,
  updateUserChannel,
  getWorkspaceMemberById,
  sendSignOut,
  removeListenersFromVoiceChannel,
} from "../users";
import moment from "moment";
import { getCurrentWorkspace, getCurrentWorkspaceId, getLivekitUrl, switchedWorkspaceAction } from "../workspace";
import { disconnectSocket, getSocket, getSocketConnectivityStatus, sendMessage } from "../socket";
import { getShowModal, showModal, switchSidebarCollapased } from "../../screens/Dashboard/state";
import {
  UPDATE_CHANNEL_MODAL_ID,
  DELETE_CHANNEL_MODAL_ID,
  ELECTRON_SCREEN_SELECTOR_MODAL_ID,
} from "../../screens/Dashboard/constants";
import { hideChatBox, setDMUserIds, setTextChannel, showChatBox } from "../textChannel";
import { log, LogCategory } from "../../utils/log";
import { PlaySoundEffectTypes } from "../../utils/playSoundEffect";
import { getJson } from "../../utils/network";
import {
  NOTIFICATION_WINDOW_AVATAR_SIZE,
  PENDING_INVITATION_STATUS_CHECK,
  MEETING_REMINDER_CLOSE_TIMER,
  VAD_RESTART_CONST,
  GUEST_JOIN_PATH,
} from "../../constant";
import {
  getScreenshare,
  selectScreenWindowToShareFromElectron,
  doStopScreenShare,
  tryStartingScreenShare,
  viewerDisconnected,
} from "../screenshare";
import { getCurrentVoiceChannelUsers, getVoiceChannelById, KnockState, RequestTypes } from ".";
import { Analytics, AnalyticsCategory } from "../../utils/ganalytics";
import { NotificationCategory, notifyOnElectron } from "../../electron/notification";
import { Toast } from "../../components/antd/Toast";
import { DelayNotification, notification } from "../../components/antd/Notification";
import {
  CallEndIconFilled,
  CallIconFilled,
  ChevronLeftIconFilled,
  CloudOffIconOutlined,
  ErrorOutlineIconOutlined,
  GroupsIconOutlined,
  MicIconFilled,
  PersonIconOutlined,
  PresentToAllIconOutlined,
  VolumeUpIconOutlined,
} from "../../components/icons/material";
import { Position } from "../virtualOffice/types";
import {
  addNotificationKey,
  addNotificationOnPIP,
  removeNotificationKey,
  removeNotificationOnPIP,
} from "../notificationsWindow/actions";
import {
  bringToFront,
  clearOrderedObjects,
  removeFromOrderedObject,
  startLoadingVirtualOffice,
} from "../virtualOffice/actions";
import {
  checkIsGuest,
  logout,
  getIsMeetingRecording,
  setUserVoiceChannelOnSentry,
  updateIsAppIdle,
  getAuthenticatedAccount,
  checkIsListener,
  setInvitedEnv,
  doLogout,
  reloadAccount,
  updateShowOriginalMessage,
} from "../account";
import { showNotificationWindow } from "../notificationsWindow";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { channels } from "../../electron/channels";
import { setMeetinRecordingStatusAction } from "../MeetingRecording";
import {
  checkActiveWindow,
  clearActiveWindowData,
  stopCheckingActiveWindow,
} from "../backgroundChecks/activeApp/actions";
import localData, { localDataTextChatHistory } from "../../localStorageKeys";
import { remoteUserDisconnected } from "../remoteDesktopControl";
import { TextChatReceiverOptionIds } from "../../screens/Dashboard/TextChat/ReceiverInput";
import {
  connectRoom,
  disconnectRoom,
  getLivekitConnectionStatus,
  getLivekitLoading,
  getParticipants,
  setMicLoading,
  setScreenshareLoading,
  setVideoLoading,
} from "../livekit";
import { enableMic } from "../livekit";
import { useIsSidebarCollapased } from "../../utils/hooks/useIsSidebarCollapsed";
import dayjs from "dayjs";
import { Button } from "../../components/antd/Button";
import { getCurrentEnvironment, getProtocol } from "../../utils/environment";
import { zohoManage } from "../../utils/zoho";
import * as Sentry from "@sentry/browser";

const { os } = getDeviceInfo();

const slackSyncErrorNotificationKey = "slack-sync-error-notification";
const callFeedbackNotificationKey = (voiceChannelId: number, userId: number) =>
  `feedback-invite-request-${voiceChannelId}-${userId}`;

const subscribingKnockNotification = (voiceChannelId: number) => ` subscribed-knock-received-${voiceChannelId}`;

interface CreateChannel extends CommonChannelData {
  voiceChannelName: string;
  parentVoiceChannelId: number | null;
  inviteTo?: number;
  isTemporary: boolean;
  speechToTextEnabled: boolean;
  textToSpeechEnabled: boolean;
  guestEnabled: boolean;
  guestPassword: string | null;
  listenerEnabled: boolean;
  listenerMicEnabled: boolean;
  listenerPassword: string | null;
  isAutoMeetingRecordingEnabled: boolean;
  isPositionLock: boolean;
  isDisableFloorAccess: boolean;
  isEnableRectangularView: boolean;
  isEnableMicForMtg: boolean;
  isEnableWebCamForMtg: boolean;
  isDeleteAllData?: boolean;
  updateMeeting?: boolean;
  assignedMemberIds: number[];
  showOriginalTextEnabledAsDefault: boolean;
}

interface ChannelCreated extends CreateChannel {
  workspaceId: number;
  textChannelId: number;
  voiceChannelId: number;
  socketId: string;
  listenerId: string;
  voiceChannelShortId: string;
  inviteFrom?: number;
  ownerUserId: number;
  speechToTextEnabled: boolean;
  textToSpeechEnabled: boolean;
  isDisableFloorAccess: boolean;
  isDefaultFloor: boolean;
  parentVoiceChannelId: number | null;
  showOriginalTextEnabledAsDefault: boolean;
}

interface UpdateChannel extends CommonChannelData {
  voiceChannelId: number;
  voiceChannelName: string | undefined;
  guestEnabled: boolean;
  guestPassword: string | null;
  listenerEnabled: boolean;
  listenerMicEnabled: boolean;
  listenerPassword: string | null;
  speechToTextEnabled: boolean;
  textToSpeechEnabled: boolean;
  isAutoMeetingRecordingEnabled: boolean;
  isPositionLock: boolean;
  isDisableFloorAccess: boolean;
  isEnableRectangularView?: boolean;
  isEnableMicForMtg?: boolean;
  isEnableWebCamForMtg?: boolean;
  isDeleteAllData?: boolean;
  updatedMeeting?: boolean;
  assignedMemberIds?: number[];
  showOriginalTextEnabledAsDefault: boolean;
}

interface ChannelUpdated extends UpdateChannel {
  workspaceId: number;
  socketId: string;
}

interface CommonChannelData {
  speechToTextLanguage: string;
  isPrivate: boolean;
  workspaceBackgroundId: number;
  isScheduledMeeting: boolean;
  meetingParticipants?: ScheduleMeetingParticipant[];
  meetingSlots?: ScheduleMeetingSlot[];
  reminderInterval?: number;
  timezoneOffset?: number;
}

interface DeleteChannel {
  voiceChannelId: number;
  isTemporary: boolean;
}

interface ChannelDeleted {
  workspaceId: number;
  voiceChannelId: number;
  isTemporary: boolean;
  initiator: number | undefined;
}

export interface JoinChannel {
  workspaceId: number;
  voiceChannelId: number;
}

export interface JoinedChannel {
  workspaceId: number;
  voiceChannelId: number;
  textChannelId?: number;
  userId: number;
  voiceChannelsJoinCount?: number;
  isMeetingRecording?: boolean;
  knockers?: { userId: number; userName: string }[];
  knock?: KnockState;
  message?: string;
  showOriginalTextEnabledAsDefault?: boolean;
}

export interface UpdateChannelLastMeetingAt {
  voiceChannelId: number;
  lastMeetingAt: string | null;
}

export interface LeaveChannel {
  duplicatedJoin: boolean;
}

export interface LeftChannel {
  workspaceId: number;
  voiceChannelId: number;
  userId: number;
}

export interface InviteRequestVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  invitedUsersIds: number[];
  isTemporary: boolean;
  isGetCloserInvite?: boolean;
}

export interface InviteCancelVoiceChannel {
  invitedUserId: number;
  workspaceId: number;
  voiceChannelId: number;
  invitedUsersIds?: number[];
  manuallyCancel?: boolean;
}

export interface InviteChannelResponse {
  workspaceId: number;
  voiceChannelId: number;
  invitedUserId: number;
  isTemporary: boolean;
}

export interface InviteReceiveVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  invitationId: string;
  invitedUserId: number;
  invitedByUserId: number;
  invitedByUserPosition?: Position;
  isTemporary: boolean;
  notifyUser: boolean;
  isGetCloserInvite?: boolean;
}

export interface ReminderMeetingVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  reminderInterval?: number;
  voiceChannelName: string;
}

export interface ReminderSendResponseVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  option: InviteResponseOption;
}

export interface JoinVoiceChannelFromSubscribingKnock {
  voiceChannelId: number;
}

export interface InviteSendResponseVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  invitationId: string;
  option: InviteResponseOption;
  invitedByUserId: number;
  invitedByUserPosition?: Position;
  isGetCloserInvite?: boolean;
}

export interface InviteGotResponseVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  invitationId: string;
  option: InviteResponseOption;
  invitedByUserId: number;
  invitedUserId: number;
  joinLaterTimeout: number;
  isGetCloserInvite?: boolean;
}

export interface UnmuteUnslienceRequestReceive {
  userId: number;
  workspaceId: number;
  voiceChannelId: number;
  mute?: boolean;
  silence?: boolean;
  invitationId: string;
  requestorSocketId: string;
}

export interface ScreenshareRequestReceive {
  userId: number;
  workspaceId: number;
  voiceChannelId: number;
  invitationId: string;
  requestorSocketId: string;
}

export interface ChannelNoExist {
  workspaceId: number;
  voiceChannelId: number;
}

export interface SpeakingWhileMuted {
  speakingWhileMuted: boolean;
}

export interface RestartVAD {
  restartVAD: number;
}

export interface GotResponse {
  requestType: RequestTypes;
  workspaceId: number;
  voiceChannelId: number;
  requestId: string;
  isAccepted: boolean;
  requestorUserId: number;
  requestedUserId: number;
  requestorSocketId: string;
}

export interface ResponseToRequest {
  requestType: RequestTypes;
  notificationsType: PIPWindowNotificationTypes;
  isAccepted: boolean;
  requestorUserId?: number;
  invitationId?: string;
  requestorSocketId?: string;
}

export interface GotLivekitAccessToken {
  accessToken: string;
  voiceChannelId: number;
}

export interface GetCloserInviteRequest extends JoinChannel {
  invitedUserId: number;
  invitedByUserId: number;
}

export interface GetCloserInvite extends GetCloserInviteRequest {
  position: Position;
}

export type KnockerListPIPData = {
  knockers: { userId: number; userName: string }[];
  voiceChannelId: number;
};

export type SubscribingKnockPipData = {
  knockers: { userId: number; userName: string }[];
  voiceChannelId: number;
  voiceChannelName: string;
};

export interface InviteAllUserRequestVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  isOnlyFloorUsers: boolean;
  isFromMeetingRoom: boolean;
  includeOfflineUsers: boolean;
}

export interface InviteGroupUserRequestVoiceChannel {
  workspaceId: number;
  voiceChannelId: number;
  isFromMeetingRoom: boolean;
  invitedUsersIds: number[];
}

export type InviteResponseOption = "join-now" | "join-later" | "decline" | "no-response" | "cancel";

export type ChannelActionTypes =
  | ReturnType<typeof channelCreateAction>
  | ReturnType<typeof channelCreatedAction>
  | ReturnType<typeof channelUpdateAction>
  | ReturnType<typeof channelUpdatedAction>
  | ReturnType<typeof channelBackgroundUpdatedAction>
  | ReturnType<typeof channelDeleteAction>
  | ReturnType<typeof channelDeletedAction>
  | ReturnType<typeof setChannels>
  | ReturnType<typeof channelJoinAction>
  | ReturnType<typeof channelLastMeetingUpdateAction>
  | ReturnType<typeof channelLeaveAction>
  | ReturnType<typeof channelLeftAction>
  | ReturnType<typeof voiceChannelInviteRequestAction>
  | ReturnType<typeof voiceChannelInviteReceiveAction>
  | ReturnType<typeof voiceChannelInviteResponseAcceptAction>
  | ReturnType<typeof voiceChannelInviteResponseDeclineAction>
  | ReturnType<typeof voiceChannelInviteResponseLaterAction>
  | ReturnType<typeof voiceChannelUpdatePendingInvitationsAction>
  | ReturnType<typeof voiceChannelInvitationResponse>
  | ReturnType<typeof resetVoiceChannelLoadingState>
  | ReturnType<typeof speakingWhileMuted>
  | ReturnType<typeof voiceChannelInviteResponseCancelAction>
  | ReturnType<typeof voiceChannelInviteRemoveAction>
  | ReturnType<typeof restartVAD>
  | ReturnType<typeof getCloserRequestAction>
  | ReturnType<typeof clearGetCloserInviteRequest>
  | ReturnType<typeof autoRecordingMeetingFeatureAvailedStatusUpdate>
  | ReturnType<typeof updateVoiceChannelSttLanguageAction>
  | ReturnType<typeof updateCollapsedVoiceChannelAction>
  | ReturnType<typeof updateIsFromCustomBackgroundAction>
  | ReturnType<typeof switchIsGetCloserWasAcceptd>
  | ReturnType<typeof setKnockerAction>
  | ReturnType<typeof setKnockersAction>
  | ReturnType<typeof removeKnockerAction>
  | ReturnType<typeof setMyKnockAction>
  | ReturnType<typeof removeMyKnockAction>
  | ReturnType<typeof updateKnockSubscriberAction>;

function voiceChannelInviteRequestAction(payload: InviteRequestVoiceChannel) {
  return {
    type: ChannelActions.VOICE_CHANNEL_INVITE_REQUEST,
    payload,
  } as const;
}

export function voiceChannelInviteRequest(payload: InviteRequestVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    dispatch(switchIsGetCloserWasAcceptd({ isGetCloserWasAccepted: false }));
    dispatch(getCloserRequestAction(null));

    const pendingInviteRequests = getPendingInvitations(getState());
    const me = getMyMember(getState());
    const existingPendingInvitedChannel = pendingInviteRequests.find(
      el => el.voiceChannelId === payload.voiceChannelId,
    );
    const newInvitedUsers = existingPendingInvitedChannel
      ? payload.invitedUsersIds.filter(el => existingPendingInvitedChannel.invitedUsersIds.indexOf(el) === -1)
      : payload.invitedUsersIds;

    // Check this invitation request was duplicated or not
    if (existingPendingInvitedChannel && newInvitedUsers.length === 0) {
      return;
    }

    const updatedPayload: InviteRequestVoiceChannel = { ...payload, invitedUsersIds: newInvitedUsers };

    dispatch(
      addNotificationOnPIP({
        notificationsType:
          payload.isGetCloserInvite && me && payload.invitedUsersIds.indexOf(me?.id) !== -1
            ? "get-closer-request"
            : "call-invite-request",
        data: updatedPayload,
      }),
    );

    Analytics(AnalyticsCategory.Channel, "User invited other users to voice channel");
    dispatch(voiceChannelInviteRequestAction(updatedPayload));
    dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_INVITE, updatedPayload));

    const allMembers = getWorkspaceUsers(getState());
    const members = getAllMembersById(allMembers, am => am);

    for (const userId of updatedPayload.invitedUsersIds) {
      const user = members[userId];
      const name = user.name;
      const key = callFeedbackNotificationKey(updatedPayload.voiceChannelId, user.id);

      const decline = () => {
        dispatch(
          voiceChannelCancelRequest({
            invitedUserId: user.id,
            workspaceId: payload.workspaceId,
            voiceChannelId: payload.voiceChannelId,
          }),
        );

        dispatch(
          removeNotificationOnPIP({
            voiceChannelId: payload.voiceChannelId,
            invitedUserId: user.id,
            type: payload.isGetCloserInvite ? "get-closer-request" : "call-invite-request",
          }),
        );
        dispatch(removeNotificationKey(key));
      };

      dispatch(addNotificationKey(key));
      notification.open({
        type: "meeting",
        key,
        icon: <CallIconFilled />,
        message: <FormattedMessage id="voiceChannel/invite-msg" defaultMessage="Inviting {name}" values={{ name }} />,
        description: (
          <FormattedMessage id="voiceChannel/invite-desc" defaultMessage="You are inviting {name}" values={{ name }} />
        ),
        secondaryButton: {
          type: "primary",
          color: "negative",
          icon: <CallEndIconFilled />,
          label: <FormattedMessage id="cancel" defaultMessage="Cancel" />,
          onClick: () => decline(),
        },
        onClose: () => decline(),
        closable: false,
      });
    }
  };
}

export function voiceChannelCancelRequest(payload: InviteCancelVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    const pendingInviteRequests = getPendingInvitations(getState());
    const existingPendingInvitedChannel = pendingInviteRequests.find(
      el => el.voiceChannelId === payload.voiceChannelId,
    );

    if (!existingPendingInvitedChannel) {
      return;
    }

    if (existingPendingInvitedChannel.invitedUsersIds.indexOf(payload.invitedUserId) === -1) {
      return;
    }

    const updatedPayload: InviteCancelVoiceChannel = { ...payload, invitedUsersIds: [payload.invitedUserId] };

    dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_CANCEL, updatedPayload));
  };
}

function voiceChannelInviteReceiveAction(payload: InviteReceiveVoiceChannel) {
  return {
    type: ChannelActions.VOICE_CHANNEL_INVITE_RECEIVE,
    payload,
  } as const;
}

export function voiceChannelReminderSendResponse(payload: ReminderSendResponseVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(playSoundEffect(null));

    if (payload.option === "join-now" && me?.voiceChannelId !== payload.voiceChannelId) {
      dispatch(playSoundEffect(PlaySoundEffectTypes.ACCEPT_INCOMING_CALL));
      dispatch(
        channelJoin({
          workspaceId: payload.workspaceId,
          voiceChannelId: payload.voiceChannelId,
        }),
      );
    } else if (payload.option === "decline") {
      dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
    }
  };
}

export function voiceChannelMeetingReminderReceive(payload: ReminderMeetingVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    if (
      payload.workspaceId !== currentWorkspace?.id ||
      (currentVoiceChannel && currentVoiceChannel.id === payload.voiceChannelId)
    ) {
      return;
    }

    dispatch(playSoundEffect(null));
    dispatch(playSoundEffect(PlaySoundEffectTypes.INCOMING_CALL));

    dispatch(
      addNotificationOnPIP({
        notificationsType: "meeting-reminder-request",
        data: [payload],
        timestamp: MEETING_REMINDER_CLOSE_TIMER,
        isAutoRemovable: true,
      }),
    );
    const key = `voice-channel-reminder-receive-${payload.voiceChannelId}`;

    dispatch(showNotificationWindow());

    const response = (option: InviteResponseOption) => {
      dispatch(
        voiceChannelReminderSendResponse({
          workspaceId: payload.workspaceId,
          voiceChannelId: payload.voiceChannelId,
          option,
        }),
      );

      dispatch(
        removeNotificationOnPIP({
          voiceChannelId: payload.voiceChannelId,
          type: "meeting-reminder-request",
        }),
      );
      dispatch(removeNotificationKey(key));
    };

    dispatch(addNotificationKey(key));
    notification.open({
      key,
      icon: <GroupsIconOutlined />,
      type: "meeting",
      duration: MEETING_REMINDER_CLOSE_TIMER,
      description: (
        <FormattedMessage
          id="meeting-reminder/subtitle"
          defaultMessage="{channelName} is about to start in {reminderInterval} minutes."
          values={{
            channelName: <strong>{payload.voiceChannelName}</strong>,
            reminderInterval: payload.reminderInterval,
          }}
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="invited-voice-channel/join-now" defaultMessage="Join Now" />,
        onClick: () => response("join-now"),
      },
      secondaryButton: {
        type: "primary",
        color: "negative",
        label: <FormattedMessage id="decline" defaultMessage="Decline" />,
        onClick: () => response("decline"),
      },
      onClose: () => response("decline"),
    });

    notifyOnElectron(NotificationCategory.MeetingReminder, payload.voiceChannelName, payload.reminderInterval);
  };
}

export function voiceChannelInviteReceive(payload: InviteReceiveVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    dispatch(voiceChannelInviteReceiveAction(payload));
    dispatch(playSoundEffect(null));

    if (payload.notifyUser) {
      dispatch(playSoundEffect(PlaySoundEffectTypes.INCOMING_CALL));
    }

    dispatch(
      addNotificationOnPIP({
        notificationsType: payload.isGetCloserInvite ? "get-closer-request" : "meeting-request",
        data: payload,
        isAutoRemovable: true,
        timestamp: MEETING_REMINDER_CLOSE_TIMER,
      }),
    );

    const channel = getInvitationChannel(getState());
    const invitedByUser = getInvitationUser(getState());

    const key = "voice-channel-invite-receive";

    dispatch(showNotificationWindow());

    const response = (option: InviteResponseOption) => {
      const voiceChannel = getCurrentVoiceChannelUsers(getState());

      const invitedUser = voiceChannel.find(channel => channel.id === payload.invitedByUserId);

      dispatch(
        voiceChannelInviteSendResponse({
          workspaceId: payload.workspaceId,
          voiceChannelId: payload.voiceChannelId,
          invitationId: payload.invitationId,
          option,
          invitedByUserId: payload.invitedByUserId,
          invitedByUserPosition: invitedUser?.position || payload.invitedByUserPosition,
          isGetCloserInvite: payload?.isGetCloserInvite,
        }),
      );

      dispatch(
        removeNotificationOnPIP({
          voiceChannelId: payload.voiceChannelId,
          invitedUserId: payload.invitedUserId,
          type: payload.isGetCloserInvite ? "get-closer-request" : "meeting-request",
        }),
      );
      dispatch(removeNotificationKey(key));
    };

    dispatch(addNotificationKey(key));
    notification.open({
      key,
      icon: <ErrorOutlineIconOutlined />,
      type: "meeting",
      duration: payload.isGetCloserInvite ? undefined : MEETING_REMINDER_CLOSE_TIMER,
      message: payload.isGetCloserInvite ? (
        <FormattedMessage id="get-closer-invite/title" defaultMessage="Get Closer Request" />
      ) : (
        <FormattedMessage id="invited-meeting-room/title" defaultMessage="Meeting Request" />
      ),
      description: payload?.isGetCloserInvite ? (
        <FormattedMessage
          id="get-closer-invite/subtitle"
          defaultMessage="{user} request you to get close."
          values={{
            user: <strong>{invitedByUser?.name}</strong>,
          }}
        />
      ) : (
        <FormattedMessage
          id="invited-voice-channel/subtitle"
          defaultMessage="{user} invited you to join {channel}."
          values={{
            user: <strong>{invitedByUser?.name}</strong>,
            channel: <strong>{channel?.name}</strong>,
          }}
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="invited-voice-channel/join-now" defaultMessage="Join Now" />,
        onClick: () => response("join-now"),
      },
      secondaryButton: {
        type: "primary",
        color: "negative",
        label: <FormattedMessage id="decline" defaultMessage="Decline" />,
        onClick: () => response("decline"),
      },
      onClose: () => response("no-response"),
    });

    if (channel && invitedByUser) {
      notifyOnElectron(NotificationCategory.IncomingCall, channel, invitedByUser, payload.isGetCloserInvite);
    }
  };
}

export function cancelInviteRequests(): AppThunk {
  return (dispatch, getState) => {
    const pendingInviteRequests = getPendingInvitations(getState());

    if (pendingInviteRequests.length > 0) {
      pendingInviteRequests.forEach(request => {
        if (request?.invitedUsersIds.length > 0) {
          request?.invitedUsersIds.forEach(userId => {
            dispatch(
              voiceChannelCancelRequest({
                invitedUserId: userId,
                workspaceId: request?.workspaceId,
                voiceChannelId: request?.voiceChannelId,
                manuallyCancel: true,
              }),
            );
          });
        }
      });
    }
  };
}

export function voiceChannelInviteAllOnFloor(payload: InviteAllUserRequestVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    dispatch(switchIsGetCloserWasAcceptd({ isGetCloserWasAccepted: false }));
    dispatch(getCloserRequestAction(null));

    let invitedUsersIds: number[] = [];

    if (payload.isOnlyFloorUsers) {
      const floorUsers = getAllFloorMembersForInvite(getState());

      if (floorUsers && floorUsers.length > 0) {
        invitedUsersIds = floorUsers.map(a => a.id);
      }
    } else {
      const workspaceUsers = getAllWorkspaceMembersForInvite(getState());

      if (workspaceUsers && workspaceUsers.length > 0) {
        invitedUsersIds = workspaceUsers.map(a => a.id);
      }
    }

    if (payload.includeOfflineUsers) {
      const validOfflineUsersForInvite = getValidOfflineMembersForInvite(getState());

      if (validOfflineUsersForInvite) {
        invitedUsersIds = invitedUsersIds.concat(validOfflineUsersForInvite?.map(a => a.id));
      }
    }

    const pendingInviteRequests = getPendingInvitations(getState());
    const existingPendingInvitedChannel = pendingInviteRequests.find(
      el => el.voiceChannelId === payload.voiceChannelId,
    );

    const newInvitedUsers = existingPendingInvitedChannel
      ? invitedUsersIds.filter(el => existingPendingInvitedChannel.invitedUsersIds.indexOf(el) === -1)
      : invitedUsersIds;

    // Check this invitation request was duplicated or not
    if (existingPendingInvitedChannel && newInvitedUsers.length === 0) {
      return;
    }

    const updatedPayload: InviteRequestVoiceChannel = {
      invitedUsersIds: newInvitedUsers,
      isTemporary: false,
      voiceChannelId: payload.voiceChannelId,
      workspaceId: payload.workspaceId,
      isGetCloserInvite: payload.isFromMeetingRoom ? false : true,
    };

    if (newInvitedUsers.length > 0) {
      dispatch(voiceChannelInviteRequestAction(updatedPayload));
      dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_INVITE, updatedPayload));
    }
  };
}

export function voiceChannelInviteGroupOfMembers(payload: InviteGroupUserRequestVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    dispatch(switchIsGetCloserWasAcceptd({ isGetCloserWasAccepted: false }));
    dispatch(getCloserRequestAction(null));

    const pendingInviteRequests = getPendingInvitations(getState());
    const existingPendingInvitedChannel = pendingInviteRequests.find(
      el => el.voiceChannelId === payload.voiceChannelId,
    );

    const newInvitedUsers = existingPendingInvitedChannel
      ? payload.invitedUsersIds.filter(el => existingPendingInvitedChannel.invitedUsersIds.indexOf(el) === -1)
      : payload.invitedUsersIds;

    // Check this invitation request was duplicated or not
    if (existingPendingInvitedChannel && newInvitedUsers.length === 0) {
      return;
    }

    const updatedPayload: InviteRequestVoiceChannel = {
      invitedUsersIds: newInvitedUsers,
      isTemporary: false,
      voiceChannelId: payload.voiceChannelId,
      workspaceId: payload.workspaceId,
      isGetCloserInvite: payload.isFromMeetingRoom ? false : true,
    };

    if (newInvitedUsers.length > 0) {
      dispatch(voiceChannelInviteRequestAction(updatedPayload));
      dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_INVITE, updatedPayload));
    }
  };
}

function voiceChannelInviteResponseAcceptAction() {
  return { type: ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_ACCEPT } as const;
}

function voiceChannelInviteResponseDeclineAction() {
  return { type: ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_DECLINE } as const;
}

function voiceChannelInviteResponseCancelAction() {
  return { type: ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_CANCEL } as const;
}

function voiceChannelInviteRemoveAction(payload: { invitedUserId: number; voiceChannelId: number }) {
  return { type: ChannelActions.VOICE_CHANNEL_INVITE_REMOVE, payload } as const;
}

function voiceChannelInviteResponseLaterAction() {
  return { type: ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_LATER } as const;
}

function voiceChannelUpdatePendingInvitationsAction(payload: InviteGotResponseVoiceChannel) {
  return { type: ChannelActions.VOICE_CHANNEL_UPDATE_PENDING_INVITATIONS, payload } as const;
}

function voiceChannelInvitationResponse(payload: InviteGotResponseVoiceChannel) {
  return { type: ChannelActions.VOICE_CHANNEL_INVITATION_RESPONSE, payload } as const;
}

export function voiceChannelInviteSendResponse(payload: InviteSendResponseVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(playSoundEffect(null));
    dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_INVITE_RESPONSE, payload));

    if (payload.option === "join-now" && payload.isGetCloserInvite && payload.invitedByUserPosition && me) {
      dispatch(
        getCloserRequest({
          voiceChannelId: payload.voiceChannelId,
          workspaceId: payload.workspaceId,
          invitedByUserId: payload.invitedByUserId,
          position: payload.invitedByUserPosition,
          invitedUserId: me?.id,
        }),
      );
    } else if (payload.option === "join-now" && me?.voiceChannelId !== payload.voiceChannelId) {
      dispatch(playSoundEffect(PlaySoundEffectTypes.ACCEPT_INCOMING_CALL));
      dispatch(
        channelJoin({
          workspaceId: payload.workspaceId,
          voiceChannelId: payload.voiceChannelId,
        }),
      );
    } else if (payload.option === "decline") {
      dispatch(showChatBox(payload.invitedByUserId));
      dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
    } else if (payload.option === "join-later") {
      dispatch(playSoundEffect(PlaySoundEffectTypes.JOIN_LATER));
    }
  };
}

export function voiceChannelInviteGotResponse(payload: InviteGotResponseVoiceChannel): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState())!;
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const webCameraStatus = getWebCameraStatus(getState());

    dispatch(removeNotificationKey("voice-channel-invite-receive"));
    dispatch(addNotificationOnPIP({ notificationsType: "invitaion-responses", data: payload }));

    if (me.id === payload.invitedUserId) {
      dispatch(playSoundEffect(null));

      switch (payload.option) {
        case "join-now":
          if (!!me.mute && currentVoiceChannel && !currentVoiceChannel.parentVoiceChannelId) {
            dispatch(changeMicStatus(false));
          }

          dispatch(voiceChannelInviteResponseAcceptAction());
          break;

        case "join-later":
          dispatch(voiceChannelInviteResponseLaterAction());
          break;

        case "decline":
          dispatch(voiceChannelInviteResponseDeclineAction());
          break;

        case "no-response":
          const key = `missed-invite-request-${payload.voiceChannelId}-${payload.invitedByUserId}`;
          const membersInfo = getOnlineMembersById(getOnlineWorkspaceUsers(getState()));
          const userInfo = membersInfo[payload.invitedByUserId];

          dispatch(addNotificationKey(key));

          const response = (option: "call-back" | "chat") => {
            dispatch(voiceChannelMissedCallSendResponse(option, payload));
          };

          notification.open({
            type: "warning",
            key,
            icon: <CallEndIconFilled />,
            message: (
              <FormattedMessage id="voiceChannel/invite-missed" defaultMessage="Missed invite get closer request" />
            ),
            description: (
              <FormattedMessage
                id="voiceChannel/invite-response-missed-call"
                defaultMessage="Missed invite get closer request from {name} at {time}"
                values={{ name: userInfo.name, time: moment().format("hh:mm a") }}
              />
            ),
            primaryButton: {
              type: "primary",
              color: "positive",
              label: <FormattedMessage id="missed-call/call-back" defaultMessage="Call Back" />,
              onClick: () => response("call-back"),
            },
            secondaryButton: {
              type: "primary",
              color: "positive",
              label: <FormattedMessage id="missed-call/chat" defaultMessage="Chat" />,
              onClick: () => response("chat"),
            },
            onClose: () => {
              dispatch(removeNotificationOnPIP({ type: "invitaion-responses", invitationId: payload.invitationId }));
            },
          });
          break;
        case "cancel":
          const members = getOnlineMembersById(getOnlineWorkspaceUsers(getState()));
          const voiceChannel = getVoiceChannelById(payload.voiceChannelId)(getState());
          const user = members[payload.invitedByUserId];
          const name = user.name;

          if (voiceChannel?.parentVoiceChannelId === null) {
            dispatch(addNotificationOnPIP({ notificationsType: "missed-call", isAutoRemovable: false, data: payload }));

            dispatch(addNotificationKey(`missed-invite-request-${payload.voiceChannelId}-${payload.invitedByUserId}`));

            const responseToMissedCall = (option: "call-back" | "chat") => {
              dispatch(voiceChannelMissedCallSendResponse(option, payload));
            };

            notification.open({
              type: "warning",
              key: `missed-invite-request-${payload.voiceChannelId}-${payload.invitedByUserId}`,
              icon: <CallEndIconFilled />,
              message: (
                <FormattedMessage id="voiceChannel/invite-missed" defaultMessage="Missed invite get closer request" />
              ),
              description: (
                <FormattedMessage
                  id="voiceChannel/invite-response-missed-call"
                  defaultMessage="Missed invite get closer request from {name} at {time}"
                  values={{ name, time: moment().format("hh:mm a") }}
                />
              ),
              primaryButton: {
                type: "primary",
                color: "positive",
                label: <FormattedMessage id="missed-call/call-back" defaultMessage="Call Back" />,
                onClick: () => responseToMissedCall("call-back"),
              },
              secondaryButton: {
                type: "primary",
                color: "positive",
                label: <FormattedMessage id="missed-call/chat" defaultMessage="Chat" />,
                onClick: () => responseToMissedCall("chat"),
              },
              onClose: () => {
                dispatch(removeNotificationOnPIP({ type: "missed-call", invitationId: payload.invitationId }));
              },
            });
          }

          dispatch(voiceChannelInviteResponseCancelAction());

          notification.open({
            type: "warning",
            icon: <CallEndIconFilled />,
            message: <FormattedMessage id="voiceChannel/invite-message" defaultMessage="Inviting cancelled" />,
            description: (
              <FormattedMessage
                id="voiceChannel/invite-response-cancel"
                defaultMessage="{name} canceled the invitation."
                values={{ name }}
              />
            ),
            onClose: () => {
              dispatch(removeNotificationOnPIP({ type: "invitaion-responses", invitationId: payload.invitationId }));
            },
            duration: DelayNotification,
          });

          dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
          break;
      }
    } else if (me.id === payload.invitedByUserId) {
      const user = getWorkspaceMemberById(payload.invitedUserId)(getState());
      const key = callFeedbackNotificationKey(payload.voiceChannelId, payload.invitedUserId);
      const name = <strong>{user?.name}</strong>;

      switch (payload.option) {
        case "join-now":
          const targetVoiceChannel = getVoiceChannelById(payload.voiceChannelId)(getState());

          if (!!me.mute && targetVoiceChannel && !targetVoiceChannel.parentVoiceChannelId) {
            dispatch(changeMicStatus(false));
          }

          if (me.voiceChannelId !== payload.voiceChannelId) {
            dispatch(channelJoin({ workspaceId: payload.workspaceId, voiceChannelId: payload.voiceChannelId }));
          }

          dispatch(removeNotificationKey(key));
          dispatch(voiceChannelUpdatePendingInvitationsAction(payload));
          dispatch(switchIsGetCloserWasAcceptd({ isGetCloserWasAccepted: true }));
          console.log(`WEB CAMERA STATUS LOG: Enabling web camera as feature is enabled for user who invited
          video on/off status: ${me.videoActive}
          `);
          webCameraStatus && !me.videoActive && dispatch(changeSelfVideoStatus(true));

          break;

        case "join-later":
          notification.open({
            type: "info",
            key,
            icon: <CallIconFilled />,
            message: <FormattedMessage id="voiceChannel/invite-joinLater" defaultMessage="Join in 5 minutes" />,
            description: (
              <FormattedMessage
                id="voiceChannel/invite-response-join-later"
                defaultMessage="{name} will join in 5 minutes..."
                values={{ name }}
              />
            ),
          });
          dispatch(voiceChannelInvitationResponse(payload));
          dispatch(playSoundEffect(PlaySoundEffectTypes.JOIN_LATER));
          break;

        case "decline":
          dispatch(voiceChannelUpdatePendingInvitationsAction(payload));
          dispatch(addNotificationKey(key));
          notification.open({
            type: "warning",
            key,
            icon: <CallEndIconFilled />,
            message: <FormattedMessage id="voiceChannel/invite-declined" defaultMessage="Invitation declined" />,
            description: (
              <FormattedMessage
                id="voiceChannel/invite-response-decline"
                defaultMessage="{name} declined call."
                values={{ name }}
              />
            ),
            onClose: () => {
              dispatch(removeNotificationKey(key));
              dispatch(removeNotificationOnPIP({ type: "invitaion-responses", invitationId: payload.invitationId }));
            },
            duration: DelayNotification,
          });
          dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
          break;
        case "no-response":
          dispatch(voiceChannelUpdatePendingInvitationsAction(payload));
          dispatch(addNotificationKey(key));
          notification.open({
            type: "warning",
            key,
            icon: <CallEndIconFilled />,
            message: <FormattedMessage id="voiceChannel/invite-unavailable" defaultMessage="Unavailable" />,
            description: (
              <FormattedMessage
                id="voiceChannel/invite-response-no-response"
                defaultMessage="{name} is not available. Try again later."
                values={{ name }}
              />
            ),
            onClose: () => {
              dispatch(removeNotificationKey(key));
              dispatch(removeNotificationOnPIP({ type: "invitaion-responses", invitationId: payload.invitationId }));
            },
            duration: DelayNotification,
          });
          dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
          break;

        case "cancel":
          dispatch(voiceChannelUpdatePendingInvitationsAction(payload));
          dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
          break;
      }
    }
  };
}

function channelCreateAction(payload: CreateChannel) {
  return {
    type: ChannelActions.CHANNEL_CREATE,
    payload,
  } as const;
}

export function voiceChannelMissedCallSendResponse(
  option: "chat" | "call-back",
  payload: InviteGotResponseVoiceChannel,
): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const currentWorkspace = getCurrentWorkspace(getState());

    const key = `missed-invite-request-${payload.voiceChannelId}-${payload.invitedByUserId}`;

    dispatch(removeNotificationKey(key));

    if (payload.option === "cancel") {
      dispatch(removeNotificationOnPIP({ type: "missed-call", invitationId: payload.invitationId }));
    } else {
      dispatch(removeNotificationOnPIP({ type: "invitaion-responses", invitationId: payload.invitationId }));
    }

    if (me && currentVoiceChannel && currentWorkspace) {
      if (option === "chat") {
        dispatch(setDMUserIds([payload.invitedByUserId]));
        dispatch(showChatBox());
      } else {
        dispatch(playSoundEffect(PlaySoundEffectTypes.INVITE_USER));
        checkForPendingRequest({ inviteduserId: payload.invitedByUserId, voiceChannelId: currentVoiceChannel.id }).then(
          response => {
            if (!response?.isPending) {
              dispatch(
                voiceChannelInviteRequest({
                  workspaceId: currentWorkspace.id,
                  voiceChannelId: currentVoiceChannel.id,
                  invitedUsersIds: [payload.invitedByUserId],
                  isTemporary: false,
                  isGetCloserInvite: true,
                }),
              );
            }
          },
        );
      }
    }
  };
}

export function channelCreate(payload: CreateChannel): AppThunk {
  return (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());
    const haveIRequestedChannel = ongoingChannelCreationRequest(getState());

    if (workspace && !haveIRequestedChannel) {
      dispatch(channelCreateAction(payload));
      dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_CREATE, { workspaceId: workspace.id, ...payload }));
    }
  };
}

export function channelCreatedAction(payload: ChannelCreated) {
  return {
    type: ChannelActions.CHANNEL_CREATE_SUCCESS,
    payload,
  } as const;
}

export function channelCreated(payload: ChannelCreated): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const currentFloorId = currentVoiceChannel?.parentVoiceChannelId ?? currentVoiceChannel?.id;
    const newFloorId = payload.parentVoiceChannelId ?? payload.voiceChannelId;

    if ((me?.role === "guest" && currentFloorId !== newFloorId) || me?.role === "listener") {
      return;
    }

    dispatch(channelCreatedAction(payload));

    const socket = getSocket(getState());

    if (socket.id === payload.socketId) {
      const inviteTo = payload.inviteTo;

      if (inviteTo) {
        // Temporary Channel/Meeting room was created and invite users to temporary channel
        dispatch(
          voiceChannelInviteRequest({
            workspaceId: payload.workspaceId,
            voiceChannelId: payload.voiceChannelId,
            invitedUsersIds: [inviteTo],
            isTemporary: true,
          }),
        );
      }

      if (payload.parentVoiceChannelId) {
        Toast.success(
          intl.formatMessage({
            id: "meeting-room/created-success",
            defaultMessage: "Meeting Room successfully created!",
          }),
        );
      } else {
        Toast.success(
          intl.formatMessage({
            id: "floor/created-success",
            defaultMessage: "Floor successfully created!",
          }),
        );
      }
    }
  };
}

function channelUpdateAction(payload: UpdateChannel) {
  return {
    type: ChannelActions.CHANNEL_UPDATE,
    payload,
  } as const;
}

function updateIsFromCustomBackgroundAction(status: boolean) {
  return {
    type: ChannelActions.UPDATE_IS_FROM_CUSTOM_BACKGROUND,
    payload: { status },
  } as const;
}

export function updateIsFromCustomBackground(status: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(updateIsFromCustomBackgroundAction(status));
  };
}

export function channelUpdate(payload: UpdateChannel): AppThunk {
  return (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());

    if (!workspace) {
      return;
    }

    dispatch(channelUpdateAction(payload));
    dispatch(sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_UPDATE, { workspaceId: workspace.id, ...payload }));
  };
}

function channelUpdatedAction(payload: ChannelUpdated) {
  return {
    type: ChannelActions.CHANNEL_UPDATE_SUCCESS,
    payload,
  } as const;
}

export function channelUpdated(payload: ChannelUpdated): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const socket = getSocket(getState());
    const voiceChannels = getAllVoiceChannels(getState());
    const isListener = checkIsListener(getState());
    const voiceChannel = voiceChannels.find(el => el.id === payload.voiceChannelId);

    if (
      !payload.listenerEnabled ||
      (payload.listenerPassword !== null && payload.listenerPassword !== voiceChannel?.listenerPassword)
    ) {
      if (isListener && me?.voiceChannelId === payload.voiceChannelId && me.joinShortId === voiceChannel?.listenerId) {
        window.location.href = "/dashboard";
      }

      dispatch(
        removeListenersFromVoiceChannel({
          voiceChannelId: payload.voiceChannelId,
          joinShortId: voiceChannel?.listenerId,
        }),
      );
    }

    if (isListener) {
      dispatch(
        setInvitedEnv({
          listenerMicEnabled: payload.listenerMicEnabled,
          textToSpeechEnabled: payload.textToSpeechEnabled,
        }),
      );
    }

    dispatch(channelUpdatedAction(payload));

    if (me?.role === "guest") {
      const voiceChannel = voiceChannels.find(el => el.id === me?.voiceChannelId);
      const updatedChannel = voiceChannels.find(el => el.id === payload.voiceChannelId);

      if (!updatedChannel?.parentVoiceChannelId && !payload.guestEnabled) {
        const guestDisabled = voiceChannel?.parentVoiceChannelId
          ? updatedChannel?.id === voiceChannel.parentVoiceChannelId
          : voiceChannel?.id === updatedChannel?.id;

        if (guestDisabled) {
          dispatch(reloadApp());
        }
      }
    }

    if (socket?.id === payload.socketId && !isListener) {
      const modals = getShowModal(getState());
      const modal = modals.find(m => m.id === UPDATE_CHANNEL_MODAL_ID);

      if (modal?.show) {
        Toast.success(
          voiceChannel?.parentVoiceChannelId
            ? intl.formatMessage({
                id: "metting-room/updated-success",
                defaultMessage: "Meeting Room successfully updated!",
              })
            : intl.formatMessage({
                id: "floor/updated-success",
                defaultMessage: "Floor successfully updated!",
              }),
        );

        dispatch(showModal({ id: UPDATE_CHANNEL_MODAL_ID, show: false }));
      }
    }
  };
}

function channelBackgroundUpdatedAction(payload: { voiceChannelId: number; workspaceBackgroundId: number }) {
  return {
    type: ChannelActions.CHANNEL_BACKGROUND_UPDATE_SUCCESS,
    payload,
  } as const;
}

export function channelBackgroundUpdated(voiceChannelId: number, workspaceBackgroundId: number): AppThunk {
  return (dispatch, getState) => {
    dispatch(channelBackgroundUpdatedAction({ voiceChannelId, workspaceBackgroundId }));
    Toast.success(
      intl.formatMessage({
        id: "floor/background-updated-success",
        defaultMessage: "Floor Background is successfully updated!",
      }),
    );
  };
}

function channelDeleteAction(payload: DeleteChannel) {
  return {
    type: ChannelActions.CHANNEL_DELETE,
    payload,
  } as const;
}

export function channelDelete(payload: DeleteChannel): AppThunk {
  return (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());

    if (!workspace) {
      return;
    }

    dispatch(channelDeleteAction(payload));
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_DELETE, {
        workspaceId: workspace.id,
        voiceChannelId: payload.voiceChannelId,
        isTemporary: payload.isTemporary,
      }),
    );

    Analytics(AnalyticsCategory.Channel, "User deleted a voice channel");
  };
}

function channelDeletedAction(payload: ChannelDeleted) {
  return {
    type: ChannelActions.CHANNEL_DELETE_SUCCESS,
    payload,
  } as const;
}

export function channelDeleted(payload: ChannelDeleted): AppThunk {
  return (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());
    const me = getMyMember(getState());
    const isListener = checkIsListener(getState());

    if (isListener) {
      if (me?.voiceChannelId === payload.voiceChannelId) {
        window.location.href = "/dashboard";
      }

      return;
    }

    const voiceChannels = getAllVoiceChannels(getState());
    const livekitConnection = getLivekitConnectionStatus(getState());

    dispatch(channelDeletedAction(payload));

    const deletedByMe = me && me.id === payload.initiator;
    const myCurrentChannel = me && me.voiceChannelId === payload.voiceChannelId;
    const voiceChannel = voiceChannels.find(el => el.id === payload.voiceChannelId);

    if (deletedByMe) {
      if (!voiceChannel?.parentVoiceChannelId) {
        Toast.success(
          intl.formatMessage({
            id: "floor/deleted-success",
            defaultMessage: "Floor successfully deleted!",
          }),
        );
      } else {
        Toast.success(
          intl.formatMessage({
            id: "meeting-room/deleted-success",
            defaultMessage: "Meeting Room successfully deleted!",
          }),
        );
      }

      dispatch(showModal({ id: DELETE_CHANNEL_MODAL_ID, show: false }));
    }

    if (myCurrentChannel) {
      dispatch(removeUsersFromChannel({ voiceChannelId: payload.voiceChannelId }));

      if (livekitConnection !== "disconnected") {
        dispatch(disconnectRoom());
        dispatch(doStopScreenShare());
      }

      history.push(getWorkspaceDashboardUrl(workspace!.shortId));
    }
  };
}

export function setChannels(payload: ChannelData[]) {
  return {
    type: ChannelActions.SET_CHANNELS,
    payload,
  } as const;
}

export function channelJoinAction(joining: boolean) {
  return {
    type: ChannelActions.CHANNEL_JOIN,
    payload: { joining },
  } as const;
}

export function removeFromChannel(userId: number, voiceChannelId: number, workspaceId: number): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_REMOVE_USER_FROM_CHANNEL, {
        userId: userId,
        voiceChannelId: voiceChannelId,
        workspaceId: workspaceId,
      }),
    );
  };
}

export function channelJoin(payload: JoinChannel, automaticBack?: boolean): AppThunk {
  return async (dispatch: Function, getState) => {
    const me = getMyMember(getState());
    const isListener = checkIsListener(getState());

    if (isListener) {
      const voiceChannel = getVoiceChannelById(payload.voiceChannelId)(getState());

      if (!voiceChannel) {
        return;
      }

      dispatch(
        sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_JOIN, {
          workspaceId: voiceChannel.workspaceId,
          voiceChannelId: voiceChannel.id,
        }),
      );

      return;
    }

    dispatch(switchIsGetCloserWasAcceptd({ isGetCloserWasAccepted: false }));
    const socketConnected = getSocketConnectivityStatus(getState());
    const livekitConnection = getLivekitConnectionStatus(getState());
    const joiningChannel = getVoiceChannelById(payload.voiceChannelId)(getState());
    const isChannelJoining = getIsChannelJoining(getState());
    const isLivekitLoading = getLivekitLoading(getState());
    const livekitUrl = getLivekitUrl(getState());
    const workspaceId = getCurrentWorkspaceId(getState());

    dispatch(switchSidebarCollapased(useIsSidebarCollapased()));

    // If user try click the channel that joined, no need to reconnect with the channel.
    if (
      !livekitUrl ||
      isLivekitLoading ||
      isChannelJoining ||
      !socketConnected ||
      !joiningChannel ||
      (livekitConnection === "connected" && payload.voiceChannelId === me?.voiceChannelId && !automaticBack) ||
      livekitConnection === "connecting"
    ) {
      return;
    }

    await dispatch(channelJoinAction(true));
    dispatch(setVideoLoading(false));
    dispatch(setMicLoading(false));
    dispatch(setScreenshareLoading(false));
    dispatch(updateIsAppIdle(false));

    if (livekitConnection === "failed") {
      return;
    }

    console.log(`${dayjs().format("YYYY-MM-DD HH:mm:ss.SSS Z")} - 3`);
    Analytics(AnalyticsCategory.Channel, "User Entered voice channel");
    dispatch(hideChatBox());
    notification.close("reconnect");
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.OUT_LIVEKIT_TOKEN_REQUEST, {
        voiceChannelShortId: joiningChannel.shortId,
        workspaceId,
      }),
    );
  };
}

export function gotLivekitToken(payload: GotLivekitAccessToken): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const noiseReduction = getNoiseReductionSetting(getState());
    const previousVoiceChannel = getCurrentVoiceChannel(getState());
    const isMeetingRecording = getIsMeetingRecording(getState());
    const workspace = getCurrentWorkspace(getState());
    const livekitUrl = getLivekitUrl(getState());
    const nextVoiceChannel = getAllVoiceChannels(getState()).find(c => c.id === payload.voiceChannelId);

    if (!livekitUrl) return;

    const accessToken = payload.accessToken;

    if (!accessToken) {
      dispatch(disconnectSocket());
    }

    if (!isMeetingRecording) {
      await dispatch(loadPermission("microphone"));
      const permissionGranted = getPermissionStateMicrophone(getState());

      if (!permissionGranted && os === "macos") {
        dispatch(showRequiredMicrophonePermissionModal());
      }
    }

    if (me && nextVoiceChannel) {
      if (workspace) {
        console.log(workspace.shortId, nextVoiceChannel.shortId);
        history.push(`${getWorkspaceDashboardUrl(workspace.shortId)}/${nextVoiceChannel.shortId}`);
        dispatch(restartVAD({ restartVAD: 0 }));
      }

      dispatch(startLoadingVirtualOffice());
      dispatch(clearOrderedObjects());
      dispatch(stopCheckingActiveWindow());
      dispatch(clearActiveWindowData(nextVoiceChannel?.workspaceId));

      if (previousVoiceChannel && previousVoiceChannel.id !== payload.voiceChannelId) {
        dispatch(doStopScreenShare());
        notification.close(slackSyncErrorNotificationKey);
      }

      if (!isMeetingRecording && nextVoiceChannel) {
        dispatch(setUserVoiceChannelOnSentry(nextVoiceChannel.id, nextVoiceChannel.name));
      }

      dispatch(
        connectRoom({
          url: livekitUrl,
          token: accessToken,
          autoSubscribe: !!nextVoiceChannel.parentVoiceChannelId,
          noiseReduction,
          iceTransportPolicy: !!workspace?.iceTransportPolicy,
        }),
      );
    }
  };
}

function channelLastMeetingUpdateAction(payload: UpdateChannelLastMeetingAt) {
  return {
    type: ChannelActions.CHANNEL_LAST_MEETING_UPDATE,
    payload,
  } as const;
}

export function channelLastMeetingUpdate(payload: UpdateChannelLastMeetingAt): AppThunk {
  return dispatch => {
    dispatch(channelLastMeetingUpdateAction(payload));
  };
}

export function channelJoined(payload: JoinedChannel): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const isMeetingRecording = getIsMeetingRecording(getState());
    const participants = getParticipants(getState());
    const userInChannel = isUserInChannel(participants)(getState());
    const screenshareStatus = getScreenshare(getState());
    const joinedChannel = getAllVoiceChannels(getState()).find(v => v.id === payload.voiceChannelId);

    dispatch(
      updateUserChannel({
        voiceChannelId: payload.voiceChannelId,
        userId: payload.userId,
      }),
    );

    if (me?.id === payload.userId && isMeetingRecording && me?.voiceChannelId !== payload.voiceChannelId) {
      return;
    }

    zohoManage({
      action: "set",
      email: me?.email,
      name: me?.name,
      language: me?.language,
      userId: me?.id,
      workspaceId: me?.workspaceId,
    });

    if (me?.id !== payload.userId) {
      // New User joined into the channel
      if (userInChannel && !!me?.silence && !me?.speakerActive) {
        dispatch(playSoundEffect(PlaySoundEffectTypes.NEW_USER_JOIN));
      }
    } else {
      // Self Joined
      dispatch(playSoundEffect(PlaySoundEffectTypes.CONNECTED));
      dispatch(switchedWorkspaceAction());
      dispatch(
        setDMUserIds(
          joinedChannel?.parentVoiceChannelId
            ? TextChatReceiverOptionIds.EVERYONE_IN_MTG_ROOM
            : TextChatReceiverOptionIds.NEAR_USERS,
        ),
      );

      if (me.role === "listener" && typeof payload.showOriginalTextEnabledAsDefault === "boolean") {
        dispatch(updateShowOriginalMessage(payload.showOriginalTextEnabledAsDefault));
      }

      if (me.role !== "meeting-recording-bot" && me.role !== "listener") {
        if (joinedChannel && joinedChannel.isEnableMicForMtg) {
          dispatch(enableMic());
        } else if (
          (joinedChannel?.parentVoiceChannelId && !joinedChannel?.isEnableMicForMtg) ||
          !joinedChannel?.parentVoiceChannelId
        ) {
          dispatch(changeMicStatus(true, true));
        }

        dispatch(changeSelfVideoStatus(!!joinedChannel?.isEnableWebCamForMtg));
      }

      dispatch(channelJoinAction(false));
      dispatch(bringToFront({ userId: payload.userId, type: "avatar" }));
      dispatch(setTextChannel(payload.workspaceId, payload.textChannelId!));
      dispatch(checkActiveWindow());

      if (payload.isMeetingRecording !== undefined) {
        dispatch(setMeetinRecordingStatusAction({ status: payload.isMeetingRecording }));
      }

      if (
        window.electron &&
        screenshareStatus.mySharedScreen &&
        screenshareStatus.mySharedScreen.id &&
        screenshareStatus.mySharedScreen?.screenIndex
      ) {
        dispatch(
          selectScreenWindowToShareFromElectron(
            screenshareStatus.mySharedScreen.id,
            screenshareStatus.mySharedScreen.name ? screenshareStatus.mySharedScreen.name : "",
            screenshareStatus.mySharedScreen?.screenIndex,
            false,
          ),
        );
      }
    }

    if (me && me.id === payload.userId) {
      dispatch(setKnockers(payload.voiceChannelId, payload.knockers));
      dispatch(openSubscribedKnockNotification(payload.voiceChannelId));
    }

    if (me && me.id === payload.userId && payload.knock) {
      dispatch(setMyKnock(payload.knock.voiceChannelId, payload.knock.isApproved, payload.knock.isGotResponse));
    }

    if (
      me &&
      (me.voiceChannelId === payload.voiceChannelId ||
        (joinedChannel && joinedChannel.knockSubscriberIds && joinedChannel.knockSubscriberIds.includes(me.id)))
    ) {
      const currentKnockers = await getKnockersByVoiceChannelId(payload.voiceChannelId)(getState());

      if (currentKnockers) {
        const currentKnockerIds = currentKnockers.map(knocker => knocker.userId);

        if (currentKnockerIds.includes(payload.userId)) {
          await dispatch(removeKnockerAction({ voiceChannelId: payload.voiceChannelId, knockerId: payload.userId }));
        }
      }
    }
  };
}

function channelLeaveAction(payload: LeaveChannel) {
  return {
    type: ChannelActions.CHANNEL_LEAVE,
    payload,
  } as const;
}

export function channelLeave(payload: LeaveChannel): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const workspace = getCurrentWorkspace(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const pendingInviteRequests = getPendingInvitations(getState());
    const existingPendingInvitedChannel = pendingInviteRequests.find(
      el => el.voiceChannelId === currentVoiceChannel?.id,
    );

    const isMeeting =
      me?.guestJoinId === currentVoiceChannel?.id &&
      currentVoiceChannel?.parentVoiceChannelId &&
      currentVoiceChannel?.guestEnabled;
    const isGuest = checkIsGuest(getState());
    const isListener = checkIsListener(getState());

    if ((isMeeting && isGuest) || isListener) {
      if (currentVoiceChannel?.shortId) {
        dispatch(logout(currentVoiceChannel?.shortId, isGuest, isListener));
      } else {
        dispatch(logout());
      }

      return;
    }

    dispatch(hideChatBox());
    dispatch(showModal({ id: ELECTRON_SCREEN_SELECTOR_MODAL_ID, show: false }));

    if (currentVoiceChannel?.isDeleteAllData && workspace?.id) {
      localDataTextChatHistory.clearLocalChatHistoryForMeeting(workspace.id, currentVoiceChannel?.id);
    }

    if (currentVoiceChannel?.id && workspace) {
      Analytics(AnalyticsCategory.Channel, "User left channel");

      if (!payload.duplicatedJoin) {
        dispatch(
          sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_LEAVE, {
            workspaceId: workspace.id,
            voiceChannelId: currentVoiceChannel.id,
            workspaceShortId: workspace.shortId,
            ...payload,
          }),
        );
      }

      dispatch(channelLeaveAction(payload));
      dispatch(doStopScreenShare());
      dispatch(disconnectRoom());
      dispatch(setTextChannel(workspace.id, 0));

      notification.close(slackSyncErrorNotificationKey);

      existingPendingInvitedChannel?.invitedUsersIds
        .map(userId => callFeedbackNotificationKey(currentVoiceChannel?.id, userId))
        .forEach(key => notification.close(key));

      history.push(getWorkspaceDashboardUrl(workspace.shortId));
    }
  };
}

function channelLeftAction(payload: LeftChannel) {
  return {
    type: ChannelActions.CHANNEL_LEFT,
    payload,
  } as const;
}

export function channelLeft(payload: LeftChannel): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const allChannels = getAllVoiceChannels(getState());
    const isMeetingRecording = getIsMeetingRecording(getState());
    const isListener = checkIsListener(getState());

    if (isListener && me?.id === payload.userId) {
      dispatch(doLogout());
      dispatch(reloadAccount());
      dispatch(disconnectSocket());
      return;
    }

    if (
      !isListener &&
      me?.voiceChannelId !== null &&
      (me?.voiceChannelId === payload.voiceChannelId || payload.voiceChannelId === null) && // payload.voiceChannelId === null means disconnect or close window
      me?.id !== payload.userId
    ) {
      dispatch(viewerDisconnected(payload.userId));
      checkForPendingRequest({
        inviteduserId: payload.userId,
        voiceChannelId: payload.voiceChannelId,
      }).then(response => {
        if (response.invitedByUserId === me?.id && me?.voiceChannelId) {
          const key = callFeedbackNotificationKey(me.voiceChannelId, response.inviteduserId);

          notification.close(key);
          dispatch(removeNotificationOnPIP({ type: "call-invite-request", invitedUserId: response.inviteduserId }));
          dispatch(
            voiceChannelInviteRemoveAction({
              invitedUserId: response.inviteduserId,
              voiceChannelId: me.voiceChannelId,
            }),
          );
        }
      });

      if (me?.silence && !me?.speakerActive) dispatch(playSoundEffect(PlaySoundEffectTypes.USER_LEAVE));
    }

    if (me && me.id === payload.userId) {
      notification.close("feedback-knocking-private-meeting");
    }

    dispatch(removeFromOrderedObject({ userId: payload.userId, type: "avatar" }));
    dispatch(resetEmojiAction({ userId: payload.userId, persistent: true }));
    dispatch(channelLeftAction(payload));
    dispatch(updateUserChannel({ voiceChannelId: null, userId: payload.userId }));

    if (me?.id === payload.userId && !isMeetingRecording && !isListener) {
      const voiceChannelForLeft = allChannels.find(el => el.id === payload.voiceChannelId);

      if (!voiceChannelForLeft?.parentVoiceChannelId && me.role === "guest") {
        window.location.href = "/dashboard";
      } else if (voiceChannelForLeft?.parentVoiceChannelId) {
        dispatch(
          channelJoin({ workspaceId: payload.workspaceId, voiceChannelId: voiceChannelForLeft.parentVoiceChannelId }),
        );
      }
    }

    dispatch(remoteUserDisconnected(payload.userId));
  };
}

export function duplicatedJoin({
  workspaceId,
  voiceChannelId,
}: {
  workspaceId: number;
  voiceChannelId: number;
}): AppThunk {
  return (dispatch, getState) => {
    if (!window.electron) {
      return;
    }

    const me = getMyMember(getState());
    const { workspaceShortId, voiceChannelShortId } = getWorkspaceChannelParams();
    const voiceChannels = getAllVoiceChannels(getState());

    if (workspaceShortId && voiceChannelShortId) {
      Analytics(AnalyticsCategory.Channel, "User attemped to join a voice channel from multiple places");

      const voiceChannel = voiceChannels.find(v => v.id === voiceChannelId);

      if (voiceChannel?.parentVoiceChannelId) {
        notification.open({
          type: "warning",
          key: "reconnect",
          icon: <CloudOffIconOutlined />,
          message: <FormattedMessage id="disconnected" defaultMessage="Disconnected" />,
          description: (
            <FormattedMessage
              id="meeting-room/reconnect"
              defaultMessage="Audio output for the meeting room is disconnected as you’ve been connected elsewhere."
            />
          ),
        });
      } else {
        notification.open({
          type: "warning",
          key: "reconnect",
          icon: <CloudOffIconOutlined />,
          message: <FormattedMessage id="disconnected" defaultMessage="Disconnected" />,
          description: (
            <FormattedMessage
              id="floor/reconnect"
              defaultMessage="Audio output for the floor is disconnected as you’ve been connected elsewhere."
            />
          ),
        });
      }

      if (me?.workspaceId && me?.voiceChannelId) {
        if (me.workspaceId === workspaceId) {
          dispatch(channelLeave({ duplicatedJoin: true }));
        } else {
          dispatch(channelLeave({ duplicatedJoin: false }));
        }
      }
    }
  };
}

export function requestMuteChange(
  userId: number,
  workspaceId: number,
  voiceChannelId: number,
  mute: boolean,
): AppThunk {
  return (dispatch, getState) => {
    Analytics(AnalyticsCategory.Channel, "User requested another user to mute/unmute");
    const msg = mute
      ? intl.formatMessage({
          id: "request-mute/voice-channel-mute",
          defaultMessage: "Mute request has been successfully sent!",
        })
      : intl.formatMessage({
          id: "request-mute/voice-channel-unmute",
          defaultMessage: "Unmute request has been successfully sent!",
        });

    Toast.success(msg);

    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_MUTE_CHANGE, {
        userId,
        workspaceId,
        voiceChannelId,
        mute,
      }),
    );
  };
}

export function requestUnSilenceChange(userId: number, workspaceId: number, voiceChannelId: number): AppThunk {
  return (dispatch, getState) => {
    Analytics(AnalyticsCategory.Channel, "User requested another user to change silence status");
    const msg = intl.formatMessage({
      id: "request-silence/voice-channel-unsilence",
      defaultMessage: "Turn audio ON request has been sent!",
    });

    Toast.success(msg);

    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_SILENCE_CHANGE, {
        userId,
        workspaceId,
        voiceChannelId,
        silence: false,
      }),
    );
  };
}

export function requestScreenshare(userId: number, workspaceId: number, voiceChannelId: number): AppThunk {
  return (dispatch, getState) => {
    Analytics(AnalyticsCategory.Channel, "User requested another user to share their screen");
    const msg = intl.formatMessage({
      id: "request-screenshare/voice-channel",
      defaultMessage: "Request to share screen has been successfully sent!",
    });

    Toast.success(msg);

    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_SCREENSHARE, {
        userId,
        workspaceId,
        voiceChannelId,
      }),
    );
  };
}

export function muteChangeReceive(payload: UnmuteUnslienceRequestReceive): AppThunk {
  return (dispatch, getState) => {
    const participants = getParticipants(getState());
    const userInChannel = isUserInChannel(participants)(getState());

    if (!userInChannel) {
      return;
    }

    const key = "mute-unmute-request";
    const members = getOnlineWorkspaceUsers(getState());
    const muteChangeByUser = members.find(m => m.id === payload.userId);
    const type = payload.mute ? "mute" : "unmute";

    dispatch(addNotificationOnPIP({ notificationsType: "mute-unmute-request", data: payload }));
    sendMessageOverIPC(channels.SHOW_NOTIFICATIONS_WINDOW);

    const response = (flag: boolean) => {
      dispatch(
        requestResponse({
          requestType: type,
          isAccepted: flag,
          requestorUserId: payload.userId,
          invitationId: payload.invitationId,
          requestorSocketId: payload.requestorSocketId,
          notificationsType: key,
        }),
      );
      dispatch(removeNotificationKey(key));
    };

    dispatch(addNotificationKey(key));
    notification.open({
      key,
      type: "meeting",
      icon: <MicIconFilled />,
      message: payload.mute ? (
        <FormattedMessage id="mute-change/title" defaultMessage="Request to mute" />
      ) : (
        <FormattedMessage id="unmute-change/title" defaultMessage="Request to unmute" />
      ),
      description: payload.mute ? (
        <FormattedMessage
          id="mute-change/subtitle"
          defaultMessage="{user} is requesting for you to mute."
          values={{ user: <strong>{muteChangeByUser?.name}</strong> }}
        />
      ) : (
        <FormattedMessage
          id="unmute-change/subtitle"
          defaultMessage="{user} is requesting for you to unmute."
          values={{ user: <strong>{muteChangeByUser?.name}</strong> }}
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: payload.mute ? (
          <FormattedMessage id="mute" defaultMessage="Mute" />
        ) : (
          <FormattedMessage id="unmute" defaultMessage="Unmute" />
        ),
        onClick: () => response(true),
      },
      secondaryButton: {
        type: "primary",
        color: "negative",
        label: <FormattedMessage id="decline" defaultMessage="Decline" />,
        onClick: () => response(false),
      },
      onClose: () => response(false),
    });
  };
}

export function silenceChangeReceive(payload: UnmuteUnslienceRequestReceive): AppThunk {
  return (dispatch, getState) => {
    const participants = getParticipants(getState());
    const userInChannel = isUserInChannel(participants)(getState());

    if (!userInChannel) {
      return;
    }

    const key = "silence-unsilence-request";
    const members = getOnlineWorkspaceUsers(getState());
    const silenceByUser = members.find(m => m.id === payload.userId);
    const type = payload.silence ? "silence" : "unsilence";

    dispatch(
      addNotificationOnPIP({
        notificationsType: "silence-unsilence-request",
        data: payload,
      }),
    );

    sendMessageOverIPC(channels.SHOW_NOTIFICATIONS_WINDOW);

    const response = (flag: boolean) => {
      dispatch(
        requestResponse({
          requestType: type,
          isAccepted: flag,
          requestorUserId: payload.userId,
          invitationId: payload.invitationId,
          requestorSocketId: payload.requestorSocketId,
          notificationsType: "silence-unsilence-request",
        }),
      );
      dispatch(removeNotificationKey(key));
    };

    dispatch(addNotificationKey(key));
    notification.open({
      key,
      type: "meeting",
      icon: <VolumeUpIconOutlined />,
      message: <FormattedMessage id="unsilence-change/title" defaultMessage="Request to turn on speaker" />,
      description: (
        <FormattedMessage
          id="unsilence-change/subtitle"
          defaultMessage="{user} is requesting for you to turn on speaker."
          values={{ user: <strong>{silenceByUser?.name}</strong> }}
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="unsilence" defaultMessage="Audio ON" />,
        onClick: () => response(true),
      },
      secondaryButton: {
        type: "primary",
        color: "negative",
        label: <FormattedMessage id="decline" defaultMessage="Decline" />,
        onClick: () => response(false),
      },
      onClose: () => response(false),
    });
  };
}

export function screenShareReceive(payload: ScreenshareRequestReceive): AppThunk {
  return (dispatch, getState) => {
    const participants = getParticipants(getState());
    const userInChannel = isUserInChannel(participants)(getState());

    if (!userInChannel) {
      return;
    }

    const key = "screenshare-startsharing-request";
    const members = getOnlineWorkspaceUsers(getState());
    const shareByUser = members.find(m => m.id === payload.userId);

    dispatch(addNotificationOnPIP({ notificationsType: "screen-share-request", data: payload }));
    sendMessageOverIPC(channels.SHOW_NOTIFICATIONS_WINDOW);

    const response = (flag: boolean) => {
      dispatch(
        requestResponse({
          requestType: "screen share",
          isAccepted: flag,
          requestorUserId: payload.userId,
          invitationId: payload.invitationId,
          requestorSocketId: payload.requestorSocketId,
          notificationsType: "screen-share-request",
        }),
      );
      dispatch(removeNotificationKey(key));
    };

    dispatch(addNotificationKey(key));
    notification.open({
      key,
      type: "meeting",
      icon: <PresentToAllIconOutlined />,
      message: (
        <FormattedMessage id="screenshare-startsharing-request/title" defaultMessage="Request to share screen" />
      ),
      description: (
        <FormattedMessage
          id="screenshare-startsharing-request/subtitle"
          defaultMessage="{user} is requesting you to share your screen."
          values={{ user: <strong>{shareByUser?.name}</strong> }}
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="share" defaultMessage="Share" />,
        onClick: () => response(true),
      },
      secondaryButton: {
        type: "primary",
        color: "negative",
        label: <FormattedMessage id="decline" defaultMessage="Decline" />,
        onClick: () => response(false),
      },
      onClose: () => response(false),
    });
  };
}

export function channelNoExist(payload: ChannelNoExist): AppThunk {
  return dispatch => {
    log(LogCategory.Debugging, "check no exist meeting room", payload);

    dispatch(resetVoiceChannelLoadingState());
    dispatch(disconnectRoom());
    dispatch(doStopScreenShare());
    Toast.warning(
      intl.formatMessage({
        id: "meeting-room/meeting-room-not-exist",
        defaultMessage: "Meeting room has been deleted, or does not exist!",
      }),
    );
    history.push(WORKSPACE_BASE_ROUTE);
  };
}

function resetVoiceChannelLoadingState() {
  return { type: ChannelActions.RESET_LOADING_STATE } as const;
}

export async function checkForPendingRequest({
  inviteduserId,
  voiceChannelId,
}: {
  inviteduserId: number;
  voiceChannelId: number;
}) {
  const { response } = await getJson(PENDING_INVITATION_STATUS_CHECK, {
    inviteduserId,
    voiceChannelId,
  });

  if (response.success) {
    const result = response.data;

    return result;
  }
}

export function speakingWhileMuted(payload: SpeakingWhileMuted) {
  return {
    type: ChannelActions.VOICE_CHANNEL_SPEAKING_WHILE_MUTED,
    payload,
  } as const;
}

export function restartVAD(payload: RestartVAD) {
  return {
    type: ChannelActions.VOICE_CHANNEL_RESTART_VAD,
    payload,
  } as const;
}

export function checkForVAD(): AppThunk {
  return (dispatch, getState) => {
    let allowedToRestartVAD = getRestartVADStatus(getState());
    let restartTime = (allowedToRestartVAD + 1) * VAD_RESTART_CONST * 1000;

    window.setTimeout(() => {
      allowedToRestartVAD = getRestartVADStatus(getState());

      if (allowedToRestartVAD >= 0 && allowedToRestartVAD < 2) {
        log(LogCategory.VAD, "RE Starting a new VAD stream at timeout of", restartTime);
        dispatch(speakingWhileMuted({ speakingWhileMuted: false }));
        dispatch(restartVAD({ restartVAD: allowedToRestartVAD + 1 }));
      } else {
        log(LogCategory.VAD, "Tooltip was dismissed by the user, exiting");
      }
    }, restartTime);
  };
}

export function gotResponse(payload: GotResponse): AppThunk {
  return (dispatch, getState) => {
    const socket = getSocket(getState())!;

    if (socket.id === payload.requestorSocketId) {
      const members = getOnlineMembersById(getOnlineWorkspaceUsers(getState()));
      const user = members[payload.requestedUserId];

      if (!payload.isAccepted) {
        switch (payload.requestType) {
          case "screen share":
            Toast.error(
              intl.formatMessage(
                {
                  id: "screen-share/response-decline",
                  defaultMessage: "{name} declined the screen share request.",
                },
                { name: user.name },
              ),
            );

            break;
          case "unsilence":
            Toast.error(
              intl.formatMessage(
                {
                  id: "unsilence/response-decline",
                  defaultMessage: "{name} declined the request to turn audio on.",
                },
                { name: user.name },
              ),
            );
            break;

          case "mute":
            Toast.error(
              intl.formatMessage(
                {
                  id: "mute/response-decline",
                  defaultMessage: "{name} declined the mute request.",
                },
                { name: user.name },
              ),
            );
            break;
          case "unmute":
            Toast.error(
              intl.formatMessage(
                {
                  id: "unmute/response-decline",
                  defaultMessage: "{name} declined the unmute request.",
                },
                { name: user.name },
              ),
            );

            break;
        }
      }
    }
  };
}

export function notificationResponse(notificationsType: PIPWindowNotificationTypes): AppThunk {
  return dispatch => {
    switch (notificationsType) {
      case "unmute-in-idle-notification":
        dispatch(changeMicStatus(false));
        break;

      case "camera-in-idle-notification":
        dispatch(changeSelfVideoStatus(true));
        break;
    }
  };
}

export function requestResponse({
  requestType,
  isAccepted,
  requestorUserId,
  invitationId,
  requestorSocketId,
}: ResponseToRequest): AppThunk {
  return (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const me = getMyMember(getState());
    const worksapceId = workspace?.id;
    const voiceChannelId = currentVoiceChannel?.id;
    const isGuest = checkIsGuest(getState());
    const isListener = checkIsListener(getState());

    if (!worksapceId || !voiceChannelId || !me || isListener) {
      return;
    }

    switch (requestType) {
      case "unmute-in-idle":
        if (isGuest) {
          dispatch(removeNotificationKey("unmute-in-idle-notification"));
        }

        dispatch(removeNotificationOnPIP({ type: "unmute-in-idle-notification" }));
        sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "unmute-in-idle-notification");

        if (isAccepted && !!me.mute) {
          dispatch(changeMicStatus(false));
        }

        break;
      case "mute":
      case "unmute":
        if (isGuest) {
          dispatch(removeNotificationKey("mute-unmute-request"));
        }

        dispatch(removeNotificationOnPIP({ type: "mute-unmute-request" }));
        sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "mute-unmute-request");

        if (isAccepted) {
          dispatch(changeMicStatus(!me.mute));
        }

        break;
      case "screen share":
        if (isGuest) {
          dispatch(removeNotificationKey("screenshare-startsharing-request"));
        }

        dispatch(removeNotificationOnPIP({ type: "screen-share-request" }));
        sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "screenshare-startsharing-request");

        if (isAccepted) {
          dispatch(tryStartingScreenShare());
        }

        break;
      case "silence":
      case "unsilence":
        if (isGuest) {
          dispatch(removeNotificationKey("silence-unsilence-request"));
        }

        dispatch(removeNotificationOnPIP({ type: "silence-unsilence-request" }));
        sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "silence-unsilence-request");

        if (isAccepted) {
          dispatch(changeSilenceStatus(worksapceId, !me.silence));
        }

        break;
      case "camera-on-in-idle":
        if (isGuest) {
          dispatch(removeNotificationKey("camera-in-idle-notification"));
        }

        dispatch(removeNotificationOnPIP({ type: "camera-in-idle-notification" }));
        sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "camera-in-idle-notification");

        if (isAccepted && !me.videoActive) {
          dispatch(changeSelfVideoStatus(true));
        }

        break;
    }

    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.RESPONSE_FOR_REQUEST, {
        requestType: requestType,
        workspaceId: worksapceId,
        voiceChannelId: voiceChannelId,
        isAccepted: isAccepted,
        requestorUserId,
        requestedUserId: me.id,
        requestId: invitationId,
        requestorSocketId,
      }),
    );
  };
}
export function copyVoiceChannelURL(voiceChannelShortId?: string): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const workspaceChannelUrl = generateChannelURL(currentWorkspace?.shortId, voiceChannelShortId);
    const voiceChannels = getAllVoiceChannels(getState());

    copyToClipboard(workspaceChannelUrl);
    const voiceChannel = voiceChannels.find(v => v.shortId === voiceChannelShortId);

    if (voiceChannel?.parentVoiceChannelId) {
      Toast.success(
        intl.formatMessage({
          id: "meeting-room/copy-link",
          defaultMessage: "Meeting room link successfully copied!",
        }),
      );
    } else {
      Toast.success(
        intl.formatMessage({
          id: "floor/copy-link",
          defaultMessage: "Floor link successfully copied!",
        }),
      );
    }
  };
}

export function copyListenerURL(listenerId: string): AppThunk {
  return (dispatch, getState) => {
    const url = `${getProtocol()}${window.location.hostname}${
      getCurrentEnvironment() === "local" ? ":3000" : ""
    }${generateListenerJoinWithParam(listenerId ? listenerId : "")}`;

    copyToClipboard(url);

    Toast.success(
      intl.formatMessage({
        id: "meeting-room/listener-copy-link",
        defaultMessage: "Listener mode link successfully copied!",
      }),
    );
  };
}

export function openCurrentVoiceChannelSettings(): AppThunk {
  return (dispatch, getState) => {
    const voiceChannel = getCurrentVoiceChannel(getState());

    notification.close(slackSyncErrorNotificationKey);

    if (!voiceChannel) {
      return;
    }

    const {
      id,
      parentVoiceChannelId,
      name,
      workspaceBackgroundId,
      isTemporary,
      speechToTextLanguage,
      isPrivate,
      speechToTextEnabled,
      textToSpeechEnabled,
    } = voiceChannel;

    dispatch(
      showModal<UpdateVoiceChannelModalData>({
        id: UPDATE_CHANNEL_MODAL_ID,
        data: {
          id,
          parentVoiceChannelId,
          name,
          workspaceBackgroundId,
          isTemporary,
          speechToTextLanguage,
          isPrivate,
          speechToTextEnabled,
          textToSpeechEnabled,
          guestEnabled: voiceChannel.guestEnabled,
          guestPassword: voiceChannel.guestPassword,
          listenerEnabled: voiceChannel.listenerEnabled,
          listenerMicEnabled: voiceChannel.listenerMicEnabled,
          listenerPassword: voiceChannel.listenerPassword,
          isAutoMeetingRecordingEnabled: voiceChannel.isAutoMeetingRecordingEnabled,
          isPositionLock: voiceChannel.isPositionLock,
          isDisableFloorAccess: voiceChannel.isDisableFloorAccess,
          isEnableRectangularView: voiceChannel.isEnableRectangularView,
          isEnableMicForMtg: voiceChannel.isEnableMicForMtg,
          isEnableWebCamForMtg: voiceChannel.isEnableWebCamForMtg,
          isScheduledMeeting: voiceChannel.isScheduledMeeting,
          meetingSlots: voiceChannel.meetingSlots,
          meetingParticipants: voiceChannel.meetingParticipants,
          reminderInterval: voiceChannel.reminderInterval,
          timezoneOffset: voiceChannel.timezoneOffset,
          isDeleteAllData: voiceChannel.isDeleteAllData,
          knockSubscriberIds: voiceChannel.knockSubscriberIds,
          showOriginalTextEnabledAsDefault: voiceChannel.showOriginalTextEnabledAsDefault,
        },
        show: true,
      }),
    );
  };
}

export function getCloserRequestAction(payload: GetCloserInvite | null) {
  return {
    type: ChannelActions.GET_CLOSER_INVITE_REQUEST,
    payload,
  } as const;
}

export function clearGetCloserInviteRequest() {
  return {
    type: ChannelActions.CLEAR_GET_CLOSER_INVITE_REQUEST,
  } as const;
}

export function jumpCloserRequest(userId: number, workspaceId: number, voiceChannelId: number): AppThunk {
  return (dispatch, getState) => {
    const members = getOnlineMembersById(getOnlineWorkspaceUsers(getState()));
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    if (!me) return;

    const randomOffset = Math.floor(Math.random() * NOTIFICATION_WINDOW_AVATAR_SIZE) + 1;

    const invitedByMember = members[userId];

    if (currentVoiceChannel?.id !== voiceChannelId) {
      dispatch(channelJoin({ voiceChannelId, workspaceId: workspaceId }));
    }

    dispatch(
      getCloserRequestAction({
        workspaceId: workspaceId,
        voiceChannelId: voiceChannelId,
        invitedUserId: me.id,
        invitedByUserId: userId,
        position: { x: invitedByMember.position.x - randomOffset, y: invitedByMember.position.y - randomOffset },
      }),
    );
  };
}

export function getCloserRequest(payload: GetCloserInvite): AppThunk {
  return (dispatch, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const members = getOnlineMembersById(getOnlineWorkspaceUsers(getState()));
    const me = getMyMember(getState());

    if (!me) return;

    if (currentVoiceChannel?.id !== payload.voiceChannelId) {
      dispatch(channelJoin({ voiceChannelId: payload.voiceChannelId, workspaceId: payload.workspaceId }));
    }

    const randomOffset = Math.floor(Math.random() * NOTIFICATION_WINDOW_AVATAR_SIZE) + 1;

    const invitedByMember = members[payload.invitedByUserId];

    if (invitedByMember && payload.position) {
      dispatch(
        getCloserRequestAction({
          workspaceId: payload.workspaceId,
          voiceChannelId: payload.voiceChannelId,
          invitedUserId: me.id,
          invitedByUserId: invitedByMember.id,
          position: { x: payload.position.x - randomOffset, y: payload.position.y - randomOffset },
        }),
      );
    }
  };
}

export function autoRecordingMeetingFeatureAvailedStatusUpdate(payload: { status: boolean; voiceChannelId: number }) {
  return {
    type: ChannelActions.AUTO_MEETING_RECORDING_FEATURE_AVAILED_STATUS_UPDATED,
    payload,
  } as const;
}

export function updateVoiceChannelSttLanguageAction(payload: { voiceChannelId: number; speechToTextLanguage: string }) {
  return {
    type: ChannelActions.VOICE_CHANNEL_UPDATE_STT_LANGUAGE,
    payload,
  } as const;
}

export function updateVoiceChannelSttLanguage(payload: {
  voiceChannelId: number;
  speechToTextLanguage: string;
}): AppThunk {
  return dispatch => {
    dispatch(updateVoiceChannelSttLanguageAction(payload));
  };
}

function updateCollapsedVoiceChannelAction(payload: { newCollapsedVoiceChannels: number[] }) {
  return {
    type: ChannelActions.UPDATE_COLLAPSED_VOICE_CHANNEL,
    payload,
  } as const;
}

export function updateCollapsedVoiceChannel(voiceChannelId: number, isCollapsed: boolean): AppThunk {
  return (dispatch, getState) => {
    const oldList = JSON.parse(localData.fetch("sidebar.voiceChannel.collapsedVoiceChannels")) as number[];
    const newList = isCollapsed
      ? [...oldList.filter(el => el !== voiceChannelId), voiceChannelId]
      : oldList.filter(el => el !== voiceChannelId);

    localData.set("sidebar.voiceChannel.collapsedVoiceChannels", JSON.stringify(newList));

    dispatch(updateCollapsedVoiceChannelAction({ newCollapsedVoiceChannels: newList }));
  };
}

function switchIsGetCloserWasAcceptd(payload: { isGetCloserWasAccepted: boolean }) {
  return {
    type: ChannelActions.SWITCH_GET_CLOSER_WAS_ACCEPTED,
    payload,
  } as const;
}

export function knockPriaveRoom(voiceChannelId: number, userName: string): AppThunk {
  return dispatch => {
    dispatch(setMyKnock(voiceChannelId, false));
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_KNOCK_PRIVATE_MEETING, {
        voiceChannelId: voiceChannelId,
        userName: userName,
      }),
    );
  };
}

export function resendKnockPrivateRoom(voiceChannelId: number, userName: string): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_RESEND_KNOCK_PRIVATE_MEETING, {
        voiceChannelId: voiceChannelId,
        userName: userName,
      }),
    );

    dispatch(removeMyKnockAction());

    Toast.success(
      <FormattedMessage
        id="knock-meeting-room/toast-success-to-sent"
        defaultMessage="Access request sent! Please wait while someone in the meeting lets you in"
      />,
    );
  };
}

export function setKnockerAction(payload: { voiceChannelId: number; knockerId: number; knockerName: string }) {
  return {
    type: ChannelActions.SET_KNOCKER,
    payload,
  } as const;
}

export function setKnockersAction(payload: {
  voiceChannelId: number;
  knockers: { userId: number; userName: string }[] | undefined;
}) {
  return {
    type: ChannelActions.SET_KNOCKERS,
    payload,
  } as const;
}

export function removeKnockerAction(payload: { voiceChannelId: number; knockerId: number }) {
  return {
    type: ChannelActions.REMOVE_KNOCKER,
    payload,
  } as const;
}

function openKnockNotification(voiceChannelId: number): AppThunk {
  return async (dispatch, getstate) => {
    const me = await getMyMember(getstate());

    const key = "feedback-knocking-private-meeting";

    const knockers = await getKnockersByVoiceChannelId(voiceChannelId)(getstate());

    if (!me || me.voiceChannelId !== voiceChannelId) {
      notification.close(key);
      dispatch(removeNotificationOnPIP({ type: "knocker-list" }));
      return;
    }

    const accept = (knockerId: number) => {
      dispatch(playSoundEffect(null));
      dispatch(answerToKnock(voiceChannelId, knockerId, true, me.workspaceId, me.name));
    };

    const reject = (knockerId: number) => {
      dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
      dispatch(answerToKnock(voiceChannelId, knockerId, false, me.workspaceId, me.name));
    };

    if (
      me &&
      me.role !== "guest" &&
      me.role !== "listener" &&
      me.role !== "meeting-recording-bot" &&
      knockers &&
      knockers.length > 0
    ) {
      notification.open({
        type: "knock",
        key: key,
        icon: (
          <ChevronLeftIconFilled
            onClick={() => {
              const list = document.getElementById("knock-list");
              const nameLine = document.getElementById("knocker-names");
              const handler = document.getElementsByClassName("knocker-list-handler");

              if (list && nameLine) {
                if (
                  list.classList[1] === "show" &&
                  nameLine.classList[1] === "hide" &&
                  handler[0].classList.value.includes("opened")
                ) {
                  list.classList.value = "description hide";
                  nameLine.classList.value = "name-line show";
                  handler[0].classList.value =
                    "material-icon material-icon--clickable material-icons knocker-list-handler closed";
                  return;
                }

                if (
                  list.classList[1] === "hide" &&
                  nameLine.classList[1] === "show" &&
                  handler[0].classList.value.includes("closed")
                ) {
                  list.classList.value = "description show";
                  nameLine.classList.value = "name-line hide";
                  handler[0].classList.value =
                    "material-icon material-icon--clickable material-icons knocker-list-handler opened";
                  return;
                }
              }
            }}
            className="knocker-list-handler opened"
          />
        ),
        message: (
          <div>
            <p>
              <FormattedMessage id="knock-meeting-room/notification-title" defaultMessage="Meeting Request" />(
              {knockers.length})
            </p>
          </div>
        ),
        description: (
          <>
            {knockers &&
              knockers.map(knocker => {
                return (
                  <div className="each-line">
                    <PersonIconOutlined />
                    <p>{knocker.userName}</p>
                    <Button
                      color="negative"
                      onClick={() => reject(knocker.userId)}
                      className="knock-notification-button"
                    >
                      <FormattedMessage id="knock-meeting-room/notification-button-reject" defaultMessage="Reject" />
                    </Button>
                    <Button
                      color="positive"
                      onClick={() => accept(knocker.userId)}
                      className="knock-notification-button"
                    >
                      <FormattedMessage id="knock-meeting-room/notification-button-accept" defaultMessage="Accept" />
                    </Button>
                  </div>
                );
              })}
          </>
        ),
        onClose: () => {},
        closable: false,
        knockers: knockers.map(knocker => knocker.userName),
      });
      dispatch(addNotificationOnPIP({ notificationsType: "knocker-list", data: { knockers, voiceChannelId } }));
      dispatch(showNotificationWindow());
    } else {
      notification.close(key);
      dispatch(removeNotificationOnPIP({ type: "knocker-list" }));
    }
  };
}

function openSubscribedKnockNotification(voiceChannelId: number): AppThunk {
  return async (dispatch, getstate) => {
    const me = getMyMember(getstate());
    const voiceChannel = getVoiceChannelById(voiceChannelId)(getstate());

    const key = subscribingKnockNotification(voiceChannelId);

    if (
      !me ||
      !voiceChannel ||
      me.voiceChannelId === voiceChannelId ||
      !voiceChannel.knockSubscriberIds?.includes(me.id)
    ) {
      notification.close(key);
      dispatch(removeNotificationOnPIP({ type: "subscribing-knock", voiceChannelId: voiceChannelId }));
      return;
    }

    const knockers = await getKnockersByVoiceChannelId(voiceChannelId)(getstate());

    const accept = (voiceChannelId: number) => {
      const meetingRoomInfo = getMeetingRoomById(voiceChannelId)(getstate());
      const workspaceMembers = getOnlineWorkspaceUsers(getstate());

      const membersInVoiceChannelCount = workspaceMembers.filter(
        el =>
          el.voiceChannelId === meetingRoomInfo?.meetingRoom.id &&
          el.online &&
          el.role !== "guest" &&
          el.role !== "meeting-recording-bot",
      ).length;

      if (membersInVoiceChannelCount > 0) {
        dispatch(knockPriaveRoom(voiceChannelId, me.name));
      } else {
        dispatch(channelJoin({ workspaceId: me.workspaceId, voiceChannelId }));
      }

      notification.close(key);
    };

    const reject = () => {
      dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
      notification.close(key);
    };

    if (me && me.role !== "guest" && me.role !== "meeting-recording-bot" && knockers && knockers.length > 0) {
      notification.open({
        type: "meeting",
        key: key,
        icon: <ChevronLeftIconFilled className="knocker-list-handler opened" />,
        message: (
          <div>
            <p>
              <FormattedMessage id="knock-meeting-room/notification-title" defaultMessage="Meeting Request" />(
              {knockers.length})
            </p>
          </div>
        ),
        description: (
          <div className="knock-subscribe-notification">
            {
              <FormattedMessage
                id="knock-meeting-room/get-join-request"
                defaultMessage="get join request for {voiceChannelName}"
                values={{ voiceChannelName: voiceChannel.name }}
              />
            }
            {
              <p className="index">
                <FormattedMessage id="knock-meeting-room/requesting-user" defaultMessage="Requesting User:" />
              </p>
            }

            {knockers.map(knock => {
              return <p>{knock.userName}</p>;
            })}
          </div>
        ),
        primaryButton: {
          type: "primary",
          color: "positive",
          label: <FormattedMessage id="invited-voice-channel/join-now" defaultMessage="Join Now" />,
          onClick: () => accept(voiceChannelId),
        },
        secondaryButton: {
          type: "secondary",
          color: "negative",
          label: <FormattedMessage id="decline" defaultMessage="Decline" />,
          onClick: () => reject(),
        },
        onClose: () => {},
        closable: false,
      });
      dispatch(
        addNotificationOnPIP({
          notificationsType: "subscribing-knock",
          data: [{ knockers: knockers, voiceChannelId: voiceChannelId, voiceChannelName: voiceChannel.name }],
        }),
      );
      dispatch(showNotificationWindow());
    } else {
      notification.close(key);
      dispatch(removeNotificationOnPIP({ type: "subscribing-knock", voiceChannelId: voiceChannelId }));
    }
  };
}

function sendCanceKnock(knockerId: number, voiceChannelId: number): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_CANCEL_KNOCK, { knockerId: knockerId, voiceChannelId: voiceChannelId }),
    );
  };
}

function openMyKnockNotification(): AppThunk {
  return (dispatch, getstate) => {
    const me = getMyMember(getstate());
    const myKnock = getMyKnock(getstate());
    const key = "request-knocking-prevate-meeting";

    if (!me) return;

    if (me && myKnock && me.voiceChannelId && !myKnock.isGotResponse) {
      const voiceChannel = getVoiceChannelById(myKnock.voiceChannelId)(getstate());

      const quitKnock = (knockerId: number, voiceChannelId: number) => {
        dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
        dispatch(sendCanceKnock(knockerId, voiceChannelId));
        notification.close(key);
      };

      if (voiceChannel) {
        notification.open({
          type: "meeting",
          key: key,
          icon: <PersonIconOutlined />,
          message: (
            <div>
              <p>
                {
                  <FormattedMessage
                    id="knock-meeting-room/myknock-window-title"
                    defaultMessage="Requesting to join {voiceChannelName}"
                    values={{ voiceChannelName: voiceChannel.name }}
                  />
                }
              </p>
            </div>
          ),
          description: (
            <>
              <Button color="negative" onClick={() => quitKnock(me.id, myKnock.voiceChannelId)}>
                {<FormattedMessage id="knock-meeting-room/myknock-window-buton" defaultMessage="Cancel" />}
              </Button>
            </>
          ),
          onClose: () => {},
          closable: false,
        });
      }
    } else {
      notification.close(key);
    }
  };
}

export function setKnockers(
  voiceChannelId: number,
  knockers: { userId: number; userName: string }[] | undefined,
): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    dispatch(setKnockersAction({ voiceChannelId, knockers }));
    if (me.voiceChannelId === voiceChannelId) {
      dispatch(openKnockNotification(voiceChannelId));
    } else {
      dispatch(openSubscribedKnockNotification(voiceChannelId));
    }
  };
}

export function setKnocker(voiceChannelId: number, knockerId: number, knockerName: string): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(setKnockerAction({ voiceChannelId, knockerId, knockerName }));

    if (!me || me.id !== knockerId) {
      if (me && me?.voiceChannelId === voiceChannelId) {
        dispatch(openKnockNotification(voiceChannelId));
      } else {
        dispatch(openSubscribedKnockNotification(voiceChannelId));
      }

      dispatch(playSoundEffect(PlaySoundEffectTypes.KNOCK));
    }

    if (me && me.id === knockerId) {
      dispatch(setMyKnock(voiceChannelId, false));
    }
  };
}

export function answerToKnock(
  voiceChannelId: number,
  knockerId: number,
  isAccepted: boolean,
  workspaceId: number,
  userName: string,
): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_ANSWER_TO_KNOCK, {
        voiceChannelId: voiceChannelId,
        knockerId: knockerId,
        isAccepted: isAccepted,
        workspaceId: workspaceId,
        userName: userName,
      }),
    );
  };
}

export function receiveAnswerToKnock(
  voiceChannelId: number,
  isAccepted: boolean,
  knockerId: number,
  userName: string,
  userIds?: number[],
  isListener?: boolean,
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const voiceChannel = getVoiceChannelById(voiceChannelId)(getState());
    const account = getAuthenticatedAccount(getState());
    const myId = me && me.id ? me.id : account.id;

    if ((me && knockerId === me.id) || account.id === knockerId) {
      if (isAccepted) {
        dispatch(removeMyKnockAction());

        if (isListener) {
          window.location.href = "/dashboard";
        }

        dispatch(openMyKnockNotification());
        dispatch(
          channelJoin({
            workspaceId: myId,
            voiceChannelId: voiceChannelId,
          }),
        );
      } else {
        dispatch(playSoundEffect(PlaySoundEffectTypes.DECLINE_INCOMING_CALL));
        dispatch(setMyKnockAction(voiceChannelId, isAccepted, true));
        dispatch(openMyKnockNotification());
        Toast.error(
          <FormattedMessage
            id="knock-meeting-room/toast-rejected"
            defaultMessage="{userName} rejected you to join {voiceChannelName}"
            values={{
              voiceChannelName: voiceChannel?.name,
              userName: userName,
            }}
          />,
        );
      }
    }

    if (userIds && userIds.includes(myId)) {
      await dispatch(removeKnock(knockerId, voiceChannelId));
    }
  };
}

export function receiveKnockingChannelIsDeleted(knockerId: number, voiceChannelName: string): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && knockerId === me.id) {
      Toast.error(`${voiceChannelName} is deleted. Please contact to worksapce manager`);
      dispatch(sendSignOut(me.id));
    }
  };
}

export function removeKnock(knockerId: number, voiceChannelId: number): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(removeKnockerAction({ voiceChannelId, knockerId }));

    if (me && me.voiceChannelId === voiceChannelId) {
      dispatch(openKnockNotification(voiceChannelId));
    }

    dispatch(openSubscribedKnockNotification(voiceChannelId));
  };
}

export function setMyKnockAction(voiceChannelId: number, isAccepted: boolean, isGotResponse?: boolean) {
  return {
    type: ChannelActions.SET_MY_KNOCK,
    payload: { voiceChannelId: voiceChannelId, isApproved: isAccepted, isGotResponse: isGotResponse },
  } as const;
}

export function removeMyKnockAction() {
  return {
    type: ChannelActions.REMOVE_MY_KNOCK,
    payload: {},
  } as const;
}

export function setMyKnock(voiceChannelId: number, isAccepted: boolean, isGotResponse?: boolean): AppThunk {
  return (dispatch, getState) => {
    dispatch(setMyKnockAction(voiceChannelId, isAccepted, isGotResponse));
    dispatch(openMyKnockNotification());
  };
}

export function fallBackGuest(): AppThunk {
  return () => {
    history.push(GUEST_JOIN_PATH);
  };
}

export function handleInvalidKnockResponse(voiceChannelId: number, knockerId: number): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me) {
      if (me.id === knockerId) {
        dispatch(removeMyKnockAction());
        dispatch(openMyKnockNotification());
        Toast.error("your knock is invalid, please try again");
      }

      dispatch(removeKnock(knockerId, voiceChannelId));
      if (me.id !== knockerId) {
        Toast.error("This knock was invalid. rejected.");
      }
    }
  };
}

export function joinVoiceChannelFromSubscribingKnock(payload: JoinVoiceChannelFromSubscribingKnock): AppThunk {
  return (dispatch, getState) => {
    console.log("LOG:JOIN-VOICE-CHANNEL-FROM-PIP");
    const me = getMyMember(getState());

    if (!me) return;

    console.log("LOG:VALID-EVENT-JOIN-VOICE-CHANNEL-FROM-PIP");

    const meetingRoomInfo = getMeetingRoomById(payload.voiceChannelId)(getState());
    const workspaceMembers = getOnlineWorkspaceUsers(getState());
    const membersInVoiceChannelCount = workspaceMembers.filter(
      el =>
        el.voiceChannelId === meetingRoomInfo?.meetingRoom.id &&
        el.online &&
        el.role !== "guest" &&
        el.role !== "meeting-recording-bot",
    ).length;

    if (membersInVoiceChannelCount > 0) {
      dispatch(knockPriaveRoom(payload.voiceChannelId, me.name));
    } else {
      dispatch(channelJoin({ workspaceId: me.workspaceId, voiceChannelId: payload.voiceChannelId }));
    }

    notification.close(subscribingKnockNotification(payload.voiceChannelId));
  };
}

export function switchSubscribeKnockNotificationRequest(
  subscriberIds: number[],
  voiceChannelId: number,
  workspaceId: number,
): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(CHANNEL_EVENT_TYPES.REQUEST_SWITCH_KNOCK_SUBSCRIBE_STATUS, {
        subscriberIds,
        voiceChannelId,
        workspaceId,
      }),
    );
  };
}

function updateKnockSubscriberAction(voiceChannelId: number, subscriberIds: number[]) {
  return {
    type: ChannelActions.UPDATE_KNOCK_SUBSCRIBER,
    payload: { voiceChannelId, subscriberIds },
  } as const;
}

export function switchSubscribeKNockNotificationResponse(
  voiceChannelId: number,
  userId: number,
  subscriberIds: number[],
  isSubscriber: boolean,
): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const voiceChannel = getVoiceChannelById(voiceChannelId)(getState());

    if (!voiceChannel?.parentVoiceChannelId) {
      return;
    }

    if (me && me.id === userId) {
      isSubscriber
        ? Toast.success(
            "You will receive this meeting room knock notification everywhare and everytime in this workspace",
          )
        : Toast.success("You won't receive knock notification");
    }

    dispatch(updateKnockSubscriberAction(voiceChannelId, subscriberIds));
  };
}

export function noPermissionChannelJoin(message: string, voiceChannelId: number): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    dispatch(channelJoinAction(false));

    Toast.error(message);
    if (me.assignedFloors) {
      dispatch(channelJoin({ workspaceId: me.workspaceId, voiceChannelId: me.assignedFloors[0] }, true));
    } else {
      Sentry.captureException(`This user is facing to dead lock status userId:${me.id}`);
    }
  };
}

export function enabledWebGuestApp(enabled: boolean, currentVoiceChannel: ChannelData): AppThunk {
  return dispatch => {
    dispatch(
      channelUpdate({
        voiceChannelId: currentVoiceChannel.id,
        voiceChannelName: currentVoiceChannel.name,
        workspaceBackgroundId: currentVoiceChannel.workspaceBackgroundId,
        speechToTextLanguage: currentVoiceChannel.speechToTextLanguage,
        isPrivate: currentVoiceChannel.isPrivate,
        speechToTextEnabled: currentVoiceChannel.speechToTextEnabled,
        textToSpeechEnabled: currentVoiceChannel.textToSpeechEnabled,
        guestEnabled: enabled,
        guestPassword: currentVoiceChannel.guestPassword,
        listenerEnabled: currentVoiceChannel.listenerEnabled,
        listenerMicEnabled: currentVoiceChannel.listenerMicEnabled,
        listenerPassword: currentVoiceChannel.listenerPassword,
        isAutoMeetingRecordingEnabled: currentVoiceChannel.isAutoMeetingRecordingEnabled,
        isPositionLock: currentVoiceChannel.isPositionLock,
        isDisableFloorAccess: currentVoiceChannel.isDisableFloorAccess,
        isEnableRectangularView: currentVoiceChannel.isEnableRectangularView,
        isScheduledMeeting: currentVoiceChannel.isScheduledMeeting,
        isEnableWebCamForMtg: currentVoiceChannel.isEnableWebCamForMtg,
        isEnableMicForMtg: currentVoiceChannel.isEnableMicForMtg,
        isDeleteAllData: currentVoiceChannel?.isDeleteAllData,
        showOriginalTextEnabledAsDefault: currentVoiceChannel.showOriginalTextEnabledAsDefault,
      }),
    );
  };
}

export function enabledWebListnerMode(enabled: boolean, currentVoiceChannel: ChannelData): AppThunk {
  return dispatch => {
    dispatch(
      channelUpdate({
        voiceChannelId: currentVoiceChannel.id,
        voiceChannelName: currentVoiceChannel.name,
        workspaceBackgroundId: currentVoiceChannel.workspaceBackgroundId,
        speechToTextLanguage: currentVoiceChannel.speechToTextLanguage,
        isPrivate: currentVoiceChannel.isPrivate,
        speechToTextEnabled: currentVoiceChannel.speechToTextEnabled,
        textToSpeechEnabled: enabled ? true : false,
        guestEnabled: currentVoiceChannel.guestEnabled,
        guestPassword: currentVoiceChannel.guestPassword,
        listenerEnabled: enabled,
        listenerMicEnabled: currentVoiceChannel.listenerMicEnabled,
        listenerPassword: null,
        isAutoMeetingRecordingEnabled: currentVoiceChannel.isAutoMeetingRecordingEnabled,
        isPositionLock: currentVoiceChannel.isPositionLock,
        isDisableFloorAccess: currentVoiceChannel.isDisableFloorAccess,
        isEnableRectangularView: currentVoiceChannel.isEnableRectangularView,
        isScheduledMeeting: currentVoiceChannel.isScheduledMeeting,
        isEnableWebCamForMtg: currentVoiceChannel.isEnableWebCamForMtg,
        isEnableMicForMtg: currentVoiceChannel.isEnableMicForMtg,
        isDeleteAllData: currentVoiceChannel?.isDeleteAllData,
        showOriginalTextEnabledAsDefault: currentVoiceChannel.showOriginalTextEnabledAsDefault,
      }),
    );
  };
}
