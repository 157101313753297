import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "../../../components/antd/Select/Select";
import { getMyMember } from "../../../store/users";
import { getTextChatFloorAdditionalOptions, getTextChatMeetingRoomAdditionalOptions } from "../../../utils/helpers";
import "./ReceiverInput.scss";
import { UserAvatar } from "../../../components/antd/UserAvatar";
import {
  ArrowDropDownIconFilled,
  GroupIconOutlined,
  GroupsIconOutlined,
  PodcastsOutlined,
} from "../../../components/icons/material";
import { ChannelData } from "../../../store/types";
import { Tag } from "antd";
import { setFocusChatBoxAction } from "../../../store/textChannel/actions";
import { getTextChatAllowMembers } from "../../../store/voiceChannels/selectors";

interface Props {
  onChangeReceiver: (value: number[] | TextChatReceiverOptionIds | undefined) => void;
  currentChannel: ChannelData | undefined;
  receiver: number[] | TextChatReceiverOptionIds | undefined;
}

export enum TextChatReceiverOptionIds {
  EVERYONE_IN_WORKSPACE = "everyone_in_workspace",
  EVERYONE_IN_FLOOR = "everyone_in_floor",
  EVERYONE_IN_MTG_ROOM = "everyone_in_mtg_room",
  NEAR_USERS = "near_users",
}

export function ReceiverInput({ onChangeReceiver, receiver, currentChannel }: Props) {
  const members = useSelector(getTextChatAllowMembers);
  const me = useSelector(getMyMember);
  const [isMultiple, setIsMultiple] = useState(typeof receiver === "string" ? false : true);
  const [openState, setOpenState] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof receiver === "string") {
      setIsMultiple(false);
    } else {
      setIsMultiple(true);
    }
  }, [receiver]);

  const additionalOptions = currentChannel?.parentVoiceChannelId
    ? getTextChatMeetingRoomAdditionalOptions(me?.role === "guest")
    : getTextChatFloorAdditionalOptions(me?.role === "guest");

  const focusChatBox = React.useCallback(() => {
    dispatch(setFocusChatBoxAction(true));
  }, [dispatch]);

  const receiverOptions = () => {
    return (
      <>
        {members.map(m => {
          return (
            me?.id !== m.id && (
              <Select.Option key={m.id} value={m.id} label={m.name}>
                <div className="user-select-item">
                  <UserAvatar src={m.avatarUrl} size={"smaller"} />
                  <span className="user-select-name">{m.name}</span>
                </div>
              </Select.Option>
            )
          );
        })}
        {additionalOptions.map(o => {
          let icon = undefined;

          switch (o.id) {
            case TextChatReceiverOptionIds.EVERYONE_IN_WORKSPACE:
              icon = <PodcastsOutlined />;
              break;
            case TextChatReceiverOptionIds.EVERYONE_IN_FLOOR:
            case TextChatReceiverOptionIds.EVERYONE_IN_MTG_ROOM:
              icon = <GroupsIconOutlined />;
              break;
            case TextChatReceiverOptionIds.NEAR_USERS:
              icon = <GroupIconOutlined />;
              break;
          }
          return (
            <Select.Option key={o.id} value={o.id} label={o.name}>
              <div className="user-select-item">
                <div className="option-icon">{icon}</div>
                <span>{o.name}</span>
              </div>
            </Select.Option>
          );
        })}
      </>
    );
  };

  const TagRender = (props: any) => {
    const { label, onClose } = props;

    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag onMouseDown={onPreventMouseDown} closable={true} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  return (
    <div className="receiver-input">
      <span className="receiver-input__label">To:</span>
      <Select
        autoFocus={autoFocus}
        suffixIcon={<ArrowDropDownIconFilled />}
        mode={isMultiple ? "multiple" : undefined}
        optionLabelProp="children"
        value={typeof receiver === "string" ? additionalOptions.find(el => el.id === receiver)?.id : receiver}
        optionFilterProp="label"
        showSearch
        dropdownClassName="receiver-input-dropdown"
        maxTagCount="responsive"
        tagRender={TagRender}
        onSelect={value => {
          if (typeof value === "string") {
            onChangeReceiver(value);
            setOpenState(false);
            focusChatBox();
          } else if (typeof value === "number") {
            setAutoFocus(true);
            if (Array.isArray(receiver)) {
              onChangeReceiver([...receiver, value]);
            } else {
              onChangeReceiver([value]);
            }
          }
        }}
        onDeselect={value => {
          if (typeof value === "number" && Array.isArray(receiver)) {
            const newReceiver = receiver.filter(el => el !== value);

            if (newReceiver.length > 0) {
              onChangeReceiver(newReceiver);
            } else {
              onChangeReceiver(additionalOptions.find(el => el.isDefault)?.id!);
              setOpenState(false);
            }
          }
        }}
        open={openState}
        onClick={() => {
          !openState && setOpenState(true);
        }}
        onFocus={() => {
          setOpenState(true);
        }}
        onBlur={() => {
          setOpenState(false);
        }}
      >
        {receiverOptions()}
      </Select>
    </div>
  );
}
