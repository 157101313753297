import React from "react";
import { RawIntlProvider } from "react-intl";
import { Alert, notification } from "antd";
import cx from "classnames";
import "./Toast.scss";
import {
  CheckIconOutlined,
  CloseIconOutlined,
  ErrorOutlineIconOutlined,
  InfoIconOutlined,
  WarningAmberIconOutlined,
} from "../../icons/material";
import { ALERT_TIMEOUT_SECONDS_WITHOUT_ACTION, ALERT_TIMEOUT_SECONDS_WITH_ACTION } from "../../../constant";
import { intl } from "../../../i18n";
import { Button } from "../Button/Button";

interface ToastProps {
  message: React.ReactNode;
  type: "success" | "info" | "warning" | "error";
  action?: {
    title: string;
    handler: () => void;
  };
  key?: string;
}

export function Toast({ message, type, action, key }: ToastProps) {
  const alertIcon = {
    warning: <WarningAmberIconOutlined />,
    success: <CheckIconOutlined />,
    info: <InfoIconOutlined />,
    error: <ErrorOutlineIconOutlined />,
  };

  return (
    <RawIntlProvider value={intl}>
      <Alert
        message={message}
        type={type}
        showIcon
        icon={alertIcon[type]}
        key={key}
        action={
          action ? (
            <Button
              variant="secondary"
              color="default"
              onClick={() => {
                action.handler();
                key && notification.close(key);
              }}
            >
              {action.title}
            </Button>
          ) : undefined
        }
      />
    </RawIntlProvider>
  );
}

Toast.info = (message: React.ReactNode, action?: { title: string; handler: () => void }, key?: string) =>
  showToast(message, "info", action, key);
Toast.success = (message: React.ReactNode, action?: { title: string; handler: () => void }, key?: string) =>
  showToast(message, "success", action, key);
Toast.warning = (message: React.ReactNode, action?: { title: string; handler: () => void }, key?: string) =>
  showToast(message, "warning", action, key);
Toast.error = (message: React.ReactNode, action?: { title: string; handler: () => void }, key?: string) =>
  showToast(message, "error", action, key);

function showToast(
  message: React.ReactNode,
  type: "success" | "info" | "warning" | "error",
  action?: { title: string; handler: () => void },
  key?: string,
) {
  if (!message) return;

  notification.open({
    message: null,
    description: <Toast message={message} type={type} action={action} />,
    className: cx("voiceping-toast", `voiceping-toast--${type}`),
    duration: action ? ALERT_TIMEOUT_SECONDS_WITH_ACTION : ALERT_TIMEOUT_SECONDS_WITHOUT_ACTION,
    closeIcon: <CloseIconOutlined />,
    key,
  });
}
