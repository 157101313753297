import * as React from "react";
import "./NotificationsWindowLoader.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export function NotificationsWindowLoader() {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

  return (
    <div className="notification-window-loader">
      <div className="loader-spinner">
        <Spin indicator={antIcon} />
      </div>
    </div>
  );
}
