import { VersionActions, VersionActionTypes } from "./actions";

export const initialVersion = { loaded: false, environment: "", commit: "", branch: "" };

export const versionInitialState = {
  gitInfo: {
    web: initialVersion,
    server: initialVersion,
    media: initialVersion,
    desktop: initialVersion,
  },
  latestWebCommitHash: "",
  desktopAppVersion: {
    current: "",
    latest: "",
    downloadProgress: false,
  },
};

export type VersionState = typeof versionInitialState;
export type Module = keyof VersionState["gitInfo"];
export type Version = VersionState["gitInfo"][Module];

export function versionReducer(state: VersionState = versionInitialState, action: VersionActionTypes): VersionState {
  switch (action.type) {
    case VersionActions.UPDATE_LATEST_WEB:
      return {
        ...state,
        latestWebCommitHash: action.payload.latestWebCommitHash,
      };
    case VersionActions.LOAD_GIT_INFO:
      return {
        ...state,
        gitInfo: {
          ...state.gitInfo,
          [action.payload.module]: {
            loaded: false,
            environment: "loading...",
            commit: "loading...",
            branch: "loading...",
          },
        },
      };
    case VersionActions.LOADED_GIT_INFO:
      return {
        ...state,
        gitInfo: {
          ...state.gitInfo,
          [action.payload.module]: action.payload.version,
        },
      };
    case VersionActions.LOAD_DESKTOP_APP_VERSION:
      return {
        ...state,
        desktopAppVersion: {
          ...state.desktopAppVersion,
          current: "loading...",
        },
      };
    case VersionActions.LOAD_LATEST_DESKTOP_APP_VERSION:
      return {
        ...state,
        desktopAppVersion: {
          ...state.desktopAppVersion,
          latest: "loading...",
        },
      };

    case VersionActions.LOADED_DESKTOP_APP_VERSION:
      return {
        ...state,
        desktopAppVersion: {
          ...state.desktopAppVersion,
          current: action.payload.version,
        },
      };
    case VersionActions.LOADED_LATEST_DESKTOP_APP_VERSION:
      return {
        ...state,
        desktopAppVersion: {
          ...state.desktopAppVersion,
          latest: action.payload.latestVersion,
        },
      };
    case VersionActions.DOWNLOAD_LATEST_DESKTOP_APP_VERSION:
      return {
        ...state,
        desktopAppVersion: {
          ...state.desktopAppVersion,
          downloadProgress: action.payload.isLoading,
        },
      };
    default:
      return state;
  }
}
