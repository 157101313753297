import { createSelector } from "reselect";
import { convertSizeBySideWay } from "../../utils/helpers";
import { State } from "../state";
import { getWorkspaceBackgrounds } from "../workspaceBackground";

export const getWorkChannelState = (state: State) => state.workChannel;
export const isWorkChannelLoaded = createSelector(getWorkChannelState, workChannelState => !workChannelState.loading);
export const getCustomBackgrounds = createSelector(getWorkChannelState, state => state.customBackgrounds);
export const getFloorObjects = createSelector(getWorkChannelState, state => state.floorObjects);
export const getIsWorkChannelLoaded = createSelector(getWorkChannelState, state => state.isWorkChannelLoaded);
export const getCurrentWorkChannel = createSelector(getWorkChannelState, state => state.workChannel);
export const getCurrentWorkChannelId = createSelector(getWorkChannelState, state => state.workChannel?.id);

export const getCurrentWorkChannelMap = createSelector(
  getWorkChannelState,
  getWorkspaceBackgrounds,
  (workChannelState, workspaceBackgrounds) => {
    return workspaceBackgrounds.find(el =>
      workChannelState.isPreview
        ? el.id === workChannelState.previewDetail?.baseWorkspaceBackgroundId
        : el.id === workChannelState.workChannel?.baseWorkspaceBackgroundId,
    );
  },
);

export const getSelectedFloorObjectId = createSelector(getWorkChannelState, state => state.selectedObjectId);
export const getSelectedBaseWorkspaceBackgroundId = createSelector(
  getWorkChannelState,
  state => state.selectedBaseWorkspaceBackgroundId,
);
export const getWorkChannelObjects = createSelector(getWorkChannelState, state => state.workChannelObjects);
export const getWorkChannelObjectIds = createSelector(getWorkChannelState, state =>
  state.workChannelObjects.map(el => el.id),
);
export const getFloorObjectCategories = createSelector(getWorkChannelState, state => state.floorObjectCategories);
export const getFloorObjectById = (objectId: number) =>
  createSelector(getWorkChannelState, state => state.floorObjects.find(el => el.id === objectId));
export const getCurrentPanel = createSelector(getWorkChannelState, state => state.currentPanel);
export const getSelectedWorkChannelObjectIds = createSelector(
  getWorkChannelState,
  state => state.selectedWorkChannelObjectIds,
);

export const getSelectedWorkChannelObjects = createSelector(getWorkChannelState, state =>
  state.workChannelObjects.filter(el => state.selectedWorkChannelObjectIds.includes(el.id)),
);

export const getWorkChannelObjectById = (id: string) =>
  createSelector(getWorkChannelState, state => state.workChannelObjects.find(o => o.id === id));

export const getWorkChannelObjectsMaxOrder = createSelector(getWorkChannelState, state =>
  state.workChannelObjects && state.workChannelObjects.length > 0
    ? Math.max(...state.workChannelObjects.map(o => o.properties.order))
    : 0,
);

export const getWorkChannelObjectsMinOrder = createSelector(getWorkChannelState, state =>
  state.workChannelObjects.length > 0 ? Math.min(...state.workChannelObjects.map(o => o.properties.order)) : 0,
);

export const getDraggingAndResizingObject = createSelector(
  getWorkChannelState,
  state => state.draggingAndResizingObject,
);

export const getBorderExtends = createSelector(getWorkChannelState, state => {
  const verticalBorderSet = new Set<number>();
  const verticalCenterSet = new Set<number>();
  const horizontalBorderSet = new Set<number>();
  const horizontalCenterSet = new Set<number>();

  state.workChannelObjects.forEach(o => {
    if (o.id !== state.draggingAndResizingObject?.id) {
      const { width, height } = convertSizeBySideWay({ rotation: o.properties.rotation, ...o.properties.size });

      verticalBorderSet.add(o.properties.position.x);
      verticalCenterSet.add(o.properties.position.x + Math.round(width / 2));
      verticalBorderSet.add(o.properties.position.x + width);
      horizontalBorderSet.add(o.properties.position.y);
      horizontalCenterSet.add(o.properties.position.y + Math.round(height / 2));
      horizontalBorderSet.add(o.properties.position.y + height);
    }
  });
  return [verticalBorderSet, verticalCenterSet, horizontalBorderSet, horizontalCenterSet];
});

export const getNearBorderExtends = createSelector(getWorkChannelState, state => state.nearBorderExtends);
export const getEditHistories = createSelector(getWorkChannelState, state => state.editHistories);
export const getLastActiveEditHistory = createSelector(getWorkChannelState, state =>
  state.editHistories.find(el => el.status === "active"),
);

export const getFirstRevertedEditHistory = createSelector(getWorkChannelState, state => {
  const reversedRevertedHistory = state.editHistories.filter(el => el.status === "reverted").reverse();

  return reversedRevertedHistory.find(el => el.status === "reverted");
});

export const getWorkChannelSubversions = createSelector(getWorkChannelState, state => state.workChannelSubversions);
export const getPreviewDetail = createSelector(getWorkChannelState, state => ({
  isPreview: state.isPreview,
  previewDetail: state.previewDetail,
}));
export const getArrowKeyPress = createSelector(getWorkChannelState, state => state.arrowKeyPress);
export const getMultiDraggingDelta = createSelector(getWorkChannelState, state => state.multiDraggingDelta);
export const getIsTextEditing = createSelector(getWorkChannelState, state => state.isTextEditing);
export const getIsFontSizeChanging = createSelector(getWorkChannelState, state => state.isFontSizeChanging);
export const getSelectionArea = createSelector(getWorkChannelState, state => state.selectionArea);
export const getWorkChannelTemplates = createSelector(getWorkChannelState, state => state.workChannelTemplates);
export const getIsRedoingOrUndoing = createSelector(getWorkChannelState, state => state.isRedoingOrUndoing);
export const getIsFontSizeUpdated = createSelector(getWorkChannelState, state => state.isFontSizeUpdated);
export const getIsSavingAsTemplate = createSelector(getWorkChannelState, state => state.isSaveingAsTemplate);
export const getIsDeploying = createSelector(getWorkChannelState, state => state.isDeploying);
export const getCroppingImageInfo = createSelector(getWorkChannelState, state => state.croppingImage);
export const getCroppingDirection = createSelector(getWorkChannelState, state => state.croppingDirection);
export const getIsRedLineEnabled = createSelector(getWorkChannelState, state => state.isRedLineEnabled);
export const getIsGridEnabled = createSelector(getWorkChannelState, state => state.isGridEnabled);
export const getGridGap = createSelector(getWorkChannelState, state => state.gridGap);
export const getNearGrids = createSelector(getWorkChannelState, state => state.nearGrids);
