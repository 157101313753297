import { WorkspaceBackground } from "../workspaceBackground";
import {
  addWorkChannelSubversionBroadcast,
  appliedWorkChannelTemplateBroadcast,
  createCustomBackgroundBroadcast,
  createCustomObjectBroadcast,
  createMultiWorkChannelObjectBroadcast,
  createWorkChannelObjectBroadcast,
  deleteCustomBackgroundBroadcast,
  deleteCustomObjectBroadcast,
  deletedWorkChannelTemplateBroadcast,
  deleteMultiWorkChannelObjectBroadcast,
  deleteWorkChannelObjectBroadcast,
  deleteWorkChannelSubversionBroadcast,
  deployCustomBackgroundToFloorBroadcast,
  joinWorkChannelResponse,
  leaveWorkChannelResponse,
  previewWorkChannelSubversionResponse,
  previewWorkChannelTemplateResponse,
  restoreWorkChannelSubversionBroadcast,
  saveAsTemplateBroadcast,
  updateBaseWorkspaceBackgroundIdBroadcast,
  updateMultiWorkChannelObjectsBroadcast,
  updateWorkChannelObjectBroadcast,
} from "./actions";
import {
  FloorObject,
  WorkChannel,
  WorkChannelSubversion,
  WorkChannelSubversionDetail,
  WorkChannelTemplate,
  WorkChannelTemplateDetail,
} from "./reducer";
import { ImageWorkChannelObject, TextWorkChannelObject, WorkChannelObject } from "./types";

const WORK_CHANNEL_EVENT_TYPE_PREFIX = `workChannel/`;

const EVENT_TYPES = {
  OUT_JOIN_WORK_CHANNEL_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}join-request`,
  IN_JOIN_WORK_CHANNEL_RESPONSE: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}join-response`,

  OUT_LEAVE_WORK_CHANNEL_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}leave-request`,
  IN_LEAVE_WORK_CHANNEL_RESPONSE: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}leave-response`,

  OUT_CREATE_CUSTOM_OBJECT_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-custom-object-request`,
  IN_CREATE_CUSTOM_OBJECT_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-custom-object-broadcast`,

  OUT_DELETE_CUSTOM_OBJECT_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-custom-object-request`,
  IN_DELETE_CUSTOM_OBJECT_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-custom-object-broadcast`,

  OUT_CREATE_CUSTOM_BACKGROUND_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-custom-background-request`,
  IN_CREATE_CUSTOM_BACKGROUND_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-custom-background-broadcast`,

  OUT_DELETE_CUSTOM_BACKGROUND_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-custom-background-request`,
  IN_DELETE_CUSTOM_BACKGROUND_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-custom-background-broadcast`,

  OUT_WORK_CHANNEL_OBJECT_CREATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-work-channel-object-request`,
  IN_WORK_CHANNEL_OBJECT_CREATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-work-channel-object-broadcast`,

  OUT_WORK_CHANNEL_OBJECT_UPDATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}update-work-channel-object-request`,
  IN_WORK_CHANNEL_OBJECT_UPDATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}update-work-channel-object-broadcast`,

  OUT_MULTI_WORK_CHANNEL_OBJECTS_UPDATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}update-multi-work-channel-objects-request`,
  IN_MULTI_WORK_CHANNEL_OBJECTS_UPDATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}update-multi-work-channel-objects-broadcast`,

  OUT_MULTI_WORK_CHANNEL_OBJECTS_CREATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-multi-work-channel-objects-request`,
  IN_MULTI_WORK_CHANNEL_OBJECTS_CREATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-multi-work-channel-objects-broadcast`,

  OUT_MULTI_WORK_CHANNEL_OBJECTS_DELETE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-multi-work-channel-objects-request`,
  IN_MULTI_WORK_CHANNEL_OBJECTS_DELETE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-multi-work-channel-objects-broadcast`,

  OUT_WORK_CHANNEL_OBJECT_DELETE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-work-channel-object-request`,
  IN_WORK_CHANNEL_OBJECT_DELETE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-work-channel-object-broadcast`,

  OUT_BASE_WORKSPACE_BACKGROUND_UPDATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}update-base-workspace-background-request`,
  IN_BASE_WORKSPACE_BACKGROUND_UPDATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}update-base-workspace-background-broadcast`,

  OUT_WORK_CHANNEL_SUBVERSION_CREATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-work-channel-subversion-request`,
  IN_WORK_CHANNEL_SUBVERSION_CREATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}create-work-channel-subversion-broadcast`,

  OUT_WORK_CHANNEL_SUBVERSION_DELETE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-work-channel-subversion-request`,
  IN_WORK_CHANNEL_SUBVERSION_DELETE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-work-channel-subversion-broadcast`,

  OUT_WORK_CHANNEL_SUBVERSION_RESTORE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}restore-work-channel-subversion-request`,
  IN_WORK_CHANNEL_SUBVERSION_RESTORE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}restore-work-channel-subversion-broadcast`,

  OUT_WORK_CHANNEL_SUBVERSION_PREVIEW_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}preview-work-channel-subversion-request`,
  IN_WORK_CHANNEL_SUBVERSION_PREVIEW_RESPONSE: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}preview-work-channel-subversion-response`,

  OUT_WORK_CHANNEL_DEPLOY_CUSTOM_BACKGROUND_TO_FLOOR_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}deploy-custom-background-to-floor-request`,
  IN_WORK_CHANNEL_DEPLOY_CUSTOM_BACKGROUND_TO_FLOOR_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}deploy-custom-background-to-floor-broadcast`,

  OUT_WORK_CHANNEL_SAVE_AS_TEMPLATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}save-as-template-request`,
  IN_WORK_CHANNEL_SAVE_AS_TEMPLATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}save-as-template-broadcast`,

  OUT_WORK_CHANNEL_SAVE_CURRENT_STATUS_AS_TEMPLATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}save-current-status-as-template-request`,
  IN_WORK_CHANNEL_SAVE_CURRENT_STATUS_AS_TEMPLATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}save-current-status-as-template-broadcast`,

  OUT_WORK_CHANNEL_DELETE_TEMPLATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-template-request`,
  IN_WORK_CHANNEL_DELETE_TEMPLATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}delete-template-broadcast`,

  OUT_WORK_CHANNEL_APPLY_TEMPLATE_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}apply-template-request`,
  IN_WORK_CHANNEL_APPLY_TEMPLATE_BROADCAST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}apply-template-broadcast`,

  OUT_WORK_CHANNEL_TEMPLATE_PREVIEW_REQUEST: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}preview-work-channel-template-request`,
  IN_WORK_CHANNEL_TEMPLATE_PREVIEW_RESPONSE: `${WORK_CHANNEL_EVENT_TYPE_PREFIX}preview-work-channel-template-response`,
};

const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(WORK_CHANNEL_EVENT_TYPE_PREFIX)) {
    return;
  }

  const {
    newFloorObject,
    newCustomBackground,
    deletedFloorObjectId,
    deletedCustomBackgroundId,
    workChannel,
    workChannelObjects,
    object,
    objectId,
    workChannelId,
    workspaceBackgroundId,
    objects,
    history,
    id,
    userId,
    createdAt,
    subversionId,
    newSubversion,
    subversionDetail,
    objectIds,
    voiceChannelId,
    newTemplate,
    templateId,
    templateDetail,
  }: {
    newFloorObject: FloorObject;
    newCustomBackground: WorkspaceBackground;
    deletedFloorObjectId: number;
    deletedCustomBackgroundId: number;
    workChannel: WorkChannel;
    workChannelObjects: WorkChannelObject[];
    object: WorkChannelObject;
    objectId: string;
    workChannelId: number;
    workspaceBackgroundId: number;
    objects: WorkChannelObject[];
    history: {
      id: number;
      userId: number;
      type?: "undo" | "redo";
      oldData?: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"];
      newData?: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"];
      oldGroupData?: {
        objectId: string;
        properties: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"];
      }[];
      newGroupData?: {
        objectId: string;
        properties: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"];
      }[];
    };
    id: number;
    userId: number;
    createdAt: Date;
    subversionId: number;
    newSubversion: WorkChannelSubversion;
    subversionDetail: WorkChannelSubversionDetail;
    objectIds: string[];
    voiceChannelId: number;
    newTemplate: WorkChannelTemplate;
    templateId: number;
    templateDetail: WorkChannelTemplateDetail;
  } = payload;

  switch (type) {
    case EVENT_TYPES.IN_CREATE_CUSTOM_OBJECT_BROADCAST:
      store.dispatch(createCustomObjectBroadcast(newFloorObject));
      break;
    case EVENT_TYPES.IN_DELETE_CUSTOM_OBJECT_BROADCAST:
      store.dispatch(deleteCustomObjectBroadcast(deletedFloorObjectId));
      break;
    case EVENT_TYPES.IN_CREATE_CUSTOM_BACKGROUND_BROADCAST:
      store.dispatch(createCustomBackgroundBroadcast(newCustomBackground));
      break;
    case EVENT_TYPES.IN_DELETE_CUSTOM_BACKGROUND_BROADCAST:
      store.dispatch(deleteCustomBackgroundBroadcast(deletedCustomBackgroundId));
      break;
    case EVENT_TYPES.IN_JOIN_WORK_CHANNEL_RESPONSE:
      store.dispatch(joinWorkChannelResponse(workChannel, workChannelObjects));
      break;
    case EVENT_TYPES.IN_LEAVE_WORK_CHANNEL_RESPONSE:
      store.dispatch(leaveWorkChannelResponse(workChannelId));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_OBJECT_CREATE_BROADCAST:
      store.dispatch(createWorkChannelObjectBroadcast(object, history.id, history.userId, history.type));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_OBJECT_UPDATE_BROADCAST:
      store.dispatch(
        updateWorkChannelObjectBroadcast(
          object,
          history.id,
          history.userId,
          history.type,
          history.oldData,
          history.newData,
        ),
      );
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_OBJECT_DELETE_BROADCAST:
      store.dispatch(deleteWorkChannelObjectBroadcast(objectId, history.id, history.userId, history.type));
      break;
    case EVENT_TYPES.IN_BASE_WORKSPACE_BACKGROUND_UPDATE_BROADCAST:
      store.dispatch(
        updateBaseWorkspaceBackgroundIdBroadcast(workChannelId, workspaceBackgroundId, workChannelObjects),
      );
      break;

    case EVENT_TYPES.IN_MULTI_WORK_CHANNEL_OBJECTS_CREATE_BROADCAST:
      store.dispatch(createMultiWorkChannelObjectBroadcast(objects, history.id, history.userId, history.type));
      break;
    case EVENT_TYPES.IN_MULTI_WORK_CHANNEL_OBJECTS_DELETE_BROADCAST:
      store.dispatch(deleteMultiWorkChannelObjectBroadcast(objectIds, history.id, history.userId, history.type));
      break;
    case EVENT_TYPES.IN_MULTI_WORK_CHANNEL_OBJECTS_UPDATE_BROADCAST:
      store.dispatch(
        updateMultiWorkChannelObjectsBroadcast(
          objects,
          history.id,
          history.userId,
          history.type,
          history.oldGroupData,
          history.newGroupData,
        ),
      );
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_SUBVERSION_CREATE_BROADCAST:
      store.dispatch(addWorkChannelSubversionBroadcast(id, workChannelId, userId, createdAt));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_SUBVERSION_DELETE_BROADCAST:
      store.dispatch(deleteWorkChannelSubversionBroadcast(subversionId));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_SUBVERSION_RESTORE_BROADCAST:
      store.dispatch(restoreWorkChannelSubversionBroadcast(newSubversion, workChannel, workChannelObjects));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_SUBVERSION_PREVIEW_RESPONSE:
      store.dispatch(previewWorkChannelSubversionResponse(subversionDetail));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_DEPLOY_CUSTOM_BACKGROUND_TO_FLOOR_BROADCAST:
      store.dispatch(deployCustomBackgroundToFloorBroadcast(voiceChannelId, newCustomBackground));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_SAVE_AS_TEMPLATE_BROADCAST:
      store.dispatch(saveAsTemplateBroadcast(newTemplate));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_SAVE_CURRENT_STATUS_AS_TEMPLATE_BROADCAST:
      store.dispatch(saveAsTemplateBroadcast(newTemplate));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_DELETE_TEMPLATE_BROADCAST:
      store.dispatch(deletedWorkChannelTemplateBroadcast(templateId));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_APPLY_TEMPLATE_BROADCAST:
      store.dispatch(appliedWorkChannelTemplateBroadcast(workChannel, workChannelObjects));
      break;
    case EVENT_TYPES.IN_WORK_CHANNEL_TEMPLATE_PREVIEW_RESPONSE:
      store.dispatch(previewWorkChannelTemplateResponse(templateDetail));
      break;
    default:
      break;
  }
};

export { EVENT_TYPES as WORK_CHANNEL_EVENT_TYPES, handleIncomingMessage as handleIncomingWorkChannelMessage };
