import { getJson, postJson } from "../utils/network";
import {
  GET_GUEST_INFO,
  GET_IS_GUEST_ENABLED,
  GET_IS_PERMITTED_CHANNEL,
  GET_WAITING_SHORT_ID,
  GUEST_API_ROUTE,
  GUEST_JOIN_PATH,
} from "../constant";
import { CreateOrReadResponseBody } from ".";
import { Account } from "../store/account";
import { ChannelData, LanguageOption, MemberData } from "../store/types";
import { KnockState } from "../store/voiceChannels";
import { Toast } from "../components/antd/Toast";

type IsRequiredPasswordForGuestJoin = CreateOrReadResponseBody<{
  guestEnabled: boolean;
  passwordEnabled: boolean;
  name: string;
  isMeeting: boolean;
  languages: LanguageOption[];
}>;

export async function isRequiredPasswordForGuestJoin(shortId: string) {
  const path = `${GUEST_API_ROUTE}/${shortId}`;

  const { response } = await getJson<IsRequiredPasswordForGuestJoin>(path);

  return response;
}

type JoinIntoFloorResponse = CreateOrReadResponseBody<{
  isKnocking: boolean;
  userData: Account;
  memberData: MemberData[];
  voiceChannelId: number;
}>;

export async function joinIntoFloor(
  shortId: string,
  name: string,
  personalSpeakingLanguage: string,
  password?: string,
) {
  const path = GUEST_JOIN_PATH;

  const { response } = await postJson<JoinIntoFloorResponse>(path, {
    shortId,
    name,
    password,
    personalSpeakingLanguage,
  });

  return response;
}

type GetWaitingInfoResponse = CreateOrReadResponseBody<{
  account: Account;
  memberData: MemberData[];
  voiceChannels: ChannelData[];
  knock: KnockState;
  shortId: string | undefined;
}>;

export async function getWaitingRoomInfo(shortId: string) {
  const path = GET_GUEST_INFO(shortId);

  const { response } = await getJson<GetWaitingInfoResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type GetWaitingShortId = CreateOrReadResponseBody<{
  shortId?: string;
  joined?: string;
}>;

export async function getWaitingShortId() {
  const path = GET_WAITING_SHORT_ID;
  const { response } = await getJson<GetWaitingShortId>(path);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type GetIsGuestEnbaledChannelResponse = CreateOrReadResponseBody<{
  isEnabled: boolean;
}>;

export async function getIsGuestEnabledChannel(channelShortId: string) {
  const path = GET_IS_GUEST_ENABLED(channelShortId);
  const { response } = await getJson<GetIsGuestEnbaledChannelResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    Toast.error(response.errors[0].message);
    return false;
  }
}

type PostIsPermittedChannelRequest = {
  workspaceShortId?: string;
  channelShortId?: string;
};

type PostIsPermittedChannelResponse = CreateOrReadResponseBody<{
  isPermitted: boolean;
}>;

export async function postIsPermittedChannel(data: PostIsPermittedChannelRequest) {
  const path = GET_IS_PERMITTED_CHANNEL;
  const { response } = await postJson<PostIsPermittedChannelResponse>(path, data);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}
