import { WORK_CHANNEL_DEFAULT_GRID_GAP } from "../../constant";
import { WorkspaceBackground } from "../workspaceBackground";
import { WorkChannelAction, WorkChannelActionType } from "./actions";
import { Crop, Position, SelectionArea, Size, WorkChannelObject, WorkChannelObjectType } from "./types";

export interface FloorObject {
  id: number;
  workspaceId: number | null;
  isCustom: boolean;
  url: string;
  enName: string;
  jaName: string;
  koName: string;
  zhName: string;
  category: number;
  width: number;
  height: number;
}

export interface FloorObjectCategory {
  id: number;
  enName: string;
  jaName: string;
  koName: string;
  zhName: string;
}

export type EditActionType = "create" | "update" | "delete" | "create_group" | "update_group" | "delete_group";
export type Direction = "top" | "topLeft" | "topRight" | "left" | "right" | "bottom" | "bottomLeft" | "bottomRight";

const editHistoryStatus = ["active", "reverted"] as const;

export type EditHistoryStatus = typeof editHistoryStatus[number];

export interface WorkChannelEditHistory {
  historyId: number;
  editorId: number;
  objectId?: string;
  objectType?: WorkChannelObjectType;
  workChannelId?: number;
  ownerUserId?: number;
  status: EditHistoryStatus;
  actionType: EditActionType;
  oldProperties?: WorkChannelObject["properties"];
  newProperties?: WorkChannelObject["properties"];
  oldPermissions?: WorkChannelObject["permissions"];
  newPermissions?: WorkChannelObject["permissions"];
  oldGroupData?: {
    objectId: string;
    objectType: WorkChannelObjectType;
    workChannelId: number;
    ownerUserId: number;
    properties: WorkChannelObject["properties"];
    permissions?: WorkChannelObject["permissions"];
  }[];
  newGroupData?: {
    objectId: string;
    objectType: WorkChannelObjectType;
    workChannelId: number;
    ownerUserId: number;
    properties: WorkChannelObject["properties"];
    permissions?: WorkChannelObject["permissions"];
  }[];
}

export interface WorkChannel {
  id: number;
  shortId: string;
  workspaceId: number;
  voiceChannelId: number;
  baseWorkspaceBackgroundId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface WorkChannelSubversion {
  id: number;
  workChannelId: number;
  userId: number;
  createdAt: Date;
}

export interface WorkChannelSubversionDetail extends WorkChannelSubversion {
  baseWorkspaceBackgroundId: number;
  workChannelObjects: WorkChannelObject[];
  updatedAt: Date;
}

export type PanelType = "background" | "object" | "history";
export type PreviewType = "subversion" | "template" | undefined;
export type WorkChannelHistoryAcitonType = "undo" | "redo";

const templateTypes = ["room", "floor"] as const;
const workspacebackgroundTypes = ["room", "floor", "custom_room", "custom_floor"] as const;

export type TemplateType = typeof templateTypes[number];
export type WorkspaceBackgroundType = typeof workspacebackgroundTypes[number];

export interface WorkChannelTemplateDetail extends WorkChannelTemplate {
  workChannelObjects: WorkChannelObject[];
}

export interface WorkChannelTemplate {
  id: number;
  workspaceId: number | null;
  baseWorkspaceBackgroundId: number;
  thumbUrl: string;
  enName: string;
  jaName: string;
  koName: string;
  zhName: string;
  type: TemplateType;
  isDeployed: boolean;
}

export interface CroppingImageInfo {
  id: string;
  size: Size;
  position: Position;
  baseSize: Size;
  crop: Crop;
}

export const workChannelInitialState = {
  loading: true,
  floorObjectCategories: [] as FloorObjectCategory[],
  floorObjects: [] as FloorObject[],
  workChannelTemplates: [] as WorkChannelTemplate[],
  customBackgrounds: [] as WorkspaceBackground[],
  workChannel: undefined as WorkChannel | undefined,
  workChannelObjects: [] as WorkChannelObject[],
  isWorkChannelLoaded: false,
  selectedObjectId: undefined as number | "text" | undefined,
  selectedBaseWorkspaceBackgroundId: undefined as number | undefined,
  selectedWorkChannelObjectIds: [] as string[],
  currentPanel: "background" as PanelType,
  draggingAndResizingObject: undefined as { id: string; position: Position; size: Size } | undefined,
  multiDraggingDelta: undefined as { id: string; deltaX: number; deltaY: number } | undefined,
  nearBorderExtends: {
    vCenter: [] as number[],
    top: [] as number[],
    bottom: [] as number[],
    hCenter: [] as number[],
    left: [] as number[],
    right: [] as number[],
  },
  editHistories: [] as WorkChannelEditHistory[],
  workChannelSubversions: [] as WorkChannelSubversion[],
  isPreview: undefined as PreviewType,
  previewDetail: undefined as WorkChannelSubversionDetail | WorkChannelTemplateDetail | undefined,
  arrowKeyPress: { up: false, left: false, down: false, right: false, deltaX: 0, deltaY: 0 },
  isTextEditing: false,
  isFontSizeChanging: false,
  selectionArea: undefined as SelectionArea | undefined,
  isRedoingOrUndoing: false,
  isFontSizeUpdated: false,
  isSaveingAsTemplate: false,
  isDeploying: false,
  croppingImage: undefined as CroppingImageInfo | undefined,
  croppingDirection: undefined as Direction | undefined,
  isRedLineEnabled: true,
  isGridEnabled: false,
  gridGap: WORK_CHANNEL_DEFAULT_GRID_GAP.default,
  nearGrids: {
    top: undefined as number | undefined,
    bottom: undefined as number | undefined,
    left: undefined as number | undefined,
    right: undefined as number | undefined,
  },
};

export type WorkChannelState = typeof workChannelInitialState;

export function workChannelReducer(
  state: WorkChannelState = workChannelInitialState,
  action: WorkChannelAction,
): WorkChannelState {
  switch (action.type) {
    case WorkChannelActionType.START_LOADING:
      return { ...state, loading: true };
    case WorkChannelActionType.END_LOADING:
      return { ...state, loading: false };
    case WorkChannelActionType.SET_BASE_INFO:
      return {
        ...state,
        floorObjectCategories: action.payload.floorObjectCategories,
        floorObjects: action.payload.floorObjects,
        customBackgrounds: action.payload.customBackgrounds,
        workChannelSubversions: action.payload.workChannelSubversions,
        workChannelTemplates: action.payload.workChannelTemplates,
      };
    case WorkChannelActionType.CREATED_CUSTOM_OBJECT:
      return { ...state, floorObjects: [...state.floorObjects, action.payload.object] };
    case WorkChannelActionType.DELETED_CUSTOM_OBJECT:
      return { ...state, floorObjects: state.floorObjects.filter(el => el.id !== action.payload.objectId) };
    case WorkChannelActionType.CREATED_CUSTOM_BACKGROUND:
      return { ...state, customBackgrounds: [...state.customBackgrounds, action.payload.object] };
    case WorkChannelActionType.DELETED_CUSTOM_BACKGROUND:
      return {
        ...state,
        customBackgrounds: state.customBackgrounds.filter(el => el.id !== action.payload.workspaceBackgroundId),
      };
    case WorkChannelActionType.JOINED_WORK_CHANNEL:
      return {
        ...state,
        workChannel: action.payload.workChannel,
        workChannelObjects: action.payload.workChannelObjects,
        isWorkChannelLoaded: true,
      };
    case WorkChannelActionType.UPDATE_SELECTED_FLOOR_OBJECT_ID:
      return { ...state, selectedObjectId: action.payload.floorObjectId };
    case WorkChannelActionType.UPDATE_SELECTED_BASE_WORKSPACE_BACKGROUND_ID:
      return { ...state, selectedBaseWorkspaceBackgroundId: action.payload.workspaceBackgroundId };
    case WorkChannelActionType.UPDATE_CURRENT_PANEL:
      return { ...state, currentPanel: action.payload.activeKey };
    case WorkChannelActionType.ADD_WORK_CHANNEL_OBJECT:
      return { ...state, workChannelObjects: [...state.workChannelObjects, action.payload.object] };
    case WorkChannelActionType.UPDATE_WORK_CHANNEL_OBJECT:
      return {
        ...state,
        workChannelObjects: state.workChannelObjects.map(o =>
          o.id === action.payload.object.id ? action.payload.object : o,
        ),
      };
    case WorkChannelActionType.ADD_TO_SELECTED_WORK_CHANNEL_OBJECT_IDS:
      return {
        ...state,
        selectedWorkChannelObjectIds: [...new Set([...state.selectedWorkChannelObjectIds, action.payload.objectId])],
      };
    case WorkChannelActionType.UPDATE_SELECTED_WORK_CHANNEL_OBJECT_ID:
      return { ...state, selectedWorkChannelObjectIds: [action.payload.objectId] };
    case WorkChannelActionType.UPDATE_SELECTED_WORK_CHANNEL_OBJECT_IDS:
      return { ...state, selectedWorkChannelObjectIds: action.payload.objectIds };
    case WorkChannelActionType.CLEAR_SELECTED_WORK_CHANNEL_OBJECT_IDS:
      return { ...state, selectedWorkChannelObjectIds: [] };
    case WorkChannelActionType.DELETE_WORK_CHANNEL_OBJECT:
      return {
        ...state,
        workChannelObjects: state.workChannelObjects.filter(o => o.id !== action.payload.objectId),
      };
    case WorkChannelActionType.UPDATE_BASE_WORKSPACE_BACKGROUND:
      return {
        ...state,
        workChannel: state.workChannel
          ? { ...state.workChannel, baseWorkspaceBackgroundId: action.payload.workspaceBackgroundId }
          : undefined,
        workChannelObjects: action.payload.workChannelObjects,
      };
    case WorkChannelActionType.UPDATE_DRAGGING_AND_RESIZING_OBJECT:
      return { ...state, draggingAndResizingObject: action.payload.detail };
    case WorkChannelActionType.UPDATE_NEAR_BORDER_EXTENDS:
      return {
        ...state,
        nearBorderExtends: {
          vCenter: action.payload.vCenter ?? [],
          top: action.payload.top ?? [],
          bottom: action.payload.bottom ?? [],
          hCenter: action.payload.hCenter ?? [],
          left: action.payload.left ?? [],
          right: action.payload.right ?? [],
        },
      };
    case WorkChannelActionType.UPDATE_MULTI_WORK_CHANNEL_OBJECTS:
      return {
        ...state,
        workChannelObjects: state.workChannelObjects.map(o =>
          action.payload.objects.some(el => el.id === o.id) ? action.payload.objects.find(el => el.id === o.id)! : o,
        ),
      };
    case WorkChannelActionType.ADD_EDIT_HISTORY:
      return {
        ...state,
        editHistories: [action.payload.newAction, ...state.editHistories.filter(el => el.status === "active")],
      };
    case WorkChannelActionType.UPDATE_EDIT_HISTORY:
      return {
        ...state,
        editHistories: state.editHistories.map(el =>
          el.historyId === action.payload.id
            ? { ...el, status: action.payload.type === "undo" ? "reverted" : "active" }
            : el,
        ),
      };
    case WorkChannelActionType.ADD_WORK_CHANNEL_SUBVERSION:
      return { ...state, workChannelSubversions: [action.payload.newSubversion, ...state.workChannelSubversions] };
    case WorkChannelActionType.DELETE_WORK_CHANNEL_SUBVERSION:
      return {
        ...state,
        workChannelSubversions: state.workChannelSubversions.filter(el => el.id !== action.payload.subversionId),
      };
    case WorkChannelActionType.RESTORE_WORK_CHANNEL_SUBVERSION:
      return {
        ...state,
        workChannelSubversions: action.payload.newSubversion
          ? [action.payload.newSubversion, ...state.workChannelSubversions]
          : state.workChannelSubversions,
        workChannelObjects: action.payload.workChannelObjects,
        workChannel: action.payload.workChannel,
        loading: false,
        selectedObjectId: undefined,
        selectedWorkChannelObjectIds: [],
        editHistories: [],
      };
    case WorkChannelActionType.PREVIEW_WORK_CHANNEL_SUBVERSION:
      return { ...state, previewDetail: action.payload.subversionDetail };
    case WorkChannelActionType.UPDATE_IS_PREVIEW:
      return { ...state, isPreview: action.payload.state, selectedWorkChannelObjectIds: [] };
    case WorkChannelActionType.UPDATE_ARROW_KEY_PRESS:
      return {
        ...state,
        arrowKeyPress: {
          ...state.arrowKeyPress,
          ...action.payload.keyDetail,
          deltaX: state.arrowKeyPress.deltaX + action.payload.keyDetail.deltaX,
          deltaY: state.arrowKeyPress.deltaY + action.payload.keyDetail.deltaY,
        },
      };
    case WorkChannelActionType.CLEARE_ARROW_KEY_PRESS_DELTA:
      return { ...state, arrowKeyPress: { ...state.arrowKeyPress, deltaX: 0, deltaY: 0 } };
    case WorkChannelActionType.UPDATE_MULTI_DRAGGING_DELTA:
      return { ...state, multiDraggingDelta: action.payload.draggingDelta };
    case WorkChannelActionType.APPEND_MULTI_DRAGGING_DELTA:
      return {
        ...state,
        multiDraggingDelta: {
          id: action.payload.draggingDelta.id,
          deltaX: state.multiDraggingDelta?.deltaX
            ? state.multiDraggingDelta?.deltaX + action.payload.draggingDelta.deltaX
            : action.payload.draggingDelta.deltaX,
          deltaY: state.multiDraggingDelta?.deltaY
            ? state.multiDraggingDelta?.deltaY + action.payload.draggingDelta.deltaY
            : action.payload.draggingDelta.deltaY,
        },
      };
    case WorkChannelActionType.FINISH_MULTI_DRAGGING:
      return {
        ...state,
        workChannelObjects: state.workChannelObjects.map(el => {
          const newData = action.payload.updatedObjects.find(uo => uo.id === el.id);

          return newData ? newData : el;
        }),
        multiDraggingDelta: undefined,
      };
    case WorkChannelActionType.INIT_WORK_CHANNEL_STATE:
      return { ...workChannelInitialState };
    case WorkChannelActionType.ADD_MULTI_WORK_CHANNEL_OBJECTS:
      return { ...state, workChannelObjects: [...state.workChannelObjects, ...action.payload.newObjects] };
    case WorkChannelActionType.DELETE_MULTI_WORK_CHANNEL_OBJECTS:
      return {
        ...state,
        workChannelObjects: state.workChannelObjects.filter(el => !action.payload.objectIds.includes(el.id)),
      };
    case WorkChannelActionType.IS_TEXT_EDITING:
      return { ...state, isTextEditing: action.payload.isTextEditing };
    case WorkChannelActionType.IS_FONT_SIZE_CHANGING:
      return { ...state, isFontSizeChanging: action.payload.isFontSizeChanging };
    case WorkChannelActionType.UPDATE_SELECTION_AREA:
      return { ...state, selectionArea: action.payload.selectionArea };
    case WorkChannelActionType.APPEND_SELECTION_AREA_SIZE:
      return {
        ...state,
        selectionArea: state.selectionArea
          ? {
              ...state.selectionArea,
              width: state.selectionArea.width + action.payload.deltaSize.width,
              height: state.selectionArea.height + action.payload.deltaSize.height,
            }
          : undefined,
      };
    case WorkChannelActionType.SAVED_NEW_WORK_CHANNEL_TEMPLATE:
      return {
        ...state,
        workChannelTemplates: [...state.workChannelTemplates, action.payload.newTemplate],
      };
    case WorkChannelActionType.DELETE_WORK_CHANNEL_TEMPLATE:
      return {
        ...state,
        workChannelTemplates: state.workChannelTemplates.filter(el => el.id !== action.payload.templateId),
      };
    case WorkChannelActionType.APPLY_WORK_CHANNEL_TEMPLATE:
      return {
        ...state,
        workChannelObjects: action.payload.workChannelObjects,
        workChannel: action.payload.workChannel,
        loading: false,
        selectedObjectId: undefined,
        selectedWorkChannelObjectIds: [],
        editHistories: [],
      };
    case WorkChannelActionType.PREVIEW_WORK_CHANNEL_TEMPLATE:
      return { ...state, previewDetail: action.payload.templateDetail };
    case WorkChannelActionType.UPDATE_IS_REDOING_OR_UNDOING:
      return { ...state, isRedoingOrUndoing: action.payload.status };
    case WorkChannelActionType.UPDATE_IS_FONT_SIZE_UPDATED:
      return { ...state, isFontSizeUpdated: action.payload.state };
    case WorkChannelActionType.UPDATE_CROPPING_IMAGE:
      return { ...state, croppingImage: action.payload.data };
    case WorkChannelActionType.CLEAR_CROPPING_IMAGE:
      return { ...state, croppingImage: undefined };
    case WorkChannelActionType.UPDATE_IS_SAVING_AS_TEMPLATE:
      return { ...state, isSaveingAsTemplate: action.payload.state };
    case WorkChannelActionType.UPDATE_IS_DEPLOYING:
      return { ...state, isDeploying: action.payload.state };
    case WorkChannelActionType.UPDATE_CROPPING_DIRECTION:
      return { ...state, croppingDirection: action.payload.direction };
    case WorkChannelActionType.UPDATE_IS_RED_LINE_ENABLED:
      return { ...state, isRedLineEnabled: action.payload.state };
    case WorkChannelActionType.UPDATE_IS_GRID_ENABLED:
      return { ...state, isGridEnabled: action.payload.state };
    case WorkChannelActionType.UPDATE_GRID_GAP:
      return { ...state, gridGap: action.payload.state };
    case WorkChannelActionType.UPDATE_NEAR_GRIDS:
      return {
        ...state,
        nearGrids: {
          top: action.payload.top,
          bottom: action.payload.bottom,
          left: action.payload.left,
          right: action.payload.right,
        },
      };
    default:
      return state;
  }
}
