import {
  canceledRecordAudioExtraction,
  createdAudioExtractionTranscript,
  createdEgressRecordingPath,
  gotEgressError,
  gotEgressRecordingLimitReached,
  gotEgressRoomToken,
  setRecordingStatus,
  updatedAudioExtractionState,
  updatedRecordAudioExtractionState,
} from "./actions";
import { RecordingStatusInWorkspace } from "./reducer";

export type EgressErrorStatus = "notAvailble" | "";

const AUDIO_EXTRACTION_EVENT_TYPE_PREFIX = "audioExtraction/";

export const AUDIO_EXTRACTION_EVENT_TYPES = {
  REQUEST_UPDATE_AUDIO_EXTRACTION: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}request-update-audio-extraction`,
  RESPONSE_UPDATE_AUDIO_EXTRACTION: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-update-audio-extraction`,
  RESPONSE_CREATE_AUDIO_EXTRACTION_TRANSCRIPT: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-create-audio-extraction-transcript`,
  REQUEST_UPDATE_RECORD_AUDIO_EXTRACTION: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}request-update-record-audio-extraction`,
  RESPONSE_UPDATE_RECORD_AUDIO_EXTRACTION: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-update-record-audio-extraction`,
  RESPONSE_EGRESS_RECORDING_PATH: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-egress-recording-path`,
  RESPONSE_EGRESS_ERROR: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-egress-error`,
  REQUEST_GET_EGRESS_ROOM_TOKEN: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}request-get-egress-room-token`,
  RESPONSE_GET_EGRESS_ROOM_TOKEN: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-get-egress-room-token`,
  EGRESS_RECORDING_STATUS_IN_WORKSPACE: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}egress-recording-status-in-workspace`,
  REQUEST_CANCEL_RECORD_AUDIO_EXTRACTION: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}request-cancel-record-audio-extraction`,
  RESPONSE_CANCEL_RECORD_AUDIO_EXTRACTION: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}response-cancel-record-audio-extraction`,
  EGRESS_RECORDING_LIMIT_REACHED: `${AUDIO_EXTRACTION_EVENT_TYPE_PREFIX}egress-recording-limit-reached`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(AUDIO_EXTRACTION_EVENT_TYPE_PREFIX)) {
    return;
  }

  const {
    workspaceId,
    voiceChannelId,
    isExtractingAudio,
    transcriptId,
    leftChannel,
    isRecordingAudioExtraction,
    recordingPath,
    accessToken,
    egressToken,
    room,
    recordingStatusInWorkspace,
    errorStatus,
  }: {
    workspaceId: number;
    voiceChannelId: number;
    isExtractingAudio: boolean;
    transcriptId?: number;
    leftChannel: boolean;
    isRecordingAudioExtraction: boolean;
    recordingPath: string;
    accessToken?: string | null;
    egressToken: string;
    room: string;
    recordingStatusInWorkspace: RecordingStatusInWorkspace;
    errorStatus?: EgressErrorStatus;
  } = payload;

  switch (type) {
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_UPDATE_AUDIO_EXTRACTION:
      store.dispatch(updatedAudioExtractionState(voiceChannelId, isExtractingAudio, leftChannel));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_CREATE_AUDIO_EXTRACTION_TRANSCRIPT:
      store.dispatch(createdAudioExtractionTranscript(workspaceId, voiceChannelId, transcriptId));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_UPDATE_RECORD_AUDIO_EXTRACTION:
      store.dispatch(updatedRecordAudioExtractionState(isRecordingAudioExtraction, accessToken));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_EGRESS_RECORDING_PATH:
      store.dispatch(createdEgressRecordingPath(recordingPath));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_EGRESS_ERROR:
      store.dispatch(gotEgressError(accessToken, errorStatus));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_GET_EGRESS_ROOM_TOKEN:
      store.dispatch(gotEgressRoomToken(egressToken, room));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.EGRESS_RECORDING_STATUS_IN_WORKSPACE:
      store.dispatch(setRecordingStatus(recordingStatusInWorkspace));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.RESPONSE_CANCEL_RECORD_AUDIO_EXTRACTION:
      store.dispatch(canceledRecordAudioExtraction(accessToken));
      break;
    case AUDIO_EXTRACTION_EVENT_TYPES.EGRESS_RECORDING_LIMIT_REACHED:
      store.dispatch(gotEgressRecordingLimitReached(accessToken));
      break;
    default:
      break;
  }
};
