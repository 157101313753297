import { ActionsUnion, createAction } from "../../utils/redux";
import { NotificationContent } from "./types";
import { AppThunk } from "../types";
import { log, LogCategory } from "../../utils/log";
import { sendMessage } from "../socket";
import { NOTIFICATION_EVENT_TYPES } from "./middleware";
import { NotificationAction } from "./types";
import { zohoToggleWindow } from "../../utils/zoho";

enum Types {
  SHOW = "appNotification/SHOW",
  HIDE = "appNotification/HIDE",
}

type Actions = ActionsUnion<typeof actions>;

const actions = {
  show: (content: NotificationContent) => createAction(Types.SHOW, { content }),
  hide: (id: string) => createAction(Types.HIDE, { id }),
};

export type { Actions as AppNotificationActions };
export { Types as AppNotificationActionsTypes };

export function showNotification(content: NotificationContent): AppThunk {
  return async dispatch => {
    log(LogCategory.InAppNotification, "Show notification", content);
    dispatch(actions.show(content));
  };
}

export function dismissNotification(notification: NotificationContent): AppThunk {
  return async dispatch => {
    log(LogCategory.InAppNotification, "Dismiss notification", notification);
    dispatch(sendMessage(NOTIFICATION_EVENT_TYPES.HIDE_NOTIFICATION, { notificationId: notification.id }));
  };
}

export function hideNotification(id: string): AppThunk {
  return async dispatch => {
    log(LogCategory.InAppNotification, "Hide notification", id);
    dispatch(actions.hide(id));
  };
}

export function executeNotificationAction(notification: NotificationContent, action: NotificationAction): AppThunk {
  return async dispatch => {
    log(LogCategory.InAppNotification, "Calling action", action);

    dispatch(dismissNotification(notification));
    if (action.type === "dismiss") {
      return;
    }

    switch (notification.type) {
      case "workspaceLimitReached":
        dispatch(workspaceLimitReached(notification, action));
        break;
      case "incomingCall":
        dispatch(incomingCall(notification, action));
        break;
      case "requestChangeMute":
      case "requestChangeSilence":
        dispatch(requestChangeMuteSilence(notification, action));
        break;
      case "incomingCallReminder":
        break;
      default:
        throw new Error("Unknown notification type");
    }
  };
}

export function workspaceLimitReached(notification: NotificationContent, action: NotificationAction): AppThunk {
  return async dispatch => {
    switch (action.action) {
      case "upgrade":
        zohoToggleWindow("show");
        break;
      default:
        throw new Error(`Unknown notification action '${action.action}' on '${notification.type}' notifications.`);
    }
  };
}

export function incomingCall(notification: NotificationContent, action: NotificationAction): AppThunk {
  return async dispatch => {
    switch (action.action) {
      case "join-now":
        // TODO: move incoming call notifications and actions handling to here
        // zohoToggleWindow("show");
        break;
      case "join-later":
        // TODO: move incoming call notifications and actions handling to here
        // zohoToggleWindow("show");
        break;
      default:
        throw new Error(`Unknown notification action '${action.action}' on '${notification.type}' notifications.`);
    }
  };
}

export function requestChangeMuteSilence(notification: NotificationContent, action: NotificationAction): AppThunk {
  return async dispatch => {
    switch (action.action) {
      case "accept":
        // TODO: move mute/silence notifications and actions handling to here
        // zohoToggleWindow("show");
        break;
      default:
        throw new Error(`Unknown notification action '${action.action}' on '${notification.type}' notifications.`);
    }
  };
}
