import logError from "./logError";

export async function listDevices(kind: MediaDeviceKind) {
  try {
    const uniqueDevices = new Map<string, MediaDeviceInfo>();
    const allDevices = await navigator.mediaDevices.enumerateDevices();

    for (const device of allDevices) {
      if (device.kind !== kind) continue;

      // remove virtual device (e.g. BlackHole, Teams, Zoom, etc.)
      if (device.label.includes("(Virtual)")) continue;

      const duplicated =
        device.deviceId !== "default" &&
        Array.from(uniqueDevices.values()).some(el => el.groupId === device.groupId && el.deviceId !== "default");

      if (!duplicated) {
        uniqueDevices.set(device.deviceId, device);
      }
    }

    return [...uniqueDevices.values()];
  } catch (err) {
    if (err instanceof Error) {
      logError(err.message);
    }

    return [];
  }
}

export async function checkDeviceAvailable(deviceId: string) {
  const allDevices = await navigator.mediaDevices.enumerateDevices();

  return allDevices.some(el => el.deviceId === deviceId || el.kind === "videoinput");
}

export interface SelectOptions {
  value: string;
  label: string;
}

export async function listDevicesOptions(kind: MediaDeviceKind): Promise<SelectOptions[]> {
  const findDeviceByKind = await listDevices(kind);

  return findDeviceByKind.map(el => ({
    label: el.label,
    value: el.deviceId,
  }));
}

export async function getMediaDeviceById(
  deviceId: string,
  kind: MediaDeviceKind,
): Promise<MediaDeviceInfo | undefined> {
  const findDeviceByKind = await listDevices(kind);

  return findDeviceByKind.find(el => el.deviceId === deviceId);
}
