import { activeAppInitialState } from "./activeApp/reducer";
import { activityDetectionInitialState } from "./activityDetection/reducer";

export type { State as BackgroundChecksState };
export { initialState as backgroundChecksInitialState };

const initialState = {
  activeApp: activeAppInitialState,
  activityDetection: activityDetectionInitialState,
};

type State = typeof initialState;
