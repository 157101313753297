import { newWorkspace, switchedWorkspace, workspaceUpdated, leftWorkspace, updateWorkspaceLogoUrl } from "../workspace";
import {
  allowdedWorkspace,
  deletedWorkspace,
  joinedWorkspace,
  updatedLanguageOptions,
  updateWorkspaceEnableConsecutiveTranslation,
  updateWorkspacePhrase,
  logoutIpValidation,
  updateWorkspaceInvitationList,
  updateWorkspaceInvitation,
  updatePlanDetailsAction,
  updatedWorkspaceVpSttServer,
  updatedWorkspaceTranslationFrequency,
} from "./actions";
import { stopWorkingRequest } from "../timeTracker/actions";
import { reloadApp } from "../app";
import { updateUserHasMobileApp, updatedUserEnableWebApp } from "../users";
import localData from "../../localStorageKeys";
import { getDeviceInfo } from "../../utils/helpers";
import { clearMessages, updateVpSttServer } from "../textChannel/actions";

const WORKSPACE_EVENT_TYPE_PREFIX = "workspace/";

export const WORKSPACE_EVENT_TYPES = {
  NEW_WORKSPACE: `${WORKSPACE_EVENT_TYPE_PREFIX}new`,
  OUT_WORKSPACE_INITIAL_LOAD_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}initial-load-request`,
  IN_WORKSPACE_INITIAL_LOAD_RESPONSE: `${WORKSPACE_EVENT_TYPE_PREFIX}initial-load-response`,
  IN_JOIN_WORKSPACE_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}join-broadcast`,
  UPDATE_WORKSPACE: `${WORKSPACE_EVENT_TYPE_PREFIX}update`,
  LEAVE_WORKSPACE: `${WORKSPACE_EVENT_TYPE_PREFIX}leave`,
  WORKSPACE_LOGO: `${WORKSPACE_EVENT_TYPE_PREFIX}logo`,
  WORKSPACE_UPDATE_LOCATION: `${WORKSPACE_EVENT_TYPE_PREFIX}update-location`,
  WORKSPACE_UPDATE_VP_STT_SERVER: `${WORKSPACE_EVENT_TYPE_PREFIX}update-vp-stt-server`,
  WORKSPACE_USER_UPDATE_CHATWORK_GROUP: `${WORKSPACE_EVENT_TYPE_PREFIX}update-user-chatwork-group`,
  IN_WORKSPACE_DELETE_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}delete-broadcast`,
  OUT_UPDATE_ICE_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-ice-request`,
  IN_UPDATE_ICE_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-ice-broadcast`,
  OUT_UPDATE_LANG_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-lang-request`,
  IN_UPDATE_LANG_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-lang-broadcast`,
  OUT_UPDATE_WORKSPACE_PHRASES_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-stt-phrase-request`,
  IN_UPDATE_WORKSPACE_PHRASES_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-stt-phrase-broadcast`,
  UPDATE_ENABLE_CONSECUTIVE_TRANSLATION: `${WORKSPACE_EVENT_TYPE_PREFIX}update-consecutive-translation`,
  WORKSPACE_USER_ENABLE_WEB_APP_UPDATE_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-member-enable-app`,
  WORKSPACE_SWITCH_EVENT_ON_IP_RESTRICTION: `${WORKSPACE_EVENT_TYPE_PREFIX}update-switch-workspace-ip-restriction`,
  WORKSPACE_LOGOUT_IP_RESTRICTION: `${WORKSPACE_EVENT_TYPE_PREFIX}logout-ip-validation`,
  WORKSPACE_UPDATE_INVITATION_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-invitation-request`,
  WORKSPACE_DELETE_INVITATION_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}delete-invitation-response`,
  WORKSPACE_UPDATE_INVITATION_LIST_RESPONSE: `${WORKSPACE_EVENT_TYPE_PREFIX}update-invitation-list-response`,
  WORKSPACE_UPDATE_INVITATION_RESPONSE: `${WORKSPACE_EVENT_TYPE_PREFIX}update-invitation-response`,
  WORKSPACE_UPDATE_HAS_MOBILE_APP_BROADCAST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-has-mobile-app-broadcast`,
  WORKSPACE_PLAN_UPDATE: `${WORKSPACE_EVENT_TYPE_PREFIX}plan-update`,
  WORKSPACE_UPDATE_TRANSLATION_FREQUENCY_REQUEST: `${WORKSPACE_EVENT_TYPE_PREFIX}update-translation-frequency-request`,
  WORKSPACE_UPDATE_TRANSLATION_FREQUENCY_RESPONSE: `${WORKSPACE_EVENT_TYPE_PREFIX}update-translation-frequency-response`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(WORKSPACE_EVENT_TYPE_PREFIX)) {
    return;
  }

  const {
    workspace,
    workspaceId,
    workspaceName,
    workspacePhrases,
    userId,
    workspaceUsers,
    workspaceListeners,
    voiceChannels,
    logoUrl,
    user,
    timeTrackerSlackChannelId,
    chatworkGroupId,
    idleDuration,
    enableActiveApp,
    enableTimeTracking,
    enableWebApp,
    languageOptions,
    enableTextChatSaving,
    workspaceUserSttSettings,
    voiceChannelSttSettings,
    audibleRange,
    userVideoCircleSize,
    broadcasterVideoCircleSize,
    enableIndirectTranslation,
    screenshareVisibility,
    language,
    phrases,
    isConsecutiveTranslation,
    userAvatarCircleSize,
    balloonFontSize,
    displayNameFontSize,
    invitees,
    invitee,
    message,
    enableBusyStatusReset,
    hasMobileApp,
    enableGuestWebApp,
    enableSpeechToText,
    speechToTextLanguage,
    enableMicForMeeting,
    enableWebCamForMeeting,
    enableAutoMeetingRecording,
    enableDeleteAllData,
    enableRectangularView,
    workspacePlan,
    enableEmailTokenFlag,
    transcriptSlackChannelId,
    meetingApps,
    transcriptChatworkGroupId,
    transcriptDiscordUrl,
    timerDiscordUrl,
    stripeCustomerId,
    invitedEnv,
    translationDictionary,
    vpSttServerId,
    vpHost,
    jwtToken,
    translationFrequency,
  } = payload;

  switch (type) {
    case WORKSPACE_EVENT_TYPES.NEW_WORKSPACE:
      store.dispatch(newWorkspace(workspace));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_USER_ENABLE_WEB_APP_UPDATE_BROADCAST:
      store.dispatch(updatedUserEnableWebApp(payload));
      const { platform } = getDeviceInfo();
      const isDesktop = platform === "desktop";

      if (!payload.value) {
        localData.set("openInBrowser", "false");
        if (!window.electron && isDesktop) {
          window.location.reload();
        }
      }

      break;
    case WORKSPACE_EVENT_TYPES.IN_WORKSPACE_INITIAL_LOAD_RESPONSE:
      store.dispatch(
        switchedWorkspace(
          workspaceId,
          workspaceUsers,
          workspaceListeners,
          voiceChannels,
          workspacePhrases,
          message,
          invitedEnv,
        ),
      );
      break;
    case WORKSPACE_EVENT_TYPES.IN_WORKSPACE_DELETE_BROADCAST:
      store.dispatch(deletedWorkspace(workspaceId));
      break;
    case WORKSPACE_EVENT_TYPES.UPDATE_WORKSPACE:
      if (enableTimeTracking === false) {
        store.dispatch(
          stopWorkingRequest(`Workspace Configuration is Updated: enableTimeTracking=${enableTimeTracking}`),
        );
      }

      if (enableTextChatSaving === false) {
        store.dispatch(clearMessages());
      }

      store.dispatch(
        workspaceUpdated(
          workspaceId,
          workspaceName,
          timeTrackerSlackChannelId,
          chatworkGroupId,
          idleDuration,
          enableActiveApp,
          enableTimeTracking,
          enableWebApp,
          enableTextChatSaving,
          audibleRange,
          userVideoCircleSize,
          broadcasterVideoCircleSize,
          enableIndirectTranslation,
          screenshareVisibility,
          userAvatarCircleSize,
          balloonFontSize,
          displayNameFontSize,
          enableBusyStatusReset,
          enableGuestWebApp,
          enableSpeechToText,
          speechToTextLanguage,
          enableMicForMeeting,
          enableWebCamForMeeting,
          enableAutoMeetingRecording,
          enableDeleteAllData,
          enableRectangularView,
          enableEmailTokenFlag,
          transcriptSlackChannelId,
          meetingApps,
          transcriptChatworkGroupId,
          transcriptDiscordUrl,
          timerDiscordUrl,
        ),
      );
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_LOGOUT_IP_RESTRICTION:
      store.dispatch(logoutIpValidation(payload));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_SWITCH_EVENT_ON_IP_RESTRICTION:
      store.dispatch(allowdedWorkspace(payload));
      break;
    case WORKSPACE_EVENT_TYPES.LEAVE_WORKSPACE:
      store.dispatch(leftWorkspace(userId, workspaceId));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_LOGO:
      store.dispatch(updateWorkspaceLogoUrl({ workspaceId, logoUrl }));
      break;
    case WORKSPACE_EVENT_TYPES.IN_JOIN_WORKSPACE_BROADCAST:
      store.dispatch(joinedWorkspace(user));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_LOCATION:
    case WORKSPACE_EVENT_TYPES.IN_UPDATE_ICE_BROADCAST:
      store.dispatch(reloadApp());
      break;
    case WORKSPACE_EVENT_TYPES.IN_UPDATE_LANG_BROADCAST:
      store.dispatch(
        updatedLanguageOptions(workspaceId, languageOptions, workspaceUserSttSettings, voiceChannelSttSettings),
      );
      break;
    case WORKSPACE_EVENT_TYPES.IN_UPDATE_WORKSPACE_PHRASES_BROADCAST:
      store.dispatch(updateWorkspacePhrase(workspaceId, language, phrases, translationDictionary));
      break;
    case WORKSPACE_EVENT_TYPES.UPDATE_ENABLE_CONSECUTIVE_TRANSLATION:
      store.dispatch(
        updateWorkspaceEnableConsecutiveTranslation({
          workspaceId: workspaceId,
          isConsecutiveTranslation: isConsecutiveTranslation,
        }),
      );
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_INVITATION_LIST_RESPONSE:
      store.dispatch(
        updateWorkspaceInvitationList({
          workspaceId,
          invitees,
        }),
      );
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_INVITATION_RESPONSE:
      store.dispatch(updateWorkspaceInvitation({ workspaceId, invitee }));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_HAS_MOBILE_APP_BROADCAST:
      store.dispatch(updateUserHasMobileApp({ workspaceId, userId, hasMobileApp }));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_PLAN_UPDATE:
      store.dispatch(
        updatePlanDetailsAction({ workspaceId, planDetails: workspacePlan, stripeCustomerId: stripeCustomerId }),
      );
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_VP_STT_SERVER:
      store.dispatch(updatedWorkspaceVpSttServer({ workspaceId, vpSttServerId }));
      store.dispatch(updateVpSttServer({ vpHost, jwtToken }));
      break;
    case WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_TRANSLATION_FREQUENCY_RESPONSE:
      store.dispatch(updatedWorkspaceTranslationFrequency({ workspaceId, translationFrequency }));
      break;
  }
};
