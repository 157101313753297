import React from "react";
import { FormattedMessage } from "react-intl";
import { nanoid } from "nanoid";
import { getSocket, sendMessage } from "../socket";
import { ChatMessage, SpeechRecognition } from "./types";
import { TEXT_CHANNEL_EVENT_TYPES } from "./middleware";
import {
  hasTextChannel,
  getTextChannelId,
  getTextChannelWorkspaceId,
  getChatBoxOption,
  getTextChannelState,
  getIsReachedSttMaxUsage,
  getResizeEvent,
  getKeyboardActive,
  getTextChannelMessages,
} from "./selectors";
import { fetchSpeechToTextMinutesSpent, getCurrentWorkspace, getSpeechToTextCurrentUsage } from "../workspace";
import { AppThunk, Translator } from "../types";
import { intl } from "../../i18n";
import { postJson } from "../../utils/network";
import {
  DEFAULT_TRANSLATION_FREQUENCY,
  HIGH_TRANSLATION_FREQUENCY,
  LOW_TRANSLATION_FREQUENCY,
  STT_GET_AUTHORIZATION,
  VOICEPINGBOT,
} from "../../constant";
import { getMyMember, isMeManager } from "../users";
import { log, LogCategory } from "../../utils/log";
import {
  getCurrentVoiceChannel,
  getMyUsingLanguages,
  getOnlineUserIdsOnFloor,
  getOnlineUserIdsOnSameFloor,
  getTextChatAllowMembers,
  getUsingLanguageInCurrentVoiceChannel,
} from "../voiceChannels/selectors";
import { notification } from "../../components/antd/Notification";
import { TextChatReceiverOptionIds } from "../../screens/Dashboard/TextChat/ReceiverInput";
import { ChatIconOutlined } from "../../components/icons/material";
import {
  getCurrentWorkspaceId,
  getOnlineUserById,
  getOnlineWorkspaceListeners,
  getOnlineWorkspaceUsers,
} from "../users/selectors";
import { getCurrentWorkspaceLanguages, isTextChatSavingEnabled } from "../workspace/selectors";
import { localDataTextChatHistory } from "../../localStorageKeys";
import {
  addNotificationKey,
  addNotificationOnPIP,
  getNotificationsKey,
  removeNotificationKey,
  removeNotificationOnPIP,
  showNotificationWindow,
} from "../notificationsWindow";
import { URLify } from "../../components/core/URLify";
import { sendElectronNotification } from "../../electron/sendNotification";
import { getSubscribers } from "../livekit";
import { getUserInfoFromParticipantId, openURL } from "../../utils/helpers";
import { getCurrentEnvironment, getProtocol } from "../../utils/environment";
import { generateBillingWithParam } from "../../routes";
import { checkIsGuest, checkIsListener, getInvitedEnv, getMyShortId } from "../account";
import { getIsExtractingAudio, getIsRecordingAudioExtraction } from "../audioExtraction";
import {
  getIsOpeningTextChatWindow,
  getIsSubtitleOpened,
  getOpenTextChatInAnotherWindow,
  hideTextChatWindow,
  setOpenTextChatInAnotherWindow,
  showTextChatWindow,
} from "../app";
import { channels } from "../../electron/channels";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { emojiReactionKeys, EmojiReactionsType } from "../../components/core/Emoji";
import { zohoToggleWindow } from "../../utils/zoho";

const env = getCurrentEnvironment();

export enum TextChannelActions {
  SET_TEXT_CHANNEL = "textChannel/SET_TEXT_CHANNEL",
  GET_MESSAGES = "textChannel/GET_MESSAGES",
  GOT_MESSAGES = "textChannel/GOT_MESSAGES",
  NEW_MESSAGE = "textChannel/NEW_MESSAGE",
  TRANSLATED_MESSAGE = "textChannel/TRANSLATED_MESSAGE",
  SPEECH_TO_TEXT_INIT = "textChannel/SPEECH_TO_TEXT_INIT",
  UPDATE_VP_STT_SERVER = "textChannel/UPDATE_VP_STT_SERVER",
  SPEECH_TO_TEXT_STOP = "textChannel/SPEECH_TO_TEXT_STOP",
  CLEAR_UNREAD_MESSAGES = "textChannel/CLEAR_UNREAD_MESSAGES",
  CLEAR_SELECTED_UNREAD_MESSAGES = "textChannel/CLEAR_SELECTED_UNREAD_MESSAGES",
  SHOW_CHAT_BOX = "textChannel/SHOW_CHAT_BOX",
  SET_DM_USERS = "textChannel/SET_DM_USER",
  SET_FOCUS_CHAT_BOX = "textChannel/SET_FOCUS_CHAT_BOX",
  UPDATE_IS_LOADING = "textChannel/UPDATE_IS_LOADING",
  FULL_SCREEN_CHAT_BOX = "textChannel/FULL_SCREEN_CHAT_BOX",
  CLEAR_MESSAGES = "textChannel/CLEAR_MESSAGES",
  GOT_TEXT_TO_SPEECH = "textChannel/GOT_TEXT_TO_SPEECH",
  UPDATE_REACHED_MAX_STT_USAGE = "textChannel/UPDATE_REACHED_MAX_STT_USAGE",
  RESIZE_CHAT_BOX = "textChannel/RESIZE_CHAT_BOX",
  SET_KEYBOARD_ACTIVE = "textChannel/SET_KEYBOARD_ACTIVE",
  DELETE_MESSAGE = "textChannel/DELETE_MESSAGE",
  UPDATE_MESSAGE = "textChannel/UPDATE_MESSAGE",
  ADD_MESSAGE_REACTION = "textChannel/ADD_MESSAGE_REACTION",
  REMOVE_MESSAGE_REACTION = "textChannel/REMOVE_MESSAGE_REACTION",
}

export interface SetTextChannel {
  workspaceId: number;
  textChannelId: number;
}

export interface GetMessages {
  textChannelId: number;
  previousTo?: string;
}

export interface GotMessages {
  textChannelId: number;
  messages: ChatMessage[];
  hasMore: boolean;
  currentOldestMessageId: number;
}

export interface SendMessage {
  textChannelId?: number;
  text: string;
  speechRecognition?: SpeechRecognition;
  receiver?: string | number[];
  isAudioExtraction?: boolean;
  sttUsage?: number;
}

export interface DeleteMessage {
  message: ChatMessage;
  textChannelId: number;
  groupIds: string[];
}
export interface EditMessage {
  messages: ChatMessage[];
  textChannelId: number;
}

export interface NewMessage {
  textChannelId: number;
  message: ChatMessage;
  stt: boolean;
}

export interface TranslateMessage {
  message: ChatMessage;
  targetLanguage: string;
  translator: Translator;
}

export interface TranslatedMessage {
  message: ChatMessage;
}

export interface TextToSpeechResponse {
  audioBase64: string;
}

export interface sendSttSessionEndEventRequest {
  sessionId: string;
  sessionText: string;
  originalLocale: string;
  sessionEndedAt: string;
  customEndpointId?: string;
  customSubscriptionId?: string;
  customEndpointLocale?: string;
  messageId?: string;
}

export type TextChannelActionTypes =
  | ReturnType<typeof setTextChannelAction>
  | ReturnType<typeof getMessagesAction>
  | ReturnType<typeof gotMessagesAction>
  | ReturnType<typeof newMessageAction>
  | ReturnType<typeof speechToTextInitAction>
  | ReturnType<typeof updateVpSttServer>
  | ReturnType<typeof speechToTextStopAction>
  | ReturnType<typeof clearUnreadMessageAction>
  | ReturnType<typeof clearSelectedUnreadMessageAction>
  | ReturnType<typeof showChatBoxAction>
  | ReturnType<typeof setFocusChatBoxAction>
  | ReturnType<typeof updateIsLoadingAction>
  | ReturnType<typeof setDMUserIds>
  | ReturnType<typeof translatedMessageAcction>
  | ReturnType<typeof fullScreeChatBoxAction>
  | ReturnType<typeof clearMessagesAction>
  | ReturnType<typeof gotTextToSpeechAction>
  | ReturnType<typeof updateReachedSttUsage>
  | ReturnType<typeof resizeChatBoxAction>
  | ReturnType<typeof setKeyboardActiveAction>
  | ReturnType<typeof deleteMessageAction>
  | ReturnType<typeof updateMessageAction>
  | ReturnType<typeof addMessageReactionAction>
  | ReturnType<typeof removeMessageReactionAction>;

// Index = 0: normal stt
// Index = 1: stt for audio extraction

const TRANSLATION_STOP = 1;

let consectiveTranslations = [
  {
    translationFlagNumber: 0,
    translatingText: {} as Record<string, string>,
    lastMessageId: 0,
    enableSendServer: true,
    translationCount: 0,
    translationTriggerNumber: DEFAULT_TRANSLATION_FREQUENCY,
  },
  {
    translationFlagNumber: 0,
    translatingText: {} as Record<string, string>,
    lastMessageId: 0,
    enableSendServer: true,
    translationCount: 0,
    translationTriggerNumber: DEFAULT_TRANSLATION_FREQUENCY,
  },
];

function setTextChannelAction(payload: SetTextChannel) {
  return {
    type: TextChannelActions.SET_TEXT_CHANNEL,
    payload,
  } as const;
}

export function setTextChannel(workspaceId: number, textChannelId: number): AppThunk {
  return dispatch => {
    dispatch(setTextChannelAction({ workspaceId, textChannelId }));
    dispatch(getMessages(workspaceId, textChannelId));
  };
}

function getMessagesAction(payload: GetMessages) {
  return {
    type: TextChannelActions.GET_MESSAGES,
    payload,
  } as const;
}

export function getMessages(workspaceId: number, textChannelId: number, currentOldestMessageId?: number): AppThunk {
  return (dispatch, getState) => {
    const ready = hasTextChannel(getState());

    if (!ready) {
      return;
    }

    const payload = { workspaceId, textChannelId, currentOldestMessageId };

    dispatch(getMessagesAction(payload));
    dispatch(sendMessage(TEXT_CHANNEL_EVENT_TYPES.GET_MESSAGES_REQUEST, payload));
  };
}

function gotMessagesAction(payload: GotMessages) {
  return {
    type: TextChannelActions.GOT_MESSAGES,
    payload,
  } as const;
}

function clearMessagesAction() {
  return {
    type: TextChannelActions.CLEAR_MESSAGES,
  } as const;
}

export function gotMessages(payload: GotMessages): AppThunk {
  return (dispatch, getState) => {
    const textChannelId = getTextChannelId(getState());
    const enableTextChatSaving = isTextChatSavingEnabled(getState());
    const workspaceId = getCurrentWorkspaceId(getState());

    if (textChannelId === payload.textChannelId && workspaceId) {
      if (enableTextChatSaving) {
        dispatch(
          gotMessagesAction({
            ...payload,
            messages: [...payload.messages]
              .filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
              .sort((a, b) => a.id - b.id),
          }),
        );
        localDataTextChatHistory.clearLocalChatHistory(workspaceId, true);
      } else {
        dispatch(gotMessagesAction({ ...payload, messages: [...payload.messages].sort((a, b) => a.id - b.id) }));
        localDataTextChatHistory.clearLocalChatHistory(workspaceId);
      }
    }

    dispatch(updateIsLoadingAction(false));
  };
}

export function sendNewMessage(payload: SendMessage): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const isSubtitleOpened = getCurrentWorkspace(getState());

    let consectiveTranslation = consectiveTranslations[Number(!!payload.isAudioExtraction)];

    if (!consectiveTranslation.enableSendServer && payload.speechRecognition && !payload.speechRecognition.isFinal) {
      return;
    }

    consectiveTranslation.enableSendServer = false;
    if (consectiveTranslation.translationFlagNumber === 0) {
      consectiveTranslation.translatingText = {};
      if (isSubtitleOpened) {
        consectiveTranslation.translationTriggerNumber = 5;
      } else {
        consectiveTranslation.translationTriggerNumber =
          currentWorkspace?.translationFrequency ?? DEFAULT_TRANSLATION_FREQUENCY;
      }
    }

    if (payload.speechRecognition && !payload.speechRecognition.isFinal) {
      consectiveTranslation.translationFlagNumber++;
    }

    const isAudioExtracting = getIsExtractingAudio(getState());
    const textChannelId = getTextChannelId(getState());
    const textChannelWorkspaceId = getTextChannelWorkspaceId(getState());
    const me = getMyMember(getState());
    const allMembers = getOnlineWorkspaceUsers(getState()).filter(wm => wm.workspaceId === currentWorkspace?.id);
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const voiceChannelUsersIds = allMembers
      .filter(el => el.voiceChannelId === currentVoiceChannel?.id && el.id !== me?.id)
      .map(el => el.id);
    const myShortId = getMyShortId(getState());
    const invitedEnv = getInvitedEnv(getState());
    const isFloor = !currentVoiceChannel?.parentVoiceChannelId;
    const voiceChannelListenerIds = getOnlineWorkspaceListeners(getState())
      .filter(
        wl =>
          ((wl.joinShortId === myShortId || wl.joinShortId === me?.joinShortId) && isFloor) ||
          (wl.voiceChannelId === currentVoiceChannel?.id && wl.joinShortId === currentVoiceChannel?.listenerId),
      )
      .map(wl => wl.id);

    const subscribers = getSubscribers(getState());
    const userIdsOnFloor = getOnlineUserIdsOnFloor(getState());
    const userIdsOnSameFloor = getOnlineUserIdsOnSameFloor(getState());
    const isListener = checkIsListener(getState());
    const isBroadCast = me?.broadcastActive;
    const subscriberUserIds = subscribers.map(el => getUserInfoFromParticipantId(el).userId!);
    const targetLanguage = isAudioExtracting
      ? getMyUsingLanguages(getState())
      : getUsingLanguageInCurrentVoiceChannel(getState());
    let onFloorSttReceivers = undefined;
    let receivers: number[] = [];
    let userShortId: string | undefined = undefined;

    if (payload.speechRecognition && isFloor) {
      if (isBroadCast) {
        receivers = userIdsOnSameFloor.filter(el => el !== me?.id);
        onFloorSttReceivers = userIdsOnSameFloor.filter(el => el !== me?.id);

        if (invitedEnv?.inviteeUserId) {
          receivers = [...receivers, invitedEnv?.inviteeUserId];
        }
      } else if (isListener) {
        if (invitedEnv?.inviteeUserId) {
          receivers = [invitedEnv.inviteeUserId];
          onFloorSttReceivers = [invitedEnv.inviteeUserId];
        } else {
          receivers = userIdsOnSameFloor.filter(el => el !== me?.id);
          onFloorSttReceivers = userIdsOnSameFloor.filter(el => el !== me?.id);
        }
      } else {
        receivers = subscriberUserIds;
        onFloorSttReceivers = subscriberUserIds;
      }

      receivers = [...receivers, ...voiceChannelListenerIds];
      userShortId = isListener ? me?.joinShortId : myShortId;
    } else if (payload.receiver) {
      if (payload.receiver === TextChatReceiverOptionIds.EVERYONE_IN_MTG_ROOM || payload.speechRecognition) {
        receivers = [...voiceChannelUsersIds, ...voiceChannelListenerIds];
      } else if (payload.receiver === TextChatReceiverOptionIds.NEAR_USERS) {
        receivers = subscriberUserIds;
      } else if (payload.receiver === TextChatReceiverOptionIds.EVERYONE_IN_FLOOR) {
        receivers = userIdsOnFloor.filter(el => el !== me?.id);
      } else if (payload.receiver === TextChatReceiverOptionIds.EVERYONE_IN_WORKSPACE) {
        receivers = allMembers.filter(el => el.id !== me?.id).map(el => el.id);
      } else {
        //@ts-ignore
        receivers = payload.receiver;
      }
    } else {
      receivers = [...voiceChannelUsersIds, ...voiceChannelListenerIds];
    }

    const translator: Translator = "google";

    const socket = getSocket(getState());

    if (textChannelWorkspaceId === currentWorkspace?.id && textChannelId) {
      const message = {
        ...payload,
        textChannelId,
        workspaceId: currentWorkspace!.id,
        users: receivers,
        translator: translator,
        isConsecutiveTranslation: currentWorkspace.enableConsecutiveTranslation,
        onFloorSttReceivers: onFloorSttReceivers,
        translationFlagNumber: consectiveTranslation.translationFlagNumber,
        translatingText: consectiveTranslation.translatingText,
        socketId: socket.id,
        translationTargetLanguage: targetLanguage,
        translationTriggerNumber: consectiveTranslation.translationTriggerNumber,
        userShortId,
        sttUsage: payload.sttUsage,
      };

      dispatch(sendMessage(TEXT_CHANNEL_EVENT_TYPES.NEW_MESSAGE_REQUEST, message));

      if (env !== "production") {
        console.log("Sent new message", message);
      }
    }
  };
}

export function deleteMessage(payload: DeleteMessage): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const socket = getSocket(getState());

    if (currentWorkspace && currentVoiceChannel) {
      const data = {
        groupIds: payload.groupIds,
        textChannelId: payload.textChannelId,
        socketId: socket.id,
        workspaceId: currentWorkspace!.id,
        receiver: payload.message.receiver,
        users: payload.message.users,
        voiceChannelId: currentVoiceChannel.id,
        id: payload.message.id,
      };

      dispatch(sendMessage(TEXT_CHANNEL_EVENT_TYPES.DELETE_MESSAGE_REQUEST, data));
    }
  };
}

export function updateMessage(message: ChatMessage, groupId?: string): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const socket = getSocket(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    if (currentWorkspace && currentVoiceChannel) {
      dispatch(
        sendMessage(TEXT_CHANNEL_EVENT_TYPES.UPDATE_MESSAGE_REQUEST, {
          ...message,
          workspaceId: currentWorkspace?.id,
          socketId: socket.id,
          voiceChannelId: currentVoiceChannel.id,
          groupId: groupId,
        }),
      );
    }
  };
}

export function addTextReaction(payload: { emoji: EmojiReactionsType; messages: ChatMessage[] }): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const socket = getSocket(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    if (currentWorkspace && currentVoiceChannel) {
      dispatch(
        sendMessage(TEXT_CHANNEL_EVENT_TYPES.ADD_TEXT_MESSAGE_REACTION_REQUEST, {
          emoji: payload.emoji.toString(),
          ...payload.messages[0],
          workspaceId: currentWorkspace?.id,
          socketId: socket.id,
          voiceChannelId: currentVoiceChannel.id,
        }),
      );
    }
  };
}

export function removeTextReaction(payload: { emoji: EmojiReactionsType; messages: ChatMessage[] }): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const socket = getSocket(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    if (currentWorkspace && currentVoiceChannel) {
      dispatch(
        sendMessage(TEXT_CHANNEL_EVENT_TYPES.REMOVE_TEXT_MESSAGE_REACTION_REQUEST, {
          emoji: payload.emoji.toString(),
          ...payload.messages[0],
          workspaceId: currentWorkspace?.id,
          socketId: socket.id,
          voiceChannelId: currentVoiceChannel.id,
        }),
      );
    }
  };
}

export function deleteMessageResponse(payload: {
  textChannelId: number;
  groupIds: string[];
  messageId: number;
}): AppThunk {
  return (dispatch, getState) => {
    dispatch(deleteMessageAction(payload));
  };
}

export function updateMessageResponse(payload: { message: ChatMessage; groupId?: string }): AppThunk {
  return (dispatch, getState) => {
    dispatch(updateMessageAction(payload));
  };
}

export function clearAllReactionsNotifications(): AppThunk {
  return (dispatch, getState) => {
    const notificationsKeys = getNotificationsKey(getState());

    const reactionsKeys = notificationsKeys.filter(a => a.includes("message-reaction"));

    for (let i = 0; i < reactionsKeys.length; i += 1) {
      dispatch(removeNotificationKey(reactionsKeys[i]));
      if (window.electron) {
        dispatch(
          removeNotificationOnPIP({
            type: "text-message-reaction",
            emoji: reactionsKeys[i].split("-")[3],
            reactedUserId: Number(reactionsKeys[i].split("-")[2]),
          }),
        );
      }
    }
  };
}

export function addMessageReactionResponse(payload: {
  emoji: string;
  groupId: string;
  createdBy: number;
  messageId: number | null;
}): AppThunk {
  return (dispatch, getState) => {
    dispatch(addMessageReactionAction(payload));

    const messages = getTextChannelMessages(getState());
    const me = getMyMember(getState());
    const isShow = getChatBoxOption(getState());

    const reactMessage = messages.find(a => a.groupId === payload.groupId);

    if (reactMessage?.userId === me?.id && me?.id !== payload.createdBy) {
      //show PIP for reaction
      const reactedUserInfo = getOnlineUserById(payload.createdBy)(getState());

      if (reactedUserInfo) {
        const notificationKey = `message-reaction-${payload.createdBy}-${payload.emoji.toString()}`;

        if (!isShow) {
          dispatch(addNotificationKey(notificationKey));
          notification.open({
            type: "info",
            key: notificationKey,
            icon: <ChatIconOutlined />,
            message: <FormattedMessage id="text-chat-reaction/reactions" defaultMessage="Chat Reaction" />,
            description: (
              <FormattedMessage
                id="notification-window/text-chat-reaction"
                defaultMessage="{userName} reacted to below message by {emoji}. {br} {text}"
                values={{
                  userName: reactedUserInfo.name,
                  text:
                    typeof reactMessage?.text === "string"
                      ? reactMessage?.text
                      : reactMessage?.originalTextLanguage
                      ? reactMessage?.text[reactMessage.originalTextLanguage]
                      : reactMessage?.text[0],
                  br: <br />,
                  emoji: emojiReactionKeys[payload.emoji as EmojiReactionsType]?.component,
                }}
              />
            ),
            primaryButton: {
              type: "primary",
              color: "positive",
              label: <FormattedMessage id="check" defaultMessage="Check" />,
              onClick: () => {
                dispatch(showChatBox());
                dispatch(clearAllReactionsNotifications());
              },
            },
            secondaryButton: {
              type: "primary",
              color: "negative",
              label: <FormattedMessage id="ignore" defaultMessage="Ignore" />,
              onClick: () => {
                dispatch(removeNotificationKey(notificationKey));
                dispatch(
                  removeNotificationOnPIP({
                    type: "text-message-reaction",
                    emoji: payload.emoji,
                    reactedUserId: payload.createdBy,
                  }),
                );
              },
            },
            onClose: () => dispatch(removeNotificationKey(notificationKey)),
            closable: false,
          });
        }

        if (window.electron) {
          dispatch(showNotificationWindow());
          dispatch(
            addNotificationOnPIP({
              notificationsType: "text-message-reaction",
              data: {
                ...payload,
                text: !(
                  reactMessage?.receiver! === TextChatReceiverOptionIds.EVERYONE_IN_MTG_ROOM ||
                  reactMessage?.receiver! === TextChatReceiverOptionIds.NEAR_USERS
                )
                  ? ""
                  : typeof reactMessage?.text === "string"
                  ? reactMessage?.text
                  : reactMessage?.originalTextLanguage
                  ? reactMessage?.text[reactMessage.originalTextLanguage]
                  : reactMessage?.text[0],
                name: reactedUserInfo.name,
              },
            }),
          );
        }
      }
    }
  };
}

export function removeMessageReactionResponse(payload: {
  emoji: string;
  groupId: string;
  createdBy: number;
  messageId: number | null;
}): AppThunk {
  return (dispatch, getState) => {
    const notificationKey = `message-reaction-${payload.createdBy}-${payload.emoji.toString()}`;

    dispatch(removeNotificationKey(notificationKey));
    dispatch(
      removeNotificationOnPIP({
        type: "text-message-reaction",
        emoji: payload.emoji,
        reactedUserId: payload.createdBy,
      }),
    );
    dispatch(removeMessageReactionAction(payload));
  };
}

function removeMessageReactionAction(payload: {
  emoji: string;
  groupId: string;
  createdBy: number;
  messageId: number | null;
}) {
  return {
    type: TextChannelActions.REMOVE_MESSAGE_REACTION,
    payload,
  } as const;
}

function addMessageReactionAction(payload: {
  emoji: string;
  groupId: string;
  createdBy: number;
  messageId: number | null;
}) {
  return {
    type: TextChannelActions.ADD_MESSAGE_REACTION,
    payload,
  } as const;
}

function updateMessageAction(payload: { message: ChatMessage; groupId?: string }) {
  return {
    type: TextChannelActions.UPDATE_MESSAGE,
    payload,
  } as const;
}

function deleteMessageAction(payload: { textChannelId: number; groupIds: string[]; messageId: number }) {
  return {
    type: TextChannelActions.DELETE_MESSAGE,
    payload,
  } as const;
}

function newMessageAction(payload: NewMessage & { myself: boolean }) {
  return {
    type: TextChannelActions.NEW_MESSAGE,
    payload,
  } as const;
}

export function newMessage(payload: NewMessage): AppThunk {
  return (dispatch, getState) => {
    const textChannelId = getTextChannelId(getState());
    const me = getMyMember(getState());
    const isShow = getChatBoxOption(getState());
    const currentWorksapce = getCurrentWorkspace(getState());
    const workspaceId = getCurrentWorkspaceId(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const isGuest = checkIsGuest(getState());
    const isListener = checkIsListener(getState());
    const members = getTextChatAllowMembers(getState());
    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());
    const isOpeningTextChatWindow = getIsOpeningTextChatWindow(getState());
    const isSubtitleOpend = getIsSubtitleOpened(getState());

    let showReplyButton = true;
    let consectiveTranslation = consectiveTranslations[Number(!!payload.message.isAudioExtraction)];

    if (isGuest) {
      if (payload.message.receiver === "everyone_in_workspace") {
        showReplyButton = false;
      } else {
        showReplyButton = members.some(a => a.id === payload.message.userId);
      }
    } else {
      showReplyButton =
        payload.message.receiver === "everyone_in_workspace" || members.some(a => a.id === payload.message.userId);
    }

    if (!payload.stt && workspaceId && !currentVoiceChannel?.isDeleteAllData) {
      localDataTextChatHistory.addLocalChatHistoryMessage(workspaceId, payload.message, textChannelId);
    }

    if (
      payload.stt &&
      typeof payload.message.text === "object" &&
      Object.keys(payload.message.text).length > 1 &&
      me &&
      me.id === payload.message.userId
    ) {
      consectiveTranslation.translatingText = payload.message.text;
    }

    dispatch(newMessageAction({ ...payload, myself: me?.id === payload.message.userId }));

    if (!payload.stt && typeof payload.message.text !== "object" && !isOpeningTextChatWindow) {
      dispatch(showNotificationWindow());
    }

    if (
      !isShow &&
      typeof payload.message.text !== "object" &&
      !isListener &&
      !isExtractingAudio &&
      !isRecordingAudioExtraction &&
      !isOpeningTextChatWindow
    ) {
      const notificationKey = `new-message-${textChannelId}-${payload.message.id}`;

      dispatch(addNotificationKey(notificationKey));
      notification.open({
        type: "info",
        key: notificationKey,
        icon: <ChatIconOutlined />,
        message: <FormattedMessage id="text-channel-label" defaultMessage="Chat" />,
        description: <URLify text={`${payload.message.displayName}: ${payload.message.text}`} msgListLen={1} />,
        primaryButton: showReplyButton
          ? {
              type: "primary",
              color: "positive",
              label: <FormattedMessage id="reply" defaultMessage="Reply" />,
              onClick: () => {
                dispatch(showChatBox());
                if (payload.message.receiver) {
                  dispatch(setDMUserIds(payload.message.receiver));
                } else {
                  const targetUsers = payload.message.users ? payload.message.users.filter(u => u !== me?.id) : [];

                  dispatch(setDMUserIds([payload.message.userId, ...targetUsers]));
                }

                dispatch(removeNotificationKey(notificationKey));
              },
            }
          : undefined,
        secondaryButton: {
          type: "primary",
          color: "negative",
          label: <FormattedMessage id="ignore" defaultMessage="Ignore" />,
          onClick: () => {
            dispatch(removeNotificationKey(notificationKey));
            dispatch(clearSelectedUnreadMessage([payload.message.id]));
          },
        },
        onClose: () => dispatch(removeNotificationKey(notificationKey)),
        closable: false,
      });
    }

    if (
      (payload.message.speechRecognition?.isFinal || consectiveTranslation.lastMessageId !== payload.message.id) &&
      me &&
      me.id === payload.message.userId
    ) {
      consectiveTranslation.translationFlagNumber = 0;
      consectiveTranslation.translatingText = {};
      consectiveTranslation.lastMessageId = payload.message.id;
      consectiveTranslation.translationCount = 0;
      consectiveTranslation.translationTriggerNumber =
        currentWorksapce?.translationFrequency ?? DEFAULT_TRANSLATION_FREQUENCY;
    }

    if (
      consectiveTranslation.translationFlagNumber === consectiveTranslation.translationTriggerNumber &&
      me &&
      me.id === payload.message.userId
    ) {
      consectiveTranslation.translationCount++;
      if (
        !!isSubtitleOpend
          ? consectiveTranslation.translationCount === 20
          : currentWorksapce?.translationFrequency === DEFAULT_TRANSLATION_FREQUENCY
          ? consectiveTranslation.translationCount === 3
          : currentWorksapce?.translationFrequency === HIGH_TRANSLATION_FREQUENCY
          ? consectiveTranslation.translationCount === 5
          : currentWorksapce?.translationFrequency === LOW_TRANSLATION_FREQUENCY
          ? consectiveTranslation.translationCount === 2
          : consectiveTranslation.translationCount === 3
      ) {
        consectiveTranslation.translationTriggerNumber = TRANSLATION_STOP;
      } else {
        if (isSubtitleOpend) {
          consectiveTranslation.translationTriggerNumber =
            consectiveTranslation.translationTriggerNumber + (DEFAULT_TRANSLATION_FREQUENCY / 3) * 1;
        } else {
          consectiveTranslation.translationTriggerNumber =
            consectiveTranslation.translationTriggerNumber +
            (currentWorksapce?.translationFrequency ?? DEFAULT_TRANSLATION_FREQUENCY) +
            (consectiveTranslation.translationCount *
              (currentWorksapce?.translationFrequency ?? DEFAULT_TRANSLATION_FREQUENCY)) /
              2;
        }
      }
    }

    consectiveTranslation.enableSendServer = true;
  };
}

export function workspaceSpeechToTextLimitReached(showNotificationWindow: boolean, showChat: boolean): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const me = getMyMember(state);
    const textChannelId = getTextChannelId(state);
    const today = new Date(Date.now());
    const isManager = isMeManager(state);
    const usage = getSpeechToTextCurrentUsage(state);
    const currentWorkspace = getCurrentWorkspace(state);

    const handlePlanUpdate = () => {
      if (!currentWorkspace || (currentWorkspace.plan.name.en !== "Free" && !currentWorkspace.stripeCustomerId)) {
        zohoToggleWindow("show");
      } else {
        const protocol = getProtocol();

        openURL(`${protocol}${window.location.host}${generateBillingWithParam(currentWorkspace.id.toString())}`, true);
      }
    };

    dispatch(fetchSpeechToTextMinutesSpent());

    const messageOwner = {
      id: VOICEPINGBOT.id,
      userId: VOICEPINGBOT.id,
      displayName: VOICEPINGBOT.name,
      groupId: nanoid(),
      time: today.toISOString(),
      text: intl.formatMessage(
        {
          id: "speech-to-text/usage-value-owner",
          defaultMessage:
            "Your speech to text has reached {max} minutes limitation this month. Do you like it? Please contact us for more minutes.",
        },
        { max: usage?.total },
      ),
    };
    const messageMember = {
      id: VOICEPINGBOT.id,
      userId: VOICEPINGBOT.id,
      displayName: VOICEPINGBOT.name,
      groupId: nanoid(),
      time: today.toISOString(),
      text: intl.formatMessage(
        {
          id: "speech-to-text-limit-reached-message-member",
          defaultMessage:
            "Your speech to text has reached {max} minutes limitation this month. Please contact the owner to upgrade the plan.",
        },
        { max: usage?.total },
      ),
    };
    const message = isManager ? messageOwner : messageMember;

    dispatch(speechToTextStopAction());

    if (me?.voiceChannelId && showNotificationWindow) {
      notification.open({
        key: "reachedSttMaxUsage",
        type: "warning",
        message: (
          <FormattedMessage
            id="speech-to-text-limit-reached"
            defaultMessage="Workspace speech recognition limit was reached."
          />
        ),
        description: (
          <FormattedMessage
            id="speech-to-text/usage-value-description"
            defaultMessage="Your speech to text has reached {max} minutes limitation this month."
            values={{ max: usage?.total }}
          />
        ),
        primaryButton: isManager
          ? {
              label: <FormattedMessage id="upgrade" defaultMessage="Upgrade" />,
              onClick: handlePlanUpdate,
            }
          : undefined,
      });

      const chatOpen = getChatBoxOption(getState());

      if (showChat) {
        dispatch(
          newMessage({
            textChannelId: textChannelId,
            message: {
              ...message,
              unread: !chatOpen,
              user: null,
              textChannelId,
              hideReplyButton: true,
              users: [state.account.user.id],
            },
            stt: false,
          }),
        );
      }
    }
  };
}

function speechToTextInitAction(payload?: { token: string; region: string; vpHost?: string; jwtToken?: string }) {
  return {
    type: TextChannelActions.SPEECH_TO_TEXT_INIT,
    payload,
  } as const;
}

export function updateVpSttServer(payload: { vpHost?: string; jwtToken?: string }) {
  return {
    type: TextChannelActions.UPDATE_VP_STT_SERVER,
    payload,
  } as const;
}

function speechToTextStopAction() {
  return {
    type: TextChannelActions.SPEECH_TO_TEXT_STOP,
  } as const;
}

function clearUnreadMessageAction() {
  return {
    type: TextChannelActions.CLEAR_UNREAD_MESSAGES,
  } as const;
}

function clearSelectedUnreadMessageAction(payload: { messageIds: number[] }) {
  return {
    type: TextChannelActions.CLEAR_SELECTED_UNREAD_MESSAGES,
    payload,
  } as const;
}

export function speechToTextInit(workspaceId: number): AppThunk {
  return async (dispatch, getState) => {
    try {
      const isReachedSttMaxUsage = getIsReachedSttMaxUsage(getState());

      if (!isReachedSttMaxUsage) {
        log(LogCategory.SpeechRecognition, "Asking the server for new credentials...");

        const { response } = await postJson(STT_GET_AUTHORIZATION, { workspaceId });

        if (response.success) {
          const token = response.data.authToken;
          const region = response.data.region;
          const vpHost = response.data.vpHost;
          const jwtToken = response.data.jwtToken;

          log(LogCategory.SpeechRecognition, "Success on getting new credentials.");
          dispatch(speechToTextInitAction({ token, region, vpHost, jwtToken }));
        } else {
          dispatch(workspaceSpeechToTextLimitReached(true, false));
          dispatch(updateReachedSttUsage(true));
        }
      }
    } catch (err) {
      console.error("Speech to text api auth error: ", err);
    }
  };
}

export function speechToTextSpend(workspaceId: number, secondsToSpend: number): AppThunk {
  return async dispatch => {
    dispatch(sendMessage(TEXT_CHANNEL_EVENT_TYPES.SPEECH_TO_TEXT_SPEND, { workspaceId, secondsToSpend }));
  };
}

export function clearUnreadMessage(): AppThunk {
  return async dispatch => {
    dispatch(clearUnreadMessageAction());
  };
}

export function clearSelectedUnreadMessage(messageIds: number[]): AppThunk {
  return async (dispatch, getState) => {
    const textChannelId = getTextChannelId(getState());

    for (const messageId of messageIds) {
      const notificationKey = `new-message-${textChannelId}-${messageId}`;

      dispatch(removeNotificationKey(notificationKey));
    }

    dispatch(clearSelectedUnreadMessageAction({ messageIds }));
  };
}

function showChatBoxAction(isShow: boolean) {
  return {
    type: TextChannelActions.SHOW_CHAT_BOX,
    payload: { isShow },
  } as const;
}

export function setFocusChatBoxAction(isFocus: boolean) {
  return {
    type: TextChannelActions.SET_FOCUS_CHAT_BOX,
    payload: { isFocus },
  } as const;
}

export function setDMUserIds(userId?: number[] | TextChatReceiverOptionIds) {
  return {
    type: TextChannelActions.SET_DM_USERS,
    payload: { userId },
  } as const;
}

export function toggleChatBoxInMainWindow(dmUserId?: number): AppThunk {
  return async (dispatch, getState) => {
    let isShow = getChatBoxOption(getState());
    const isOpeningTextChatWindow = getIsOpeningTextChatWindow(getState());

    if (isOpeningTextChatWindow) {
      dispatch(showChatBoxAction(false));
      return;
    }

    dispatch(setDMUserIds(dmUserId ? [dmUserId] : undefined));

    if (dmUserId) {
      isShow = false;
    }

    dispatch(showChatBoxAction(!isShow));
    dispatch(setFocusChatBoxAction(true));
  };
}

export function hideChatBoxInMainWindow(): AppThunk {
  return async (dispatch, getState) => {
    const isShow = getChatBoxOption(getState());

    if (isShow) {
      dispatch(showChatBoxAction(!isShow));
      dispatch(fullScreeChatBox(false));
    }
  };
}

export function showChatBoxInMainWindow(dmUserId?: number): AppThunk {
  return async (dispatch, getState) => {
    const isShow = getChatBoxOption(getState());
    const isOpeningTextChatWindow = getIsOpeningTextChatWindow(getState());

    dispatch(setDMUserIds(dmUserId ? [dmUserId] : undefined));

    if (isOpeningTextChatWindow) {
      dispatch(showChatBoxAction(false));

      return;
    }

    if (!isShow) {
      dispatch(showChatBoxAction(!isShow));
    }

    dispatch(setOpenTextChatInAnotherWindow(false));
    dispatch(setFocusChatBoxAction(true));
  };
}

export function toggleChatBox(dmUserId?: number): AppThunk {
  return async (dispatch, getState) => {
    const openTextChatInAnotherWindow = getOpenTextChatInAnotherWindow(getState());
    const isOpeningTextChatWindow = getIsOpeningTextChatWindow(getState());
    const isShowChatBox = getChatBoxOption(getState());
    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());

    if (openTextChatInAnotherWindow && !isExtractingAudio && !isRecordingAudioExtraction && !isShowChatBox) {
      if (isOpeningTextChatWindow) {
        dispatch(hideTextChatWindow());
      } else {
        dispatch(showTextChatWindow(dmUserId));
      }
    } else {
      dispatch(toggleChatBoxInMainWindow(dmUserId));
    }
  };
}

export function hideChatBox(): AppThunk {
  return async (dispatch, getState) => {
    if (window.electron) {
      dispatch(hideTextChatWindow());
    }

    dispatch(hideChatBoxInMainWindow());
  };
}

export function showChatBox(dmUserId?: number, showApp?: boolean): AppThunk {
  return async (dispatch, getState) => {
    const isOpeningTextChatWindow = getIsOpeningTextChatWindow(getState());
    const isShowChatBox = getChatBoxOption(getState());
    const openTextChatInAnotherWindow = getOpenTextChatInAnotherWindow(getState());
    const isExtractingAudio = getIsExtractingAudio(getState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(getState());

    if (
      (openTextChatInAnotherWindow && !isExtractingAudio && !isRecordingAudioExtraction && !isShowChatBox) ||
      isOpeningTextChatWindow
    ) {
      dispatch(showTextChatWindow(dmUserId));
    } else {
      dispatch(showChatBoxInMainWindow(dmUserId));

      if (showApp) {
        sendMessageOverIPC(channels.SHOW_APP);
      }
    }
  };
}

export function sendNewTextNotification(payload: { userId: number; displayName: string; text: string }): AppThunk {
  return async (dispatch, getState) => {
    const isOpeningTextChatWindow = getIsOpeningTextChatWindow(getState());

    if (isOpeningTextChatWindow) return;

    const myMember = getMyMember(getState());

    if (payload.userId !== myMember?.id) {
      sendElectronNotification({
        title: intl.formatMessage(
          {
            id: "new-message/desktop-notificaiton",
            defaultMessage: "New message from {displayName}",
          },
          {
            displayName: payload.displayName,
          },
        ),
        body: payload.text,
      });
    }
  };
}

export function updateIsLoadingAction(loading: boolean) {
  return {
    type: TextChannelActions.UPDATE_IS_LOADING,
    payload: { loading },
  } as const;
}
export function updateIsLoading(payload: { loading: boolean }): AppThunk {
  return async (dispatch, getState) => {
    dispatch(updateIsLoadingAction(payload.loading));
  };
}

export function translateMessage(payload: TranslateMessage): AppThunk {
  return (dispatch, getState) => {
    dispatch(sendMessage(TEXT_CHANNEL_EVENT_TYPES.TRANSLATE_MESSAGE_REQUEST, payload));
  };
}

export function translatedMessageAcction(payload: TranslatedMessage) {
  return {
    type: TextChannelActions.TRANSLATED_MESSAGE,
    payload,
  } as const;
}

export function translatedMessage(payload: TranslatedMessage): AppThunk {
  return (dispatch, getState) => {
    dispatch(translatedMessageAcction(payload));
  };
}

export function fullScreeChatBoxAction(fullScreen: boolean) {
  return {
    type: TextChannelActions.FULL_SCREEN_CHAT_BOX,
    payload: { fullScreen },
  } as const;
}

export function fullScreeChatBox(fullScreen?: boolean): AppThunk {
  return (dispatch, getState) => {
    if (fullScreen === undefined) {
      const textChannel = getTextChannelState(getState());

      dispatch(fullScreeChatBoxAction(!textChannel.fullScreenChatBox));

      return;
    }

    dispatch(fullScreeChatBoxAction(fullScreen));
  };
}

export function gotTextToSpeech(payload: TextToSpeechResponse): AppThunk {
  return dispatch => {
    dispatch(gotTextToSpeechAction(payload));
  };
}

export function gotTextToSpeechAction(payload: TextToSpeechResponse) {
  return {
    type: TextChannelActions.GOT_TEXT_TO_SPEECH,
    payload,
  } as const;
}

export function removeTextToSpeech() {
  return {
    type: TextChannelActions.GOT_TEXT_TO_SPEECH,
    payload: {
      audioBase64: "",
    },
  } as const;
}

export function sendSttSessionEndEvent(payload: sendSttSessionEndEventRequest): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me) {
      const textChannelId = getTextChannelId(getState());
      const userId = me.id;
      const workspaceId = me.workspaceId;
      const currentWorkspaceLanguages = getCurrentWorkspaceLanguages(getState());

      const message = {
        ...payload,
        userId,
        textChannelId,
        workspaceId,
        isMobileNative: false,
        enableLanguageDetection: me.enabledLanguageInconsistencyAlert,
        workspaceLanguageCodes: currentWorkspaceLanguages
          ?.map(l => l.code)
          .filter(l => l !== payload.originalLocale.slice(0, 2))
          .join(","),
      };

      dispatch(sendMessage(TEXT_CHANNEL_EVENT_TYPES.STT_SESSION_END_REQUEST, message));
    }
  };
}

export function clearMessages(): AppThunk {
  return (dispatch, getState) => {
    dispatch(clearMessagesAction());
  };
}

export function updateReachedSttUsage(reachedSttMaxUsage: boolean) {
  return {
    type: TextChannelActions.UPDATE_REACHED_MAX_STT_USAGE,
    payload: { reachedSttMaxUsage },
  } as const;
}

export function resizeChatBox(): AppThunk {
  return (dispatch, getState) => {
    const resizeEvent = getResizeEvent(getState());

    dispatch(resizeChatBoxAction(!resizeEvent));
  };
}

export function resizeChatBoxAction(resizeEvent: boolean) {
  return {
    type: TextChannelActions.RESIZE_CHAT_BOX,
    payload: { resizeEvent },
  } as const;
}

export function setKeyboardActiveAction(keyboardActive: boolean) {
  return {
    type: TextChannelActions.SET_KEYBOARD_ACTIVE,
    payload: { keyboardActive },
  } as const;
}

export function toggleKeyboardActive(): AppThunk {
  return (dispatch, getState) => {
    const keyboardActive = getKeyboardActive(getState());

    dispatch(setKeyboardActive(!keyboardActive));
  };
}

export function setKeyboardActive(keyboardActive: boolean): AppThunk {
  return (dispatch, getState) => {
    dispatch(setKeyboardActiveAction(keyboardActive));

    if (keyboardActive) {
      dispatch(setFocusChatBoxAction(true));
    }
  };
}
