import * as Actions from "./actions";

const APP_EVENT_TYPE_PREFIX = "app/";

export const APP_EVENT_TYPE = {
  APP_RELOAD_CONFIG_BROADCAST: `${APP_EVENT_TYPE_PREFIX}reload-config-broadcast`,
  APP_DUPLICATE_LOGIN: `${APP_EVENT_TYPE_PREFIX}showReconnect`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(APP_EVENT_TYPE_PREFIX)) {
    return;
  }

  if (type === APP_EVENT_TYPE.APP_DUPLICATE_LOGIN) {
    store.dispatch(Actions.duplicateLogin());
  } else if (type === APP_EVENT_TYPE.APP_RELOAD_CONFIG_BROADCAST) {
    store.dispatch(Actions.setForceReloadAction(payload));
  }
};
