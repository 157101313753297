import { createSelector } from "reselect";
import {
  ADD_COMPANY_MODAL_ID,
  APPLY_WORK_CHANNEL_TEMPLATE_MODAL_ID,
  APPROVE_REMOTE_DESKTOP_CONTROL_MODAL_ID,
  BROADCAST_START_MODAL_ID,
  CREATE_CHANNEL_MODAL_ID,
  CREATE_WORKSPACE_MODAL_ID,
  CROP_IMAGE_MODAL_ID,
  CUSTOM_PHRASE_MODAL_ID,
  DELCINED_REMOTE_DESKTOP_CONTROL_MODAL_ID,
  DELETE_ACCOUNT_MODAL_ID,
  DELETE_BACKGROUND_MODAL_ID,
  DELETE_CHANNEL_MODAL_ID,
  DELETE_CUSTOM_BACKGROUND_MODAL_ID,
  DELETE_CUSTOM_OBJECT_MODAL_ID,
  DELETE_OBJECT_MODAL_ID,
  DELETE_WORKSPACE_MODAL_ID,
  DELETE_WORK_CHANNEL_SUBVERSION_MODAL_ID,
  DELETE_WORK_CHANNEL_TEMPLATE_MODAL_ID,
  DISCONNECT_APP_MODAL_ID,
  DUPLICATE_LOGIN_MODAL_ID,
  ELECTRON_REQUIRED_MAC_PERMISSION_MODAL_ID,
  ELECTRON_SCREEN_SELECTOR_MODAL_ID,
  HANDLE_MANAGE_TEAM_MODAL_ID,
  INVITE_USER_MODAL_ID,
  LEAVE_WORKSPACE_MODAL_ID,
  NEW_VERSION_RELEASE_MODAL_ID,
  PLAN_LIMIT_MODAL_ID,
  QRCODE_MODAL_ID,
  RECONNECT_CHANNEL_MODAL_ID,
  REQUEST_REMOTE_DESKTOP_CONTROL_MODAL_ID,
  RESTORE_WORK_CHANNEL_SUBVERSION_MODAL_ID,
  SETTINGS_MODAL_ID,
  SIGNOUT_CONFIRMATION_MODAL_ID,
  STOP_MEETING_RECORDING_MODAL_ID,
  TRACKER_STOPPED_ALERT_MODAL,
  UPDATE_CHANNEL_MODAL_ID,
  VIRTUAL_BACKGROUND_SETTING_MODAL_ID,
  VIRTUAL_OFFICE_URL_OBJECT_MODAL,
  WORKSPACE_RECORDING_PLAN_LIMITATION_MODAL_ID,
  WORKSPACE_TIME_TRACKER_LIMITATION_MODAL_ID,
} from "../../screens/Dashboard/constants";
import { getShowModal, ModalState } from "../../screens/Dashboard/state";

import { State } from "../../store/state";
import {
  CreateVoiceChannelModalData,
  CropImageModalData,
  MacRequiredPermissionModalData,
  SettingsModalData,
  UpdateVoiceChannelModalData,
} from "../types";
import { VirtualOfficeObject } from "../virtualOffice/types";
import { UrlObjectModalData } from "../../screens/Dashboard/UrlObjectModal/UrlObjectModal";

export function getApp(state: State) {
  return state.app;
}

export const isLoading = createSelector(getApp, app => app.loading);

export const isWorkspaceUpdating = createSelector(getApp, app => app.workspaceUpdating);

export const getNotificationConfig = createSelector(getApp, app => app.notificationConfig);

export const getStartupPrefernce = createSelector(getApp, app => app.startup);
export const getDownloadProgress = createSelector(getApp, app => app.downloadedPercentage);

export const getPermissions = createSelector(getApp, app => app.permissions);
export const getPermissionStateMicrophone = createSelector(getPermissions, permissions => permissions.microphone);
export const getPermissionStateCamera = createSelector(getPermissions, permissions => permissions.camera);
export const getPermissionStateScreenShare = createSelector(getPermissions, permissions => permissions.screenshare);
export const getPermissionStateWebNotification = createSelector(
  getPermissions,
  permissions => permissions.webNotification,
);

export const getRequiredMacPermissionModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === ELECTRON_REQUIRED_MAC_PERMISSION_MODAL_ID) as
      | ModalState<MacRequiredPermissionModalData>
      | undefined,
);
export const getTrackerAwayModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === TRACKER_STOPPED_ALERT_MODAL) as ModalState | undefined,
);

export const isAppWaitingToBeClosed = createSelector(getApp, app => app.waitingClosure);

export const getShowOfflineUsers = createSelector(getApp, app => app.showOfflineUsers);

export const getAppFocusStatus = createSelector(getApp, app => app.isAppInFocus);

export const getNotificationWindowLastPosition = createSelector(getApp, app => app.notificationWindowLastPosition);

export const getDuplicatedLoginStatus = createSelector(getApp, app => app.isDuplicatedLogin);

export const getSignoutConfirmModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === SIGNOUT_CONFIRMATION_MODAL_ID) as ModalState | undefined,
);

export const getNewVersionRelaseModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === NEW_VERSION_RELEASE_MODAL_ID) as ModalState | undefined,
);

export const getAppForceReloadStatus = createSelector(getApp, app => app.autoReload);

export const getPermissionStateAccessibility = createSelector(getPermissions, permissions => permissions.accessibility);

export const getIsCustomizingOfficeBackground = createSelector(getApp, app => app.isCustomizingOfficeBackground);

export const getVirtualBackgrounds = createSelector(getApp, app => app.virtualBackgrounds);

export const getActiveVBG = createSelector(getApp, app => app.virtualBackgrounds.find(el => el.id === app.activeVBG));

export const getPhraseActiveLanguage = createSelector(getApp, app => app.phraseLanguage);

export const getAudioEffect = createSelector(getApp, app => app.audioEffect);

export const getIsInActive = createSelector(getApp, app => app.inActive);

export const getSoundEffectEnbaled = createSelector(getApp, app => app.soundEffect);

export const getInitialAccessWorkpsaceChannelShortId = createSelector(
  getApp,
  app => app.initialAccessWorkspaceChannelId,
);

export const getSileceBufferNode = createSelector(getApp, app => app.silenceNode);

export const getWebCameraStatus = createSelector(getApp, app => app.webCameraStatus);

export const getStopSharingWindowOpeningStatus = createSelector(getApp, app => app.disableStopSharingWindow);

export const getTextChatWindowLastPosition = createSelector(getApp, app => app.textChatWindowLastPosition);

export const getIsOpeningTextChatWindow = createSelector(getApp, app => app.isOpeningTextChatWindow);

export const getOpenTextChatInAnotherWindow = createSelector(getApp, app => app.openTextChatInAnotherWindow);

export const getIdleFeatureStatus = createSelector(getApp, app => app.isIdleStatusFeatureEnabled);
export const getPipDefaultScreenshareWindowStatus = createSelector(
  getApp,
  app => app.isPipDefaultScreenshareWindowEnabled,
);

export const getUpdateVoiceChannelModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === UPDATE_CHANNEL_MODAL_ID) as ModalState<UpdateVoiceChannelModalData> | undefined,
);

export const getPlanLimitModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === PLAN_LIMIT_MODAL_ID) as ModalState | undefined,
);

export const getCreateVoiceChannelModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === CREATE_CHANNEL_MODAL_ID) as ModalState<CreateVoiceChannelModalData> | undefined,
);

export const getManageTeamModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === HANDLE_MANAGE_TEAM_MODAL_ID) as ModalState | undefined,
);

export const getInviteUserModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === INVITE_USER_MODAL_ID) as ModalState | undefined,
);

export const getElectronScreenSelectorModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === ELECTRON_SCREEN_SELECTOR_MODAL_ID) as ModalState | undefined,
);

export const getReconnectChannelModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === RECONNECT_CHANNEL_MODAL_ID) as ModalState | undefined,
);

export const getDeleteChannelModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DELETE_CHANNEL_MODAL_ID) as ModalState | undefined,
);

export const getCreateWorkspaceModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === CREATE_WORKSPACE_MODAL_ID) as ModalState | undefined,
);

export const getAddCompanyModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === ADD_COMPANY_MODAL_ID) as ModalState | undefined,
);

export const getSettingModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === SETTINGS_MODAL_ID) as ModalState<SettingsModalData> | undefined,
);

export const getDeleteAccountModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DELETE_ACCOUNT_MODAL_ID) as ModalState | undefined,
);

export const getCropImageModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === CROP_IMAGE_MODAL_ID) as ModalState<CropImageModalData> | undefined,
);

export const getDeleteWorkspaceModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DELETE_WORKSPACE_MODAL_ID) as ModalState | undefined,
);

export const getLeaveWorkspaceModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === LEAVE_WORKSPACE_MODAL_ID) as ModalState | undefined,
);

export const getDisconnectAppModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DISCONNECT_APP_MODAL_ID) as ModalState | undefined,
);

export const getDuplicateLoginModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DUPLICATE_LOGIN_MODAL_ID) as ModalState | undefined,
);

export const getDeleteObjectModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === DELETE_OBJECT_MODAL_ID) as ModalState<{ object: VirtualOfficeObject }> | undefined,
);

export const getDeleteBackgroundModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DELETE_BACKGROUND_MODAL_ID) as ModalState | undefined,
);

export const getStopMeetingRecordingModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === STOP_MEETING_RECORDING_MODAL_ID) as ModalState | undefined,
);

export const getWorkspaceRecordingPlanLimitationModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === WORKSPACE_RECORDING_PLAN_LIMITATION_MODAL_ID) as ModalState | undefined,
);

export const getVirtualOfficeUrlObject = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === VIRTUAL_OFFICE_URL_OBJECT_MODAL) as ModalState<UrlObjectModalData> | undefined,
);

export const getBroadcastStartModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === BROADCAST_START_MODAL_ID) as ModalState | undefined,
);

export const getRequestRemoteDesktopControlModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === REQUEST_REMOTE_DESKTOP_CONTROL_MODAL_ID) as ModalState | undefined,
);

export const getApproveRemoteDesktopControlModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === APPROVE_REMOTE_DESKTOP_CONTROL_MODAL_ID) as ModalState | undefined,
);

export const getDeclinedRemoteDesktopControlModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DELCINED_REMOTE_DESKTOP_CONTROL_MODAL_ID) as ModalState | undefined,
);

export const getDeleteWorkChannelSubversionModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === DELETE_WORK_CHANNEL_SUBVERSION_MODAL_ID) as
      | ModalState<{ subversionId: number }>
      | undefined,
);

export const getRestoreWorkChannelSubversionModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === RESTORE_WORK_CHANNEL_SUBVERSION_MODAL_ID) as
      | ModalState<{ subversionId: number }>
      | undefined,
);

export const getVirtualBackgroundSettingModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === VIRTUAL_BACKGROUND_SETTING_MODAL_ID) as ModalState | undefined,
);

export const getDeleteCustomObjectModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === DELETE_CUSTOM_OBJECT_MODAL_ID) as ModalState<{ objectId: number }> | undefined,
);

export const getDeleteCustomBackgroundModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === DELETE_CUSTOM_BACKGROUND_MODAL_ID) as
      | ModalState<{ workspaceBackgroundId: number }>
      | undefined,
);

export const getDeleteWorkChannelTemplateModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === DELETE_WORK_CHANNEL_TEMPLATE_MODAL_ID) as ModalState<{ templateId: number }> | undefined,
);

export const getApplyWorkChannelTemplateModal = createSelector(
  getShowModal,
  modals =>
    modals.find(m => m.id === APPLY_WORK_CHANNEL_TEMPLATE_MODAL_ID) as ModalState<{ templateId: number }> | undefined,
);

export const getQrcodeModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === QRCODE_MODAL_ID) as ModalState | undefined,
);

export const getWorkspaceTimeTrackerLimitationModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === WORKSPACE_TIME_TRACKER_LIMITATION_MODAL_ID) as ModalState | undefined,
);

export const getCustomPhraseModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === CUSTOM_PHRASE_MODAL_ID) as ModalState | undefined,
);

export const getIsSubtitleOpened = createSelector(getApp, appState => appState.isSubtitleOpened);

export const getCurrentTextChatWindowType = createSelector(getApp, appState => appState.textChatWindowType);
