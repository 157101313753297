import { pingResponse, startWorkingResponse, stopWorkingResponse, syncOfflineWorkResponse } from "./actions";
import { updateUserStartWorkingAction } from "../users/actions";

const TIME_TRACKER_EVENT_TYPE_PREFIX = "timeTracker/";

const EVENT_TYPES = {
  // Outgoing events we send
  OUT_START_WORKING_REQUEST: `${TIME_TRACKER_EVENT_TYPE_PREFIX}start-working-request`,
  OUT_STOP_WORKING_REQUEST: `${TIME_TRACKER_EVENT_TYPE_PREFIX}stop-working-request`,
  OUT_PING_WORKING_REQUEST: `${TIME_TRACKER_EVENT_TYPE_PREFIX}ping-working-request`,
  OUT_SYNC_OFFLINE_WORK_REQUEST: `${TIME_TRACKER_EVENT_TYPE_PREFIX}sync-offline-work-request`,

  // Incoming events we listen
  IN_START_WORKING_RESPONSE: `${TIME_TRACKER_EVENT_TYPE_PREFIX}start-working-response`,
  IN_START_WORKING_BROADCAST: `${TIME_TRACKER_EVENT_TYPE_PREFIX}start-working-broadcast`,
  IN_STOP_WORKING_RESPONSE: `${TIME_TRACKER_EVENT_TYPE_PREFIX}stop-working-response`,
  IN_PING_WORKING_RESPONSE: `${TIME_TRACKER_EVENT_TYPE_PREFIX}ping-working-response`,
  IN_SYNC_OFFLINE_WORK_RESPONSE: `${TIME_TRACKER_EVENT_TYPE_PREFIX}sync-offline-work-response`,
  IN_UPDATE_DESCRIPTION_BROADCAST: `${TIME_TRACKER_EVENT_TYPE_PREFIX}update-description-broadcast`,
};

const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(TIME_TRACKER_EVENT_TYPE_PREFIX)) {
    return;
  }

  switch (type) {
    case EVENT_TYPES.IN_START_WORKING_RESPONSE:
      store.dispatch(
        startWorkingResponse(
          payload.userId,
          payload.projectId,
          payload.workspaceId,
          payload.serverTimeTrackingId,
          payload.clientTimeTrackingId,
        ),
      );
      break;
    case EVENT_TYPES.IN_STOP_WORKING_RESPONSE:
      store.dispatch(stopWorkingResponse(payload.debugInfo));
      break;
    case EVENT_TYPES.IN_START_WORKING_BROADCAST:
      store.dispatch(updateUserStartWorkingAction({ userId: payload.userId, workingMemo: payload.workingMemo }));
      break;

    case EVENT_TYPES.IN_PING_WORKING_RESPONSE:
      store.dispatch(
        pingResponse(
          payload.userId,
          payload.projectId,
          payload.workspaceId,
          payload.serverTimeTrackingId,
          payload.clientTimeTrackingId,
          payload.crossedMidnight,
          payload.restarted,
        ),
      );
      break;
    case EVENT_TYPES.IN_SYNC_OFFLINE_WORK_RESPONSE:
      store.dispatch(
        syncOfflineWorkResponse(
          payload.userId,
          payload.projectId,
          payload.workspaceId,
          payload.serverIds,
          payload.syncError,
        ),
      );
      break;
  }
};

export { EVENT_TYPES as TIME_TRACKER_EVENT_TYPES, handleIncomingMessage as handleIncomingTimeTrackerMessage };
