import { ShareActions, ShareActionTypes } from "./actions";
import { Screenshare } from "../types";

export const shareInitialState = {} as Screenshare;

export type shareState = typeof shareInitialState;

export function shareReducer(state: shareState = shareInitialState, action: ShareActionTypes): shareState {
  switch (action.type) {
    case ShareActions.START_SHARE:
      return {
        ...state,
        mySharedScreen: {
          ...state.mySharedScreen,
          id: action.payload.sourceId,
          name: action.payload.sourceName,
          screenIndex: action.payload.screenIndex,
        },
      };
    case ShareActions.STOP_SHARE:
      return {
        ...state,
        mySharedScreen: {
          id: null,
          screenIndex: undefined,
          isDrawingAllowed: false,
        },
      };

    case ShareActions.VIEWER_DISCONNECTED:
      return {
        ...state,
        mySharedScreen: {
          ...state.mySharedScreen,
          viewers: state.mySharedScreen?.viewers?.filter(userId => userId !== action.payload.userId),
        },
      };
    case ShareActions.HANDLE_VIEWERS_LIST:
      return {
        ...state,
        mySharedScreen: { ...state.mySharedScreen, viewers: action.payload.viewers },
      };
    case ShareActions.HANDLE_VIEWER:
      if (!state.mySharedScreen?.id) {
        return state;
      }

      let viewers = state.mySharedScreen?.viewers;

      if (action.payload.activeWindowName === "VoicePing") {
        viewers =
          state.mySharedScreen?.viewers && state.mySharedScreen?.viewers.length > 0
            ? viewers?.some(userId => userId === action.payload.userId)
              ? viewers
              : [...state.mySharedScreen.viewers, action.payload.userId]
            : [action.payload.userId];
      } else {
        viewers = state.mySharedScreen?.viewers?.filter(userId => userId !== action.payload.userId);
      }

      return {
        ...state,
        mySharedScreen: { ...state.mySharedScreen, viewers },
      };
    default:
      return state;
  }
}
