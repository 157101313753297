import { startMeetingRecordingResponse, stopMeetingRecordingResponse } from ".";
import { getCurrentVoiceChannel } from "../voiceChannels";
import { updateWorkspaceMeetingRecordingCount } from "../workspace";
import {
  duplicateMeetingRecordingRequest,
  meetingRecordingErrorResponse,
  meetingRecordingMaxTimeLimitReached,
  noRecordingFileCreatedResponse,
  stopMeetingRecordingRequestProcessing,
  workspaceMaxRecordingLimitReached,
} from "./actions";

export const MEETING_RECORDING_EVENT_TYPES = {
  IN_MEETING_RECORDING_START_RESPONSE: "meeting-recording/start-response",
  IN_MEETING_RECORDING_STOP_RESPONSE: "meeting-recording/stop-response",
  OUT_MEETING_RECORDING_START_REQUEST: "meeting-recording/start-request",
  OUT_MEETING_RECORDING_STOP_REQUEST: "meeting-recording/stop-request",
  IN_MEETING_RECORDING_STOP_REQUEST: "meeting-recording/stop-request-processing",
  IN_MEETING_RECORDING_MAX_TIME_LIMIT_REACHED: "meeting-recording/max-time-limit-reached",
  IN_MEETING_RECORDING_DUPLICATE_REQUEST: "meeting-recording/duplicate-request",
  IN_MAX_WORKSPACE_RECORDING_LIMIT_REACHED: "meeting-recording/workspace-limit-reached",
  IN_MEETING_RECORDING_ERROR_RESPONSE: "meeting-recording/error-response",
  IN_MEETING_RECORDING_ERROR_NO_MEETING_RECORDING_FILE_CREATED: "meeting-recording/no-meeting-recording-file-created",
  OUT_DEBUG_MEETING_RECORDING_START_REQUEST: "meeting-recording/debug-start-request",
};

export const handleIncomingMeetingRecordingMessage = (store: any) => (type: string, payload: any) => {
  const {
    workspaceId,
    voiceChannelId,
    response,
    isAutoMeetingRecordingFeatureAvailed,
    recordingLimit,
    numberOfMeetingRecorded,
  } = payload;

  const channel = getCurrentVoiceChannel(store.getState());

  switch (type) {
    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_START_RESPONSE:
      if (channel?.id === voiceChannelId)
        store.dispatch(
          startMeetingRecordingResponse({
            workspaceId,
            voiceChannelId,
            response,
            isAutoMeetingRecordingFeatureAvailed,
          }),
        );

      break;

    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_STOP_RESPONSE:
      if (channel?.id === voiceChannelId) store.dispatch(stopMeetingRecordingResponse({ response }));

      if (channel?.workspaceId === workspaceId)
        store.dispatch(updateWorkspaceMeetingRecordingCount(numberOfMeetingRecorded, workspaceId));

      break;

    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_STOP_REQUEST:
      if (channel?.id === voiceChannelId) store.dispatch(stopMeetingRecordingRequestProcessing());

      break;

    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_MAX_TIME_LIMIT_REACHED:
      if (channel?.id === voiceChannelId) store.dispatch(meetingRecordingMaxTimeLimitReached());

      if (channel?.workspaceId === workspaceId)
        store.dispatch(updateWorkspaceMeetingRecordingCount(numberOfMeetingRecorded, workspaceId));

      break;
    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_DUPLICATE_REQUEST:
      store.dispatch(duplicateMeetingRecordingRequest());

      break;
    case MEETING_RECORDING_EVENT_TYPES.IN_MAX_WORKSPACE_RECORDING_LIMIT_REACHED:
      store.dispatch(workspaceMaxRecordingLimitReached(recordingLimit));
      break;

    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_ERROR_RESPONSE:
      if (channel?.id === voiceChannelId) store.dispatch(meetingRecordingErrorResponse());

      break;

    case MEETING_RECORDING_EVENT_TYPES.IN_MEETING_RECORDING_ERROR_NO_MEETING_RECORDING_FILE_CREATED:
      if (channel?.id === voiceChannelId) store.dispatch(noRecordingFileCreatedResponse());

      break;
  }
};
