export const displayLanguages = {
  default: "en",
  additional: ["ja"],
} as const;

export const defaultLanguages = {
  display: displayLanguages.default,
  speaking: "en-US",
  translation: {
    deepL: "JA",
    azure: "ja",
  },
};

export const translationLanguages = {
  /**
   * Copy from: https://cloud.google.com/translate/docs/languages
   */
  google: [
    { code: "af", name: "Afrikaans" },
    { code: "sq", name: "Shqip" },
    { code: "am", name: "አማርኛ" },
    { code: "ar", name: "العربية" },
    { code: "hy", name: "Հայերեն" },
    { code: "az", name: "Azərbaycan" },
    { code: "bn", name: "বাংলা" },
    { code: "bs", name: "Bosnian" },
    { code: "bg", name: "Български" },
    { code: "ca", name: "Català" },
    { code: "zh-CN", name: "简体中文" },
    { code: "zh-TW", name: "繁體中文" },
    { code: "hr", name: "Hrvatski" },
    { code: "cs", name: "Čeština" },
    { code: "da", name: "Dansk" },
    { code: "nl", name: "Nederlands" },
    { code: "en", name: "English" },
    { code: "et", name: "Eesti" },
    { code: "fi", name: "Suomi" },
    { code: "fr", name: "Français" },
    { code: "ka", name: "ქართული" },
    { code: "de", name: "Deutsch" },
    { code: "el", name: "Ελληνικά" },
    { code: "gu", name: "ગુજરાતી" },
    { code: "ht", name: "Haitian Creole" },
    { code: "he", name: "עברית" },
    { code: "hi", name: "हिन्दी" },
    { code: "hu", name: "Magyar" },
    { code: "is", name: "Íslenska" },
    { code: "id", name: "Indonesia" },
    { code: "ga", name: "Gaeilge" },
    { code: "it", name: "Italiano" },
    { code: "ja", name: "日本語" },
    { code: "kn", name: "ಕನ್ನಡ" },
    { code: "kk", name: "Қазақ Тілі" },
    { code: "km", name: "ខ្មែរ" },
    { code: "ko", name: "한국어" },
    { code: "ku", name: "Kurdî (Navîn)" },
    { code: "ky", name: "Kyrgyz" },
    { code: "lo", name: "ລາວ" },
    { code: "lv", name: "Latviešu" },
    { code: "lt", name: "Lietuvių" },
    { code: "mk", name: "Македонски" },
    { code: "mg", name: "Malagasy" },
    { code: "ms", name: "Melayu" },
    { code: "ml", name: "മലയാളം" },
    { code: "mt", name: "Malti" },
    { code: "mi", name: "Te Reo Māori" },
    { code: "mn", name: "Mongolian" },
    { code: "mr", name: "मराठी" },
    { code: "my", name: "မြန်မာ" },
    { code: "ne", name: "नेपाली" },
    { code: "or", name: "ଓଡ଼ିଆ" },
    { code: "ps", name: "پښتو" },
    { code: "fa", name: "فارسی" },
    { code: "pl", name: "Polski" },
    { code: "pt", name: "Português" },
    { code: "pa", name: "ਪੰਜਾਬੀ" },
    { code: "ro", name: "Română" },
    { code: "ru", name: "Русский" },
    { code: "sm", name: "Gagana Sāmoa" },
    { code: "sk", name: "Slovenčina" },
    { code: "sl", name: "Slovenščina" },
    { code: "es", name: "Español" },
    { code: "sw", name: "Kiswahili" },
    { code: "sv", name: "Svenska" },
    { code: "ta", name: "தமிழ்" },
    { code: "tt", name: "Татар" },
    { code: "te", name: "తెలుగు" },
    { code: "th", name: "ไทย" },
    { code: "tr", name: "Türkçe" },
    { code: "tk", name: "Türkmen Dili" },
    { code: "uk", name: "Українська" },
    { code: "ur", name: "اردو" },
    { code: "ug", name: "ئۇيغۇرچە" },
    { code: "uz", name: "Uzbek (Latin)" },
    { code: "vi", name: "Tiếng Việt" },
    { code: "cy", name: "Cymraeg" },
  ],
  /**
   * Copy from: https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation
   */
  azure: {
    af: { name: "Afrikaans", nativeName: "Afrikaans", dir: "ltr" },
    am: { name: "Amharic", nativeName: "አማርኛ", dir: "ltr" },
    ar: { name: "Arabic", nativeName: "العربية", dir: "rtl" },
    as: { name: "Assamese", nativeName: "অসমীয়া", dir: "ltr" },
    az: { name: "Azerbaijani", nativeName: "Azərbaycan", dir: "ltr" },
    ba: { name: "Bashkir", nativeName: "Bashkir", dir: "ltr" },
    bg: { name: "Bulgarian", nativeName: "Български", dir: "ltr" },
    bn: { name: "Bangla", nativeName: "বাংলা", dir: "ltr" },
    bo: { name: "Tibetan", nativeName: "བོད་སྐད་", dir: "ltr" },
    bs: { name: "Bosnian", nativeName: "Bosnian", dir: "ltr" },
    ca: { name: "Catalan", nativeName: "Català", dir: "ltr" },
    cs: { name: "Czech", nativeName: "Čeština", dir: "ltr" },
    cy: { name: "Welsh", nativeName: "Cymraeg", dir: "ltr" },
    da: { name: "Danish", nativeName: "Dansk", dir: "ltr" },
    de: { name: "German", nativeName: "Deutsch", dir: "ltr" },
    dv: { name: "Divehi", nativeName: "ދިވެހިބަސް", dir: "rtl" },
    el: { name: "Greek", nativeName: "Ελληνικά", dir: "ltr" },
    en: { name: "English", nativeName: "English", dir: "ltr" },
    es: { name: "Spanish", nativeName: "Español", dir: "ltr" },
    et: { name: "Estonian", nativeName: "Eesti", dir: "ltr" },
    fa: { name: "Persian", nativeName: "فارسی", dir: "rtl" },
    fi: { name: "Finnish", nativeName: "Suomi", dir: "ltr" },
    fil: { name: "Filipino", nativeName: "Filipino", dir: "ltr" },
    fj: { name: "Fijian", nativeName: "Na Vosa Vakaviti", dir: "ltr" },
    fr: { name: "French", nativeName: "Français", dir: "ltr" },
    "fr-CA": { name: "French (Canada)", nativeName: "Français (Canada)", dir: "ltr" },
    ga: { name: "Irish", nativeName: "Gaeilge", dir: "ltr" },
    gu: { name: "Gujarati", nativeName: "ગુજરાતી", dir: "ltr" },
    he: { name: "Hebrew", nativeName: "עברית", dir: "rtl" },
    hi: { name: "Hindi", nativeName: "हिन्दी", dir: "ltr" },
    hr: { name: "Croatian", nativeName: "Hrvatski", dir: "ltr" },
    ht: { name: "Haitian Creole", nativeName: "Haitian Creole", dir: "ltr" },
    hu: { name: "Hungarian", nativeName: "Magyar", dir: "ltr" },
    hy: { name: "Armenian", nativeName: "Հայերեն", dir: "ltr" },
    id: { name: "Indonesian", nativeName: "Indonesia", dir: "ltr" },
    is: { name: "Icelandic", nativeName: "Íslenska", dir: "ltr" },
    it: { name: "Italian", nativeName: "Italiano", dir: "ltr" },
    iu: { name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ", dir: "ltr" },
    ja: { name: "Japanese", nativeName: "日本語", dir: "ltr" },
    ka: { name: "Georgian", nativeName: "ქართული", dir: "ltr" },
    kk: { name: "Kazakh", nativeName: "Қазақ Тілі", dir: "ltr" },
    km: { name: "Khmer", nativeName: "ខ្មែរ", dir: "ltr" },
    kmr: { name: "Kurdish (Northern)", nativeName: "Kurdî (Bakur)", dir: "ltr" },
    kn: { name: "Kannada", nativeName: "ಕನ್ನಡ", dir: "ltr" },
    ko: { name: "Korean", nativeName: "한국어", dir: "ltr" },
    ku: { name: "Kurdish (Central)", nativeName: "Kurdî (Navîn)", dir: "rtl" },
    ky: { name: "Kyrgyz", nativeName: "Kyrgyz", dir: "ltr" },
    lo: { name: "Lao", nativeName: "ລາວ", dir: "ltr" },
    lt: { name: "Lithuanian", nativeName: "Lietuvių", dir: "ltr" },
    lv: { name: "Latvian", nativeName: "Latviešu", dir: "ltr" },
    lzh: { name: "Chinese (Literary)", nativeName: "中文 (文言文)", dir: "ltr" },
    mg: { name: "Malagasy", nativeName: "Malagasy", dir: "ltr" },
    mi: { name: "Māori", nativeName: "Te Reo Māori", dir: "ltr" },
    mk: { name: "Macedonian", nativeName: "Македонски", dir: "ltr" },
    ml: { name: "Malayalam", nativeName: "മലയാളം", dir: "ltr" },
    "mn-Cyrl": { name: "Mongolian (Cyrillic)", nativeName: "Mongolian (Cyrillic)", dir: "ltr" },
    "mn-Mong": { name: "Mongolian (Traditional)", nativeName: "ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ", dir: "ltr" },
    mr: { name: "Marathi", nativeName: "मराठी", dir: "ltr" },
    ms: { name: "Malay", nativeName: "Melayu", dir: "ltr" },
    mt: { name: "Maltese", nativeName: "Malti", dir: "ltr" },
    mww: { name: "Hmong Daw", nativeName: "Hmong Daw", dir: "ltr" },
    my: { name: "Myanmar (Burmese)", nativeName: "မြန်မာ", dir: "ltr" },
    nb: { name: "Norwegian", nativeName: "Norsk Bokmål", dir: "ltr" },
    ne: { name: "Nepali", nativeName: "नेपाली", dir: "ltr" },
    nl: { name: "Dutch", nativeName: "Nederlands", dir: "ltr" },
    or: { name: "Odia", nativeName: "ଓଡ଼ିଆ", dir: "ltr" },
    otq: { name: "Querétaro Otomi", nativeName: "Hñähñu", dir: "ltr" },
    pa: { name: "Punjabi", nativeName: "ਪੰਜਾਬੀ", dir: "ltr" },
    pl: { name: "Polish", nativeName: "Polski", dir: "ltr" },
    prs: { name: "Dari", nativeName: "دری", dir: "rtl" },
    ps: { name: "Pashto", nativeName: "پښتو", dir: "rtl" },
    pt: { name: "Portuguese (Brazil)", nativeName: "Português (Brasil)", dir: "ltr" },
    "pt-PT": { name: "Portuguese (Portugal)", nativeName: "Português (Portugal)", dir: "ltr" },
    ro: { name: "Romanian", nativeName: "Română", dir: "ltr" },
    ru: { name: "Russian", nativeName: "Русский", dir: "ltr" },
    sk: { name: "Slovak", nativeName: "Slovenčina", dir: "ltr" },
    sl: { name: "Slovenian", nativeName: "Slovenščina", dir: "ltr" },
    sm: { name: "Samoan", nativeName: "Gagana Sāmoa", dir: "ltr" },
    sq: { name: "Albanian", nativeName: "Shqip", dir: "ltr" },
    "sr-Cyrl": { name: "Serbian (Cyrillic)", nativeName: "Српски (ћирилица)", dir: "ltr" },
    "sr-Latn": { name: "Serbian (Latin)", nativeName: "Srpski (latinica)", dir: "ltr" },
    sv: { name: "Swedish", nativeName: "Svenska", dir: "ltr" },
    sw: { name: "Swahili", nativeName: "Kiswahili", dir: "ltr" },
    ta: { name: "Tamil", nativeName: "தமிழ்", dir: "ltr" },
    te: { name: "Telugu", nativeName: "తెలుగు", dir: "ltr" },
    th: { name: "Thai", nativeName: "ไทย", dir: "ltr" },
    ti: { name: "Tigrinya", nativeName: "ትግር", dir: "ltr" },
    tk: { name: "Turkmen", nativeName: "Türkmen Dili", dir: "ltr" },
    "tlh-Latn": { name: "Klingon (Latin)", nativeName: "Klingon (Latin)", dir: "ltr" },
    "tlh-Piqd": { name: "Klingon (pIqaD)", nativeName: "Klingon (pIqaD)", dir: "ltr" },
    to: { name: "Tongan", nativeName: "Lea Fakatonga", dir: "ltr" },
    tr: { name: "Turkish", nativeName: "Türkçe", dir: "ltr" },
    tt: { name: "Tatar", nativeName: "Татар", dir: "ltr" },
    ty: { name: "Tahitian", nativeName: "Reo Tahiti", dir: "ltr" },
    ug: { name: "Uyghur", nativeName: "ئۇيغۇرچە", dir: "rtl" },
    uk: { name: "Ukrainian", nativeName: "Українська", dir: "ltr" },
    ur: { name: "Urdu", nativeName: "اردو", dir: "rtl" },
    uz: { name: "Uzbek (Latin)", nativeName: "Uzbek (Latin)", dir: "ltr" },
    vi: { name: "Vietnamese", nativeName: "Tiếng Việt", dir: "ltr" },
    yua: { name: "Yucatec Maya", nativeName: "Yucatec Maya", dir: "ltr" },
    yue: { name: "Cantonese (Traditional)", nativeName: "粵語 (繁體)", dir: "ltr" },
    "zh-Hans": { name: "Chinese Simplified", nativeName: "简体中文", dir: "ltr" },
    "zh-Hant": { name: "Chinese Traditional", nativeName: "繁體中文", dir: "ltr" },
  },
  /**
   * Copy from: https://www.deepl.com/docs-api/translating-text/
   */
  deepL: {
    source: [
      { code: "DE", name: "Deutsch" },
      { code: "EN", name: "English" },
      { code: "FR", name: "Français" },
      { code: "IT", name: "Italiano" },
      { code: "JA", name: "日本語" },
      { code: "ES", name: "Español" },
      { code: "NL", name: "Nederlands" },
      { code: "PL", name: "Polski" },
      { code: "PT", name: "Português" },
      { code: "RU", name: "Pусский" },
      { code: "ZH", name: "中文" },
    ],
    target: [
      { code: "DE", name: "Deutsch" },
      { code: "EN", name: "English" },
      { code: "FR", name: "Français" },
      { code: "IT", name: "Italiano" },
      { code: "JA", name: "日本語" },
      { code: "ES", name: "Español" },
      { code: "NL", name: "Nederlands" },
      { code: "PL", name: "Polski" },
      { code: "PT-PT", name: "Português" },
      { code: "PT-BR", name: "Português (Brasil)" },
      // // unspecified variant for backward compatibility; please select PT-PT or PT-BR instead
      // { code: 'PT', name: 'Portuguese' },
      { code: "RU", name: "Pусский" },
      { code: "ZH", name: "中文" },
    ],
  },
};

export const displayLanguageLabels = [
  { code: "en", label: "English" },
  { code: "ja", label: "日本語" },
];

export const speechRecognitionLanguages = {
  /**
   * Copy from: https://www.google.com/intl/en/chrome/demos/speech.html (view source -> Ctrl+F -> langs)
   *
   * Run code below to convert:
   *
   * ```
   * JSON.stringify(langs.map(([name, ...variations]) => ({
   *  name,
   *  variations: variations.length === 1
   *    ? [{ code: variations[0][0] }]
   *    : variations.map(v => ({ code: v[0], name: v[1] })),
   * })));
   * ```
   */
  browserSpeechRecognition: [
    { name: "Afrikaans", variations: [{ code: "af-ZA" }] },
    { name: "አማርኛ", variations: [{ code: "am-ET" }] },
    { name: "Azərbaycanca", variations: [{ code: "az-AZ" }] },
    {
      name: "বাংলা",
      variations: [
        { code: "bn-BD", name: "বাংলাদেশ" },
        { code: "bn-IN", name: "ভারত" },
      ],
    },
    { name: "Bahasa Indonesia", variations: [{ code: "id-ID" }] },
    { name: "Bahasa Melayu", variations: [{ code: "ms-MY" }] },
    { name: "Català", variations: [{ code: "ca-ES" }] },
    { name: "Čeština", variations: [{ code: "cs-CZ" }] },
    { name: "Dansk", variations: [{ code: "da-DK" }] },
    { name: "Deutsch", variations: [{ code: "de-DE" }] },
    {
      name: "English",
      variations: [
        { code: "en-AU", name: "Australia" },
        { code: "en-CA", name: "Canada" },
        { code: "en-IN", name: "India" },
        { code: "en-KE", name: "Kenya" },
        { code: "en-TZ", name: "Tanzania" },
        { code: "en-GH", name: "Ghana" },
        { code: "en-NZ", name: "New Zealand" },
        { code: "en-NG", name: "Nigeria" },
        { code: "en-ZA", name: "South Africa" },
        { code: "en-PH", name: "Philippines" },
        { code: "en-GB", name: "United Kingdom" },
        { code: "en-US", name: "United States" },
      ],
    },
    {
      name: "Español",
      variations: [
        { code: "es-AR", name: "Argentina" },
        { code: "es-BO", name: "Bolivia" },
        { code: "es-CL", name: "Chile" },
        { code: "es-CO", name: "Colombia" },
        { code: "es-CR", name: "Costa Rica" },
        { code: "es-EC", name: "Ecuador" },
        { code: "es-SV", name: "El Salvador" },
        { code: "es-ES", name: "España" },
        { code: "es-US", name: "Estados Unidos" },
        { code: "es-GT", name: "Guatemala" },
        { code: "es-HN", name: "Honduras" },
        { code: "es-MX", name: "México" },
        { code: "es-NI", name: "Nicaragua" },
        { code: "es-PA", name: "Panamá" },
        { code: "es-PY", name: "Paraguay" },
        { code: "es-PE", name: "Perú" },
        { code: "es-PR", name: "Puerto Rico" },
        { code: "es-DO", name: "República Dominicana" },
        { code: "es-UY", name: "Uruguay" },
        { code: "es-VE", name: "Venezuela" },
      ],
    },
    { name: "Euskara", variations: [{ code: "eu-ES" }] },
    { name: "Filipino", variations: [{ code: "fil-PH" }] },
    { name: "Français", variations: [{ code: "fr-FR" }] },
    { name: "Basa Jawa", variations: [{ code: "jv-ID" }] },
    { name: "Galego", variations: [{ code: "gl-ES" }] },
    { name: "ગુજરાતી", variations: [{ code: "gu-IN" }] },
    { name: "Hrvatski", variations: [{ code: "hr-HR" }] },
    { name: "IsiZulu", variations: [{ code: "zu-ZA" }] },
    { name: "Íslenska", variations: [{ code: "is-IS" }] },
    {
      name: "Italiano",
      variations: [
        { code: "it-IT", name: "Italia" },
        { code: "it-CH", name: "Svizzera" },
      ],
    },
    { name: "ಕನ್ನಡ", variations: [{ code: "kn-IN" }] },
    { name: "ភាសាខ្មែរ", variations: [{ code: "km-KH" }] },
    { name: "Latviešu", variations: [{ code: "lv-LV" }] },
    { name: "Lietuvių", variations: [{ code: "lt-LT" }] },
    { name: "മലയാളം", variations: [{ code: "ml-IN" }] },
    { name: "मराठी", variations: [{ code: "mr-IN" }] },
    { name: "Magyar", variations: [{ code: "hu-HU" }] },
    { name: "ລາວ", variations: [{ code: "lo-LA" }] },
    { name: "Nederlands", variations: [{ code: "nl-NL" }] },
    { name: "नेपाली भाषा", variations: [{ code: "ne-NP" }] },
    { name: "Norsk bokmål", variations: [{ code: "nb-NO" }] },
    { name: "Polski", variations: [{ code: "pl-PL" }] },
    {
      name: "Português",
      variations: [
        { code: "pt-BR", name: "Brasil" },
        { code: "pt-PT", name: "Portugal" },
      ],
    },
    { name: "Română", variations: [{ code: "ro-RO" }] },
    { name: "සිංහල", variations: [{ code: "si-LK" }] },
    { name: "Slovenščina", variations: [{ code: "sl-SI" }] },
    { name: "Basa Sunda", variations: [{ code: "su-ID" }] },
    { name: "Slovenčina", variations: [{ code: "sk-SK" }] },
    { name: "Suomi", variations: [{ code: "fi-FI" }] },
    { name: "Svenska", variations: [{ code: "sv-SE" }] },
    {
      name: "Kiswahili",
      variations: [
        { code: "sw-TZ", name: "Tanzania" },
        { code: "sw-KE", name: "Kenya" },
      ],
    },
    { name: "ქართული", variations: [{ code: "ka-GE" }] },
    { name: "Հայերեն", variations: [{ code: "hy-AM" }] },
    {
      name: "தமிழ்",
      variations: [
        { code: "ta-IN", name: "இந்தியா" },
        { code: "ta-SG", name: "சிங்கப்பூர்" },
        { code: "ta-LK", name: "இலங்கை" },
        { code: "ta-MY", name: "மலேசியா" },
      ],
    },
    { name: "తెలుగు", variations: [{ code: "te-IN" }] },
    { name: "Tiếng Việt", variations: [{ code: "vi-VN" }] },
    { name: "Türkçe", variations: [{ code: "tr-TR" }] },
    {
      name: "اُردُو",
      variations: [
        { code: "ur-PK", name: "پاکستان" },
        { code: "ur-IN", name: "بھارت" },
      ],
    },
    { name: "Ελληνικά", variations: [{ code: "el-GR" }] },
    { name: "български", variations: [{ code: "bg-BG" }] },
    { name: "Pусский", variations: [{ code: "ru-RU" }] },
    { name: "Српски", variations: [{ code: "sr-RS" }] },
    { name: "Українська", variations: [{ code: "uk-UA" }] },
    { name: "한국어", variations: [{ code: "ko-KR" }] },
    {
      name: "普通话",
      variations: [
        { code: "cmn-Hans-CN", name: "中国大陆" },
        { code: "cmn-Hans-HK", name: "香港" },
      ],
    },
    { name: "中文 (台灣)", variations: [{ code: "cmn-Hant-TW" }] },
    { name: "粵語 (香港)", variations: [{ code: "yue-Hant-HK" }] },
    { name: "日本語", variations: [{ code: "ja-JP" }] },
    { name: "हिन्दी", variations: [{ code: "hi-IN" }] },
    { name: "ภาษาไทย", variations: [{ code: "th-TH" }] },
  ],

  /**
   * Copy from https://cloud.google.com/speech-to-text/docs/languages
   */
  googleSpeechToTextAPI: [
    // TODO: copy and fill
  ],
  /**
   * Copy from https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/language-support
   */
  azureSpeechToTextAPI: [
    { name: "български", variations: [{ code: "bg-BG" }] },
    { name: "Català", variations: [{ code: "ca-ES" }] },
    {
      name: "普通话",
      variations: [
        { code: "zh-CN", name: "中国大陆" },
        { code: "zh-HK", name: "香港" },
      ],
    },
    { name: "中文 (台灣)", variations: [{ code: "zh-TW" }] },
    { name: "Hrvatski", variations: [{ code: "hr-HR" }] },
    { name: "Čeština", variations: [{ code: "cs-CZ" }] },
    { name: "Dansk", variations: [{ code: "da-DK" }] },
    { name: "Nederlands", variations: [{ code: "nl-NL" }] },
    {
      name: "English",
      variations: [
        { name: "Australia", code: "en-AU" },
        { name: "Canada", code: "en-CA" },
        { name: "Ghana", code: "en-GH" },
        { name: "Hong Kong", code: "en-HK" },
        { name: "India", code: "en-IN" },
        { name: "Ireland", code: "en-IE" },
        { name: "Kenya", code: "en-KE" },
        { name: "New Zealand", code: "en-NZ" },
        { name: "Nigeria", code: "en-NG" },
        { name: "Philippines", code: "en-PH" },
        { name: "Singapore", code: "en-SG" },
        { name: "South Africa", code: "en-ZA" },
        { name: "Tanzania", code: "en-TZ" },
        { name: "United Kingdom", code: "en-GB" },
        { name: "United States", code: "en-US" },
      ],
    },
    { name: "Suomi", variations: [{ code: "fi-FI" }] },
    {
      name: "Français",
      variations: [
        { name: "Canada", code: "fr-CA" },
        { name: "France", code: "fr-FR" },
        { name: "Switzerland", code: "fr-CH" },
      ],
    },
    {
      name: "Deutsch",
      variations: [
        { name: "Austria", code: "de-AT" },
        { name: "Switzerland", code: "de-CH" },
        { name: "Germany", code: "de-DE" },
      ],
    },
    { name: "Ελληνικά", variations: [{ code: "el-GR" }] },
    { name: "ગુજરાતી", variations: [{ code: "gu-IN" }] },
    { name: "हिन्दी", variations: [{ code: "hi-IN" }] },
    { name: "Magyar", variations: [{ code: "hu-HU" }] },
    { name: "Italiano", variations: [{ code: "it-IT" }] },
    { name: "日本語", variations: [{ code: "ja-JP" }] },
    { name: "한국어", variations: [{ code: "ko-KR" }] },
    { name: "Latviešu", variations: [{ code: "lv-LV" }] },
    { name: "Lietuvių", variations: [{ code: "lt-LT" }] },
    { name: "मराठी", variations: [{ code: "mr-IN" }] },
    { name: "Norsk bokmål", variations: [{ code: "nb-NO" }] },
    { name: "Polski", variations: [{ code: "pl-PL" }] },
    {
      name: "Português",
      variations: [
        { name: "Brasil", code: "pt-BR" },
        { name: "Portugal", code: "pt-PT" },
      ],
    },
    { name: "Română", variations: [{ code: "ro-RO" }] },
    { name: "Pусский", variations: [{ code: "ru-RU" }] },
    { name: "Slovenčina", variations: [{ code: "sk-SK" }] },
    { name: "Slovenščina", variations: [{ code: "sl-SI" }] },
    {
      name: "Español",
      variations: [
        { name: "Argentina", code: "es-AR" },
        { name: "Bolivia", code: "es-BO" },
        { name: "Chile", code: "es-CL" },
        { name: "Colombia", code: "es-CO" },
        { name: "Costa Rica", code: "es-CR" },
        { name: "Cuba", code: "es-CU" },
        { name: "Dominican Republic", code: "es-DO" },
        { name: "Ecuador", code: "es-EC" },
        { name: "El Salvador", code: "es-SV" },
        { name: "Equatorial Guinea", code: "es-GQ" },
        { name: "Guatemala", code: "es-GT" },
        { name: "Honduras", code: "es-HN" },
        { name: "Mexico", code: "es-MX" },
        { name: "Nicaragua", code: "es-NI" },
        { name: "Panama", code: "es-PA" },
        { name: "Paraguay", code: "es-PY" },
        { name: "Peru", code: "es-PE" },
        { name: "Puerto Rico", code: "es-PR" },
        { name: "Spain", code: "es-ES" },
        { name: "Uruguay", code: "es-UY" },
        { name: "USA", code: "es-US" },
        { name: "Venezuela", code: "es-VE" },
      ],
    },
    { name: "Svenska", variations: [{ code: "sv-SE" }] },
    {
      name: "Indian",
      variations: [
        { name: "இந்தியா", code: "ta-IN" },
        { name: "తెలుగు", code: "te-IN" },
      ],
    },
    { name: "ภาษาไทย", variations: [{ code: "th-TH" }] },
    { name: "Türkçe", variations: [{ code: "tr-TR" }] },
    { name: "Tiếng Việt", variations: [{ code: "vi-VN" }] },
  ],
};

const azureTranslationLanguages = Object.entries(translationLanguages.azure).map(([code, l]) => ({
  code,
  name: l.nativeName,
}));

export const allowedTranslationLanguages = ["en", "ja", "zh-CN", "zh-TW", "vi"];

export const allSourceTranslationLanguages = {
  deepL: translationLanguages.deepL.source,
  azure: azureTranslationLanguages,
  google: translationLanguages.google,
};

export const allTargetTranslationLanguages = {
  deepL: translationLanguages.deepL.target,
  azure: azureTranslationLanguages,
  google: translationLanguages.google,
};

export const allSpeakingLanguages = speechRecognitionLanguages.azureSpeechToTextAPI
  .map(group =>
    (group.variations as { code: string; name?: string }[]).map(l => ({
      code: l.code,
      name: l.name ? `${group.name} (${l.name})` : group.name,
    })),
  )
  .flat();

export const languageNamesByCode = Object.fromEntries([
  ...displayLanguageLabels.map(l => [l.code, l.label]),
  ...allSourceTranslationLanguages.azure.map(l => [l.code, l.name]),
  ...allTargetTranslationLanguages.azure.map(l => [l.code, l.name]),
  ...allSourceTranslationLanguages.deepL.map(l => [l.code, l.name]),
  ...allTargetTranslationLanguages.deepL.map(l => [l.code, l.name]),
  ...allSourceTranslationLanguages.google.map(l => [l.code, l.name]),
  ...allTargetTranslationLanguages.google.map(l => [l.code, l.name]),
  ...allSpeakingLanguages.map(l => [l.code, l.name]),
]);

export const displayLanguageCodes = ["en", "ja", "vi", "ko"];
