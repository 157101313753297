import { getDeviceInfo } from "../../../../utils/helpers";
import { log, LogCategory } from "../../../../utils/log";
import { KnownApp, knownBrowsers, knownDesktopApps, knownMacBrowsers, knownWebApps } from "./knownApps";

const { os } = getDeviceInfo();

export function identifyActiveApp(
  windowTitle: string | null,
  iconURL: string | null,
  iconHash: string | null,
): { icon: string; name: string } | null {
  if (!windowTitle) {
    return null;
  }

  let browser = null;

  if (os === "macos" && iconHash) {
    browser = knownMacBrowsers.find(b => b.iconHash!.indexOf(iconHash) > -1);
  } else {
    browser = knownBrowsers.find(b => windowTitle.endsWith(b.suffix!));
  }

  if (browser) {
    let activeBrowserTabTitle = windowTitle;

    if (os !== "macos" && browser.suffix) {
      const titleLength = windowTitle.length - browser.suffix.length;

      activeBrowserTabTitle = windowTitle.substring(0, titleLength);
    }

    const app =
      knownWebApps.fixedNames[windowTitle] ?? getKnownAppUsingRegex(activeBrowserTabTitle, knownWebApps.regexPatterns);

    log(LogCategory.ActiveApp, `Detected window as Browser - Actual title: "${windowTitle}"`);

    return app
      ? {
          icon: app.web!.icon,
          name: app.displayName,
        }
      : {
          icon: browser.icon || iconURL!,
          name: browser.displayName,
        };
  } else {
    const app =
      knownDesktopApps.fixedNames[windowTitle] ??
      knownDesktopApps.iconHashs[iconHash || ""] ??
      getKnownAppUsingRegex(windowTitle, knownDesktopApps.regexPatterns);

    log(LogCategory.ActiveApp, `Detected window as App - Actual title: "${windowTitle}"`);

    return app
      ? {
          icon: app.desktop?.icon ?? iconURL!,
          name: app.displayName,
        }
      : null;
  }
}

function getKnownAppUsingRegex(title: string, regexPatterns: { regex: RegExp; app: KnownApp }[]) {
  const element = regexPatterns.find(el => el.regex.test(title));

  return element?.app;
}
