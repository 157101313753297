import { createSelector } from "reselect";
import { State } from "../state";

export const getWorkspaceBackgroundState = (state: State) => state.workspaceBackground;

export function getWorkspaceBackgrounds(state: State) {
  return state.workspaceBackground.workspaceBackgrounds;
}

export const getWorkspaceBackgroundsExceptDeployed = createSelector(
  getWorkspaceBackgroundState,
  workspaceBackgroundState => workspaceBackgroundState.workspaceBackgrounds,
);

export const getWorkspaceBackgroundById = (workspaceBackgroundId: number) =>
  createSelector(getWorkspaceBackgroundState, workspaceBackgroundState =>
    workspaceBackgroundState.workspaceBackgrounds.find(el => el.id === workspaceBackgroundId),
  );

export const getWorkspaceBackgroundForDefaultFloorTemplate = createSelector(
  getWorkspaceBackgroundState,
  workspaceBackgroundState =>
    workspaceBackgroundState.workspaceBackgrounds.find(el => el.isDefaultTemplate && el.type === "floor"),
);

export const getWorkspaceBackgroundForDefaultRoomTemplate = createSelector(
  getWorkspaceBackgroundState,
  workspaceBackgroundState =>
    workspaceBackgroundState.workspaceBackgrounds.find(el => el.isDefaultTemplate && el.type === "room"),
);
