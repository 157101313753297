import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { postFCMToken } from "../api/user";
import { getNotificationPermission } from "../utils/helpers";

const firebaseConfig: { [key: string]: string | undefined } = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
};

const swUrl = `${window.location.origin}/firebase-messaging-sw.js`;

export const app = initializeApp(firebaseConfig);

export const IsFirebaseServiceWorkerRegister = () => {
  if ("serviceWorker" in navigator && typeof window.navigator.serviceWorker !== "undefined") {
    return window.navigator.serviceWorker.getRegistration("/firebase-push-notification-scope").then(serviceWorker => {
      if (serviceWorker) {
        return true;
      } else {
        return false;
      }
    });
  }

  throw new Error("The browser doesn`t support service worker.");
};

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator && typeof window.navigator.serviceWorker !== "undefined") {
    return window.navigator.serviceWorker.getRegistration("/firebase-push-notification-scope").then(serviceWorker => {
      if (serviceWorker) return serviceWorker;

      return window.navigator.serviceWorker.register(swUrl, {
        scope: "/",
      });
    });
  }

  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;

    if (messagingResolve) {
      return getOrRegisterServiceWorker().then(serviceWorkerRegistration => {
        return Promise.resolve(
          getToken(messagingResolve, {
            vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
            serviceWorkerRegistration,
          }),
        );
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

export const messaging = (async () => {
  try {
    if (window.electron) {
      return null;
    }

    const isSupportedBrowser = await isSupported();

    if (!(await IsFirebaseServiceWorkerRegister())) {
      await getOrRegisterServiceWorker();
      const token = await getFirebaseToken();

      if (token) await postFCMToken(token);
    }

    if (isSupportedBrowser) {
      return getMessaging(app);
    }

    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const requestWebNotificationPermission = async () => {
  const permission = await getNotificationPermission();

  if (permission === "granted") {
    return true;
  } else if (permission === "denied") {
    return false;
  }

  return false;
};

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.then(messagingResolve => {
      if (messagingResolve) {
        onMessage(messagingResolve, payload => {
          resolve(payload);
        });
      }
    });
  });
