import { PNG_ICONS_S3_URL } from "../../../../constant";
import { KNOWN_APPS_LIST } from "./known-apps-list";

export interface KnownBrowser {
  displayName: string;
  suffix?: string;
  icon?: string;
  iconHash?: string[];
}

export interface KnownApp {
  displayName: string;
  desktop?: KnownAppDesktop;
  web?: KnownAppWeb;
}

export interface KnownAppDesktop extends KnownAppName {
  icon?: string;
  iconHash?: string[];
}

export interface KnownAppWeb extends KnownAppName {
  icon: string;
}

export interface KnownAppName {
  fixedNames?: string[];
  regexPatterns?: RegExp[];
}

export const knownBrowsers: KnownBrowser[] = [
  {
    displayName: "Chrome",
    suffix: " - Google Chrome",
    icon: PNG_ICONS_S3_URL + "Chrome.svg",
  },
  {
    displayName: "Edge",
    suffix: " - Microsoft​ Edge",
    icon: PNG_ICONS_S3_URL + "Edge.svg",
  },
  {
    displayName: "Firefox",
    suffix: " - Mozilla Firefox",
    icon: PNG_ICONS_S3_URL + "Firefox.svg",
  },
  { displayName: "Chromium", suffix: " - Chromium" },
];

export const knownMacBrowsers: KnownBrowser[] = [
  {
    displayName: "Chrome",
    icon: PNG_ICONS_S3_URL + "Chrome.svg",
    iconHash: [
      "baa582ad482228f487323023d6c5035dbf618ed1",
      "0b8b43c1e108816bf97322fe749097f8903018ac",
      "e34edf8fa6ff91733ccfe74d738a9dd4cf1dc761",
      "71d6b21cc1e0762619427aa27a96809737983541",
    ],
  },
  {
    displayName: "Chromium",
    iconHash: ["d65f7bd2f4365ced56b0438ce3335f768357b4e9"],
  },
  {
    displayName: "Safari",
    icon: PNG_ICONS_S3_URL + "Safari.svg",
    iconHash: [
      "ede802d836d8d2770c9c9493db75ef5e3d11ca68",
      "567ae92005c433b9b83389f0ac3716c62fab4562",
      "7730ced3a9418fb58e47c10476a4092c8bd65842",
      "def2f99081c07504e8c39a3314e0e82817c49318",
    ],
  },
];

const knownAppsList: KnownApp[] = KNOWN_APPS_LIST;

export const knownWebApps = {
  fixedNames: Object.fromEntries(
    knownAppsList.map(app => (app.web?.fixedNames ? app.web.fixedNames?.map(name => [name, app] as const) : [])).flat(),
  ),
  regexPatterns: knownAppsList
    .map(app => (app.web?.regexPatterns ? app.web.regexPatterns?.map(regex => ({ regex, app })) : []))
    .flat(),
};

export const knownDesktopApps = {
  fixedNames: Object.fromEntries(
    knownAppsList
      .map(app => (app.desktop?.fixedNames ? app.desktop.fixedNames?.map(name => [name, app] as const) : []))
      .flat(),
  ),
  regexPatterns: knownAppsList
    .map(app => (app.desktop?.regexPatterns ? app.desktop.regexPatterns?.map(regex => ({ regex, app })) : []))
    .flat(),
  iconHashs: Object.fromEntries(
    knownAppsList
      .map(app => (app.desktop?.iconHash ? (app.desktop.iconHash ?? []).map(hash => [hash, app]) : []))
      .flat(),
  ),
};
