import { WindowTypes } from "../components/core/TitleBar/TitleBar";
import { Language } from "../i18n";
import { TimeTrackingRequestData } from "../store/timeTracker/actions";
import { MacPermission } from "../store/types";
import {
  InviteGotResponseVoiceChannel,
  InviteSendResponseVoiceChannel,
  JoinVoiceChannelFromSubscribingKnock,
  ReminderSendResponseVoiceChannel,
} from "../store/voiceChannels";
import { log, LogCategory } from "../utils/log";
import { channels } from "./channels";
import { Config } from "./config";
import { ActiveWindowSource, ScreenShareWindowSource } from "./ipc";
import { ElectronWindowsSettings } from "./windows";

//IPC calls without messages
export async function sendMessageOverIPC(channel: typeof channels.RELOAD_APP): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.APP_VERSION): Promise<any>;
export async function sendMessageOverIPC(channel: typeof channels.APP_LATEST_VERSION): Promise<string>;
export async function sendMessageOverIPC(channel: typeof channels.CONFIG_LOAD): Promise<Config>;
export async function sendMessageOverIPC(channel: typeof channels.GET_IDLE_TIME): Promise<number>;
export async function sendMessageOverIPC(channel: typeof channels.CONFIRM_CLOSE): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.CLEAR_NOTIFICATIONS): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_APP): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.WEB_LOAD_SUCCESS): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.GET_CPU_INFO): Promise<void>;

export async function sendMessageOverIPC(
  channel: typeof channels.STOPPED_WORKING,
  message: null,
  ignoreError: true,
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.WILL_APP_LAUNCH_AT_LOGIN): Promise<boolean>;
export async function sendMessageOverIPC(channel: typeof channels.APP_UPDATE): Promise<boolean>;
export async function sendMessageOverIPC(
  channel: typeof channels.GET_ACTIVE_WIN_SOURCES,
): Promise<ActiveWindowSource[]>;
export async function sendMessageOverIPC(
  channel: typeof channels.GET_SCREENSHARE_SOURCES,
): Promise<ScreenShareWindowSource[]>;

//IPC calls with messages
export async function sendMessageOverIPC(
  channel: typeof channels.CHECK_PERMISSION,
  message: MacPermission,
): Promise<boolean>;
export async function sendMessageOverIPC(channel: typeof channels.SET_USER, message: any): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.SET_LOGIN_SETINGS,
  launchAtStartup: boolean,
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.MINIMIZE_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.MAXIMIZE_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.UNMAXIMIZE_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.I18N_SETUP, message: Language): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.OPEN_WINDOW,
  message: ElectronWindowsSettings,
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.CLEAR_UNREAD_MESSAGE_IDS,
  messageIds: number[],
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.CLOSE_WINDOW,
  args: { windowId: WindowTypes; isRequestFromPiP: boolean; isAppCloseRequest: boolean },
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.UPDATE_APP, force: boolean): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.STARTED_WORKING,
  data: TimeTrackingRequestData,
): Promise<void>;

export async function sendMessageOverIPC(channel: typeof channels.STOP_SCREENSHOT): Promise<void>;

export async function sendMessageOverIPC(
  channel: typeof channels.START_SCREENSHOT,
  data: { takeScreenshot: boolean },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.UPDATE_TRACKING,
  data: { updateTracking: boolean },
): Promise<void>;

export async function sendMessageOverIPC(
  channel: typeof channels.SET_MENU_TIMER,
  arg: { initialSeconds: number; startedAt?: string },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.INTERNET_CONNECTIVITY,
  connected: boolean,
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SIMULATE_USER_INPUT): Promise<boolean>;

// Separate window actions
export async function sendMessageOverIPC(channel: typeof channels.STOP_SHARE): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.START_SHARE): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.LOADED_SUB_WINDOW, frameName: string): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.FOCUS_MAIN_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.CLOSE_NOTIFICATION, key: string): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.REDIRECTION_URL, url: string): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_NOTIFICATIONS_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_STOP_SHARING_WINDOW): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.HANDLE_INVITE_RESPONSE,
  payload: InviteSendResponseVoiceChannel,
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.HANDLE_REMINDER_RESPONSE,
  payload: ReminderSendResponseVoiceChannel,
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_NOTIFICATIONS_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_STOP_SHARING_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.REQUEST_REMOTE_CONTROL): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.MOVE_REMOTE_DESKTOP_MOUSE,
  arg: { x: number; y: number },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.SCROLL_REMOTE_DESKTOP_MOUSE,
  arg: { x: number; y: number },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.TOGGLE_REMOTE_DESKTOP_MOUSE,
  arg: { buttonType: "left" | "right"; toggleType: "up" | "down"; doubleClick: boolean },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.HANDLE_KEYBOARD_EVENT,
  arg: { key: string; state: "up" | "down"; modifier: string[] },
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_STOP_REMOTE_CONTROL_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_STOP_REMOTE_CONTROL_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_SCREENSHARE_OVERLAY_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_SCREENSHARE_OVERLAY_WINDOW): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.DOWNLOAD_URL,
  arg: { url: string; fileName: string; successMessage: string; errorMessage: string },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.ALLOW_DRAWING_ON_OVERLAY_WINDOW,
  arg: { desiredScreenIndex: number },
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.DISABLE_DRAWING_ON_OVERLAY_WINDOW): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.JOIN_CHANNEL_FROM_SUBSCRIBING_KNOCK,
  payload: JoinVoiceChannelFromSubscribingKnock,
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_TEXT_CHAT_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_TEXT_CHAT_WINDOW): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.SHOW_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION,
  payload: { forceOpen?: boolean },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.HIDE_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION,
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.DESTROY_TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION,
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.SHOW_LOGIN_WINDOW): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_LOGIN_WINDOW): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.HANDLE_MISSED_CALL_RESPONSE,
  args: { option: "chat" | "call-back"; payload: InviteGotResponseVoiceChannel },
): Promise<void>;
export async function sendMessageOverIPC(
  channel: typeof channels.SHOW_SUBTITLE_WINDOW,
  payload: { forceOpen?: boolean },
): Promise<void>;
export async function sendMessageOverIPC(channel: typeof channels.HIDE_SUBTITLE_WINDOW): Promise<void>;

/**
 *
 * @param channel The IPC channel name over which you wish to send message to main process
 * @param message Optional message with the invocation
 */
export async function sendMessageOverIPC(channel: string, message?: any, ignoreError?: boolean): Promise<any> {
  if (!window.electron) {
    return;
  }

  const { ipcRenderer } = window.electron;

  log(LogCategory.IPCMessages, `Sending message over ${channel}`, message);

  try {
    const returnData = await ipcRenderer.invoke(channel, message);

    return returnData;
  } catch (error) {
    window.electron.ipcRenderer.send(channels.UPDATE_APP, true);
    if (!ignoreError) {
      throw error;
    }
  }
}
