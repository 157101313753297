import { createSelector } from "reselect";
import { getAccountState } from "../account";

import { State } from "../state";

export function getMemberState(state: State) {
  return state.member;
}

export const getProjectById = (id: number) =>
  createSelector(getMemberState, memberState => memberState.projects.find(p => p.id === id));

export const getWorkspaceFloors = createSelector(getMemberState, memberState => memberState.floors);
export const getWorkspaceProjects = createSelector(getMemberState, memberstate => memberstate.projects);
export const getWorkspaceGroups = createSelector(getMemberState, memberstate => memberstate.groups);

export const getWorkspaceProjectIds = createSelector(getWorkspaceProjects, projects => {
  return projects.map(project => project.id);
});

export const getWorkspaceGroupIds = createSelector(getWorkspaceGroups, groups => {
  return groups.map(group => group.id!);
});

export const getWorkspaceFloorIds = createSelector(getWorkspaceFloors, floors => {
  return floors.map(floor => floor.id);
});

export const getUserProjectsData = () => {
  return createSelector([getAccountState, getMemberState], (accountState, memberState) => {
    const userProjects = accountState.user.projects;
    const userProjectsIds = userProjects?.map(projects => projects.id);

    return memberState.projects.filter(p => p.id && userProjectsIds?.includes(p.id));
  });
};
