import { createSelector } from "reselect";
import { QUIT_APP_WHILE_TRACKER_RUNNING_MODAL_ID } from "../../screens/Dashboard/constants";
import { getShowModal, ModalState } from "../../screens/Dashboard/state";

import { State } from "../state";
import { getMyMember, getSelectedProject } from "../users/selectors";
import { TimeTrackerState } from "./reducer";

export function getTimeTrackerState(state: State): TimeTrackerState {
  return state.timeTracker;
}

export const getWorkingState = createSelector(getTimeTrackerState, timeTrackerState => timeTrackerState.working);

export const getStartedTimeTrackerState = createSelector(
  getTimeTrackerState,
  getWorkingState,
  (timeTrackerState, working) => {
    if (!working) {
      throw new Error("There is not any ongoing work");
    }

    return timeTrackerState;
  },
);

export const getLoadingState = createSelector(
  getTimeTrackerState,
  getMyMember,
  getSelectedProject,
  (timeTrackerState, member, selectedProject) => {
    return (
      timeTrackerState.loading ||
      (selectedProject && !timeTrackerState.currentPeriod) ||
      (!timeTrackerState.offline && !Boolean(member))
    );
  },
);

export const getCurrentTimeTrackerSession = createSelector(getTimeTrackerState, timeTrackerState => {
  if (!timeTrackerState.currentSession) {
    throw new Error("There is not any ongoing work");
  }

  return timeTrackerState.currentSession;
});

export const getTimeTrackerProject = createSelector(getTimeTrackerState, timeTrackerState => timeTrackerState.project);

export const getWorkingStartTime = createSelector(
  getTimeTrackerState,
  timeTrackerState => timeTrackerState.currentSession?.startTime,
);

export const getTimeTrackerTotalSeconds = createSelector(
  getTimeTrackerState,
  timeTrackerState =>
    (timeTrackerState.currentPeriod?.totalSecondsSynced ?? 0) +
    (timeTrackerState.currentPeriod?.totalSecondsPending ?? 0),
);

export const getServerMillisecondsAhead = createSelector(
  getTimeTrackerState,
  timeTrackerState => timeTrackerState.localClockMillisecondsSlowness,
);

export const getAppQuitModal = createSelector(
  getShowModal,
  modals => modals.find(m => m.id === QUIT_APP_WHILE_TRACKER_RUNNING_MODAL_ID) as ModalState | undefined,
);

export const getIsPingOnFlying = createSelector(
  getTimeTrackerState,
  timeTrackerState => timeTrackerState.isPingOnFlying,
);

export const getTimeTrackerTodayTotalSeconds = createSelector(
  getTimeTrackerState,
  timeTrackerState => timeTrackerState.todayTotalSecond ?? 0,
);
