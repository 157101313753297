import dayjs from "dayjs";
import { getNewQueueRunner } from "../../utils/queue";
import { ActionsUnion, createAction } from "../../utils/redux";
import { AppThunk } from "../types";
import { log, LogCategory } from "../../utils/log";
import { getSocketConnectivityStatus, sendMessage } from "../socket";
import { TIME_TRACKER_EVENT_TYPES as EVENT_TYPES } from "./middleware";
import { getCurrentWorkspace } from "../workspace/selectors";
import {
  getCurrentTimeTrackerSession,
  getLoadingState,
  getStartedTimeTrackerState,
  getTimeTrackerProject,
  getTimeTrackerState,
  getWorkingState,
} from "./selectors";
import * as timeTrackerHttpApi from "../../api/timeTracker";
import {
  offlineTrackingService,
  PING_INTERVAL_VALUE,
  PING_RESPONSE_DELAY,
} from "./offlineTracking/offlineTrackingService";
import { showModal } from "../../screens/Dashboard/state";
import {
  QUIT_APP_WHILE_TRACKER_RUNNING_MODAL_ID,
  SIGNOUT_CONFIRMATION_MODAL_ID,
  TRACKER_STOPPED_ALERT_MODAL,
} from "../../screens/Dashboard/constants";
import { closeAppWhenSafeAction } from "../app";
import { channels } from "../../electron/channels";
import { TimeTrackingIntialData, getAuthenticatedAccount, updateTimeTrackingInitialDataAction } from "../account";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { updateMyUserWorkingMemo, updatedUserWorkingMemo, updateManualWorkingMemoAction } from "../users/actions";
import { NotificationCategory, notifyOnElectron } from "../../electron/notification";
import { getMyMember } from "../users";
import localData from "../../localStorageKeys";
import { getIsFromCustomBackground, updateIsFromCustomBackground } from "../voiceChannels";

const runOnQueue = getNewQueueRunner(LogCategory.TimeTracker);

export interface TimeTrackingRequestData {
  userId?: number;
  projectId?: number;
  workspaceId?: number;
  startTime?: any;
  takeScreenshot?: boolean;
}

enum ActionType {
  LOADED_INITIAL_DATA = "timeTracker/LOADED_INITIAL_DATA",
  START_WORKING_REQUEST = "timeTracker/START_WORKING_REQUEST",
  START_WORKING_RESPONSE = "timeTracker/START_WORKING_RESPONSE",
  STOP_WORKING_REQUEST = "timeTracker/STOP_WORKING_REQUEST",
  STOP_WORKING_RESPONSE = "timeTracker/STOP_WORKING_RESPONSE",
  PING_WORKING_REQUEST = "timeTracker/PING_WORKING_REQUEST",
  PING_WORKING_RESPONSE = "timeTracker/PING_WORKING_RESPONSE",
  SWITCH_TO_ONLINE_MODE = "timeTracker/SWITCH_TO_ONLINE_MODE",
  SWITCH_TO_OFFLINE_MODE = "timeTracker/SWITCH_TO_OFFLINE_MODE",
  UPDATE_DESCRIPTION = "timeTracker/UPDATE_DESCRIPTION",
  UPDATE_PROJECT = "timeTracker/UPDATE_PROJECT",
  TIMER_LOADING = "timeTracker/LOADING",
  TIMER_LOADING_OUT = "timeTracker/LOADING_OUT",
  SET_EDITING_DESCRIPTION = "timeTracker/SET_EDITING_DESCRIPTION",
  UPDATE_IS_PING_ON_FLYING = "timeTracker/UPDATE_IS_PING_ON_FLYING",
}

type Action = ActionsUnion<typeof actions>;

export const actions = {
  loadedInitialData: (
    timezoneOffset: number,
    totalSecondsSynced: number | null,
    totalSecondsPending: number | null,
    localClockMillisecondsSlowness: number | undefined,
    startTime: string | undefined,
    todayTotalSecond: number | null,
  ) =>
    createAction(ActionType.LOADED_INITIAL_DATA, {
      timezoneOffset,
      totalSecondsSynced,
      totalSecondsPending,
      localClockMillisecondsSlowness,
      startTime,
      todayTotalSecond,
    }),
  startWorkingRequest: (
    userId: number,
    projectId: number,
    workspaceId: number,
    clientTimeTrackingId: string,
    startTime: string,
    pingInterval: number,
  ) =>
    createAction(ActionType.START_WORKING_REQUEST, {
      userId,
      projectId,
      workspaceId,
      clientTimeTrackingId,
      startTime,
      pingInterval,
    }),
  startWorkingResponse: (serverTimeTrackingId: number) =>
    createAction(ActionType.START_WORKING_RESPONSE, { serverTimeTrackingId }),
  stopWorkingRequest: (sessionTotalSeconds: number) =>
    createAction(ActionType.STOP_WORKING_REQUEST, { sessionTotalSeconds }),
  stopWorkingResponse: () => createAction(ActionType.STOP_WORKING_RESPONSE),
  pingWorkingRequest: (clientTimeTrackingId: string) =>
    createAction(ActionType.PING_WORKING_REQUEST, { clientTimeTrackingId }),
  pingWorkingResponse: (serverTimeTrackingId: number, clientTimeTrackingId: string) =>
    createAction(ActionType.PING_WORKING_RESPONSE, { serverTimeTrackingId, clientTimeTrackingId }),
  switchToOnlineMode: (clientTimeTrackingId?: string) =>
    createAction(ActionType.SWITCH_TO_ONLINE_MODE, { clientTimeTrackingId }),
  switchToOfflineMode: (clientTimeTrackingId?: string) =>
    createAction(ActionType.SWITCH_TO_OFFLINE_MODE, { clientTimeTrackingId }),
  updateDescription: (description: string) => createAction(ActionType.UPDATE_DESCRIPTION, { description }),
  updateProject: (projectId: number) => createAction(ActionType.UPDATE_PROJECT, { projectId }),
  timerInLoadingState: () => createAction(ActionType.TIMER_LOADING),
  updateIsPingOnFlying: (status: boolean) => createAction(ActionType.UPDATE_IS_PING_ON_FLYING, { status }),
};

export type { Action as TimeTrackerAction };
export { ActionType as TimeTrackerActionType };

export function loadInitialData(): AppThunk {
  return async (dispatch, getState) => {
    const account = getAuthenticatedAccount(getState());
    const timeTrackerState = getTimeTrackerState(getState());
    const workspaceId =
      getCurrentWorkspace(getState())?.id || timeTrackerState.currentSession?.workspaceId || account.workspaceId;
    const projectId = getTimeTrackerProject(getState());

    if (!workspaceId || !projectId) {
      return;
    }

    log(LogCategory.TimeTracker, `Started loading initial data`);
    dispatch(actions.timerInLoadingState());

    if (!timeTrackerState.working) {
      await offlineTrackingService.closeOpenedSlots(account.id, projectId, workspaceId);
    }

    log(LogCategory.TimeTracker, "Syncing offline work...");
    dispatch(syncOfflineWorkRequest(projectId, workspaceId));
  };
}

function syncOfflineWorkRequest(projectId: number, workspaceId: number): AppThunk {
  return async (dispatch, getState) => {
    const timeTrackerState = getTimeTrackerState(getState());

    if (timeTrackerState.offline) {
      log(LogCategory.TimeTracker, "Not syncing because there is no connection");
      dispatch(finishLoadingInitialDataAfterSync(projectId, workspaceId));
      return;
    }

    const { id: userId } = getAuthenticatedAccount(getState());
    const offlineTimeSlotsToSync = await offlineTrackingService.listOfflineDataToSync(userId, projectId, workspaceId);

    if (offlineTimeSlotsToSync.length === 0) {
      log(LogCategory.TimeTracker, "No off-line slot to sync");
      dispatch(finishLoadingInitialDataAfterSync(projectId, workspaceId));
      return;
    }

    log(LogCategory.TimeTracker, "Offline time slots to sync", offlineTimeSlotsToSync);

    dispatch(
      sendMessage(EVENT_TYPES.OUT_SYNC_OFFLINE_WORK_REQUEST, {
        userId,
        projectId,
        workspaceId,
        offlineData: offlineTimeSlotsToSync,
        pingInterval: Math.floor(PING_INTERVAL_VALUE / 1_000),
      }),
    );
  };
}

export function syncOfflineWorkResponse(
  userId: number,
  projectId: number,
  workspaceId: number,
  serverIds: Record<string, number[]>,
  error: string | undefined,
): AppThunk {
  return async dispatch => {
    log(LogCategory.TimeTracker, "Offline time slots to synced. Server ids:", serverIds);

    await offlineTrackingService.updateServerIdsOnSyncedOfflineSlots(userId, projectId, workspaceId, serverIds, error);
    dispatch(finishLoadingInitialDataAfterSync(projectId, workspaceId));
  };
}

function finishLoadingInitialDataAfterSync(projectId: number, workspaceId: number): AppThunk {
  return async (dispatch, getState) => {
    log(LogCategory.TimeTracker, "Finish syncing. Loading data for workspace:", workspaceId);

    const timeTrackerState = getTimeTrackerState(getState());
    const { id: userId, timetrackingInitialData } = getAuthenticatedAccount(getState());
    const timezoneOffset = new Date().getTimezoneOffset();

    let totalSecondsSynced: number | null = null;
    let totalSecondsPending: number | null = null;
    let localClockMillisecondsSlowness: number | undefined = undefined;
    let todayTotalSecond: number | null = null;
    let remoteData = null;

    try {
      log(LogCategory.TimeTracker, "Loading data from server...");
      remoteData = timetrackingInitialData;

      if (timetrackingInitialData?.projectId !== projectId || timeTrackerState.currentPeriod.totalSecondsPending > 0) {
        remoteData = await timeTrackerHttpApi.getInitialData(workspaceId, projectId);

        //Update Intitial Tracking Data
        if (remoteData) dispatch(updateTimeTrackingInitialDataAction(remoteData as TimeTrackingIntialData));
      }

      log(LogCategory.TimeTracker, "Data from server loaded successfully:", remoteData);

      if (remoteData) {
        localClockMillisecondsSlowness = dayjs.utc(remoteData.serverClock).diff(dayjs.utc(), "milliseconds");
        totalSecondsSynced = remoteData.totalSeconds;
        totalSecondsPending = 0;
        todayTotalSecond = remoteData.todayTotalSecond;
      }
    } catch {
      log(LogCategory.TimeTracker, "Data from server failed to load. Using local data instead...");

      const localData = await offlineTrackingService.loadInitialData(
        userId,
        projectId!,
        workspaceId,
        timezoneOffset,
        timeTrackerState.currentSession?.clientTimeTrackingId,
      );

      log(LogCategory.TimeTracker, "Successfully loaded local data:", localData);

      localClockMillisecondsSlowness = undefined;
      totalSecondsSynced = localData.totalSecondsSynced;
      totalSecondsPending = localData.totalSecondsPending;
      todayTotalSecond = 0;
    }

    const startTime =
      timeTrackerState.currentSession &&
      (await offlineTrackingService.getSlotStartTime(
        userId,
        projectId!,
        workspaceId,
        timeTrackerState.currentSession.clientTimeTrackingId,
      ));

    dispatch(
      actions.loadedInitialData(
        timezoneOffset,
        totalSecondsSynced,
        totalSecondsPending,
        localClockMillisecondsSlowness,
        startTime,
        todayTotalSecond,
      ),
    );

    log(
      LogCategory.TimeTracker,
      `Sending Menu time as initial seconds: ${
        totalSecondsSynced ?? 0 + (totalSecondsPending ?? 0)
      } started at: ${startTime}`,
    );
    await sendMessageOverIPC(channels.SET_MENU_TIMER, {
      initialSeconds: totalSecondsSynced ?? 0 + (totalSecondsPending ?? 0),
      startedAt: startTime,
    });

    log(LogCategory.TimeTracker, `Finished loading initial data`);
  };
}

export function resumeOnlineWorkAfterReconnected(): AppThunk {
  return async (dispatch, getState) => {
    await runOnQueue("resumeOnlineWorkAfterReconnected", async () => {
      const socketConnected = getSocketConnectivityStatus(getState());
      const timeTrackerState = getTimeTrackerState(getState());
      const isFromCustomBackground = getIsFromCustomBackground(getState());

      if (!socketConnected || isFromCustomBackground) {
        dispatch(updateIsFromCustomBackground(false));
        return;
      }

      if (timeTrackerState.working && timeTrackerState.currentSession && timeTrackerState.offline) {
        log(LogCategory.TimeTracker, "Switching to online time tracking");

        notifyOnElectron(NotificationCategory.OfflineTimeTracking);

        const { userId, projectId, workspaceId, clientTimeTrackingId } = timeTrackerState.currentSession;

        const result = await offlineTrackingService.switchOnlineOfflineMode(
          userId,
          projectId,
          workspaceId,
          clientTimeTrackingId,
          true,
        );

        dispatch(actions.switchToOnlineMode(result.clientTimeTrackingId));
        dispatch(pingRequest());
      } else {
        dispatch(actions.switchToOnlineMode());
        dispatch(loadInitialData());
      }
    });
  };
}

export function switchToOfflineWork(): AppThunk {
  return async (dispatch, getState) => {
    await runOnQueue("switchToOfflineWork", async () => {
      const timeTrackerState = getTimeTrackerState(getState());

      if (timeTrackerState.working && timeTrackerState.currentSession && !timeTrackerState.offline) {
        log(LogCategory.TimeTracker, "Switching to offline time tracking");

        const { userId, projectId, workspaceId, clientTimeTrackingId } = timeTrackerState.currentSession;
        const result = await offlineTrackingService.switchOnlineOfflineMode(
          userId,
          projectId,
          workspaceId,
          clientTimeTrackingId,
          false,
        );

        dispatch(actions.switchToOfflineMode(result.clientTimeTrackingId));
      } else {
        dispatch(actions.switchToOfflineMode());
      }
    });
  };
}

export function startWorkingRequest(): AppThunk {
  return async (dispatch, getState) => {
    await runOnQueue("startWorkingRequest", async () => {
      const amIInLoadingState = getLoadingState(getState());

      if (amIInLoadingState) {
        throw new Error("Too many back to back start requests, timer is still loading...");
      }

      const timeTrackerState = getTimeTrackerState(getState());

      if (!timeTrackerState.currentPeriod) {
        throw new Error("To start the tracker, we need to load the locally stored initial data first.");
      }

      if (timeTrackerState.working || timeTrackerState.currentSession) {
        throw new Error("There is a working session already running.");
      }

      const me = getMyMember(getState());
      const workspace = getCurrentWorkspace(getState());

      if (!me) {
        throw new Error("Cannot get my member status");
      }

      const userId = me.id;
      const workspaceId = workspace?.id || me.workspaceId;
      const description = me.workingMemo;
      const projectId = getTimeTrackerProject(getState());

      if (!workspaceId) {
        throw new Error("Cannot start tracking without a workspace id");
      }

      if (!projectId) {
        throw new Error("Cannot start tracking without a project id");
      }

      dispatch(actions.timerInLoadingState());

      const { clientTimeTrackingId } = await offlineTrackingService.startWorking(
        userId,
        projectId,
        workspaceId,
        description,
      );

      log(LogCategory.TimeTracker, "Start working (request)", { workspaceId, clientTimeTrackingId, description });

      const startTime = dayjs().utc().toISOString();
      const pingInterval = window.setInterval(() => dispatch(pingRequest()), PING_INTERVAL_VALUE);

      localData.set("timeTracker.status.isWorking", JSON.stringify(true));

      dispatch(
        actions.startWorkingRequest(userId, projectId, workspaceId, clientTimeTrackingId, startTime, pingInterval),
      );

      notifyOnElectron(NotificationCategory.TimeTrackerStart);

      if (!timeTrackerState.offline) {
        dispatch(
          sendMessage(EVENT_TYPES.OUT_START_WORKING_REQUEST, {
            projectId,
            workspaceId,
            clientTimeTrackingId,
            workingMemo: description,
          }),
        );
      }

      await sendMessageOverIPC(channels.SET_MENU_TIMER, {
        initialSeconds:
          timeTrackerState.currentPeriod.totalSecondsSynced + timeTrackerState.currentPeriod.totalSecondsPending,
        startedAt: startTime,
      });

      let data: TimeTrackingRequestData = {
        userId: me.id,
        projectId: me.project?.id,
        workspaceId: me.workspaceId,
        startTime,
        takeScreenshot: me.enableScreenshot,
      };

      await sendMessageOverIPC(channels.STARTED_WORKING, data);
    });
  };
}

export function startWorkingResponse(
  userId: number,
  projectId: number,
  workspaceId: number,
  serverTimeTrackingId: number,
  clientTimeTrackingId: string,
): AppThunk {
  return async (dispatch, getState) => {
    await runOnQueue("startWorkingResponse", async () => {
      log(LogCategory.TimeTracker, "Start working (response)", {
        userId,
        projectId,
        workspaceId,
        clientTimeTrackingId,
        serverTimeTrackingId,
      });

      const account = getAuthenticatedAccount(getState());
      const timeTrackerSession = getCurrentTimeTrackerSession(getState());

      if (
        account.id !== userId ||
        timeTrackerSession.userId !== userId ||
        timeTrackerSession.projectId !== projectId ||
        timeTrackerSession.workspaceId !== workspaceId ||
        timeTrackerSession.clientTimeTrackingId !== clientTimeTrackingId
      ) {
        throw new Error("Unexpected startWorkingResponse from server side.");
      }

      await offlineTrackingService.startWorkingServerId(
        userId,
        projectId,
        workspaceId,
        clientTimeTrackingId,
        serverTimeTrackingId,
      );

      localData.set("timeTracker.stopped.userAway", JSON.stringify(false));

      dispatch(actions.startWorkingResponse(serverTimeTrackingId));
    });
  };
}

function pingRequest(): AppThunk {
  return async (dispatch, getState) => {
    await runOnQueue("pingRequest", async () => {
      const timeTrackerState = getStartedTimeTrackerState(getState());
      const timeTrackerSession = getCurrentTimeTrackerSession(getState());
      const timezoneOffset = new Date().getTimezoneOffset();
      const online = !timeTrackerState.offline;

      log(LogCategory.TimeTracker, "Ping request (to offline service) params", timeTrackerState);

      dispatch(updateIsPingOnFlying(true));

      const {
        clientTimeTrackingId,
        newDescription,
        localMidnightCrossOffline,
      } = await offlineTrackingService.pingWorkingRequestSent(
        timeTrackerSession.userId,
        timeTrackerSession.projectId,
        timeTrackerSession.workspaceId,
        timeTrackerSession.clientTimeTrackingId,
        online,
        timeTrackerState.description,
      );

      log(LogCategory.TimeTracker, "Ping request (to offline service) result", {
        clientTimeTrackingId,
        newDescription,
      });

      dispatch(actions.pingWorkingRequest(clientTimeTrackingId));

      if (online) {
        dispatch(
          sendMessage(EVENT_TYPES.OUT_PING_WORKING_REQUEST, {
            projectId: timeTrackerSession.projectId,
            workspaceId: timeTrackerSession.workspaceId,
            serverTimeTrackingId: timeTrackerSession.serverTimeTrackingId,
            clientTimeTrackingId: clientTimeTrackingId,
            timezoneOffset,
            newDescription,
          }),
        );
      } else if (localMidnightCrossOffline) {
        dispatch(loadInitialData());
      }
    });
  };
}

export function pingResponse(
  userId: number,
  projectId: number,
  workspaceId: number,
  serverTimeTrackingId: number,
  clientTimeTrackingId: string,
  crossedMidnight: boolean,
  restarted: boolean,
): AppThunk {
  return async dispatch => {
    await runOnQueue("pingResponse", async () => {
      log(LogCategory.TimeTracker, "Ping response (from server)", {
        userId,
        projectId,
        workspaceId,
        serverTimeTrackingId,
        clientTimeTrackingId,
        crossedMidnight,
        restarted,
      });

      const {
        clientTimeTrackingId: newClientTimeTrackingId,
      } = await offlineTrackingService.pingWorkingResponseReceived(
        userId,
        projectId,
        workspaceId,
        clientTimeTrackingId,
        serverTimeTrackingId,
        crossedMidnight,
      );

      log(LogCategory.TimeTracker, "Ping response (from offline service)");

      dispatch(actions.pingWorkingResponse(serverTimeTrackingId, newClientTimeTrackingId));

      if (crossedMidnight || restarted) {
        dispatch(loadInitialData());
      }

      setTimeout(() => {
        dispatch(updateIsPingOnFlying(false));
      }, PING_RESPONSE_DELAY);
    });
  };
}

export function midnightReset(type: "local" | "server"): AppThunk {
  return async (dispatch, getState) => {
    const timeTrackerState = getTimeTrackerState(getState());
    const onlineReset = type === "server" && !timeTrackerState.offline;
    const offlineReset = type === "local" && timeTrackerState.offline;

    if (onlineReset || offlineReset) {
      if (timeTrackerState.working) {
        dispatch(pingRequest());
      } else {
        dispatch(loadInitialData());
      }
    }
  };
}

export function stopWorkingRequest(debugInfo: string): AppThunk {
  return async (dispatch, getState) => {
    await runOnQueue("stopWorkingRequest", async () => {
      const timeTrackerState = getTimeTrackerState(getState());

      if (!timeTrackerState.working) {
        return; // ignore
      }

      log(LogCategory.TimeTracker, `Stop working (request)`);

      const timeTrackerSession = getCurrentTimeTrackerSession(getState());

      clearInterval(timeTrackerSession.pingInterval);

      await sendMessageOverIPC(channels.STOPPED_WORKING, null, true);

      const sessionTotalSeconds = dayjs().utc().diff(timeTrackerSession.startTime, "seconds");

      dispatch(actions.stopWorkingRequest(sessionTotalSeconds));

      await offlineTrackingService.stopWorking(
        timeTrackerSession.userId,
        timeTrackerSession.projectId,
        timeTrackerSession.workspaceId,
        timeTrackerSession.clientTimeTrackingId,
      );

      notifyOnElectron(NotificationCategory.TimeTrackerStop);

      if (!timeTrackerState.offline) {
        dispatch(
          sendMessage(EVENT_TYPES.OUT_STOP_WORKING_REQUEST, {
            projectId: timeTrackerSession.projectId,
            workspaceId: timeTrackerSession.workspaceId,
            serverTimeTrackingId: timeTrackerSession.serverTimeTrackingId,
            clientTimeTrackingId: timeTrackerSession.clientTimeTrackingId,
            debugInfo,
          }),
        );
      }
    });
  };
}

export function stopWorkingResponse(debugInfo: string): AppThunk {
  return async dispatch => {
    await runOnQueue("stopWorkingResponse", async () => {
      log(LogCategory.TimeTracker, `Stop working (response)`);

      if (debugInfo !== "Stop Time Tracker and Quit App") {
        localData.set("timeTracker.status.isWorking", JSON.stringify(false));
      }

      dispatch(actions.stopWorkingResponse());
    });
  };
}

export function updateDescription(description: string, isManual?: boolean): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const timeTrackerState = getTimeTrackerState(getState());
    const me = getMyMember(getState());

    await dispatch(actions.updateDescription(description));
    await dispatch(updatedUserWorkingMemo({ userId: me?.id!, workingMemo: description }));

    if (isManual) {
      dispatch(updateManualWorkingMemoAction({ userId: me?.id!, workingMemo: description }));
    }

    if (timeTrackerState.working) {
      dispatch(pingRequest());
    }

    dispatch(updateMyUserWorkingMemo({ workspaceId: currentWorkspace!.id, workingMemo: description, isManual }));
  };
}

export function updateTimeTrackerProject(projectId: number): AppThunk {
  return async dispatch => {
    dispatch(actions.updateProject(projectId));
  };
}

export function closeTrackerAwayReasoningModal(): AppThunk {
  return dispatch => {
    dispatch(showModal({ id: TRACKER_STOPPED_ALERT_MODAL, show: false }));
  };
}

export function showTrackerAwayReasoningModal(): AppThunk {
  return dispatch => {
    dispatch(showModal({ id: TRACKER_STOPPED_ALERT_MODAL, show: true }));
  };
}

export function openConfirmAppQuitWithRunningTracker(): AppThunk {
  return dispatch => {
    dispatch(showModal({ id: QUIT_APP_WHILE_TRACKER_RUNNING_MODAL_ID, show: true }));
  };
}

export function closeConfirmAppQuitWithRunningTracker(): AppThunk {
  return dispatch => {
    dispatch(showModal({ id: QUIT_APP_WHILE_TRACKER_RUNNING_MODAL_ID, show: false }));
  };
}

export function stopWorkingAndQuitApp(): AppThunk {
  return dispatch => {
    dispatch(stopWorkingRequest("Stop Time Tracker and Quit App"));
    dispatch(closeAppWhenSafeAction());
  };
}

export function restartTrackerAfterInactivity(): AppThunk {
  return async (dispatch, getState) => {
    const timeTrackerState = getTimeTrackerState(getState());
    const amIInLoadingState = getLoadingState(getState());
    const isWorking = getWorkingState(getState());

    if (!amIInLoadingState && !isWorking && !timeTrackerState.currentSession) {
      dispatch(closeTrackerAwayReasoningModal());
      dispatch(startWorkingRequest());
    }
  };
}

export function closeSignoutConfirmUser(): AppThunk {
  return dispatch => {
    dispatch(showModal({ id: SIGNOUT_CONFIRMATION_MODAL_ID, show: false }));
  };
}

export function updateIsPingOnFlying(status: boolean): AppThunk {
  return dispatch => {
    dispatch(actions.updateIsPingOnFlying(status));
  };
}
