import PQueue from "p-queue";
import { log, LogCategory } from "./log";

export function getNewQueueRunner(logCategory: LogCategory) {
  const queue = new PQueue({ concurrency: 1 });

  return async (description: string, task: () => Promise<void>) => {
    log(logCategory, `[QUEUE] [${description}] waiting to run...`);

    await queue.add(async () => {
      log(logCategory, `[QUEUE] [${description}] started running task...`);
      await task();
      log(logCategory, `[QUEUE] [${description}] finished running task...`);
    });

    log(logCategory, `[QUEUE] [${description}] finished.`);
  };
}
