import { useEffect } from "react";
import { isUserLoaded, fetchAuthenticatedAccount } from "../../store/account";
import {
  getWebVersion,
  loadAppLatestVersion,
  loadAppVersion,
  loadServerVersion,
  loadWebVersion,
} from "../../store/version";
import { useSelector, useDispatch } from "react-redux";

export default function useUserAuth() {
  const dispatch = useDispatch();
  const userLoaded = useSelector(isUserLoaded);
  const webVersion = useSelector(getWebVersion);

  useEffect(() => {
    dispatch(loadWebVersion());
    dispatch(loadServerVersion());

    if (
      window.electron &&
      window.name !== "notification-window" &&
      window.name !== "stop-sharing-window" &&
      window.name !== "stop-remote-control-window" &&
      window.name !== "text-chat-window" &&
      window.name !== "text-chat-window-for-audio-extraction" &&
      window.name !== "login-window" &&
      window.name !== "subtitle-window"
    ) {
      dispatch(loadAppVersion());
      dispatch(loadAppLatestVersion());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      window.name !== "notification-window" &&
      window.name !== "stop-sharing-window" &&
      webVersion.loaded &&
      !userLoaded &&
      window.name !== "stop-remote-control-window" &&
      window.name !== "text-chat-window" &&
      window.name !== "text-chat-window-for-audio-extraction" &&
      window.name !== "login-window" &&
      window.name !== "subtitle-window"
    ) {
      dispatch(fetchAuthenticatedAccount(true));
    }
  }, [dispatch, userLoaded, webVersion.loaded]);
  return userLoaded;
}
