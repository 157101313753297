import { Action } from "redux";
import { State } from "../../store/state";
import { AppThunk } from "../../store/types";
import { Analytics, AnalyticsCategory } from "../../utils/ganalytics";

export enum DashboardActions {
  SHOW_MODAL = "dashboard/SHOW_MODAL",
  RESET_MODALS = "dashboard/RESET_MODALS",
  SWITCH_SIDEBAR_COLLAPSED = "dashboard/SWITCH_SIDEBAR_COLLAPASED",
  SHOW_CALENDAR_SCHEDULE_BOX = "dashboard/SHOW_CALENDAR_SCHEDULE_BOX",
}

export interface ShowModalAction<T = any> extends Action {
  type: DashboardActions.SHOW_MODAL;
  payload: ModalState<T>;
}

export function showModal<T = any>(payload: ModalState<T>): ShowModalAction<T> {
  Analytics(AnalyticsCategory.User, `Showing Modal ${payload.id}`);
  return { type: DashboardActions.SHOW_MODAL, payload };
}

export interface ResetModalsAction extends Action {
  type: DashboardActions.RESET_MODALS;
}

export function resetModals(): ResetModalsAction {
  return { type: DashboardActions.RESET_MODALS };
}

export interface SwitchMobileSiderCollapasedAction extends Action {
  type: DashboardActions.SWITCH_SIDEBAR_COLLAPSED;
  payload: boolean;
}

export function switchSidebarCollapased(collapsed: boolean): SwitchMobileSiderCollapasedAction {
  return { type: DashboardActions.SWITCH_SIDEBAR_COLLAPSED, payload: collapsed };
}

function showCalendarScheduleBoxAction(isShow: boolean) {
  return {
    type: DashboardActions.SHOW_CALENDAR_SCHEDULE_BOX,
    payload: { isShow },
  } as const;
}

export type DashboardActionTypes =
  | ShowModalAction
  | ResetModalsAction
  | SwitchMobileSiderCollapasedAction
  | ReturnType<typeof showCalendarScheduleBoxAction>;

export interface ModalState<T = any> {
  id: string;
  show: boolean;
  data?: T;
}

export const dashboardInitialState = {
  error: null as string | null,
  modals: [] as ModalState[],
  sidebarCollapased: true,
  showCalendarScheduleBox: false,
};

export function hideCalendarScheduleBox(): AppThunk {
  return async (dispatch, getState) => {
    const isShow = getCalendarScheduleBoxOption(getState());

    if (isShow) {
      dispatch(showCalendarScheduleBoxAction(!isShow));
    }
  };
}

export function showCalendarScheduleBox(): AppThunk {
  return async (dispatch, getState) => {
    const isShow = getCalendarScheduleBoxOption(getState());

    if (!isShow) {
      dispatch(showCalendarScheduleBoxAction(!isShow));
    }
  };
}

export type DashboardState = typeof dashboardInitialState;

export function dashboardReducer(
  state: DashboardState = dashboardInitialState,
  action: DashboardActionTypes,
): DashboardState {
  switch (action.type) {
    case DashboardActions.SHOW_MODAL:
      return {
        ...state,
        modals: [
          ...state.modals.filter(m => m.id !== (action.payload as ModalState)?.id),
          action.payload as ModalState,
        ].filter(a => a.show),
      };
    case DashboardActions.RESET_MODALS:
      return {
        ...state,
        modals: [],
      };
    case DashboardActions.SWITCH_SIDEBAR_COLLAPSED:
      return {
        ...state,
        sidebarCollapased: action.payload,
      };
    case DashboardActions.SHOW_CALENDAR_SCHEDULE_BOX:
      return {
        ...state,
        showCalendarScheduleBox: action.payload.isShow,
      };
    default:
      return state;
  }
}

export function getShowModal(state: State) {
  return state.dashboard.modals;
}

export function getSidebarCollapased(state: State) {
  return state.dashboard.sidebarCollapased;
}

export function getCalendarScheduleBoxOption(state: State) {
  return state.dashboard.showCalendarScheduleBox;
}
