import * as Sentry from "@sentry/browser";
import { getCurrentEnvironment } from "../utils/environment";

const apiKey = process.env.REACT_APP_SENTRY_API_KEY;
const orgId = process.env.REACT_APP_SENTRY_ORG_ID;
const projectId = process.env.REACT_APP_SENTRY_PROJECT_ID;

const commitHash = process.env.REACT_APP_GIT_COMMIT_HASH || "not-set";
const currentBranch = process.env.REACT_APP_GIT_CURRENT_BRANCH || "not-set";
const release = commitHash;

const dsn = `https://${apiKey}@${orgId}.ingest.sentry.io/${projectId}`;

const environment = getCurrentEnvironment();

const allowedEnvs: typeof environment[] = ["firebase", "development", "production"];

const ignoreErrors =
  environment === "production" || environment === "development"
    ? [
        "Socket was disconnected",
        "Attempt at sending message over a non connected socket",
        "Non-Error exception captured",
        "ResizeObserver loop limit exceeded",
        "NotFoundError: Requested device not found",
      ]
    : [];

const regex = /'app-latest-version': FetchError: request/gi;

const beforeSend = (event: Sentry.Event, hint?: Sentry.EventHint | undefined) => {
  if (ignoreErrors.includes(event.message ?? "") || event.message?.match(regex)) return null;

  return event;
};

if (allowedEnvs.includes(environment)) {
  Sentry.init({ dsn, beforeSend, release, environment });
  Sentry.setTags({
    commit: commitHash,
    branch: currentBranch,
  });
}
