import { CursorObject, ScreenShareVirtualOfficeObject, VirtualObjectDeltaStack, VirtualOfficeObject } from "./types";
import { VirtualOfficeAction, VirtualOfficeActionType } from "./actions";
import { FrontObject, PrevLocation } from "../types";

export const virtualOfficeInitialState = {
  loading: true,
  objects: [] as VirtualOfficeObject[],
  objectSpawn: {
    position: { x: 0, y: 0 },
    offset: 0,
    limit: 0,
  },
  fullscreenObjectId: undefined as string | undefined,
  myPrevLocation: undefined as PrevLocation | undefined,
  shouldJumpBack: false,
  orderedObjects: [] as FrontObject[],
  actionStack: [] as {
    objectId: string;
    undoStack: VirtualObjectDeltaStack<any>[];
    redoStack: VirtualObjectDeltaStack<any>[];
  }[],
  selectedObjectId: undefined as string | undefined,
  draggedObjectId: undefined as string | undefined,
  cursors: [] as CursorObject[],
};

export type VirtualOfficeState = typeof virtualOfficeInitialState;

export function virtualOfficeReducer(
  state: VirtualOfficeState = virtualOfficeInitialState,
  action: VirtualOfficeAction,
): VirtualOfficeState {
  switch (action.type) {
    case VirtualOfficeActionType.START_LOADING:
      return { ...state, loading: true };
    case VirtualOfficeActionType.OBJECTS_LOADED:
      return {
        ...state,
        loading: false,
        objects: action.payload.objects,
      };
    case VirtualOfficeActionType.CREATE_OBJECT:
      return {
        ...state,
        objects: [...state.objects, action.payload.object],
      };
    case VirtualOfficeActionType.UPDATE_OBJECT:
      return {
        ...state,
        objects: state.objects.map(o => (o.id === action.payload.object.id ? action.payload.object : o)),
      };
    case VirtualOfficeActionType.DELETE_OBJECT:
      return {
        ...state,
        objects: state.objects.filter(o => o.id !== action.payload.objectId),
      };
    case VirtualOfficeActionType.RECOVER_OBJECT:
      return {
        ...state,
        objects: state.objects.find(obj => obj.id === action.payload.object.id)
          ? state.objects
          : [...state.objects, action.payload.object],
      };
    case VirtualOfficeActionType.BRING_OBJECT_TO_FRONT:
      const frontObject = state.orderedObjects.find(
        o =>
          o.type === action.payload.type &&
          (action.payload.type === "avatar"
            ? action.payload.userId === o.userId
            : action.payload.objectId === o.objectId),
      );

      return {
        ...state,
        orderedObjects: frontObject
          ? [
              ...state.orderedObjects.filter(
                o =>
                  !(
                    o.type === action.payload.type &&
                    (action.payload.type === "avatar"
                      ? action.payload.userId === o.userId
                      : action.payload.objectId === o.objectId)
                  ),
              ),
              frontObject,
            ]
          : [...state.orderedObjects, action.payload],
      };

    case VirtualOfficeActionType.REMOVE_ORDERED_OBJECT:
      return {
        ...state,
        orderedObjects: state.orderedObjects.filter(
          o =>
            !(
              o.type === action.payload.type &&
              (action.payload.type === "avatar"
                ? action.payload.userId === o.userId
                : action.payload.objectId === o.objectId)
            ),
        ),
      };
    case VirtualOfficeActionType.CLEAR_ORDERED_OBJECTS:
      return { ...state, orderedObjects: [] };
    case VirtualOfficeActionType.UPDATE_SPAWN_OBJECT_OFFSET:
      return {
        ...state,
        objectSpawn: action.payload,
      };
    case VirtualOfficeActionType.UPDATE_FULLSCREEN_OBJECT_ID:
      return { ...state, fullscreenObjectId: action.payload.objectId };
    case VirtualOfficeActionType.UPDATE_MY_PREV_LOCATION:
      return { ...state, myPrevLocation: action.payload.location };
    case VirtualOfficeActionType.UPDATE_SHOULD_JUMP_BACK:
      return { ...state, shouldJumpBack: action.payload.shouldJumpBack };
    case VirtualOfficeActionType.UPDATE_SCREEN_OVERLAY_DRAWING_STATUS:
      const object = state.objects?.find(o => o.type === "screen-share" && o.ownerUserId === action.payload.userId);

      if (object) {
        const screenshareObject = object as ScreenShareVirtualOfficeObject;

        screenshareObject.properties.content.isDrawingAllowed = action.payload.isDrawingAllowed;
        return {
          ...state,
          objects: state.objects.map(o => (o.id === object.id ? object : o)),
        };
      }

      return state;

    case VirtualOfficeActionType.ADD_ACTION_TO_STACK:
      const oldActionStack = state.actionStack.find(s => s.objectId === action.payload.objectId);

      if (oldActionStack) {
        return {
          ...state,
          actionStack: state.actionStack.map(s =>
            s.objectId === action.payload.objectId
              ? { ...s, undoStack: [...s.undoStack, action.payload.stack], redoStack: [] }
              : s,
          ),
        };
      } else {
        return {
          ...state,
          actionStack: [
            ...state.actionStack,
            { objectId: action.payload.objectId, undoStack: [action.payload.stack], redoStack: [] },
          ],
        };
      }

    case VirtualOfficeActionType.APPLY_UNDO_ACTION_TO_STACK: {
      const stack = state.actionStack.find(s => s.objectId === action.payload.objectId);

      if (!stack || stack.undoStack.length === 0) {
        return { ...state };
      } else {
        return {
          ...state,
          actionStack: state.actionStack.map(s =>
            s.objectId === action.payload.objectId
              ? {
                  ...s,
                  undoStack: [...s.undoStack].slice(0, -1),
                  redoStack: [
                    ...s.redoStack,
                    {
                      add: s.undoStack[s.undoStack.length - 1].remove,
                      remove: s.undoStack[s.undoStack.length - 1].add,
                      update: s.undoStack[s.undoStack.length - 1].update.map(e => ({
                        after: e.before,
                        before: e.after,
                      })),
                    },
                  ],
                }
              : s,
          ),
        };
      }
    }

    case VirtualOfficeActionType.APPLY_REDO_ACTION_TO_STACK: {
      const stack = state.actionStack.find(s => s.objectId === action.payload.objectId);

      if (!stack || stack.redoStack.length === 0) {
        return { ...state };
      } else {
        return {
          ...state,
          actionStack: state.actionStack.map(s =>
            s.objectId === action.payload.objectId
              ? {
                  ...s,
                  redoStack: [...s.redoStack].slice(0, -1),
                  undoStack: [
                    ...s.undoStack,
                    {
                      add: s.redoStack[s.redoStack.length - 1].remove,
                      remove: s.redoStack[s.redoStack.length - 1].add,
                      update: s.redoStack[s.redoStack.length - 1].update.map(e => ({
                        after: e.before,
                        before: e.after,
                      })),
                    },
                  ],
                }
              : s,
          ),
        };
      }
    }

    case VirtualOfficeActionType.UPDATE_CURSORS:
      const hasUserCursor = state.cursors.find(c => c.userId === action.payload.cursor.userId);

      return {
        ...state,
        cursors: hasUserCursor
          ? state.cursors.map(c => (c.userId === action.payload.cursor.userId ? action.payload.cursor : c))
          : [...state.cursors, action.payload.cursor],
      };

    case VirtualOfficeActionType.REMOVE_CURSOR:
      return {
        ...state,
        cursors: state.cursors.filter(c => c.userId !== action.payload.userId),
      };

    case VirtualOfficeActionType.UPDATE_SELECTED_OBJECT_ID:
      return { ...state, selectedObjectId: action.payload.objectId };
    case VirtualOfficeActionType.UPDATE_DRAGGED_OBJECT_ID:
      return { ...state, draggedObjectId: action.payload.objectId };
    default:
      return state;
  }
}
