import { Action } from "redux";

import {
  getPermissionStateMicrophone,
  loadPermission,
  showRequiredMicrophonePermissionModal,
  getPermissionStateCamera,
  showRequiredCameraPermissionModal,
  getAppFocusStatus,
  getWebCameraStatus,
} from "../app";
import { getTimeTrackerState } from "../timeTracker/selectors";
import { getDeviceSettings } from "../devices";
import { sendMessage, forceLogout } from "../socket";
import {
  getEnableTextChatMic,
  getEnabledLanguageInconsistencyAlert,
  getMyMember,
  getMyMemberStatus,
  getOnlineUserById,
  getOnlineWorkspaceUsers,
  getUserSpeechToTextSetting,
} from "./selectors";
import { USER_EVENT_TYPES } from "./middleware";
import {
  MemberStatus,
  AppThunk,
  MemberData,
  Position,
  SpeechToTextSettings,
  Role,
  FloorDetailData,
  StatusProvider,
  WorkspaceActiveWindowPayload,
  LanguageInconsistencyAlert,
  DeviceType,
  ListenerData,
  SettingsModalData,
  AlertLevel,
} from "../types";
import { Analytics, AnalyticsCategory } from "../../utils/ganalytics";
import { log, LogCategory } from "../../utils/log";
import { showModal } from "../../screens/Dashboard/state";
import { BROADCAST_START_MODAL_ID, SETTINGS_MODAL_ID } from "../../screens/Dashboard/constants";
import { getCurrentWorkspaceId } from ".";
import { allowdedWorkspace, getCurrentWorkspace, getCurrentWorkspaceLanguages, logoutIpValidation } from "../workspace";
import { getDeviceInfo, parseHeartbeat } from "../../utils/helpers";
import { bringToFront } from "../virtualOffice/actions";
import { addNotificationOnPIP, removeNotificationOnPIP, showNotificationWindow } from "../notificationsWindow";
import { checkDeviceAvailable } from "../../utils/devices";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { channels } from "../../electron/channels";
import { channelJoin, getCurrentVoiceChannel, getMyPosition, getSpeechToTextEnabled } from "../voiceChannels";
import localData, { MapStatesLocalStorageClient } from "../../localStorageKeys";
import {
  doLogout,
  getIsMeetingRecording,
  updateAccountSelectedProjectAction,
  getBaseLanguage,
  checkIsListener,
} from "../account";
import { ScheduleEvent } from "./reducer";
import { enableValidateIp } from "../../api/workspace";
import { doStopScreenShare, showScreenOverlayWindow } from "../screenshare";
import { DEFAULT_SEARCH_MEMBER_HISTORY_COUNT } from "../../constant";
import { Toast } from "../../components/antd/Toast";
import { intl } from "../../i18n";
import { EmojiReactionsType, RandomEmojiReactions } from "../../components/core/Emoji";
import { actions, updateDescription } from "../timeTracker/actions";
import {
  checkUserInAudibleArea,
  checkUserIsBroadcaster,
  disableMute,
  disableVideo,
  enableMute,
  getLivekitConnectionStatus,
  getMicLivekitLoading,
  getVideoLivekitLoading,
  setVideoLoading,
} from "../livekit";
import dayjs from "dayjs";
import moment from "moment";
import { getIsReachedSttMaxUsage, workspaceSpeechToTextLimitReached } from "../textChannel";
import { VirtualOfficeObject } from "../virtualOffice/types";
import { getIsLoadingRecordAudioExtraction } from "../audioExtraction";

const { os } = getDeviceInfo();

export enum UsersActions {
  SET_ONLINE_USERS_IN_VOICECHANNEL = "users/SET_ONLINE_USERS_IN_VOICECHANNEL",
  SET_WORKSPACE_USERS = "users/SET_WORKSPACE_USERS",
  SET_WORKSPACE_USERS_ENABLE_WEB_APP = "users/SET_WORKSPACE_USERS",
  ADD_USER = "users/ADD_USER",
  REMOVE_USER = "users/REMOVE_USER",
  UPDATE_WORKSPACE = "users/UPDATE_WORKSPACE",
  UPDATE_CHANNEL = "users/UPDATE_CHANNEL",
  UPDATE_ONLINE = "users/UPDATE_ONLINE",
  UPDATE_SPEAKER_ACTIVE = "users/UPDATE_SPEAKER_ACTIVE",
  UPDATE_STATUS = "users/UPDATE_STATUS",
  UPDATE_MIC_ACTIVE = "users/UPDATE_MIC_ACTIVE",
  UPDATE_BROADCAST_ACTIVE = "users/UPDATE_BROADCAST_ACTIVE",
  UPDATE_SILENCE_ACTIVE = "users/UPDATE_SILENCE_ACTIVE",
  UPDATE_VIDEO_ACTIVE = "users/UPDATE_VIDEO_ACTIVE",
  UPDATE_NATIVE_NOTIFIATION = "users/UPDATE_NATIVE_NOTIFIATION",
  UPDATE_AVATAR_URL = "users/UPDATE_AVATAR_URL",
  UPDATE_NAME = "users/UPDATE_NAME",
  UPDATE_MUTE = "users/UPDATE_MUTE",
  UPDATE_SCREEN_ACTIVE = "users/UPDATE_SCREEN_ACTIVE",
  SET_PARTICIPANT_IDENTITY = "users/SET_PARTICIPANT_IDENTITY",
  REMOVE_USERS_FROM_CHANNEL = "users/REMOVE_USERS_FROM_CHANNEL",
  UPDATE_SELF_ACTIVE_WINDOW = "users/UPDATE_SELF_ACTIVE_WINDOW",
  UPDATE_ACTIVE_WINDOW_BATCH = "users/UPDATE_ACTIVE_WINDOW_BATCH",
  UPDATE_WORKING_START = "users/UPDATE_WORKING_START",
  UPDATE_WORKING_MEMO = "users/UPDATE_WORKING_MEMO",
  UPDATE_USER_TIMETRACKING = "users/UPDATE_USER_TIMETRACKING",
  UPDATE_USER_SCREENSHOT = "users/UPDATE_USER_SCREENSHOT",
  UPDATE_AVATAR_POSITION = "users/UPDATE_AVATAR_POSITION",
  UPDATE_STT_SETTING = "users/UPDATE_STT_SETTING",
  UPDATE_ROLE = "users/UPDATE_ROLE",
  UPDATE_FLOOR_IDLE_AUTO_MIC_MUTE_STATUS = "users/UPDATE_FLOOR_IDLE_AUTO_MIC_MUTE_STATUS",
  UPDATE_USERS_ASSIGNED_FLOORS = "users/UPDATE_USERS_ASSIGNED_FLOORS",
  UPDATE_USERS_SELECTED_PROJECT = "users/UPDATE_USERS_SELECTED_PROJECT",
  UPDATE_SEARCHED_MEMBER = "users/UPDATE_SEARCHED_MEMBER",
  INCREASE_SEARCH_MEMBER_FOCUS = "users/INCREASE_SEARCH_MEMBER_FOCUS",
  UPDATE_HIGHLIGHTED_MEMBER = "users/UPDATE_HIGHLIGHTED_MEMBER",
  UPDATE_LAST_SCROLL_POSITION = "users/UPDATE_LAST_SCROLL_POSITION",
  ADD_TO_SEARCH_MEMBER_HISTORY = "users/ADD_TO_SEARCH_MEMBER_HISTORY",
  UPDATE_OFFLINE_USERS_COLLAPSED_STATUS = "users/UPDATE_OFFLINE_USERS_COLLAPSED_STATUS",
  UPDATE_OFFLINE_USERS_OPENED_STATUS = "users/UPDATE_OFFLINE_USERS_OPENED_STATUS",
  UPDATE_GOOGLE_CALENDAR_STATUS = "users/UPDATE_GOOGLE_CALENDAR_STATUS",
  UPDATE_OUTLOOK_CALENDAR_STATUS = "users/UPDATE_OUTLOOK_CALENDAR_STATUS",
  SET_EMOJI_REACTION = "users/SET_EMOJI_REACTION",
  RESET_EMOJI_REACTION = "users/RESET_EMOJI_REACTION",
  REMOVE_EMOJI_REACTION = "users/REMOVE_EMOJI_REACTION",
  RESET_USER_SELECTED_PROJECT = "users/RESET_USER_SELECTED_PROJECT",
  SYNC_CALENDAR_WORKING_MEMO = "user/SYNC_CALENDAR_WORKING_MEMO",
  UPDATE_MEMBER_ENABLE_WEB_APP = "user/UPDATE_MEMBER_ENABLE_WEB_APP",
  UPDATE_MANUAL_WORKING_MEMO_TEMP = "user/UPDATE_MANUAL_WORKING_MEMO_TEMP",
  CLEANUP_ALL_SPEAKER_ACTIVE = "user/CLEANUP_ALL_SPEAKER_ACTIVE",
  UPDATE_CALENDAR_EVENTS = "users/UPDATE_CALENDAR_EVENTS",
  USER_GOOGLE_INTEGRATION_UPDATE = "users/GOOGLE_INTEGRATION_UPDATE",
  USER_AZURE_INTEGRATION_UPDATE = "users/AZURE_INTEGRATION_UPDATE",
  USER_SYNC_WORKING_MEMO_UPDATE = "users/USER_SYNC_WORKING_MEMO_UPDATE",
  REFRESH_CALENDAR_EVENTS = "users/REFRESH_CALENDAR_EVENTS",
  USER_HAS_MOBILE_APP_UPDATE = "users/HAS_MOBILE_APP_UPDATE",
  UPDATE_LANGUAGE_INCONSISTENCY_ALERT = "users/UPDATE_LANGUAGE_INCONSISTENCY_ALERT",
  SET_WORKSPACE_LISTENERS = "users/SET_WORKSPACE_LISTENERS",
  ADD_LISTENER = "users/ADD_LISTENER",
  UPDATE_LISTENER = "users/UPDATE_LISTENER",
  REMOVE_LISTENER = "users/REMOVE_LISTENER",
  REMOVE_LISTENERS_FROM_VOICE_CHANNEL = "users/REMOVE_LISTENERS_FROM_VOICE_CHANNEL",
  REMOVE_LISTENERS_FROM_SHARING = "users/REMOVE_LISTENERS_FROM_SHARING",
  UPDATE_AUDIO_EXTRACTION = "users/UPDATE_AUDIO_EXTRACTION",
  SET_USER_MEDIA_STREAM = "users/SET_USER_MEDIA_STREAM",
  SET_LISTENER_MEDIA_STREAM = "users/SET_LISTENER_MEDIA_STREAM",
  UPDATE_WEB_FCM_TOKEN = "users/UPDATE_WEB_FCM_TOKEN",
  SET_ENABLE_TEXT_CHAT_MIC = "users/SET_ENABLE_TEXT_CHAT_MIC",
}

export interface SetOnlineUsersInVoiceChannelAction extends Action {
  type: UsersActions.SET_ONLINE_USERS_IN_VOICECHANNEL;
  payload: MemberData[];
}

export interface SetWorkspaceUsersAction extends Action {
  type: UsersActions.SET_WORKSPACE_USERS;
  payload: MemberData[];
}

export interface AddUserAction extends Action {
  type: UsersActions.ADD_USER;
  payload: MemberData;
}

export interface UserIdentifier {
  userId: number;
}

export interface UpdateBroadcastActive {
  userId: number;
  voiceChannelId: number;
  broadcastActive: boolean;
}

export interface UpdateBroadcastActiveNotification extends UpdateBroadcastActive {
  broadcastId: string;
}

export interface UpdateBroadcastActiveAction extends Action {
  type: UsersActions.UPDATE_BROADCAST_ACTIVE;
  payload: UpdateBroadcastActive;
}

export interface RemoveUserAction extends Action {
  type: UsersActions.REMOVE_USER;
  payload: UserIdentifier;
}

export interface UpdateWorkspace extends UserIdentifier {
  workspaceId: number;
}

export interface UpdateWorkspaceAction extends Action {
  type: UsersActions.UPDATE_WORKSPACE;
  payload: UpdateWorkspace;
}

export interface UpdateChannel extends UserIdentifier {
  voiceChannelId: number | null;
}

export interface UpdateChannelAction extends Action {
  type: UsersActions.UPDATE_CHANNEL;
  payload: UpdateChannel;
}

export interface UpdateOnline extends UserIdentifier {
  workspaceId?: number;
  online: boolean;
  voiceChannelId?: number | null;
  screenActive?: boolean;
  connections?: { from: DeviceType; version: string }[];
  lastActiveAt: Date;
}

export interface UpdateOnlineAction extends Action {
  type: UsersActions.UPDATE_ONLINE;
  payload: UpdateOnline;
}

export interface UpdateStatus extends UserIdentifier {
  status: MemberStatus;
  googleStatus: MemberStatus;
  azureStatus: MemberStatus;
  statusProvider: StatusProvider;
  workingMemo?: string;
  lastActiveAt: Date | null;
}

export interface UpdateStatusAction extends Action {
  type: UsersActions.UPDATE_STATUS;
  payload: UpdateStatus;
}

export interface UpdateMicActive {
  userId: number;
  mute: boolean;
}

export interface UpdateNativeNotification {
  userId?: number;
  workspaceId?: number;
  nativeNotification: boolean;
}

export interface UpdateNativeNotificationAction extends Action {
  type: UsersActions.UPDATE_NATIVE_NOTIFIATION;
  payload: UpdateNativeNotification;
}

export interface UpdateSilenceActive extends UserIdentifier {
  workspaceId?: number;
  silence: boolean;
}

export interface UpdateSilenceActiveAction extends Action {
  type: UsersActions.UPDATE_SILENCE_ACTIVE;
  payload: UpdateSilenceActive;
}

export interface UpdateSpeakerActive extends UserIdentifier {
  workspaceId: number;
  speakerActive: boolean;
}

export interface UpdateSpeakerActiveAction extends Action {
  type: UsersActions.UPDATE_SPEAKER_ACTIVE;
  payload: UpdateSpeakerActive;
}

export interface UpdateVideoActive extends UserIdentifier {
  workspaceId: number;
  videoActive: boolean;
}

export interface UpdateVideoActiveAction extends Action {
  type: UsersActions.UPDATE_VIDEO_ACTIVE;
  payload: UpdateVideoActive;
}

export interface UpdateScreenActive extends UserIdentifier {
  workspaceId: number;
  screenActive: boolean;
  screenIndex?: number;
  isDrawingAllowed?: boolean;
}

export interface UpdateSpeakerActive extends UserIdentifier {
  workspaceId: number;
  speakerActive: boolean;
}

export interface RemoveUsersFromChannel {
  voiceChannelId: number;
}

export interface RemoveUsersFromChannelAction extends Action {
  type: UsersActions.REMOVE_USERS_FROM_CHANNEL;
  payload: RemoveUsersFromChannel;
}

export interface PostUpdateAvatar {
  workspaceId: number;
  avatarUrl: string;
}

export interface UpdateAvatar extends UserIdentifier, PostUpdateAvatar {}

export interface UpdateAvatarAction extends Action {
  type: UsersActions.UPDATE_AVATAR_URL;
  payload: UpdateAvatar;
}

export interface UpdateNameResponse extends UserIdentifier {
  workspaceId: number;
  displayName: string;
  avatarUrl: string;
}
export interface UpdateName extends UserIdentifier {
  workspaceId: number;
  displayName: string;
}

export interface UpdateNameAction extends Action {
  type: UsersActions.UPDATE_NAME;
  payload: UpdateNameResponse;
}
export interface UpdateActiveWindow extends UserIdentifier {
  activeWindowName: string | null;
  activeWindowIcon: string | null;
}

export interface UserScreenActiveNotification {
  workspaceId: number;
  userId: number;
}
export interface UserWhiteBoardOrTextEditorCreateNotification extends UserScreenActiveNotification {
  object: VirtualOfficeObject;
}

export type UsersActionTypes =
  | SetOnlineUsersInVoiceChannelAction
  | SetWorkspaceUsersAction
  | AddUserAction
  | RemoveUserAction
  | UpdateWorkspaceAction
  | UpdateChannelAction
  | UpdateStatusAction
  | UpdateOnlineAction
  | UpdateNativeNotificationAction
  | UpdateSilenceActiveAction
  | UpdateSpeakerActiveAction
  | UpdateVideoActiveAction
  | UpdateAvatarAction
  | UpdateNameAction
  | UpdateBroadcastActiveAction
  | RemoveUsersFromChannelAction
  | ReturnType<typeof updateUserAvatarPositionAction>
  | ReturnType<typeof updateSelfUserActiveWindow>
  | ReturnType<typeof updateUserActiveWindowByBatch>
  | ReturnType<typeof updateUserStartWorkingAction>
  | ReturnType<typeof updatedUserWorkingMemoAction>
  | ReturnType<typeof updatedUserTimeTracking>
  | ReturnType<typeof updatedUserCaptureScreenshot>
  | ReturnType<typeof updateUserSTTSettingsAction>
  | ReturnType<typeof updateUserRoleAction>
  | ReturnType<typeof updateFloorIdleAutoMicMuteStatusAction>
  | ReturnType<typeof updateUsersAssignedFloorsAction>
  | ReturnType<typeof updateSearchedMemberAction>
  | ReturnType<typeof updateUsersSelectedProjectAction>
  | ReturnType<typeof increaseSearchMemberFocusAction>
  | ReturnType<typeof updateHighlightedMemberAction>
  | ReturnType<typeof addToSearchMemberHistoryAction>
  | ReturnType<typeof updateLastScrollPositionAction>
  | ReturnType<typeof updateOfflineUsersOpenedStatusAction>
  | ReturnType<typeof updateGoogleCalendarStatusAction>
  | ReturnType<typeof updateOutlookCalendarStatusAction>
  | ReturnType<typeof addEmojiReactionAction>
  | ReturnType<typeof resetEmojiAction>
  | ReturnType<typeof updatedUserEnableWebApp>
  | ReturnType<typeof resetUserSelectedProjectAction>
  | ReturnType<typeof syncCalendarMemoAction>
  | ReturnType<typeof updateManualWorkingMemoAction>
  | ReturnType<typeof updateScreenActiveAction>
  | ReturnType<typeof updateUserMicActive>
  | ReturnType<typeof setUserParticipantIdentity>
  | ReturnType<typeof cleanupAllSpeakerActiveAction>
  | ReturnType<typeof updateCalendarEventsAction>
  | ReturnType<typeof updatedGoogleIntegrationValue>
  | ReturnType<typeof updatedAzureIntegrationValue>
  | ReturnType<typeof refreshScheduleEventsAction>
  | ReturnType<typeof updateUserHasMobileApp>
  | ReturnType<typeof updateLanguageInconsistencyAlert>
  | ReturnType<typeof setWorkspaceListeners>
  | ReturnType<typeof addListener>
  | ReturnType<typeof updateListener>
  | ReturnType<typeof removeListener>
  | ReturnType<typeof removeListenersFromVoiceChannel>
  | ReturnType<typeof removeListenersFromSharing>
  | ReturnType<typeof setListenerMediaStream>
  | ReturnType<typeof updateUserAudioExtraction>
  | ReturnType<typeof updateUserWebFCMTokenAction>
  | ReturnType<typeof setEnableTextChatMic>;

export function updateScreenActiveAction(payload: { userId: number; screenActive: boolean }) {
  return {
    type: UsersActions.UPDATE_SCREEN_ACTIVE,
    payload,
  } as const;
}

export function setOnlineUsersInVoiceChannel(payload: MemberData[]) {
  return { type: UsersActions.SET_ONLINE_USERS_IN_VOICECHANNEL, payload };
}

export function setOnlineUsersEnableWebApp(payload: any) {
  return { type: UsersActions.SET_ONLINE_USERS_IN_VOICECHANNEL, payload };
}

export function setWorkspaceUsers(payload: MemberData[]) {
  return { type: UsersActions.SET_WORKSPACE_USERS, payload };
}

export function addUser(payload: MemberData) {
  return { type: UsersActions.ADD_USER, payload };
}

export function removeUser(payload: UserIdentifier) {
  return { type: UsersActions.REMOVE_USER, payload };
}

export function updateUserWorkspace(payload: UpdateWorkspace) {
  return { type: UsersActions.UPDATE_WORKSPACE, payload };
}

export function updateUserChannel(payload: UpdateChannel) {
  return { type: UsersActions.UPDATE_CHANNEL, payload };
}

export function updateUserOnline(payload: UpdateOnline) {
  return { type: UsersActions.UPDATE_ONLINE, payload };
}

export function updateUserSpeakerActive(payload: UpdateSpeakerActive) {
  return { type: UsersActions.UPDATE_SPEAKER_ACTIVE, payload };
}

export function updateUserMicActive(payload: UpdateMicActive) {
  return { type: UsersActions.UPDATE_MIC_ACTIVE, payload } as const;
}

export function updateUserBroadcastActiveAction(payload: UpdateBroadcastActive) {
  return { type: UsersActions.UPDATE_BROADCAST_ACTIVE, payload };
}

export function updateUserNativeNotification(payload: UpdateNativeNotification) {
  return { type: UsersActions.UPDATE_NATIVE_NOTIFIATION, payload };
}

export function updateUserSilenceActive(payload: UpdateSilenceActive) {
  return { type: UsersActions.UPDATE_SILENCE_ACTIVE, payload };
}

export function updateUserVideoActiveAction(payload: UpdateVideoActive) {
  return { type: UsersActions.UPDATE_VIDEO_ACTIVE, payload };
}

export function updateUserScreenActiveAction(payload: UpdateScreenActive) {
  return { type: UsersActions.UPDATE_SCREEN_ACTIVE, payload };
}

export function setUserParticipantIdentity({ userId, identity }: { userId: number; identity?: string }) {
  return { type: UsersActions.SET_PARTICIPANT_IDENTITY, payload: { userId, identity } } as const;
}

export function updateUserScreenActiveReceive(payload: UpdateScreenActive): AppThunk {
  return (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());

    if (isMeetingRecording) {
      return;
    }

    dispatch(updateUserScreenActiveAction(payload));

    if (!payload.screenActive) {
      dispatch(
        removeNotificationOnPIP({
          type: "screen-share-notification",
          screenSharerUserId: payload.userId,
        }),
      );
    } else {
      dispatch(
        showUserScreenActiveNotification({
          workspaceId: payload.workspaceId,
          userId: payload.userId,
        }),
      );
    }

    // dispatch(openScreenshareOverlayWindow(payload.userId));
    dispatch(showScreenOverlayWindow());
  };
}

export function updateUserScreenActiveSend(payload: UpdateScreenActive): AppThunk {
  return (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    if (isMeetingRecording || !currentVoiceChannel) {
      return;
    }

    const selfDiv = document.getElementsByClassName("spawned-user--self")[0] as HTMLDivElement;

    if (!selfDiv) return;

    const userRect = selfDiv.getBoundingClientRect();
    const mapScalingClient = new MapStatesLocalStorageClient(currentVoiceChannel.id);
    const mapTransform = mapScalingClient.loadData();
    const scale = mapTransform?.voiceChannel?.scale!;
    const myPosition = getMyPosition(getState());

    if (!myPosition) return;

    let x = myPosition.x;
    let y = myPosition.y;

    if (userRect.x + 500 * scale > window.innerWidth && userRect.y + 300 * scale > window.innerHeight) {
      x -= 480;
      y -= 270;
    } else if (userRect.x + 500 * scale > window.innerWidth) {
      x -= 480;
    } else if (userRect.y + 300 * scale > window.innerHeight) {
      y -= 270;
      x += 100;
    } else {
      x += 100;
    }

    dispatch(updateUserScreenActiveAction(payload));
    dispatch(
      sendMessage(USER_EVENT_TYPES.USER_SCREEN_ACTIVE, {
        workspaceId: payload.workspaceId,
        screenActive: payload.screenActive,
        framePosition: { x, y },
        screenIndex: payload.screenIndex,
        isDrawingAllowed: false,
      }),
    );
  };
}

export function doStopScreenShareReceive(): AppThunk {
  return dispatch => {
    dispatch(doStopScreenShare());
  };
}

export function removeUsersFromChannel(payload: RemoveUsersFromChannel) {
  return { type: UsersActions.REMOVE_USERS_FROM_CHANNEL, payload };
}

export const updateAvatar = (payload: UpdateAvatar): AppThunk => {
  return dispatch => {
    Analytics(AnalyticsCategory.User, "User uploaded an avatar");
    dispatch({ type: UsersActions.UPDATE_AVATAR_URL, payload });
  };
};

export function postUpdatedAvatar(payload: PostUpdateAvatar) {
  return sendMessage(USER_EVENT_TYPES.USER_AVATAR, payload);
}

export function updateName(payload: UpdateNameResponse): AppThunk {
  return dispatch => {
    dispatch({ type: UsersActions.UPDATE_NAME, payload });
  };
}

export const postUpdatedName = (payload: UpdateName): AppThunk => {
  return dispatch => {
    dispatch(sendMessage(USER_EVENT_TYPES.USER_NAME, payload));
  };
};

export function changeUserStatus({
  workspaceId,
  status,
  statusProvider,
}: {
  workspaceId: number;
  status: MemberStatus;
  statusProvider: StatusProvider;
}): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me?.role === "meeting-recording-bot" || me?.role === "listener") {
      return;
    }

    const isMeetingRecording = getIsMeetingRecording(getState());

    if (isMeetingRecording) {
      return;
    }

    const myStatus = getMyMemberStatus(getState());

    if (me && (myStatus !== status || me?.statusProvider !== statusProvider)) {
      // When the new status is different with prev status, notify to users in the channel.
      Analytics(AnalyticsCategory.User, `User status updated to ${status}`);
      dispatch(sendMessage(USER_EVENT_TYPES.USER_OUT_UPDATE_STATUS_REQUEST, { workspaceId, status, statusProvider }));
      dispatch(
        updateUserStatus({
          userId: me!.id,
          status,
          googleStatus: me.googleStatus,
          azureStatus: me.azureStatus,
          statusProvider,
          lastActiveAt: me.lastActiveAt,
        }),
      );
    }
  };
}

export function toggleSilenceStatus(): AppThunk {
  return (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());

    if (isMeetingRecording) {
      return;
    }

    const me = getMyMember(getState());
    const silence = !me?.silence;
    const workspace = getCurrentWorkspace(getState());
    const workspaceId = workspace?.id;

    if (!workspaceId) return;

    dispatch(changeSilenceStatus(workspaceId, silence));
  };
}

export function validateIp(): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());

    if (currentWorkspace && currentWorkspace.id) {
      const data = await enableValidateIp(currentWorkspace.id);

      if (!data.ipValidationPassed) {
        if (data.firstAvailableWorkspaceId) {
          dispatch(allowdedWorkspace(data));
        } else {
          dispatch(logoutIpValidation(data));
        }
      }
    }
  };
}

export function changeSilenceStatus(workspaceId: number, silence: boolean): AppThunk {
  return (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());

    if (isMeetingRecording) {
      return;
    }

    const me = getMyMember(getState());

    dispatch(updateUserSilenceActive({ userId: me!.id, workspaceId, silence }));
    dispatch(sendMessage(USER_EVENT_TYPES.USER_SILENCE, { workspaceId, silence }));
    dispatch(removeNotificationOnPIP({ type: "silence-unsilence-request" }));
    sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "silence-unsilence-request");
  };
}

export function toggleMicStatus(): AppThunk {
  return (dispatch, getState) => {
    const sttStatus = getSpeechToTextEnabled(getState());
    const isReachedSttMaxLimit = getIsReachedSttMaxUsage(getState());

    const me = getMyMember(getState());
    const isMicLoading = getMicLivekitLoading(getState());

    if (sttStatus && isReachedSttMaxLimit) {
      dispatch(workspaceSpeechToTextLimitReached(true, false));
    }

    if (!isMicLoading) {
      dispatch(changeMicStatus(!me?.mute));
    }
  };
}

export function changeMicStatus(mute: boolean, isInitinal?: boolean): AppThunk {
  return async (dispatch: Function, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());
    const me = getMyMember(getState());

    if (isMeetingRecording) {
      return;
    }

    if (!mute) {
      await dispatch(loadPermission("microphone"));
      const microphonePermissionGranted = getPermissionStateMicrophone(getState());

      if (!microphonePermissionGranted && os === "macos") {
        await dispatch(showRequiredMicrophonePermissionModal());
        return;
      }
    }

    const livekitConnection = getLivekitConnectionStatus(getState());

    if (livekitConnection === "connected") {
      if (mute) {
        await dispatch(enableMute());
      } else {
        await dispatch(disableMute());
        dispatch(enableTextChatMic(true));
      }
    }

    const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(getState());

    if ((livekitConnection !== "connected" || isInitinal) && me && me.workspaceId && !isLoadingRecordAudioExtraction) {
      await Promise.all([
        dispatch(
          sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
            workspaceId: me.workspaceId,
            mute,
          }),
        ),
        dispatch(updateUserMicActive({ userId: me.id, mute })),
      ]);
    }

    dispatch(removeNotificationOnPIP({ type: "mute-unmute-request" }));
    await sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "mute-unmute-request");
    dispatch(removeNotificationOnPIP({ type: "unmute-in-idle-notification" }));
    await sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "unmute-in-idle-notification");
  };
}

export function toggleNativeNotificatonStatus(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const nativeNotification = !me?.nativeNotification;

    dispatch(changeNativeNotificationStatus(nativeNotification));
  };
}

export function setNativeNotification(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const nativeNotification = me?.nativeNotification;

    localData.set("settings.workspace.nativeNotification", JSON.stringify(!!nativeNotification));
  };
}

export function changeNativeNotificationStatus(nativeNotification: boolean): AppThunk {
  return async (dispatch: Function, getState) => {
    const me = getMyMember(getState());

    if (me && me.workspaceId) {
      await Promise.all([
        dispatch(
          sendMessage(USER_EVENT_TYPES.USER_NATIVE_NOTIFICATION, { workspaceId: me.workspaceId, nativeNotification }),
        ),
        dispatch(updateUserNativeNotification({ userId: me.id, workspaceId: me.workspaceId, nativeNotification })),
        dispatch(setNativeNotification()),
      ]);
    }
  };
}
export function toggleVideoStatus(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const webCameraStatus = getWebCameraStatus(getState());

    console.log(`WEB CAMERA STATUS LOG: toggle video status method called`);

    if (!me) return;

    if (!me.videoActive) {
      dispatch(bringToFront({ userId: me.id, type: "avatar" }));
    }

    console.log(`WEB CAMERA STATUS LOG: toggle video status method called
      Webcamera on/off on invite feature: ${webCameraStatus}
      Current video active status: ${me.videoActive}
      Dispatch changeSelfVideoStatus called for ${!me.videoActive}
    `);
    dispatch(changeSelfVideoStatus(!me.videoActive));
  };
}

export function updateUserVideoActive(payload: UpdateVideoActive): AppThunk {
  return (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());

    if (isMeetingRecording) {
      return;
    }

    dispatch(updateUserVideoActiveAction(payload));
    dispatch(
      sendMessage(USER_EVENT_TYPES.USER_VIDEO_ACTIVE, {
        workspaceId: payload.workspaceId,
        videoActive: payload.videoActive,
      }),
    );
  };
}

export function changeSelfVideoStatus(enabled: boolean): AppThunk {
  return async (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());
    const isVideoLoading = getVideoLivekitLoading(getState());

    console.log(`WEB CAMERA STATUS LOG: changeSelfVideoStatus action`);

    console.log(`WEB CAMERA STATUS LOG: changeSelfVideoStatus action checking status
    isMeetingRecording: ${isMeetingRecording}
    isVideoLoading: ${isVideoLoading}
    `);

    if (isMeetingRecording || isVideoLoading) {
      return;
    }

    const me = getMyMember(getState());
    const deviceSetting = getDeviceSettings(getState());
    const deviceAvailable = await checkDeviceAvailable(deviceSetting.videoinput.id);

    console.log(`WEB CAMERA STATUS LOG: changeSelfVideoStatus action checking status
    me?.voiceChannelId: ${me?.voiceChannelId}
    `);

    if (!me?.voiceChannelId) return;

    console.log(`WEB CAMERA STATUS LOG: changeSelfVideoStatus action - setting video loading..`);

    dispatch(setVideoLoading(true));
    dispatch(removeNotificationOnPIP({ type: "camera-in-idle-notification" }));
    await sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "camera-in-idle-notification");

    if (enabled) {
      if (deviceAvailable) {
        await dispatch(loadPermission("camera"));
        const cameraPermissionGranted = getPermissionStateCamera(getState());

        if (!cameraPermissionGranted && os === "macos") {
          await dispatch(showRequiredCameraPermissionModal());
          return;
        }

        dispatch(
          updateUserVideoActive({
            workspaceId: me.workspaceId,
            userId: me.id,
            videoActive: true,
          }),
        );

        console.log(
          `WEB CAMERA STATUS LOG: changeSelfVideoStatus action - Updated video to true from `,
          me.videoActive,
        );

        return;
      }
    }

    dispatch(disableVideo());
    dispatch(
      updateUserVideoActive({
        workspaceId: me.workspaceId,
        userId: me.id,
        videoActive: false,
      }),
    );

    console.log(`WEB CAMERA STATUS LOG: changeSelfVideoStatus action - Updated video to from `, me.videoActive);
  };
}

export function logoutUser(): AppThunk {
  return dispatch => {
    dispatch(forceLogout());
  };
}

export function updateSelfUserActiveWindow(payload: UpdateActiveWindow) {
  log(LogCategory.VirtualWorkspace, "Data being Recieved from the server", payload);

  return {
    type: UsersActions.UPDATE_SELF_ACTIVE_WINDOW,
    payload,
  } as const;
}

export function updateUserActiveWindowByBatch(payload: WorkspaceActiveWindowPayload[]) {
  log(LogCategory.VirtualWorkspace, "Data being Recieved from the server", payload);

  return {
    type: UsersActions.UPDATE_ACTIVE_WINDOW_BATCH,
    payload,
  } as const;
}

export function updateUserStartWorkingAction(payload: { userId: number; workingMemo: string }) {
  return {
    type: UsersActions.UPDATE_WORKING_START,
    payload,
  } as const;
}

export function updateUserAvatarPosition(payload: {
  workspaceId: number;
  userId: number;
  position: Position;
}): AppThunk {
  return dispatch => {
    dispatch(updateUserAvatarPositionAction(payload));
  };
}

export function updatedUserEnableWebApp(payload: any) {
  return {
    type: UsersActions.UPDATE_MEMBER_ENABLE_WEB_APP,
    payload,
  } as const;
}

function updateUserAvatarPositionAction(payload: { workspaceId: number; userId: number; position: Position }) {
  return {
    type: UsersActions.UPDATE_AVATAR_POSITION,
    payload,
  } as const;
}

function updatedUserWorkingMemoAction(payload: { userId: number; workingMemo: string }) {
  return {
    type: UsersActions.UPDATE_WORKING_MEMO,
    payload,
  } as const;
}

export function updatedUserTimeTracking(payload: any) {
  return {
    type: UsersActions.UPDATE_USER_TIMETRACKING,
    payload,
  } as const;
}

export function updatedUserCaptureScreenshot(payload: { enableScreenshot: boolean }) {
  return {
    type: UsersActions.UPDATE_USER_SCREENSHOT,
    payload,
  } as const;
}

export function updateMyUserWorkingMemo(payload: {
  workspaceId: number;
  workingMemo?: string;
  isManual?: boolean;
}): AppThunk {
  return dispatch => {
    dispatch(sendMessage(USER_EVENT_TYPES.USER_WORKING_MEMO_UPDATE_REQUEST, payload));
  };
}

export function updatedUserWorkingMemo(payload: { userId: number; workingMemo: string }): AppThunk {
  return dispatch => {
    dispatch(updatedUserWorkingMemoAction(payload));
  };
}

export function openUserSttSettingModal(): AppThunk {
  return dispatch => {
    dispatch(
      showModal<SettingsModalData>({
        id: SETTINGS_MODAL_ID,
        show: true,
        data: { activeSection: "workspaceSpeechToText" },
      }),
    );
  };
}

export function updateUserSTTSettings(payload: { userId: number; speechToText: SpeechToTextSettings }): AppThunk {
  return dispatch => {
    dispatch(updateUserSTTSettingsAction(payload));
  };
}

function addEmojiReactionAction(payload: {
  userId: number;
  voiceChannelId: number;
  emoji: EmojiReactionsType;
  persistent: boolean;
}) {
  return {
    type: UsersActions.SET_EMOJI_REACTION,
    payload,
  } as const;
}

export function addEmojiReaction(payload: {
  userId: number;
  voiceChannelId: number;
  emoji: EmojiReactionsType;
  persistent: boolean;
}): AppThunk {
  return dispatch => {
    dispatch(addEmojiReactionAction(payload));
  };
}

export function sendRandomEmoji(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const rand = Math.floor(Math.random() * Object.keys(RandomEmojiReactions).length);

    dispatch(
      sendMessage(USER_EVENT_TYPES.OUT_USER_REACTION_REQUEST, {
        voiceChannelId: me?.voiceChannelId,
        emoji: Object.keys(RandomEmojiReactions)[rand],
        persistent: false,
      }),
    );
  };
}

export function resetEmojiAction(payload: { userId: number; persistent: boolean }) {
  return {
    type: UsersActions.RESET_EMOJI_REACTION,
    payload,
  } as const;
}

export function updateManualWorkingMemoAction(payload: { userId: number; workingMemo: string | null }) {
  return {
    type: UsersActions.UPDATE_MANUAL_WORKING_MEMO_TEMP,
    payload,
  } as const;
}

export function updateUserCalendarStatus(payload: {
  workspaceId: number;
  googleStatus: MemberStatus;
  azureStatus: MemberStatus;
  workingMemo?: string;
  statusProvider: StatusProvider;
  status: MemberStatus;
  lastActiveAt: Date;
  userId: number;
  message?: string;
}): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    if (payload.message) {
      Toast.error(
        intl.formatMessage({
          id: "user-status/outlook-automatically-disconnection",
          defaultMessage: "failed to refresh token and disconnected from Outlook. please integrate again if you want.",
        }),
      );
      dispatch(
        updateUserStatusAction({
          userId: payload.userId,
          status: payload.status,
          googleStatus: payload.googleStatus,
          azureStatus: payload.azureStatus,
          statusProvider: payload.statusProvider,
          lastActiveAt: payload.lastActiveAt,
        }),
      );
    }

    if (payload.googleStatus && me.googleStatus !== payload.googleStatus) {
      dispatch(
        updateGoogleCalendarStatusAction({
          userId: me.id,
          workspaceId: payload.workspaceId,
          googleStatus: payload.googleStatus,
        }),
      );
    }

    if (payload.azureStatus && me.azureStatus !== payload.azureStatus) {
      dispatch(
        updateOutlookCalendarStatusAction({
          userId: me.id,
          workspaceId: payload.workspaceId,
          azureStatus: payload.azureStatus,
        }),
      );
    }

    if (
      (me.statusProvider === "Google" && payload.googleStatus) ||
      (me.statusProvider === "Outlook" && payload.azureStatus)
    ) {
      let workingMemo = payload.workingMemo;

      if (workingMemo && me.syncCalendar) {
        await dispatch(updateManualWorkingMemoAction({ userId: me.id, workingMemo: me.workingMemo }));
      } else {
        await dispatch(updateManualWorkingMemoAction({ userId: me.id, workingMemo: workingMemo ?? "" }));
      }

      if (
        (me.statusProvider === "Google" && payload.googleStatus !== me.googleStatus) ||
        (me.statusProvider === "Outlook" && payload.azureStatus !== me.azureStatus)
      ) {
        dispatch(
          sendMessage(USER_EVENT_TYPES.USER_OUT_UPDATE_STATUS_REQUEST, {
            workspaceId: payload.workspaceId,
            googleStatus: payload.googleStatus,
            azureStatus: payload.azureStatus,
            statusProvider: payload.statusProvider,
          }),
        );
      }

      if (
        payload.workingMemo !== undefined &&
        me?.workingMemo !== payload.workingMemo &&
        me.statusProvider === payload.statusProvider
      ) {
        dispatch(updateDescription(payload.workingMemo));
      }
    }
  };
}

export function getAllScheduleEventsForUser(payload: {
  workspaceId: number;
  userId: number;
  startDate: string;
}): AppThunk {
  return dispatch => {
    dispatch(sendMessage(USER_EVENT_TYPES.USER_OUT_CALENDAR_EVENTS_REQUEST, payload));
  };
}

export function updateUserCalendarScheduleEvents(events?: ScheduleEvent[], userId?: number): AppThunk {
  return async function (dispatch: Function, getState) {
    if (events && events.length > 0) {
      const timezoneOffset = new Date().getTimezoneOffset();

      for (let i = 0; i < events.length; i++) {
        events[i].start = moment(dayjs.utc(events[i].start).format("YYYY-MM-DDTHH:mm"))
          .add(-1 * timezoneOffset, "minutes")
          .format("YYYY-MM-DDTHH:mm");
        events[i].end = moment(dayjs.utc(events[i].end).format("YYYY-MM-DDTHH:mm"))
          .add(-1 * timezoneOffset, "minutes")
          .format("YYYY-MM-DDTHH:mm");
      }

      const allDayEvent = events.filter(a => a.allDayEvent);
      const completedEvent = events.filter(a => a.completed && !a.allDayEvent);
      const upcomingEvent = events.filter(a => !a.allDayEvent && !a.completed);

      const scheduleEvents = [...completedEvent, ...upcomingEvent, ...allDayEvent];

      dispatch(updateCalendarEventsAction({ userId, scheduleEvents: scheduleEvents }));
    } else {
      dispatch(updateCalendarEventsAction({ userId, scheduleEvents: events }));
    }
  };
}

function updateUserSTTSettingsAction(payload: { userId: number; speechToText: SpeechToTextSettings }) {
  return {
    type: UsersActions.UPDATE_STT_SETTING,
    payload,
  } as const;
}

export function changeUserDisplayLanguage(language: string | null): AppThunk {
  return async (dispatch: Function, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(USER_EVENT_TYPES.USER_CHAT_DISPLAY_LANGUAGE_REQUEST, {
        workspaceId: currentWorkspaceId,
        chatDisplayLanguage: language,
      }),
    );
  };
}

export function changeUserSpeakingLanguage(language: string | null): AppThunk {
  return async (dispatch: Function, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(USER_EVENT_TYPES.USER_PERSONAL_SPEAKING_LANGUAGE_REQUEST, {
        workspaceId: currentWorkspaceId,
        personalSpeakingLanguage: language,
      }),
    );
  };
}

export function updateUserRole(payload: { userId: number; role: Role }): AppThunk {
  return dispatch => {
    dispatch(updateUserRoleAction(payload));
  };
}

function updateUserRoleAction(payload: { userId: number; role: Role }) {
  return {
    type: UsersActions.UPDATE_ROLE,
    payload,
  } as const;
}

export function userHeartbeat(users: string[]): AppThunk {
  return (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());
    const isListener = checkIsListener(getState());

    if (isMeetingRecording || isListener) {
      return;
    }

    const me = getMyMember(getState());
    const workspaceMembers = getOnlineWorkspaceUsers(getState());
    const uncompressedHeartbeat = parseHeartbeat(users);

    workspaceMembers.forEach(localUserData => {
      const remoteUserData = uncompressedHeartbeat.find(
        u => u.id === localUserData.id && u.workspaceId === localUserData.workspaceId,
      );

      if (remoteUserData) {
        dispatch(
          updateUserOnline({
            userId: localUserData.id,
            workspaceId: localUserData.workspaceId,
            online: !localUserData.online,
            lastActiveAt: new Date(),
          }),
        );

        if (remoteUserData.id !== me?.id) {
          if (localUserData.mute !== remoteUserData.mute) {
            dispatch(updateUserMicActive({ userId: remoteUserData.id, mute: remoteUserData.mute }));
          }

          if (localUserData.silence !== remoteUserData.silence) {
            dispatch(updateUserSilenceActive({ userId: remoteUserData.id, silence: remoteUserData.silence }));
          }
        }
      }
    });
  };
}

export function showUserScreenActiveNotification(payload: UserScreenActiveNotification): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const user = getOnlineUserById(payload.userId)(getState());

    if (currentVoiceChannel) {
      const isSubscribed = checkUserInAudibleArea(user?.identity, currentVoiceChannel?.id)(getState());
      const isSubscribedBroadcaster = checkUserIsBroadcaster(user?.identity)(getState());
      const users = getOnlineWorkspaceUsers(getState());
      const sharingUser = users.find(u => u.id === payload.userId);

      if (
        me &&
        me.voiceChannelId === sharingUser?.voiceChannelId &&
        me.id !== payload.userId &&
        (isSubscribed || isSubscribedBroadcaster || currentVoiceChannel?.parentVoiceChannelId)
      ) {
        dispatch(showNotificationWindow());
        dispatch(
          addNotificationOnPIP({
            notificationsType: "screen-share-notification",
            data: [payload],
          }),
        );
      }
    }
  };
}

export function showUserWhiteBoardOrTextEditorCreateNotification(
  payload: UserWhiteBoardOrTextEditorCreateNotification,
): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const user = getOnlineUserById(payload.userId)(getState());

    if (currentVoiceChannel) {
      const isSubscribed = checkUserInAudibleArea(user?.identity, currentVoiceChannel?.id)(getState());
      const isSubscribedBroadcaster = checkUserIsBroadcaster(user?.identity)(getState());
      const users = getOnlineWorkspaceUsers(getState());
      const sharingUser = users.find(u => u.id === payload.userId);

      if (
        me &&
        me.voiceChannelId === sharingUser?.voiceChannelId &&
        me.id !== payload.userId &&
        (isSubscribed || isSubscribedBroadcaster || currentVoiceChannel?.parentVoiceChannelId) &&
        (payload.object.type === "white-board" || payload.object.type === "text-editor")
      ) {
        dispatch(showNotificationWindow());
        dispatch(
          addNotificationOnPIP({
            notificationsType:
              payload.object.type === "white-board" ? "white-board-notification" : "text-editor-notification",
            data: [payload],
          }),
        );
      }
    }
  };
}

function updateFloorIdleAutoMicMuteStatusAction(payload: { floorIdleAutoMicMuteStatus: boolean; userId: number }) {
  return {
    type: UsersActions.UPDATE_FLOOR_IDLE_AUTO_MIC_MUTE_STATUS,
    payload,
  } as const;
}

export function updateFloorIdleAutoMicMuteStatus(floorIdleAutoMicMuteStatus: boolean): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    localData.set("settings.device.audio.enableFloorIdleAutoMicMute", JSON.stringify(floorIdleAutoMicMuteStatus));
    if (me?.id) dispatch(updateFloorIdleAutoMicMuteStatusAction({ floorIdleAutoMicMuteStatus, userId: me.id }));
  };
}

export function hydrateFloorIdleAutoMicMuteStatus(): AppThunk {
  return (dispatch, getState) => {
    const floorIdleAutoMicMuteStatus = JSON.parse(localData.fetch("settings.device.audio.enableFloorIdleAutoMicMute"));
    const me = getMyMember(getState());

    if (me?.id) dispatch(updateFloorIdleAutoMicMuteStatusAction({ floorIdleAutoMicMuteStatus, userId: me.id }));
  };
}

function updateUsersAssignedFloorsAction(payload: { floors: FloorDetailData[] }) {
  return {
    type: UsersActions.UPDATE_USERS_ASSIGNED_FLOORS,
    payload,
  } as const;
}

export function updateUsersAssignedFloors(floors: FloorDetailData[]): AppThunk {
  return async dispatch => {
    await dispatch(updateUsersAssignedFloorsAction({ floors }));
  };
}

function updateUsersSelectedProjectAction(payload: {
  workspaceId: number;
  userId: number;
  projectId: number;
  projectName?: string;
}) {
  return {
    type: UsersActions.UPDATE_USERS_SELECTED_PROJECT,
    payload,
  } as const;
}

export function updateUsersSelectedProject(
  workspaceId: number,
  userId: number,
  projectId: number,
  projectName?: string,
): AppThunk {
  return dispatch => {
    dispatch(updateUsersSelectedProjectAction({ workspaceId, userId, projectId, projectName }));
  };
}

function updateSearchedMemberAction(payload: { memberId: number | undefined }) {
  return {
    type: UsersActions.UPDATE_SEARCHED_MEMBER,
    payload,
  } as const;
}

export function updateSearchedMember(memberId: number | undefined): AppThunk {
  return dispacth => {
    dispacth(updateSearchedMemberAction({ memberId }));
  };
}

function increaseSearchMemberFocusAction() {
  return { type: UsersActions.INCREASE_SEARCH_MEMBER_FOCUS } as const;
}

export function increaseSearchMemberFocus(): AppThunk {
  return dispatch => {
    dispatch(increaseSearchMemberFocusAction());
  };
}

function updateHighlightedMemberAction(payload: { memberId: number | undefined }) {
  return {
    type: UsersActions.UPDATE_HIGHLIGHTED_MEMBER,
    payload,
  } as const;
}

export function updateHighlightedMember(memberId: number | undefined): AppThunk {
  return dispatch => {
    dispatch(updateHighlightedMemberAction({ memberId }));
  };
}

function updateLastScrollPositionAction(payload: { scrollPosition: number | undefined }) {
  return {
    type: UsersActions.UPDATE_LAST_SCROLL_POSITION,
    payload,
  } as const;
}

export function updateLastScrollPosition(scrollPosition: number | undefined): AppThunk {
  return dispatch => {
    dispatch(updateLastScrollPositionAction({ scrollPosition }));
  };
}

function addToSearchMemberHistoryAction(payload: { memberId: number }) {
  return {
    type: UsersActions.ADD_TO_SEARCH_MEMBER_HISTORY,
    payload,
  } as const;
}

export function addToSearchMemberHistory(memberId: number): AppThunk {
  return dispatch => {
    const oldHistory = JSON.parse(localData.fetch("sidebar.searchMember.history"));
    const newHistory =
      oldHistory.length > 0
        ? [memberId, ...oldHistory.filter((el: number) => el !== memberId)].slice(
            0,
            DEFAULT_SEARCH_MEMBER_HISTORY_COUNT,
          )
        : [memberId];

    localData.set("sidebar.searchMember.history", JSON.stringify(newHistory));
    dispatch(addToSearchMemberHistoryAction({ memberId }));
  };
}

function updateOfflineUsersOpenedStatusAction(payload: { openedWorkspaceIds: number[] }) {
  return {
    type: UsersActions.UPDATE_OFFLINE_USERS_OPENED_STATUS,
    payload,
  } as const;
}

export function updateOfflineUsersOpenedStatus(opened: boolean): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());

    if (currentWorkspace?.id) {
      const oldValues = JSON.parse(localData.fetch("sidebar.offlineUsers.isOpened.workspaceIds")) as number[];
      const newValues = opened
        ? [...oldValues.filter(el => el !== currentWorkspace.id), currentWorkspace.id]
        : oldValues.filter(el => el !== currentWorkspace.id);

      localData.set("sidebar.offlineUsers.isOpened.workspaceIds", JSON.stringify(newValues));
      dispatch(updateOfflineUsersOpenedStatusAction({ openedWorkspaceIds: newValues }));
    }
  };
}

export function changeBroadcastStatus(broadcastActive: boolean): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.voiceChannelId) {
      dispatch(
        sendMessage(USER_EVENT_TYPES.USER_BROADCAST_ACTIVE_REQUEST, {
          voiceChannelId: me.voiceChannelId,
          broadcastActive,
        }),
      );
    }
  };
}

export function toggleBroadcast(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me?.broadcastActive) {
      dispatch(showModal({ id: BROADCAST_START_MODAL_ID, show: true }));
    } else {
      dispatch(changeBroadcastStatus(false));
    }
  };
}

export function updateUserBroadcastActive({
  userId,
  voiceChannelId,
  broadcastActive,
  broadcastId,
}: {
  userId: number;
  voiceChannelId: number;
  broadcastActive: boolean;
  broadcastId: string;
}): AppThunk {
  return (dispatch, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const appFocusStatus = getAppFocusStatus(getState());
    const user = getOnlineUserById(userId)(getState());
    const me = getMyMember(getState());

    dispatch(updateUserBroadcastActiveAction({ userId, voiceChannelId, broadcastActive }));

    if (me?.id === userId) {
      if (!!me?.mute && broadcastActive) {
        dispatch(changeMicStatus(false));
      }

      if (broadcastActive) {
        Toast.success(
          intl.formatMessage(
            {
              id: "broadcast-floor/self-start-notification-message",
              defaultMessage: "Broadcast has been started",
            },
            {
              name: user?.name,
            },
          ),
        );
      } else {
        dispatch(changeMicStatus(true));
        dispatch(changeSelfVideoStatus(false));
        dispatch(doStopScreenShare());
        Toast.success(
          intl.formatMessage(
            {
              id: "broadcast-floor/self-stop-notification-message",
              defaultMessage: "Broadcast has been stopped",
            },
            {
              name: user?.name,
            },
          ),
        );
      }
    } else if (currentVoiceChannel?.id === voiceChannelId) {
      if (appFocusStatus) {
        if (broadcastActive) {
          Toast.success(
            intl.formatMessage(
              {
                id: "broadcast-floor/start-notification-message",
                defaultMessage: "{name} has started to broadcast.",
              },
              {
                name: user?.name,
              },
            ),
          );
        } else {
          Toast.success(
            intl.formatMessage(
              {
                id: "broadcast-floor/stop-notification-message",
                defaultMessage: "{name} has stopped to broadcast.",
              },
              {
                name: user?.name,
              },
            ),
          );
        }
      } else {
        if (broadcastActive) {
          dispatch(
            addNotificationOnPIP({
              notificationsType: "broadcast-active",
              data: [{ userId, voiceChannelId, broadcastActive, broadcastId }],
            }),
          );

          dispatch(showNotificationWindow());
        } else {
          dispatch(
            addNotificationOnPIP({
              notificationsType: "broadcast-active",
              data: [{ userId, voiceChannelId, broadcastActive, broadcastId }],
            }),
          );

          dispatch(showNotificationWindow());
        }
      }
    }
  };
}

function resetUserSelectedProjectAction(workspaceId: number, userId: number) {
  return {
    type: UsersActions.RESET_USER_SELECTED_PROJECT,
    payload: { workspaceId, userId },
  } as const;
}

export function resetUserSelectedProject(workspaceId: number, userId: number): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (userId === me?.id) {
      dispatch(updateAccountSelectedProjectAction(null));
    }

    dispatch(resetUserSelectedProjectAction(workspaceId, userId));
  };
}

export function syncCalendarMemoAction({ syncCalendar, userId }: { syncCalendar: boolean; userId: number }) {
  return {
    type: UsersActions.SYNC_CALENDAR_WORKING_MEMO,
    payload: { syncCalendar, userId },
  } as const;
}

export function syncCalendarMemo(syncCalendar: boolean): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    dispatch(syncCalendarMemoAction({ syncCalendar, userId: me!.id }));
    dispatch(updateManualWorkingMemoAction({ userId: me.id, workingMemo: syncCalendar ? me.workingMemo : null }));
    dispatch(
      sendMessage(USER_EVENT_TYPES.OUT_USER_SYNC_CALENDAR_WORKING_MEMO_REQUEST, {
        workspaceId: me!.workspaceId,
        syncCalendar,
      }),
    );
  };
}

function updateGoogleCalendarStatusAction(payload: {
  userId: number;
  workspaceId: number;
  googleStatus: MemberStatus;
}) {
  return {
    type: UsersActions.UPDATE_GOOGLE_CALENDAR_STATUS,
    payload,
  } as const;
}

function updateUserStatusAction(payload: UpdateStatus) {
  return { type: UsersActions.UPDATE_STATUS, payload };
}

export function updateUserStatus(payload: UpdateStatus): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me?.id === payload.userId && payload.workingMemo && me.workingMemo !== payload.workingMemo) {
      dispatch(actions.updateDescription(payload.workingMemo));
    }

    dispatch(updateUserStatusAction(payload));
  };
}

function updateOutlookCalendarStatusAction(payload: {
  userId: number;
  workspaceId: number;
  azureStatus: MemberStatus;
}) {
  return {
    type: UsersActions.UPDATE_OUTLOOK_CALENDAR_STATUS,
    payload,
  } as const;
}

export function captureScreenshot(payload: { enableScreenshot: boolean }): AppThunk {
  return async (dispatch, getState) => {
    const timeTrackerState = getTimeTrackerState(getState());

    if (timeTrackerState.working) {
      if (!payload.enableScreenshot) {
        await sendMessageOverIPC(channels.STOP_SCREENSHOT);
      } else {
        await sendMessageOverIPC(channels.START_SCREENSHOT, {
          takeScreenshot: payload.enableScreenshot,
        });
      }
    }
  };
}

export function cleanupAllSpeakerActiveAction(payload: { workspaceId: number }) {
  return {
    type: UsersActions.CLEANUP_ALL_SPEAKER_ACTIVE,
    payload,
  } as const;
}

export function resetBusyStatusToAvaiable(workspaceId: number): AppThunk {
  return dispatch => {
    dispatch(changeUserStatus({ workspaceId: workspaceId, status: "available", statusProvider: "" }));
  };
}
export function updateCalendarEventsAction(payload: { scheduleEvents?: ScheduleEvent[]; userId?: number }) {
  return {
    type: UsersActions.UPDATE_CALENDAR_EVENTS,
    payload,
  } as const;
}

export function refreshScheduleEventsAction(payload: { refreshScheduleEvents: boolean }) {
  return {
    type: UsersActions.REFRESH_CALENDAR_EVENTS,
    payload,
  } as const;
}

export function updatedGoogleIntegrationValue(payload: {
  workspaceId: number;
  userId: number;
  connectedGoogle: boolean;
}) {
  return {
    type: UsersActions.USER_GOOGLE_INTEGRATION_UPDATE,
    payload,
  } as const;
}

export function updatedAzureIntegrationValue(payload: {
  workspaceId: number;
  userId: number;
  connectedAzure: boolean;
}) {
  return {
    type: UsersActions.USER_AZURE_INTEGRATION_UPDATE,
    payload,
  } as const;
}

export function receivedLanguageInconsistencyAlert(alert: LanguageInconsistencyAlert): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (alert.userId === me?.id && alert.workspaceId === me?.workspaceId && !me?.mute) {
      dispatch(languageInconsistencyAlert(alert.detectedLanguage, alert.confidence));
    }
  };
}

export function languageInconsistencyAlert(detectedLanguage: string, confidence: number): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me?.enabledLanguageInconsistencyAlert) return;

    const alertLevel = getAlertLevel(confidence);

    if (alertLevel === "LOW") {
      return;
    }

    const currentWorkspaceLanguages = getCurrentWorkspaceLanguages(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const speechToTextSettings = getUserSpeechToTextSetting(getState());
    const userLanguage = getBaseLanguage(getState());

    const speakingLanguage =
      (speechToTextSettings && speechToTextSettings.speakingLanguage) ||
      (currentVoiceChannel && currentVoiceChannel.speechToTextLanguage) ||
      undefined;

    if (
      speakingLanguage &&
      !speakingLanguage.toLowerCase().includes(detectedLanguage.toLowerCase()) &&
      currentWorkspaceLanguages &&
      currentWorkspaceLanguages.length > 0
    ) {
      const targetLanguages = currentWorkspaceLanguages
        .map(languageOption =>
          languageOption.sttVariations[0].code.length === 1
            ? {
                code: languageOption.sttVariations[0].code,
                name: languageOption.label[userLanguage],
              }
            : languageOption.sttVariations.map(sttVariation => ({
                code: sttVariation.code,
                name: languageOption.label[userLanguage],
              })),
        )
        .reduce<{ code: string; name: string }[]>((acc, item) => {
          if (Array.isArray(item)) {
            return [...acc, ...item];
          } else {
            return [...acc, item];
          }
        }, []);

      const nextLanguage = detectedLanguage.includes("en")
        ? targetLanguages.find(la => la.code === "en-US")
        : targetLanguages.find(la => la.code.toLowerCase().includes(detectedLanguage.toLowerCase()));

      if (nextLanguage) {
        if (alertLevel === "MODERATE") {
          Toast.warning(
            intl.formatMessage(
              {
                id: "users/language-inconsistency-alert-warning",
                defaultMessage: "Did you select the correct language? You seem to be speaking in {language}.",
              },
              {
                language: nextLanguage.name,
              },
            ),
          );
        } else {
          Toast.success(
            intl.formatMessage(
              {
                id: "users/language-inconsistency-alert-success",
                defaultMessage:
                  "{language} was detected! The speaking language is automatically updated to {language}!",
              },
              {
                language: nextLanguage.name,
              },
            ),
          );
          dispatch(changeUserSpeakingLanguage(nextLanguage.code));
        }
      }
    }
  };
}

export function getAlertLevel(confidence: number): AlertLevel {
  if (confidence > 0.9) {
    return "HIGH";
  } else if (confidence > 0.8) {
    return "MODERATE";
  }

  return "LOW";
}

export function receivedLanguageInconsistencyAlertsVerbose(payload: any): AppThunk {
  return (dispatch, getState) => {
    console.log("LANGUAGE DETECTION LOGS: received data of the current voice channel: ", payload);
  };
}

export function updateUserHasMobileApp(payload: { workspaceId: number; userId: number; hasMobileApp: boolean }) {
  return {
    type: UsersActions.USER_HAS_MOBILE_APP_UPDATE,
    payload,
  } as const;
}

export function sendSignOut(userId: number): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.id === userId) {
      dispatch(doLogout());
    }
  };
}

export function receiveMakeMoveToFloor(userId: number, workspaceid: number, voiceChannelId: number): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.id === userId) {
      dispatch(channelJoin({ workspaceId: workspaceid, voiceChannelId: voiceChannelId }));
      Toast.success(
        intl.formatMessage({
          id: "meeting-room/remove-user-toast",
          defaultMessage: "You are removed from meeting room",
        }),
      );
    }
  };
}

export function updateLanguageInconsistencyAlert(payload: {
  userId: number;
  enabledLanguageInconsistencyAlert: boolean;
}) {
  return {
    type: UsersActions.UPDATE_LANGUAGE_INCONSISTENCY_ALERT,
    payload,
  } as const;
}

export function updatePersonalLanguageInconsistencyAlert(): AppThunk {
  return async (dispatch: Function, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());

    const enabledLanguageInconsistencyAlert = getEnabledLanguageInconsistencyAlert(getState());

    dispatch(
      sendMessage(USER_EVENT_TYPES.USER_PERSONAL_LANGUAGE_INCONSISTENCY_ALERT_REQUEST, {
        workspaceId: currentWorkspaceId,
        enabledLanguageInconsistencyAlert: !enabledLanguageInconsistencyAlert,
      }),
    );
  };
}

export function sendEventAppStartUpPreference(startup: boolean, workspaceId: number): AppThunk {
  return (dispatch: Function) => {
    dispatch(sendMessage(USER_EVENT_TYPES.USER_APP_START_UP_PREFERENCE, { startup, workspaceId }));
  };
}

export function setWorkspaceListeners(payload: ListenerData[]) {
  return { type: UsersActions.SET_WORKSPACE_LISTENERS, payload } as const;
}

export function addListener(payload: ListenerData) {
  return { type: UsersActions.ADD_LISTENER, payload } as const;
}

export function updateListener(payload: ListenerData) {
  return { type: UsersActions.UPDATE_LISTENER, payload } as const;
}

export function removeListener(payload: { userId: number }) {
  return { type: UsersActions.REMOVE_LISTENER, payload } as const;
}

export function removeListenersFromVoiceChannel(payload: { voiceChannelId: number; joinShortId?: string }) {
  return {
    type: UsersActions.REMOVE_LISTENERS_FROM_VOICE_CHANNEL,
    payload,
  } as const;
}

export function removeListenersFromSharing(payload: { myShortId: string }) {
  return {
    type: UsersActions.REMOVE_LISTENERS_FROM_SHARING,
    payload,
  } as const;
}

export function setListenerMediaStream(listenerMediaStream: MediaStream | undefined) {
  return {
    type: UsersActions.SET_LISTENER_MEDIA_STREAM,
    payload: { listenerMediaStream },
  } as const;
}

export function updateUserAudioExtraction(payload: { isExtractingAudio: boolean; userId: number }) {
  return { type: UsersActions.UPDATE_AUDIO_EXTRACTION, payload } as const;
}

export function updateUserWebFCMTokenAction(payload: { webDeviceToken: string | null; userId: number }) {
  return { type: UsersActions.UPDATE_WEB_FCM_TOKEN, payload } as const;
}

export function sendEventWebNotificationPermission(allowed: boolean, workspaceId?: number): AppThunk {
  return (dispatch: Function) => {
    dispatch(sendMessage(USER_EVENT_TYPES.USER_WEB_NOTIFICATION_PERMISSION_CHANGE, { allowed, workspaceId }));
  };
}

function setEnableTextChatMic(enableMic: boolean) {
  return {
    type: UsersActions.SET_ENABLE_TEXT_CHAT_MIC,
    payload: { enableMic },
  } as const;
}

export function toggleTextChatMic(): AppThunk {
  return (dispatch, getState) => {
    const enableMic = getEnableTextChatMic(getState());

    dispatch(enableTextChatMic(!enableMic));
  };
}

export function enableTextChatMic(enable: boolean): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!enable && !me?.mute) {
      dispatch(changeMicStatus(true));
    }

    dispatch(setEnableTextChatMic(enable));
  };
}
