import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RawIntlProvider } from "react-intl";

import "./App.scss";
import { AppLoader } from "./components/containers";
import { AppRouter } from "./routes";
import { hydrate, loadDesktopAppTitle, processWebNotifications, setInitialAccessChannelId } from "./store/app";
import { useLanguage } from "./utils/hooks/useLanguage";
import { ErrorBoundary } from "react-error-boundary";
import AppErrors from "./screens/AppErrors";
import { hydrateFloorIdleAutoMicMuteStatus } from "./store/users";
import { CHANNEL_EVENT_TYPES } from "./store/voiceChannels/actions-enum";
import { addWebPushNotification } from "./store/account";
import { TEXT_CHANNEL_EVENT_TYPES } from "./store/textChannel";
import { getSocketConnectivityStatus } from "./store/socket";
import { getCurrentWorkspace } from "./store/workspace";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hydrate());
    dispatch(loadDesktopAppTitle());
    dispatch(hydrateFloorIdleAutoMicMuteStatus());
    dispatch(setInitialAccessChannelId());
  }, [dispatch]);

  const socketConnected = useSelector(getSocketConnectivityStatus);
  const currentWorkspace = useSelector(getCurrentWorkspace);

  const intl = useLanguage();

  const onMessageReceive = React.useCallback(
    (message: any) => {
      if (message.data.type === "NOTIFICATION_CLICK") {
        const { payload, type } = message.data.data;
        const paths = window.location?.pathname.split("/");

        if (type === CHANNEL_EVENT_TYPES.CHANNEL_INVITE || type === TEXT_CHANNEL_EVENT_TYPES.NEW_MESSAGE_RESPONSE) {
          if (socketConnected && currentWorkspace?.id === payload.workspaceId) {
            dispatch(processWebNotifications({ payload, type }));
          } else if (paths[1] === "dashboard" && paths[2]) {
            dispatch(addWebPushNotification(payload, type));
          }
        }
      }
    },
    [currentWorkspace?.id, dispatch, socketConnected],
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", onMessageReceive);

      return () => {
        navigator?.serviceWorker?.removeEventListener("message", onMessageReceive);
      };
    }
  }, [onMessageReceive]);

  return (
    <RawIntlProvider value={intl}>
      <ErrorBoundary FallbackComponent={AppErrors}>
        <AppRouter />
        <AppLoader />
      </ErrorBoundary>
    </RawIntlProvider>
  );
}

export default App;
