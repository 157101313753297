import { channels } from "../../electron/channels";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import {
  APPROVE_REMOTE_DESKTOP_CONTROL_MODAL_ID,
  DELCINED_REMOTE_DESKTOP_CONTROL_MODAL_ID,
} from "../../screens/Dashboard/constants";
import { showModal } from "../../screens/Dashboard/state";
import { getDeviceInfo } from "../../utils/helpers";
import { getPermissionStateAccessibility, loadPermission, showRequiredAccessiblityPermissionModal } from "../app";
import { addNotificationOnPIP, removeNotificationOnPIP } from "../notificationsWindow";
import { sendMessage } from "../socket";
import { AppThunk } from "../types";
import { setremoteScreenShareToFullScreen } from "../virtualOffice/actions";
import { getScreenShareObjects } from "../virtualOffice/selector";
import { getCurrentWorkspaceId } from "../workspace";
import { EVENT_TYPES } from "./middleware";
import { getMyDesktopControlDetails, getMySkyWayPeerId, getRemoteDesktopControlDetails } from "./selectors";
const { os } = getDeviceInfo();

export enum RemoteDesktopControlActions {
  SET_MY_DESKTOP_CONTROL = "remote-desktop-control/SET_MY_DESKTOP_CONTROL",
  UNSET_MY_DESKTOP_CONTROL = "remote-desktop-control/UNSET_MY_DESKTOP_CONTROL",
  SET_REMOTE_DESKTOP_CONTROL = "remote-desktop-control/SET_REMOTE_DESKTOP_CONTROL",
  UNSET_REMOTE_DESKTOP_CONTROL = "remote-desktop-control/UNSET_REMOTE_DESKTOP_CONTROL",
  CONNECTION_STATUS_UPDATED_WITH_SKYWAY = "remote-desktop-control/CONNECTION_STATUS_UPDATED_WITH_SKYWAY",
}

export type RemoteDesktopControlActionType =
  | ReturnType<typeof setMydesktopControlDetailsAction>
  | ReturnType<typeof unsetMydesktopControlDetails>
  | ReturnType<typeof setRemoteDesktopControlDetails>
  | ReturnType<typeof unsetRemoteDesktopControlDetails>
  | ReturnType<typeof connectionStatusUpdatedWithSkyWayAction>;

export interface ScreenSize {
  width: number;
  height: number;
}

export interface RequestResponseData {
  requestFromUserId: number;
  requestToUserId: number;
  requestFromName?: string;
  requestToName?: string;
  remoteUserSkyWayPeerId?: string;
  remoteUserScreenSize?: ScreenSize;
}

export interface DeclineResponseData extends RequestResponseData {
  isAlreadyControlledByAnotherUser?: boolean;
}

export interface MoveRemoteDesktopMousePayload {
  userId: number;
  position: {
    x: number;
    y: number;
  };
}

export interface ToggleRemoteDesktopMousePayload {
  userId: number;
  buttonType: "left" | "right";
  toggleType: "up" | "down";
}

export function requestRemotDesktopControl({
  requestFromName,
  requestFromUserId,
  requestToName,
  requestToUserId,
}: RequestResponseData): AppThunk {
  return (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(EVENT_TYPES.OUT_REQUEST_REMOTE_DESKTOP_CONTROL, {
        workspaceId,
        requestFromName,
        requestFromUserId,
        requestToName,
        requestToUserId,
      }),
    );
  };
}

export function requestRemoteDesktopControlResponse({
  requestFromName,
  requestFromUserId,
  requestToName,
  requestToUserId,
}: RequestResponseData): AppThunk {
  return (dispatch, getState) => {
    const myDesktopControlDetails = getMyDesktopControlDetails(getState());

    if (myDesktopControlDetails?.remoteUserId && myDesktopControlDetails?.remoteUserId !== requestFromUserId) {
      dispatch(
        declinedRemoteDesktopControlRequest({
          requestFromUserId: requestToUserId,
          requestToUserId: requestFromUserId,
          requestFromName: requestToName,
          requestToName: requestFromName,
          isAlreadyControlledByAnotherUser: true,
        }),
      );

      return;
    }

    dispatch(
      showModal({
        id: APPROVE_REMOTE_DESKTOP_CONTROL_MODAL_ID,
        show: true,
        data: {
          requestFromName,
          requestFromUserId,
          requestToName,
          requestToUserId,
        },
      }),
    );
    dispatch(
      addNotificationOnPIP({
        notificationsType: "remote-access-request",
        data: { userId: requestFromUserId, userName: requestFromName },
        timestamp: 15,
        isAutoRemovable: true,
      }),
    );
  };
}

export function approvedRemoteDesktopControlRequest({
  requestFromName,
  requestFromUserId,
  requestToName,
  requestToUserId,
}: RequestResponseData): AppThunk {
  return async (dispatch: Function, getState) => {
    const device = getDeviceInfo();

    const myScreenSize = {
      width: device.os === "macos" ? window.screen.width : window.screen.width * window.devicePixelRatio,
      height: device.os === "macos" ? window.screen.height : window.screen.height * window.devicePixelRatio,
    };

    await dispatch(loadPermission("accessibility"));
    const accessibilityGranted = getPermissionStateAccessibility(getState());

    if (!accessibilityGranted && os === "macos") {
      dispatch(showRequiredAccessiblityPermissionModal());
      return;
    }

    const mySkyWayPeerId = getMySkyWayPeerId(getState()) ?? "";
    const workspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(EVENT_TYPES.OUT_REQUEST_APPROVED_REMOTE_DESKTOP_CONTROL, {
        workspaceId,
        requestFromName,
        requestFromUserId,
        requestToName,
        requestToUserId,
        mySkyWayPeerId,
        myScreenSize,
      }),
    );

    dispatch(setMydesktopControlDetails(requestToUserId, requestToName));

    dispatch(removeNotificationOnPIP({ type: "remote-access-request" }));
  };
}

export function approvedRemoteDesktopControlResponse({
  requestFromName,
  requestFromUserId,
  remoteUserSkyWayPeerId,
  remoteUserScreenSize,
}: RequestResponseData): AppThunk {
  return (dispatch, getState) => {
    const screenShareObjects = getScreenShareObjects(getState());

    const remoteUserScreenShareWindow = screenShareObjects?.find(el => el.ownerUserId === requestFromUserId);

    dispatch(
      setRemoteDesktopControlDetails(requestFromUserId, requestFromName, remoteUserSkyWayPeerId, remoteUserScreenSize),
    );

    sendMessageOverIPC(channels.MAXIMIZE_WINDOW);

    if (remoteUserScreenShareWindow) {
      dispatch(setremoteScreenShareToFullScreen(remoteUserScreenShareWindow));
    }
  };
}

export function declinedRemoteDesktopControlRequest({
  requestFromName,
  requestFromUserId,
  requestToName,
  requestToUserId,
  isAlreadyControlledByAnotherUser,
}: DeclineResponseData): AppThunk {
  return (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(EVENT_TYPES.OUT_REQUEST_DECLINE_REMOTE_DESKTOP_CONTROL, {
        workspaceId,
        requestFromName,
        requestFromUserId,
        requestToName,
        requestToUserId,
        isAlreadyControlledByAnotherUser,
      }),
    );

    dispatch(removeNotificationOnPIP({ type: "remote-access-request" }));
  };
}

export function declinedRemoteDesktopControlResponse(isAlreadyControlledByAnotherUser?: boolean): AppThunk {
  return dispatch => {
    dispatch(
      showModal({
        id: DELCINED_REMOTE_DESKTOP_CONTROL_MODAL_ID,
        show: true,
        data: { isAlreadyControlledByAnotherUser: !!isAlreadyControlledByAnotherUser },
      }),
    );
  };
}

export function setMydesktopControlDetailsAction(userId: number, userName: string | undefined) {
  return {
    type: RemoteDesktopControlActions.SET_MY_DESKTOP_CONTROL,
    payload: {
      remoteUserId: userId,
      remoteUserName: userName,
    },
  } as const;
}

export function setMydesktopControlDetails(userId: number, userName: string | undefined): AppThunk {
  return dispatch => {
    dispatch(showStopRemoteControlWindow());
    dispatch(setMydesktopControlDetailsAction(userId, userName));
  };
}

export function setRemoteDesktopControlDetails(
  userId: number,
  userName: string | undefined,
  remoteUserSkyWayPeerId: string | undefined,
  remoteUserScreenSize: ScreenSize | undefined,
) {
  return {
    type: RemoteDesktopControlActions.SET_REMOTE_DESKTOP_CONTROL,
    payload: {
      remoteUserId: userId,
      remoteUserName: userName,
      remoteUserSkyWayPeerId,
      remoteUserScreenSize,
    },
  } as const;
}

export function unsetMydesktopControlDetails() {
  return {
    type: RemoteDesktopControlActions.UNSET_MY_DESKTOP_CONTROL,
  } as const;
}

export function unsetRemoteDesktopControlDetails() {
  return {
    type: RemoteDesktopControlActions.UNSET_REMOTE_DESKTOP_CONTROL,
  } as const;
}

export function stopControllingMyDesktopRequest(): AppThunk {
  return (dispatch, getState) => {
    const myDesktopControlDetails = getMyDesktopControlDetails(getState());
    const workspaceId = getCurrentWorkspaceId(getState());

    if (!myDesktopControlDetails) return;

    dispatch(unsetMydesktopControlDetails());
    dispatch(hideStopRemoteControlWindow());
    dispatch(
      sendMessage(EVENT_TYPES.OUT_STOP_MY_DESKTOP_CONTROL, {
        userId: myDesktopControlDetails.remoteUserId,
        workspaceId,
      }),
    );
  };
}

export function stopControllingRemoteDesktopRequest(): AppThunk {
  return (dispatch, getState) => {
    const remoteDesktopControlDetails = getRemoteDesktopControlDetails(getState());
    const workspaceId = getCurrentWorkspaceId(getState());

    if (!remoteDesktopControlDetails) return;

    dispatch(unsetRemoteDesktopControlDetails());
    dispatch(hideStopRemoteControlWindow());

    dispatch(
      sendMessage(EVENT_TYPES.OUT_STOP_REMOTE_DESKTOP_CONTROL, {
        userId: remoteDesktopControlDetails.remoteUserId,
        workspaceId,
      }),
    );
  };
}

export function stopControllingMyDesktopResponse(): AppThunk {
  return dispatch => {
    dispatch(unsetRemoteDesktopControlDetails());
    dispatch(hideStopRemoteControlWindow());
  };
}

export function stopControllingRemoteDesktopResponse(): AppThunk {
  return dispatch => {
    dispatch(hideStopRemoteControlWindow());
    dispatch(unsetMydesktopControlDetails());
  };
}

export function showStopRemoteControlWindow(): AppThunk {
  return () => {
    sendMessageOverIPC(channels.SHOW_STOP_REMOTE_CONTROL_WINDOW);
  };
}

export function hideStopRemoteControlWindow(): AppThunk {
  return () => {
    sendMessageOverIPC(channels.HIDE_STOP_REMOTE_CONTROL_WINDOW);
  };
}

export function connectionStatusUpdatedWithSkyWayAction(connected: boolean, id: string | undefined) {
  return {
    type: RemoteDesktopControlActions.CONNECTION_STATUS_UPDATED_WITH_SKYWAY,
    payload: {
      connected,
      id,
    },
  } as const;
}

export function remoteUserDisconnected(userId: number): AppThunk {
  return (dispatch, getState) => {
    const myDesktopControlDetails = getMyDesktopControlDetails(getState());
    const remoteDesktopControlDetails = getRemoteDesktopControlDetails(getState());

    if (!myDesktopControlDetails && !remoteDesktopControlDetails) return;

    if (remoteDesktopControlDetails?.isControlledByRemoteUser && remoteDesktopControlDetails?.remoteUserId === userId) {
      dispatch(unsetRemoteDesktopControlDetails());
      dispatch(hideStopRemoteControlWindow());
    }

    if (myDesktopControlDetails?.isControlledByRemoteUser && myDesktopControlDetails?.remoteUserId === userId) {
      dispatch(unsetRemoteDesktopControlDetails());
      dispatch(unsetMydesktopControlDetails());
      dispatch(hideStopRemoteControlWindow());
    }
  };
}
