import { RegionsActions, RegionsActionTypes } from "./actions";
import { Region } from "../types";

export const regionsInitialState = [] as Region[];
export type RegionsState = typeof regionsInitialState;

export const regionsReducer = (state: RegionsState = regionsInitialState, action: RegionsActionTypes): RegionsState => {
  switch (action.type) {
    case RegionsActions.GOT_REGIONS:
      return action.payload.regions as Region[];
    default: {
      return state;
    }
  }
};
