import { updateUserAvatarPosition } from "../users/actions";
import {
  createObjectBroadcast,
  deleteObjectBroadcast,
  listObjectsResponse,
  recoverObjectBroadcast,
  removeCursor,
  responseToEnableDrawing,
  updateCursorPosition,
  updateObjectPropertiesBroadcast,
  updateObjectWithDeltaBroadcast,
  updateScreenOverlayDrawingStatusResponse,
} from "./actions";

const EVENT_TYPES = {
  // Outgoing events we send
  OUT_AVATAR_MOVE_REQUEST: "virtualOffice/avatar-move-request",
  OUT_OBJECT_CREATE_REQUEST: "virtualOffice/object-create-request",
  OUT_OBJECT_UPDATE_REQUEST: "virtualOffice/object-update-request",
  OUT_OBJECT_UPDATE_DELTA_REQUEST: "virtualOffice/object-update-delta-request",
  OUT_OBJECT_DELETE_REQUEST: "virtualOffice/object-delete-request",
  OUT_UPDATE_SCREEN_OVERLAY_DRAWING_STATUS: "virtualOffice/update-screen-overlay-drawing-status-request",
  OUT_OBJECT_RECOVER_REQUEST: "virtualOffice/object-recover-request",
  OUT_ENABLE_SCREEN_DRAWING_REQUEST: "virtualOffice/enable-screen-drawing-request",
  OUT_OBJECT_CURSOR_MOVE_REQUEST: "virtualOffice/object-cursor-move-request",
  OUT_OBJECT_CURSOR_REMOVE_REQUEST: "virtualOffice/object-cursor-remove-request",

  // Incoming events we listen
  IN_AVATAR_MOVE_BROADCAST: "virtualOffice/avatar-move-broadcast",
  IN_OBJECT_CREATE_BROADCAST: "virtualOffice/object-create-broadcast",
  IN_OBJECT_UPDATE_BROADCAST: "virtualOffice/object-update-broadcast",
  IN_OBJECT_UPDATE_DELTA_BROADCAST: "virtualOffice/object-update-delta-broadcast",
  IN_OBJECT_DELETE_BROADCAST: "virtualOffice/object-delete-broadcast",
  IN_LIST_OBJECTS_RESPONSE: "virtualOffices/list-objects-response",
  IN_UPDATE_SCREEN_OVERLAY_DRAWING_STATUS: "virtualOffice/update-screen-overlay-drawing-status-response",
  IN_OBJECT_RECOVER_BROADCAST: "virtualOffice/object-recover-broadcast",
  IN_ENABLE_SCREEN_DRAWING_RESPONSE: "virtualOffice/enable-screen-drawing-response",
  IN_OBJECT_CURSOR_MOVE_RESPONSE: "virtualOffice/object-cursor-move-response",
  IN_OBJECT_CURSOR_REMOVE_RESPONSE: "virtualOffice/object-cursor-remove-response",
};

const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  switch (type) {
    case EVENT_TYPES.IN_AVATAR_MOVE_BROADCAST:
      store.dispatch(
        updateUserAvatarPosition({
          workspaceId: payload.workspaceId,
          userId: payload.userId,
          position: payload.position,
        }),
      );
      break;

    case EVENT_TYPES.IN_OBJECT_CREATE_BROADCAST:
      store.dispatch(createObjectBroadcast(payload.object));
      break;

    case EVENT_TYPES.IN_OBJECT_UPDATE_BROADCAST:
      store.dispatch(updateObjectPropertiesBroadcast(payload.object));
      break;

    case EVENT_TYPES.IN_OBJECT_UPDATE_DELTA_BROADCAST:
      store.dispatch(updateObjectWithDeltaBroadcast(payload.objectId, payload.delta));
      break;

    case EVENT_TYPES.IN_OBJECT_DELETE_BROADCAST:
      store.dispatch(deleteObjectBroadcast(payload.objectId, payload.userId));
      break;
    case EVENT_TYPES.IN_OBJECT_RECOVER_BROADCAST:
      store.dispatch(recoverObjectBroadcast(payload.object, payload.userId));
      break;
    case EVENT_TYPES.IN_LIST_OBJECTS_RESPONSE:
      store.dispatch(listObjectsResponse(payload.objects));
      break;
    case EVENT_TYPES.IN_UPDATE_SCREEN_OVERLAY_DRAWING_STATUS:
      store.dispatch(
        updateScreenOverlayDrawingStatusResponse({
          userId: payload.userId,
          isDrawingAllowed: payload.isDrawingAllowed,
        }),
      );
      break;
    case EVENT_TYPES.IN_ENABLE_SCREEN_DRAWING_RESPONSE:
      store.dispatch(responseToEnableDrawing());
      break;
    case EVENT_TYPES.IN_OBJECT_CURSOR_MOVE_RESPONSE:
      store.dispatch(updateCursorPosition(payload.userId, payload.userName, payload.objectId, payload.position));
      break;
    case EVENT_TYPES.IN_OBJECT_CURSOR_REMOVE_RESPONSE:
      store.dispatch(removeCursor(payload.userId));
      break;
  }
};

export { EVENT_TYPES as VIRTUAL_OFFICE_EVENT_TYPES, handleIncomingMessage as handleIncomingVirtualOfficeMessage };
