import { combineReducers } from "redux";

import { State } from "./state";
import { appReducer } from "./app/reducer";
import { appNotificationReducer } from "./appNotification/reducer";
import { backgroundChecksReducer } from "./backgroundChecks/reducers";
import { demoWorkspaceReducer } from "./demoWorkspace";
import { versionReducer } from "./version";
import { accountReducer } from "./account/reducer";
import { socketReducer } from "./socket";
import { workspacesReducer } from "./workspace";
import { channelsReducer } from "./voiceChannels";
import { textChannelReducer } from "./textChannel";
import { usersReducer } from "./users";
import { dashboardReducer } from "../screens/Dashboard/state";
import { timeTrackerReducer } from "./timeTracker/reducer";
import { resetPasswordReducer } from "./resetPassword";
import { invitationJoinReducer } from "./invitationJoin";
import { shareReducer } from "./screenshare";
import { regionsReducer } from "./region/reducer";
import { virtualOfficeReducer } from "./virtualOffice/reducer";
import { devicesReducer } from "./devices/reducer";
import { NotificationsWindowReducer } from "./notificationsWindow";
import { workspaceBackgroundReducer } from "./workspaceBackground/reducer";
import { memberReducer } from "./member";
import { meetinRecordingReducer } from "./MeetingRecording";
import { remoteDesktopControlReducer } from "./remoteDesktopControl";
import { workChannelReducer } from "./workChannel";
import { livekitReducer } from "./livekit";
import { audioExtractionReducer } from "./audioExtraction";
import { paymentReducer } from "./payment/reducer";

export const rootReducers = combineReducers<State>({
  app: appReducer,
  devices: devicesReducer,
  appNotification: appNotificationReducer,
  backgroundChecks: backgroundChecksReducer,
  demoWorkspace: demoWorkspaceReducer,
  version: versionReducer,
  account: accountReducer,
  users: usersReducer,
  socket: socketReducer,
  workspaces: workspacesReducer,
  voiceChannels: channelsReducer,
  textChannel: textChannelReducer,
  regions: regionsReducer,
  dashboard: dashboardReducer,
  screenshare: shareReducer,
  timeTracker: timeTrackerReducer,
  resetPassword: resetPasswordReducer,
  invitationJoin: invitationJoinReducer,
  virtualOffice: virtualOfficeReducer,
  notificationsWindow: NotificationsWindowReducer,
  member: memberReducer,
  workspaceBackground: workspaceBackgroundReducer,
  meetingRecordings: meetinRecordingReducer,
  remoteDesktopControl: remoteDesktopControlReducer,
  workChannel: workChannelReducer,
  livekit: livekitReducer,
  audioExtraction: audioExtractionReducer,
  payment: paymentReducer,
});
