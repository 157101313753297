import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { makeIntl } from "../../i18n";
import { getLanguage, isAuthenticated } from "../../store/account/selectors";
import { getDisplayLanguage } from "../helpers";

/** Create the intl object, and make sure everything off-tree that depends on
 * it gets updated if the language changes.
 * This is meant to be called only once per layout, at the top. For getting
 * the intl object in an individual component, use `useIntl` from react-intl. */
export function useLanguage() {
  let language = useSelector(getLanguage);
  const authenticated = useSelector(isAuthenticated);

  if (!authenticated) {
    language = getDisplayLanguage();
  }

  return useMemo(() => {
    if (language === "en") {
      // Using British to have Monday as the first week day
      // Changes here require changes on both these files:
      //  - dayjs-setup.js
      //  - DatePicker.tsx
      dayjs.locale("en-gb");
    } else if (language === "ja") {
      dayjs.locale("ja");
    } else if (language === "vi") {
      dayjs.locale("vi");
    } else if (language === "ko") {
      dayjs.locale("ko");
    } else {
      throw new Error("Language not supported.");
    }

    document.documentElement.lang = language;
    return makeIntl(language);
  }, [language]);
}
