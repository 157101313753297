import { LISTENER_MODE_QUERY_NAME, LISTENER_MODE_QUERY_TYPE } from "../constant";

export const APP_SERVICE_UNAVAILABLE_ROUTE = "/app/service-unavailable";
export const APP_NO_PERMISSION_ROUTE = "/app/no-permission";

export const USER_SIGNUP_ROUTE_PATH = "/user/signup";
export const USER_SIGNUP_STEP2_ROUTE_PATH = `${USER_SIGNUP_ROUTE_PATH}/step2`;
export const USER_SIGNUP_ROUTE = `${USER_SIGNUP_ROUTE_PATH}/:token?`;
export const USER_SIGNUP_MOBILE_PATH = "/user/mobile/signup";
export const USER_SIGNUP_MOBILE_ROUTE = `${USER_SIGNUP_MOBILE_PATH}/:token?`;
export const USER_MOBILE_INSTALLATION_ROUTE = `/mobile/install`;

export const USER_LOGIN_CHECK_ROUTE = "/user/check-login";
export const USER_LOGIN_ROUTE_PATH = "/user/login";
export const USER_LOGIN_ROUTE = `${USER_LOGIN_ROUTE_PATH}/:token?`;
export const USER_LOGIN_DESKTOP_ROUTE = "/user/login-desktop";
export const USER_JOIN_WORKSPACE_ROUTE = "/user/join/:token";
export const USER_CONFIRM_EMAIL_ROUTE = "/user/confirm-email/:token/(create-workspace|join-workspace)/";
export const USER_ACCOUNT_DELETED_ROUTE = "/user/account-deleted";
export const USER_RESET_PASSWORD_REQUEST_ROUTE = "/user/reset-password-request";
export const USER_RESET_PASSWORD_CHECK_MAIL_ROUTE = "/user/reset-password-check-mail";
export const USER_RESET_PASSWORD_TOKEN_ROUTE = "/user/reset-password/:token";
export const SIGNING_OUT_ROUTE = "/user/signing-out";
export const USER_EMAIL_VERIFICATION_PATH = "/user/email-verify";
export const USER_EMAIL_VERIFICATION = `${USER_EMAIL_VERIFICATION_PATH}/:device?`;
export const WORKSPACE_REFERRAL_ROUTE = "/referral/:token";

export const SLACK_OAUTH_ROUTE = "/slack/oauth";
export const GOOGLE_OAUTH_ROUTE = "/google/oauth";
export const CHATWORK_OAUTH_ROUTE = "/chatwork/oauth";
export const AZURE_OAUTH_ROUTE = "/azure/oauth";

export const WORKSPACE_BASE_ROUTE = "/dashboard";
export const WORKSPACE_NAMED_ROUTE = `${WORKSPACE_BASE_ROUTE}/:workspaceShortId?/:channelShortId?`;
export const WORKSPACE_CONFIRM_EMAIL_CHANGE_ROUTE = "/workspace/confirm-email-change/:token";
export const WORKSPACE_CREATE_ROUTE = "/workspace/create";
export const WORKSPACE_DELETED_PATH = "/workspace/deleted";
export const WORKSPACE_DELETED_ROUTE = `${WORKSPACE_DELETED_PATH}/:workspaceName?`;
export const WORKSPACE_LEFT_ROUTE = "/workspace/left/:workspaceName?";

export const REPORTS_BASE_ROUTE = "/reports";
export const generateReportsBaseRouteByWorkspaceId = (workspaceId: string) => `${REPORTS_BASE_ROUTE}/${workspaceId}`;
export const REPORTS_BASE_ROUTE_BY_WORKSPACE_ID = generateReportsBaseRouteByWorkspaceId(":workspaceId?");
export const WORKING_LOG = "working-log";
export const generateWorkingLogDailyPathWithParams = (
  workspaceId: string,
  date: string,
  userId?: string,
  projectId?: string,
) => `${REPORTS_BASE_ROUTE}/${workspaceId}/${WORKING_LOG}-daily/${date}/${userId}/${projectId}`;

export const REPORTS_WORKING_LOGS_DAILY_ROUTE = generateWorkingLogDailyPathWithParams(
  ":workspaceId",
  ":date",
  ":userId?",
  ":projectId?",
);

export const generateWorkingLogWeeklyPathWithParams = (workspaceId: string, date: string, projectId?: string) => {
  return projectId
    ? `${REPORTS_BASE_ROUTE}/${workspaceId}/${WORKING_LOG}-weekly/${date}/${projectId}`
    : `${REPORTS_BASE_ROUTE}/${workspaceId}/${WORKING_LOG}-weekly/${date}`;
};

export const REPORTS_WORKING_LOGS_WEEKLY_ROUTE = generateWorkingLogWeeklyPathWithParams(
  ":workspaceId",
  ":date",
  ":projectId?",
);

export const generateWorkingLogExportPathWithParams = (
  workspaceId: string,
  startDate: string,
  endDate: string,
  userId?: string,
  projectId?: string,
) => `${REPORTS_BASE_ROUTE}/${workspaceId}/${WORKING_LOG}-export/${startDate}/${endDate}/${userId}/${projectId}`;

export const REPORTS_WORKING_LOGS_EXPORT_ROUTE = generateWorkingLogExportPathWithParams(
  ":workspaceId",
  ":startDate",
  ":endDate",
  ":userIds",
  ":projectIds",
);

export const generateWorkingLogManualTimeLogPathWithParams = (
  workspaceId: string,
  startDate: string,
  endDate: string,
) => `${REPORTS_BASE_ROUTE}/${workspaceId}/${WORKING_LOG}-manual-time-log/${startDate}/${endDate}`;

export const REPORTS_WORKING_LOGS_MANUAL_LOG_ROUTE = generateWorkingLogManualTimeLogPathWithParams(
  ":workspaceId",
  ":startDate",
  ":endDate",
);
export const INVOICES = "invoices";

export const generateInvoicePath = (workspaceId: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${WORKING_LOG}-invoices`;

export const REPORTS_INVOICES_ROUTE = generateInvoicePath(":workspaceId");
export const REPORTS_INVOICES_CREATE_ROUTE = `${generateInvoicePath(":workspaceId")}/create`;
export const REPORTS_INVOICES_EDIT_ROUTE = `${generateInvoicePath(":workspaceId")}/edit`;

export const ONLINE_LOGS = "online-logs";
export const REPORTS_ONLINE_LOGS_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${ONLINE_LOGS}`;

export const generateOnlineLogPathWithParams = (workspaceId: string, type: string, date: string, userId?: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${ONLINE_LOGS}/${type}/${date}/${userId}`;
export const REPORTS_ONLINE_LOGS_ROUTE = generateOnlineLogPathWithParams(":workspaceId", ":type", ":date", ":userId?");

export const generateOnlineLogExportPathWithParams = (
  workspaceId: string,
  type: string,
  startDate: string,
  endDate: string,
  userId?: string,
  guestUserId?: string,
) => `${REPORTS_BASE_ROUTE}/${workspaceId}/${ONLINE_LOGS}/${type}/${startDate}/${endDate}/${userId}/${guestUserId}`;

export const REPORTS_ONLINE_LOGS_EXPORT_ROUTE = generateOnlineLogExportPathWithParams(
  ":workspaceId",
  ":type",
  ":startDate",
  ":endDate",
  ":userIds",
  ":guestUserIds",
);
export const MEETING_LOGS = "meeting-logs";
export const MEETING_RECORDINGS = "meeting-recordings";
export const AUDIO_EXTRACTIONS = "live-translations";
export const TRANSCRIPT_LIBRARY = "transcript-library";
export const REPORTS_MEETING_LOGS_BASE_PATH = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MEETING_LOGS}`;
export const generateMeetingLogWithParams = (workspaceId: string, type: string, roomId: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${MEETING_LOGS}/${type}/${roomId}`;
export const generateTranscriptContentWithParams = (workspaceId: string, roomId: string, type: string, id: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${MEETING_LOGS}/${type}/${roomId}/${id}`;

export const REPORTS_TRANSCRIPTS_CONTENT_ROUTE = generateTranscriptContentWithParams(
  ":workspaceId?",
  ":roomId?",
  ":type?",
  ":id?",
);

export const MANAGE_TEAM = "manage-team";

export const MANAGE_TEAM_MEMBER = `${MANAGE_TEAM}/member`;
export const REPORTS_MANAGE_TEAM_MEMBER_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MANAGE_TEAM_MEMBER}`;
export const generateManageTeamMemberWithParam = (workspaceId: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${MANAGE_TEAM_MEMBER}`;

export const MANAGE_TEAM_PROJECT = `${MANAGE_TEAM}/project`;
export const REPORTS_MANAGE_TEAM_PROJECT_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MANAGE_TEAM_PROJECT}`;
export const generateManageTeamProjectWithParam = (workspaceId: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${MANAGE_TEAM_PROJECT}`;

export const MANAGE_TEAM_GROUP = `${MANAGE_TEAM}/group`;
export const REPORTS_MANAGE_TEAM_GROUP_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MANAGE_TEAM_GROUP}`;
export const generateManageTeamGroupWithParam = (workspaceId: string) =>
  `${REPORTS_BASE_ROUTE}/${workspaceId}/${MANAGE_TEAM_GROUP}`;

export const MANAGE_TEAM_TIMETRACKING_SETTING = `${MANAGE_TEAM}/timetracking`;
export const REPORTS_MANAGE_TEAM_TIMETRACKING_SETTING_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MANAGE_TEAM_TIMETRACKING_SETTING}`;
export const generateManageTeamTimetrackingWithParam = (workspaceId: string) => {
  return `${REPORTS_BASE_ROUTE}/${workspaceId}/${MANAGE_TEAM_TIMETRACKING_SETTING}`;
};

export const MANAGE_TEAM_IP_WHITE_LIST = `${MANAGE_TEAM}/ipwhitelist`;
export const REPORTS_MANAGE_TEAM_IP_WHITE_LISTBASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MANAGE_TEAM_IP_WHITE_LIST}`;
export const generateManageTeamIpWhiteListWithParam = (workspaceId: string) => {
  return `${REPORTS_BASE_ROUTE}/${workspaceId}/${MANAGE_TEAM_IP_WHITE_LIST}`;
};

export const MANAGE_TEAM_ENTERPRISE_MANAGEMENT = `${MANAGE_TEAM}/MultipleWorkspace`;
export const REPORTS_MANAGE_TEAM_ENTERPRISE_MANAGEMENT_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${MANAGE_TEAM_ENTERPRISE_MANAGEMENT}`;

export const generateManageTeamMultipleWorkspaceWithParam = (workspaceId: string) => {
  return `${REPORTS_BASE_ROUTE}/${workspaceId}/${MANAGE_TEAM_ENTERPRISE_MANAGEMENT}`;
};

export const EVENT_LOG = "event-log";
export const REPORTS_EVENT_LOG_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${EVENT_LOG}`;
export const generateEventLogWithParam = (workspaceId: string) => `${REPORTS_BASE_ROUTE}/${workspaceId}/${EVENT_LOG}`;
export const REPORTS_EVENT_LOG_ROUTE = generateEventLogWithParam(":workspaceId?");

export const BILLING = "billing";
export const REPORTS_BILLING_BASE_ROUTE = `${REPORTS_BASE_ROUTE_BY_WORKSPACE_ID}/${BILLING}`;
export const generateBillingWithParam = (workspaceId: string) => `${REPORTS_BASE_ROUTE}/${workspaceId}/${BILLING}`;
export const REPORTS_BILLING_ROUTE = generateBillingWithParam(":workspaceId?");

export const CMS_BASE_ROUTE = "/cms";
export const CMS_CHECK_PASSWORD_ROUTE = "/cms/check-password";

export const CMS_PERMISSION = "permission";
export const CMS_PERMISSION_BASE_ROUTE = `${CMS_BASE_ROUTE}/${CMS_PERMISSION}`;
export const CMS_PERMISSION_GLOBAL_ROUTE = `${CMS_BASE_ROUTE}/${CMS_PERMISSION}/global`;
export const CMS_PERMISSION_WORKSPACE_BASE_ROUTE = `${CMS_BASE_ROUTE}/${CMS_PERMISSION}/workspace`;
export const generateWorkspaceCmsPermissionPath = (workspaceId: string) =>
  `${CMS_PERMISSION_WORKSPACE_BASE_ROUTE}/${workspaceId}`;
export const CMS_PERMISSION_WORKSPACE_ROUTE = generateWorkspaceCmsPermissionPath(":workspaceId");

export const CMS_CUSTOM_OFFICE = "custom-office";
export const CMS_CUSTOM_OFFICE_ROUTE = `${CMS_BASE_ROUTE}/${CMS_CUSTOM_OFFICE}`;
export const CMS_CUSTOM_OFFICE_OBJECT_ROUTE = `${CMS_CUSTOM_OFFICE_ROUTE}/object`;
export const CMS_CUSTOM_OFFICE_BACKGROUND_ROUTE = `${CMS_CUSTOM_OFFICE_ROUTE}/background`;
export const CMS_CUSTOM_OFFICE_TEMPLATE_ROUTE = `${CMS_CUSTOM_OFFICE_ROUTE}/template`;
export const CMS_CUSTOM_OFFICE_CATEGORY_ROUTE = `${CMS_CUSTOM_OFFICE_ROUTE}/category`;

export const AVATAR = "avatar";
export const CMS_PROFILE_AVATAR = `${CMS_BASE_ROUTE}/avatar`;
export const CMS_PROFILE_AVATAR_CATEGORY_ROUTE = `${CMS_PROFILE_AVATAR}/category`;

export const CMS_EVENT_LOG = "event-log";
export const CMS_EVENT_LOG_BASE_ROUTE = `${CMS_BASE_ROUTE}/${CMS_EVENT_LOG}`;

export const FLOATING_PRESENTER_ROUTE = "/electron/presenter";

export const NOTIFICATIONS_WINDOW_ROUTE = "/electron/notifications-window";
export const STOP_SHARING_WINDOW_ROUTE = "/electron/stop-sharing";
export const TEXT_CHAT_WINDOW_ROUTE = "/electron/text-chat-window";
export const TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION_ROUTE = "/electron/text-chat-window-for-audio-extraction";
export const LOGIN_WINDOW_ROUTE = "/electron/login-window";
export const SUBTITLE_WINDOW_ROUTE = "/electron/subtitle-window";

export const PROMOTE_APP = "/download-now";

export const generateGuestJoinWithParam = (shortId: string) => `/join/${shortId}`;
export const GUEST_JOIN_ROUTE = generateGuestJoinWithParam(":shortId");
export const GUEST_WAITING_PATH = `/guest/waiting/:shortId`;
export const generateSessionExistPage = (workspaceShortId: string, channelShortId: string) =>
  `/guest/logout/${workspaceShortId}/${channelShortId}`;
export const generateListenerSessionExistPage = (listenerId: string, listenerModeQueryName?: string | null) =>
  listenerModeQueryName
    ? `/listener/logout/${listenerId}?${LISTENER_MODE_QUERY_NAME}=${listenerModeQueryName}`
    : `/listener/logout/${listenerId}`;
export const GUEST_SESSION_EXISTING_PAGE = `/guest/logout/:workspaceShortId?/:channelShortId?`;
export const LISTENER_SESSION_EXISTING_PAGE = `/listener/logout/:listenerId?`;

export const generateListenerJoinWithParam = (listenerId: string) => `/listener/join/${listenerId}`;
export const LISTENER_JOIN_ROUTE = generateListenerJoinWithParam(":listenerId");
export const generateListenerSharingChatWithParam = (userShortId: string, isMobileDevice: boolean) =>
  isMobileDevice
    ? `${generateListenerJoinWithParam(userShortId)}?${LISTENER_MODE_QUERY_NAME}=${LISTENER_MODE_QUERY_TYPE.MOBILE}`
    : `${generateListenerJoinWithParam(userShortId)}?${LISTENER_MODE_QUERY_NAME}=${
        LISTENER_MODE_QUERY_TYPE.AUDIO_EXTRACTION
      }`;

export const generateRecordingJoinWithParam = (token: string) => `/meeting-room/join/${token}`;
export const REDIRECT_RECORDING_ROUTE = generateRecordingJoinWithParam(":token");

export const STOP_REMOTE_CONTROL_WINDOW = "/electron/stop-remote-control-window";

export const SCREENSHARE_OVERLAY_WINDOW = "/electron/screenshare-overlay-window";
