import * as io from "socket.io-client";
import { Version } from "../store/version";
import { versionString } from "./helpers";
import logError from "./logError";

const EVENTS = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  MESSAGES: "messages",
  CONNECT_ERROR: "connect_error",
};

export default class Socket {
  private onChange: (isConnected: boolean, id: string | null) => void;
  private onMessage: (event: string) => void;
  private socket: SocketIOClient.Socket | null;
  private webVersion: Version | null;

  constructor(onChange: (isConnected: boolean, id: string | null) => void, onMessage: (event: string) => void) {
    this.onChange = onChange;
    this.onMessage = onMessage;
    this.socket = null;
    this.webVersion = null;
  }

  public connect = (webVersion: Version | null) => {
    const host = process.env.REACT_APP_BACKEND_HOST as string;

    try {
      this.webVersion = webVersion;
      this.socket?.close();
      this.socket = null;
      this.socket = io.connect(host, {
        reconnection: false,
        transportOptions: ["websocket"],
        secure: true,
        rejectUnauthorized: false,
        forceNew: true,
        query: {
          webVersion: webVersion ? versionString(webVersion) : "",
        },
      });
      this.socket.on(EVENTS.CONNECT, this.onConnected);
      this.socket.on(EVENTS.MESSAGES, this.onMessage);
      this.socket.on(EVENTS.DISCONNECT, this.onDisconnected);
      this.socket.on(EVENTS.CONNECT_ERROR, this.onDisconnected);
    } catch (error) {
      logError("Socket connection failed", error);
    }
  };

  public onConnected = async () => {
    this.onChange(true, this.socket!.id);
  };

  public onDisconnected = () => {
    logError("Socket was disconnected");
    this.onChange(false, null);
  };

  public sendMessage = (type: string, payload: any) => {
    if (typeof this.socket?.emit === "function") {
      this.socket.connected
        ? this.socket.emit(type, payload)
        : console.log("Cannot emit socket messages. Socket.io not connected");
    } else {
      console.log("Cannot emit socket messages. Socket.io not connected");
    }
  };

  public disconnect = () => {
    try {
      this.socket?.close();
    } catch (error) {
      logError("Socket close failed", error);
    }

    this.onChange(false, null);
  };

  public logout = () => {
    try {
      this.socket?.close();
      window.location.reload();
    } catch (error) {
      logError("Socket close failed", error);
    }
  };
}
