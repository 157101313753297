import { PNG_ICONS_S3_URL } from "../../../../constant";

export const KNOWN_APPS_LIST = [
  {
    displayName: "Calculator",
    desktop: {
      fixedNames: ["Calculator"],
      icon: PNG_ICONS_S3_URL + "Calculator.svg",
    },
  },
  {
    displayName: "Figma",
    web: {
      icon: "https://static.figma.com/app/icon/1/favicon.png",
      regexPatterns: [/Figma$/],
    },
    desktop: {
      icon: "https://static.figma.com/app/icon/1/favicon.png",
      iconHash: ["4eb9a7d1501c0316808183022f30d52cad19a48d"],
    },
  },
  {
    displayName: "Loom",
    desktop: {
      fixedNames: ["Loom Camera", "Loom Control Menu", "Loom Recorder Settings", "Loom Preferences"],
      icon: PNG_ICONS_S3_URL + "Loom.svg",
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Loom.svg",
      regexPatterns: [/^Loom \| /, / - Loom Recording$/],
    },
  },
  {
    displayName: "GitHub",
    // TODO: check GitHub desktop app
    // desktop: { ... }
    web: {
      icon: PNG_ICONS_S3_URL + "Github.svg",
      regexPatterns: [
        /^Issues · [a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/,
        /^Pull requests · [a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/,
        / · Issue #\d+ · [a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/,
        / · Pull Request #\d+ · [a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/,
      ],
    },
  },
  {
    displayName: "Slack",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Slack.svg",
      regexPatterns: [/^Slack \| /, /\| Slack$/, /^Slack$/, /Slack$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Slack.svg",
      regexPatterns: [/Slack$/],
    },
  },
  {
    displayName: "VoicePing",
    desktop: {
      icon: "https://app.voice-ping.com/favicon.ico",
      regexPatterns: [/^VoicePing\w*$/],
    },
    web: {
      icon: "https://app.voice-ping.com/favicon.ico",
      regexPatterns: [/^VoicePing\w*$/],
    },
  },
  {
    displayName: "VS Code",
    desktop: {
      regexPatterns: [/ - Visual Studio Code$/],
      icon: PNG_ICONS_S3_URL + "VisualStudio.svg",
      iconHash: ["4da95dc1b0c936f74cb7d8d77d676a187a40a42d", "0b245df1defb6ff4df270b452d092b14578cdc60"],
    },
  },
  {
    displayName: "Zoom",
    desktop: {
      regexPatterns: [/Zoom$/, /^zoom.us$/],
      iconHash: ["ea369e2be19934a7553729a95ee6d8efc40df63e"],
      icon: PNG_ICONS_S3_URL + "Zoom.svg",
    },
  },
  {
    displayName: "Tandem",
    desktop: {
      fixedNames: ["Tandem", "Tandem Settings", "Tandem | A virtual office for remote teams"],
    },
  },
  {
    displayName: "Google Sheets",
    web: {
      icon: PNG_ICONS_S3_URL + "gSheet.svg",
      regexPatterns: [/ - Google Sheets$/],
    },
  },
  {
    displayName: "Airtable",
    desktop: {
      regexPatterns: [/^Airtable \| /],
      icon: PNG_ICONS_S3_URL + "Airtable.svg",
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Airtable.svg",
      regexPatterns: [/^Airtable \| /],
    },
  },
  {
    displayName: "Google Docs",
    web: {
      icon: "https://www.google.com/docs/about/favicon.ico",
      regexPatterns: [/ - Google Docs$/],
    },
  },
  {
    displayName: "Google Slides",
    web: {
      icon: PNG_ICONS_S3_URL + "Slides.svg",
      regexPatterns: [/ - Google Slides$/],
    },
  },
  {
    displayName: "Google Drive",
    web: {
      icon: PNG_ICONS_S3_URL + "Drive.svg",
      regexPatterns: [/ - Google Drive$/],
    },
  },
  {
    displayName: "Notion",
    desktop: {
      regexPatterns: [/^Notion \| /],
      icon: PNG_ICONS_S3_URL + "Notion.svg",
      iconHash: [
        "41ea5f60d544c38db726a793893d10ab5b79c7b8",
        "c7516a2949d111674e634e02d682e3456a6c5bcb",
        "7e5c63f281e2f24a5e64a68a48aa6f3436cea830",
      ],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Notion.svg",
      regexPatterns: [/Notion$/],
    },
  },
  {
    displayName: "Slite",
    web: {
      icon: "https://cdn.slite.com/favicon/simple.ico",
      regexPatterns: [/\| Slite$/],
    },
  },
  {
    displayName: "Dropbox",
    web: {
      icon: PNG_ICONS_S3_URL + "Dropbox.svg",
      regexPatterns: [/^Dropbox$/],
    },
  },
  {
    displayName: "Word",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Word.svg",
      regexPatterns: [/ - Word$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Word.svg",
      regexPatterns: [/ - Microsoft Word Online$/],
    },
  },
  {
    displayName: "Excel",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Excel.svg",
      regexPatterns: [/ - Excel$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Excel.svg",
      regexPatterns: [/ - Microsoft Excel Online$/],
    },
  },
  {
    displayName: "Power Point",
    desktop: {
      icon: PNG_ICONS_S3_URL + "PowerPoint.svg",
      regexPatterns: [/ - PowerPoint$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "PowerPoint.svg",
      regexPatterns: [/ - Microsoft PowerPoint Online$/],
    },
  },
  {
    displayName: "OneNote",
    desktop: {
      icon: PNG_ICONS_S3_URL + "OneNote.svg",
      regexPatterns: [/^Notebooks$/, /OneNote$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "OneNote.svg",
      regexPatterns: [/^Notebooks$/, /OneNote$/, / - Microsoft OneNote Online$/],
    },
  },
  {
    displayName: "OneDrive",
    desktop: {
      regexPatterns: [/ - OneDrive$/],
    },
    web: {
      icon: "https://p.sfx.ms/images/favicon.ico",
      regexPatterns: [/ - OneDrive$/],
    },
  },
  {
    displayName: "Skype",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Skype.svg",
      regexPatterns: [/^Skype$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Skype.svg",
      regexPatterns: [/^Skype$/],
    },
  },
  {
    displayName: "MS Teams",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Teams.svg",
      regexPatterns: [/Microsoft Teams$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Teams.svg",
      regexPatterns: [/Microsoft Teams$/],
    },
  },
  {
    displayName: "Outlook",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Outlook.svg",
      regexPatterns: [/ - Outlook$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Outlook.svg",
      regexPatterns: [/ - Outlook$/],
    },
  },
  {
    displayName: "Asana",
    web: {
      icon: PNG_ICONS_S3_URL + "Asana.svg",
      regexPatterns: [/Asana$/],
    },
  },
  {
    displayName: "Trello",
    web: {
      icon: PNG_ICONS_S3_URL + "Trello.svg",
      regexPatterns: [/Trello$/],
    },
  },
  {
    displayName: "InVision",
    web: {
      icon: "https://marketing.invisionapp-cdn.com/www.invisionapp.com/global-assets/favicon/favicon-16x16.png",
      regexPatterns: [/InVision$/],
    },
  },
  {
    displayName: "Zeplin",
    web: {
      icon: PNG_ICONS_S3_URL + "Zeplin.svg",
      regexPatterns: [/Zeplin$/],
    },
  },
  {
    displayName: "Miro",
    web: {
      icon: "https://miro.com/static/favicons/favicon-16x16.png",
      regexPatterns: [/Miro$/],
    },
  },
  {
    displayName: "Blender",
    web: {
      icon: "https://www.blender.org/wp-content/themes/bthree/assets/images/favicon.ico?x20324",
      regexPatterns: [/Blender$/],
    },
  },
  {
    displayName: "Bitbucket",
    web: {
      icon: "https://bitbucket.org/favicon.ico?v=2",
      regexPatterns: [/Bitbucket$/],
    },
  },
  {
    displayName: "Confluence",
    web: {
      icon: PNG_ICONS_S3_URL + "Confluence.svg",
      regexPatterns: [/Confluence$/],
    },
  },
  {
    displayName: "Sourcetree",
    web: {
      icon: "https://www.sourcetreeapp.com/assets/img/favicons/sourcetree/favicon.ico",
      regexPatterns: [/Sourcetree$/],
    },
  },
  {
    displayName: "Visio",
    desktop: {
      regexPatterns: [/ - Visio$/],
    },
    web: {
      icon: "https://c1-odc-15.cdn.office.net/start/s/161330741000_resources/favicon_visio.ico",
      regexPatterns: [/ - Microsoft Visio Online$/],
    },
  },
  {
    displayName: "Lever",
    web: {
      icon: "https://www.lever.co/favicon.ico",
      regexPatterns: [/Lever$/],
    },
  },
  {
    displayName: "LinkedIn",
    web: {
      icon: PNG_ICONS_S3_URL + "LinkedIn.svg",
      regexPatterns: [/LinkedIn$/],
    },
  },
  {
    displayName: "Stack Overflow",
    web: {
      icon: PNG_ICONS_S3_URL + "Stack+overflow.svg",
      regexPatterns: [/ - Stack Overflow$/],
    },
  },
  {
    displayName: "Sentry",
    web: {
      icon: "https://www.sentry.dev/_assets2/favicon.ico",
      regexPatterns: [/Sentry$/],
    },
  },
  {
    displayName: "Gitlab",
    web: {
      icon: PNG_ICONS_S3_URL + "Gitlab.svg",
      regexPatterns: [/ - Gitlab$/],
    },
  },
  {
    displayName: "Visual Studio",
    desktop: {
      icon: PNG_ICONS_S3_URL + "VisualStudio.svg",
      regexPatterns: [/Visual Studio$/],
    },
  },
  {
    displayName: "Atom",
    desktop: {
      regexPatterns: [/Atom$/],
    },
  },
  {
    displayName: "Sublime Text",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Sublime text.svg",
      regexPatterns: [/- Sublime Text$/],
    },
  },
  {
    displayName: "Android Studio",
    desktop: {
      regexPatterns: [/Android Studio$/],
      icon: PNG_ICONS_S3_URL + "Android Studio.svg",
    },
  },
  {
    displayName: "IntelliJ",
    desktop: {
      regexPatterns: [/IntelliJ$/],
    },
  },
  {
    displayName: "Eclipse",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Eclipse.svg",
      regexPatterns: [/Eclipse$/],
    },
  },
  {
    displayName: "PyCharm",
    desktop: {
      regexPatterns: [/PyCharm$/],
    },
  },
  {
    displayName: "RubyMine",
    desktop: {
      regexPatterns: [/RubyMine$/],
    },
  },
  {
    displayName: "GoLand",
    desktop: {
      regexPatterns: [/GoLand$/],
    },
  },
  {
    displayName: "Google Meet",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Meet.svg",
      regexPatterns: [/^Meet\s–|Google\sMeet$/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Meet.svg",
      regexPatterns: [/^Meet\s–|Google\sMeet$/],
    },
  },
  {
    displayName: "Chatwork",
    desktop: {
      icon: PNG_ICONS_S3_URL + "Chatwork.svg",
      regexPatterns: [/^Chatwork/],
    },
    web: {
      icon: PNG_ICONS_S3_URL + "Chatwork.svg",
      regexPatterns: [/^Chatwork/],
    },
  },
  {
    displayName: "Whereby",
    web: {
      icon: PNG_ICONS_S3_URL + "Whereby.svg",
      regexPatterns: [/Whereby/],
    },
  },
  {
    displayName: "Backlog",
    web: {
      icon: PNG_ICONS_S3_URL + "Backlog.svg",
      regexPatterns: [/Backlog/],
    },
  },
  // ["WeChat", /^WeChat \| .*/],
  // ["Jitsi", /^Jitsi.org \| .*/],
  // ["TeamViewer", /^TeamViewer \| .*/],

  // TODO: add MS Office apps
  // TODO: Add Google Docs apps
  // TODO: Copy from Tandem: https://tandem.chat/integrations
];
