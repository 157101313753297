import { RemoteDesktopControlActions, RemoteDesktopControlActionType, ScreenSize } from "./actions";

export type RemoteDesktopControlState = {
  myDesktop: {
    isControlledByRemoteUser: boolean;
    remoteUserId: number | undefined;
    remoteUserName: string | undefined;
    isConnectedWithSkyWay: boolean;
    mySkyWayPeerId: string | undefined;
  };
  remoteDesktop: {
    isControlledByRemoteUser: boolean;
    remoteUserId: number | undefined;
    remoteUserName: string | undefined;
    remoteUserSkyWayPeerId: string | undefined;
    remoteUserScreenSize: ScreenSize | undefined;
  };
};

export const remoteDesktopControlInitialState = {} as RemoteDesktopControlState;

export type RemoteDesktopControlInitialState = typeof remoteDesktopControlInitialState;

export function remoteDesktopControlReducer(
  state: RemoteDesktopControlInitialState = remoteDesktopControlInitialState,
  action: RemoteDesktopControlActionType,
): RemoteDesktopControlInitialState {
  switch (action.type) {
    case RemoteDesktopControlActions.SET_MY_DESKTOP_CONTROL:
      return {
        ...state,
        myDesktop: {
          ...state.myDesktop,
          remoteUserId: action.payload.remoteUserId,
          remoteUserName: action.payload.remoteUserName,
          isControlledByRemoteUser: true,
        },
      };
    case RemoteDesktopControlActions.UNSET_MY_DESKTOP_CONTROL:
      return {
        ...state,
        myDesktop: {
          ...state.myDesktop,
          remoteUserId: undefined,
          isControlledByRemoteUser: false,
          remoteUserName: undefined,
        },
      };
    case RemoteDesktopControlActions.SET_REMOTE_DESKTOP_CONTROL:
      return {
        ...state,
        remoteDesktop: {
          remoteUserId: action.payload.remoteUserId,
          remoteUserName: action.payload.remoteUserName,
          isControlledByRemoteUser: true,
          remoteUserSkyWayPeerId: action.payload.remoteUserSkyWayPeerId,
          remoteUserScreenSize: action.payload.remoteUserScreenSize,
        },
      };
    case RemoteDesktopControlActions.UNSET_REMOTE_DESKTOP_CONTROL:
      return {
        ...state,
        remoteDesktop: {
          remoteUserId: undefined,
          isControlledByRemoteUser: false,
          remoteUserName: undefined,
          remoteUserSkyWayPeerId: undefined,
          remoteUserScreenSize: undefined,
        },
      };
    case RemoteDesktopControlActions.CONNECTION_STATUS_UPDATED_WITH_SKYWAY:
      return {
        ...state,
        myDesktop: {
          ...state.myDesktop,
          isConnectedWithSkyWay: action.payload.connected,
          mySkyWayPeerId: action.payload.id,
        },
      };
    default:
      return state;
  }
}
