import { getReactHostPath } from "../helpers";

export async function connectNoiseSuppressorNode(audioContext: AudioContext, audioSource: MediaStreamAudioSourceNode) {
  const modulePath = `${getReactHostPath()}/noise-suppressor-worklet.min.js`;

  await audioContext.audioWorklet.addModule(modulePath);

  // This happened when switching audio devices multiple times in short
  if (audioContext.state === "closed") return;

  const noiseSuppressorNode = new AudioWorkletNode(audioContext, "NoiseSuppressorWorklet");

  audioSource.connect(noiseSuppressorNode);

  return noiseSuppressorNode;
}
