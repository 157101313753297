import { InvitationJoinActionsTypes, InvitationJoinActions } from "./actions";
import { ApiError } from "../../utils/hooks/useApi";

interface InvitationJoinState {
  isLoading: boolean;
  errors: ApiError<any>[];
  invitedUserEmail: string;
  isUserRegistered: boolean;
}

export const invitationJoinInitialState = {
  isLoading: false,
  invitedUserEmail: "",
  isUserRegistered: false,
  errors: [
    {
      path: "",
      message: "",
    },
  ],
};

export function invitationJoinReducer(
  state: InvitationJoinState = invitationJoinInitialState,
  action: InvitationJoinActionsTypes,
): InvitationJoinState {
  switch (action.type) {
    case InvitationJoinActions.SHOW_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case InvitationJoinActions.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case InvitationJoinActions.SET_TOKEN_RESPONSE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
