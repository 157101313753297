import { channels } from "../electron/channels";
import { sendMessageOverIPC } from "../electron/sendMessageOverIPC";

export async function setStartUpPreference(start: boolean) {
  if (window.electron) {
    await sendMessageOverIPC(channels.SET_LOGIN_SETINGS, start);
  }
}

export async function getStartUpPreference() {
  if (window.electron) {
    const startupState = await sendMessageOverIPC(channels.WILL_APP_LAUNCH_AT_LOGIN);

    return startupState;
  }
}
