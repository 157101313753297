import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { LoaderWithDescription } from "../core/Loader/LoaderWithDescription";
import { isLoading } from "../../store/app";
import { NotificationsWindowLoader } from "../core/Loader/NotificationsWindowLoader";
import "./AppLoader.scss";
import { getDeviceInfo } from "../../utils/helpers";
import { getSocketConnectivityStatus } from "../../store/socket";
import { WORKSPACE_BASE_ROUTE } from "../../routes";

export function AppLoader() {
  const { os } = getDeviceInfo();
  const loading = useSelector(isLoading);
  const socketConnectionStatus = useSelector(getSocketConnectivityStatus);
  const loadingView: ReactNode =
    window.name === "notification-window" ||
    window.name === "stop-sharing-window" ||
    window.name === "screenshare-overlay-window" ||
    window.name === "text-chat-window" ||
    window.name === "text-chat-window-for-audio-extraction" ||
    window.name === "login-window" ||
    window.name === "subtitle-window" ? (
      <NotificationsWindowLoader />
    ) : (
      <LoaderWithDescription />
    );
  const isDashboard = window.location.pathname.includes(WORKSPACE_BASE_ROUTE);

  return (loading || !socketConnectionStatus) && isDashboard ? (
    <div className={cx("app-loader", { "app-loader-mac": os === "macos" })}>{loadingView}</div>
  ) : null;
}
