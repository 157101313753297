import { MemberActions, MemberActionTypes } from "./actions";
import { Floor, GroupData, ProjectData } from "./types";

export const memberInitialState = {
  workspaceId: undefined as number | undefined,
  projects: [] as ProjectData[],
  groups: [] as GroupData[],
  floors: [] as Floor[],
};

export type MemberInitialState = typeof memberInitialState;

export function memberReducer(
  state: MemberInitialState = memberInitialState,
  action: MemberActionTypes,
): MemberInitialState {
  switch (action.type) {
    case MemberActions.SET_BASE_INFO:
      return {
        ...state,
        projects: action.payload.projects,
        floors: action.payload.floors,
        groups: action.payload.groups,
      };
    case MemberActions.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects,
      };
    case MemberActions.SET_GROUPS:
      return {
        ...state,
        groups: action.payload.groups,
      };
    case MemberActions.ADD_GROUP:
      return {
        ...state,
        groups: [...state.groups, action.payload.group],
      };
    case MemberActions.ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload.project],
      };
    case MemberActions.ADD_FLOOR:
      return {
        ...state,
        floors: [...state.floors, action.payload.floor],
      };
    default:
      return state;
  }
}
