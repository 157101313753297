import React from "react";
import cx from "classnames";

import "./UserAvatar.scss";
import { Avatar, Badge } from "antd";

type AvatarSize = "smaller" | "small" | "medium" | "large" | "larger" | "xlarge" | "larger-pip";
export type BadgeColors = "available" | "away" | "offline" | "busy";

interface UserAvatarProps {
  badgeColor?: BadgeColors;
  showBorder?: boolean;
  src: string | null | undefined;
  size?: AvatarSize;
  onClick?: () => void;
  className?: string;
  times?: number;
}

function offsetMappingToSize(size: AvatarSize, times?: number): [number, number] {
  switch (size) {
    case "large":
      return [-6, 36];
    case "larger":
      switch (times) {
        case 3:
          return [-35, 46];
        case 2:
          return [-25, 46];
      }
      return [-11, 46];
    case "larger-pip":
      return [-11, 58];
    case "xlarge":
      return [-24, 114];
    case "medium":
      return [-7, 25];
    case "small":
    case "smaller":
      return [-6, 23];
    default:
      return [0, 0];
  }
}

export function UserAvatar({
  badgeColor,
  showBorder,
  src,
  size = "medium",
  onClick,
  className,
  times,
}: UserAvatarProps) {
  return (
    <div
      className={cx(
        "user-avatar",
        `user-avatar--${size}${
          (size === "xlarge" || size === "larger") && times ? (times === 0.8 ? "08" : times) : ""
        }`,
        `user-avatar--${badgeColor}`,
        className || "",
        {
          "user-avatar--border-on": showBorder === true,
          "user-avatar--border-off": showBorder === false,
        },
      )}
      onClick={onClick}
    >
      {badgeColor ? (
        <Badge dot offset={offsetMappingToSize(size, times)}>
          <Avatar src={src}></Avatar>
        </Badge>
      ) : (
        <Avatar src={src}></Avatar>
      )}
    </div>
  );
}
