import { Action, Dispatch, MiddlewareAPI } from "redux";
import { initialState, State } from "./state";
import { features } from "../features";

const limit = 500;
let preloadedState = initialState;
let actions: { preloadedState: State; action: Action }[] = [];

export function actionsHistoryMiddleware(store: MiddlewareAPI<Dispatch, State>) {
  if (!features.enabled("debugger.reduxStore")) {
    // short-circuit in case the feature is disabled
    return (next: Function) => (action: Action) => next(action);
  }

  return (next: Function) => (action: Action) => {
    if (actions.length >= limit) {
      const removedAction = actions.shift()!;

      preloadedState = removedAction.preloadedState;
    }

    const result = next(action);

    actions.push({
      action,
      preloadedState: store.getState(),
    });

    return result;
  };
}

export function getReduxData() {
  return {
    payload: JSON.stringify(actions.map(({ action }) => action)),
    preloadedState: JSON.stringify(preloadedState),
  };
}
