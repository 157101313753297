import { createSelector } from "reselect";
import { State } from "../../state";
import { ActiveAppState } from "./reducer";

export function getActiveApp(state: State) {
  return state.backgroundChecks.activeApp;
}

export const getCurrentActiveWindowFullName = createSelector(getActiveApp, (activeApp: ActiveAppState) => {
  return activeApp.activeWindowFullName;
});
