import { AudioExtractionActionTypes, AudioExtractionActions } from "./actions";

export const audioExtractionInitialState = {
  isExtractingAudio: false,
  userMediaStream: undefined as MediaStream | undefined,
  enableUseOfAudioExtraction: true,
  isRecordingAudioExtraction: false,
  isStartingRecordAudioExtraction: false,
  isStoppingRecordAudioExtraction: false,
  audioTrackId: undefined as string | undefined,
  videoTrackId: undefined as string | undefined,
  useOfRecordAudioExtraction: false,
  room: undefined as string | undefined,
  recordingStatusInWorkspace: {
    available: false,
    userIds: [],
  } as RecordingStatusInWorkspace,
  autoSelectedScreen: {
    id: undefined,
    name: undefined,
  } as {
    id: string | undefined;
    name: string | undefined;
  },
  autoRecordExternalMeeting: true,
  defaultMicStatus: true,
};

export type audioExtractionState = typeof audioExtractionInitialState;

export type RecordingStatusInWorkspace = {
  available: boolean;
  userIds: number[];
};

export function audioExtractionReducer(
  state: audioExtractionState = audioExtractionInitialState,
  action: AudioExtractionActionTypes,
): audioExtractionState {
  switch (action.type) {
    case AudioExtractionActions.UPDATE_AUDIO_EXTRACTION:
      return { ...state, isExtractingAudio: action.payload.isExtractingAudio };
    case AudioExtractionActions.SET_USER_MEDIA_STREAM:
      return {
        ...state,
        userMediaStream: action.payload.userMediaStream,
      };
    case AudioExtractionActions.SET_USE_AUDIO_EXTRACTION:
      return { ...state, enableUseOfAudioExtraction: action.payload.status };
    case AudioExtractionActions.UPDATE_RECORD_AUDIO_EXTRACTION:
      return { ...state, isRecordingAudioExtraction: action.payload.isRecordingAudioExtraction };
    case AudioExtractionActions.SET_AUDIO_TRACK_ID:
      return { ...state, audioTrackId: action.payload.audioTrackId };
    case AudioExtractionActions.SET_VIDEO_TRACK_ID:
      return { ...state, videoTrackId: action.payload.videoTrackId };
    case AudioExtractionActions.UPDATE_STARTING_RECORD_AUDIO_EXTRACTION:
      return { ...state, isStartingRecordAudioExtraction: action.payload.isStartingRecordAudioExtraction };
    case AudioExtractionActions.UPDATE_STOPPING_RECORD_AUDIO_EXTRACTION:
      return { ...state, isStoppingRecordAudioExtraction: action.payload.isStoppingRecordAudioExtraction };
    case AudioExtractionActions.SET_USE_RECORD_AUDIO_EXTRACTION:
      return { ...state, useOfRecordAudioExtraction: action.payload.status };
    case AudioExtractionActions.SET_EGRESS_ROOM_NAME:
      return { ...state, room: action.payload.room };
    case AudioExtractionActions.SET_RECORDING_STATUS_IN_WORKSPACE:
      return { ...state, recordingStatusInWorkspace: action.payload.recordingStatusInWorkspace };
    case AudioExtractionActions.SET_AUTO_SELECTED_SCREEN:
      return { ...state, autoSelectedScreen: action.payload.autoSelectedScreen };
    case AudioExtractionActions.SET_AUTO_RECORD_EXTERNAL_MEETING:
      return { ...state, autoRecordExternalMeeting: action.payload.autoRecordExternalMeeting };
    case AudioExtractionActions.SET_DEFAULT_MIC_STATUS:
      return { ...state, defaultMicStatus: action.payload.defaultMicStatus };
    default:
      return state;
  }
}
