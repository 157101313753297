import { useEffect, useRef } from "react";
import { channels } from "../../electron/channels";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS_URL, INTERNET_ONLINE_STATUS_CHECK } from "../../constant";
import { getDuplicatedLoginStatus, reloadApp } from "../../store/app";
import { getSocketConnectivityStatus } from "../../store/socket";
import { getAccountState, isAuthenticated } from "../../store/account";

export function useInternetStatus() {
  const dispatch = useDispatch();
  const isSocketConnected = useSelector(getSocketConnectivityStatus);
  const isDuplicatedLogin = useSelector(getDuplicatedLoginStatus);
  const authenticated = useSelector(isAuthenticated);
  const account = useSelector(getAccountState);
  const resumeAppInterval = useRef<number>();
  const timeoutApp = useRef<NodeJS.Timeout>();
  const pathname = window.location.pathname;
  const isUserHasWorkspace =
    !account?.user?.isListener && !account?.user?.isGuest && account?.user?.workspaces?.length !== 0;

  useEffect(() => {
    sendMessageOverIPC(channels.INTERNET_CONNECTIVITY, isSocketConnected);
    if (
      authenticated &&
      !isSocketConnected &&
      !isDuplicatedLogin &&
      isUserHasWorkspace &&
      !pathname.startsWith("/reports/") &&
      !pathname.startsWith("/cms") &&
      pathname.startsWith("/dashboard/")
    ) {
      resumeAppInterval.current = window.setInterval(() => {
        fetch(API_STATUS_URL).then(response => {
          timeoutApp.current = setTimeout(() => {
            if (response.ok && !isSocketConnected) {
              dispatch(reloadApp());
            }
          }, 5000);
        });
      }, INTERNET_ONLINE_STATUS_CHECK);
    }

    if (isSocketConnected) {
      if (timeoutApp.current) clearTimeout(timeoutApp.current);

      clearInterval(resumeAppInterval.current);
    }

    return () => {
      if (timeoutApp.current) clearTimeout(timeoutApp.current);

      clearInterval(resumeAppInterval.current);
    };
  }, [dispatch, isSocketConnected, isDuplicatedLogin, authenticated, isUserHasWorkspace, pathname]);
}
