import { AppThunk } from "../types";
import { postJson } from "../../utils/network";
import { LOGIN_DEMO_PATH } from "../../constant";
import { history, WORKSPACE_BASE_ROUTE } from "../../routes";
import { reloadAccount } from "../account/actions";

export enum DemoWorkspaceActions {
  SET_LOADER = "demoWorkspace/SET_LOADER",
  SET_ERROR = "demoWorkspace/SET_ERROR",
}

export type DemoWorkspaceActionsTypes = ReturnType<typeof setLoader> | ReturnType<typeof setError>;

export function createDemoUserAndSignin(): AppThunk {
  return async dispatch => {
    dispatch(setLoader(true));
    dispatch(setError(false));

    const { response } = await postJson<{ success: boolean }>(LOGIN_DEMO_PATH);

    if (response.success) {
      dispatch(reloadAccount());
      history.push(WORKSPACE_BASE_ROUTE);
    } else {
      dispatch(setError(true));
    }

    dispatch(setLoader(false));
  };
}

function setLoader(payload: boolean) {
  return { type: DemoWorkspaceActions.SET_LOADER, payload } as const;
}

function setError(payload: boolean) {
  return { type: DemoWorkspaceActions.SET_ERROR, payload } as const;
}
