import { log, LogCategory } from "../../../utils/log";
import { AppThunk } from "../../types";
import { getMyMember, getOnlineWorkspaceUsers } from "../../users/selectors";
import { getCurrentActiveWindowFullName } from "./selectors";
import { USER_EVENT_TYPES } from "../../users/middleware";
import { sendMessage } from "../../socket/actions";
import { cachedUserAppIcon } from "./helpers/appIconCache";
import { identifyActiveApp } from "./helpers/identifyActiveApp";
import { getIconHashAndType } from "./helpers/getIconHashAndType";
import { activeWindowBlacklist } from "../../../constant";
import { channels } from "../../../electron/channels";
import logError from "../../../utils/logError";
import { getCurrentWorkspace } from "../../workspace";
import { getIsMeetingRecording } from "../../account";
import { updateSelfUserActiveWindow, updateUserActiveWindowByBatch } from "../../users";

export enum ActiveAppActions {
  ONGOING_ACTIVE_WINDOW_TIMER = "activeApp/ONGOING_ACTIVE_WINDOW_TIMER",
  UPDATE_WINDOW_FULL_NAME = "activeApp/UPDATE_WINDOW_FULL_NAME",
}

export type ActiveAppActionTypes = ReturnType<typeof updateWindowFullName>;

export function updateWindowFullName(payload: { activeWindowFullName: string | null }) {
  return { type: ActiveAppActions.UPDATE_WINDOW_FULL_NAME, payload } as const;
}

export function stopCheckingActiveWindow(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    log(LogCategory.VirtualWorkspace, "Stopping checks for active window");

    window.electron?.ipcRenderer.removeAllListeners?.(channels.GET_ACTIVE_WIN_SOURCES);

    if (me?.workspaceId) {
      dispatch(clearActiveWindowData(me.workspaceId));
    }
  };
}

export function cleanupActiveWindow(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const onlineUsers = getOnlineWorkspaceUsers(getState());

    window.electron?.ipcRenderer.removeAllListeners?.(channels.GET_ACTIVE_WIN_SOURCES);

    if (me?.workspaceId) {
      const payload = onlineUsers.map(user => ({
        userId: user.id,
        workspaceId: me.workspaceId,
        activeWindowName: null,
        activeWindowIcon: null,
      }));

      dispatch(updateUserActiveWindowByBatch(payload));
    }
  };
}

export function startListeningForActiveWindowChange(payload: { userId: number; workspaceId: number }): AppThunk {
  return async dispatch => {
    if (window.electron) {
      log(LogCategory.ActiveApp, "Starting to listen for active window updates coming from electron");

      window.electron.ipcRenderer.on(channels.GET_ACTIVE_WIN_SOURCES, async (_, sources) => {
        let activeWindow;

        log(LogCategory.ActiveApp, "Raw sources", sources);

        if (!Array.isArray(sources) || sources.length === 0) {
          throw new Error("Error fetching the list of screens and windows to share.");
        }

        const finalSourcesList = sources.filter(source => !activeWindowBlacklist.has(source.name));

        log(LogCategory.ActiveApp, "The filtered sources list is", finalSourcesList);
        if (!Array.isArray(finalSourcesList) || finalSourcesList.length === 0) {
          logError("Empty sources list!!!");
          activeWindow = null;
        }

        activeWindow = finalSourcesList[0];

        log(LogCategory.ActiveApp, "Raw active window data", activeWindow);

        dispatch(
          changeActiveWindow(
            payload.userId,
            payload.workspaceId,
            activeWindow?.name ?? null,
            activeWindow?.appIconDataURI ?? null,
          ),
        );
      });
    }
  };
}

export function changeActiveWindow(
  userId: number,
  workspaceId: number,
  activeWindowName: string | null,
  activeWindowIcon: string | null,
): AppThunk {
  return async (dispatch, getState) => {
    const isMeetingRecording = getIsMeetingRecording(getState());

    if (isMeetingRecording) {
      return;
    }

    const currentActiveWindowFullName = getCurrentActiveWindowFullName(getState());

    log(
      LogCategory.ActiveApp,
      `Current Active Window Full Name ${currentActiveWindowFullName} and the activeWindowName recieved via IPC is ${activeWindowName}`,
    );

    if (currentActiveWindowFullName !== activeWindowName) {
      const updatedWindowName = activeWindowName?.replace(" 🔊", "");

      dispatch(updateWindowFullName({ activeWindowFullName: updatedWindowName || null }));

      const icon = getIconHashAndType(activeWindowIcon);
      let iconCacheUrl = icon ? await cachedUserAppIcon(icon.hash, icon.type, activeWindowIcon!) : null;

      log(LogCategory.ActiveApp, "Data before identifying active app", updatedWindowName, iconCacheUrl, icon);

      const activeApp = identifyActiveApp(updatedWindowName!, iconCacheUrl, icon?.hash ?? null);

      dispatch(
        updateSelfUserActiveWindow({
          userId,
          activeWindowName: activeApp?.name ?? null,
          activeWindowIcon: activeApp?.icon ?? iconCacheUrl,
        }),
      );

      log(LogCategory.ActiveApp, "Data being sent to server for active app", activeApp, iconCacheUrl);
      dispatch(
        sendMessage(USER_EVENT_TYPES.USER_ACTIVE_WINDOW, {
          workspaceId,
          activeWindowName: activeApp?.name ?? null,
          activeWindowIcon: activeApp?.icon ?? iconCacheUrl,
        }),
      );
    }
  };
}

export function checkActiveWindow(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentWorkspace = getCurrentWorkspace(getState());

    if (currentWorkspace?.enableActiveApp) {
      dispatch(startListeningForActiveWindowChange({ userId: me!.id, workspaceId: me!.workspaceId }));
    } else {
      dispatch(cleanupActiveWindow());
    }
  };
}

export function clearActiveWindowData(workspaceId: number): AppThunk {
  return dispatch => {
    log(LogCategory.VirtualWorkspace, "Clearing active window data on workspaceId", workspaceId);

    dispatch(updateWindowFullName({ activeWindowFullName: null }));

    dispatch(
      sendMessage(USER_EVENT_TYPES.USER_ACTIVE_WINDOW, {
        workspaceId,
        activeWindowName: null,
        activeWindowIcon: null,
      }),
    );
  };
}
