import { getBlob, getJson, postJson } from "../utils/network";
import {
  TIME_TRACKER_ACTIVITY_SUMMARY_PATH,
  TIME_TRACKER_INITIAL_DATA_PATH,
  TIME_TRACKER_WEEKLY_REPORT_PATH,
  TIME_TRACKER_DO_RESUME_PATH,
  TIME_TRACKER_EXPORT_REPORT_PATH,
  TIME_TRACKER_EXPORT_REPORT_GENERATE_PDF_PATH,
  TIME_TRACKER_ADD_MANUAL_TIME_PATH,
  TIME_TRACKER_DELETE_TIME_PATH,
  TIME_TRACKER_VALIDATE_TIME_PATH,
  MANUAL_TIME_LOG_PATH,
} from "../constant";
import { CreateOrReadResponseBody } from ".";

type InitialDataResponse = CreateOrReadResponseBody<TimeTrackerInitialData>;
type ActivitySummaryResponse = CreateOrReadResponseBody<ActivitySummary>;
type WeeklyReportResponse = CreateOrReadResponseBody<WeeklyReport>;
type ExportReportResponse = CreateOrReadResponseBody<ExportDataType>;
type DoResumeResponse = CreateOrReadResponseBody<DoResume>;

export type TimeTrackerInitialData = {
  description: string;
  totalSeconds: number;
  serverClock: string;
  todayTotalSecond: number;
};

export type ActivitySummary = {
  isManager: boolean;
  timeSlots: {
    id: number;
    offlineWork: boolean;
    projectId: number;
    description: string;
    beginTime: string;
    endTime: string;
    totalSeconds: number;
    manuallyAdded: boolean;
  }[];
  runningSlot: boolean;
  userScreenshotData: { enabled: boolean; screenshots: [] };
};

export type WeeklyReport = {
  users: {
    userId: number;
    deleted: boolean;
    totalSecondsByDate: Record<string, number>;
  }[];
};

export type ExportReportDetails = {
  userId: number | null;
  projectId: number | null;
  day: string;
  totalSeconds: number;
  displayName: string;
  avatarUrl: string;
  projectName: string | null;
  task: string | null;
};

export type ExportDataType = {
  data: ExportReportDetails[][];
  membersSummary: ExportReportDetails[][];
  projectsSummary: ExportReportDetails[][];
  totalTime: number;
};

export type DoResume = {
  doResume: boolean;
};

export async function getInitialData(workspaceId: number, projectId: number) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = { workspaceId, projectId, timezoneOffset: localTimezone };

  const { response } = await getJson<InitialDataResponse>(TIME_TRACKER_INITIAL_DATA_PATH, params);

  if (response.success) {
    return response.data;
  } else {
    alert(response.errors[0].message);
    return null;
  }
}

export async function getActivitySummary(
  workspaceId: number,
  userId: number,
  date: string,
  projectId: number | undefined,
) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = { workspaceId, userId, date, timezoneOffset: localTimezone, projectId };

  const { response } = await getJson<ActivitySummaryResponse>(TIME_TRACKER_ACTIVITY_SUMMARY_PATH, params);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching time tracker activity summary: ${JSON.stringify(response.errors)}`);
  }
}

export async function getWeeklyReport(workspaceId: number, weekBeginningDate: string, projectId: number | undefined) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = { workspaceId, weekBeginningDate, timezoneOffset: localTimezone, projectId };

  const { response } = await getJson<WeeklyReportResponse>(TIME_TRACKER_WEEKLY_REPORT_PATH, params);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching time tracker weekly report: ${JSON.stringify(response.errors)}`);
  }
}

export async function getDoResume(workspaceId: number, userId: number) {
  const params = { workspaceId, userId };

  const { response } = await getJson<DoResumeResponse>(TIME_TRACKER_DO_RESUME_PATH, params);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching time tracker do resume: ${JSON.stringify(response.errors)}`);
  }
}

export async function getExportReport(
  workspaceId: number,
  startDate: string,
  endDate: string,
  projectIds: Array<string | number>,
  userIds: Array<string | number>,
) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = {
    workspaceId,
    startDate,
    endDate,
    timezoneOffset: localTimezone,
    projectIds: projectIds.join("|"),
    userIds: userIds.join("|"),
  };

  const { response } = await getJson<ExportReportResponse>(TIME_TRACKER_EXPORT_REPORT_PATH, params);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching time tracker weekly report: ${JSON.stringify(response.errors)}`);
  }
}

export async function getExportReportPdf(
  workspaceId: number,
  startDate: string,
  endDate: string,
  projectIds: Array<string | number>,
  userIds: Array<string | number>,
) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = {
    workspaceId,
    startDate,
    endDate,
    timezoneOffset: localTimezone,
    projectIds: projectIds.join("|"),
    userIds: userIds.join("|"),
  };

  const { response } = await getBlob(TIME_TRACKER_EXPORT_REPORT_GENERATE_PDF_PATH, params);

  return response;
}

export async function addManualTime(
  workspaceId: number,
  projectId: number,
  userId: number,
  beginTime: string,
  endTime: string,
  clientTrackingId: string,
  description: string,
  reason: string,
  startOfDay: string,
  endOfDay: string,
) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = {
    workspaceId,
    timezoneOffset: localTimezone,
    projectId,
    userId,
    beginTime,
    endTime,
    clientTrackingId,
    description,
    reason,
    startOfDay,
    endOfDay,
  };

  const { response } = await postJson(TIME_TRACKER_ADD_MANUAL_TIME_PATH, params);

  return response;
}

export async function deleteTrackedTime(
  workspaceId: number,
  userId: number,
  type: string,
  date: string,
  startOfDay: string,
  endOfDay: string,
  projectId?: number,
) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = {
    workspaceId,
    projectId,
    timezoneOffset: localTimezone,
    userId,
    type,
    date,
    startOfDay,
    endOfDay,
  };

  const { response } = await postJson(TIME_TRACKER_DELETE_TIME_PATH, params);

  return response;
}

export async function validateTrackedTime(
  workspaceId: number,
  userId: number,
  beginTime: string,
  endTime: string,
  startDay: string,
  endDay: string,
) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = {
    workspaceId,
    timezoneOffset: localTimezone,
    userId,
    beginTime,
    endTime,
    startDay,
    endDay,
  };

  const { response } = await postJson(TIME_TRACKER_VALIDATE_TIME_PATH, params);

  return response;
}

export async function getManualTimeLog(workspaceId: number, userId: number, startDate: string, endDate: string) {
  const localTimezone = new Date().getTimezoneOffset();
  const params = {
    workspaceId,
    timezoneOffset: localTimezone,
    userId,
    startDate,
    endDate,
  };

  const { response } = await getJson(MANUAL_TIME_LOG_PATH, params);

  return response;
}
