import React, { Fragment, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row, Typography } from "antd";

import "./AppErrors.scss";
import scarecrow from "../../assets/images/scarecrow.svg";
import { getErrorPageInfo } from "../../utils/helpers";
import NoPermission from "./NoPermission";
import { channels } from "../../electron/channels";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";

const { Title } = Typography;

function AppErrors() {
  useEffect(() => {
    const interval = setInterval(async () => {
      if (window.electron) {
        //for desktop
        //Hide screenoverlay window in case of any error to avoid any errors/warnings display on overlay window
        await sendMessageOverIPC(channels.HIDE_SCREENSHARE_OVERLAY_WINDOW);
        await sendMessageOverIPC(channels.RELOAD_APP);
      } else {
        //for browser
        window.location.href = "/dashboard";
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderDescription = () => {
    if (getErrorPageInfo() === "service-unavailable") {
      return (
        <FormattedMessage
          id="service-unavailable/server-is-down"
          defaultMessage="Server is down. Press F5 button to reload app."
        />
      );
    } else {
      return (
        <FormattedMessage
          id="error/text-1"
          defaultMessage="We've run into an unexpected error, please refresh voiceping"
        />
      );
    }
  };

  return (
    <Fragment>
      {getErrorPageInfo() === "no-permission" ? (
        <NoPermission />
      ) : (
        <Row align="middle" justify="start" className="error-page">
          <Col span={12}>
            <Row align="middle" justify="center" gutter={[500, 25]}>
              <Col offset={1}>
                <Title>
                  <FormattedMessage id="error/title" defaultMessage="Oops, something went wrong" />
                </Title>
              </Col>
              <Col offset={1}>{renderDescription()}</Col>
            </Row>
          </Col>

          <Col span={12}>
            <span className="scarecrow">
              <img src={scarecrow} alt="Scarecrow" />
            </span>
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

export default AppErrors;
