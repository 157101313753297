import React from "react";
import { CreateOrReadResponseBody, UpdateOrDeleteResponseBody } from ".";
import {
  ASSIGN_FLOOR_TO_TEAM_MEMBER,
  CREATE_GROUP,
  CREATE_GROUP_MEMBER,
  CREATE_PROJECT,
  CREATE_PROJECT_MEMBER,
  DELETE_GROUP,
  DELETE_GROUP_MEMBER,
  DELETE_PROJECT,
  DELETE_PROJECT_MEMBER,
  DELETE_TEAM_MEMBER,
  EDIT_ACCESS_GROUP_FOR_VOICE_CHANNEL,
  EDIT_GROUP_FOR_TEAM_MEMBER,
  EDIT_PROJECT_FOR_TEAM_MEMBER,
  GET_GROUPS_BY_WORKSPACEID,
  GET_GROUP_MEMBERS,
  GET_MEMBER_MANAGE_BASE_INFO,
  GET_PROJECTS_BY_WORKSPACEID,
  GET_PROJECT_MEMBERS,
  GET_WORKSPACE_MEMBERS_WITH_DATA,
  SEARCH_QUERY_PARAM_NAME,
  UPDATE_GROUP_NAME,
  UPDATE_MEMBER_ROLE,
  UPDATE_PROJECT_NAME,
  UPDATE_GROUP_MEMBERS,
  INCLUDED_DELETED_MEMBERS,
  ADD_MEMBER_TIME_TRACKING,
  ADD_MEMBER_SCREENSHOTS,
  GET_WORKSPACE_MEMBERS_TRACKING_DATA,
  PROJECT_GROUPS,
  INCLUDE_GROUP_MEMBERS,
  UPDATE_MEMBER_WEB_APP,
  CREATE_OR_UPDATE_TIME_TRACKER_LIMIT,
  GET_FLOOR_ASSIGNED_MEMBER_IDS,
  GET_OWNER_STATUS,
  POST_UPDATE_OWNER,
  GET_MEMBER_DATA_FOR_TRAMSCRIPT,
} from "../constant";
import {
  CheckOwnerChangeableStatus,
  Floor,
  GroupData,
  GroupMemberDetail,
  ProjectData,
  ProjectGroupData,
  ProjectMemberDetail,
  WorkspaceMemberDetail,
} from "../store/member/types";
import dayjs from "dayjs";
import { dateFormat } from "../utils/dateformat";
import { createRequest, getJson, postJson } from "../utils/network";
import * as Sentry from "@sentry/browser";

interface CreateProjectRequestData {
  workspaceId: number;
  name: string;
  members?: number[] | undefined;
}

interface CreateOrUpdateProjectGroupRequestData {
  groups: React.Key[];
  projectId: number;
  workspaceId?: number;
}

interface CreateGroupRequestData {
  workspaceId: number;
  name: string;
  members?: number[] | undefined;
}

interface UpdateMemberRoleRequestData {
  workspaceId: number;
  userId: number;
  role: string;
}

interface DeleteProjectGroupRequestData {
  groupId: number;
  projectId: number;
}

interface DeleteTeamMemberRequestData {
  workspaceId: number;
  userId: number;
}

interface AssignFloorToTeamMemberRequestData {
  workspaceId: number;
  userId: number;
  floors: React.Key[];
}

interface EditProjectForTeamMemberRequestData {
  workspaceId: number;
  userId: number;
  projects: React.Key[];
}

interface EditGroupForTeamMemberRequestData {
  workspaceId: number;
  userId: number;
  groups: React.Key[];
}

interface EditAccessGroupForVoiceChannelRequestData {
  workspaceId: number;
  voiceChannelId: number;
  groups: React.Key[];
}

interface CreateOrUpdateProjectMemberRequestData {
  members: number[];
  projectId: number;
}

interface CreateOrUpdateGroupMemberRequestData {
  members: number[];
  groupId: number;
}

interface UpdateGroupMembersRequestData {
  workspaceId: number;
  groupMembers: { [key: number]: number[] };
}

interface UpdateProjectNameRequestData {
  projectId: number;
  projectName: string;
}

interface UpdateGroupNameRequestData {
  groupId: number;
  groupName: string;
}

interface DeleteProjectMemberRequestData {
  userId: number;
  projectId: number;
}
interface EnableMemberTrackingRequestData {
  workspaceId: number;
  userId: number;
  timeTracking: boolean;
}
interface EnableMemberScreenshotRequestData {
  workspaceId: number;
  userId: number;
  enableScreenshot: boolean;
}

interface DeleteGroupMemberRequestData {
  userId: number;
  groupId: number;
}

interface CreateOrUpdateTimeTreackerLimitRequestData {
  id: number | null;
  enableTimeTrackerLimit: boolean;
  monthlyLimit?: number;
  weeklyLimit?: number;
  dailyLimit?: number;
  enableShift?: boolean;
  timezoneOffset?: number;
  timezoneName?: string;
  days?: string;
  slots?: {
    id?: number;
    startTime: string;
    endTime: string;
  }[];
  userId: number;
  workspaceId: number;
}

export type GetProjectsResponse = CreateOrReadResponseBody<{
  projects: Array<ProjectData>;
}>;

export type GetGroupsResponse = CreateOrReadResponseBody<{
  groups: Array<GroupData>;
}>;

export type GetProjectMembersResponse = CreateOrReadResponseBody<{
  members: ProjectMemberDetail[];
}>;

export type GetGroupMembersResponse = CreateOrReadResponseBody<{
  members: GroupMemberDetail[];
}>;

export type GetWorkspaceMembersResponse = CreateOrReadResponseBody<{
  members: WorkspaceMemberDetail[];
  totalMemberCount: number;
}>;

export type GetProjectGroupsResponse = CreateOrReadResponseBody<{
  groups: Array<ProjectGroupData>;
}>;

export async function getWorkspaceMembersWithData(workspaceId: number, search?: string | null) {
  const path = GET_WORKSPACE_MEMBERS_WITH_DATA(workspaceId);

  const { response } = search
    ? await getJson<GetWorkspaceMembersResponse>(path, { [SEARCH_QUERY_PARAM_NAME]: search })
    : await getJson<GetWorkspaceMembersResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    Sentry.captureException("Error when fetching workspace members");
    return null;
  }
}

export async function getWorkspaceMembersTrackingData(workspaceId: number, search?: string | null) {
  const path = GET_WORKSPACE_MEMBERS_TRACKING_DATA(workspaceId);
  const { response } = search
    ? await getJson<any>(path, {
        [SEARCH_QUERY_PARAM_NAME]: search,
        timezoneOffset: new Date().getTimezoneOffset(),
        currentDate: dateFormat(dayjs()),
      })
    : await getJson<any>(path, {
        timezoneOffset: new Date().getTimezoneOffset(),
        currentDate: dateFormat(dayjs()),
      });

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching member list  data: ${JSON.stringify(response.errors)}`);
  }
}

export type GetBaseInfoResponse = CreateOrReadResponseBody<{
  project: ProjectData[];
  floor: Floor[];
  group: GroupData[];
}>;

export async function getBaseInfo(workspaceId: number) {
  const path = GET_MEMBER_MANAGE_BASE_INFO(workspaceId);
  const { response } = await getJson<GetBaseInfoResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    Sentry.captureException(`Error when fetching base information data: ${JSON.stringify(response.errors)}`);
    return false;
  }
}

export async function updateMemberRole(data: UpdateMemberRoleRequestData) {
  const path = UPDATE_MEMBER_ROLE(data.workspaceId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function updateMemberEnableWeb(data: any) {
  const path = UPDATE_MEMBER_WEB_APP(data.workspaceId);
  const { response } = await postJson<any>(path, data);

  return response;
}

export async function deleteTeamMember(data: DeleteTeamMemberRequestData) {
  const path = DELETE_TEAM_MEMBER(data.workspaceId, data.userId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function assignFloorToTeamMember(data: AssignFloorToTeamMemberRequestData) {
  const path = ASSIGN_FLOOR_TO_TEAM_MEMBER(data.workspaceId, data.userId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function editProjectForTeamMember(data: EditProjectForTeamMemberRequestData) {
  const path = EDIT_PROJECT_FOR_TEAM_MEMBER(data.workspaceId, data.userId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function editGroupForTeamMember(data: EditGroupForTeamMemberRequestData) {
  const path = EDIT_GROUP_FOR_TEAM_MEMBER(data.workspaceId, data.userId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function createProject(data: CreateProjectRequestData) {
  const path = CREATE_PROJECT;

  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  return response;
}

export async function deleteProject(projectId: number) {
  const path = DELETE_PROJECT(projectId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function getProjectByWorkspaceId(workspaceId: number, projectId?: number, search?: string | null) {
  const path = GET_PROJECTS_BY_WORKSPACEID(workspaceId);

  const { response } = search
    ? await getJson<GetProjectsResponse>(path, { [SEARCH_QUERY_PARAM_NAME]: search })
    : projectId
    ? await getJson<GetProjectsResponse>(path, { projectId: projectId })
    : await getJson<GetProjectsResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching project list  data: ${JSON.stringify(response.errors)}`);
  }
}

export async function getProjectMembers(
  projectId: number,
  search?: string | null,
  deletedMembers?: boolean | null,
  includeGroupMembers?: boolean,
) {
  let path = GET_PROJECT_MEMBERS(projectId) + "?";

  if (deletedMembers) {
    path = path + `${INCLUDED_DELETED_MEMBERS}=${deletedMembers}&`;
  }

  if (includeGroupMembers !== undefined && includeGroupMembers) {
    path = path + `${INCLUDE_GROUP_MEMBERS}=${includeGroupMembers}&`;
  }

  if (search && search !== "") {
    path = path + `${SEARCH_QUERY_PARAM_NAME}=${search}`;
  }

  const { response } = await getJson<GetProjectMembersResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching member list  data: ${JSON.stringify(response.errors)}`);
  }
}

export async function createProjectMember(data: CreateOrUpdateProjectMemberRequestData) {
  const path = CREATE_PROJECT_MEMBER(data.projectId);

  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  return response;
}

export async function deleteProjectMember(data: DeleteProjectMemberRequestData) {
  const path = DELETE_PROJECT_MEMBER(data.projectId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function enableMemberTimetracking(data: EnableMemberTrackingRequestData) {
  const path = ADD_MEMBER_TIME_TRACKING(data.workspaceId);

  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}
export async function enableMemberScreenshots(data: EnableMemberScreenshotRequestData) {
  const path = ADD_MEMBER_SCREENSHOTS(data.workspaceId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function updateProjectName(data: UpdateProjectNameRequestData) {
  const path = UPDATE_PROJECT_NAME(data.projectId);

  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function createGroup(data: CreateGroupRequestData) {
  const path = CREATE_GROUP;

  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  return response;
}

export async function deleteGroup(groupId: number) {
  const path = DELETE_GROUP(groupId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  return response;
}

export async function createGroupMember(data: CreateOrUpdateGroupMemberRequestData) {
  const path = CREATE_GROUP_MEMBER(data.groupId);

  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  return response;
}

export async function updateGroupMembers(data: UpdateGroupMembersRequestData) {
  const path = UPDATE_GROUP_MEMBERS;

  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function deleteGroupMember(data: DeleteGroupMemberRequestData) {
  const path = DELETE_GROUP_MEMBER(data.groupId);

  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function updateGroupName(data: UpdateGroupNameRequestData) {
  const path = UPDATE_GROUP_NAME(data.groupId);

  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function getGroupByWorkspaceId(workspaceId: number, search?: string | null) {
  const path = GET_GROUPS_BY_WORKSPACEID(workspaceId);

  const { response } = search
    ? await getJson<GetGroupsResponse>(path, { [SEARCH_QUERY_PARAM_NAME]: search })
    : await getJson<GetGroupsResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching group list data: ${JSON.stringify(response.errors)}`);
  }
}

export async function getGroupMembers(groupId: number, search?: string | null) {
  let path = GET_GROUP_MEMBERS(groupId);

  if (search && search !== "") {
    path = path + `?${SEARCH_QUERY_PARAM_NAME}=${search}`;
  }

  const { response } = await getJson<GetGroupMembersResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    alert(response.errors[0].message);
  }
}

export async function editAccessGroupForVoiceChannel(data: EditAccessGroupForVoiceChannelRequestData) {
  const path = EDIT_ACCESS_GROUP_FOR_VOICE_CHANNEL(data.workspaceId, data.voiceChannelId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  return response;
}

export async function getProjectGroups(projectId: number, search?: string | null) {
  let path = PROJECT_GROUPS(projectId);

  if (search && search !== "") {
    path = path + `?${SEARCH_QUERY_PARAM_NAME}=${search}`;
  }

  const { response } = await getJson<GetProjectGroupsResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching member list  data: ${JSON.stringify(response.errors)}`);
  }
}

export async function createProjectGroup(data: CreateOrUpdateProjectGroupRequestData) {
  const path = PROJECT_GROUPS(data.projectId);

  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    throw new Error(`Error while creating projectGroup data: ${JSON.stringify(response.errors)}`);
  }
}

export async function deleteProjectGroup(data: DeleteProjectGroupRequestData) {
  const { response } = await createRequest(PROJECT_GROUPS(data.projectId), {
    method: "DELETE",
    body: JSON.stringify(data),
  });

  if (response.success) {
    return response;
  } else {
    throw new Error(`Error while deleting projectGroup data: ${JSON.stringify(response.errors)}`);
  }
}

export async function updateProjectGroup(data: CreateOrUpdateProjectGroupRequestData) {
  const { response } = await createRequest(PROJECT_GROUPS(data.projectId), {
    method: "PUT",
    body: JSON.stringify(data),
  });

  if (response.success) {
    return response;
  } else {
    throw new Error(`Error while updating projectGroup data: ${JSON.stringify(response.errors)}`);
  }
}

export async function createMemberTimeTrackerLimit(data: CreateOrUpdateTimeTreackerLimitRequestData) {
  const { response } = await createRequest(CREATE_OR_UPDATE_TIME_TRACKER_LIMIT(data.workspaceId), {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (response.success) {
    return response;
  } else {
    throw new Error(`Error while creating time tracker limit: ${JSON.stringify(response.errors)}`);
  }
}

export async function updateMemberTimeTrackerLimit(data: CreateOrUpdateTimeTreackerLimitRequestData) {
  const { response } = await createRequest(CREATE_OR_UPDATE_TIME_TRACKER_LIMIT(data.workspaceId), {
    method: "POST",
    body: JSON.stringify(data),
  });

  if (response.success) {
    return response;
  } else {
    throw new Error(`Error while updating time tracker limit data: ${JSON.stringify(response.errors)}`);
  }
}

type GetFloorAssignedMemberIdsResponse = CreateOrReadResponseBody<{
  memberIds: number[];
}>;

export async function getFloorAssignedMemberIds(floorId: number) {
  const path = GET_FLOOR_ASSIGNED_MEMBER_IDS(floorId);
  const { response } = await getJson<GetFloorAssignedMemberIdsResponse>(path);

  if (response.success) {
    return response.data.memberIds;
  } else {
    return false;
  }
}

type GetOnwerStatusResponse = CreateOrReadResponseBody<{
  status: boolean;
  description?: CheckOwnerChangeableStatus;
}>;

type OwnerChangingRelatedRequest = {
  newOwnerId: number;
  workspaceId: number;
};

export async function getOwnerStatus(data: OwnerChangingRelatedRequest) {
  const path = GET_OWNER_STATUS;
  const { response } = await postJson<GetOnwerStatusResponse>(path, data);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type PostUpdateOwnerResponse = CreateOrReadResponseBody<{
  status: boolean;
}>;

export async function postUpdateOwner(data: OwnerChangingRelatedRequest) {
  const path = POST_UPDATE_OWNER;
  const { response } = await postJson<PostUpdateOwnerResponse>(path, data);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type GetMemberDataForTranscriptPermissionResponse = CreateOrReadResponseBody<{
  members: { userId: number; fullName: string; avatarUrl: string }[];
  groups: GroupData[];
}>;

export async function getMemberDataForTranscriptPermission(workspaceId: number) {
  const path = GET_MEMBER_DATA_FOR_TRAMSCRIPT(workspaceId);
  const { response } = await getJson<GetMemberDataForTranscriptPermissionResponse>(path);

  return response;
}
