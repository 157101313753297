import { createSelector } from "reselect";
import { CAL_PROVIDER_OPTIONS_OBJECT } from "../../constant";

import { State } from "../state";
import { AccountState } from "./reducer";
import { SttEndpoint, TranslatorEndpoint } from "../types";

export function getAccountState(state: State): AccountState {
  return state.account;
}

export const getAuthenticatedAccount = createSelector(getAccountState, (account: AccountState) => account.user);

export const isAcceptedInvitation = createSelector(
  getAccountState,
  (account: AccountState) => account.acceptedInvitation,
);

export const isUserLoaded = createSelector(getAccountState, (account: AccountState) => account.loaded);

export const isAuthenticated = createSelector(getAccountState, (account: AccountState) => account.user.authenticated);

export const getLanguage = createSelector(getAccountState, (account: AccountState) => account.user.language);

export const getBaseLanguage = createSelector(getAccountState, (account: AccountState) =>
  account.user.language === "ja"
    ? "ja"
    : account.user.language === "en"
    ? "en"
    : account.user.language === "vi"
    ? "vi"
    : "ko",
);

export const getRedirectPath = createSelector(getAccountState, (account: AccountState) => account.redirectPath);

export const isSlackConnected = createSelector(
  getAccountState,
  (account: AccountState) => account.user.slack?.connected,
);

export const getSlackMissingScopes = createSelector(
  getAccountState,
  (account: AccountState) => account.user.slack?.missingScopes,
);

export const isSlackMissingScopes = createSelector(
  getSlackMissingScopes,
  missingScopes => !!missingScopes?.botScopes.length || !!missingScopes?.userScopes.length,
);

export const isSlackMissingScopesFor = createSelector(getSlackMissingScopes, missingScopes => ({
  incomingCallNotification:
    missingScopes?.botScopes.includes("im:read") ||
    missingScopes?.botScopes.includes("im:write") ||
    missingScopes?.botScopes.includes("im:history") ||
    missingScopes?.botScopes.includes("chat:write"),
  meetingStatusSync:
    missingScopes?.botScopes.includes("users.profile:read") ||
    missingScopes?.userScopes.includes("users.profile:write"),
  sttMessagesSync:
    missingScopes?.botScopes.includes("channels:read") ||
    missingScopes?.botScopes.includes("channels:join") ||
    missingScopes?.botScopes.includes("groups:read") ||
    missingScopes?.botScopes.includes("chat:write.customize"),
  timeTrackerNotifications:
    missingScopes?.botScopes.includes("channels:read") ||
    missingScopes?.botScopes.includes("channels:join") ||
    missingScopes?.botScopes.includes("users:read") ||
    missingScopes?.botScopes.includes("users:read.email") ||
    missingScopes?.botScopes.includes("groups:read"),
  transcriptNotifications:
    missingScopes?.botScopes.includes("channels:read") ||
    missingScopes?.botScopes.includes("channels:join") ||
    missingScopes?.botScopes.includes("users:read") ||
    missingScopes?.botScopes.includes("users:read.email") ||
    missingScopes?.botScopes.includes("groups:read"),
}));

export const isGoogleConnected = createSelector(
  getAccountState,
  (account: AccountState) => account.user.connectedGoogle,
);

export const getOAuthProvider = createSelector(getAccountState, (account: AccountState) => account.user.oAuthProvider);

export const isSignupWithPassword = createSelector(
  getAccountState,
  (account: AccountState) => account.user.signupWithPassword,
);

export const getEmailOfAccount = createSelector(getAccountState, (account: AccountState) => account.user.email);

export const getEmailTokenFlag = createSelector(
  getAccountState,
  (account: AccountState) => account.user.emailTokenFlag,
);

export const isChatworkConnected = createSelector(getAccountState, account => account.user.connectedChatwork);

export const isAzureConnected = createSelector(getAccountState, account => account.user.connectedAzure);

export const getUserProjects = createSelector(getAccountState, (account: AccountState) => account.user.projects);

export const getChatworkGroupId = createSelector(
  getAccountState,
  (account: AccountState) => account.user.chatworkGroupId,
);

export const getWorkspaces = createSelector(getAccountState, (account: AccountState) => account.user.workspaces);

export const getWorkspace = (workspaceId: number) =>
  createSelector(getAccountState, (account: AccountState) => account.user.workspaces?.find(w => w.id === workspaceId));

export const isWorkspaceManager = (workspaceId: number) =>
  createSelector(getAccountState, (account: AccountState) =>
    account.user.workspaces?.some(
      w => (w.id === workspaceId && w.role === "manager") || (w.id === workspaceId && w.role === "owner"),
    ),
  );

export const getAssignedFloor = createSelector(getAccountState, (account: AccountState) => account.user.assignedFloor);

export const checkIsGuest = createSelector(getAccountState, (account: AccountState) => account.user.isGuest);

export const checkIsListener = createSelector(getAccountState, (account: AccountState) => account.user.isListener);

export const getIsMeetingRecording = createSelector(
  getAccountState,
  (account: AccountState) => account.user.isMeetingRecording,
);

export const getAccountWorkspaceId = createSelector(
  getAccountState,
  (account: AccountState) => account.user.workspaceId,
);
export const getStatusProvider = createSelector(
  getAccountState,
  (account: AccountState) => account.user.statusProvider,
);

export const getStatusProviderOptions = createSelector(
  getAccountState,
  getStatusProvider,
  (account: AccountState, statusProvider?: string) =>
    statusProvider
      ? arrangeStatusProviderOptions(
          statusProvider,
          account.user.connectedGoogle ? true : false,
          account.user.connectedAzure ? true : false,
        )
      : [],
);

const arrangeStatusProviderOptions = (statusProvider: string, connectedGoogle: boolean, connectedAzure: boolean) => {
  if (statusProvider === "Google") {
    const options = [CAL_PROVIDER_OPTIONS_OBJECT.google];

    connectedAzure && options.push(CAL_PROVIDER_OPTIONS_OBJECT.outlook);

    return options;
  }

  const options = [CAL_PROVIDER_OPTIONS_OBJECT.outlook];

  connectedGoogle && options.push(CAL_PROVIDER_OPTIONS_OBJECT.google);

  return options;
};

export const getSkyWayAPIKey = createSelector(getAuthenticatedAccount, account => account.skyWayAPIKey);

export const getIsAppIdle = createSelector(getAccountState, (account: AccountState) => account.isAppIdle);

export const getTimeTrackerLimitData = createSelector(getAccountState, (account: AccountState) => {
  return {
    enableTimeTrackerLimit: account.user.enableTimeTrackerLimit,
    dailyLimit: account.user.dailyLimit,
    monthlyLimit: account.user.monthlyLimit,
    weeklyLimit: account.user.weeklyLimit,
    enableShift: account.user.enableShift,
    timezoneOffset: account.user.timezoneOffset,
    slots: account.user.slots,
    days: account.user.days as number[],
    currentMonthTime: account.user.currentMonthTime,
    currentWeekTime: account.user.currentWeekTime,
    todayTime: account.user.todayTime,
  };
});

export const getSttEndpoints = createSelector(getAuthenticatedAccount, account => {
  return account.aiModelInfo?.filter(model => model.modelType === "speechToText") as SttEndpoint[];
});

export const getTranslatorEndpoints = createSelector(getAuthenticatedAccount, account => {
  return account.aiModelInfo?.filter(model => model.modelType === "translator") as TranslatorEndpoint[];
});

export const getPinVoiceChannels = createSelector(getAuthenticatedAccount, account => {
  return account.pinVoiceChannels;
});

export const getPinUsers = createSelector(getAuthenticatedAccount, account => {
  return account.pinUsers;
});

export const getTimeTrackingInitialData = createSelector(getAuthenticatedAccount, account => {
  return account.timetrackingInitialData;
});

export const getMyShortId = createSelector(getAuthenticatedAccount, account => account.shortId);

export const getEnableSharing = createSelector(getAuthenticatedAccount, account => account.enableSharing);
export const getSharingPassword = createSelector(getAuthenticatedAccount, account => account.sharingPassword);
export const getEnableSharingTextToSpeech = createSelector(
  getAuthenticatedAccount,
  account => account.enableSharingTextToSpeech,
);
export const getEnableSharingMic = createSelector(getAuthenticatedAccount, account => account.enableSharingMic);

export const getInvitedEnv = createSelector(getAccountState, (account: AccountState) => account.invitedEnv);

export const getMyId = createSelector(getAuthenticatedAccount, account => account.id);

export const getTextFontSize = createSelector(getAuthenticatedAccount, account => account.textFontSize);

export const getTextTranslation = createSelector(getAuthenticatedAccount, account => account.textTranslation);

export const getShowOriginalSttMessage = createSelector(getAccountState, account => account.user.showOriginalMessage);

export const getIsMarketingMailAcceptable = createSelector(getAccountState, account => account.user.isMarketingMail);

export const getIsSystemMailAcceptable = createSelector(getAccountState, account => account.user.isSystemMail);
