import { AppNotificationActionsTypes, AppNotificationActions } from "./actions";
import { NotificationContent } from "./types";

export interface AppNotificationState {
  notifications: NotificationContent[];
}

export const appNotificationInitialState: AppNotificationState = {
  notifications: [],
};

export function appNotificationReducer(
  state: AppNotificationState = appNotificationInitialState,
  action: AppNotificationActions,
): AppNotificationState {
  switch (action.type) {
    case AppNotificationActionsTypes.SHOW:
      return {
        ...state,
        notifications: [...state.notifications, action.payload.content],
      };
    case AppNotificationActionsTypes.HIDE:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.payload.id),
      };
    default:
      return state;
  }
}
