import { createStore, compose, applyMiddleware, AnyAction, CombinedState, Action } from "redux";
import thunk from "redux-thunk";
import {
  createStateSyncMiddleware,
  initMessageListener,
  initStateWithPrevTab,
  withReduxStateSync,
} from "redux-state-sync";

import { StoreActionTypes } from "./actions";
import { initialState, State } from "./state";
import { rootReducers } from "./reducers";
import { socketMiddleware } from "./socket";
import { actionsHistoryMiddleware } from "./actionsHistory";
import { log, LogCategory } from "../utils/log";
import features from "../features";
import { livekitMiddleware } from "./livekit";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;

const enhancedRootReducers = (state: CombinedState<State> | undefined, action: AnyAction) => {
  if (action.type === StoreActionTypes.RESET_STORE) {
    return rootReducers(undefined, action);
  }

  return rootReducers(state, action);
};

const syncedReducers = (withReduxStateSync(enhancedRootReducers) as any) as typeof enhancedRootReducers;

const stateSyncMiddleware = createStateSyncMiddleware({
  channel: "redux-sync",
  broadcastChannelOption: { type: "localstorage" },
});

const isChildWindow = !!window.opener;

export const store = createStore(
  syncedReducers,
  initialState,
  composeEnhancers(
    isChildWindow
      ? applyMiddleware(thunk, stateSyncMiddleware)
      : window.electron
      ? applyMiddleware(
          thunk,
          logActionsMiddleware,
          socketMiddleware,
          livekitMiddleware,
          actionsHistoryMiddleware,
          userTiming,
          stateSyncMiddleware,
        )
      : applyMiddleware(
          thunk,
          logActionsMiddleware,
          socketMiddleware,
          livekitMiddleware,
          actionsHistoryMiddleware,
          userTiming,
        ),
  ),
);

if (isChildWindow) {
  initStateWithPrevTab(store);
} else {
  initMessageListener(store);
}

if (features.enabled("reduxDevtools")) {
  Object.assign(window, { store });
}

/**
 * This redux middleware is used to get action names on Chrome Dev Tools profiler results
 */
function userTiming() {
  return (next: Function) => (action: Action) => {
    if (performance.mark === undefined) return next(action);

    performance.mark(`${action.type}_start`);

    const result = next(action);

    performance.mark(`${action.type}_end`);
    performance.measure(`${action.type}`, `${action.type}_start`, `${action.type}_end`);

    return result;
  };
}

/**
 * This redux middleware is used to get action names on Chrome Dev Tools profiler results
 */
function logActionsMiddleware() {
  return (next: Function) => (action: Action) => {
    try {
      log(LogCategory.Redux, action.type, "start", action);

      return next(action);
    } catch (error) {
      log(LogCategory.Redux, action.type, "error", error);
    } finally {
      log(LogCategory.Redux, action.type, "end");
    }
  };
}
