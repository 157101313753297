import { createSelector } from "reselect";

import { State } from "../state";
import { Screenshare } from "../types";
import { getCurrentVoiceChannelUsersExceptMe } from "../voiceChannels";
import { Member } from "../users";

function getScreenshareState(state: State) {
  return state.screenshare;
}

export const getScreenshare = createSelector(getScreenshareState, (screenshare: Screenshare) => {
  return screenshare;
});

export const getMySharedScreen = createSelector(getScreenshareState, (screenshare: Screenshare) => {
  return screenshare.mySharedScreen;
});

export const getViewers = (subscriberIds: number[]) =>
  createSelector(
    getScreenshareState,
    getCurrentVoiceChannelUsersExceptMe,
    (screenshare: Screenshare, members: Member[]) => {
      const viewers = members
        .filter(m => screenshare.mySharedScreen.viewers?.includes(m.id) && subscriberIds.includes(m.id))
        .map(m => {
          return { userId: m.id, avatarUrl: m.avatarUrl };
        });

      return viewers;
    },
  );
