import { createSelector } from "reselect";
import { getAuthenticatedAccount } from "../account/selectors";
import { State } from "../state";
import { Member } from "./reducer";

export function getWorkspaceUsersState(state: State) {
  return state.users;
}

export const getWorkspaceUsers = createSelector(getWorkspaceUsersState, usersState => usersState.users);

export const getWorkspaceListeners = createSelector(getWorkspaceUsersState, usersState => usersState.listeners);

export const getAllMembersEmail = createSelector(getWorkspaceUsers, members => members.map(member => member.email));

export const getAllMembersId = createSelector(getWorkspaceUsers, members => members.map(member => member.id));

export const getWorkspaceMemberById = (id: number) =>
  createSelector(getWorkspaceUsersState, usersState => usersState.users.find(el => el.id === id));

export const getOnlineWorkspaceUsers = createSelector(getWorkspaceUsersState, usersState =>
  usersState.users.filter(u => u.online),
);

export const getOnlineWorkspaceListeners = createSelector(getWorkspaceListeners, listeners =>
  listeners.filter(u => u.online),
);

export const getOfflineWorkspaceUsers = createSelector(getWorkspaceUsersState, usersState =>
  usersState.users.filter(wu => !wu.online && wu.role !== "guest"),
);

export const getOfflineWorkspaceUsersId = createSelector(getOfflineWorkspaceUsers, members =>
  members.map(member => member.id),
);

export const getWorkspaceMembers = createSelector(getWorkspaceUsers, allMembers =>
  allMembers.filter(el => el.role !== "guest"),
);

export const getWorkspaceMembersCount = createSelector(getWorkspaceMembers, members => members.length);

export const getMyMember = createSelector(getAuthenticatedAccount, getOnlineWorkspaceUsers, (account, members) =>
  members.find(u => u.id === account.id),
);

export const getSelectedProject = createSelector(getMyMember, me => me?.project);

export const getMyPosition = createSelector(getMyMember, me =>
  me && me.identity ? { identity: me.identity, position: me.position, broadcastActive: me.broadcastActive } : null,
);

export const checkMeRecordingBot = createSelector(getMyMember, me => me?.role === "meeting-recording-bot");

export const getScreenshareStatus = createSelector(getMyMember, me => !!me?.screenActive);

export const isMeManager = createSelector(getMyMember, me => me?.role === "manager" || me?.role === "owner");
export const getCurrentWorkspaceId = createSelector(getMyMember, me => me?.workspaceId);
export const getMyMemberStatus = createSelector(getMyMember, getAuthenticatedAccount, (me, account) => {
  if (me?.isExtractingAudio) {
    return "busy";
  } else if (me?.status === "away") {
    return me?.status;
  } else if (account.connectedAzure && me?.statusProvider === "Outlook") {
    return me.azureStatus;
  } else if (account.connectedGoogle && me?.statusProvider === "Google") {
    return me.googleStatus;
  }

  return me?.status;
});

export const amIInAChannel = createSelector(getMyMember, me => (me?.voiceChannelId ? true : false));

export const getUserSpeechToTextSetting = createSelector(getMyMember, (me?: Member) => me?.speechToText);

export const isDefaultAvatarUrl = createSelector(getMyMember, me => me?.avatarUrl.includes("ui-avatars.com/api"));

export const getSearchedMember = createSelector(getWorkspaceUsersState, usersState => usersState.searchedMemberId);

export const getSearchMemberFocus = createSelector(getWorkspaceUsersState, usersState => usersState.searchMemberFocus);

export const getHighlightedMember = createSelector(
  getWorkspaceUsersState,
  usersState => usersState.highlightedMemberId,
);

export const getLastScrollPosition = createSelector(
  getWorkspaceUsersState,
  usersState => usersState.lastScrollPosition,
);

export const getSearchMemberHistory = createSelector(
  getWorkspaceUsersState,
  usersState => usersState.searchMemberHistory,
);

export const getOfflineUsersOpenedWorkspaceIds = createSelector(
  getWorkspaceUsersState,
  usersState => usersState.offlineUsersOpenedWorkspaceIds,
);

export const getOnlineUserById = (userId: number) =>
  createSelector(getOnlineWorkspaceUsers, users => users.find(el => el.id === userId));

export const getBroadcastersOnFloor = createSelector(getOnlineWorkspaceUsers, getMyMember, (onlineMembers, me) =>
  onlineMembers.filter(el => el.id !== me?.id && el.voiceChannelId === me?.voiceChannelId && el.broadcastActive),
);

export const getBroadcastersIdentitesOnFloor = createSelector(
  getOnlineWorkspaceUsers,
  getMyMember,
  (onlineMembers, me) =>
    onlineMembers
      .filter(el => el.identity && el.id !== me?.id && el.voiceChannelId === me?.voiceChannelId && el.broadcastActive)
      .map(el => el.identity!),
);

export const getEmojiReaction = (userId: number) =>
  createSelector(getWorkspaceUsersState, usersState => usersState.emojiReactions.find(el => el.userId === userId));

export const getSyncStatus = createSelector(getMyMember, me => !!me?.syncCalendar);

export const getIsMyActiveWindowVoicePing = createSelector(
  getMyMember,
  me => me?.activeWindowName?.includes("VoicePing") && me?.activeWindowIcon?.includes("voice-ping.com"),
);

export const getIsMyActiveWindowLoaded = createSelector(getMyMember, me => !!me?.activeWindowIcon);

export const getMemberAndManager = createSelector(getWorkspaceUsers, users =>
  users.filter(el => el.role !== "guest" && el.role !== "meeting-recording-bot"),
);

export const getMemberAndManagerIds = createSelector(getMemberAndManager, users => users.map(user => user.id));

export const getOnlyThisFloorAssignedUser = (floorId: number) =>
  createSelector(getWorkspaceUsers, usersState =>
    usersState.filter(u => u.assignedFloors && u.assignedFloors.length === 1 && u.assignedFloors[0] === floorId),
  );
export const getCalendarScheduleEvents = createSelector(
  getWorkspaceUsersState,
  getOnlineWorkspaceUsers,
  getOfflineWorkspaceUsers,
  (userstate, members, offlineUsers) => {
    return {
      scheduleEvents: userstate.scheduleEvents,
      activeCalendarUserId: userstate.activeCalendarUserId,
      refreshScheduleEvents: userstate.refreshScheduleEvents,
      user: [...members, ...offlineUsers].find(a => a.id === userstate.activeCalendarUserId),
    };
  },
);

export const getEnabledLanguageInconsistencyAlert = createSelector(
  getMyMember,
  me => !!me?.enabledLanguageInconsistencyAlert,
);

export const getListenerMediaStream = createSelector(
  getWorkspaceUsersState,
  userState => userState.listenerMediaStream,
);

export const getEnableTextChatMic = createSelector(getWorkspaceUsersState, userState => userState.enableMic);
