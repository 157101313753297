import { Dispatch } from "redux";
import { AppThunk } from "../types";
import { createRequest } from "../../utils/network";
import { CHECK_RESET_PASSWORD_TOKEN } from "../../constant";

export enum ResetPasswordActions {
  SHOW_LOADER = "resetPassword/SHOW_LOADER",
  SET_SUCCESS = "resetPassword/SET_SUCCESS",
}

export type ResetPasswordActionTypes = ReturnType<typeof showLoader> | ReturnType<typeof setSuccess>;

export function validateResetPasswordToken(token: string): AppThunk {
  return async (dispatch: Dispatch) => {
    dispatch(showLoader(true));
    const { response } = await createRequest(CHECK_RESET_PASSWORD_TOKEN(token), {
      method: "GET",
    });

    dispatch(setSuccess(response.success));
    dispatch(showLoader(false));
  };
}

function setSuccess(payload: boolean) {
  return { type: ResetPasswordActions.SET_SUCCESS, payload } as const;
}

function showLoader(payload: boolean) {
  return { type: ResetPasswordActions.SHOW_LOADER, payload } as const;
}
