import { createSelector } from "reselect";
import { State } from "../state";
import { UserScreenActiveNotification } from "../users";
import { NotificationsWindowState } from "./reducer";

export function getNotificationsState(state: State): NotificationsWindowState {
  return state.notificationsWindow;
}

export const isNotificationWindowLoaded = createSelector(
  getNotificationsState,
  (notificationsWindow: NotificationsWindowState) => {
    return notificationsWindow.notificationWindowLoaded;
  },
);

export const getNotifications = createSelector(
  getNotificationsState,
  notificationsWindow => notificationsWindow.notifications,
);

export const checkExistingNotifications = createSelector(getNotifications, notifications => notifications.length > 0);

export const getScreenShareNotificationsCount = createSelector(getNotifications, notifications => {
  const screenActiveNotifications = notifications.find(el => el.notificationsType === "screen-share-notification");

  const screenActiveNotificationsCount = screenActiveNotifications
    ? (screenActiveNotifications.data as UserScreenActiveNotification[])
    : [];

  return screenActiveNotificationsCount.length;
});

export const getNotificationsCount = createSelector(
  getNotifications,
  getScreenShareNotificationsCount,
  (generalNotifications, screenShareNotificationsCount) => {
    const otherNotificationsCount = generalNotifications.filter(
      n => n.notificationsType !== "screen-share-notification",
    ).length;

    return otherNotificationsCount + screenShareNotificationsCount;
  },
);

export const getNotificationsKey = createSelector(
  getNotificationsState,
  notificatoinState => notificatoinState.notificationKeys,
);
