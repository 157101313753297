import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { DAY_FULL_NAMES, JP_DAY_FULL_NAMES, DAYS_NOTATION, JP_DAYS_NOTATION } from "../constant";
import { areEqualArray } from "./helpers";

export const dateFormat = (date: string | Dayjs, format: string = "YYYY-MM-DD") => {
  const dateDayjs = typeof date === "string" ? dayjs(date) : date;

  return dateDayjs.format(format);
};

export const convertUtcOffsetToLocal = (
  startTime?: string,
  endTime?: string,
  timezoneOffset?: number,
  isStandardFormat?: boolean,
) => {
  const localTimezone = moment().utcOffset();

  if (startTime && endTime && timezoneOffset && localTimezone !== timezoneOffset) {
    const meetingStartTime = moment(startTime, "h:mm a").utcOffset(timezoneOffset, true);
    const meetingEndTime = moment(endTime, "h:mm a").utcOffset(timezoneOffset, true);

    let localMeetingStartTime = meetingStartTime.local();
    let localMeetingEndTime = meetingEndTime.local();

    startTime = isStandardFormat ? localMeetingStartTime.format("HH:mm") : localMeetingStartTime.format("h:mm a");
    endTime = isStandardFormat ? localMeetingEndTime.format("HH:mm") : localMeetingEndTime.format("h:mm a");
  }

  return { startTime, endTime };
};

export const getMeetingDays = (currentLanguage: string, slotDays: number[]) => {
  const notationArray = currentLanguage === "en" ? DAYS_NOTATION : JP_DAYS_NOTATION;

  let notationTitle: string = "";

  for (const notationDay of notationArray) {
    if (areEqualArray(notationDay.days, slotDays)) {
      notationTitle = notationDay.notation;
    }
  }

  if (notationTitle) {
    return notationTitle;
  } else {
    return (
      (currentLanguage === "en" ? "Every " : "毎 ") +
      slotDays?.map(d => (currentLanguage === "en" ? DAY_FULL_NAMES[d] : JP_DAY_FULL_NAMES[d])).join(", ")
    );
  }
};

export const getMeetingTime = (slotStartTime: string, slotEndTime: string) => {
  return slotStartTime + " - " + slotEndTime;
};
