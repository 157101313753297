import React from "react";
import { log, LogCategory } from "../../../utils/log";

import { AppThunk } from "../../types";
import { changeMicStatus, changeUserStatus, changeSelfVideoStatus } from "../../users/actions";
import { getMyMember, getMyMemberStatus } from "../../users/selectors";
import { updateWindowFullName } from "../activeApp/actions";
import { reasonsForBeingSetAway, reasonsForBeingSetResume } from "./reducer";
import {
  closeTrackerAwayReasoningModal,
  showTrackerAwayReasoningModal,
  startWorkingRequest,
  stopWorkingRequest,
} from "../../timeTracker/actions";
import { getIsPingOnFlying, getWorkingState } from "../../timeTracker/selectors";
import {
  addNotificationKey,
  addNotificationOnPIP,
  removeNotificationKey,
  removeNotificationOnPIP,
} from "../../notificationsWindow";
import { notification } from "../../../components/antd/Notification";
import { MicIconFilled, VideocamIconFilled } from "../../../components/icons/material";
import { FormattedMessage } from "react-intl";
import { wasIWorkingBeforeAway } from "./selectors";
import localData from "../../../localStorageKeys";
import { PING_RESPONSE_DELAY } from "../../timeTracker/offlineTracking/offlineTrackingService";
import { getTrackerAwayModal } from "../../app";

export enum ActivityDetectionActions {
  REASON_FOR_AWAY = "background/REASON_FOR_AWAY",
  REASON_FOR_RESUME = "background/REASON_FOR_RESUME",
  IS_WORKING_BEFORE_AWAY = "background/IS_WORKING_BEFORE_AWAY",
}

export type ActivityDetectionActionTypes =
  | ReturnType<typeof setReasonForBeingSetAway>
  | ReturnType<typeof setReasonForBeingSetResume>
  | ReturnType<typeof setIsWorkingBeforeAway>;

export function setReasonForBeingSetAway(payload: { reasonForBeingSetAway: reasonsForBeingSetAway }) {
  return { type: ActivityDetectionActions.REASON_FOR_AWAY, payload } as const;
}

export function setReasonForBeingSetResume(payload: { reasonForBeingSetResume: reasonsForBeingSetResume }) {
  return { type: ActivityDetectionActions.REASON_FOR_RESUME, payload } as const;
}

export function setIsWorkingBeforeAway(payload: { isWorkingBeforeAway: boolean }) {
  return { type: ActivityDetectionActions.IS_WORKING_BEFORE_AWAY, payload } as const;
}

export function setUserAway(reason: reasonsForBeingSetAway): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const myStatus = getMyMemberStatus(getState());
    const amIWorking = getWorkingState(getState());
    const trackerModal = getTrackerAwayModal(getState());

    if (me && !trackerModal?.show) {
      log(LogCategory.VirtualWorkspace, `Calling stop active window checks as user is inactive because of ${reason} `);
      dispatch(setIsWorkingBeforeAway({ isWorkingBeforeAway: amIWorking }));
      dispatch(setReasonForBeingSetAway({ reasonForBeingSetAway: reason }));

      if (myStatus === "available") {
        dispatch(changeUserStatus({ workspaceId: me.workspaceId, status: "away", statusProvider: me.statusProvider }));
      }

      dispatch(setIdleBasedOnBusinessLogic());
    }
  };
}

export function setUserResume(reason: reasonsForBeingSetResume): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me) {
      log(LogCategory.VirtualWorkspace, `Calling start active window checks as user is active because of ${reason} `);
      dispatch(setReasonForBeingSetResume({ reasonForBeingSetResume: reason }));
      if (me.status === "away") {
        dispatch(
          changeUserStatus({ workspaceId: me.workspaceId, status: "available", statusProvider: me.statusProvider }),
        );
      }

      dispatch(setResumeBasedOnBusinessLogic());
    }
  };
}

export function closeAwayMuteNotification(isAccepted: boolean): AppThunk {
  return dispatch => {
    const notificationkey = "unmute-in-idle-notification";

    if (isAccepted) {
      dispatch(changeMicStatus(false));
    }

    dispatch(removeNotificationOnPIP({ type: notificationkey }));
    dispatch(removeNotificationKey(notificationkey));
  };
}

export function showAwayMuteNotification(): AppThunk {
  return dispatch => {
    const notificationkey = "unmute-in-idle-notification";

    dispatch(
      addNotificationOnPIP({
        notificationsType: notificationkey,
        data: undefined,
        timestamp: 15,
        isAutoRemovable: true,
      }),
    );
    dispatch(addNotificationKey(notificationkey));
    notification.open({
      key: notificationkey,
      type: "info",
      icon: <MicIconFilled />,
      description: (
        <FormattedMessage
          id="unmute-change-in-idle/subtitle"
          defaultMessage="Your mic is muted because of idle. Do you want to unmute it?"
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="unmute" defaultMessage="Unmute" />,
        onClick: () => dispatch(closeAwayMuteNotification(true)),
      },
      secondaryButton: {
        type: "outline",
        color: "white",
        label: <FormattedMessage id="ignore" defaultMessage="Ignore" />,
        onClick: () => dispatch(closeAwayMuteNotification(false)),
      },
      onClose: () => dispatch(closeAwayMuteNotification(false)),
    });
  };
}

export function closeAwayCamNotification(isAccepted: boolean): AppThunk {
  return dispatch => {
    const notificationKey = "camera-in-idle-notification";

    if (isAccepted) {
      dispatch(changeSelfVideoStatus(true));
    }

    dispatch(removeNotificationOnPIP({ type: notificationKey }));
    dispatch(removeNotificationKey(notificationKey));
  };
}

export function showAwayVideoNotification(): AppThunk {
  return dispatch => {
    const notificationKey = "camera-in-idle-notification";

    dispatch(
      addNotificationOnPIP({
        notificationsType: notificationKey,
        data: undefined,
        timestamp: 15,
        isAutoRemovable: true,
      }),
    );
    dispatch(addNotificationKey(notificationKey));
    notification.open({
      key: notificationKey,
      type: "info",
      icon: <VideocamIconFilled />,
      description: (
        <FormattedMessage
          id="camera-change-in-idle/subtitle"
          defaultMessage="Your cam is turned off because of idle. Do you want to turn it on?"
        />
      ),
      primaryButton: {
        type: "primary",
        color: "positive",
        label: <FormattedMessage id="turn-on-cam" defaultMessage="Turn on Cam" />,
        onClick: () => dispatch(closeAwayCamNotification(true)),
      },
      secondaryButton: {
        type: "outline",
        color: "white",
        label: <FormattedMessage id="ignore" defaultMessage="Ignore" />,
        onClick: () => dispatch(closeAwayCamNotification(false)),
      },
      onClose: () => dispatch(closeAwayCamNotification(false)),
    });
  };
}

export function setIdleBasedOnBusinessLogic(): AppThunk {
  return (dispatch, getState) => {
    const amIWorking = getWorkingState(getState());
    const isPingOnFlying = getIsPingOnFlying(getState());

    dispatch(updateWindowFullName({ activeWindowFullName: null }));
    if (amIWorking) {
      dispatch(showTrackerAwayReasoningModal());

      if (isPingOnFlying) {
        setTimeout(() => {
          dispatch(stopWorkingRequest("User Status changed to Away with passing IDLE TIME"));
        }, PING_RESPONSE_DELAY);
      } else {
        dispatch(stopWorkingRequest("User Status changed to Away with passing IDLE TIME"));
      }

      localData.set("timeTracker.stopped.userAway", JSON.stringify(true));
    }
  };
}

export function setResumeBasedOnBusinessLogic(): AppThunk {
  return (dispatch, getState) => {
    const wasIWorking = wasIWorkingBeforeAway(getState());
    const amIWorking = getWorkingState(getState());

    if (wasIWorking && !amIWorking) {
      dispatch(closeTrackerAwayReasoningModal());
      dispatch(startWorkingRequest());
    }
  };
}
