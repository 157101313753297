import { ResourceState } from "../store/types";
import { getWebVersion } from "../store/version";
import { store } from "../store";
import { HEADER_FRONTEND_VERSION_NAME } from "../constant";
import { versionString } from "./helpers";
import { log, LogCategory } from "./log";

const defaultOptions: RequestInit = {
  headers: {
    "Content-Type": "application/json",
  },
};

export async function createRequest<T extends {} = any>(url: string, options?: RequestInit) {
  //  fixme: have a backendBasePrefix env at some stage
  const backendBasePrefix = process.env.REACT_APP_BACKEND_BASE_URL || "/api/v1";

  const webVersion = getWebVersion(store.getState());

  const initOptions: RequestInit = {
    credentials: "include",
    mode: "cors",
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...options?.headers,
      [HEADER_FRONTEND_VERSION_NAME]: versionString(webVersion),
    },
    ...options,
  };

  log(LogCategory.Api, "request", url, initOptions);

  const res = await fetch(backendBasePrefix + url, initOptions);

  const response: T = await res.json();

  if (res.status === 429) {
    //@ts-ignore
    alert(response.message ?? "Too many requests. Please try again later.");
  }

  log(LogCategory.Api, "response", url, response);

  return { response };
}

export async function getJson<T extends {} = any>(path: string, params?: Record<string, any>) {
  const query = (params && `?${new URLSearchParams(params).toString()}`) ?? "";

  return await createRequest<T>(path + query, { method: "GET" });
}

export async function postJson<T extends {} = any>(path: string, body: Record<string, any> = {}) {
  return await createRequest<T>(path, { method: "POST", body: JSON.stringify(body) });
}

export async function putJson<T extends {} = any>(path: string, body: Record<string, any> = {}) {
  return await createRequest<T>(path, { method: "PUT", body: JSON.stringify(body) });
}

export function isResourceLoading(status: ResourceState): boolean {
  return status === ResourceState.LOADING;
}

export function isResourceNotFoundOrLoading(status: ResourceState): boolean {
  return status === ResourceState.NOT_FOUND || status === ResourceState.LOADING;
}

export function isResourceFound(status: ResourceState): boolean {
  return status === ResourceState.FOUND;
}

export async function getBlob(path: string, params?: Record<string, any>) {
  const query = (params && `?${new URLSearchParams(params).toString()}`) ?? "";

  return await createBlobRequest(path + query, { method: "GET" });
}

export async function createBlobRequest(url: string, options?: RequestInit) {
  const backendBasePrefix = process.env.REACT_APP_BACKEND_BASE_URL || "/api/v1";

  const webVersion = getWebVersion(store.getState());

  const initOptions: RequestInit = {
    credentials: "include",
    mode: "cors",
    ...defaultOptions,
    headers: {
      ...defaultOptions.headers,
      ...options?.headers,
      [HEADER_FRONTEND_VERSION_NAME]: versionString(webVersion),
    },
    ...options,
  };

  const res = await fetch(backendBasePrefix + url, initOptions);

  const response = await res.blob();

  return { response };
}
