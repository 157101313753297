import { getUserAppIcon, postUserAppIcon } from "../../../../api/user";

const localAppIconCache = new Map<string, string>();

export async function cachedUserAppIcon(hash: string, type: string, iconDataURI: string) {
  if (!localAppIconCache.has(hash)) {
    const iconUrl = (await getUserAppIcon(hash, type)) ?? (await postUserAppIcon(hash, iconDataURI));

    localAppIconCache.set(hash, iconUrl);
  }

  return localAppIconCache.get(hash)!;
}
