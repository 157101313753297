import features from "../features";

// Performance radar
if (features.enabled("perfRadar")) {
  // @ts-ignore
  import("@gaearon/lag-radar").then(({ default: lagRadar }) =>
    lagRadar({
      frames: 50, // number of frames to draw, more = worse performance
      speed: 0.0017, // how fast the sweep moves (rads per ms)
      size: 280, // outer frame px
      inset: 3, // circle inset px
      parent: document.body, // DOM node to attach to
    }),
  );
}
