import type { ChannelActionTypes, InviteRequestVoiceChannel, InviteChannelResponse } from "./actions";
import type { ChannelData, Position } from "../types";
import { ChannelActions } from "./actions-enum";
import { GetCloserInvite } from ".";
import localData from "../../localStorageKeys";
import moment from "moment";
import { convertUtcOffsetToLocal } from "../../utils/dateformat";

export const channelsInitialState = {
  voiceChannelsList: [] as ChannelData[],
  invitation: undefined as Invitation | undefined,
  pendingInvitations: [] as InviteRequestVoiceChannel[],
  joining: false,
  loading: false,
  creating: false,
  laterJoins: [] as InviteChannelResponse[],
  speakingWhileMuted: false,
  restartVAD: 0,
  getCloserInvite: null as GetCloserInvite | null,
  isAutoMeetingRecordingEnabled: false,
  collapsedVoiceChannels: JSON.parse(localData.fetch("sidebar.voiceChannel.collapsedVoiceChannels")) as number[],
  isFromCustomBackground: false,
  isGetCloserWaAccepted: false,
  knocking: undefined as KnockState | undefined,
};

export type ChannelsState = typeof channelsInitialState;

export type Invitation = {
  voiceChannelId: number;
  invitationId: string;
  invitedByUserId: number;
  invitedByUserPosition: Position | undefined;
  isGetCloserInvite: boolean;
};

export type KnockState = {
  isApproved: boolean;
  voiceChannelId: number;
  isGotResponse?: boolean;
};

export type RequestTypes =
  | "silence"
  | "unsilence"
  | "mute"
  | "unmute"
  | "screen share"
  | "unmute-in-idle"
  | "camera-on-in-idle"
  | "ignore-unmute-in-idle"
  | "ignore-camera-on-in-idle";

export function channelsReducer(
  state: ChannelsState = channelsInitialState,
  action: ChannelActionTypes,
): ChannelsState {
  switch (action.type) {
    case ChannelActions.CHANNEL_CREATE:
      return { ...state, creating: true };
    case ChannelActions.CHANNEL_JOIN:
      return { ...state, joining: action.payload.joining };

    case ChannelActions.SET_CHANNELS:
      const localTimezone = moment().utcOffset();

      action.payload.map(a => {
        if (a.meetingSlots && a.meetingSlots.length > 0 && a.timezoneOffset !== localTimezone) {
          a.meetingSlots?.map(slot => {
            const { startTime, endTime } = convertUtcOffsetToLocal(
              slot.startTime,
              slot.endTime,
              a.timezoneOffset,
              true,
            );

            slot.startTime = startTime!;
            slot.endTime = endTime!;
            return slot;
          });
        }

        return a;
      });

      return { ...state, voiceChannelsList: action.payload };

    case ChannelActions.CHANNEL_CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        voiceChannelsList: [
          ...state.voiceChannelsList,
          {
            id: action.payload.voiceChannelId,
            name: action.payload.voiceChannelName,
            shortId: action.payload.voiceChannelShortId,
            listenerId: action.payload.listenerId,
            workspaceId: action.payload.workspaceId,
            textChannelId: action.payload.textChannelId,
            parentVoiceChannelId: action.payload.parentVoiceChannelId,
            isTemporary: action.payload.isTemporary,
            speechToTextLanguage: action.payload.speechToTextLanguage,
            isPrivate: action.payload.isPrivate,
            ownerUserId: action.payload.ownerUserId,
            speechToTextEnabled: action.payload.speechToTextEnabled,
            textToSpeechEnabled: action.payload.textToSpeechEnabled,
            lastMeetingAt: null,
            workspaceBackgroundId: action.payload.workspaceBackgroundId,
            guestEnabled: action.payload.guestEnabled,
            guestPassword: action.payload.guestPassword,
            listenerEnabled: action.payload.listenerEnabled,
            listenerMicEnabled: action.payload.listenerMicEnabled,
            listenerPassword: action.payload.listenerPassword,
            isAutoMeetingRecordingEnabled: action.payload.isAutoMeetingRecordingEnabled,
            isPositionLock: action.payload.isPositionLock,
            isDisableFloorAccess: action.payload.isDisableFloorAccess,
            isDefaultFloor: action.payload.isDefaultFloor,
            isEnableRectangularView: action.payload.isEnableRectangularView,
            isScheduledMeeting: action.payload.isScheduledMeeting,
            meetingParticipants: action.payload.meetingParticipants,
            meetingSlots: action.payload.meetingSlots,
            reminderInterval: action.payload.reminderInterval,
            timezoneOffset: action.payload.timezoneOffset,
            isEnableMicForMtg: action.payload.isEnableMicForMtg,
            isEnableWebCamForMtg: action.payload.isEnableWebCamForMtg,
            isDeleteAllData: action.payload.isDeleteAllData,
            showOriginalTextEnabledAsDefault: action.payload.showOriginalTextEnabledAsDefault,
          },
        ],
        loading: false,
      };
    case ChannelActions.CHANNEL_UPDATE_SUCCESS:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(c =>
          c.id === action.payload.voiceChannelId
            ? {
                ...c,
                name: action.payload.voiceChannelName ?? c.name,
                speechToTextLanguage: action.payload.speechToTextLanguage,
                isPrivate: action.payload.isPrivate,
                speechToTextEnabled: action.payload.speechToTextEnabled,
                textToSpeechEnabled: action.payload.textToSpeechEnabled,
                guestEnabled: action.payload.guestEnabled,
                guestPassword: action.payload.guestPassword,
                listenerEnabled: action.payload.listenerEnabled,
                listenerMicEnabled: action.payload.listenerMicEnabled,
                listenerPassword: action.payload.listenerPassword,
                workspaceBackgroundId: action.payload.workspaceBackgroundId,
                map: undefined,
                isAutoMeetingRecordingEnabled: action.payload.isAutoMeetingRecordingEnabled,
                isPositionLock: action.payload.isPositionLock,
                isEnableRectangularView: action.payload.isEnableRectangularView,
                isScheduledMeeting: action.payload.isScheduledMeeting,
                meetingParticipants: action.payload.meetingParticipants,
                meetingSlots: action.payload.meetingSlots,
                reminderInterval: action.payload.reminderInterval,
                timezoneOffset: action.payload.timezoneOffset,
                isEnableMicForMtg: action.payload.isEnableMicForMtg,
                isEnableWebCamForMtg: action.payload.isEnableWebCamForMtg,
                isDeleteAllData: action.payload.isDeleteAllData,
                isDisableFloorAccess: action.payload.isDisableFloorAccess,
                assignedMember: action.payload.assignedMemberIds,
                showOriginalTextEnabledAsDefault: action.payload.showOriginalTextEnabledAsDefault,
              }
            : c,
        ),
        loading: false,
      };
    case ChannelActions.CHANNEL_BACKGROUND_UPDATE_SUCCESS:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(c =>
          c.id === action.payload.voiceChannelId
            ? { ...c, workspaceBackgroundId: action.payload.workspaceBackgroundId, map: undefined }
            : c,
        ),
      };
    case ChannelActions.CHANNEL_LAST_MEETING_UPDATE:
      return {
        ...state,
        loading: false,
        voiceChannelsList: state.voiceChannelsList.map(channel =>
          channel.id === action.payload.voiceChannelId
            ? {
                ...channel,
                lastMeetingAt: action.payload.lastMeetingAt,
              }
            : channel,
        ),
      };
    case ChannelActions.CHANNEL_DELETE_SUCCESS:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.filter(c => c.id !== action.payload.voiceChannelId),
        loading: false,
      };

    case ChannelActions.VOICE_CHANNEL_INVITE_RECEIVE:
      return {
        ...state,
        invitation: {
          invitationId: action.payload.invitationId,
          voiceChannelId: action.payload.voiceChannelId,
          invitedByUserId: action.payload.invitedByUserId,
          invitedByUserPosition: action.payload.invitedByUserPosition,
          isGetCloserInvite: !!action.payload.isGetCloserInvite,
        },
      };
    case ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_ACCEPT:
    case ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_DECLINE:
      return {
        ...state,
        invitation: undefined,
      };
    case ChannelActions.VOICE_CHANNEL_INVITE_REMOVE:
    case ChannelActions.VOICE_CHANNEL_UPDATE_PENDING_INVITATIONS:
      const existingPendingInvitedChannel = state.pendingInvitations.find(
        el => el.voiceChannelId === action.payload.voiceChannelId,
      );

      if (existingPendingInvitedChannel) {
        const invitedUsersInPendingChannel = existingPendingInvitedChannel.invitedUsersIds.filter(
          el => el !== action.payload.invitedUserId,
        );

        if (invitedUsersInPendingChannel.length === 0) {
          // Invited Users are empty in pending invite channel
          return {
            ...state,
            pendingInvitations: state.pendingInvitations.filter(
              el => el.voiceChannelId !== existingPendingInvitedChannel.voiceChannelId,
            ),
            laterJoins: state.laterJoins.filter(el => el.invitedUserId !== action.payload.invitedUserId),
          };
        } else {
          // Updated pending invitedUsers
          return {
            ...state,
            pendingInvitations: state.pendingInvitations.map(el =>
              el.voiceChannelId === existingPendingInvitedChannel.voiceChannelId
                ? { ...el, invitedUsersIds: invitedUsersInPendingChannel }
                : el,
            ),
          };
        }
      }

      return state;
    case ChannelActions.VOICE_CHANNEL_INVITATION_RESPONSE:
      return {
        ...state,
        laterJoins: [
          ...state.laterJoins,
          {
            workspaceId: action.payload.workspaceId,
            voiceChannelId: action.payload.voiceChannelId,
            invitedUserId: action.payload.invitedUserId,
            isTemporary: false,
          },
        ],
      };
    case ChannelActions.VOICE_CHANNEL_INVITE_RESPONSE_LATER:
      return {
        ...state,
        invitation: {
          ...state.invitation!,
        },
      };
    case ChannelActions.RESET_LOADING_STATE:
      return { ...state, loading: false };
    case ChannelActions.VOICE_CHANNEL_INVITE_REQUEST: {
      const existingPendingInvitedChannel = state.pendingInvitations.find(
        el => el.voiceChannelId === action.payload.voiceChannelId,
      );

      if (existingPendingInvitedChannel) {
        return {
          ...state,
          pendingInvitations: state.pendingInvitations.map(el =>
            el.voiceChannelId === action.payload.voiceChannelId
              ? { ...el, invitedUsersIds: el.invitedUsersIds.concat(action.payload.invitedUsersIds) }
              : el,
          ),
        };
      } else {
        return { ...state, pendingInvitations: [...state.pendingInvitations, action.payload] };
      }
    }
    case ChannelActions.VOICE_CHANNEL_SPEAKING_WHILE_MUTED:
      return { ...state, speakingWhileMuted: action.payload.speakingWhileMuted };

    case ChannelActions.VOICE_CHANNEL_RESTART_VAD:
      return { ...state, restartVAD: action.payload.restartVAD };
    case ChannelActions.GET_CLOSER_INVITE_REQUEST:
      return { ...state, getCloserInvite: action.payload };
    case ChannelActions.CLEAR_GET_CLOSER_INVITE_REQUEST:
      return { ...state, getCloserInvite: null };
    case ChannelActions.AUTO_MEETING_RECORDING_FEATURE_AVAILED_STATUS_UPDATED:
      return {
        ...state,
        loading: false,
        voiceChannelsList: state.voiceChannelsList.map(channel =>
          channel.id === action.payload.voiceChannelId
            ? {
                ...channel,
                isAutoMeetingRecordingFeatureAvailed: action.payload.status,
              }
            : channel,
        ),
      };
    case ChannelActions.VOICE_CHANNEL_UPDATE_STT_LANGUAGE:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(channel =>
          channel.id === action.payload.voiceChannelId
            ? { ...channel, speechToTextLanguage: action.payload.speechToTextLanguage }
            : channel,
        ),
      };
    case ChannelActions.UPDATE_COLLAPSED_VOICE_CHANNEL:
      return {
        ...state,
        collapsedVoiceChannels: action.payload.newCollapsedVoiceChannels,
      };
    case ChannelActions.UPDATE_IS_FROM_CUSTOM_BACKGROUND:
      return {
        ...state,
        isFromCustomBackground: action.payload.status,
      };

    case ChannelActions.SWITCH_GET_CLOSER_WAS_ACCEPTED:
      return {
        ...state,
        isGetCloserWaAccepted: action.payload.isGetCloserWasAccepted,
      };

    case ChannelActions.SET_KNOCKER:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(v => {
          if (v.id === action.payload.voiceChannelId) {
            const currentKnocker = v.knocker;

            if (currentKnocker) {
              const knockerIds = currentKnocker.map(knocker => knocker.userId);

              if (!knockerIds.includes(action.payload.knockerId)) {
                currentKnocker.push({ userId: action.payload.knockerId, userName: action.payload.knockerName });
              }

              return { ...v, knocker: currentKnocker };
            } else {
              return { ...v, knocker: [{ userId: action.payload.knockerId, userName: action.payload.knockerName }] };
            }
          } else {
            return v;
          }
        }),
      };
    case ChannelActions.SET_KNOCKERS:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(v => {
          if (v.id === action.payload.voiceChannelId) {
            return { ...v, knocker: action.payload.knockers };
          } else {
            return v;
          }
        }),
      };
    case ChannelActions.REMOVE_KNOCKER:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(v => {
          if (v.id === action.payload.voiceChannelId) {
            const currentKnocker = v.knocker;

            if (currentKnocker) {
              const newKnocker = currentKnocker.filter(k => k.userId !== action.payload.knockerId);

              return { ...v, knocker: newKnocker };
            } else {
              return v;
            }
          } else {
            return v;
          }
        }),
      };
    case ChannelActions.SET_MY_KNOCK:
      return {
        ...state,
        knocking: action.payload,
      };
    case ChannelActions.REMOVE_MY_KNOCK:
      return {
        ...state,
        knocking: undefined,
      };

    case ChannelActions.UPDATE_KNOCK_SUBSCRIBER:
      return {
        ...state,
        voiceChannelsList: state.voiceChannelsList.map(channel => {
          if (channel.id === action.payload.voiceChannelId) {
            return { ...channel, knockSubscriberIds: action.payload.subscriberIds };
          }

          return channel;
        }),
      };

    default:
      return state;
  }
}
