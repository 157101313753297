export type {
  Size,
  Position,
  Crop,
  SelectionArea,
  Object as WorkChannelObject,
  TextObject as TextWorkChannelObject,
  ImageObject as ImageWorkChannelObject,
  ObjectType as WorkChannelObjectType,
  ObjectPermissions as WorkChannelObjectPermissions,
  PermissionType as WorkChannelObjectPermissionType,
  DeltaUpdate as WorkChannelObjectDeltaUpdate,
};

export { ObjectPermission as WorkChannelObjectPermission };

type ObjectType = "text" | "image";

enum ObjectPermission {
  None = 0x0000,
  ObjectOwner = 0x0001,
  WorkspaceManager = 0x0002,
  WorkspaceMember = 0x0004,
  All = ObjectOwner | WorkspaceManager | WorkspaceMember,
}

interface Size {
  width: number;
  height: number;
}

type Object = TextObject | ImageObject;

interface Position {
  x: number;
  y: number;
}

interface Crop {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

interface TextContent {
  text: string;
  fontFamily?: string | null;
  fontSize?: number | null;
  color?: string | null;
  isBold?: boolean;
  isUnderline?: boolean;
  isItalic?: boolean;
  isStrikethrough?: boolean;
}

interface ImageContent {
  url: string;
  objectId: number;
  baseSize?: Size;
  crop?: Crop;
}

interface TextObject extends ObjectBase<TextContent> {
  type: "text";
}

interface ImageObject extends ObjectBase<ImageContent> {
  type: "image";
}

interface ObjectBase<T> {
  type: ObjectType;
  workChannelId: number;
  id: string;
  ownerUserId: number;
  properties: ObjectProperties<T>;
  permissions: ObjectPermissions;
}

interface ObjectProperties<T> {
  content: T;
  size: Size;
  position: Position;
  rotation: number;
  order: number;
  isLocked?: boolean;
  hideFrame?: boolean;
}

type ObjectPermissions = {
  list: ObjectPermission;
  read: ObjectPermission;
  write: ObjectPermission;
  move: ObjectPermission;
  rotate: ObjectPermission;
  reorder: ObjectPermission;
  resize: ObjectPermission;
  remove: ObjectPermission;
  lock: ObjectPermission;
  changePermission: ObjectPermission;
};

type PermissionType = keyof ObjectPermissions;

type DeltaUpdate<T extends { id: string }> = { add: T } | { update: T } | { remove: T };

type SelectionArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};
