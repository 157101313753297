import { EmojiReactionsType } from "../../components/core/Emoji";
import { DEFAULT_SEARCH_MEMBER_HISTORY_COUNT } from "../../constant";
import localData from "../../localStorageKeys";
import { ListenerData, MemberData } from "../types";
import { UsersActions, UsersActionTypes } from "./actions";

export interface Member extends MemberData {
  fullScreen: boolean; // TODO: move to somewhere else
  floorIdleAutoMicMuteStatus: boolean;
  speakerActive?: boolean;
  mute?: boolean;
  videoActive?: boolean;
  screenActive?: boolean;
  identity?: string;
  isPin?: boolean;
}

export interface MemberControlStatus {
  mute: boolean;
  screenActive: boolean;
  silence: boolean;
}

export interface MemberReaction {
  userId: number;
  persistentEmoji?: EmojiReactionsType;
  emoji?: EmojiReactionsType;
}

export interface ScheduleEvent {
  provider: "Google" | "Outlook";
  id: string | number;
  start: string;
  end: string;
  updatedDate?: string;
  summary: string;
  userId: number;
  completed: boolean;
  allDayEvent: boolean;
}

export const usersInitialState = {
  users: [] as Member[],
  listeners: [] as ListenerData[],
  searchedMemberId: undefined as number | undefined,
  searchMemberFocus: 0 as number,
  searchMemberHistory: JSON.parse(localData.fetch("sidebar.searchMember.history")) as number[],
  highlightedMemberId: undefined as number | undefined,
  lastScrollPosition: undefined as number | undefined,
  emojiReactions: [] as MemberReaction[],
  offlineUsersOpenedWorkspaceIds: JSON.parse(localData.fetch("sidebar.offlineUsers.isOpened.workspaceIds")) as number[],
  scheduleEvents: undefined as ScheduleEvent[] | undefined,
  activeCalendarUserId: undefined as number | undefined,
  refreshScheduleEvents: false as boolean,
  listenerMediaStream: undefined as MediaStream | undefined,
  enableMic: false,
};

export type UsersState = typeof usersInitialState;

export function usersReducer(state: UsersState = usersInitialState, action: UsersActionTypes): UsersState {
  switch (action.type) {
    case UsersActions.SET_EMOJI_REACTION: {
      const emojiReactions = state.emojiReactions;

      if (emojiReactions.some(el => el.userId === action.payload.userId)) {
        return {
          ...state,
          emojiReactions: emojiReactions.map(el =>
            el.userId === action.payload.userId
              ? {
                  ...el,
                  persistentEmoji: action.payload.persistent
                    ? el.persistentEmoji
                      ? undefined
                      : action.payload.emoji
                    : el.persistentEmoji,
                  emoji: !action.payload.persistent ? action.payload.emoji : el.emoji,
                }
              : el,
          ),
        };
      } else {
        return {
          ...state,
          emojiReactions: [
            ...emojiReactions,
            {
              userId: action.payload.userId,
              persistentEmoji: action.payload.persistent ? action.payload.emoji : undefined,
              emoji: !action.payload.persistent ? action.payload.emoji : undefined,
            },
          ],
        };
      }
    }

    case UsersActions.RESET_EMOJI_REACTION:
      const emojiReactions = state.emojiReactions;

      if (emojiReactions.some(el => el.userId === action.payload.userId)) {
        return {
          ...state,
          emojiReactions: emojiReactions.map(el =>
            el.userId === action.payload.userId
              ? {
                  ...el,
                  persistentEmoji: action.payload.persistent ? undefined : el.persistentEmoji,
                  emoji: !action.payload.persistent ? undefined : el.emoji,
                }
              : el,
          ),
        };
      }

      return state;

    case UsersActions.SET_WORKSPACE_USERS:
      return {
        ...state,
        users: action.payload.map(u => ({
          ...u,
          fullScreen: false,
          speakerActive: false,
          floorIdleAutoMicMuteStatus: false,
        })),
      };
    case UsersActions.UPDATE_MANUAL_WORKING_MEMO_TEMP:
      return {
        ...state,
        users: state.users.map(u =>
          u.id === action.payload.userId
            ? {
                ...u,
                manualWorkingMemoTemp: action.payload.workingMemo,
              }
            : u,
        ),
      };
    case UsersActions.ADD_USER:
      return {
        ...state,
        users: [
          ...state.users,
          {
            ...action.payload,
            fullScreen: false,
            speakerActive: false,
            floorIdleAutoMicMuteStatus: false,
          },
        ],
      };
    case UsersActions.SET_ONLINE_USERS_IN_VOICECHANNEL:
      return {
        ...state,
        users: state.users.map(u =>
          action.payload.some(m => m.id === u.id)
            ? {
                ...action.payload.find(m => m.id === u.id)!,
                fullScreen: false,
                identity: u.identity,
                speakerActive: false,
                floorIdleAutoMicMuteStatus: false,
              }
            : { ...u },
        ),
      };
    case UsersActions.REMOVE_USER:
      return {
        ...state,
        users: state.users.filter(u => u.id !== action.payload.userId),
      };
    case UsersActions.UPDATE_ONLINE:
    case UsersActions.UPDATE_SPEAKER_ACTIVE:
    case UsersActions.UPDATE_CHANNEL:
    case UsersActions.UPDATE_WORKSPACE:
    case UsersActions.UPDATE_STATUS:
    case UsersActions.UPDATE_SILENCE_ACTIVE:
    case UsersActions.UPDATE_AVATAR_URL:
    case UsersActions.UPDATE_VIDEO_ACTIVE:
    case UsersActions.UPDATE_SELF_ACTIVE_WINDOW:
    case UsersActions.UPDATE_FLOOR_IDLE_AUTO_MIC_MUTE_STATUS:
    case UsersActions.UPDATE_BROADCAST_ACTIVE:
    case UsersActions.UPDATE_MIC_ACTIVE:
    case UsersActions.UPDATE_SCREEN_ACTIVE:
    case UsersActions.SET_PARTICIPANT_IDENTITY:
    case UsersActions.UPDATE_LANGUAGE_INCONSISTENCY_ALERT:
    case UsersActions.UPDATE_AUDIO_EXTRACTION:
    case UsersActions.UPDATE_WEB_FCM_TOKEN:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId) {
            return {
              ...u,
              ...action.payload,
            };
          }

          return u;
        }),
      };
    case UsersActions.UPDATE_STT_SETTING:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId) {
            return {
              ...u,
              ...action.payload,
            };
          }

          return u;
        }),
        listeners: state.listeners.map(l => {
          if (l.id === action.payload.userId) {
            return {
              ...l,
              ...action.payload,
            };
          }

          return l;
        }),
      };
    case UsersActions.UPDATE_ACTIVE_WINDOW_BATCH:
      return {
        ...state,
        users: state.users.map(u => {
          const dataUser = action.payload.find(d => d.userId === u.id);

          if (dataUser) {
            return {
              ...u,
              activeWindowIcon: dataUser.activeWindowIcon,
              activeWindowName: dataUser.activeWindowName,
            };
          }

          return u;
        }),
      };

    case UsersActions.UPDATE_ROLE:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId) {
            return {
              ...u,
              role: action.payload.role,
            };
          }

          return u;
        }),
      };

    case UsersActions.UPDATE_MEMBER_ENABLE_WEB_APP:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.user) {
            return {
              ...u,
              enableWebApp: action.payload.value,
            };
          }

          return u;
        }),
      };
    case UsersActions.UPDATE_NAME:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId) {
            return {
              ...u,
              name: action.payload.displayName,
              avatar: action.payload.avatarUrl,
            };
          }

          return u;
        }),
      };
    case UsersActions.REMOVE_USERS_FROM_CHANNEL:
      return {
        ...state,
        users: state.users.map(u =>
          u.voiceChannelId === action.payload.voiceChannelId ? { ...u, voiceChannelId: null } : u,
        ),
      };
    case UsersActions.UPDATE_NATIVE_NOTIFIATION:
      const nativeNotification = Boolean(Number(action.payload.nativeNotification));

      return {
        ...state,
        users: state.users.map(u => {
          if (u.workspaceId === action.payload.workspaceId && u.id === action.payload.userId) {
            return {
              ...u,
              nativeNotification,
            };
          }

          return u;
        }),
      };
    case UsersActions.UPDATE_WORKING_START:
      return {
        ...state,
        users: state.users.map(u =>
          u.id === action.payload.userId
            ? {
                ...u,
                workingMemo: action.payload.workingMemo,
              }
            : u,
        ),
      };

    case UsersActions.UPDATE_AVATAR_POSITION: {
      return {
        ...state,
        users: state.users.map(u =>
          u.id === action.payload.userId
            ? {
                ...u,
                position: action.payload.position,
              }
            : u,
        ),
      };
    }

    case UsersActions.UPDATE_WORKING_MEMO:
      return {
        ...state,
        users: state.users.map(u =>
          u.id === action.payload.userId
            ? {
                ...u,
                workingMemo: action.payload.workingMemo,
              }
            : u,
        ),
      };

    case UsersActions.UPDATE_USER_TIMETRACKING:
      return {
        ...state,
        users: state.users.map(u => {
          if (action.payload) {
            return {
              ...u,
              timeTracking: action.payload.value,
            };
          }

          return u;
        }),
      };

    case UsersActions.UPDATE_USER_SCREENSHOT:
      return {
        ...state,
        users: state.users.map(u => {
          if (action.payload) {
            return {
              ...u,
              enableScreenshot: action.payload.enableScreenshot,
            };
          }

          return u;
        }),
      };

    case UsersActions.UPDATE_USERS_ASSIGNED_FLOORS:
      return {
        ...state,
        users: state.users.map(u => ({
          ...u,
          assignedFloors: action.payload.floors.filter(f => f.members.some(m => m === u.id)).map(f => f.id),
        })),
      };
    case UsersActions.UPDATE_USERS_SELECTED_PROJECT:
      const project = action.payload.projectName
        ? {
            id: action.payload.projectId,
            name: action.payload.projectName,
          }
        : undefined;

      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId && u.workspaceId === action.payload.workspaceId) {
            return {
              ...u,
              projectId: action.payload.projectId,
              project: project || u.project,
            };
          }

          return u;
        }),
      };
    case UsersActions.UPDATE_SEARCHED_MEMBER:
      return {
        ...state,
        searchedMemberId: action.payload.memberId,
      };
    case UsersActions.INCREASE_SEARCH_MEMBER_FOCUS:
      return {
        ...state,
        searchMemberFocus: state.searchMemberFocus + 1,
      };
    case UsersActions.UPDATE_HIGHLIGHTED_MEMBER:
      return {
        ...state,
        highlightedMemberId: action.payload.memberId,
      };
    case UsersActions.UPDATE_LAST_SCROLL_POSITION:
      return {
        ...state,
        lastScrollPosition: action.payload.scrollPosition,
      };
    case UsersActions.ADD_TO_SEARCH_MEMBER_HISTORY:
      return {
        ...state,
        searchMemberHistory:
          state.searchMemberHistory.length > 0
            ? [
                action.payload.memberId,
                ...state.searchMemberHistory.filter((el: number) => el !== action.payload.memberId),
              ].slice(0, DEFAULT_SEARCH_MEMBER_HISTORY_COUNT)
            : [action.payload.memberId],
      };
    case UsersActions.UPDATE_OFFLINE_USERS_OPENED_STATUS:
      return {
        ...state,
        offlineUsersOpenedWorkspaceIds: action.payload.openedWorkspaceIds,
      };
    case UsersActions.RESET_USER_SELECTED_PROJECT:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId && u.workspaceId === action.payload.workspaceId) {
            return {
              ...u,
              projectId: null,
              project: undefined,
            };
          }

          return u;
        }),
      };
    case UsersActions.SYNC_CALENDAR_WORKING_MEMO:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId) {
            return {
              ...u,
              syncCalendar: action.payload.syncCalendar,
            };
          }

          return u;
        }),
      };
    case UsersActions.UPDATE_OUTLOOK_CALENDAR_STATUS:
    case UsersActions.UPDATE_GOOGLE_CALENDAR_STATUS:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.id === action.payload.userId && u.workspaceId === action.payload.workspaceId) {
            return {
              ...u,
              ...action.payload,
            };
          }

          return u;
        }),
      };

    case UsersActions.CLEANUP_ALL_SPEAKER_ACTIVE:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.workspaceId === action.payload.workspaceId && u.speakerActive) {
            return {
              ...u,
              speakerActive: false,
            };
          }

          return u;
        }),
      };

    case UsersActions.UPDATE_CALENDAR_EVENTS:
      return {
        ...state,
        scheduleEvents: action.payload.scheduleEvents,
        activeCalendarUserId: action.payload.userId,
      };
    case UsersActions.REFRESH_CALENDAR_EVENTS:
      return {
        ...state,
        refreshScheduleEvents: action.payload.refreshScheduleEvents,
      };
    case UsersActions.USER_GOOGLE_INTEGRATION_UPDATE:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.workspaceId === action.payload.workspaceId && u.id === action.payload.userId) {
            return {
              ...u,
              connectedGoogle: action.payload.connectedGoogle,
            };
          }

          return u;
        }),
      };
    case UsersActions.USER_AZURE_INTEGRATION_UPDATE:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.workspaceId === action.payload.workspaceId && u.id === action.payload.userId) {
            return {
              ...u,
              connectedAzure: action.payload.connectedAzure,
            };
          }

          return u;
        }),
      };
    case UsersActions.USER_HAS_MOBILE_APP_UPDATE:
      return {
        ...state,
        users: state.users.map(u => {
          if (u.workspaceId === action.payload.workspaceId && u.id === action.payload.userId) {
            return {
              ...u,
              hasMobileApp: action.payload.hasMobileApp,
            };
          }

          return u;
        }),
      };
    case UsersActions.SET_WORKSPACE_LISTENERS:
      return {
        ...state,
        listeners: action.payload,
      };
    case UsersActions.ADD_LISTENER:
      return {
        ...state,
        listeners: [
          ...state.listeners,
          {
            ...action.payload,
          },
        ],
      };
    case UsersActions.UPDATE_LISTENER:
      return {
        ...state,
        listeners: state.listeners.map(l => {
          if (l.id === action.payload.id) {
            return action.payload;
          } else {
            return l;
          }
        }),
      };
    case UsersActions.REMOVE_LISTENER:
      return {
        ...state,
        listeners: state.listeners.map(l => {
          if (l.id === action.payload.userId) {
            return {
              ...l,
              online: false,
            };
          }

          return l;
        }),
      };
    case UsersActions.REMOVE_LISTENERS_FROM_VOICE_CHANNEL:
      return {
        ...state,
        listeners: state.listeners.map(l => {
          if (l.voiceChannelId === action.payload.voiceChannelId && l.joinShortId === action.payload.joinShortId) {
            return {
              ...l,
              online: false,
            };
          }

          return l;
        }),
      };
    case UsersActions.REMOVE_LISTENERS_FROM_SHARING:
      return {
        ...state,
        listeners: state.listeners.map(l => {
          if (l.joinShortId === action.payload.myShortId) {
            return {
              ...l,
              online: false,
            };
          }

          return l;
        }),
      };
    case UsersActions.SET_LISTENER_MEDIA_STREAM:
      return {
        ...state,
        listenerMediaStream: action.payload.listenerMediaStream,
      };
    case UsersActions.SET_ENABLE_TEXT_CHAT_MIC:
      return {
        ...state,
        enableMic: action.payload.enableMic,
      };

    default:
      return state;
  }
}
