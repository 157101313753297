import { Action, Dispatch } from "redux";
import { AppThunk, Region } from "../types";
import { createRequest } from "../../utils/network";
import { GET_REGIONS_LIST } from "../../constant";

export enum RegionsActions {
  GET_REGIONS = "region/GET_REGIONS",
  GOT_REGIONS = "region/GOT_REGIONS",
}

export interface GetRegionsAction extends Action {
  type: RegionsActions.GET_REGIONS;
  payload: undefined;
}

export interface GotRegions {
  readonly regions: Region[];
}

interface GotRegionsAction extends Action {
  readonly type: RegionsActions.GOT_REGIONS;
  readonly payload: GotRegions;
}

export type RegionsActionTypes = GetRegionsAction | GotRegionsAction;

function gotRegionsAction(payload: GotRegions): GotRegionsAction {
  return { type: RegionsActions.GOT_REGIONS, payload };
}

export function gotRegions(regions: Region[]): AppThunk {
  return (dispatch: Dispatch) => {
    dispatch(gotRegionsAction({ regions }));
  };
}

export async function getRegions() {
  const { response } = await createRequest(GET_REGIONS_LIST);

  if (response.success) {
    const regions = response.data;

    return regions;
  }
}
