import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row, Typography } from "antd";
import "./NoPermission.scss";
import { ReportIcon } from "../../components/icons/material";

const { Title } = Typography;

function NoPermission() {
  return (
    <Row align="middle" justify="center" className="no-permission-error-page">
      <Col span={12}>
        <Row align="middle" justify="center" gutter={[500, 25]}>
          <Col offset={1}>
            <ReportIcon />
            <Title>
              <FormattedMessage id="no-permission/title" defaultMessage="Permission Denied" />
            </Title>
          </Col>
          <Col offset={1}>
            <FormattedMessage
              id="no-permission"
              defaultMessage="Sorry, you don’t have permission to visit this page.<lineBreak></lineBreak>Please contact the admin to get more information."
              values={{
                lineBreak: () => {
                  return <br />;
                },
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default NoPermission;
