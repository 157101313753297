import { intl } from "../i18n";
import { ChannelData } from "../store/types";
import type { Member } from "../store/users/reducer";
import { getCurrentEnvironment } from "../utils/environment";

import { sendElectronNotification } from "./sendNotification";

export enum NotificationCategory {
  TimeTrackerStart,
  TimeTrackerStop,
  IncomingCall,
  OfflineTimeTracking,
  WorkspaceEvents,
  MeetingRecordingEvent,
  MeetingReminder,
  ScreenshotNotification,
  TimeTrackerLimit,
}
export type WorkspaceEventType = "Join";
export type MeetingRecordingEventType = "start" | "stop" | "error";

export function notifyOnElectron(
  category: NotificationCategory.IncomingCall,
  channel: ChannelData,
  member: Member,
  isGetCloserInvite?: boolean,
): void;
export function notifyOnElectron(category: NotificationCategory.TimeTrackerStart): void;
export function notifyOnElectron(category: NotificationCategory.TimeTrackerStop): void;
export function notifyOnElectron(category: NotificationCategory.ScreenshotNotification): void;
export function notifyOnElectron(category: NotificationCategory.OfflineTimeTracking): void;
export function notifyOnElectron(
  category: NotificationCategory.WorkspaceEvents,
  eventType: WorkspaceEventType,
  memberName: string,
): void;
export function notifyOnElectron(
  category: NotificationCategory.MeetingRecordingEvent,
  eventType: MeetingRecordingEventType,
  meetingRoomName: string,
): void;
export function notifyOnElectron(
  category: NotificationCategory.MeetingReminder,
  voiceChannelName: string,
  reminderInterval?: number,
): void;
export function notifyOnElectron(category: NotificationCategory.TimeTrackerLimit, message: string): void;
export function notifyOnElectron(category: NotificationCategory, ...params: any[]) {
  const curEnv = getCurrentEnvironment();

  const title = curEnv === "production" ? "VoicePing" : "VoicePing-Dev";

  switch (category) {
    case NotificationCategory.TimeTrackerStop:
      sendElectronNotification({
        title: title,
        body: intl.formatMessage({
          id: "working-timer-stop/body",
          defaultMessage: "Time tracking stopped",
        }),
      });
      break;
    case NotificationCategory.TimeTrackerStart:
      sendElectronNotification({
        title: title,
        body: intl.formatMessage({
          id: "working-timer-start/body",
          defaultMessage: "Time tracking started",
        }),
      });
      break;
    case NotificationCategory.ScreenshotNotification:
      sendElectronNotification({
        title: title,
        body: intl.formatMessage({
          id: "screenshot-taken/successful-message",
          defaultMessage: "Screenshot Taken",
        }),
      });
      break;
    case NotificationCategory.MeetingReminder:
      const channelName = params[0] as string;
      const reminderInterval = params[1] as number;

      sendElectronNotification({
        title: intl.formatMessage(
          {
            id: "meeting-reminder/subtitle",
            defaultMessage: "{channelName} is about to start in {reminderInterval} minutes.",
          },
          {
            channelName,
            reminderInterval,
          },
        ),
      });

      break;
    case NotificationCategory.IncomingCall:
      const channel = params[0] as ChannelData;
      const user = params[1] as Member;
      const isGetCloserInvite = params[2] as boolean;

      sendElectronNotification({
        title: isGetCloserInvite
          ? intl.formatMessage({
              id: "invited-get-closer/title",
              defaultMessage: "Incoming request",
            })
          : channel.parentVoiceChannelId
          ? intl.formatMessage({
              id: "invited-meeting-room/title",
              defaultMessage: "Incoming request",
            })
          : intl.formatMessage({
              id: "invited-floor/title",
              defaultMessage: "Incoming request",
            }),
        body: isGetCloserInvite
          ? intl.formatMessage(
              {
                id: "invited-get-closer/subtitle",
                defaultMessage: "{user} is inviting you to get close.",
              },
              { user: user.name },
            )
          : channel.parentVoiceChannelId
          ? intl.formatMessage(
              {
                id: "invited-meeting-room/subtitle",
                defaultMessage: "{user} is inviting you to join {meetingRoom} meeting room",
              },
              {
                user: user.name,
                meetingRoom: channel.name,
              },
            )
          : intl.formatMessage(
              {
                id: "invited-floor/subtitle",
                defaultMessage: "{user} is inviting you to join {floor} floor",
              },
              {
                user: user.name,
                floor: channel.name,
              },
            ),
      });

      break;
    case NotificationCategory.OfflineTimeTracking:
      sendElectronNotification({
        title: title,
        body: intl.formatMessage({
          id: "working-timer-offline/body",
          defaultMessage: "Connection lost. We are still tracking time.",
        }),
      });
      break;

    case NotificationCategory.WorkspaceEvents:
      const userName = params[1];

      switch (params[0] as WorkspaceEventType) {
        case "Join":
          sendElectronNotification({
            title: intl.formatMessage({
              id: "user/join-title",
              defaultMessage: "A new user just joined your workspace!",
            }),
            body: intl.formatMessage(
              {
                id: "user/join-body",
                defaultMessage: "{user} just joined. Say Hi!",
              },
              {
                user: userName,
              },
            ),
          });
          break;
      }
      break;
    case NotificationCategory.MeetingRecordingEvent:
      switch (params[0] as MeetingRecordingEventType) {
        case "start":
          sendElectronNotification({
            title: params[1] as string,
            body: intl.formatMessage({
              id: "notification/recording-started",
              defaultMessage: "Meeting Recording started!",
            }),
          });
          break;
        case "stop":
          sendElectronNotification({
            title: params[1] as string,
            body: intl.formatMessage({
              id: "notification/recording-stopped",
              defaultMessage: "Meeting Recording Stopped",
            }),
          });
          break;
        case "error":
          sendElectronNotification({
            title: params[1] as string,
            body: intl.formatMessage({
              id: "notification/error",
              defaultMessage: "Not able to proceed your request, please try again!",
            }),
          });
          break;
      }
      break;
    case NotificationCategory.TimeTrackerLimit:
      const message = params[0] as string;

      sendElectronNotification({
        title: message,
      });

      break;
  }
}
