import { sendMessage } from "../socket";
import { AppThunk, LivekitParticipant } from "../types";
import { getMyMember } from "../users";
import { getAllVoiceChannels, removeKnockerAction } from "../voiceChannels";
import { CHANNEL_EVENT_TYPES } from "../voiceChannels/actions-enum";
import { ConnectionStatus } from "./reducer";

export enum LivekitActions {
  CONNECT_LIVEKIT_ROOM = "CONNECT_LIVEKIT_ROOM",
  DISCONNECT_LIVEKIT_ROOM = "DISCONNECT_LIVEKIT_ROOM",
  SUBSCRIBE = "SUBSCRIBE",
  UNSUBSCRIBE = "UNSUBSCRIBE",
  SUBSCRIBE_BROADCAST = "SUBSCRIBE_BROADCAST",
  UNSUBSCRIBE_BROADCAST = "UNSUBSCRIBE_BROADCAST",
  ENABLE_MIC = "ENABLE_MIC",
  DISABLE_MIC = "DISABLE_MIC",
  ENABLE_MUTE = "ENABLE_MUTE",
  DISABLE_MUTE = "DISABLE_MUTE",
  ENABLE_VIDEO = "ENABLE_VIDEO",
  DISABLE_VIDEO = "DISABLE_VIDEO",
  ENABLE_SCREENSHARE = "ENABLE_SCREENSHARE",
  UPDATE_SCREENSHARE_CONSTRAINTS = "UPDATE_SCREENSHARE_CONSTRAINTS",
  DISABLE_SCREENSHARE = "DISABLE_SCREENSHARE",
  SWITCH_DEVICE = "SWITCH_DEVICE",
  UPDATE_NOISE_REDUCTION = "UPDATE_NOISE_REDUCTION",
  CONNECTION_STATUS = "CONNECTION_STATUS",
  LIVEKIT_MIC_LOADING = "LIVEKIT_MIC_LOADING",
  LIVEKIT_VIDEO_LOADING = "LIVEKIT_VIDEO_LOADING",
  LIVEKIT_SCREENSHARE_LOADING = "LIVEKIT_SCREENSHARE_LOADING",
  SET_AUDIO_PERMISSION = "SET_AUDIO_PERMISSION",
  LIVEKIT_LOADING = "LIVEKIT_LOADING",
  SET_PARTICIPANTS = "SET_PARTICIPANTS",
  REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT",
  SET_PARTICIPANT = "SET_PARTICIPANT",
  CLEAR_PARTICIPANTS = "CLEAR_PARTICIPANTS",
  UPDATE_SUBSCRIBERS = "UPDATE_SUBSCRIBERS",
  UPDATE_BROADCASTERS = "UPDATE_BROADCASTERS",
  ENABLE_EGRESS = "ENABLE_EGRESS",
  DISABLE_EGRESS = "DISABLE_EGRESS",
}

export const connectRoom = ({
  url,
  token,
  autoSubscribe,
  noiseReduction,
  iceTransportPolicy,
}: {
  url: string;
  token: string;
  autoSubscribe: boolean;
  noiseReduction?: boolean;
  iceTransportPolicy: boolean;
}) => {
  return {
    type: LivekitActions.CONNECT_LIVEKIT_ROOM,
    payload: { url, token, autoSubscribe, noiseReduction, iceTransportPolicy },
  };
};

export function disconnectRoom(force?: boolean) {
  return {
    type: LivekitActions.DISCONNECT_LIVEKIT_ROOM,
    payload: { force },
  };
}

export const subscribe = (identity: string) => {
  return {
    type: LivekitActions.SUBSCRIBE,
    payload: { identity },
  } as const;
};

export const unsubscribe = (identity: string) => {
  return {
    type: LivekitActions.UNSUBSCRIBE,
    payload: { identity },
  } as const;
};

export const subscribeBroadcaster = (identity: string) => {
  return {
    type: LivekitActions.SUBSCRIBE_BROADCAST,
    payload: { identity },
  } as const;
};

export const unsubscribeBroadcaster = (identity: string) => {
  return {
    type: LivekitActions.UNSUBSCRIBE_BROADCAST,
    payload: { identity },
  } as const;
};

export const enableMic = () => {
  return {
    type: LivekitActions.ENABLE_MIC,
  };
};

export const enableMute = () => {
  return {
    type: LivekitActions.ENABLE_MUTE,
  };
};

export const disableMute = () => {
  return {
    type: LivekitActions.DISABLE_MUTE,
  };
};

export const enableVideo = (stream: MediaStream) => {
  return {
    type: LivekitActions.ENABLE_VIDEO,
    payload: { stream },
  };
};

export const disableVideo = () => {
  return {
    type: LivekitActions.DISABLE_VIDEO,
  };
};

export const enableScreenshare = (sourceId?: string) => {
  return {
    type: LivekitActions.ENABLE_SCREENSHARE,
    payload: { sourceId },
  };
};

export const updateScreenshareConstraints = (resolution: string) => {
  return {
    type: LivekitActions.UPDATE_SCREENSHARE_CONSTRAINTS,
    payload: { resolution },
  };
};

export const disableScreenshare = () => {
  return {
    type: LivekitActions.DISABLE_SCREENSHARE,
  };
};

export const switchDevice = () => {
  return { type: LivekitActions.SWITCH_DEVICE };
};

export const updateNoiseReduction = (noiseReduction: boolean) => {
  return {
    type: LivekitActions.UPDATE_NOISE_REDUCTION,
    payload: { noiseReduction },
  };
};

export type LivekitRequestsActionTypes =
  | ReturnType<typeof setRoomConnection>
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setMicLoading>
  | ReturnType<typeof setVideoLoading>
  | ReturnType<typeof setScreenshareLoading>
  | ReturnType<typeof setParticipants>
  | ReturnType<typeof setParticipant>
  | ReturnType<typeof removeParticipant>
  | ReturnType<typeof clearParticipants>
  | ReturnType<typeof updateSubscribers>
  | ReturnType<typeof updateBroadcasters>
  | ReturnType<typeof setAudioPermission>;

export function setAudioPermission(hasAudioPermission: boolean) {
  return {
    type: LivekitActions.SET_AUDIO_PERMISSION,
    payload: { hasAudioPermission },
  } as const;
}

export function setLoading(loading: boolean) {
  return {
    type: LivekitActions.LIVEKIT_LOADING,
    payload: { loading },
  } as const;
}

export function setScreenshareLoading(loading: boolean) {
  return {
    type: LivekitActions.LIVEKIT_SCREENSHARE_LOADING,
    payload: { loading },
  } as const;
}

export function setMicLoading(loading: boolean) {
  return {
    type: LivekitActions.LIVEKIT_MIC_LOADING,
    payload: { loading },
  } as const;
}

export function setVideoLoading(loading: boolean) {
  return {
    type: LivekitActions.LIVEKIT_VIDEO_LOADING,
    payload: { loading },
  } as const;
}

export function setParticipants(participants: LivekitParticipant[]) {
  return {
    type: LivekitActions.SET_PARTICIPANTS,
    payload: { participants },
  } as const;
}

export function setParticipant(participant: LivekitParticipant) {
  return {
    type: LivekitActions.SET_PARTICIPANT,
    payload: { participant },
  } as const;
}

export function removeParticipant(identity: string) {
  return {
    type: LivekitActions.REMOVE_PARTICIPANT,
    payload: { identity },
  } as const;
}

export function clearParticipants() {
  return {
    type: LivekitActions.CLEAR_PARTICIPANTS,
  } as const;
}

export function setRoomConnection(connectionStatus: ConnectionStatus, voiceChannelShortId?: string) {
  return {
    type: LivekitActions.CONNECTION_STATUS,
    payload: { voiceChannelShortId, connectionStatus },
  } as const;
}

export function setRoomConneted(voiceChannelShortId: string): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const voiceChannels = getAllVoiceChannels(getState());
    const voiceChannel = voiceChannels.find(el => el.shortId === voiceChannelShortId);

    if (me?.role === "meeting-recording-bot") {
      dispatch(setAudioPermission(true));
    }

    if (voiceChannel) {
      dispatch(
        sendMessage(CHANNEL_EVENT_TYPES.CHANNEL_JOIN, {
          workspaceId: voiceChannel!.workspaceId,
          voiceChannelId: voiceChannel!.id,
        }),
      );
      dispatch(setRoomConnection("connected", voiceChannelShortId));
      if (voiceChannel.knocker && me) {
        dispatch(removeKnockerAction({ voiceChannelId: voiceChannel.id, knockerId: me.id }));
      }
    }
  };
}

export function updateSubscribers(identities: string[]) {
  return {
    type: LivekitActions.UPDATE_SUBSCRIBERS,
    payload: { identities },
  } as const;
}

export function updateBroadcasters(identities: string[]) {
  return {
    type: LivekitActions.UPDATE_BROADCASTERS,
    payload: { identities },
  } as const;
}

export function enableEgress() {
  return {
    type: LivekitActions.ENABLE_EGRESS,
  } as const;
}
