import { Action } from "redux";
import { AppThunk } from "../types";
import { showModal } from "../../screens/Dashboard/state";
import { ELECTRON_SCREEN_SELECTOR_MODAL_ID } from "../../screens/Dashboard/constants";
import {
  getPermissionStateScreenShare,
  getPipDefaultScreenshareWindowStatus,
  loadPermission,
  showRequiredScreenCapturePermissionModal,
} from "../app";
import { getDeviceInfo } from "../../utils/helpers";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { channels } from "../../electron/channels";
import { removeNotificationOnPIP } from "../notificationsWindow";
import { checkIsGuest } from "../account";
import { stopControllingMyDesktopRequest } from "../remoteDesktopControl";
import * as ipc from "../../electron/ipc";
import { startWorkingRequest } from "../timeTracker/actions";
import { getMyMember } from "../users";
import {
  disableScreenshare,
  enableScreenshare,
  getLivekitConnectionStatus,
  getScreenshareLivekitLoading,
  setScreenshareLoading,
} from "../livekit";
import { PRIMARY_WINDOW_ID } from "../../constant";
import { getIsLoadingRecordAudioExtraction } from "../audioExtraction";
const { os } = getDeviceInfo();

export enum ShareActions {
  START_SHARE = "share/START_SHARE",
  STOP_SHARE = "share/STOP_SHARE",
  SET_PIP = "share/SET_PIP",
  HANDLE_VIEWER = "share/HANDLE_VIEWER",
  VIEWER_DISCONNECTED = "share/VIEWER_DISCONNECTED",
  HANDLE_VIEWERS_LIST = "share/HANDLE_VIEWERS_LIST",
  UPDATE_SCREEN_OVERLAY_DRAWING_STATUS = "share/UPDATE_SCREEN_OVERLAY_DRAWING_STATUS",
}

export interface ScreenInfo {
  sourceId: string;
  sourceName?: string;
  screenIndex?: number;
}

export interface Viewer {
  userId: number;
  activeWindowName: string;
}

export interface ViewerList {
  viewers: { avatarUrl: string; userId: number }[];
}

interface StartSaveAction extends Action {
  readonly type: ShareActions.START_SHARE;
  readonly payload: ScreenInfo;
}

interface StartClearAction extends Action {
  readonly type: ShareActions.STOP_SHARE;
}

export interface SetScreenSharePiPPreferenceAction extends Action {
  type: ShareActions.SET_PIP;
  payload: { defaultPiP: boolean };
}

interface HandleViewerAction extends Action {
  type: ShareActions.HANDLE_VIEWER;
  payload: Viewer;
}

interface HandleViewersListAction extends Action {
  type: ShareActions.HANDLE_VIEWERS_LIST;
  payload: { viewers: number[] };
}

interface ViewerDisconnectedAction extends Action {
  type: ShareActions.VIEWER_DISCONNECTED;
  payload: { userId: number };
}

export type ShareActionTypes =
  | StartSaveAction
  | StartClearAction
  | SetScreenSharePiPPreferenceAction
  | HandleViewerAction
  | ViewerDisconnectedAction
  | HandleViewersListAction;

export function handleViewer(payload: Viewer): HandleViewerAction {
  return {
    type: ShareActions.HANDLE_VIEWER,
    payload,
  };
}

export function handleViewersList(viewers: number[]): HandleViewersListAction {
  return {
    type: ShareActions.HANDLE_VIEWERS_LIST,
    payload: { viewers },
  };
}

function saveShareAction(payload: ScreenInfo): StartSaveAction {
  return {
    type: ShareActions.START_SHARE,
    payload,
  };
}

export function saveSharedScreenInfo(payload: ScreenInfo): AppThunk {
  return dispatch => {
    dispatch(saveShareAction(payload));
  };
}

function clearShareAction(): StartClearAction {
  return {
    type: ShareActions.STOP_SHARE,
  };
}

export function tryStartingScreenShare(): AppThunk {
  return (dispatch, getState) => {
    const livekitConnection = getLivekitConnectionStatus(getState());

    if (livekitConnection === "connected") {
      dispatch(doStartScreenShare());
    }
  };
}

export function doStopScreenShare(): AppThunk {
  return (dispatch, getState) => {
    const livekitScreenshareLoading = getScreenshareLivekitLoading(getState());

    if (livekitScreenshareLoading) return;

    dispatch(clearShareAction());
    dispatch(disableScreenshare());
    dispatch(removeNotificationOnPIP({ type: "screen-share-request" }));
    sendMessageOverIPC(channels.HIDE_STOP_SHARING_WINDOW);
    dispatch(stopControllingMyDesktopRequest());
    sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "screenshare-startsharing-request");
  };
}

export function doStartScreenShare(isRequestFromPiP?: boolean): AppThunk {
  return async (dispatch: Function, getState) => {
    console.log("doStartScreenShare");

    const isGuest = checkIsGuest(getState());
    const isPipDefaultScreenshareWindowDisabled = getPipDefaultScreenshareWindowStatus(getState());
    // const livekitScreenshareLoading = getScreenshareLivekitLoading(getState());

    // console.log(livekitScreenshareLoading)

    // if (livekitScreenshareLoading) return;

    dispatch(removeNotificationOnPIP({ type: "screen-share-request" }));
    sendMessageOverIPC(channels.CLOSE_NOTIFICATION, "screenshare-startsharing-request");

    if (window.electron || isGuest) {
      await dispatch(loadPermission("screenshare"));
      const permissionGranted = getPermissionStateScreenShare(getState());

      if (!permissionGranted && os === "macos") {
        dispatch(showRequiredScreenCapturePermissionModal());
        isRequestFromPiP && sendMessageOverIPC(channels.SHOW_APP);
        return;
      }

      if (window.electron) {
        if (isRequestFromPiP && !isPipDefaultScreenshareWindowDisabled) {
          const sharingOptionsList = await ipc.getScreenWindowSharingOptions();
          const entirScreen = sharingOptionsList.sort((a, b) => a.id.localeCompare(b.id))[0];

          dispatch(selectScreenWindowToShareFromElectron(entirScreen.id, "", 0, isRequestFromPiP));
        } else {
          if (isRequestFromPiP) {
            sendMessageOverIPC(channels.SHOW_APP);
          }

          dispatch(
            showModal({
              id: ELECTRON_SCREEN_SELECTOR_MODAL_ID,
              show: true,
            }),
          );
        }

        return;
      }
    }

    dispatch(setScreenshareLoading(true));
    dispatch(enableScreenshare());
  };
}

export function doStartTimeTracking(): AppThunk {
  return async (dispatch: Function, getState) => {
    const me = getMyMember(getState());

    if (me?.enableScreenshot) {
      await dispatch(loadPermission("screenshare"));
      const permissionGranted = getPermissionStateScreenShare(getState());

      if (permissionGranted) {
        dispatch(startWorkingRequest());
      } else if (os === "macos") {
        dispatch(showRequiredScreenCapturePermissionModal());
        return;
      }
    } else {
      dispatch(startWorkingRequest());
    }
  };
}

export function selectScreenWindowToShareFromElectron(
  sourceId: string,
  sourceName: string,
  screenIndex: number,
  isRequestFromPiP: boolean,
): AppThunk {
  return (dispatch, getState) => {
    const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(getState());
    const livekitConnection = getLivekitConnectionStatus(getState());

    dispatch(showModal({ id: ELECTRON_SCREEN_SELECTOR_MODAL_ID, show: false }));
    dispatch(setScreenshareLoading(true));

    if (livekitConnection === "connected") {
      dispatch(enableScreenshare(sourceId));
    }

    dispatch(saveSharedScreenInfo({ sourceId, sourceName, screenIndex }));

    //Close main desktop app when entire screen is shared
    if (screenIndex === 0 && !isLoadingRecordAudioExtraction) {
      if (os === "macos") {
        ipc.closeElectronWindow(PRIMARY_WINDOW_ID, isRequestFromPiP, false);
      } else {
        if (!isRequestFromPiP) {
          sendMessageOverIPC(channels.MINIMIZE_WINDOW);
        }
      }
    }
  };
}

export function showStopSharingWindow(): AppThunk {
  return () => {
    sendMessageOverIPC(channels.SHOW_STOP_SHARING_WINDOW);
  };
}

export function handleScreenShareAttempt(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!!me?.screenActive) {
      // If you are sharing the screen now, disable to share screen.
      dispatch(doStopScreenShare());
    } else {
      dispatch(tryStartingScreenShare());
    }
  };
}

export function showScreenOverlayWindow(): AppThunk {
  return () => {
    sendMessageOverIPC(channels.SHOW_SCREENSHARE_OVERLAY_WINDOW);
  };
}

export function hideScreenOverlayWindow(): AppThunk {
  return dispatch => {
    sendMessageOverIPC(channels.HIDE_SCREENSHARE_OVERLAY_WINDOW);
  };
}

export function viewerDisconnected(userId: number): ViewerDisconnectedAction {
  return {
    type: ShareActions.VIEWER_DISCONNECTED,
    payload: { userId },
  };
}
