import { appInitialState } from "./app";
import { devicesInitialState } from "./devices";
import { appNotificationInitialState } from "./appNotification/reducer";
import { backgroundChecksInitialState } from "./backgroundChecks/state";
import { demoWorkspaceInitialState } from "./demoWorkspace/reducer";
import { versionInitialState } from "./version";
import { accountInitialState } from "./account/reducer";
import { socketInitialState } from "./socket";
import { workspaceInitialState } from "./workspace";
import { channelsInitialState } from "./voiceChannels";
import { textChannelInitialState } from "./textChannel";
import { shareInitialState } from "./screenshare";
import { usersInitialState } from "./users";
import { dashboardInitialState } from "../screens/Dashboard/state";
import { timeTrackerInitialState } from "./timeTracker/reducer";
import { resetPasswordInitialState } from "./resetPassword";
import { invitationJoinInitialState } from "./invitationJoin";
import { regionsInitialState } from "./region";
import { virtualOfficeInitialState } from "./virtualOffice/reducer";
import { notificationsWindowInitialState } from "./notificationsWindow";
import { workspaceBackgroundInitialState } from "./workspaceBackground";
import { memberInitialState } from "./member";
import { meetinRecordingInitialState } from "./MeetingRecording";
import { remoteDesktopControlInitialState } from "./remoteDesktopControl";
import { workChannelInitialState } from "./workChannel";
import { livekitInitialState } from "./livekit";
import { audioExtractionInitialState } from "./audioExtraction";
import { paymentInitialState } from "./payment/reducer";

export const initialState = {
  app: appInitialState,
  devices: devicesInitialState,
  appNotification: appNotificationInitialState,
  backgroundChecks: backgroundChecksInitialState,
  demoWorkspace: demoWorkspaceInitialState,
  version: versionInitialState,
  account: accountInitialState,
  users: usersInitialState,
  socket: socketInitialState,
  workspaces: workspaceInitialState,
  voiceChannels: channelsInitialState,
  textChannel: textChannelInitialState,
  regions: regionsInitialState,
  dashboard: dashboardInitialState,
  screenshare: shareInitialState,
  timeTracker: timeTrackerInitialState,
  resetPassword: resetPasswordInitialState,
  invitationJoin: invitationJoinInitialState,
  virtualOffice: virtualOfficeInitialState,
  notificationsWindow: notificationsWindowInitialState,
  workspaceBackground: workspaceBackgroundInitialState,
  member: memberInitialState,
  meetingRecordings: meetinRecordingInitialState,
  remoteDesktopControl: remoteDesktopControlInitialState,
  workChannel: workChannelInitialState,
  livekit: livekitInitialState,
  audioExtraction: audioExtractionInitialState,
  payment: paymentInitialState,
};

export type State = typeof initialState;
