export function getCurrentEnvironment() {
  const { hostname } = window.location;

  if (hostname.includes("voice-ping-staging3-web-app") && hostname.endsWith(".web.app")) {
    return "firebase";
  } else if (hostname === "app.voice-ping.com") {
    return "production";
  } else if (hostname === "dev.voice-ping.com") {
    //added cloudfront URL just for testing purpose, can be removed later
    return "development";
  } else if (hostname === "localhost" || hostname.endsWith(".ngrok.io") || hostname.startsWith("192.168")) {
    return "local";
  } else {
    throw new Error("unknown environment");
  }
}

export function getProtocol() {
  return getCurrentEnvironment() === "local" ? "http://" : "https://";
}

export type Environment = ReturnType<typeof getCurrentEnvironment>;
