import { State } from "../state";
import { RegionsState } from "./reducer";
import { createSelector } from "reselect";
import { getLanguage } from "../account/selectors";
import { Language } from "../../i18n";
import { getCurrentWorkspace } from "../workspace/selectors";

export function getMediaServers(state: State): RegionsState {
  return state.regions;
}

export const getEnglishRegionOptions = createSelector(getMediaServers, (regions: RegionsState) => {
  const regionOptions: { label: string; value: string }[] = [];

  regions.forEach(mediaserver => {
    regionOptions.push({
      label: mediaserver.enName,
      value: mediaserver.id.toString(),
    });
  });

  return regionOptions;
});

export const getJapaneseRegionOptions = createSelector(getMediaServers, (regions: RegionsState) => {
  const regionOptions: { label: string; value: string }[] = [];

  regions.forEach(mediaserver => {
    regionOptions.push({
      label: mediaserver.jaName,
      value: mediaserver.id.toString(),
    });
  });

  return regionOptions;
});

export const getCurrentRegionBasedonLanguage = createSelector(
  getLanguage,
  getJapaneseRegionOptions,
  getEnglishRegionOptions,
  (language: Language, jaRegionOptions, enRegionOptions) => {
    const regionOptions = language === "ja" ? jaRegionOptions : enRegionOptions;

    return regionOptions;
  },
);

export const getCurrentRegion = createSelector(
  getCurrentRegionBasedonLanguage,
  getCurrentWorkspace,
  (regionOptions, workspace) => {
    return regionOptions?.find(item => parseInt(item.value) === workspace?.regionId)?.label;
  },
);

export const getCurrentRegionDetails = createSelector(getMediaServers, getCurrentWorkspace, (regions, workspace) => {
  return regions?.find(item => item.id === workspace?.regionId);
});

export const checkIfRegionsExist = createSelector(getMediaServers, regions => {
  return !!(regions.length > 0);
});
