import bowser from "bowser";
import dayjs, { Dayjs } from "dayjs";
import QRCode from "qrcode";
import { createCanvas, loadImage } from "canvas";
import logo from "../assets/images/logo.svg";
import {
  CMSPermission,
  CURRENT_TRANSLATOR,
  DEFAULT_SPEECH_TO_TEXT_LANGUAGE,
  FAVICON_URL,
  GA,
  IS_AUDIOWORKLET_AVAILABLE,
  LISTENER_MODE_QUERY_NAME,
  LISTENER_MODE_QUERY_TYPE,
  SideWay,
  USE_JITSI_MEET_AUDIO_CONSTRAINTS,
} from "../constant";
import {
  generateListenerJoinWithParam,
  generateOnlineLogPathWithParams,
  generateWorkingLogWeeklyPathWithParams,
  REPORTS_BASE_ROUTE,
  WORKSPACE_BASE_ROUTE,
} from "../routes";
import {
  ChannelData,
  ChatMessage,
  DisplayLanguageCodes,
  LanguageOption,
  ListenerData,
  LivekitParticipant,
  MemberStatus,
  Position,
  SpeechToTextSettings,
  SttEndpoint,
} from "../store/types";
import { intl, Language } from "../i18n";
import {
  allowedTranslationLanguages,
  allSourceTranslationLanguages,
  allSpeakingLanguages,
  allTargetTranslationLanguages,
  displayLanguageCodes,
  translationLanguages,
} from "./languages";
import { useIntl } from "react-intl";
import { desktopAppSettings } from "../config";
import { Version } from "../store/version/reducer";
import { getCurrentEnvironment } from "./environment";
import { Member } from "../store/users/reducer";
import { TextChatReceiverOptionIds } from "../screens/Dashboard/TextChat/ReceiverInput";
import { IMemoHistory } from "../components/core/WorkingMemoInput/WorkingMemoInput";
import { VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE } from "../store/virtualOffice/actions";
import {
  ImageWorkChannelObject,
  SelectionArea,
  Size,
  TextWorkChannelObject,
  WorkChannelObject,
} from "../store/workChannel/types";
import logError from "./logError";
import { WorkspaceBackground } from "../store/workspaceBackground";
import { FloorObject, FloorObjectCategory, WorkChannelTemplate } from "../store/workChannel";
import moment from "moment-timezone";
import { sendMessageOverIPC } from "../electron/sendMessageOverIPC";
import { channels } from "../electron/channels";
import { Toast } from "../components/antd/Toast";

export function isNullOrUndefined(obj?: unknown): obj is null | undefined {
  return obj === null || obj === undefined;
}
export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return !isNullOrUndefined(value);
}

export function getOr<T>(value: T, defaultValue: T): T {
  return value ?? defaultValue;
}

export function getClassNames(className?: string): string | undefined {
  if (isNotNullOrUndefined(className)) {
    className.charAt(0);
    return ` ${className}`;
  }
}

export function trimData(text: String) {
  return text.trim().replace(/\s+/g, " ");
}

export type DeviceInfo = {
  flag: string;
  os: "windows" | "macos" | "linux" | "ios" | "android";
  platform: string;
  name: string;
  version: string;
};

export function getDeviceInfo(): DeviceInfo {
  const ua = navigator.userAgent;
  const browser = bowser.getParser(ua);
  let flag;

  if (browser.satisfies({ chrome: ">=0", chromium: ">=0" })) flag = "chrome";
  else if (browser.satisfies({ firefox: ">=0" })) flag = "firefox";
  else if (browser.satisfies({ safari: ">=0" })) flag = "safari";
  else if (browser.satisfies({ opera: ">=0" })) flag = "opera";
  else if (browser.satisfies({ "microsoft edge": ">=0" })) flag = "edge";
  else flag = "unknown";

  return {
    flag,
    os: browser.getOSName(true) as "windows" | "macos" | "linux" | "ios" | "android",
    platform: browser.getPlatformType(true) as "mobile" | "desktop" | "tablet",
    name: browser.getBrowserName(),
    version: browser.getBrowserVersion(),
  };
}

export function getIsMac() {
  const { os } = getDeviceInfo();

  return os === "macos";
}

export function getIsIosIpadLessThan16() {
  if (!isMobile.iOS() && !isMobile.iPad()) return false;

  const { version } = getDeviceInfo();

  let isLessThan16: boolean;

  try {
    isLessThan16 = Number(version.split(".")[0]) < 16;
  } catch (e) {
    logError("failed to parse version info", e);
    isLessThan16 = false;
  }

  return isLessThan16;
}

export function isStringAndNotEmpty(str: string): boolean {
  return str.length > 0;
}

export function isEmptyObject(obj: object): boolean {
  return isNullOrUndefined(obj) || Object.keys(obj).length === 0;
}
export function isNotEmptyObject(obj: object): boolean {
  return !isEmptyObject(obj);
}

export function copyToClipboard(text: string) {
  const dummy = document.createElement("textarea");

  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export const isEllipsisActive = (e: HTMLElement | null) => {
  if (e) {
    return e.offsetWidth < e.scrollWidth;
  } else {
    return false;
  }
};

export function versionString(version: Version) {
  return version.loaded ? `${version.branch}@${version.commit} (${version.environment})` : "not-loaded-yet";
}

export function splitString(value: string, splitBy: string) {
  return value.replace(/\s/g, "")?.split(splitBy);
}

export function generateRandomString(length = 10) {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  return generateRandomFromCharacters(length, characters);
}

export function generateRandomNumber(length = 4) {
  var characters = "0123456789";

  return generateRandomFromCharacters(length, characters);
}

export function generateRandomFromCharacters(length = 4, characters = "") {
  var result = "";
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function isEqual(obj1: any, obj2: any) {
  if (!obj1 || !obj2) return false;

  const sortedObj1 = sort(obj1);
  const sortedObj2 = sort(obj2);

  return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2);
}

export function areEqualArray(arr1: number[] | string[], arr2: number[] | string[]) {
  //check if lengths are different
  if (arr1.length !== arr2.length) return false;

  //slice so we do not effect the orginal
  //sort makes sure they are in order
  let c1 = arr1.slice().sort();
  let c2 = arr2.slice().sort();

  for (let i = 0; i < c1.length; i++) {
    if (c1[i] !== c2[i]) return false;
  }

  return true;
}

export function sort(obj: any) {
  return Object.keys(obj)
    .sort()
    .reduce(function (result: any, key: string) {
      result[key] = obj[key];
      return result;
    }, {});
}

export function getWorkspaceDashboardUrl(workspaceShortId: string) {
  return `${WORKSPACE_BASE_ROUTE}/${workspaceShortId}`;
}

export function alphaNumericSort<T, K extends keyof T>(array: T[], sortField: K, language: Language) {
  const collator = new Intl.Collator(language, { numeric: true, sensitivity: "base" });

  return array.sort((a, b) => collator.compare(a[sortField] as any, b[sortField] as any));
}

export const arrayEquals = (a: any, b: any) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export function redirectToDesktop(typeOfRedirect: "signin" | "dashboard", data: string) {
  console.log("redirecting to desktop app", generateRedirectDesktopURL(typeOfRedirect, data));
  const curEnv = getCurrentEnvironment();

  window.onblur = () => {};

  const iframe = document.createElement("iframe");

  iframe.style.display = "none";
  iframe.src = generateRedirectDesktopURL(typeOfRedirect, data);

  document.body.appendChild(iframe);

  if (curEnv !== "local") {
    setTimeout(() => {
      window.onblur = () => {
        window.close();
      };
    }, 1000);
  }
}

export function useCompanySizeList() {
  const intl = useIntl();

  const sizes = [
    { min: 1, max: 5 },
    { min: 6, max: 10 },
    { min: 11, max: 20 },
    { min: 21, max: 30 },
    { min: 31, max: 50 },
    { min: 51, max: 100 },
    { min: 101, max: 200 },
    { min: 201, max: 300 },
    { min: 301, max: 500 },
    { min: 501, max: 1000 },
  ];

  const maxSize = sizes[sizes.length - 1].max;

  return [
    ...sizes.map(size => ({
      label: intl.formatMessage({ id: "company-size", defaultMessage: "{min}-{max} people" }, size),
      value: `${size.min}-${size.max} people`,
    })),
    {
      label: intl.formatMessage({ id: "company-size-max", defaultMessage: "more than {max} people" }, { max: maxSize }),
      value: `more than ${maxSize} people`,
    },
  ];
}

export function useKnowUs() {
  const intl = useIntl();

  const ways = [
    {
      label: intl.formatMessage({ id: "signup/step2/knowUs/option-browser", defaultMessage: "Browser Search" }),
      value: "Browser Search",
    },
    {
      label: intl.formatMessage({ id: "signup/step2/knowUs/option-google", defaultMessage: "Google Ad" }),
      value: "Google Ad",
    },
    {
      label: intl.formatMessage({ id: "signup/step2/knowUs/option-sns", defaultMessage: "SNS Ad" }),
      value: "SNS Ad",
    },
    { label: intl.formatMessage({ id: "signup/step2/knowUs/option-blog", defaultMessage: "Blogs" }), value: "Blogs" },
    {
      label: intl.formatMessage({
        id: "signup/step2/knowUs/option-comparative-articles",
        defaultMessage: "Comparative Articles",
      }),
      value: "Comparative Articles",
    },
    {
      label: intl.formatMessage({ id: "signup/step2/knowUs/option-acquaintance", defaultMessage: "Acquaintance" }),
      value: "Acquaintance",
    },
    { label: intl.formatMessage({ id: "signup/step2/knowUs/option-other", defaultMessage: "Other" }), value: "Other" },
  ];

  return [...ways];
}

export function convertLanguages(options: string[], planName: string | undefined): LanguageOption[] {
  return options.map(o => allAvailableLanguage.find(el => el.code === o)!);
}

export const defaultLanguageOptions = [
  {
    code: "ja",
    label: { origin: "日本語", en: "Japanese", ja: "日本語", vi: "Tiếng Nhật", ko: "일본어" },
    ttsVariations: [{ code: "ja-JP", name: { origin: "日本", en: "Japan", ja: "日本", vi: "Nhật Bản", ko: "일본" } }],
    sttVariations: [{ code: "ja-JP", name: { origin: "日本", en: "Japan", ja: "日本", vi: "Nhật Bản", ko: "일본" } }],
    translateVariations: [
      { code: "ja", name: { origin: "日本語", en: "japanese", ja: "日本語", vi: "Tiếng Nhật", ko: "일본어" } },
    ],
  },
  {
    code: "en",
    label: { origin: "English", en: "English", ja: "英語", ko: "영어" },
    sttVariations: [
      {
        code: "en-US",
        name: { origin: "United States", en: "United States", ja: "アメリカ合衆国", ko: "미국" },
      },
    ],
    translateVariations: [{ code: "en", name: { origin: "English", en: "English", ja: "英語", ko: "영어" } }],
  },
  {
    code: "ko",
    label: { origin: "한국어", en: "Korean", ja: "韓国語", ko: "한국어", vi: "Tiếng Hàn" },
    ttsVariations: [{ code: "ko-KR", name: { origin: "한국", en: "Korea", ja: "韓国", ko: "한국", vi: "Hàn Quốc" } }],
    sttVariations: [{ code: "ko-KR", name: { origin: "한국", en: "Korea", ja: "韓国", ko: "한국", vi: "Hàn Quốc" } }],
    translateVariations: [
      { code: "ko", name: { origin: "한국어", en: "Korean", ja: "韓国語", ko: "한국어", vi: "Tiếng Hàn" } },
    ],
  },
  {
    code: "vi",
    label: { origin: "Tiếng Việt", en: "Vietnamese", ja: "ベトナム語", ko: "베트남어", vi: "Tiếng Việt" },
    ttsVariations: [
      { code: "vi-VN", name: { origin: "Việt Nam", en: "Vietnam", ja: "ベトナム", ko: "베트남", vi: "Việt Nam" } },
    ],
    sttVariations: [
      { code: "vi-VN", name: { origin: "Việt Nam", en: "Vietnam", ja: "ベトナム", ko: "베트남", vi: "Việt Nam" } },
    ],
    translateVariations: [
      {
        code: "vi",
        name: { origin: "Tiếng Việt", en: "Vietnamese", ja: "ベトナム語", ko: "베트남어", vi: "Tiếng Việt" },
      },
    ],
  },
];

export const allAvailableLanguage = [
  {
    code: "ja",
    label: { origin: "日本語", en: "Japanese", ja: "日本語", vi: "Tiếng Nhật", ko: "일본어" },
    ttsVariations: [{ code: "ja-JP", name: { origin: "日本", en: "Japan", ja: "日本", vi: "Nhật Bản", ko: "일본" } }],
    sttVariations: [{ code: "ja-JP", name: { origin: "日本", en: "Japan", ja: "日本", vi: "Nhật Bản", ko: "일본" } }],
    translateVariations: [
      { code: "ja", name: { origin: "日本語", en: "japanese", ja: "日本語", vi: "Tiếng Nhật", ko: "일본어" } },
    ],
  },
  {
    label: { origin: "English", en: "English", ja: "英語", vi: "Tiếng Anh", ko: "영어" },
    code: "en",
    ttsVariations: [
      {
        code: "en-US",
        name: { origin: "United States", en: "United States", ja: "アメリカ", ko: "미국", vi: "Hoa Kỳ" },
      },
    ],
    sttVariations: [
      {
        code: "en-AU",
        name: { origin: "Australia", en: "Australia", ja: "オーストラリア", ko: "호주", vi: "Châu Úc" },
      },
      { code: "en-CA", name: { origin: "Canada", en: "Canada", ja: "カナダ", ko: "캐나다", vi: "Canada" } },
      { code: "en-GH", name: { origin: "Ghana", en: "Ghana", ja: "ガーナ", ko: "가나", vi: "Gana" } },
      { code: "en-HK", name: { origin: "Hong Kong", en: "Hong Kong", ja: "香港", ko: "홍콩", vi: "Hồng Kông" } },
      { code: "en-IN", name: { origin: "India", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } },
      { code: "en-IE", name: { origin: "Ireland", en: "Ireland", ja: "アイルランド", ko: "아일랜드", vi: "Ireland" } },
      { code: "en-KE", name: { origin: "Kenya", en: "Kenya", ja: "ケニア", ko: "케냐", vi: "Kê-ni-a" } },
      {
        code: "en-NZ",
        name: { origin: "New Zealand", en: "New Zealand", ja: "ニュージーランド", ko: "뉴질랜드", vi: "Tân Tây Lan" },
      },
      {
        code: "en-NG",
        name: { origin: "Nigeria", en: "Nigeria", ja: "ナイジェリア", ko: "나이지리아", vi: "Ni-giê-ri-a" },
      },
      {
        code: "en-PH",
        name: { origin: "Philippines", en: "Philippines", ja: "フィリピン", ko: "필리핀 제도", vi: "Philippines" },
      },
      {
        code: "en-SG",
        name: { origin: "Singapore", en: "Singapore", ja: "シンガポール", ko: "싱가포르", vi: "Singapore" },
      },
      {
        code: "en-ZA",
        name: { origin: "South Africa", en: "South Africa", ja: "南アフリカ", ko: "남아프리카", vi: "Nam Phi" },
      },
      { code: "en-TZ", name: { origin: "Tanzania", en: "Tanzania", ja: "タンザニア", ko: "탄자니아", vi: "Tanzania" } },
      {
        code: "en-GB",
        name: { origin: "United Kingdom", en: "United Kingdom", ja: "イギリス", ko: "영국", vi: "Vương quốc Anh" },
      },
      {
        code: "en-US",
        name: { origin: "United States", en: "United States", ja: "アメリカ", ko: "미국", vi: "Hoa Kỳ" },
        default: true,
      },
    ],
    translateVariations: [
      { code: "en", name: { origin: "English", en: "English", ja: "英語", vi: "Tiếng Anh", ko: "영어" } },
    ],
  },
  {
    code: "zh",
    label: { origin: "普通话", en: "Chinese", ja: "中国語", ko: "중국어", vi: "Tiếng Trung" },
    ttsVariations: [
      {
        code: "zh-CN",
        name: { origin: "中国大陆", en: "Mainland", ja: "中国本土", ko: "중국대륙", vi: "Trung hoa đại lục" },
      },
    ],
    sttVariations: [
      {
        code: "zh-CN",
        name: { origin: "中国大陆", en: "Mainland", ja: "中国本土", ko: "중국대륙", vi: "Trung hoa đại lục" },
        default: true,
      },
      { code: "zh-HK", name: { origin: "香港", en: "Hong Kong", ja: "香港", ko: "홍콩", vi: "Hồng Kông" } },
      { code: "zh-TW", name: { origin: "台湾", en: "Taiwan", ja: "台湾", ko: "대만", vi: "Đài Loan" } },
    ],
    translateVariations: [
      { code: "zh-CN", name: { origin: "简体", en: "Simplified", ja: "簡体字", ko: "간체", vi: "Chữ giản thể" } },
      {
        code: "zh-TW",
        name: { origin: "繁体", en: "Traditional", ja: "繁体字", ko: "번체", vi: "Truyền thống Trung Quốc" },
      },
    ],
  },
  {
    code: "da",
    label: { origin: "Dansk", en: "Danish", ja: "デンマーク語", ko: "덴마크어", vi: "Tiếng Đan Mạch" },
    ttsVariations: [
      { code: "da-DK", name: { origin: "Danmark", en: "Denmark", ja: "デンマーク", ko: "덴마크", vi: "Đan Mạch" } },
    ],
    sttVariations: [
      { code: "da-DK", name: { origin: "Danmark", en: "Denmark", ja: "デンマーク", ko: "덴마크", vi: "Đan Mạch" } },
    ],
    translateVariations: [
      { code: "da", name: { origin: "Dansk", en: "Danish", ja: "デンマーク語", ko: "덴마크어", vi: "Tiếng Đan Mạch" } },
    ],
  },
  {
    code: "vi",
    label: { origin: "Tiếng Việt", en: "Vietnamese", ja: "ベトナム語", ko: "베트남어", vi: "Tiếng Việt" },
    ttsVariations: [
      { code: "vi-VN", name: { origin: "Việt Nam", en: "Vietnam", ja: "ベトナム", ko: "베트남", vi: "Việt Nam" } },
    ],
    sttVariations: [
      { code: "vi-VN", name: { origin: "Việt Nam", en: "Vietnam", ja: "ベトナム", ko: "베트남", vi: "Việt Nam" } },
    ],
    translateVariations: [
      {
        code: "vi",
        name: { origin: "Tiếng Việt", en: "Vietnamese", ja: "ベトナム語", ko: "베트남어", vi: "Tiếng Việt" },
      },
    ],
  },
  {
    code: "fr",
    label: { origin: "Français", en: "French", ja: "フランス語", ko: "프랑스어", vi: "Tiếng Pháp" },
    ttsVariations: [
      { code: "fr-FR", name: { origin: "France", en: "France", ja: "フランス", ko: "프랑스", vi: "Pháp" } },
    ],
    sttVariations: [
      { code: "fr-CA", name: { origin: "Canada", en: "Canada", ja: "カナダ", ko: "캐나다", vi: "Canada" } },
      {
        code: "fr-FR",
        name: { origin: "France", en: "France", ja: "フランス", ko: "프랑스", vi: "Pháp" },
        default: true,
      },
      { code: "fr-CH", name: { origin: "Switzerland", en: "Switzerland", ja: "スイス", ko: "스위스", vi: "Thụy Sĩ" } },
    ],
    translateVariations: [
      { code: "fr", name: { origin: "Français", en: "French", ja: "フランス語", ko: "프랑스어", vi: "Tiếng Pháp" } },
    ],
  },
  {
    code: "de",
    label: { origin: "Deutsch", en: "Deutsch", ja: "ドイツ語", ko: "독일어", vi: "Tiếng Đức" },
    ttsVariations: [{ code: "de-DE", name: { origin: "Germany", en: "Germany", ja: "ドイツ", ko: "독일", vi: "Đức" } }],
    sttVariations: [
      { code: "de-AT", name: { origin: "Austria", en: "Austria", ja: "オーストリア", ko: "오스트리아", vi: "Áo" } },
      { code: "de-CH", name: { origin: "Switzerland", en: "Switzerland", ja: "スイス", ko: "스위스", vi: "Thụy Sĩ" } },
      { code: "de-DE", name: { origin: "Germany", en: "Germany", ja: "ドイツ", ko: "독일", vi: "Đức" }, default: true },
    ],
    translateVariations: [
      { code: "de", name: { origin: "Deutsch", en: "Deutsch", ja: "ドイツ語", ko: "독일어", vi: "Tiếng Đức" } },
    ],
  },
  {
    code: "ar",
    label: { origin: "عربي", en: "Arabic", ja: "アラビア語", ko: "아라비아어", vi: "Tiếng Ả Rập" },
    ttsVariations: [
      {
        code: "ar-SA",
        name: {
          origin: "المملكة العربية السعودية",
          en: "Saudi Arabia",
          ja: "サウジアラビア",
          ko: "사우디 아라비아",
          vi: "Ả Rập Xê Út",
        },
      },
    ],
    sttVariations: [
      { code: "ar-DZ", name: { origin: "الجزائر", en: "Algeria", ja: "アルジェリア", ko: "알제리", vi: "Algeria" } },
      { code: "ar-BH", name: { origin: "البحرين", en: "Bahrain", ja: "バーレーン", ko: "바레인", vi: "Bahrain" } },
      { code: "ar-EG", name: { origin: "مصر", en: "Egypt", ja: "エジプト", ko: "이집트", vi: "Ai Cập" } },
      { code: "ar-IQ", name: { origin: "العراق", en: "Iraq", ja: "イラク", ko: "이라크", vi: "Iraq" } },
      { code: "ar-IL", name: { origin: "إسرائيل", en: "Israel", ja: "イスラエル", ko: "이스라엘", vi: "Israel" } },
      { code: "ar-JO", name: { origin: "الأردن", en: "Jordan", ja: "ヨルダン", ko: "요르단", vi: "Jordan" } },
      { code: "ar-KW", name: { origin: "الكويت", en: "Kuwait", ja: "クウェート", ko: "쿠웨이트", vi: "Kuwait" } },
      { code: "ar-LB", name: { origin: "لبنان", en: "Lebanon", ja: "レバノン", ko: "레바논", vi: "Li-băng" } },
      { code: "ar-LY", name: { origin: "ليبيا", en: "Libya", ja: "リビア", ko: "리비아", vi: "Libya" } },
      { code: "ar-MA", name: { origin: "المغرب", en: "Morocco", ja: "モロッコ", ko: "모로코", vi: "Ma-rốc" } },
      { code: "ar-OM", name: { origin: "سلطنة عمان", en: "Oman", ja: "オマーン", ko: "오만", vi: "Oman" } },
      {
        code: "ar-PS",
        name: {
          origin: "السلطة الفلسطينية",
          en: "Palestinian Authority",
          ja: "パレスチナ自治政府",
          ko: "팔레스타인 자치 정부",
          vi: "Chính quyền Palestine",
        },
      },
      { code: "ar-QA", name: { origin: "دولة قطر", en: "Qatar", ja: "カタール", ko: "카타르", vi: "Qatar" } },
      {
        code: "ar-SA",
        name: {
          origin: "المملكة العربية السعودية",
          en: "Saudi Arabia",
          ja: "サウジアラビア",
          ko: "사우디 아라비아",
          vi: "Ả Rập Xê Út",
        },
        default: true,
      },
      { code: "ar-SY", name: { origin: "سوريا", en: "Syria", ja: "シリア", ko: "시리아", vi: "Syria" } },
      { code: "ar-TN", name: { origin: "تونس", en: "Tunisia", ja: "チュニジア", ko: "튀니지", vi: "Tunisia" } },
      {
        code: "ar-AE",
        name: {
          origin: "الإمارات العربية المتحدة",
          en: "United Arab Emirates",
          ja: "アラブ首長国連邦",
          ko: "아랍 에미리트",
          vi: "Các Tiểu vương quốc Ả Rập Thống nhất",
        },
      },
      { code: "ar-YE", name: { origin: "اليمن", en: "Yemen", ja: "イエメン", ko: "예멘", vi: "Yemen" } },
    ],
    translateVariations: [
      { code: "ar", name: { origin: "عربي", en: "Arabic", ja: "アラビア語", ko: "아라비아어", vi: "Tiếng Ả Rập" } },
    ],
  },
  {
    code: "el",
    label: { origin: "Ελληνικά", en: "Greek", ja: "ギリシャ語", ko: "그리스어", vi: "Tiếng Hy Lạp" },
    ttsVariations: [
      { code: "el-GR", name: { origin: "Ελλάδα", en: "Greece", ja: "ギリシャ", ko: "그리스", vi: "Hy Lạp" } },
    ],
    sttVariations: [
      { code: "el-GR", name: { origin: "Ελλάδα", en: "Greece", ja: "ギリシャ", ko: "그리스", vi: "Hy Lạp" } },
    ],
    translateVariations: [
      { code: "el", name: { origin: "Ελληνικά", en: "Greek", ja: "ギリシャ語", ko: "그리스어", vi: "Tiếng Hy Lạp" } },
    ],
  },
  {
    code: "gu",
    label: { origin: "ગુજરાતી", en: "Gujarati", ja: "グジャラート語", ko: "구자라트어", vi: "Tiếng Gujarat" },
    ttsVariations: [{ code: "gu-IN", name: { origin: "ભારત", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    sttVariations: [{ code: "gu-IN", name: { origin: "ભારત", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    translateVariations: [
      {
        code: "gu",
        name: { origin: "ગુજરાતી", en: "Gujarati", ja: "グジャラート語", ko: "구자라트어", vi: "Tiếng Gujarat" },
      },
    ],
  },
  {
    code: "hi",
    label: { origin: "हिन्दी", en: "Hindi", ja: "ヒンディー語", ko: "힌디어", vi: "Tiếng Hindi" },
    ttsVariations: [{ code: "hi-IN", name: { origin: "भारत", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    sttVariations: [{ code: "hi-IN", name: { origin: "भारत", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    translateVariations: [
      { code: "hi", name: { origin: "हिन्दी", en: "Hindi", ja: "ヒンディー語", ko: "힌디어", vi: "Tiếng Hindi" } },
    ],
  },
  {
    code: "is",
    label: { origin: "Íslenska", en: "Icelandic", ja: "アイスランド語", ko: "아이슬란드어", vi: "Tiếng Iceland" },
    ttsVariations: [
      { code: "is-IS", name: { origin: "Ísland", en: "Iceland", ja: "アイスランド", ko: "아이슬란드", vi: "Iceland" } },
    ],
    sttVariations: [
      { code: "is-IS", name: { origin: "Ísland", en: "Iceland", ja: "アイスランド", ko: "아이슬란드", vi: "Iceland" } },
    ],
    translateVariations: [
      {
        code: "is",
        name: { origin: "Íslenska", en: "Icelandic", ja: "アイスランド語", ko: "아이슬란드어", vi: "Tiếng Iceland" },
      },
    ],
  },
  {
    code: "id",
    label: { origin: "Indonesia", en: "Indonesia", ja: "インドネシア", ko: "인도네시아", vi: "Indonesia" },
    ttsVariations: [
      {
        code: "id-ID",
        name: {
          origin: "Bahasa Indonesia",
          en: "Indonesian",
          ja: "インドネシア語",
          ko: "인도네시아어",
          vi: "Tiếng Indonesia",
        },
      },
    ],
    sttVariations: [
      {
        code: "id-ID",
        name: {
          origin: "Bahasa Indonesia",
          en: "Indonesian",
          ja: "インドネシア語",
          ko: "인도네시아어",
          vi: "Tiếng Indonesia",
        },
        default: true,
      },
      {
        code: "jv-ID",
        name: { origin: "Basa Jawa", en: "Javanese", ja: "ジャワ人", ko: "자바어", vi: "Tiếng Javanese" },
      },
    ],
    translateVariations: [
      {
        code: "id",
        name: { origin: "Indonesia", en: "Indonesia", ja: "インドネシア", ko: "인도네시아", vi: "Indonesia" },
      },
    ],
  },
  {
    code: "ga",
    label: { origin: "Gaeilge", en: "Irish", ja: "アイルランド語", ko: "아일랜드어", vi: "Tiếng Ireland" },
    ttsVariations: [
      { code: "ga-IE", name: { origin: "Éireann", en: "Ireland", ja: "アイルランド", ko: "아일랜드", vi: "Ireland" } },
    ],
    sttVariations: [
      { code: "ga-IE", name: { origin: "Éireann", en: "Ireland", ja: "アイルランド", ko: "아일랜드", vi: "Ireland" } },
    ],
    translateVariations: [
      {
        code: "ga",
        name: { origin: "Gaeilge", en: "Irish", ja: "アイルランド語", ko: "아일랜드어", vi: "Tiếng Ireland" },
      },
    ],
  },
  {
    code: "it",
    label: { origin: "Italiano", en: "Italian", ja: "イタリア語", ko: "이탈리아어", vi: "Tiếng Ý" },
    ttsVariations: [
      { code: "it-IT", name: { origin: "Italia", en: "Italy", ja: "イタリア", ko: "이탈리아", vi: "Ý" } },
    ],
    sttVariations: [
      { code: "it-IT", name: { origin: "Italia", en: "Italy", ja: "イタリア", ko: "이탈리아", vi: "Ý" } },
    ],
    translateVariations: [
      { code: "it", name: { origin: "Italiano", en: "Italian", ja: "イタリア語", ko: "이탈리아어", vi: "Tiếng Ý" } },
    ],
  },
  {
    code: "km",
    label: {
      origin: "ខ្មែរ (កម្ពុជា)",
      en: "Khmer(Cambodia)",
      ja: "クメール語 (カンボジア)",
      ko: "크메르(캄보디아)",
      vi: "Tiếng Khmer (Campuchia)",
    },
    ttsVariations: [
      { code: "km-KH", name: { origin: "កម្ពុជា", en: "Cambodia", ja: "カンボジア", ko: "캄보디아", vi: "Campuchia" } },
    ],
    sttVariations: [
      { code: "km-KH", name: { origin: "កម្ពុជា", en: "Cambodia", ja: "カンボジア", ko: "캄보디아", vi: "Campuchia" } },
    ],
    translateVariations: [
      { code: "km", name: { origin: "ខ្មែរ", en: "Khmer", ja: "クメール語", ko: "크메르어", vi: "Tiếng Khmer" } },
    ],
  },
  {
    code: "kn",
    label: { origin: "ಕನ್ನಡ", en: "Kannada", ja: "カンナダ語", ko: "칸나다어", vi: "Tiếng Kannada" },
    ttsVariations: [{ code: "kn-IN", name: { origin: "ಭಾರತ", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    sttVariations: [{ code: "kn-IN", name: { origin: "ಭಾರತ", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    translateVariations: [
      { code: "kn", name: { origin: "ಕನ್ನಡ", en: "Kannada", ja: "カンナダ語", ko: "칸나다어", vi: "Tiếng Kannada" } },
    ],
  },
  {
    code: "ko",
    label: { origin: "한국어", en: "Korean", ja: "韓国語", ko: "한국어", vi: "Tiếng Hàn" },
    ttsVariations: [{ code: "ko-KR", name: { origin: "한국", en: "Korea", ja: "韓国", ko: "한국", vi: "Hàn Quốc" } }],
    sttVariations: [{ code: "ko-KR", name: { origin: "한국", en: "Korea", ja: "韓国", ko: "한국", vi: "Hàn Quốc" } }],
    translateVariations: [
      { code: "ko", name: { origin: "한국어", en: "Korean", ja: "韓国語", ko: "한국어", vi: "Tiếng Hàn" } },
    ],
  },
  {
    code: "lo",
    label: { origin: "ພາສາລາວ", en: "Lao", ja: "ラオス語", ko: "라오어", vi: "Tiếng Lào" },
    ttsVariations: [{ code: "lo-LA", name: { origin: "ປະເທດລາວ", en: "Laos", ja: "ラオス", ko: "라오스", vi: "Lào" } }],
    sttVariations: [{ code: "lo-LA", name: { origin: "ປະເທດລາວ", en: "Laos", ja: "ラオス", ko: "라오스", vi: "Lào" } }],
    translateVariations: [
      { code: "lo", name: { origin: "ພາສາລາວ", en: "Lao", ja: "ラオス語", ko: "라오어", vi: "Tiếng Lào" } },
    ],
  },
  {
    code: "lv",
    label: { origin: "Latviešu", en: "Latvian", ja: "ラトビア語", ko: "라트비아어", vi: "Tiếng Latvia" },
    ttsVariations: [
      { code: "lv-LV", name: { origin: "Latvija", en: "Latvia", ja: "ラトビア", ko: "라트비아", vi: "Latvia" } },
    ],
    sttVariations: [
      { code: "lv-LV", name: { origin: "Latvija", en: "Latvia", ja: "ラトビア", ko: "라트비아", vi: "Latvia" } },
    ],
    translateVariations: [
      {
        code: "lv",
        name: { origin: "Latviešu", en: "Latvian", ja: "ラトビア語", ko: "라트비아어", vi: "Tiếng Latvia" },
      },
    ],
  },
  {
    code: "lt",
    label: { origin: "Lietuvių", en: "Lithuanian", ja: "リトアニア語", ko: "리투아니아어", vi: "Tiếng Litva" },
    ttsVariations: [
      { code: "lt-LT", name: { origin: "Lietuva", en: "Lithuania", ja: "リトアニア", ko: "리투아니아", vi: "Litva" } },
    ],
    sttVariations: [
      { code: "lt-LT", name: { origin: "Lietuva", en: "Lithuania", ja: "リトアニア", ko: "리투아니아", vi: "Litva" } },
    ],
    translateVariations: [
      {
        code: "lt",
        name: { origin: "Lietuvių", en: "Lithuanian", ja: "リトアニア語", ko: "리투아니아어", vi: "Tiếng Litva" },
      },
    ],
  },
  {
    code: "mk",
    label: { origin: "Македонски", en: "Macedonian", ja: "マケドニア語", ko: "마케도니아어", vi: "Tiếng Macedonia" },
    ttsVariations: [
      {
        code: "mk-MK",
        name: {
          origin: "Северна Македонија",
          en: "North Macedonia",
          ja: "北マケドニア",
          ko: "북마케도니아",
          vi: "Bắc Macedonia",
        },
      },
    ],
    sttVariations: [
      {
        code: "mk-MK",
        name: {
          origin: "Северна Македонија",
          en: "North Macedonia",
          ja: "北マケドニア",
          ko: "북마케도니아",
          vi: "Bắc Macedonia",
        },
      },
    ],
    translateVariations: [
      {
        code: "mk",
        name: { origin: "Македонски", en: "Macedonian", ja: "マケドニア語", ko: "마케도니아어", vi: "Tiếng Macedonia" },
      },
    ],
  },
  {
    code: "mn",
    label: { origin: "Монгол", en: "Mongolian", ja: "モンゴル語", ko: "몽고어", vi: "Tiếng Mông Cổ" },
    ttsVariations: [
      {
        code: "mn-MN",
        name: { origin: "Монгол", en: "Mongolian", ja: "モンゴル語", ko: "몽고어", vi: "Tiếng Mông Cổ" },
      },
    ],
    sttVariations: [
      {
        code: "mn-MN",
        name: { origin: "Монгол", en: "Mongolian", ja: "モンゴル語", ko: "몽고어", vi: "Tiếng Mông Cổ" },
      },
    ],
    translateVariations: [
      { code: "mn", name: { origin: "Монгол", en: "Mongolian", ja: "モンゴル語", ko: "몽고어", vi: "Tiếng Mông Cổ" } },
    ],
  },
  {
    code: "ms",
    label: { origin: "Bahasa Melayu", en: "Malay", ja: "マレー語", ko: "말레이어", vi: "Tiếng Mã Lai" },
    ttsVariations: [
      {
        code: "ms-MY",
        name: { origin: "Malaysia", en: "Malaysia", ja: "マレーシア", ko: "말레이시아", vi: "Malaysia" },
      },
    ],
    sttVariations: [
      {
        code: "ms-MY",
        name: { origin: "Malaysia", en: "Malaysia", ja: "マレーシア", ko: "말레이시아", vi: "Malaysia" },
      },
    ],
    translateVariations: [
      {
        code: "ms",
        name: { origin: "Bahasa Melayu", en: "Malay", ja: "マレー語", ko: "말레이어", vi: "Tiếng Mã Lai" },
      },
    ],
  },
  {
    code: "mt",
    label: { origin: "Malti", en: "Maltese", ja: "マルタ語", ko: "몰타어", vi: "Tiếng Malt" },
    ttsVariations: [{ code: "mt-MT", name: { origin: "Malta", en: "Malta", ja: "マルタ", ko: "몰타", vi: "Malta" } }],
    sttVariations: [{ code: "mt-MT", name: { origin: "Malta", en: "Malta", ja: "マルタ", ko: "몰타", vi: "Malta" } }],
    translateVariations: [
      { code: "mt", name: { origin: "Malti", en: "Maltese", ja: "マルタ語", ko: "몰타어", vi: "Tiếng Malt" } },
    ],
  },
  {
    code: "mr",
    label: { origin: "मराठी", en: "Marathi", ja: "マラーティー語", ko: "마라티어", vi: "Tiếng Marathi" },
    ttsVariations: [{ code: "mr-IN", name: { origin: "भारत", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    sttVariations: [{ code: "mr-IN", name: { origin: "भारत", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    translateVariations: [
      {
        code: "mr",
        name: { origin: "मराठी", en: "Marathi", ja: "マラーティー語", ko: "마라티어", vi: "Tiếng Marathi" },
      },
    ],
  },
  {
    code: "my",
    label: { origin: "ဗမာ", en: "Burmese", ja: "ビルマ語", ko: "버마어", vi: "Tiếng Miến Điện" },
    ttsVariations: [
      { code: "my-MM", name: { origin: "မြန်မာ", en: "Myanmar", ja: "ミャンマー", ko: "미얀마", vi: "Myanmar" } },
    ],
    sttVariations: [
      { code: "my-MM", name: { origin: "မြန်မာ", en: "Myanmar", ja: "ミャンマー", ko: "미얀마", vi: "Myanmar" } },
    ],
    translateVariations: [
      {
        code: "my",
        name: {
          origin: "မြန်မာ (ဗမာ)",
          en: "Myanmar (Burmese)",
          ja: "ミャンマー語（ビルマ語）",
          ko: "미얀마(버마어)",
          vi: "Myanmar (Tiếng Miến Điện)",
        },
      },
    ],
  },
  {
    code: "ne",
    label: { origin: "नेपाली", en: "Nepali", ja: "ネパール語", ko: "네팔어", vi: "Tiếng Nepal" },
    ttsVariations: [{ code: "ne-NP", name: { origin: "नेपाल", en: "Nepal", ja: "ネパール", ko: "네팔", vi: "Nepal" } }],
    sttVariations: [{ code: "ne-NP", name: { origin: "नेपाल", en: "Nepal", ja: "ネパール", ko: "네팔", vi: "Nepal" } }],
    translateVariations: [
      { code: "ne", name: { origin: "नेपाली", en: "Nepali", ja: "ネパール語", ko: "네팔어", vi: "Tiếng Nepal" } },
    ],
  },
  // {
  //   code: "no",
  //   label: { origin: "Norsk bokmål", en: "Norwegian", ja: "ノルウェー語", ko: "노르웨이어", vi: "Tiếng Na Uy" },
  //   ttsVariations: [
  //     { code: "nb-NO", name: { origin: "Bokmål, Norge", en: "Norway", ja: "ノルウェー", ko: "노르웨이", vi: "Na Uy" } },
  //   ],
  //   sttVariations: [
  //     { code: "nb-NO", name: { origin: "Bokmål, Norge", en: "Norway", ja: "ノルウェー", ko: "노르웨이", vi: "Na Uy" } },
  //   ],
  //   translateVariations: [
  //     {
  //       code: "no",
  //       name: { origin: "Norsk bokmål", en: "Norwegian", ja: "ノルウェー語", ko: "노르웨이어", vi: "Tiếng Na Uy" },
  //     },
  //   ],
  // },
  {
    code: "fa",
    label: { origin: "فارسی", en: "Persian", ja: "ペルシア語", ko: "페르시아어", vi: "Tiếng Ba Tư" },
    ttsVariations: [{ code: "fa-IR", name: { origin: "ایران", en: "Iran", ja: "イラン", ko: "이란", vi: "Iran" } }],
    sttVariations: [{ code: "fa-IR", name: { origin: "ایران", en: "Iran", ja: "イラン", ko: "이란", vi: "Iran" } }],
    translateVariations: [
      { code: "fa", name: { origin: "فارسی", en: "Persian", ja: "ペルシア語", ko: "페르시아어", vi: "Tiếng Ba Tư" } },
    ],
  },
  {
    code: "pl",
    label: { origin: "Polski", en: "Polish", ja: "ポーランド語", ko: "폴란드어", vi: "Tiếng Ba Lan" },
    ttsVariations: [
      { code: "pl-PL", name: { origin: "Polska", en: "Poland", ja: "ポーランド", ko: "폴란드", vi: "Ba Lan" } },
    ],
    sttVariations: [
      { code: "pl-PL", name: { origin: "Polska", en: "Poland", ja: "ポーランド", ko: "폴란드", vi: "Ba Lan" } },
    ],
    translateVariations: [
      { code: "pl", name: { origin: "Polski", en: "Polish", ja: "ポーランド語", ko: "폴란드어", vi: "Tiếng Ba Lan" } },
    ],
  },
  {
    code: "pt",
    label: { origin: "Português", en: "Portuguese", ja: "ポルトガル語", ko: "포르투갈어", vi: "Tiếng Bồ Đào Nha" },
    ttsVariations: [
      {
        code: "pt-PT",
        name: { origin: "Portugal", en: "Portugal", ja: "ポルトガル", ko: "포르투갈", vi: "Bồ Đào Nha" },
      },
    ],
    sttVariations: [
      { code: "pt-BR", name: { origin: "Brasil", en: "Brazil", ja: "ブラジル", ko: "브라질", vi: "Brasil" } },
      {
        code: "pt-PT",
        name: { origin: "Portugal", en: "Portugal", ja: "ポルトガル", ko: "포르투갈", vi: "Bồ Đào Nha" },
        default: true,
      },
    ],
    translateVariations: [
      {
        code: "pt",
        name: { origin: "Português", en: "Portuguese", ja: "ポルトガル語", ko: "포르투갈어", vi: "Tiếng Bồ Đào Nha" },
      },
    ],
  },
  {
    code: "ro",
    label: { origin: "Română", en: "Romanian", ja: "ルーマニア語", ko: "루마니아어", vi: "Tiếng Romania" },
    ttsVariations: [
      { code: "ro-RO", name: { origin: "Romania", en: "Romania", ja: "ルーマニア", ko: "루마니아", vi: "Romania" } },
    ],
    sttVariations: [
      { code: "ro-RO", name: { origin: "Romania", en: "Romania", ja: "ルーマニア", ko: "루마니아", vi: "Romania" } },
    ],
    translateVariations: [
      {
        code: "ro",
        name: { origin: "Română", en: "Romanian", ja: "ルーマニア語", ko: "루마니아어", vi: "Tiếng Romania" },
      },
    ],
  },
  {
    code: "ru",
    label: { origin: "Pусский", en: "Russian", ja: "ロシア語", ko: "러시아어", vi: "Tiếng Nga" },
    ttsVariations: [{ code: "ru-Ru", name: { origin: "Россия", en: "Russia", ja: "ロシア", ko: "러시아", vi: "Nga" } }],
    sttVariations: [{ code: "ru-Ru", name: { origin: "Россия", en: "Russia", ja: "ロシア", ko: "러시아", vi: "Nga" } }],
    translateVariations: [
      { code: "ru", name: { origin: "Pусский", en: "Russian", ja: "ロシア語", ko: "러시아어", vi: "Tiếng Nga" } },
    ],
  },
  // {
  //   code: "sr",
  //   label: { origin: "Српски", en: "Serbian", ja: "セルビア語", ko: "세르비아어", vi: "Tiếng Serbia" },
  //   ttsVariations: [
  //     { code: "sr-RS", name: { origin: "Serbia", en: "Serbia", ja: "セルビア", ko: "세르비아", vi: "Serbia" } },
  //   ],
  //   sttVariations: [
  //     { code: "sr-RS", name: { origin: "Serbia", en: "Serbia", ja: "セルビア", ko: "세르비아", vi: "Serbia" } },
  //   ],
  //   translateVariations: [
  //     { code: "sr", name: { origin: "Српски", en: "Serbian", ja: "セルビア語", ko: "세르비아어", vi: "Tiếng Serbia" } },
  //   ],
  // },
  {
    code: "sk",
    label: { origin: "Slovenčina", en: "Slovak", ja: "スロバキア語", ko: "슬로바키아어", vi: "Tiếng Slovak" },
    ttsVariations: [
      {
        code: "sk-SK",
        name: { origin: "Slovakia", en: "Slovakia", ja: "スロバキア", ko: "슬로바키아", vi: "Slovakia" },
      },
    ],
    sttVariations: [
      {
        code: "sk-SK",
        name: { origin: "Slovakia", en: "Slovakia", ja: "スロバキア", ko: "슬로바키아", vi: "Slovakia" },
      },
    ],
    translateVariations: [
      {
        code: "sk",
        name: { origin: "Slovenčina", en: "Slovak", ja: "スロバキア語", ko: "슬로바키아어", vi: "Tiếng Slovak" },
      },
    ],
  },
  {
    code: "sl",
    label: { origin: "Slovenščina", en: "Slovenian", ja: "スロベニア語", ko: "슬로베니아어", vi: "Tiếng Slovenia" },
    ttsVariations: [
      {
        code: "sl-SI",
        name: { origin: "Slovenija", en: "Slovenia", ja: "スロベニア", ko: "슬로베니아", vi: "Slovenia" },
      },
    ],
    sttVariations: [
      {
        code: "sl-SI",
        name: { origin: "Slovenija", en: "Slovenia", ja: "スロベニア", ko: "슬로베니아", vi: "Slovenia" },
      },
    ],
    translateVariations: [
      {
        code: "sl",
        name: { origin: "Slovenščina", en: "Slovenian", ja: "スロベニア語", ko: "슬로베니아어", vi: "Tiếng Slovenia" },
      },
    ],
  },
  {
    code: "es",
    label: { origin: "Español", en: "Spanish", ja: "スペイン語", ko: "스페인어", vi: "Tiếng Tây Ban Nha" },
    ttsVariations: [
      { code: "es-ES", name: { origin: "Spain", en: "Spain", ja: "スペイン", ko: "스페인", vi: "Tây Ban Nha" } },
    ],
    sttVariations: [
      {
        code: "es-AR",
        name: { origin: "Argentina", en: "Argentina", ja: "アルゼンチン", ko: "아르헨티나", vi: "Argentina" },
      },
      { code: "es-BO", name: { origin: "Bolivia", en: "Bolivia", ja: "ボリビア", ko: "볼리비아", vi: "Bolivia" } },
      { code: "es-CL", name: { origin: "Chile", en: "Chile", ja: "チリ", ko: "칠레", vi: "Chile" } },
      { code: "es-CO", name: { origin: "Colombia", en: "Colombia", ja: "コロンビア", ko: "콜롬비아", vi: "Colombia" } },
      {
        code: "es-CR",
        name: { origin: "Costa Rica", en: "Costa Rica", ja: "コスタリカ", ko: "코스타리카", vi: "Costa Rica" },
      },
      { code: "es-CU", name: { origin: "Cuba", en: "Cuba", ja: "キューバ", ko: "쿠바", vi: "Cuba" } },
      {
        code: "es-DO",
        name: {
          origin: "Dominican Republic",
          en: "Dominican Republic",
          ja: "ドミニカ共和国",
          ko: "도미니카 공화국",
          vi: "Cộng hòa Dominica",
        },
      },
      { code: "es-EC", name: { origin: "Ecuador", en: "Ecuador", ja: "エクアドル", ko: "에콰도르", vi: "Ecuador" } },
      {
        code: "es-SV",
        name: { origin: "El Salvador", en: "El Salvador", ja: "エルサルバドル", ko: "엘살바도르", vi: "El Salvador" },
      },
      {
        code: "es-GQ",
        name: {
          origin: "Equatorial Guinea",
          en: "Equatorial Guinea",
          ja: "赤道ギニア",
          ko: "적도기니",
          vi: "Guinea Xích Đạo",
        },
      },
      {
        code: "es-GT",
        name: { origin: "Guatemala", en: "Guatemala", ja: "グアテマラ", ko: "과테말라", vi: "Guatemala" },
      },
      {
        code: "es-HN",
        name: { origin: "Honduras", en: "Honduras", ja: "ホンジュラス", ko: "온두라스", vi: "Honduras" },
      },
      { code: "es-MX", name: { origin: "Mexico", en: "Mexico", ja: "メキシコ", ko: "멕시코", vi: "Mexico" } },
      {
        code: "es-NI",
        name: { origin: "Nicaragua", en: "Nicaragua", ja: "ニカラグア", ko: "니카라과", vi: "Nicaragua" },
      },
      { code: "es-PA", name: { origin: "Panama", en: "Panama", ja: "パナマ", ko: "파나마", vi: "Panama" } },
      { code: "es-PY", name: { origin: "Paraguay", en: "Paraguay", ja: "パラグアイ", ko: "파라과이", vi: "Paraguay" } },
      { code: "es-PE", name: { origin: "Peru", en: "Peru", ja: "ペルー", ko: "페루", vi: "Peru" } },
      {
        code: "es-PR",
        name: { origin: "Puerto Rico", en: "Puerto Rico", ja: "プエルトリコ", ko: "푸에르토 리코", vi: "Puerto Rico" },
      },
      {
        code: "es-ES",
        name: { origin: "Spain", en: "Spain", ja: "スペイン", ko: "스페인", vi: "Tây Ban Nha" },
        default: true,
      },
      { code: "es-UY", name: { origin: "Uruguay", en: "Uruguay", ja: "ウルグアイ", ko: "우루과이", vi: "Uruguay" } },
      { code: "es-US", name: { origin: "USA", en: "USA", ja: "アメリカ合衆国", ko: "미국", vi: "Hoa Kỳ" } },
      {
        code: "es-VE",
        name: { origin: "Venezuela", en: "Venezuela", ja: "ベネズエラ", ko: "베네수엘라", vi: "Venezuela" },
      },
    ],
    translateVariations: [
      {
        code: "es",
        name: { origin: "Español", en: "Spanish", ja: "スペイン語", ko: "스페인어", vi: "Tiếng Tây Ban Nha" },
      },
    ],
  },
  {
    code: "sw",
    label: { origin: "Kiswahili", en: "Swahili", ja: "スワヒリ語", ko: "스와힐리어", vi: "Tiếng Swahili" },
    ttsVariations: [{ code: "sw-KE", name: { origin: "Kenya", en: "Kenya", ja: "ケニア", ko: "케냐", vi: "Kenya" } }],
    sttVariations: [{ code: "sw-KE", name: { origin: "Kenya", en: "Kenya", ja: "ケニア", ko: "케냐", vi: "Kenya" } }],
    translateVariations: [
      {
        code: "sw",
        name: { origin: "Kiswahili", en: "Swahili", ja: "スワヒリ語", ko: "스와힐리어", vi: "Tiếng Swahili" },
      },
    ],
  },
  {
    code: "ta",
    label: { origin: "இந்தியா", en: "Tamil", ja: "タミル語", ko: "타밀어", vi: "Tiếng Tamil" },
    ttsVariations: [{ code: "ta-IN", name: { origin: "India", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    sttVariations: [{ code: "ta-IN", name: { origin: "India", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    translateVariations: [
      { code: "ta", name: { origin: "இந்தியா", en: "Tamil", ja: "タミル語", ko: "타밀어", vi: "Tiếng Tamil" } },
    ],
  },
  {
    code: "te",
    label: { origin: "తెలుగు", en: "Telugu", ja: "テルグ語", ko: "텔루구어", vi: "Tiếng Telugu" },
    ttsVariations: [{ code: "te-IN", name: { origin: "India", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    sttVariations: [{ code: "te-IN", name: { origin: "India", en: "India", ja: "インド", ko: "인도", vi: "Ấn Độ" } }],
    translateVariations: [
      { code: "te", name: { origin: "తెలుగు", en: "Telugu", ja: "テルグ語", ko: "텔루구어", vi: "Tiếng Telugu" } },
    ],
  },
  {
    code: "th",
    label: { origin: "ภาษาไทย", en: "Thai", ja: "タイ語", ko: "태국어", vi: "Tiếng Thái" },
    ttsVariations: [
      { code: "th-TH", name: { origin: "Thailand", en: "Thailand", ja: "タイ", ko: "태국", vi: "Thái Lan" } },
    ],
    sttVariations: [
      { code: "th-TH", name: { origin: "Thailand", en: "Thailand", ja: "タイ", ko: "태국", vi: "Thái Lan" } },
    ],
    translateVariations: [
      { code: "th", name: { origin: "ภาษาไทย", en: "Thai", ja: "タイ語", ko: "태국어", vi: "Tiếng Thái" } },
    ],
  },
  {
    code: "tr",
    label: { origin: "Türkçe", en: "Turkish", ja: "トルコ語", ko: "터키어", vi: "Tiếng Thổ Nhĩ Kỳ" },
    ttsVariations: [
      { code: "tr-TR", name: { origin: "Turkey", en: "Turkey", ja: "トルコ", ko: "터키", vi: "Thổ Nhĩ Kỳ" } },
    ],
    sttVariations: [
      { code: "tr-TR", name: { origin: "Turkey", en: "Turkey", ja: "トルコ", ko: "터키", vi: "Thổ Nhĩ Kỳ" } },
    ],
    translateVariations: [
      { code: "tr", name: { origin: "Türkçe", en: "Turkish", ja: "トルコ語", ko: "터키어", vi: "Tiếng Thổ Nhĩ Kỳ" } },
    ],
  },
  {
    code: "uk",
    label: { origin: "Українська", en: "Ukrainian", ja: "ウクライナ語", ko: "Ukrainian", vi: "Tiếng Ukraina" },
    ttsVariations: [
      { code: "uk-UA", name: { origin: "Ukraine", en: "Ukraine", ja: "ウクライナ", ko: "Ukraine", vi: "Ukraina" } },
    ],
    sttVariations: [
      { code: "uk-UA", name: { origin: "Ukraine", en: "Ukraine", ja: "ウクライナ", ko: "Ukraine", vi: "Ukraina" } },
    ],
    translateVariations: [
      {
        code: "uk",
        name: { origin: "Українська", en: "Ukrainian", ja: "ウクライナ語", ko: "Ukrainian", vi: "Tiếng Ukraina" },
      },
    ],
  },
  {
    code: "af",
    label: { origin: "Afrikaans", en: "Afrikaans", ja: "アフリカーンス語", ko: "아프리카어", vi: "Tiếng Afrikaans" },
    ttsVariations: [
      {
        code: "af-ZA",
        name: { origin: "South Africa", en: "South Africa", ja: "南アフリカ", ko: "남아프리카", vi: "Nam Phi" },
      },
    ],
    sttVariations: [
      {
        code: "af-ZA",
        name: { origin: "South Africa", en: "South Africa", ja: "南アフリカ", ko: "남아프리카", vi: "Nam Phi" },
      },
    ],
    translateVariations: [
      {
        code: "af",
        name: { origin: "Afrikaans", en: "Afrikaans", ja: "アフリカーンス語", ko: "아프리카어", vi: "Tiếng Afrikaans" },
      },
    ],
  },
];

export function getAllLanguages(plan: string | undefined) {
  return allAvailableLanguage.map(el => ({
    label: el.label,
    code: el.code,
  }));
}

export function getAllAvailableSpeakingLanguage(currentLanguage: "en" | "ja" | "vi" | "ko") {
  const sttVariationsSet = new Set<{ code: string; name: string }>();

  allAvailableLanguage.forEach(el => {
    el.sttVariations.forEach(stt => {
      sttVariationsSet.add({ code: stt.code, name: `${el.label[currentLanguage]} (${stt.name[currentLanguage]})` });
    });
  });
  return Array.from(sttVariationsSet);
}

export function getAvailableSpeakingLanguages() {
  const sortPriorities = [/ja-/, /en-US/, /(zh-)|((cmn|yue)-(Hans|Hant))/, /vi-VN/];
  const limitedLanguages = ["ja-JP", "en-US", "zh-CN", "vi-VN"];

  return allSpeakingLanguages
    .filter(
      l =>
        allSourceTranslationLanguages[CURRENT_TRANSLATOR].some(
          s =>
            (l.code.toLowerCase().startsWith(`${s.code.replace(/-.*/, "").toLowerCase()}-`) ||
              l.code.match(/(cmn|yue)-(Hans|Hant)/)) &&
            true,
        ) && limitedLanguages.some(lang => lang === l.code),
    )
    .sort((a, b) => languageSortPriotity(a.code, sortPriorities) - languageSortPriotity(b.code, sortPriorities));
}

export function getAvailableTranslationLanguages() {
  const sortPriorities = CURRENT_TRANSLATOR === "deepL" ? [/EN/, /JA/, /ZH/] : [/ja/, /en/, /zh-/, /vi/];
  const googlelimitedLanguages = ["ja", "en", "zh-CN", "vi"];

  if (CURRENT_TRANSLATOR !== "google") {
    return allTargetTranslationLanguages[CURRENT_TRANSLATOR].sort(
      (a, b) => languageSortPriotity(a.code, sortPriorities) - languageSortPriotity(b.code, sortPriorities),
    );
  } else {
    return allTargetTranslationLanguages[CURRENT_TRANSLATOR].filter(l =>
      googlelimitedLanguages.some(lang => lang === l.code),
    ).sort((a, b) => languageSortPriotity(a.code, sortPriorities) - languageSortPriotity(b.code, sortPriorities));
  }
}

export function languageSortPriotity(language: string, priorities: RegExp[]) {
  for (let i = 0; i < priorities.length; i++) {
    if (priorities[i].test(language)) {
      return i;
    }
  }
  return priorities.length;
}

export function getChannelPresentationalName(channel: ChannelData | undefined) {
  let channelName = channel?.name || "";

  if (channelName && channel?.isTemporary) {
    channelName = channel.name.includes("-") ? channel.name.split("-")[1] : channel.name;
    channelName = intl.formatMessage(
      { id: "temp/channel-append", defaultMessage: "{name}'s conversation" },
      { name: channelName },
    );
  }

  return channelName;
}

// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
// Copied from https://github.com/mozilla/serviceworker-cookbook/blob/b3d7788d44c5c1f071b5321b5ee1c5d7dbcdfec5/tools.js

export function getOnlineMembersById(users: Member[]): Record<number, Member>;
export function getOnlineMembersById<T = Member>(users: Member[], map: (u: Member) => T): Record<number, T>;
export function getOnlineMembersById<T = Member>(
  users: Member[],
  map: (u: Member) => T = u => (u as any) as T,
): Record<number, T> {
  return Object.fromEntries(users.map(u => [u.id, map(u)] as const));
}

export function getListenersById<T = ListenerData>(
  listeners: ListenerData[],
  map: (l: ListenerData) => T,
): Record<number, T> {
  return Object.fromEntries(listeners.map(l => [l.id, map(l)] as const));
}

export function getAllMembersById<T = Member>(users: Member[], map: (u: Member) => T): Record<number, T> {
  return Object.fromEntries(users.map(u => [u.id, map(u)] as const));
}

export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function joinWorkspaceByShortId(shortId: string) {
  const url = `${window.location.protocol}//${window.location.host}/dashboard/${shortId}`;

  if (window.electron) {
    sendMessageOverIPC(channels.REDIRECTION_URL, url);
  } else {
    window.location.href = url;
  }
}

export function generateRedirectDesktopURL(type: string, data: string) {
  let URL;
  const protocol =
    new URLSearchParams(window.location.search).get("protocol") ||
    desktopAppSettings.appProtocol[getCurrentEnvironment()];

  if (type === "signin") {
    URL = `${protocol}://${window.location.host}/${type}/${data || "null"}`;
  } else {
    const path = data.startsWith("/") ? data.substr(1) : data;

    URL = `${protocol}://${window.location.host}/${path}`;
  }

  return URL;
}

export function openURL(URL: string, newTab = false) {
  if (window.electron) {
    window.electron.shell.openExternal(URL);
  } else {
    if (newTab) {
      window.open(URL, "_blank");
    } else {
      window.location.href = URL;
    }
  }
}

export function openPath(path: string) {
  const fullUrl = new URL(path, window.location.origin).href;

  openURL(fullUrl, true);
}

export function readImageResolutionFromFile(file: File) {
  return new Promise<{ width: number; height: number }>(resolve => {
    const img = new Image();

    img.addEventListener("load", () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
      window.URL.revokeObjectURL(img.src); // Free some memory
    });

    img.src = window.URL.createObjectURL(file);
  });
}

export function readImageResolutionFromURL(url: string) {
  return new Promise<{ width: number; height: number }>(resolve => {
    const img = new Image();

    img.addEventListener("load", () => {
      if (img.naturalWidth >= img.naturalHeight && img.naturalWidth >= VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.width) {
        resolve({
          width: VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.width,
          height: (VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.width * img.naturalHeight) / img.naturalWidth,
        });
      } else if (
        img.naturalWidth <= img.naturalHeight &&
        img.naturalHeight >= VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.height
      ) {
        resolve({
          width: (VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.height * img.naturalWidth) / img.naturalHeight,
          height: VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.height,
        });
      } else {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      }

      resolve({ width: img.naturalWidth, height: img.naturalHeight });
      window.URL.revokeObjectURL(img.src); // Free some memory
    });

    img.src = url;
  });
}

export function getTextWidth(text: string, font: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  context!.font = font;
  const metrics = context!.measureText(text);

  return metrics.width * 1.5;
}

export function convertSecondsToFormattedTime(seconds: number) {
  const formattedTime = new Date(seconds * 1000).toISOString().substr(11, 8);

  return formattedTime;
}

export async function getActualRedirectURL() {
  const windowPath = window.location.pathname;

  return windowPath;
}

export function generateOnlineLogPath(workspaceId?: number) {
  const localTimezone = new Date().getTimezoneOffset();
  const today = dayjs().utcOffset(-1 * localTimezone);

  let path = REPORTS_BASE_ROUTE;

  if (workspaceId) {
    path = generateOnlineLogPathWithParams(workspaceId.toString(), "weekly", today.format("YYYY-MM-DD"), "");
  }

  return path;
}

export function generateWorkingLogPath(workspaceId?: number) {
  const localTimezone = new Date().getTimezoneOffset();
  const today = dayjs().utcOffset(-1 * localTimezone);

  let path = REPORTS_BASE_ROUTE;

  if (workspaceId) {
    path = generateWorkingLogWeeklyPathWithParams(workspaceId.toString(), today.format("YYYY-MM-DD"));
  }

  return path;
}

export function getSquareDistanceFromTwoPositions(position1: Position, position2: Position) {
  if (!position1 || !position2) return -1;

  const dX = position2.x - position1.x;
  const dY = position2.y - position1.y;

  return dX * dX + dY * dY;
}

export function getMapOffset(mapDivElement: HTMLDivElement) {
  const style = window.getComputedStyle(mapDivElement);
  const matrix = new DOMMatrixReadOnly(style.transform);
  const scale = matrix.m11;
  const [parentRect] = mapDivElement.parentElement!.getClientRects();
  const [mapRect] = mapDivElement.getClientRects();
  const offsetX = (parentRect.x - mapRect.x + parentRect.width / 2) / scale;
  const offsetY = (parentRect.y - mapRect.y + parentRect.height / 2) / scale;

  return { x: offsetX, y: offsetY };
}

export function getMessageContentByLanguageCode(message: ChatMessage, languageCode?: string | null) {
  if (typeof message.text === "string") {
    return message.text;
  } else if (typeof message === "object") {
    if (!message.speechRecognition) {
      return message.originalTextLanguage ? message.text[message.originalTextLanguage] : "";
    }

    if (languageCode && message.text[mapLanguageCode(languageCode)]) {
      return message.text[mapLanguageCode(languageCode)];
    }

    return "";
  } else {
    throw new Error("Unexpected message type.");
  }
}

/**
 * Map a speaking language code (azure) to a translation language code (azure / google / deepL)
 */
export function mapLanguageCode(code: string) {
  switch (CURRENT_TRANSLATOR) {
    case "azure":
      if (code.split("-")[0] === "zh") {
        return code;
      } else {
        return code.split("-")[0];
      }
    case "google":
      return mapAzureSttCodeToGoogle(code);
    case "deepL":
      return mapAzureCodeToDeepL(code);
  }
}

/**
 * As the speaking language use Azure codes and Translation language
 * here must be compatible with Google, we need to translate these codes.
 */

const googleTranslationLanguages = Object.fromEntries(translationLanguages.google.map(l => [l.code, l] as const));

/**
 * As the speaking language use Azure codes and Translation language
 * here must be compatible with Google, we need to translate these codes.
 */
function mapAzureSttCodeToGoogle(azureCodeWithCountry: string) {
  if (azureCodeWithCountry === "zh-HK") {
    return googleTranslationLanguages["zh-TW"].code;
  }

  const azureCodeWithoutCountry = azureCodeWithCountry.split("-")[0];
  const googleCode =
    googleTranslationLanguages[azureCodeWithCountry] ?? googleTranslationLanguages[azureCodeWithoutCountry];

  if (!googleCode) throw new Error(`Invalid language mapping for ${azureCodeWithCountry}`);

  return googleCode.code;
}

/**
 * Convert language code for SpeechRecognition API options to language code for DeepL options
 * @param language Speaking language code, examples: "en-US", "pt-BR" and "cmn-Hans-CN" (from browser SpeechRecognition API options)
 * @returns Translation language code, examples: "EN", "PT" and "ZH"
 */
function mapAzureCodeToDeepL(language: string) {
  // For Chinese, that the prefix doesn't match. Example: cmn-Hans-CN -> ZH and yue-Hant-HK -> ZH
  if (language.match(/(cmn|yue)-(Hans|Hant)/)) {
    return "ZH";
  }

  // For all other languages that the prefix match. Example: en-US -> EN and pt-BR -> PT
  const sourceLang = translationLanguages.deepL.source.find(l =>
    language.toUpperCase().startsWith(`${l.code.toUpperCase()}-`),
  );

  if (!sourceLang) {
    throw new Error(`Source language '${language}' is not supported for translation`);
  }

  return sourceLang.code;
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

/**
 * Validate url
 * @param url
 * @returns boolean
 */
export function isValidUrl(url: string) {
  if (url !== undefined && url !== "") {
    /* eslint-disable no-useless-escape */
    const regExp = new RegExp(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
      "gm",
    );

    const match = url.match(regExp);

    return !!match;
  }

  return true;
}

/**
 * Validate https url
 * @param url
 * @returns boolean
 */
export function isValidHttpsUrl(url: string) {
  if (url !== undefined && url !== "") {
    /* eslint-disable no-useless-escape */
    const regExp = new RegExp(
      /^https:\/\/(?:[\w\-]+:[\w\-\.]+@)?[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/,
      "gm",
    );

    const match = url.match(regExp);

    return !!match;
  }

  return true;
}

/**
 * Validate youtube url
 * @param url
 * @returns boolean
 */
export function isValidYoutubeUrl(url: string) {
  if (url !== undefined || url !== "") {
    /* eslint-disable no-useless-escape */
    const regExp = new RegExp(
      /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      "gm",
    );

    const match = url.match(regExp);

    if (match) {
      return true;
    }
  }

  return false;
}

export function convertYoutubeToEmbedUrl(url: string) {
  // Extract the video ID from the YouTube URL
  const videoId = extractYoutubeVideoId(url);

  // Construct the embed URL
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return embedUrl;
}

export function extractYoutubeVideoId(url: string) {
  // Extract the video ID using a regular expression
  const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|youtu\.be\/|user\/\S+|playlist\?|embed\/videoseries\?|shared\/item\/\w+)|youtu\.be\/|y2u\.be\/)([a-zA-Z0-9_-]{11})/i;
  const match = url.match(regExp);

  if (match && match[1]) {
    return match[1];
  }

  // Return null if no valid video ID is found
  return null;
}

/**
 * Validate video url
 * @param url
 * @returns boolean
 */
export function isValidVimeoUrl(url: string) {
  if (url !== undefined || url !== "") {
    /* eslint-disable no-useless-escape */
    const regExp = new RegExp(/^(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/i, "gm");

    const match = url.match(regExp);

    if (match) {
      return true;
    }
  }

  return false;
}

export function convertViemoToEmbedUrl(url: string) {
  // Extract the video ID from the Vimeo URL
  const videoId = extractVimeoVideoId(url);

  // Construct the embed URL
  const embedUrl = `https://player.vimeo.com/video/${videoId}`;

  return embedUrl;
}

export function extractVimeoVideoId(url: string) {
  const match = url.split("/");

  return match[match.length - 1];
}

/**
 * Validate video url
 * @param url
 * @returns boolean
 */
export function isValidLoomUrl(url: string) {
  const regex = /^(https?:\/\/)?(?:www\.)?loom\.com\/(?:share|embed)\/[a-zA-Z0-9_-]+$/;

  return regex.test(url);
}

export function convertLoomEmbedUrl(loomUrl: string) {
  const match = loomUrl.match(/share\/([a-zA-Z0-9]+)/);

  if (match && match[1]) {
    const embedUrl = `https://www.loom.com/embed/${match[1]}`;

    return embedUrl;
  }

  return null;
}

export function getErrorPageInfo() {
  const pathname = window.location.pathname;

  if (pathname.includes("no-permission")) {
    return "no-permission";
  } else if (pathname.includes("service-unavailable")) {
    return "service-unavailable";
  }

  return "unexpected-error";
}

export function secondToDuration(sec: number, getSec: boolean = false, isOriginalHour: boolean | undefined = false) {
  const h = isOriginalHour ? "" + Math.floor(sec / 3600) : ("0" + Math.floor(sec / 3600)).slice(-2);
  const m = ("0" + Math.floor((sec / 60) % 60)).slice(-2);
  const s = ("0" + Math.floor(sec % 60)).slice(-2);

  return !getSec ? `${h}:${m}` : `${h}:${m}:${s}`;
}

export function formatHHmmssString(duration: string) {
  const HHmmss = duration.split(":");
  const h = ("0" + HHmmss[0]).slice(-2);
  const m = ("0" + HHmmss[1]).slice(-2);
  const s = ("0" + HHmmss[2]).slice(-2);

  return `${h}:${m}:${s}`;
}

export function getAvailableLanguages() {
  const availableLanguages = translationLanguages.google.filter(el =>
    allowedTranslationLanguages.some(al => al === el.code),
  );

  return availableLanguages;
}

export function generateGAUserTrafficUrl(userId: string) {
  const currentDate = new Date();
  const formattedDate = new Date(currentDate).toISOString().split("T")[0].replace(/\-/g, "");

  return `${GA.url}${GA.id}/_u.date00=${formattedDate}&_u.date01=${formattedDate}&_r.userId=${userId}${GA.params}`;
}

export function convertToJapaneseDateFormat(date: Dayjs) {
  const year = date.year();
  const month = date.month();
  const day = date.date();
  const hour = date.hour();
  const minute = date.minute();
  const dayOfWeek = date.day();
  const dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][dayOfWeek];

  return `${year}年${month + 1}月${day}日 (${dayOfWeekStr}) ${hour < 10 ? "0" + hour : hour}:${
    minute < 10 ? "0" + minute : minute
  }`;
}

export function formatDate(date: Dayjs, timezone: number, language: string) {
  const convertedTimeToTimeZone = dayjs(date).utc().subtract(timezone, "minutes");

  if (language === "ja") {
    return convertToJapaneseDateFormat(convertedTimeToTimeZone);
  } else {
    return convertedTimeToTimeZone.format("lLL");
  }
}

export function addDuration(durationString: string, date: Dayjs) {
  const [hours, minutes, seconds] = durationString.split(":").map(Number);
  const durationObj = dayjs.duration({ hours, minutes, seconds });
  const newTime = date.add(durationObj);

  return newTime;
}

export function formatTime(date: Dayjs, timezone: number) {
  const convertedTimeToTimeZone = dayjs(date).utc().subtract(timezone, "minutes");

  return convertedTimeToTimeZone.format("HH:mm:ss");
}

export function lengthOfString(str?: string) {
  let len = 0;

  if (!str) return len;

  for (let i = 0; i < str.length; i++) {
    str[i].match(/[ -~]/) ? (len += 1) : (len += 2);
  }

  return len;
}

export function addPadToEnd(str: string, length: number, language: string) {
  const offset = length - lengthOfString(str);

  if (offset < 0) {
    return str;
  }

  const sizeOfTab = language === "ja" ? 1 : str.length < 8 ? 2 : 1;

  return str + Array(sizeOfTab).fill("\t").join("");
}

export function alignUserName(str: string, length: number) {
  const maxTabs = length % 5 === 0 ? length / 5 : Math.ceil(length / 5);
  const offset = maxTabs * 5 - strLengthWithWeight(str);

  if (offset < 0) {
    return str;
  }

  const sizeOfTab = offset % 5 === 0 ? offset / 5 : Math.ceil(offset / 5);

  return (
    str +
    Array(sizeOfTab + 1)
      .fill("\t")
      .join("")
  );
}

export function strLengthWithWeight(str: string) {
  let weightedLength = 0;

  for (var i = 0, n = str.length; i < n; i++) {
    if (str.charCodeAt(i) > 255) {
      weightedLength += 2;
    } else {
      weightedLength += 1;
    }
  }
  return weightedLength;
}

export function getTextChatMeetingRoomAdditionalOptions(isGuest: boolean) {
  let everyoneInWorkspace = [];

  if (!isGuest) {
    everyoneInWorkspace.push({
      id: TextChatReceiverOptionIds.EVERYONE_IN_WORKSPACE,
      value: TextChatReceiverOptionIds.EVERYONE_IN_WORKSPACE,
      name: intl.formatMessage({
        id: "text-chat/everyone_in_workspace",
        defaultMessage: "Everyone in workspace",
      }),
      isDefault: false,
    });
  }

  return [
    ...everyoneInWorkspace,
    {
      id: TextChatReceiverOptionIds.EVERYONE_IN_MTG_ROOM,
      value: TextChatReceiverOptionIds.EVERYONE_IN_MTG_ROOM,
      name: intl.formatMessage({
        id: "text-chat/everyone_in_mtg_room",
        defaultMessage: "Everyone in mtg room",
      }),
      isDefault: true,
    },
  ];
}

export function getTextChatFloorAdditionalOptions(isGuest: boolean) {
  let everyoneInWorkspace = [];

  if (!isGuest) {
    everyoneInWorkspace.push({
      id: TextChatReceiverOptionIds.EVERYONE_IN_WORKSPACE,
      value: TextChatReceiverOptionIds.EVERYONE_IN_WORKSPACE,
      name: intl.formatMessage({
        id: "text-chat/everyone_in_workspace",
        defaultMessage: "Everyone in workspace",
      }),
      isDefault: false,
    });
  }

  return [
    ...everyoneInWorkspace,
    {
      id: TextChatReceiverOptionIds.EVERYONE_IN_FLOOR,
      value: TextChatReceiverOptionIds.EVERYONE_IN_FLOOR,
      name: intl.formatMessage({
        id: "text-chat/everyone_in_floor",
        defaultMessage: "Everyone in floor",
      }),
      isDefault: false,
    },
    {
      id: TextChatReceiverOptionIds.NEAR_USERS,
      value: TextChatReceiverOptionIds.NEAR_USERS,
      name: intl.formatMessage({
        id: "text-chat/near_users",
        defaultMessage: "Near Users",
      }),
      isDefault: true,
    },
  ];
}

export function generateChannelURL(workspaceShortId?: string, voiceChannelShortId?: string) {
  let url = `${window.location.origin}${WORKSPACE_BASE_ROUTE}/`;

  if (workspaceShortId) {
    url += `${workspaceShortId}`;
  }

  if (voiceChannelShortId) {
    url += `/${voiceChannelShortId}`;
  }

  return url;
}

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  iPad: function () {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
  },
};

export const fileTypes = ["image/gif", "image/jpeg", "image/jpg", "image/png"];

export function validImageFileType(file: File) {
  return file && fileTypes.includes(file.type);
}

export function stripHtmlTag(htmlString?: string) {
  return htmlString ? htmlString.replace(/<\/?[^>]+(>|$)/g, "") : "";
}

export function getFavIconFromUrl(url?: string) {
  return url ? `${FAVICON_URL}${url}` : "";
}

export const getUserStatus = (member: Member): MemberStatus => {
  if (member.isExtractingAudio) {
    return "busy";
  } else if (member.status === "away") {
    return member.status;
  } else if (member.statusProvider === "Outlook") {
    return member.azureStatus;
  } else if (member.statusProvider === "Google") {
    return member.googleStatus;
  }

  return member.status;
};

export function getArraySpecificLatestData(array: Array<IMemoHistory>, slice: number) {
  return array.reverse().slice(0, array.length > slice ? slice : array.length);
}

export const getDevicePlatform = (version: string) => {
  const versionSplit = version.split("/");

  if (versionSplit && versionSplit.length > 0) {
    return versionSplit[0] !== "mobile";
  }

  return true;
};

export interface UserPosition {
  identity: string;
  position: Position;
  broadcastActive: boolean;
}

export function separateMembersForSubscribe(
  source: UserPosition | null,
  members: UserPosition[],
  distance: number,
  scale: number,
  broadcasterVideoCircleSize: number,
) {
  if (!source) return [];

  const distanceSquare = distance * distance;
  const subscribers: UserPosition[] = members.filter(el => {
    const destPosition = el.broadcastActive
      ? {
          x: el.position.x + 64 * broadcasterVideoCircleSize * scale,
          y: el.position.y + 64 * broadcasterVideoCircleSize * scale,
        }
      : el.position;
    const sourcePosition = source.broadcastActive
      ? {
          x: source.position.x + 64 * broadcasterVideoCircleSize * scale,
          y: source.position.y + 64 * broadcasterVideoCircleSize * scale,
        }
      : source.position;

    return getSquareDistanceFromTwoPositions(destPosition, sourcePosition) <= distanceSquare;
  });

  return subscribers;
}

export function getReactHostPath() {
  const isLocal = getCurrentEnvironment() === "local";
  const http = isLocal ? "http" : "https";
  const port = isLocal ? ":3000" : "";

  return `${http}://${window.location.hostname}${port}`;
}

export function pickNearBorderExtends(extendSet: Set<number>, scope: number, value: number) {
  const result = [] as number[];

  extendSet.forEach(val => {
    if (between(val, value - scope, value + scope)) result.push(val);
  });
  return result;
}

export function between(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

export function checkSideWay(degree: number) {
  if (degree % 180 === 0) {
    return SideWay.SAME_DIRECTION;
  } else if (degree % 90 === 0) {
    return SideWay.VERTICAL_DIRECTION;
  } else {
    return SideWay.SLOP_DIRECTION;
  }
}

export function convertSizeBySideWay({ rotation, width, height }: { rotation: number; width: number; height: number }) {
  const sideWay = checkSideWay(rotation);

  return {
    width:
      sideWay === SideWay.SAME_DIRECTION
        ? width
        : sideWay === SideWay.VERTICAL_DIRECTION
        ? height
        : Math.sqrt(Math.pow(width + height, 2) / 2),
    height:
      sideWay === SideWay.SAME_DIRECTION
        ? height
        : sideWay === SideWay.VERTICAL_DIRECTION
        ? width
        : Math.sqrt(Math.pow(width + height, 2) / 2),
  };
}

export function generateRandomInRange(min: number, max: number) {
  let difference = max - min;

  // generate random number
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor(rand * difference);

  // add with min value
  rand = rand + min;

  return rand;
}

export function getMaxHeight({ width, height }: { width: number; height: number }) {
  return Math.sqrt(Math.pow(width + height, 2) / 2);
}

export function validateHHMM(time: string) {
  const isValid = /^([01]?[0-9]|2[0-4]):[0-5][0-9]$/.test(time);

  if (!isValid) {
    return isValid;
  } else {
    const hh = Number(time.split(":")[0]);
    const mm = Number(time.split(":")[1]);

    if (hh === 24 && mm > 0) {
      return false;
    }
  }

  return true;
}

export function validateIp(ip: string) {
  const isValid = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);

  if (!isValid) {
    return isValid;
  }

  return true;
}

export function workChannelObjectAreEqual(prevObj: WorkChannelObject, nextObj: WorkChannelObject) {
  if (prevObj.type === "text" && nextObj.type === "text") {
    return textWorkChannelObjectAreEqual(prevObj, nextObj);
  } else if (prevObj.type === "image" && nextObj.type === "image") {
    return imageWorkChannelObjectAreEqual(prevObj, nextObj);
  }

  return false;
}

export function imageWorkChannelObjectAreEqual(prevObj: ImageWorkChannelObject, nextObj: ImageWorkChannelObject) {
  if (
    prevObj.properties.order === nextObj.properties.order &&
    prevObj.properties.rotation === nextObj.properties.rotation &&
    prevObj.properties.isLocked === nextObj.properties.isLocked &&
    prevObj.properties.size.width === nextObj.properties.size.width &&
    prevObj.properties.size.height === nextObj.properties.size.height &&
    prevObj.properties.position.x === nextObj.properties.position.x &&
    prevObj.properties.position.y === nextObj.properties.position.y &&
    prevObj.properties.content.objectId === nextObj.properties.content.objectId &&
    prevObj.properties.content.url === nextObj.properties.content.url &&
    prevObj.properties.content.crop?.top === nextObj.properties.content.crop?.top &&
    prevObj.properties.content.crop?.bottom === nextObj.properties.content.crop?.bottom &&
    prevObj.properties.content.crop?.left === nextObj.properties.content.crop?.left &&
    prevObj.properties.content.crop?.right === nextObj.properties.content.crop?.right &&
    prevObj.id === nextObj.id
  ) {
    return true;
  }

  return false;
}

export function textWorkChannelObjectAreEqual(prevObj: TextWorkChannelObject, nextObj: TextWorkChannelObject) {
  if (
    prevObj.properties.order === nextObj.properties.order &&
    prevObj.properties.rotation === nextObj.properties.rotation &&
    prevObj.properties.isLocked === nextObj.properties.isLocked &&
    prevObj.properties.size.width === nextObj.properties.size.width &&
    prevObj.properties.size.height === nextObj.properties.size.height &&
    prevObj.properties.position.x === nextObj.properties.position.x &&
    prevObj.properties.position.y === nextObj.properties.position.y &&
    prevObj.properties.content.color === nextObj.properties.content.color &&
    prevObj.properties.content.fontFamily === nextObj.properties.content.fontFamily &&
    prevObj.properties.content.fontSize === nextObj.properties.content.fontSize &&
    prevObj.properties.content.isBold === nextObj.properties.content.isBold &&
    prevObj.properties.content.isItalic === nextObj.properties.content.isItalic &&
    prevObj.properties.content.isStrikethrough === nextObj.properties.content.isStrikethrough &&
    prevObj.properties.content.isUnderline === nextObj.properties.content.isUnderline &&
    prevObj.properties.content.text === nextObj.properties.content.text &&
    prevObj.id === nextObj.id
  ) {
    return true;
  }

  return false;
}

export function calcMoveMapByZoom(
  mousePosition: Position,
  mapSize: Size,
  prevScale: number,
  newScale: number,
  margins: any,
  isZoomIn: boolean,
) {
  const rateXBase = (mousePosition.x - mapSize.width / 2) / mapSize.width;
  const rateYBase = (mousePosition.y - mapSize.height / 2) / mapSize.height;

  const rateX = isZoomIn
    ? rateXBase
    : margins.left <= 0
    ? -margins.left / mapSize.width
    : margins.right >= 0
    ? -margins.right / mapSize.width
    : rateXBase;

  const rateY = isZoomIn
    ? rateYBase
    : margins.top <= 0
    ? -margins.top / mapSize.height
    : margins.bottom >= 0
    ? -margins.bottom / mapSize.height
    : rateYBase;

  const deltaScale = prevScale - newScale;

  return { deltaX: mapSize.width * deltaScale * rateX, deltaY: mapSize.height * deltaScale * rateY };
}

export function checkObjectInSelectionArea(
  selectionArea: SelectionArea,
  workChannelObject: WorkChannelObject,
  scale: number,
) {
  const objectTopLeftPosition = workChannelObject.properties.position;
  const objectBottomRightPosition = {
    x: workChannelObject.properties.position.x + workChannelObject.properties.size.width,
    y: workChannelObject.properties.position.y + workChannelObject.properties.size.height,
  };
  const selectionTopLeftPosition = {
    x: selectionArea.width > 0 ? selectionArea.x / scale : (selectionArea.x + selectionArea.width) / scale,
    y: selectionArea.height > 0 ? selectionArea.y / scale : (selectionArea.y + selectionArea.height) / scale,
  };
  const selectionBottomRightPosition = {
    x: selectionArea.width > 0 ? (selectionArea.x + selectionArea.width) / scale : selectionArea.x / scale,
    y: selectionArea.height > 0 ? (selectionArea.y + selectionArea.height) / scale : selectionArea.y / scale,
  };

  if (
    workChannelObject.properties.size.width === 0 ||
    workChannelObject.properties.size.height === 0 ||
    selectionArea.width === 0 ||
    selectionArea.height === 0
  ) {
    return false;
  }

  if (
    objectTopLeftPosition.x > selectionBottomRightPosition.x ||
    selectionTopLeftPosition.x > objectBottomRightPosition.x
  ) {
    return false;
  }

  if (
    objectBottomRightPosition.y < selectionTopLeftPosition.y ||
    selectionBottomRightPosition.y < objectTopLeftPosition.y
  ) {
    return false;
  }

  return true;
}

export function convertString2Blob(value: string, type: string) {
  const byteCharacters = atob(value);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type });
}

export function b64toBlob(dataURI: string) {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}

export function getUserInfoFromParticipantId(identity?: string) {
  return identity
    ? {
        userId: parseInt(identity.split("_")[2]),
        voiceChannelId: identity.split("_")[1],
        timestamp: identity.split("_")[3],
      }
    : {};
}

export function getVoiceChannelShortId(name: string) {
  return name.split(/_(.*)/s)[1];
}

export async function getAudioStream(deviceId: string, sampleRate?: number, enableAutoGainStatus?: boolean) {
  const autoGainControlValue = enableAutoGainStatus ? enableAutoGainStatus : true;

  const jitsiMeetAudioConstraints = {
    autoGainControl: true,
    echoCancellation: true,
    noiseSuppression: true,
  };

  const constraints = {
    audio: USE_JITSI_MEET_AUDIO_CONSTRAINTS
      ? {
          ...jitsiMeetAudioConstraints,
          deviceId,
          sampleRate,
        }
      : {
          deviceId,
          echoCancellation: true,
          echoCancellationType: { ideal: " system " },
          channelCount: 1,
          sampleRate: sampleRate,
          sampleSize: 16,
          noiseSuppression: true,
          autoGainControl: autoGainControlValue,
          googEchoCancellation: true,
          googAutoGainControl: autoGainControlValue,
          googExperimentalAutoGainControl: true,
          googNoiseSuppression: true,
          googExperimentalNoiseSuppression: true,
          googHighpassFilter: true,
          googTypingNoiseDetection: true,
          googBeamforming: false,
          googArrayGeometry: false,
          googAudioMirroring: true,
          googNoiseReduction: true,
          mozNoiseSuppression: true,
          mozAutoGainControl: false,
          latency: 0.01,
        },
    video: false,
  };

  const stream = await navigator.mediaDevices.getUserMedia(constraints);

  return stream;
}

export async function getAudioStreamFromElectron() {
  const constraints = {
    audio: {
      mandatory: {
        chromeMediaSource: "desktop",
      },
    },
    video: {
      mandatory: {
        chromeMediaSource: "desktop",
      },
    },
  };

  const stream = await (navigator.mediaDevices as any).getUserMedia(constraints);

  return stream;
}

export function clearParticipantFromGlobal() {
  window.participants = undefined;
}
export function setParticipantsToGlobal(participants: LivekitParticipant[]) {
  window.participants = participants;
}

export function removeParticipantFromGlobal(identity: string) {
  if (!window.participants) {
    window.participants = [];
  }

  window.participants = window.participants.filter(el => el.identity !== identity);
}

export function setParticipantToGlobal(participant: LivekitParticipant) {
  if (!window.participants) {
    window.participants = [];
  }

  window.participants = window.participants.some(el => el.identity === participant.identity)
    ? window.participants.map(el => (el.identity === participant.identity ? participant : el))
    : [...window.participants, participant];
}

export function parseLivekitTrackName(trackName: string) {
  return {
    isDrawingAllowed: trackName.split(":")[1] === "true",
    sourceId: trackName.split(":")[0] === "undefined" ? undefined : trackName.split(":")[0],
  };
}

// userId:workspaceId:mute:silence
export function parseHeartbeat(compressedUsers: string[]) {
  return compressedUsers.map(el => {
    const uncompress = el.split(":");

    return {
      id: Number(uncompress[0]),
      workspaceId: Number(uncompress[1]),
      mute: uncompress[2] === "true",
      silence: uncompress[3] === "true",
    };
  });
}

export function getNameByLanguage(
  language: Language,
  object: WorkspaceBackground | FloorObject | FloorObjectCategory | WorkChannelTemplate,
) {
  return language === "en"
    ? object.enName
    : language === "ja"
    ? object.jaName
    : language === "ko"
    ? object.koName
    : object.zhName;
}

export function getNameColumnByLanguage(language: Language) {
  return `${language}Name`;
}

export function checkCmsPermission(cmsPermission: number, pageToAccess: CMSPermission) {
  return cmsPermission > 0 && (cmsPermission & pageToAccess) > 0;
}

export function convert2Positive(value: number) {
  return value < 0 ? 0 : value;
}

export function findCloestItem(array: number[], value: number) {
  let result: number = array[0];
  let distance = Math.abs(array[0] - value);

  array.forEach(val => {
    const newDistance = Math.abs(val - value);

    if (newDistance < distance) {
      distance = newDistance;
      result = val;
    }
  });

  return result;
}

export function pickNearGrid(gap: number, scope: number, value: number) {
  if (value % gap <= scope) return Math.floor(value / gap) * gap;

  if (gap - (value % gap) <= scope) return Math.ceil(value / gap) * gap;

  return undefined;
}

export function getExtFromFilename(filename: string) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function convertFileNameByExt(filename: string) {
  const ext = getExtFromFilename(filename);

  if (["csv"].includes(ext.toLowerCase())) {
    const pos = filename.lastIndexOf(".");

    return filename.substr(0, pos < 0 ? filename.length : pos) + ".xls";
  }

  if (["h264", "avchd", "flv", "f4v", "swf", "mkv", "webm"].includes(ext.toLowerCase())) {
    const pos = filename.lastIndexOf(".");

    return filename.substr(0, pos < 0 ? filename.length : pos) + ".avi";
  }

  if (["wave", "aif"].includes(ext.toLowerCase())) {
    const pos = filename.lastIndexOf(".");

    return filename.substr(0, pos < 0 ? filename.length : pos) + ".mp3";
  }

  return filename;
}

export function calcDistance(start: Position, end: Position) {
  return Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
}

export function currencyFormat(value: number | string) {
  if (typeof value === "string") {
    return Number(value)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  } else {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }
}

export function getAllTimezone() {
  const utcTimezones: { name: string; offset: number }[] = [];

  moment.tz.names().forEach(timezone => {
    const offset = moment.tz(timezone).utcOffset() * -1;
    const utcPrefix = moment.tz(timezone).format("Z");

    if (!utcTimezones.some(a => a.offset === offset)) {
      let replacedTimeZone = timezone;

      if (utcPrefix === "+09:00") {
        replacedTimeZone = "Asia/Tokyo";
      }

      utcTimezones.push({ name: `UTC ${utcPrefix} ${replacedTimeZone}`, offset });
    }
  });
  return utcTimezones;
}

export function convertURLtoQR(url: string): Promise<string | null> {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(url, function (error, qrcode) {
      if (error) {
        console.log(error);
        resolve(null);
      }

      resolve(qrcode);
    });
  });
}

export async function convertURLtoQRwithLogo(
  url: string,
  width = 500,
  logoHeight = 80,
  margin = 5,
): Promise<string | null> {
  const canvas = createCanvas(width, width);

  QRCode.toCanvas(canvas, url, {
    width: width,
    errorCorrectionLevel: "H",
    margin: 1,
  });

  const ctx = canvas.getContext("2d");
  const img = await loadImage(logo);
  const logoWidth = img.width * (logoHeight / img.height);
  const startHeight = (width - logoHeight) / 2;
  const startWidth = (width - logoWidth) / 2;

  ctx.fillStyle = "white";
  ctx.fillRect(startWidth - margin, startHeight - margin, logoWidth + margin * 2, logoHeight + margin * 2);

  ctx.drawImage(img, startWidth, startHeight, logoWidth, logoHeight);

  return canvas.toDataURL("image/png");
}

export function getDisplayLanguage(): DisplayLanguageCodes {
  const browserLanguage = window.navigator.language.split("-")[0].toLowerCase();
  const displayLanguage = (displayLanguageCodes.includes(browserLanguage)
    ? browserLanguage
    : "en") as DisplayLanguageCodes;

  return displayLanguage;
}

export const useNoiseReductionConfig = IS_AUDIOWORKLET_AVAILABLE && !(isMobile.iOS() || isMobile.iPad());

export function canUseAudioExtractionEnvironment() {
  return true;
}

export function getAvailableWorkspaceLanguages(
  currentWorkspaceLanguages: LanguageOption[] | undefined,
): LanguageOption[] | undefined {
  return currentWorkspaceLanguages?.map(element => ({
    ...element,
    sttVariations: [element.sttVariations.find(el => el.default === true) || element.sttVariations[0]],
  }));
}

export function equateUndefinedAndNull(value1: any, value2: any): boolean {
  if ((value1 === null || value1 === undefined) && (value2 === null || value2 === undefined)) {
    return true;
  }

  return value1 === value2;
}

export async function getExtractedAudioStream(sampleRate?: number) {
  const { os } = getDeviceInfo();
  let stream: MediaStream | undefined = undefined;

  if (os === "macos") {
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const blackhole = allDevices.find(d => d.kind === "audioinput" && d.label.startsWith("BlackHole 2ch"))?.deviceId;

    if (!blackhole) return;

    stream = await getAudioStream(blackhole, sampleRate);
  } else {
    stream = await getAudioStreamFromElectron();
  }

  return stream;
}

export function createBlackCanvas(width: number, height: number) {
  const canvas = document.createElement("canvas");

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");

  if (ctx) {
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, width, height);
  }

  return canvas;
}

export function getDurationHHmmss(beginTime: Date, endTime: Date) {
  const durationInMillis = dayjs(endTime).diff(dayjs(beginTime));
  const duration = dayjs.duration(durationInMillis);

  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const formattedDuration = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    seconds.toString().padStart(2, "0"),
  ].join(":");

  return formattedDuration;
}

export function getJsonFromString(text: string) {
  try {
    const dict = JSON.parse(text.replace(/”/g, `"`).replace(/\n/g, ""));

    return dict;
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export function getLanguageCodeBySpeakingLanguage(
  speakingLanguage: string | undefined | null,
  sttEndpoints: SttEndpoint[],
) {
  if (!speakingLanguage) return undefined;

  if (!sttEndpoints) return undefined;

  const model = sttEndpoints.find(model => model.code === speakingLanguage);

  if (!model) return speakingLanguage;

  return model.sttCode;
}

export async function getNotificationPermission() {
  try {
    return await Notification.requestPermission();
  } catch (err) {
    console.log(err);
  }
}

export function generateSharingChatUrl(userShortId: string) {
  let url = `${window.location.origin}${generateListenerJoinWithParam(userShortId)}?${LISTENER_MODE_QUERY_NAME}=${
    LISTENER_MODE_QUERY_TYPE.AUDIO_EXTRACTION
  }`;

  return url;
}

export async function downloadQR(url: string, filename: string) {
  const downloadLink = document.createElement("a");
  const localTimezone = new Date().getTimezoneOffset();
  const qrcode = await convertURLtoQRwithLogo(url);

  if (!qrcode) {
    Toast.error(
      intl.formatMessage({
        id: "qrcode-download/failed-status",
        defaultMessage: "QR code download has been failed, please try again later!",
      }),
    );

    return;
  }

  downloadLink.href = qrcode;
  downloadLink.download = `${filename}_${dayjs(new Date())
    .utc()
    .subtract(localTimezone, "minutes")
    .format("YYYYMMDD_HH_mm_ss")}.png`;

  if (window.electron) {
    sendMessageOverIPC(channels.DOWNLOAD_URL, {
      url: downloadLink.href,
      fileName: downloadLink.download,
      successMessage: intl.formatMessage({
        id: "qrcode-download/success-status",
        defaultMessage: "QR code downloaded successfully!",
      }),
      errorMessage: intl.formatMessage({
        id: "qrcode-download/failed-status",
        defaultMessage: "QR code download has been failed, please try again later!",
      }),
    });
  } else {
    downloadLink.click();

    Toast.success(
      intl.formatMessage({
        id: "qrcode-download/success-status",
        defaultMessage: "QR code downloaded successfully!",
      }),
    );
  }
}

export function getTranslationLanguageCodes(
  sttSettings: SpeechToTextSettings[],
  sttEndpoints: SttEndpoint[],
  voiceChannel: ChannelData | undefined,
) {
  const languages = new Set();

  sttSettings.forEach(el => {
    let code: string;

    if (el.displayLanguage) {
      code = mapLanguageCode(el.displayLanguage);
    } else {
      code = mapLanguageCode(
        getLanguageCodeBySpeakingLanguage(el.speakingLanguage, sttEndpoints) ??
          voiceChannel?.speechToTextLanguage ??
          DEFAULT_SPEECH_TO_TEXT_LANGUAGE,
      );
    }

    languages.add(code.split("-")[0]);
  });

  return Array.from(languages);
}

export function stringToColorCode(str: string) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;

    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
}

enum SharingOptions {
  ONLY_PARTICIPANT = "ONLY_PARTICIPANT",
  USER_AND_GROUP = "USER_AND_GROUP",
  ENTIRE_OF_WORKSPACE = "ENTIRE_OF_WORKSPACE",
}

export function returnPermissionString(permission: SharingOptions) {
  switch (permission) {
    case SharingOptions.ONLY_PARTICIPANT:
      return intl.formatMessage({
        id: "transcript/permission-only-participatn",
        defaultMessage: "Only meeting participant",
      });
    case SharingOptions.USER_AND_GROUP:
      return intl.formatMessage({
        id: "transcript/permission-user-and-groups",
        defaultMessage: "Specified user and groups",
      });
    case SharingOptions.ENTIRE_OF_WORKSPACE:
      return intl.formatMessage({
        id: "transcript/permission-entire-of-workspace",
        defaultMessage: "All of workspace member",
      });
  }
}
