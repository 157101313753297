import { getJson, postJson } from "../utils/network";
import {
  CREATE_FLOOR_OBJECT,
  CREATE_FLOOR_OBJECT_CATEGORY,
  CREATE_WORKSPACE_BACKGROUND,
  DELETE_FLOOR_OBJECT,
  DELETE_FLOOR_OBJECT_CATEGORY,
  DELETE_WORKSPACE_BACKGROUND,
  DELETE_WORK_CHANNEL_TEMPLATE,
  GET_FLOOR_OBJECTS,
  GET_FLOOR_OBJECT_CATEGORIES,
  GET_WORKSPACE_BACKGROUNDS,
  GET_WORK_CHANNEL_TEMPLATES,
  UPDATE_DEFAULT_TEMPLATE,
  UPDATE_FLOOR_OBJECT,
  UPDATE_FLOOR_OBJECT_CATEGORY,
  UPDATE_WORKSPACE_BACKGROUND,
  UPDATE_WORK_CHANNEL_TEMPLATE,
  WORK_CHANNEL_BASE_INFO,
} from "../constant";
import { CreateOrReadResponseBody, UpdateOrDeleteResponseBody } from ".";
import {
  FloorObject,
  FloorObjectCategory,
  TemplateType,
  WorkChannelSubversion,
  WorkChannelTemplate,
} from "../store/workChannel";
import { WorkspaceBackground } from "../store/workspaceBackground";
import { UpdateObjectFormInputs } from "../screens/CMS/CustomOffice/Object/UpdateObjectForm";
import { UpdateBackgroundFormInputs } from "../screens/CMS/CustomOffice/Background/UpdateBackgroundForm";
import { UpdateTemplateFormInputs } from "../screens/CMS/CustomOffice/Template/UpdateTemplateForm";
import { CreateObjectFormInputs } from "../screens/CMS/CustomOffice/Object/CreateObjectForm";
import { CreateBackgroundFormInputs } from "../screens/CMS/CustomOffice/Background/CreateBackgroundForm";
import { UpdateCategoryFormInputs } from "../screens/CMS/CustomOffice/Category/UpdateCategoryForm";
import { CreateCategoryFormInputs } from "../screens/CMS/CustomOffice/Category/CreateCategoryForm";
import { Toast } from "../components/antd/Toast";

type WorkChannelBaseInfoResponse = CreateOrReadResponseBody<{
  floorObjectCategories: FloorObjectCategory[];
  floorObjects: FloorObject[];
  customBackgrounds: WorkspaceBackground[];
  workChannelSubversions: WorkChannelSubversion[];
  workChannelTemplates: WorkChannelTemplate[];
}>;

export async function getWorkChannelBaseInfo(workspaceId: number, voiceChannelId: number) {
  let path = WORK_CHANNEL_BASE_INFO(workspaceId, voiceChannelId);

  const { response } = await getJson<WorkChannelBaseInfoResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error("Error when fetching workChannel base information");
  }
}

export type GetCustomOfficeObjectsResponse = CreateOrReadResponseBody<{
  floorObjectCategories: FloorObjectCategory[];
  floorObjects: FloorObject[];
}>;

export async function getCustomOfficeObjects() {
  const path = GET_FLOOR_OBJECTS;
  const { response } = await getJson<GetCustomOfficeObjectsResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching floor object list  data: ${JSON.stringify(response.errors)}`);
  }
}

export type GetCustomOfficeBackgroundsResponse = CreateOrReadResponseBody<{
  workspaceBackgrounds: WorkspaceBackground[];
}>;
export async function getCustomOfficeBackgrounds() {
  const path = GET_WORKSPACE_BACKGROUNDS;
  const { response } = await getJson<GetCustomOfficeBackgroundsResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching workspace background list  data: ${JSON.stringify(response.errors)}`);
  }
}

export type GetCustomOfficeTemplatesResponse = CreateOrReadResponseBody<{
  workChannelTemplates: WorkChannelTemplate[];
  defaultFloorTemplateId: number;
  defaultRoomTemplateId: number;
}>;

export async function getCustomOfficeTemplates() {
  const path = GET_WORK_CHANNEL_TEMPLATES;
  const { response } = await getJson<GetCustomOfficeTemplatesResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching work channel template list  data: ${JSON.stringify(response.errors)}`);
  }
}

export type GetCustomOfficeCategoriesResponse = CreateOrReadResponseBody<{
  floorObjectCategories: FloorObjectCategory[];
}>;

export async function getCustomOfficeCategories() {
  const path = GET_FLOOR_OBJECT_CATEGORIES;
  const { response } = await getJson<GetCustomOfficeCategoriesResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when fetching work channel template list  data: ${JSON.stringify(response.errors)}`);
  }
}

export async function createBackground(data: CreateBackgroundFormInputs) {
  const path = CREATE_WORKSPACE_BACKGROUND;
  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function updateBackground(data: UpdateBackgroundFormInputs) {
  const path = UPDATE_WORKSPACE_BACKGROUND(data.id);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function deleteBackground(workspaceBackgroundId: number) {
  const path = DELETE_WORKSPACE_BACKGROUND(workspaceBackgroundId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function createObject(data: CreateObjectFormInputs) {
  const path = CREATE_FLOOR_OBJECT;
  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function updateObject(data: UpdateObjectFormInputs) {
  const path = UPDATE_FLOOR_OBJECT(data.id);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function deleteObject(objectId: number) {
  const path = DELETE_FLOOR_OBJECT(objectId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function deleteTemplate(templateId: number) {
  const path = DELETE_WORK_CHANNEL_TEMPLATE(templateId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function updateTemplate(data: UpdateTemplateFormInputs) {
  const path = UPDATE_WORK_CHANNEL_TEMPLATE(data.id);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function updateDefaultTemplate(templateId: number, type: TemplateType) {
  const path = UPDATE_DEFAULT_TEMPLATE(templateId);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, { type });

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function createCategory(data: CreateCategoryFormInputs) {
  const path = CREATE_FLOOR_OBJECT_CATEGORY;
  const { response } = await postJson<CreateOrReadResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function updateCategory(data: UpdateCategoryFormInputs) {
  const path = UPDATE_FLOOR_OBJECT_CATEGORY(data.id);
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}

export async function deleteCategory(categoryId: number) {
  const path = DELETE_FLOOR_OBJECT_CATEGORY(categoryId);
  const { response } = await getJson<UpdateOrDeleteResponseBody>(path);

  if (response.success) {
    return response;
  } else {
    response.errors.forEach(error => Toast.error(error.message));
  }
}
