import { Role } from "../types";

export interface Project {
  id: number;
  name: string;
  isDefault: boolean;
}

export interface Group {
  id: number;
  name: string;
}

export interface ProjectData {
  id: number;
  workspaceId?: number;
  name: string;
  status: boolean;
  owner?: ProjectMemberData;
  isDefault?: boolean;
  members?: number[];
  groups?: number[];
}

export interface ProjectMemberData {
  id?: number;
  email?: string;
  fullName?: string;
}

export interface ProjectGroupData {
  id?: number;
  name?: string;
  projectId?: number;
}

export interface Floor {
  id: number;
  workspaceId?: number;
  name: string;
  shortId?: string;
  isDisableFloorAccess?: boolean;
}

export interface WorkspaceMemberDetail {
  userId: number;
  workspaceId: number;
  role: Role;
  displayName: string;
  avatarUrl: string;
  email: string;
  fullName: string;
  projects: number[];
  groups: number[];
  floors: number[];
  projectsByGroups: number[];
  createdAt: Date;
  timeTracking: boolean;
  enableScreenshot: boolean;
  projectsGroupsInfo: { id: number; groupIds: number[] }[];
  enableWebApp: any;
  enableTimeTrackerLimit: boolean;
  workspaceUserTimeTrackerLimitId?: number;
  dailyLimit?: number;
  weeklyLimit?: number | null;
  monthlyLimit?: number | null;
  days?: string;
  timezoneName?: string;
  timezoneOffset?: number;
  enableShift?: boolean;
  slots?: { id: number; startTime: string; endTime: string }[];
  currentMonthHours?: number;
  currentWeekHours?: number;
  todayHours?: number;
}

export interface WorkspaceMemberTrackingDetail {
  userId: number;
  workspaceId: number;
  role: Role;
  displayName: string;
  avatarUrl: string;
  email: string;
  fullName: string;
  createdAt: Date;
  timeTracking: boolean;
  enableScreenshot: boolean;
  enableWebApp: any;
  enableTimeTrackerLimit: boolean;
  workspaceUserTimeTrackerLimitId?: number;
  dailyLimit?: number;
  weeklyLimit?: number | null;
  monthlyLimit?: number | null;
  days?: string;
  timezoneName?: string;
  timezoneOffset?: number;
  enableShift?: boolean;
  slots?: { id: number; startTime: string; endTime: string }[];
  currentMonthHours?: number;
  currentWeekHours?: number;
  todayHours?: number;
}

export interface WorkspaceIpWhitelistAddressDetail {
  ip: number;
  description: string;
  enableWorkspaceAccess: boolean;
  workspaceId: number;
}

export interface ProjectMemberData {
  id?: number;
  email?: string;
  fullName?: string;
}

export interface ProjectMemberDetail {
  id: number;
  email: string;
  displayName: string;
  avatarUrl: string;
  workspaceId: number;
  projectId: number;
  workspaceRole: Role;
  createdAt: Date;
}
export interface GroupMemberDetail {
  id: number;
  email: string;
  displayName: string;
  avatarUrl: string;
  workspaceId: number;
  groupId: number;
  workspaceRole: Role;
  createdAt: Date;
}

export interface GroupData {
  id: number;
  workspaceId?: number;
  name: string;
  owner?: ProjectMemberData;
  members?: Array<ProjectMemberData>;
}

export interface GroupMemberData {
  id?: number;
  email?: string;
  fullName?: string;
}

export enum CheckOwnerChangeableStatus {
  YOU_ARE_NOT_CURRENT_OWNER = "YOU ARE NOT CURRENT OWNER",
  YOU_ARE_OWNER_OF_MULTIPLE_WORKSPACE = "YOU ARE OWNER OF MULTIPLE WORKSPACE",
  THIS_USER_IS_OWNER_OF_MULTIPLE_WORKSPACE = "THIS USER IS OWNER OF MULTIPlE WORKSPACE",
}
