import { createSelector } from "reselect";
import { State } from "../../store/state";

export function getDevice(state: State) {
  return state.devices;
}

export const getDeviceSettings = createSelector(getDevice, device => device.deviceSetting);

export const getResolution = createSelector(getDevice, device => device.resolution);

export const getDeviceSettingByKind = (kind: MediaDeviceKind) =>
  createSelector(getDeviceSettings, deviceSettings => {
    switch (kind) {
      case "audioinput":
        return deviceSettings.audioinput;
      case "audiooutput":
        return deviceSettings.audiooutput;
      case "videoinput":
        return deviceSettings.videoinput;
      default:
        return null;
    }
  });

export const getNoiseReductionSetting = createSelector(getDevice, device => device.noiseReduction);

export const getCurrentDevicesList = (kind: MediaDeviceKind) =>
  createSelector(getDevice, device => {
    switch (kind) {
      case "audioinput":
        return device.audioInputDevices;
      case "audiooutput":
        return device.audioOutputDevices;
      case "videoinput":
        return device.videoInputDevices;
      default:
        return [];
    }
  });

export const getAudioInputDevices = createSelector(getDevice, device => device.audioInputDevices);

export const getRequestOpenAudioOutputDeviceSetting = createSelector(
  getDevice,
  device => device.requestOpenAudioOutputDeviceSetting,
);

export const getCurrentAutoGainStatus = createSelector(getDevice, device => device.audioInputAutoGainControl);

export const getIsVirtualBackgroundOn = createSelector(getDevice, device => device.isVirtualBackgroundOn);

export const getVideoDeviceId = createSelector(getDeviceSettings, deviceSetting => deviceSetting.videoinput.id);

export const getLocalAudioStream = createSelector(getDevice, device => device.localAudioStream);

export const getCurrentDeviceOptions = (kind: MediaDeviceKind) =>
  createSelector(getDevice, device => {
    switch (kind) {
      case "audioinput":
        return device.audioInputDevices;
      case "audiooutput":
        console.log(device);
        return device.audioOutputDevices.map(el => ({
          lable: el.label,
          value: el.deviceId,
        }));
      case "videoinput":
        return device.videoInputDevices.map(el => {
          console.log(el, "v");
          return {
            lable: el.label,
            value: el.deviceId,
          };
        });
      default:
        return [];
    }
  });

export const getGuestDevicePermissionAccess = createSelector(getDevice, device => device.guestDeviceAccessPermission);
