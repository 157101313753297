import { createSelector } from "reselect";

import { State } from "../state";
import { LivekitState } from "./reducer";
import { getCurrentVoiceChannel, getCurrentVoiceChannelUsers } from "../voiceChannels";
import { getMyMember } from "../users";
import { LivekitParticipant } from "../types";
import {
  getIsExtractingAudio,
  getIsLoadingRecordAudioExtraction,
  getIsRecordingAudioExtraction,
} from "../audioExtraction";

function getLivekitState(state: State) {
  return state.livekit;
}

export const getLivekitConnectionStatus = createSelector(getLivekitState, (livekit: LivekitState) => {
  return livekit.connectionStatus;
});

export const getParticipants = createSelector(getLivekitState, (livekit: LivekitState) => {
  return livekit.participants;
});

export const getParticipantByUser = (identity?: string) =>
  createSelector(getParticipants, (participants: LivekitParticipant[]) =>
    participants.find(el => el.identity === identity),
  );

export const getAudioPublicationByUser = (identity?: string) =>
  createSelector(
    getParticipants,
    (participants: LivekitParticipant[]) => participants.find(el => el.identity === identity)?.audioPublication,
  );

export const getVideoPublicationByUser = (identity?: string) =>
  createSelector(
    getParticipants,
    (participants: LivekitParticipant[]) => participants.find(el => el.identity === identity)?.videoPublication,
  );

export const getScreensharePublicationByUser = (identity?: string) =>
  createSelector(
    getParticipants,
    (participants: LivekitParticipant[]) => participants.find(el => el.identity === identity)?.screensharePublication,
  );

export const getLivekitLoading = createSelector(getLivekitState, (livekit: LivekitState) => livekit.loading);

export const getMicLivekitLoading = createSelector(getLivekitState, (livekit: LivekitState) => livekit.micLoading);

export const getVideoLivekitLoading = createSelector(getLivekitState, (livekit: LivekitState) => livekit.videoLoading);

export const getScreenshareLivekitLoading = createSelector(
  getLivekitState,
  (livekit: LivekitState) => livekit.screnshareLoading,
);

export const getSubscribers = createSelector(getLivekitState, (livekit: LivekitState) => {
  return livekit.subscribers;
});

export const getBroadcasters = createSelector(getLivekitState, (livekit: LivekitState) => {
  return livekit.broadcasters;
});

export const checkUserInAudibleArea = (identity?: string, userVoiceChannelId?: number | null) =>
  createSelector(
    getIsRecordingAudioExtraction,
    getIsLoadingRecordAudioExtraction,
    getSubscribers,
    getCurrentVoiceChannel,
    (isRecording, isLoading, subscribers, currentVoiceChannel) =>
      (userVoiceChannelId && currentVoiceChannel?.id !== userVoiceChannelId) || isRecording || isLoading
        ? false
        : currentVoiceChannel?.parentVoiceChannelId
        ? true
        : subscribers.some(el => el === identity),
  );

export const checkUserIsAudible = (identity?: string) =>
  createSelector(
    getSubscribers,
    getBroadcasters,
    getCurrentVoiceChannel,
    (subscribers, broadcasters, currentVoiceChannel) =>
      currentVoiceChannel?.parentVoiceChannelId
        ? true
        : subscribers.some(el => el === identity) || broadcasters.some(el => el === identity),
  );

export const checkUserIsBroadcaster = (identity?: string) =>
  createSelector(getBroadcasters, broadcasters => broadcasters.some(el => el === identity));

export const isExistingSubscribers = createSelector(getLivekitState, (livekit: LivekitState) => {
  return livekit.subscribers.length > 0;
});

export const getVoicePingActiveSubscriberIds = createSelector(
  getSubscribers,
  getCurrentVoiceChannel,
  getCurrentVoiceChannelUsers,
  (subscribers, currentVoiceChannel, members) => {
    const membersWithVoicePing = members.filter(m => m.activeWindowName === "VoicePing");
    const activeSubscribersIds: number[] = currentVoiceChannel?.parentVoiceChannelId
      ? membersWithVoicePing.map(m => m.id)
      : membersWithVoicePing.filter(m => subscribers.some(el => el === m.identity)).map(m => m.id);

    return activeSubscribersIds;
  },
);

export const getActiveScreenshareParticipants = createSelector(getLivekitState, (livekit: LivekitState) => {
  return livekit.participants.filter(el => !!el.screensharePublication);
});

export const getHasAudioPermission = createSelector(
  getLivekitState,
  (livekit: LivekitState) => window.electron || (!window.electron && livekit.hasAudioPermission),
);

export const canShowNotificationWindow = (notifications: boolean, unreadMessages: boolean) =>
  createSelector(
    getSubscribers,
    getMyMember,
    getCurrentVoiceChannel,
    getCurrentVoiceChannelUsers,
    getIsExtractingAudio,
    getIsRecordingAudioExtraction,
    (
      subscribers,
      me,
      currentVoiceChannel,
      currentVoiceChannelUsers,
      isExtractingAudio,
      isRecordingAudioExtraction,
    ): boolean => {
      if (isExtractingAudio || isRecordingAudioExtraction) return false;

      if (!me?.mute || me.videoActive) return true;

      if (!currentVoiceChannel?.parentVoiceChannelId && subscribers.length > 0) {
        for (const subscriber of subscribers) {
          if (currentVoiceChannelUsers.some(cvu => cvu.identity === subscriber && cvu.mute === false)) return true;
        }
      }

      if (notifications || currentVoiceChannel?.parentVoiceChannelId || unreadMessages) {
        return true;
      }

      if (currentVoiceChannelUsers.some(el => el.broadcastActive)) return true;

      return false;
    },
  );
