import { Select as AntdSelect } from "antd";
import type { SelectProps, SelectValue } from "antd/lib/select";
import React from "react";
import { ArrowDropDownIconFilled } from "../../icons/material";

export function Select<VT extends SelectValue = SelectValue>({ ...props }: SelectProps<VT>) {
  return <AntdSelect suffixIcon={<ArrowDropDownIconFilled />} {...props} />;
}
Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
