// We cannot use IPC to get this config from desktop app because we need
//them from browser, so there is no way other than duplicating them
export const desktopAppSettings = {
  appProtocol: {
    production: "voiceping",
    development: "voicepingdev",
    firebase: "voicepinglocalrun",
    local: "voicepinglocalrun",
  },
};
