// import dayjs from "dayjs";
import dayjs from "dayjs";
import { getCurrentEnvironment } from "./environment";

declare global {
  interface Window {
    log: Record<LogCategoryName, boolean>;
  }
}

export enum LogCategory {
  InAppNotification,
  Socket,
  Livekit,
  SpeechRecognition,
  Debugging,
  VAD,
  NoiseReduction,
  Redux,
  VirtualWorkspace,
  ActiveApp,
  Screenshare,
  TimeTracker,
  IPCMessages,
  Devices,
  WhiteBoard,
  OnlineLog,
  SttSocket,
  Egress,
  Api,
}

type LogCategoryName = keyof typeof LogCategory;

window.log = {
  InAppNotification: false,
  Socket: false,
  Livekit: false,
  SpeechRecognition: false,
  Debugging: false,
  VAD: false,
  NoiseReduction: false,
  Redux: false,
  VirtualWorkspace: false,
  ActiveApp: false,
  Screenshare: false,
  TimeTracker: false,
  IPCMessages: false,
  WhiteBoard: false,
  Devices: false,
  OnlineLog: false,
  SttSocket: true,
  Egress: false,
  Api: false,
};

disableAllOnProduction();

export function log(category: LogCategory, message?: any, ...optionalParams: any[]) {
  const categoryKey = LogCategory[category] as LogCategoryName;

  if (window.log[categoryKey]) {
    console.log(`${dayjs().format("YYYY-MM-DD HH:mm:ss.SSS Z")} - [${categoryKey}]`, message, ...optionalParams);
  }
}

function disableAllOnProduction() {
  window.log = Object.assign(Object.create(null), window.log);
  if (getCurrentEnvironment() === "production") {
    const categoryKeys = Object.keys(window.log) as LogCategoryName[];

    for (const category of categoryKeys) {
      window.log[category] = false;
    }
  }
}
