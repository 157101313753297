import { ResetPasswordActionTypes, ResetPasswordActions } from "./actions";

interface ResetPasswordState {
  isLoading: boolean;
  success: boolean;
}

export const resetPasswordInitialState = {
  isLoading: false,
  success: false,
};

export function resetPasswordReducer(
  state: ResetPasswordState = resetPasswordInitialState,
  action: ResetPasswordActionTypes,
): ResetPasswordState {
  switch (action.type) {
    case ResetPasswordActions.SHOW_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ResetPasswordActions.SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };

    default:
      return state;
  }
}
