import { createSelector } from "reselect";
import { State } from "../state";
import { getOnlineWorkspaceUsers } from "../users/selectors";
import { RecordingStatusInWorkspace } from "./reducer";
import { Member } from "../users";

export function getAudioExtractionState(state: State) {
  return state.audioExtraction;
}

export const getIsExtractingAudio = createSelector(getAudioExtractionState, ae => ae.isExtractingAudio);

export const getUserMediaStream = createSelector(getAudioExtractionState, ae => ae.userMediaStream);

export const getEnableUseOfAudioExtraction = createSelector(
  getAudioExtractionState,
  ae => ae.enableUseOfAudioExtraction,
);

export const getIsRecordingAudioExtraction = createSelector(
  getAudioExtractionState,
  ae => ae.isRecordingAudioExtraction,
);

export const getIsExtractingAudioRegardlessRecording = createSelector(
  getAudioExtractionState,
  ae => ae.isExtractingAudio || ae.isRecordingAudioExtraction,
);

export const getIsLoadingRecordAudioExtraction = createSelector(
  getAudioExtractionState,
  ae => ae.isStartingRecordAudioExtraction || ae.isStoppingRecordAudioExtraction,
);

export const getIsStartingRecordAudioExtraction = createSelector(
  getAudioExtractionState,
  ae => ae.isStartingRecordAudioExtraction,
);

export const getIsStoppingRecordAudioExtraction = createSelector(
  getAudioExtractionState,
  ae => ae.isStoppingRecordAudioExtraction,
);

export const getAudioTrackId = createSelector(getAudioExtractionState, ae => ae.audioTrackId);

export const getVideoTrackId = createSelector(getAudioExtractionState, ae => ae.videoTrackId);

export const getUseOfRecordAudioExtraction = createSelector(
  getAudioExtractionState,
  ae => ae.useOfRecordAudioExtraction,
);

export const getEgressRoomName = createSelector(getAudioExtractionState, ae => ae.room);

export const getRecordingStatusInWorkspace = createSelector(
  getAudioExtractionState,
  ae => ae.recordingStatusInWorkspace,
);

export const getRecordingUsers = createSelector(
  getRecordingStatusInWorkspace,
  getOnlineWorkspaceUsers,
  (status: RecordingStatusInWorkspace, users: Member[]) =>
    status.userIds?.map(userId => users.find(user => user.id === userId)?.name),
);

export const getAutoSelectedScreen = createSelector(getAudioExtractionState, ae => ae.autoSelectedScreen);

export const getAutoRecordExternalMeeting = createSelector(getAudioExtractionState, ae => ae.autoRecordExternalMeeting);

export const getAudioExtractionDefaultMicStatus = createSelector(getAudioExtractionState, ae => ae.defaultMicStatus);
