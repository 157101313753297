import { notification } from "../../components/antd/Notification";
import { channels } from "../../electron/channels";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { getIsExtractingAudio, getIsRecordingAudioExtraction } from "../audioExtraction";
import { PIPNotifications, PIPWindowNotificationTypes, AppThunk } from "../types";
import { getMyMember } from "../users";
import { InviteGotResponseVoiceChannel } from "../voiceChannels";
import { getNotificationsKey } from "./selectors";

export type SubWindowType = "notification-window";

export enum NotificationsWindowActions {
  SET_LOAD_CALL_NOTIFICATIONS = "notificationsWindow/SET_LOAD_CALL_NOTIFICATIONS",
  ADD_NOTIFICATION = "notificationsWindow/ADD_NOTIFICATION",
  REMOVE_NOTIFICATION = "notificationsWindow/REMOVE_NOTIFICATION",
  CLEAR_NOTIFICATIONS = "notificationsWindow/CLEAR_NOTIFICATIONS",
  ADD_NOTIFICATION_KEY = "notificationWindow/ADD_NOTIFICATION_KEY",
  REMOVE_NOTIFICATION_KEY = "notificationWindow/REMOVE_NOTIFICATION_KEY",
  CLEAR_NOTIFICATIONS_KEY = "notificationWindow/CLEAR_NOTIFICATIONS_KEY",
}

export type NotificationsWindowActionTypes =
  | ReturnType<typeof setLoadNotificationsWindowAction>
  | ReturnType<typeof addNotificationOnPIPAction>
  | ReturnType<typeof removeNotificationOnPIP>
  | ReturnType<typeof clearNotificationsOnPIPAction>
  | ReturnType<typeof addNotificationKeyAction>
  | ReturnType<typeof removeNotificationKeyAction>
  | ReturnType<typeof clearNotificationsKeyAction>;

function addNotificationKeyAction(notificationKey: string) {
  return {
    type: NotificationsWindowActions.ADD_NOTIFICATION_KEY,
    payload: { notificationKey },
  } as const;
}

export function addNotificationKey(notificationkey: string): AppThunk {
  return dispatch => {
    dispatch(addNotificationKeyAction(notificationkey));
  };
}

function removeNotificationKeyAction(notificationKey: string) {
  return {
    type: NotificationsWindowActions.REMOVE_NOTIFICATION_KEY,
    payload: { notificationKey },
  } as const;
}

export function removeNotificationKey(notificationKey: string): AppThunk {
  return (dispatch, getState) => {
    const notificationKeys = getNotificationsKey(getState());

    if (notificationKeys.some(el => el === notificationKey)) {
      notification.close(notificationKey);
      dispatch(removeNotificationKeyAction(notificationKey));
    }
  };
}

function clearNotificationsKeyAction() {
  return {
    type: NotificationsWindowActions.CLEAR_NOTIFICATIONS_KEY,
  } as const;
}

export function clearNotificationsKey(): AppThunk {
  return (dispatch, getState) => {
    const notificationKeys = getNotificationsKey(getState());

    for (const notificationKey of notificationKeys) {
      notification.close(notificationKey);
    }

    dispatch(clearNotificationsKeyAction());
  };
}

export function setLoadNotificationsWindowAction(frameName: SubWindowType, isLoaded: boolean) {
  return { type: NotificationsWindowActions.SET_LOAD_CALL_NOTIFICATIONS, payload: { frameName, isLoaded } } as const;
}

export function addNotificationOnPIP(payload: PIPNotifications): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (!me) return;

    let isAutoRemovable = payload.notificationsType === "invitaion-responses";

    if (payload.notificationsType === "invitaion-responses") {
      const data = payload.data as InviteGotResponseVoiceChannel;

      if (data.option === "no-response" && data.invitedUserId === me.id) {
        isAutoRemovable = false;
      }
    }

    dispatch(
      addNotificationOnPIPAction({
        ...payload,
        myId: me.id,
        timestamp: new Date().getTime(),
        isAutoRemovable,
      }),
    );
  };
}

export function addNotificationOnPIPAction(payload: PIPNotifications & { myId: number; timestamp: number }) {
  return { type: NotificationsWindowActions.ADD_NOTIFICATION, payload } as const;
}

export function removeNotificationOnPIP(payload: {
  type: PIPWindowNotificationTypes;
  invitationId?: string;
  invitedUserId?: number;
  voiceChannelId?: number;
  screenSharerUserId?: number;
  broadcastId?: string;
  reactedUserId?: number;
  emoji?: string;
}) {
  return { type: NotificationsWindowActions.REMOVE_NOTIFICATION, payload } as const;
}

function clearNotificationsOnPIPAction() {
  return { type: NotificationsWindowActions.CLEAR_NOTIFICATIONS } as const;
}

export function clearNotificationsPiP(): AppThunk {
  return dispatch => {
    dispatch(clearNotificationsKey());
    dispatch(clearNotificationsOnPIPAction());
  };
}

export function showNotificationWindow(): AppThunk {
  return (dispatch, useState) => {
    const isExtractingAudio = getIsExtractingAudio(useState());
    const isRecordingAudioExtraction = getIsRecordingAudioExtraction(useState());

    if (!isExtractingAudio && !isRecordingAudioExtraction) {
      sendMessageOverIPC(channels.SHOW_NOTIFICATIONS_WINDOW);
    }
  };
}

export function hideNotificationWindow(): AppThunk {
  return dispatch => {
    sendMessageOverIPC(channels.HIDE_NOTIFICATIONS_WINDOW);
    dispatch(setLoadNotificationsWindowAction("notification-window", false));
  };
}
