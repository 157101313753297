import { createSelector } from "reselect";
import { State } from "../state";
import { TextChannelState } from "./reducer";
import { STT_MESSAGE_LINES } from "../../constant";

export function getTextChannelState(state: State): TextChannelState {
  return state.textChannel;
}

export const getTextChannelId = createSelector(getTextChannelState, ({ textChannelId }) => textChannelId);

export const getTextChannelWorkspaceId = createSelector(getTextChannelState, ({ workspaceId }) => workspaceId);

export const getTextChannelMessages = createSelector(getTextChannelState, ({ messages }: TextChannelState) => messages);

export const getOldestMessageId = createSelector(
  getTextChannelState,
  ({ currentOldestMessageId }: TextChannelState) => currentOldestMessageId,
);

export const getTextChannelHasMore = createSelector(getTextChannelState, ({ hasMore }: TextChannelState) => hasMore);

export const hasTextChannel = createSelector(
  getTextChannelState,
  ({ textChannelId }: TextChannelState) => !!textChannelId,
);

export const getSpeechToTextData = createSelector(
  getTextChannelState,
  (textChannelState: TextChannelState) => textChannelState.speechToText,
);

export const getUnreadMessagesIds = createSelector(getTextChannelState, ({ messages }: TextChannelState) =>
  messages.filter(el => el.unread).map(el => el.id),
);

export const checkUnreadMessages = createSelector(getTextChannelState, ({ messages }: TextChannelState) =>
  messages.some(el => el.unread),
);

export const getLastMessages = createSelector(getTextChannelState, (textChannelState: TextChannelState) =>
  textChannelState.messages.filter(m => m.speechRecognition).slice(-1 * STT_MESSAGE_LINES),
);

export const getChatBoxOption = createSelector(getTextChannelState, ({ showChatBox }: TextChannelState) => showChatBox);
export const getChatBoxFocus = createSelector(
  getTextChannelState,
  ({ focusChatBox }: TextChannelState) => focusChatBox,
);
export const getDMUserIds = createSelector(getTextChannelState, ({ dmUserIds }: TextChannelState) => dmUserIds);

export const getIsLoading = createSelector(getTextChannelState, ({ isLoading }: TextChannelState) => isLoading);

export const getFullScreenChatBox = createSelector(
  getTextChannelState,
  ({ fullScreenChatBox }: TextChannelState) => fullScreenChatBox,
);

export const getAudioDatas = createSelector(getTextChannelState, ({ audioDatas }: TextChannelState) => audioDatas);

export const getIsReachedSttMaxUsage = createSelector(
  getTextChannelState,
  textChannelState => textChannelState.reachedMaxSttUsage,
);

export const getResizeEvent = createSelector(getTextChannelState, ({ resizeEvent }: TextChannelState) => resizeEvent);

export const getKeyboardActive = createSelector(
  getTextChannelState,
  ({ keyboardActive }: TextChannelState) => keyboardActive,
);

export const getSelectedMessage = createSelector(
  getTextChannelState,
  ({ selectedMessage }: TextChannelState) => selectedMessage,
);
