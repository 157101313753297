import * as React from "react";
import { openURL } from "../../../utils/helpers";
import { Tooltip } from "antd";
import "./urlify.scss";
import { VIETNAMESE_TEXT_CHECK_REGEX } from "../../../common/regex";

interface URLifyProps {
  readonly text: string;
  readonly msgListLen: number;
  readonly showTooltips?: boolean;
  readonly textType?: "original" | "translate";
  readonly textFontSize?: number;
}

function chkstrlen(str: string) {
  var strlen = 0;

  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      //Not ascii code will require 2 times the length of the string
      strlen += 2;
    } else strlen++;
  }
  return strlen;
}

export function URLify({ text, msgListLen, showTooltips, textFontSize, textType = "original" }: URLifyProps) {
  const urlPatternRegex = /(https?:\/\/(?:[^\s),.]|[),.](?!\s|$))+)/gm;
  const pieces = text.split(urlPatternRegex);

  const regex = new RegExp(VIETNAMESE_TEXT_CHECK_REGEX, "i");

  const isTextVietnamese = text?.split(" ")?.some(word =>
    word
      ?.replace(" ", "")
      ?.split("")
      ?.some(char => regex.test(char.normalize())),
  );

  if (!text) {
    return (
      <div>
        <span>{text}</span>
      </div>
    );
  }

  const onClick = (url: string) => {
    openURL(url, true);
  };

  //260 - 3 dots
  const limitTextLenMax = 257;
  //260 / 4 = 65 > 45(maximum chars in a line)
  const maxMsgListLen = msgListLen > 4 ? 4 : msgListLen;
  const curMaxLen = limitTextLenMax / maxMsgListLen;

  return (
    <div
      className={`messageContainer text-type-${textType} ${isTextVietnamese ? "arial-font" : ""}`}
      style={
        textFontSize
          ? {
              fontSize: textType === "translate" ? `${textFontSize - 2}px` : `${textFontSize}px`,
            }
          : {}
      }
    >
      {pieces.map((piece, i) =>
        i % 2 === 0 ? (
          // pieces on even indexes are just textContent
          showTooltips && chkstrlen(piece) > curMaxLen ? (
            <Tooltip
              key={i}
              placement="topLeft"
              title={piece}
              overlayClassName="pipChatTooltip"
              destroyTooltipOnHide={true}
              mouseEnterDelay={0.5}
            >
              <span key={i}>{piece.substring(0, curMaxLen) + "..."}</span>
            </Tooltip>
          ) : (
            <span key={i}>{piece}</span>
          )
        ) : (
          // pieces on odd indexes are URLs
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key={i} href={"#"} onClick={() => onClick(piece)} id={`${shortenURL(piece)}url-anchor`} data-url={piece}>
            {shortenURL(piece)}
          </a>
        ),
      )}
    </div>
  );
}

export function shortenURL(text: string) {
  return text.replace(/(https?:\/\/.+?\/.{10})(.+)(.{10})/, "$1...$3");
}
