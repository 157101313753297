import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import * as ipc from "../electron/ipc";

import { State } from "./state";
import { Language } from "../i18n";
import { Member, UpdateBroadcastActiveNotification } from "./users";
import {
  InviteGotResponseVoiceChannel,
  InviteReceiveVoiceChannel,
  ReminderMeetingVoiceChannel,
  InviteRequestVoiceChannel,
  ScreenshareRequestReceive,
  UnmuteUnslienceRequestReceive,
  KnockerListPIPData,
  SubscribingKnockPipData,
} from "./voiceChannels";

import { UserScreenActiveNotification } from "./users";
import { Group } from "./member";
import { TrackPublication } from "livekit-client";
import { PermissionData } from "../screens/Dashboard/WorkspaceManagement/InviteUser/InviteUser";
import { InviteeData } from "../api/workspace";
import { SubscriptionPlan } from "./payment/type";

export * from "./textChannel/types";

export type Role = "manager" | "member" | "guest" | "meeting-recording-bot" | "listener" | "owner";

export type MemberStatus = "available" | "away" | "busy";

export type DeviceType = "web" | "desktop" | "mobile";

export type DisplayLanguageCodes = "en" | "ja" | "vi" | "ko";

export enum EnPlanNames {
  Free = "Free",
  Small = "Small",
  Premium = "Premium",
  Enterprise = "Enterprise",
}

export const planTypeOptions = ["user"] as const;
export type PlanType = typeof planTypeOptions[number];

export interface PlanDetails {
  name: Record<Language, string>;
  maxUsersPerWorkspace: number | null;
  maxFloorsPerWorkspace: number | null;
  maxMapWidth: number | null;
  maxMapHeight: number | null;
  maxVerticalMapWidth: number | null;
  maxVerticalMapHeight: number | null;
  maxSpeechToTextMinutes: number | null;
  maxFileStorage: number | null;
  demo: boolean;
  upgradable: boolean;
  maxWorkspaces: number | null;
  maxMeetingRecordingPerWorkspace?: number | null;
  planExpiryDate?: Date | null;
  isPlanExpired?: boolean;
  isWorkspaceMembersLimitReached?: boolean;
  numberOfMeetingsRecorded?: number | null;
  maxWorkspaceLanguages?: number | null;
}

export interface Workspace {
  id: number;
  name: string;
  shortId: string;
  createdById: number;
  regionId: number;
  vpSttServerId: number;
  role: string;
  logoUrl: string;
  speechToTextCurrentUsage: number;
  timeTrackerSlackChannelId: string | null;
  plan: PlanDetails;
  etherpadGroupId: string;
  chatworkGroupId: number | null;
  idleDuration: number;
  enableActiveApp: boolean;
  enableWebApp: boolean;
  numberOfMeetingsRecorded: number;
  enableTimeTracking: boolean;
  screenshareVisibility: boolean;
  iceTransportPolicy: boolean;
  languageOptions: string | null;
  referralUrl: string;
  enableTextChatSaving: boolean;
  audibleRange: number;
  userVideoCircleSize: number;
  broadcasterVideoCircleSize: number;
  stripeCustomerId: string | null;
  stripePaymentMethodId: string[];
  stripeSubscriptionId: string;
  stripePriceId: string;
  willPlanBeCanceled: boolean;
  enableIndirectTranslation: boolean;
  workspacePhrases?: WorkspacePhrase[];
  workspaceLanguages?: LanguageOption[];
  country: string | null;
  enableConsecutiveTranslation: boolean;
  userAvatarCircleSize: number;
  balloonFontSize: number;
  displayNameFontSize: number;
  invitees: InviteeData[];
  enableBusyStatusReset: boolean;
  enableIpFiltering: boolean;
  livekitUrl: string;
  isTrialAvailable: boolean;
  enableGuestWebApp: boolean;
  enableSpeechToText: boolean;
  speechToTextLanguage: string;
  enableMicForMeeting: boolean;
  enableWebCamForMeeting: boolean;
  enableAutoMeetingRecording: boolean;
  enableDeleteAllData: boolean;
  enableRectangularView: boolean;
  enableEmailTokenFlag: boolean;
  transcriptSlackChannelId: string | null;
  meetingApps: string[] | null;
  transcriptChatworkGroupId: number | null;
  transcriptDiscordUrl: string | null;
  timerDiscordUrl: string | null;
  cmsLoginAt: Date;
  cmsPermission: number;
  currency: string | null;
  planName: string | null;
  nextStripePriceId: string | null;
  stripePlanExpiryDate: Date | null;
  currentStripePriceId: string | null;
  isOnTrial: boolean;
  ownerId: number;
  languages: LanguageOption[];
  translationFrequency: number | null;
}

export interface Invitee {
  email: string;
  permissionData: PermissionData;
}

export type TranslationDictionary = Record<string, string>[];

export interface WorkspacePhrase {
  workspaceId: number;
  language: string;
  phrases: string;
  translationDictionary: TranslationDictionary | null;
}

export interface ScheduleMeetingParticipant {
  participantId: number;
  participantType: string;
}

export interface ScheduleMeetingSlot {
  id: string;
  startTime: string;
  endTime: string;
  days: number[];
}

export interface ChannelData {
  id: number;
  name: string;
  shortId: string;
  listenerId: string;
  workspaceId: number;
  textChannelId?: number;
  parentVoiceChannelId: number | null;
  isTemporary: boolean;
  speechToTextLanguage: string;
  map?: ChannelMapDetails;
  isPrivate: boolean;
  ownerUserId: number;
  lastMeetingAt: string | null;
  speechToTextEnabled: boolean;
  textToSpeechEnabled: boolean;
  guestEnabled: boolean;
  guestPassword: string | null;
  listenerEnabled: boolean;
  listenerMicEnabled: boolean;
  listenerPassword: string | null;
  workspaceBackgroundId: number;
  isAutoMeetingRecordingEnabled: boolean;
  isAutoMeetingRecordingFeatureAvailed?: boolean;
  isPositionLock: boolean;
  isDisableFloorAccess: boolean;
  isDefaultFloor: boolean;
  groups?: Group[];
  isEnableRectangularView?: boolean;
  isScheduledMeeting: boolean;
  meetingParticipants?: ScheduleMeetingParticipant[];
  meetingSlots?: ScheduleMeetingSlot[];
  reminderInterval?: number;
  timezoneOffset?: number;
  isEnableMicForMtg?: boolean;
  isEnableWebCamForMtg?: boolean;
  isDeleteAllData?: boolean;
  knocker?: { userId: number; userName: string }[];
  isPin?: boolean;
  knockSubscriberIds?: number[];
  showOriginalTextEnabledAsDefault: boolean;
}

export interface ChannelMapDetails {
  url: string;
  width: number;
  height: number;
}

export interface SpeechToTextSettings {
  speakingLanguage: string | null;
  displayLanguage: string | null;
}

export type StatusProvider = "" | "Google" | "Outlook";

export interface MemberData {
  id: number;
  name: string;
  email: string;
  language: Language;
  silence: boolean;
  workspaceId: number;
  avatarUrl: string;
  role: Role;
  voiceChannelId: number | null;
  status: MemberStatus;
  googleStatus: MemberStatus;
  azureStatus: MemberStatus;
  statusProvider: StatusProvider;
  broadcastActive: boolean;
  raiseHand: boolean;
  nativeNotification: boolean;
  activeWindowIcon: string | null;
  activeWindowName: string | null;
  workingMemo: string;
  manualWorkingMemoTemp: string | null;
  isManualUpdated: boolean;
  online: boolean;
  connections: { from: DeviceType; version: string }[];
  position: Position;
  speechToText: SpeechToTextSettings;
  assignedFloors: number[] | null;
  guestJoinId: number | null;
  lastActiveAt: Date | null;
  project?: { id: number; name: string };
  syncCalendar: boolean;
  timeTracking: boolean;
  enableScreenshot: boolean;
  enableWebApp: boolean;
  hasMobileApp: boolean;
  connectedChatwork?: boolean;
  connectedAzure?: boolean;
  connectedGoogle?: boolean;
  pinnedChannels: string | null;
  enabledLanguageInconsistencyAlert: boolean;
  isExtractingAudio: boolean;
  webDeviceToken: string | null;
  joinShortId?: string;
}

export interface ListenerData {
  id: number;
  name: string;
  avatarUrl: string;
  workspaceId: number;
  online: boolean;
  voiceChannelId: number | null;
  connections: { from: DeviceType; version: string }[];
  guestJoinId: number | null;
  speechToText: SpeechToTextSettings;
  joinShortId?: string;
}

export interface Screenshare {
  mySharedScreen: {
    id: string | null;
    name?: string | null;
    screenIndex?: number;
    isDrawingAllowed: boolean;
    viewers?: number[];
  };
}

export interface VideoCall {
  device: string | null;
}

export interface ActiveUser {
  user: Member;
  isExpanded: boolean;
}

export interface Device {
  flag: string;
  name: string;
  version: string;
}

export type NotificationType = "info" | "error";

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, Action<string>>;

export enum ResourceState {
  LOADING = "loading",
  NOT_FOUND = "not found",
  FOUND = "found",
}

export interface RemoteAccessRequestNotification {
  userId: number;
  userName?: string;
}

export interface ActiveSpeaker {
  workspaceId: number;
  voiceChannelId: number;
  userId: number;
}

/**
 * There are more options on https://recoursive.com/2020/03/03/reset_macos_privacy_permissions/
 * Adding only what we need for now.
 */
export type MacPermission = "ScreenCapture" | "Microphone" | "Camera" | "Accessibility";

export interface MacRequiredPermissionModalData {
  permission: MacPermission;
  title: string;
  message: string;
}

export interface Position {
  x: number;
  y: number;
}

export interface PrevLocation {
  voiceChannelId: number;
  position: Position;
}
export interface InteractableElement extends Position {
  height: number;
  width: number;
}

export interface NotificationWindowPosition {
  position: {
    x: number;
    y: number;
  };
  screenResolution: {
    width: number;
    height: number;
  };
}

export type PIPWindowNotificationTypes =
  | "meeting-request"
  | "incoming-call"
  | "call-invite-request"
  | "invitaion-responses"
  | "mute-unmute-request"
  | "screen-share-request"
  | "silence-unsilence-request"
  | "get-closer-request"
  | "screen-share-notification"
  | "unmute-in-idle-notification"
  | "camera-in-idle-notification"
  | "broadcast-active"
  | "remote-access-request"
  | "meeting-reminder-request"
  | "knocker-list"
  | "subscribing-knock"
  | "white-board-notification"
  | "text-editor-notification"
  | "record-audio-extraction-notification"
  | "missed-call"
  | "text-message-reaction";

export interface PIPNotifications {
  notificationsType: PIPWindowNotificationTypes;
  data?:
    | UnmuteUnslienceRequestReceive
    | InviteRequestVoiceChannel
    | ScreenshareRequestReceive
    | InviteGotResponseVoiceChannel
    | InviteReceiveVoiceChannel
    | RemoteAccessRequestNotification
    | ReminderMeetingVoiceChannel[]
    | UserScreenActiveNotification[]
    | UpdateBroadcastActiveNotification[]
    | KnockerListPIPData
    | SubscribingKnockPipData[]
    | RecordAudioExtractionPipData
    | TextChatMessageReaction;
  timestamp?: number;
  isAutoRemovable?: boolean;
}

export type TextChatMessageReaction = {
  emoji: string;
  groupId: string;
  createdBy: number;
  name: string;
  text?: string;
};

export type RecordAudioExtractionPipData = {
  currentActiveWindowFullName: string;
  sharingOptions: ipc.ScreenShareWindowSource | undefined;
  notificationKey: string;
  doRecord: boolean;
};

export type ErrorTypes = "no-permission" | "service-unavailable" | "unexpected-error";

export type ProjectDetailData = {
  id: number;
  name: string;
  workspaceId: number;
  createdById: number;
  isDefault: boolean;
  members: { id: number; projectId: number }[];
};

export type FloorDetailData = {
  id: number;
  name: string;
  workspaceId: number;
  members: number[];
};

export interface RecordingUser {
  userId: number;
  displayName: string;
  avatarUrl: string;
  guestJoinId: number | null;
}

export interface MeetingRecording {
  id: number;
  name: string | null;
  fileName: string;
  meetingRecordingWebmUrl: string;
  meetingRecordingMp4Url?: string;
  recordingDuration: string;
  createdAt: Date;
  meetingName: string | null;
  floorName: string | null;
  startTime: Date;
  users?: RecordingUser[];
  isEntireOfWorkspacePermitted: boolean;
  permittedGroups: number[] | null;
  permittedUsers: number[] | null;
}

export interface RemoteControl {
  remoteUserId: number;
}

export interface LanguageOption {
  code: string;
  label: {
    origin: string;
    en: string;
    ja: string;
    vi: string;
    ko: string;
  };
  ttsVariations: {
    code: string;
    name: {
      origin: string;
      en: string;
      ja: string;
      vi: string;
      ko: string;
    };
  }[];
  sttVariations: {
    custom?: boolean;
    code: string;
    name: {
      origin: string;
      en: string;
      ja: string;
      vi: string;
      ko: string;
    };
    default?: boolean;
  }[];
  translateVariations: {
    code: string;
    name: {
      origin: string;
      en: string;
      ja: string;
      vi: string;
      ko: string;
    };
  }[];
}

export interface SttEndpoint {
  id: number;
  language: string;
  code: string;
  enDisplayName: string;
  jpDisplayName: string;
  viDisplayName: string;
  koDisplayName: string;
  region: string;
  subscriptionId: string;
  endpointId: string;
  enabled: boolean;
  modelType: "speechToText" | "translator";
  sttCode: string;
}

export interface TranslatorEndpoint {
  id: number;
  enDisplayName: string;
  jpDisplayName: string;
  enabled: boolean;
  translationLanguages: string[] | null;
  modelType: "translator";
}

export type AiModelsEndpoint = SttEndpoint | TranslatorEndpoint;

export interface InitialBillingPlanData {
  price: number | "Free";
  planName: string;
  currency: string;
  term: string | null;
}

export interface PaymentMethod {
  paymentMethodId: string;
  last4: string;
  current: boolean;
  expiringDate: Date;
}

export type PaymentMethods = Array<PaymentMethod>;

export type SubscriptionPlans = Array<SubscriptionPlan>;

export interface BillingLog {
  state: "current" | "previous";
  genre: string;
  evnetDate: Date;
  status:
    | "SUBSCRIPTION_CREATED"
    | "SUBSCRIPTION_DELETED"
    | "SUBSCRIPTION_PENDING_UPDATED_APPLIED"
    | "SUBSCRIPTION_PENDING_UPDATED_EXPIRED"
    | "SUBSCRIPTION_TRIAL_WILL_END"
    | "SUBSCRIPTION_WILL_BE_DELETED"
    | "SUBSCRIPTION_WILL_BE_CHANGED"
    | "SUBSCRIPTION_UPDATED"
    | "SUBSCRIPTION_RESTORED"
    | "SUBSCRIPTION_PAYMENTMETHOD_CHANGED"
    | "SUBSCRIPTION_UPGRADED"
    | "SUBSCRIPTION_CONTINUED"
    | "PAYMENTMETHOD_ATTACHED"
    | "PAYMENTMETHOD_DETTACHED"
    | "PAYMENTMETHOD_UPDATED"
    | "FIRST_PAYMENT_FAILED"
    | "CONTINUATION_PAYMENT_FAILED"
    | "TRIAL_SUBSCRIPTION_CREATED"
    | "TRIAL_SUBSCRIPTION_UPDATED"
    | "TRIAL_SUBSCRIPTION_CANCELED";
  startDate: Date | null;
  endDate: Date | null;
  invoiceUrl: string | null | undefined;
  name: String | null;
  price: number | null;
  currency: string | null;
}

export type FrontObjectType = "object" | "avatar";

export interface FrontObject {
  type: FrontObjectType;
  objectId?: string;
  userId?: number;
}

export type Translator = "azure" | "google" | "deepL";

export interface TURN {
  url: string[];
  username: string;
  password: string;
}

export interface Region {
  id: number;
  enName: string;
  jaName: string;
  turn: TURN;
  iceTransportPolicy: boolean;
  defaultRegion?: string;
  environment: string;
  livekit: string;
}

export interface LivekitParticipant {
  identity: string;
  audioPublication?: TrackPublication;
  videoPublication?: TrackPublication;
  screensharePublication?: TrackPublication;
}

export interface MapTransform {
  workChannel?: {
    x?: number;
    y?: number;
    scale?: number;
  };
  voiceChannel?: {
    x?: number;
    y?: number;
    scale?: number;
  };
}

export interface WorkspaceActiveWindowPayload {
  userId: number;
  workspaceId: number;
  activeWindowName: string | null;
  activeWindowIcon: string | null;
}

export interface ActiveWindowData extends WorkspaceActiveWindowPayload {
  voiceChannelId: number;
}

export type AlertLevel = "LOW" | "MODERATE" | "HIGH";

export interface LanguageInconsistencyAlert {
  workspaceId: number;
  userId: number;
  textChannelId: number;
  azureOriginalLocale: string;
  detectedLanguage: string;
  confidence: number;
  sttCreatedAt: string;
}

export interface EgressRecording {
  id: number;
  recordingUrl: string;
  recordingDuration: string;
  transcriptId?: number;
  transcriptName?: string;
  startTime: Date;
  meetingId?: number;
  meetingName?: string;
  meetingParentId?: number;
  floorId: number;
  floorName: string;
  recordedById: number;
  avatarUrl: string;
  displayName: string;
}

export interface UpdateVoiceChannelModalData {
  id: number;
  parentVoiceChannelId: number | null;
  name: string;
  speechToTextLanguage: string;
  isTemporary: boolean;
  isPrivate: boolean;
  speechToTextEnabled: boolean;
  textToSpeechEnabled: boolean;
  guestEnabled: boolean;
  guestPassword: string | null;
  listenerEnabled: boolean;
  listenerMicEnabled: boolean;
  listenerPassword: string | null;
  workspaceBackgroundId: number;
  isAutoMeetingRecordingEnabled: boolean;
  isPositionLock: boolean;
  isDisableFloorAccess: boolean;
  isDefaultFloor?: boolean;
  isEnableRectangularView?: boolean;
  isScheduledMeeting: boolean;
  meetingParticipants?: ScheduleMeetingParticipant[];
  meetingSlots?: ScheduleMeetingSlot[];
  reminderInterval?: number;
  timezoneOffset?: number;
  isEnableMicForMtg?: boolean;
  isEnableWebCamForMtg?: boolean;
  isDeleteAllData?: boolean;
  knockSubscriberIds?: number[];
  showOriginalTextEnabledAsDefault: boolean;
}

export interface CreateVoiceChannelModalData {
  inviteTo?: number;
  parentVoiceChannelId: number | null;
  isPrivate: boolean;
  textToSpeechEnabled: boolean;
  workspaceBackgroundId: number;
  isScheduledMeeting: boolean;
  meetingParticipants?: ScheduleMeetingParticipant[];
  meetingSlots?: ScheduleMeetingSlot[];
  reminderInterval?: number;
}

export interface SettingsModalData {
  activeSection?: string;
}

export interface CropImageModalData {
  image: string;
  fetchPresignedUrl: string;
  onUpload: (url: string) => void;
}
