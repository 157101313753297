import React, { ReactNode } from "react";
import { RawIntlProvider } from "react-intl";
import cx from "classnames";
import { notification as antdNotification, Space } from "antd";

import "./Notification.scss";
import { Button, ButtonProps } from "../Button";
import { CloseIconOutlined } from "../../icons/material";
import { intl } from "../../../i18n";
import { NotificationDescription } from "./NotificationDescription";

export type NotificationVariant = "info" | "warning" | "success" | "meeting" | "knock";
export const DelayNotification = 5;

export type ButtonConfig = {
  icon?: ReactNode;
  type?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  label: ReactNode;
  onClick: React.MouseEventHandler<HTMLElement>;
};

export interface NotificationArgs {
  // custom args
  type: NotificationVariant;
  primaryButton?: ButtonConfig;
  secondaryButton?: ButtonConfig;
  closable?: boolean;
  // antd args
  key?: string;
  icon?: ReactNode;
  message?: ReactNode;
  description: ReactNode;
  duration?: number;
  onClick?: () => void;
  onClose?: () => void;
  knockers?: string[];
}

function open({
  // antd args
  key,
  icon,
  message,
  description,
  onClick,
  onClose,
  duration,
  // custom args
  type = "info",
  primaryButton,
  secondaryButton,
  closable = true,
  knockers,
}: NotificationArgs) {
  const btn = (primaryButton || secondaryButton) && (
    <IntlProvider>
      <Space>
        {secondaryButton && (
          <Button
            variant={secondaryButton.type ?? (type === "meeting" ? "primary" : "secondary")}
            color={secondaryButton.color ?? (type === "meeting" ? "negative" : "default")}
            size="small"
            onClick={secondaryButton.onClick}
            icon={secondaryButton.icon}
          >
            {secondaryButton.label}
          </Button>
        )}
        {primaryButton && (
          <Button
            variant={primaryButton.type ?? (type === "meeting" ? "primary" : "outline")}
            color={primaryButton.color ?? (type === "meeting" ? "positive" : "default")}
            size="small"
            onClick={primaryButton.onClick}
            icon={primaryButton.icon}
          >
            {primaryButton.label}
          </Button>
        )}
      </Space>
    </IntlProvider>
  );

  antdNotification.open({
    key,
    icon,
    message: <IntlProvider>{message}</IntlProvider>,
    description: (
      <IntlProvider>
        <NotificationDescription type={type} description={description} knockers={knockers} />
      </IntlProvider>
    ),
    className: cx("voiceping-notification", `voiceping-notification--${type}`, {
      clickable: !!onClick,
      "voiceping-notification-close-btn-hide": !closable,
    }),
    placement: "topRight",
    duration: duration ? duration : 0,
    btn,
    closeIcon: <CloseIconOutlined />,
    onClick,
    onClose,
  });
}

function close(key: string) {
  antdNotification.close(key);
}

function IntlProvider({ children }: React.PropsWithChildren<{}>) {
  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}

export const notification = { open, close, IntlProvider };
