import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import en from "dayjs/locale/en-gb"; // Using British to have Monday as the first week day
import ja from "dayjs/locale/ja";

// Use Monday as the first day of the week
en.weekStart = 1;
ja.weekStart = 1;

// Custom date formats -- based on the localizedFormat plugin's code
const customFormats = {
  Lt: "hh:mm a",
  // long date
  Ll: "dddd, MMMM Do, YYYY",
  // medium date
  lL: "ddd, MMM D",
  // medium date and time
  lLL: "ddd, MMM D [at] hh:mm a",
  // long date and day
  Lll: "ddd, MMM D, YYYY",
  // long date and time
  LDT: "ddd, MMM D, YYYY [at] hh:mm a",
};
const customFormatsJa = {
  Lt: "HH:mm",
  // long date
  Ll: "YYYY年M月D日（dd）",
  // medium date
  lL: "M月D日（dd）",
  // medium date and time
  lLL: "M月D日（dd）HH:mm",
  // long date and day
  Lll: "YYYY年M月D日 (ddd)",
  // long date and time
  LDT: "YYYY年M月DD日（ddd）HH:mm",
};

Object.assign(en.formats as any, customFormats);
Object.assign(en as any, { rangeSeparator: " - " });
Object.assign(ja.formats as any, customFormatsJa);
Object.assign(ja as any, { rangeSeparator: "～" });

const customFormatter = (formatStr: string, formats: any) =>
  formatStr.replace(/(\[[^\]]+])|(LDT|Lt|Lll|Ll|lLL?)/g, (_, a, b: keyof typeof customFormats) => {
    return a || formats[b] || customFormats[b];
  });

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend((_o, cls, _d) => {
  const proto = cls.prototype;
  const oldFormat = proto.format;

  proto.format = function format(formatStr?) {
    // sorry for the any, the type definition doesn't expose this method
    const { formats = en.formats } = (this as any).$locale();
    const result = formatStr ? customFormatter(formatStr, formats) : formatStr;

    return oldFormat.call(this, result);
  };
});
Object.assign(window, { dayjs });
