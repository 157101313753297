import { createSelector } from "reselect";

import {
  getOnlineWorkspaceUsers,
  getMyMember,
  getOfflineWorkspaceUsers,
  getOnlineWorkspaceListeners,
} from "../users/selectors";
import { State } from "../state";
import { ChannelsState } from "./reducer";
import { Member } from "../users";
import { getWorkspaceBackgrounds } from "../workspaceBackground/selectors";
import { ChannelData, LivekitParticipant, SpeechToTextSettings, SttEndpoint } from "../types";
import { checkIsGuest, getMyShortId, getSttEndpoints } from "../account";
import { getTranslationLanguageCodes } from "../../utils/helpers";

export const getVoiceChannelState = (state: State) => state.voiceChannels;

export const getAllVoiceChannels = createSelector(getVoiceChannelState, (voiceChannel: ChannelsState) => {
  return voiceChannel.voiceChannelsList;
});

export const getCurrentVoiceChannel = createSelector(getMyMember, getVoiceChannelState, (me, voiceChannelState) =>
  voiceChannelState.voiceChannelsList.find(c => c.id === me?.voiceChannelId),
);

export const getCurrentVoiceChannelUsers = createSelector(
  getCurrentVoiceChannel,
  getOnlineWorkspaceUsers,
  (voiceChannel, members) =>
    members.filter(
      u => u.voiceChannelId === voiceChannel?.id && u.role !== "meeting-recording-bot" && u.role !== "listener",
    ),
);

export const getCurrentVoiceChannelUsersIncludeListner = createSelector(
  getCurrentVoiceChannel,
  getOnlineWorkspaceUsers,
  getOnlineWorkspaceListeners,
  (voiceChannel, members, listeners) => [
    ...members.filter(u => u.voiceChannelId === voiceChannel?.id && u.role !== "meeting-recording-bot"),
    ...listeners.filter(l => l.voiceChannelId === voiceChannel?.id),
  ],
);

export const getCurrentVoiceChannelUsersPosition = createSelector(getOnlineWorkspaceUsers, getMyMember, (members, me) =>
  members
    .filter(
      u =>
        u.identity &&
        u.voiceChannelId === me?.voiceChannelId &&
        u.id !== me.id &&
        u.role !== "meeting-recording-bot" &&
        u.role !== "listener",
    )
    .map(el => ({ identity: el.identity!, broadcastActive: el.broadcastActive, position: el.position })),
);

export const getMyPosition = createSelector(
  getOnlineWorkspaceUsers,
  getMyMember,
  (members, me) =>
    members.find(u => u.identity && u.voiceChannelId === me?.voiceChannelId && u.id === me.id)?.position!,
);

export const getCurrentVoiceChannelUsersExceptMe = createSelector(
  getCurrentVoiceChannelUsers,
  getMyMember,
  (users, me) => users.filter(u => u.id !== me?.id),
);

export const getCurrentVoiceChannelMap = createSelector(
  getCurrentVoiceChannel,
  getWorkspaceBackgrounds,
  (voiceChannel, workspaceBackgrounds) => {
    if (voiceChannel?.map) {
      return voiceChannel?.map;
    } else {
      return workspaceBackgrounds.filter(wb => wb.id === voiceChannel?.workspaceBackgroundId)[0];
    }
  },
);

export const getInvitation = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.invitation);

export const getInvitationChannel = createSelector(
  getVoiceChannelState,
  getInvitation,
  (voiceChannelState, invitation) => voiceChannelState.voiceChannelsList.find(c => c.id === invitation?.voiceChannelId),
);

export const getInvitationUser = createSelector(getInvitation, getOnlineWorkspaceUsers, (invitation, users) =>
  users.find(u => u.id === invitation?.invitedByUserId),
);

export const isUserInChannel = (participants: LivekitParticipant[]) =>
  createSelector(getMyMember, me => participants.some(el => el.identity === me?.identity));

export const getMyTemporaryVoiceChannel = createSelector(getMyMember, getVoiceChannelState, (me, voiceChannelState) =>
  voiceChannelState.voiceChannelsList.find(c => c.isTemporary === true && c.id === me?.voiceChannelId),
);

export const getMeetingRoomsOnFloor = createSelector(
  getVoiceChannelState,
  getCurrentVoiceChannel,
  getOnlineWorkspaceUsers,
  (voiceChannelState: ChannelsState, voiceChannel: ChannelData | undefined, members: Member[]) => {
    return voiceChannelState.voiceChannelsList
      .filter(c => c.parentVoiceChannelId === voiceChannel?.id)
      .map(c => {
        const usersInChannel = members.filter(el => el.voiceChannelId === c.id);

        return { ...c, users: usersInChannel };
      });
  },
);

export const getMeetingRoomById = (id: number) =>
  createSelector(
    getVoiceChannelState,
    getOnlineWorkspaceUsers,
    (voiceChannelState: ChannelsState, members: Member[]) => {
      const meetingRoom = voiceChannelState.voiceChannelsList.find(el => el.id === id);

      return meetingRoom ? { meetingRoom, users: members.filter(el => el.voiceChannelId === id) } : undefined;
    },
  );

export const getUsersInMeetingRoomsOnFloor = createSelector(
  getMeetingRoomsOnFloor,
  getOnlineWorkspaceUsers,
  (meetingRooms: ChannelData[], members: Member[]) => {
    const usersInMeetingRooms = members.filter(el => meetingRooms.find(rl => rl.id === el.voiceChannelId));

    return usersInMeetingRooms;
  },
);

export const getOnlineUserIdsOnFloor = createSelector(
  getVoiceChannelState,
  getCurrentVoiceChannel,
  getOnlineWorkspaceUsers,
  (voiceChannelState: ChannelsState, voiceChannel: ChannelData | undefined, members: Member[]) => {
    const allVoiceChannelIncludeFloor = voiceChannelState.voiceChannelsList.filter(c =>
      voiceChannel?.parentVoiceChannelId
        ? c.parentVoiceChannelId === voiceChannel.parentVoiceChannelId || c.id === voiceChannel.parentVoiceChannelId
        : c.parentVoiceChannelId === voiceChannel?.id || c.id === voiceChannel?.id,
    );

    return members.filter(m => allVoiceChannelIncludeFloor.some(c => c.id === m.voiceChannelId)).map(m => m.id);
  },
);

export const getOnlineUserIdsOnSameFloor = createSelector(
  getCurrentVoiceChannel,
  getOnlineWorkspaceUsers,
  (voiceChannel: ChannelData | undefined, members: Member[]) => {
    const memberOnSameFloor = members.filter(m => m.voiceChannelId === voiceChannel?.id);

    return memberOnSameFloor.map(m => m.id);
  },
);

export const getIsChannelIsLoading = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.loading);
export const getIsChannelJoining = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.joining);
export const ongoingChannelCreationRequest = createSelector(
  getVoiceChannelState,
  voiceChannel => voiceChannel.creating,
);

export const getPendingInvitations = createSelector(
  getVoiceChannelState,
  voiceChannel => voiceChannel.pendingInvitations,
);

export const getLaterJoins = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.laterJoins);

export const getSpeakingWhileMutedStatus = createSelector(
  getVoiceChannelState,
  voiceChannel => voiceChannel.speakingWhileMuted,
);

export const getRestartVADStatus = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.restartVAD);

export const amIInAVoiceChannel = createSelector(getCurrentVoiceChannel, voiceChannel =>
  voiceChannel?.id ? true : false,
);

export const getCloserInvitation = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.getCloserInvite);

export const isGetCloserInvite = createSelector(
  getVoiceChannelState,
  voiceChannel => voiceChannel.invitation?.isGetCloserInvite,
);

export const getCurrentVoiceChannelLastMeeting = createSelector(
  getCurrentVoiceChannel,
  (voiceChannel: ChannelData | undefined) => voiceChannel?.lastMeetingAt,
);

export const getAllFloors = createSelector(getAllVoiceChannels, channels =>
  channels.filter(c => !c.parentVoiceChannelId),
);

export const getAllFloorIds = createSelector(getAllFloors, floors => floors.map(f => f.id));

export const getAllFloorsCount = createSelector(
  getAllVoiceChannels,
  channels => channels.filter(c => !c.parentVoiceChannelId).length,
);

export const getSpeechToTextEnabled = createSelector(
  getCurrentVoiceChannel,
  (voiceChannel: ChannelData | undefined) => voiceChannel?.speechToTextEnabled && !!voiceChannel.parentVoiceChannelId,
);

export const getTextToSpeechEnabled = createSelector(
  getCurrentVoiceChannel,
  (voiceChannel: ChannelData | undefined) => voiceChannel?.textToSpeechEnabled,
);

export const getAutoMeetingRecordingEnabledStatus = createSelector(
  getCurrentVoiceChannel,
  (voiceChannel: ChannelData | undefined) => !!voiceChannel?.isAutoMeetingRecordingEnabled,
);

export const getAutoMeetingRecordingFeatureAvailedStatus = createSelector(
  getCurrentVoiceChannel,
  (voiceChannel: ChannelData | undefined) => !!voiceChannel?.isAutoMeetingRecordingFeatureAvailed,
);

export const getVoiceChannelById = (id: number) =>
  createSelector(getAllVoiceChannels, channels => channels.find(c => c.id === id));

export const getCollapsedVoiceChannels = createSelector(
  getVoiceChannelState,
  voiceChannelState => voiceChannelState.collapsedVoiceChannels,
);

export const getIsFromCustomBackground = createSelector(
  getVoiceChannelState,
  voiceChannelState => voiceChannelState.isFromCustomBackground,
);

export const getIsGetCloserWasAccepted = createSelector(
  getVoiceChannelState,
  voicechannelState => voicechannelState.isGetCloserWaAccepted,
);

export const getKnockersByVoiceChannelId = (voiceChannelId: number) =>
  createSelector(getVoiceChannelById(voiceChannelId), v => v?.knocker);

export const getTextChatAllowMembers = createSelector(
  getAllVoiceChannels,
  checkIsGuest,
  getOnlineWorkspaceUsers,
  getOfflineWorkspaceUsers,
  getMyMember,
  (allVoiceChannels: ChannelData[], isGuest, onlineMembers, offlineMembers, me) => {
    const floorVoiceChannels = allVoiceChannels.filter(c =>
      me?.assignedFloors?.length === 0
        ? isGuest
          ? c.id === me?.guestJoinId
          : c.parentVoiceChannelId === null
        : me?.assignedFloors?.some(av => av === c.id),
    );

    const meetingVoiceChannels = allVoiceChannels.filter(c =>
      floorVoiceChannels.some(a => a.id === c.parentVoiceChannelId),
    );

    const allAllowChannels = [...floorVoiceChannels, ...meetingVoiceChannels];

    if (isGuest) {
      return onlineMembers.filter(a => allAllowChannels.some(z => z.id === a.voiceChannelId));
    } else {
      return [
        ...onlineMembers.filter(a => allAllowChannels.some(z => z.id === a.voiceChannelId)),
        ...offlineMembers.filter(m => m.hasMobileApp || m.webDeviceToken),
      ];
    }
  },
);

export const getTextChatAllowMembersId = createSelector(getTextChatAllowMembers, members => members.map(m => m.id));

export const getMyKnock = createSelector(getVoiceChannelState, voiceChannel => voiceChannel.knocking);

export const isVoiceChannelMeetingRoom = createSelector(
  getCurrentVoiceChannel,
  voiceChannel => voiceChannel?.parentVoiceChannelId !== null,
);

export const getAllFloorMembersForInvite = createSelector(
  getAllVoiceChannels,
  getOnlineWorkspaceUsers,
  getMyMember,
  getCurrentVoiceChannel,
  (allVoiceChannels: ChannelData[], onlineMembers, me, currentVoiceChannel) => {
    if (currentVoiceChannel) {
      if (currentVoiceChannel.parentVoiceChannelId) {
        //Meeting Room
        const currentFloorVoiceChannel = allVoiceChannels.find(
          a => a.id === currentVoiceChannel.parentVoiceChannelId,
        ) as ChannelData;

        const meetingRooms = allVoiceChannels.filter(a => a.parentVoiceChannelId === currentFloorVoiceChannel?.id);

        return onlineMembers.filter(
          a =>
            a.role !== "guest" &&
            [currentFloorVoiceChannel, ...meetingRooms].some(c => c.id === a.voiceChannelId) &&
            a.id !== me?.id &&
            a.voiceChannelId !== currentVoiceChannel.id,
        );
      } else {
        //Floor
        const meetingRooms = allVoiceChannels.filter(a => a.parentVoiceChannelId === currentVoiceChannel?.id);

        return onlineMembers.filter(
          a =>
            a.role !== "guest" &&
            [currentVoiceChannel, ...meetingRooms].some(c => c.id === a.voiceChannelId) &&
            a.id !== me?.id,
        );
      }
    }
  },
);

export const getAllWorkspaceMembersForInvite = createSelector(
  getAllVoiceChannels,
  getOnlineWorkspaceUsers,
  getMyMember,
  getCurrentVoiceChannel,
  (allVoiceChannels: ChannelData[], onlineMembers, me, currentVoiceChannel) => {
    if (currentVoiceChannel) {
      if (currentVoiceChannel.parentVoiceChannelId) {
        //Meeting Room
        const currentFloorVoiceChannel = allVoiceChannels.find(
          a => a.id === currentVoiceChannel.parentVoiceChannelId,
        ) as ChannelData;

        if (currentFloorVoiceChannel) {
          return onlineMembers.filter(
            a =>
              a.role !== "guest" &&
              a.assignedFloors?.some(c => c === currentFloorVoiceChannel.id) &&
              a.id !== me?.id &&
              a.voiceChannelId !== currentVoiceChannel.id,
          );
        }
      } else {
        //Floor
        return onlineMembers.filter(
          a => a.role !== "guest" && a.assignedFloors?.some(c => c === currentVoiceChannel.id) && a.id !== me?.id,
        );
      }
    }
  },
);

export const getValidOfflineMembersForInvite = createSelector(
  getCurrentVoiceChannel,
  getOfflineWorkspaceUsers,
  (currentVoiceChannel, offlineMembers) => {
    if (currentVoiceChannel) {
      const floorId = currentVoiceChannel?.parentVoiceChannelId
        ? currentVoiceChannel?.parentVoiceChannelId
        : currentVoiceChannel?.id;

      const validOfflineUsersForInvite = offlineMembers.filter(
        a => a.assignedFloors?.some(f => f === floorId) && (a.webDeviceToken || a.hasMobileApp),
      );

      return validOfflineUsersForInvite;
    }
  },
);

export const getVoiceChannelListenerEnabled = (voiceChannelId: number) =>
  createSelector(getVoiceChannelById(voiceChannelId), v => v?.listenerEnabled);

export const getListenerMicEnabled = createSelector(getCurrentVoiceChannel, v => v?.listenerMicEnabled);

export const getParticipantsSttSettings = createSelector(getCurrentVoiceChannelUsersIncludeListner, members => {
  return members?.map(m => m.speechToText);
});

export const getUsingLanguageInCurrentVoiceChannel = createSelector(
  getParticipantsSttSettings,
  getSttEndpoints,
  getCurrentVoiceChannel,
  getMyMember,
  (
    sttSettings: SpeechToTextSettings[] | undefined,
    sttEndpoints: SttEndpoint[],
    voiceChannel: ChannelData | undefined,
    me: Member | undefined,
  ) => {
    if (voiceChannel && sttSettings) {
      if (me) {
        sttSettings.push(me.speechToText);
      }

      return getTranslationLanguageCodes(sttSettings, sttEndpoints, voiceChannel);
    }
  },
);

export const getMyUsingLanguages = createSelector(
  getOnlineWorkspaceListeners,
  getSttEndpoints,
  getMyMember,
  getMyShortId,
  getCurrentVoiceChannel,
  (
    onlineListeners,
    sttEndpoints: SttEndpoint[],
    me: Member | undefined,
    myShortId: string,
    voiceChannel: ChannelData | undefined,
  ) => {
    let sttSettings = onlineListeners.filter(l => l.joinShortId === myShortId).map(m => m.speechToText);

    if (me) {
      sttSettings.push(me.speechToText);
    }

    return getTranslationLanguageCodes(sttSettings, sttEndpoints, voiceChannel);
  },
);

export const getCurrentVoiceChannelIsOriginalTextChatShow = createSelector(
  getCurrentVoiceChannel,
  voiceChannel => voiceChannel?.showOriginalTextEnabledAsDefault,
);
