import { DevicesAction, DevicesActionType } from "./actions";

export interface DeviceSetting {
  videoinput: { id: string; label: string; groupId: string };
  audioinput: { id: string; label: string; groupId: string };
  audiooutput: { id: string; label: string; groupId: string };
}

export const devicesInitialState = {
  videoInputDevices: [] as MediaDeviceInfo[],
  audioInputDevices: [] as MediaDeviceInfo[],
  audioOutputDevices: [] as MediaDeviceInfo[],
  deviceSetting: {
    videoinput: { id: "", label: "", groupId: "" },
    audioinput: { id: "", label: "", groupId: "" },
    audiooutput: { id: "", label: "", groupId: "" },
  } as DeviceSetting,
  noiseReduction: false,
  requestOpenAudioOutputDeviceSetting: false,
  audioInputAutoGainControl: true,
  isVirtualBackgroundOn: false as boolean,
  resolution: {
    camera: "qvga",
  },
  localAudioStream: undefined as MediaStreamTrack | undefined,
  guestDeviceAccessPermission: false as Boolean,
};

export type DevicesState = typeof devicesInitialState;

export function devicesReducer(state: DevicesState = devicesInitialState, action: DevicesAction): DevicesState {
  switch (action.type) {
    case DevicesActionType.UPDATE_DEVICE_SETTINGS:
      return {
        ...state,
        deviceSetting: { ...state.deviceSetting, ...action.payload.deviceSetting },
      };
    case DevicesActionType.UPDATE_RESOLUTION:
      return { ...state, resolution: { ...state.resolution, [`${action.payload.type}`]: action.payload.resolution } };
    case DevicesActionType.UPDATE_NOISE_REDUCTION:
      return {
        ...state,
        noiseReduction: action.payload.noiseReduction,
      };
    case DevicesActionType.UPDATE_VIDEO_INPUT_DEVICES:
      return {
        ...state,
        videoInputDevices: action.payload.devices ?? [],
      };
    case DevicesActionType.UPDATE_AUDIO_INPUT_DEVICES:
      return {
        ...state,
        audioInputDevices: action.payload.devices ?? [],
      };
    case DevicesActionType.UPDATE_AUDIO_OUTPUT_DEVICES:
      return {
        ...state,
        audioOutputDevices: action.payload.devices ?? [],
      };
    case DevicesActionType.OPEN_AUDIO_OUTPUT_DEVICE_SETTING:
      return {
        ...state,
        requestOpenAudioOutputDeviceSetting: true,
      };
    case DevicesActionType.CLEAR_OPEN_AUDIO_OUTPUT_DEVICE_SETTING:
      return {
        ...state,
        requestOpenAudioOutputDeviceSetting: false,
      };
    case DevicesActionType.TOGGLE_AUTO_GAIN_STATUS:
      return {
        ...state,
        audioInputAutoGainControl: action.payload.autoGainStatus,
      };
    case DevicesActionType.UPDATE_IS_VIRTUAL_BACKGROUND_ON:
      return {
        ...state,
        isVirtualBackgroundOn: action.payload.isVirtualBackgroundOn,
      };
    case DevicesActionType.UPDATE_LOCAL_AUDIO_STREAM:
      return {
        ...state,
        localAudioStream: action.payload.localAudioStream,
      };
    case DevicesActionType.SET_GUEST_DEVICE_PERMISSION:
      return {
        ...state,
        guestDeviceAccessPermission: action.payload.guestDeviceAccessPermission,
      };
    default:
      return state;
  }
}
