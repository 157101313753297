import { DemoWorkspaceActionsTypes, DemoWorkspaceActions } from "./actions";

interface DemoWorkspaceState {
  isLoading: boolean;
  hasError: boolean;
}

export const demoWorkspaceInitialState = {
  isLoading: false,
  hasError: false,
};

export function demoWorkspaceReducer(
  state: DemoWorkspaceState = demoWorkspaceInitialState,
  action: DemoWorkspaceActionsTypes,
): DemoWorkspaceState {
  switch (action.type) {
    case DemoWorkspaceActions.SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case DemoWorkspaceActions.SET_ERROR:
      return {
        ...state,
        hasError: action.payload,
      };

    default:
      return state;
  }
}
