import { Action } from "redux";
import { AppThunk } from "../types";
import * as memberHttpApi from "../../api/member";
import { Floor, GroupData, ProjectData } from "./types";

export enum MemberActions {
  SET_BASE_INFO = "member/SET_BASE_INFO",
  SET_PROJECTS = "member/SET_PROJECTS",
  SET_GROUPS = "member/SET_GROUPS",
  ADD_GROUP = "member/ADD_GROUP",
  ADD_PROJECT = "member/ADD_PROJECT",
  ADD_FLOOR = "member/ADD_FLOOR",
}

export interface SetBaseInfoAction extends Action {
  type: MemberActions.SET_BASE_INFO;
  payload: { project: ProjectData[]; floor: Floor[] };
}

export type MemberActionTypes =
  | ReturnType<typeof fetchBaseInfoAction>
  | ReturnType<typeof fetchProjectsAction>
  | ReturnType<typeof fetchGroupsAction>
  | ReturnType<typeof addGroupAction>
  | ReturnType<typeof addProjectAction>
  | ReturnType<typeof addFloorAction>;

function fetchBaseInfoAction(projects: ProjectData[], floors: Floor[], groups: GroupData[]) {
  return { type: MemberActions.SET_BASE_INFO, payload: { projects, floors, groups } } as const;
}

export function fetchBaseInfo(workspaceId: number): AppThunk {
  return async dispatch => {
    const data = await memberHttpApi.getBaseInfo(workspaceId);

    if (data) {
      dispatch(fetchBaseInfoAction(data.project, data.floor, data.group));
    }
  };
}

function fetchProjectsAction(projects: ProjectData[]) {
  return { type: MemberActions.SET_PROJECTS, payload: { projects } } as const;
}

export function fetchProjects(workspaceId: number): AppThunk {
  return async dispatch => {
    const data = await memberHttpApi.getProjectByWorkspaceId(workspaceId);

    if (data) {
      dispatch(fetchProjectsAction(data.projects));
    }
  };
}

export function fetchGroupsAction(groups: GroupData[]) {
  return { type: MemberActions.SET_GROUPS, payload: { groups } } as const;
}

export function fetchGroups(workspaceId: number): AppThunk {
  return async dispatch => {
    const data = await memberHttpApi.getGroupByWorkspaceId(workspaceId);

    dispatch(fetchGroupsAction(data?.groups || []));
  };
}

function addProjectAction(project: ProjectData) {
  return { type: MemberActions.ADD_PROJECT, payload: { project } } as const;
}

export function addProjectResponse(project: ProjectData): AppThunk {
  return async dispatch => {
    dispatch(addProjectAction(project));
  };
}

function addGroupAction(group: GroupData) {
  return { type: MemberActions.ADD_GROUP, payload: { group } } as const;
}

export function addGroupResponse(group: GroupData): AppThunk {
  return async dispatch => {
    dispatch(addGroupAction(group));
  };
}

function addFloorAction(floor: Floor) {
  return { type: MemberActions.ADD_FLOOR, payload: { floor } } as const;
}

export function addFloorResponse(floor: Floor): AppThunk {
  return async dispatch => {
    dispatch(addFloorAction(floor));
  };
}
