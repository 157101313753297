import { State } from "../state";

export function isLoading(state: State) {
  return state.invitationJoin.isLoading;
}
export function getErrors(state: State) {
  return state.invitationJoin.errors;
}

export function getInvitedUserEmail(state: State) {
  return state.invitationJoin.invitedUserEmail;
}

export function getUserRegisteredStatus(state: State) {
  return state.invitationJoin.isUserRegistered;
}
