import { createSelector } from "reselect";
import { MeetingRecordingState } from ".";
import { State } from "../state";

export function getMeetingRecordingState(state: State): MeetingRecordingState {
  return state.meetingRecordings;
}

export const getMeetingRecordingList = createSelector(
  getMeetingRecordingState,
  (meetingRecording: MeetingRecordingState) => {
    return meetingRecording.meetingRecordings;
  },
);

export const getMeetingRecordingStatus = createSelector(
  getMeetingRecordingState,
  (meetingRecording: MeetingRecordingState) => {
    return meetingRecording.isMeetingRecordingOn;
  },
);

export const getMeetingRecordingLoadingStatus = createSelector(
  getMeetingRecordingState,
  (meetingRecording: MeetingRecordingState) => {
    return meetingRecording.loading;
  },
);
