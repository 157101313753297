import fetchIntercept from "fetch-intercept";

import { history } from "../routes/history";
import { store } from "../store";
import { APP_NO_PERMISSION_ROUTE, APP_SERVICE_UNAVAILABLE_ROUTE } from "../routes";
import { setUserLogout } from "../store/account";

fetchIntercept.register({
  response: function (response) {
    if (response.status === 401) {
      // @ts-ignore AppThunk type issue, we should use event based middleware
      store.dispatch(setUserLogout());
    } else if (response.status === 403 && !window.electron) {
      history.push(APP_NO_PERMISSION_ROUTE);
    } else if (response.status >= 500 && !window.electron) {
      history.push(APP_SERVICE_UNAVAILABLE_ROUTE);
    }

    return response;
  },
});
