import * as Sentry from "@sentry/browser";
import type { AppThunk } from "../../store/types";
import type { Module, Version } from "./reducer";
import { versionString } from "../../utils/helpers";
import { createRequest } from "../../utils/network";
import { channels } from "../../electron/channels";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { getCurrentEnvironment } from "../../utils/environment";
import { getWebAppLatestCommitHash } from "./selectors";

export const GIT_COMMIT_LATEST_INTERVAL_VALUE = 600_000; // check latest git commit every 10 mins

export enum VersionActions {
  LOAD_GIT_INFO = "version/LOAD_GIT_INFO",
  LOADED_GIT_INFO = "version/LOADED_GIT_INFO",
  UPDATE_LATEST_WEB = "version/UPDATE_LATEST_WEB",
  LOAD_DESKTOP_APP_VERSION = "version/LOAD_DESKTOP_APP_VERSION",
  LOADED_DESKTOP_APP_VERSION = "version/LOADED_DESKTOP_APP_VERSION",
  LOAD_LATEST_DESKTOP_APP_VERSION = "version/LOAD_LATEST_DESKTOP_APP_VERSION",
  DOWNLOAD_LATEST_DESKTOP_APP_VERSION = "version/DOWNLOAD_LATEST_DESKTOP_APP_VERSION",
  LOADED_LATEST_DESKTOP_APP_VERSION = "version/LOADED_LATEST_DESKTOP_APP_VERSION",
}

export function updateLatestWeb(latestWebCommitHash: string) {
  return { type: VersionActions.UPDATE_LATEST_WEB, payload: { latestWebCommitHash } } as const;
}

export function load(module: Module) {
  return { type: VersionActions.LOAD_GIT_INFO, payload: { module } } as const;
}

export function loaded(module: Module, version: Version) {
  return { type: VersionActions.LOADED_GIT_INFO, payload: { module, version } } as const;
}
function loadAppVersionAction() {
  return { type: VersionActions.LOAD_DESKTOP_APP_VERSION } as const;
}

function loadedAppVersionAction(version: string) {
  return {
    type: VersionActions.LOADED_DESKTOP_APP_VERSION,
    payload: { version },
  } as const;
}

function loadLatestAppVersionAction() {
  return { type: VersionActions.LOAD_LATEST_DESKTOP_APP_VERSION } as const;
}

function loadedLatestAppVersionAction(latestVersion: string) {
  return {
    type: VersionActions.LOADED_LATEST_DESKTOP_APP_VERSION,
    payload: { latestVersion },
  } as const;
}

export function loadWebVersion(): AppThunk {
  return async (dispatch: Function) => {
    dispatch(load("web"));

    const version: Version = {
      loaded: true,
      environment: getCurrentEnvironment(),
      commit: process.env.REACT_APP_GIT_COMMIT_HASH || "not-set",
      branch: process.env.REACT_APP_GIT_CURRENT_BRANCH || "not-set",
    };

    configureVersionOnSentry("web", version);
    dispatch(loaded("web", version));
    dispatch(updateLatestWeb(process.env.REACT_APP_GIT_COMMIT_HASH || ""));
  };
}

export function loadMediaVersion(): AppThunk {
  return async (dispatch: Function) => {
    dispatch(load("media"));

    const host = process.env.REACT_APP_PROTOO_HOST!.replace("wss://", "https://");

    const httpResponse = await fetch(`${host}/status`, { method: "GET" });
    const responseContent = await httpResponse.json();
    const version: Version = await Promise.resolve({
      loaded: true,
      environment: responseContent.environment || "not-set",
      commit: responseContent.commitHash || "not-set",
      branch: responseContent.currentBranch || "not-set",
    });

    configureVersionOnSentry("media", version);
    dispatch(loaded("media", version));
  };
}

function configureVersionOnSentry(module: Module, version: Version) {
  Sentry.configureScope(scope =>
    scope.setTags({
      [`version.${module}`]: versionString(version),
    }),
  );
}

export function loadServerVersion(): AppThunk {
  return async (dispatch: Function) => {
    dispatch(load("server"));

    try {
      type StatusResponse = {
        data: {
          commitHash: string;
          currentBranch: string;
          environment: string;
          externalIpAddresses: string[];
        };
      };
      const result = await createRequest<StatusResponse>("/status");
      const version: Version = {
        loaded: true,
        environment: result.response.data.environment || "not-set",
        commit: result.response.data.commitHash || "not-set",
        branch: result.response.data.currentBranch || "not-set",
      };

      configureVersionOnSentry("server", version);
      dispatch(loaded("server", version));
    } catch (err) {
      console.error(err);
    }
  };
}

export function loadLatestWebVersion(): AppThunk {
  return async (dispatch, getState) => {
    const noCacheHeaders = new Headers();

    noCacheHeaders.append("pragma", "no-cache");
    noCacheHeaders.append("cache-control", "no-cache");

    const response = await fetch("/version.txt", { method: "GET", headers: noCacheHeaders });
    const version = await response.text();
    const newLatest = version.trim();

    const currentLatest = getWebAppLatestCommitHash(getState());

    if (currentLatest !== newLatest) {
      dispatch(updateLatestWeb(newLatest));
    }
  };
}

export function downloadLatestAppVersionAction(isLoading: boolean) {
  return {
    type: VersionActions.DOWNLOAD_LATEST_DESKTOP_APP_VERSION,
    payload: { isLoading },
  } as const;
}

export function loadDesktopVersionInfo(commit: string, branch: string): AppThunk {
  return async (dispatch: Function) => {
    if (window.electron) {
      dispatch(load("desktop"));

      const version: Version = {
        loaded: true,
        environment: getCurrentEnvironment(),
        commit: commit || "not-set",
        branch: branch || "not-set",
      };

      configureVersionOnSentry("desktop", version);
      dispatch(loaded("desktop", version));
    }
  };
}

export function loadAppVersion(): AppThunk {
  return async dispatch => {
    if (window.electron) {
      dispatch(loadAppVersionAction());
      const appVersion = await sendMessageOverIPC(channels.APP_VERSION);

      dispatch(loadDesktopVersionInfo(appVersion.commit, appVersion.branch));

      if (appVersion.version) {
        dispatch(loadedAppVersionAction(appVersion.version));
      }
    }
  };
}

export function loadAppLatestVersion(): AppThunk {
  return async dispatch => {
    if (window.electron) {
      const latestVersion = await sendMessageOverIPC(channels.APP_LATEST_VERSION);

      if (latestVersion) {
        dispatch(loadedLatestAppVersionAction(latestVersion));
      }
    }
  };
}

export type VersionActionTypes =
  | ReturnType<typeof load>
  | ReturnType<typeof loaded>
  | ReturnType<typeof loadAppVersionAction>
  | ReturnType<typeof loadedAppVersionAction>
  | ReturnType<typeof loadLatestAppVersionAction>
  | ReturnType<typeof loadedLatestAppVersionAction>
  | ReturnType<typeof downloadLatestAppVersionAction>
  | ReturnType<typeof updateLatestWeb>;
