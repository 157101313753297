import { TextChatReceiverOptionIds } from "../../screens/Dashboard/TextChat/ReceiverInput";
import { ChatMessage } from "../types";
import { TextChannelActionTypes, TextChannelActions, TextToSpeechResponse } from "./actions";

export interface TextChannelState {
  workspaceId: number;
  textChannelId: number;
  messages: ChatMessage[];
  audioDatas: TextToSpeechResponse[];
  hasMore: boolean;
  currentOldestMessageId?: number;
  lastMessageTime?: string;
  speechToText?: {
    token: string;
    region: string;
    vpHost?: string;
    jwtToken?: string;
  };
  reachedMaxSttUsage: boolean;
  showChatBox: boolean;
  dmUserIds: number[] | TextChatReceiverOptionIds | undefined;
  focusChatBox: boolean;
  isLoading: boolean;
  fullScreenChatBox: boolean;
  resizeEvent: boolean;
  keyboardActive: boolean;
  selectedMessage: ChatMessage | undefined;
}

export const textChannelInitialState: TextChannelState = {
  workspaceId: 0,
  textChannelId: 0,
  messages: [],
  audioDatas: [],
  hasMore: false,
  currentOldestMessageId: undefined,
  lastMessageTime: undefined,
  speechToText: undefined,
  reachedMaxSttUsage: false,
  showChatBox: false,
  focusChatBox: false,
  dmUserIds: undefined,
  isLoading: false,
  fullScreenChatBox: false,
  resizeEvent: false,
  keyboardActive: true,
  selectedMessage: undefined,
};

/**
 * Compare messages considering non-stored STT messages that won't have ID. In this case, fallback the comparison to the STT result ID.
 */
const cmpMessage = (a: ChatMessage, b: ChatMessage) =>
  (a.id && b.id && a.id === b.id) ||
  (a.speechRecognition && b.speechRecognition && a.speechRecognition.id === b.speechRecognition.id);

export function textChannelReducer(
  state: TextChannelState = textChannelInitialState,
  action: TextChannelActionTypes,
): TextChannelState {
  switch (action.type) {
    case TextChannelActions.SET_TEXT_CHANNEL:
      return {
        ...state,
        workspaceId: action.payload.workspaceId,
        textChannelId: action.payload.textChannelId,
        messages: [],
        hasMore: false,
        lastMessageTime: undefined,
      };
    case TextChannelActions.GET_MESSAGES:
      return {
        ...state,
        hasMore: false,
        lastMessageTime: action.payload.previousTo,
      };
    case TextChannelActions.GOT_MESSAGES:
      return {
        ...state,
        messages: [...action.payload.messages, ...state.messages],
        hasMore: action.payload.hasMore,
        currentOldestMessageId: action.payload.currentOldestMessageId,
      };
    case TextChannelActions.NEW_MESSAGE:
      const exists = action.payload.stt && state.messages.some(m => cmpMessage(m, action.payload.message));

      return {
        ...state,
        messages: !exists
          ? [...state.messages, calcTsOnMessage(action.payload.message, action.payload.myself)]
          : state.messages.map(m =>
              cmpMessage(m, action.payload.message)
                ? calcTsOnMessage(action.payload.message, action.payload.myself)
                : m,
            ),
      };
    case TextChannelActions.SPEECH_TO_TEXT_INIT:
      return { ...state, speechToText: action.payload };
    case TextChannelActions.UPDATE_VP_STT_SERVER:
      return { ...state, speechToText: state.speechToText ? { ...state.speechToText, ...action.payload } : undefined };
    case TextChannelActions.SPEECH_TO_TEXT_STOP:
      return { ...state, speechToText: undefined };
    case TextChannelActions.CLEAR_UNREAD_MESSAGES:
      return { ...state, messages: state.messages.map(el => ({ ...el, unread: false })) };
    case TextChannelActions.CLEAR_SELECTED_UNREAD_MESSAGES:
      return {
        ...state,
        messages: state.messages.map(el => {
          if (action.payload.messageIds.some(id => id === el.id)) {
            return { ...el, unread: false };
          } else {
            return el;
          }
        }),
      };
    case TextChannelActions.SHOW_CHAT_BOX:
      return { ...state, showChatBox: action.payload.isShow };
    case TextChannelActions.SET_FOCUS_CHAT_BOX:
      return { ...state, focusChatBox: action.payload.isFocus };
    case TextChannelActions.SET_DM_USERS:
      if (action.payload.userId) return { ...state, dmUserIds: action.payload.userId };

      return state;
    case TextChannelActions.UPDATE_IS_LOADING:
      return { ...state, isLoading: action.payload.loading };
    case TextChannelActions.TRANSLATED_MESSAGE:
      return {
        ...state,
        messages: state.messages.map(m => {
          return m.id === action.payload.message.id
            ? {
                ...action.payload.message,
                reactions: m.reactions,
              }
            : m;
        }),
      };
    case TextChannelActions.FULL_SCREEN_CHAT_BOX:
      return {
        ...state,
        fullScreenChatBox: action.payload.fullScreen,
      };
    case TextChannelActions.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case TextChannelActions.GOT_TEXT_TO_SPEECH:
      return {
        ...state,
        audioDatas: [action.payload],
      };
    case TextChannelActions.UPDATE_REACHED_MAX_STT_USAGE:
      return {
        ...state,
        reachedMaxSttUsage: action.payload.reachedSttMaxUsage,
      };
    case TextChannelActions.RESIZE_CHAT_BOX:
      return {
        ...state,
        resizeEvent: action.payload.resizeEvent,
      };
    case TextChannelActions.SET_KEYBOARD_ACTIVE:
      return {
        ...state,
        keyboardActive: action.payload.keyboardActive,
      };

    case TextChannelActions.DELETE_MESSAGE:
      const remainMessages = state.messages.filter(a =>
        action.payload.groupIds.length > 0
          ? !action.payload.groupIds.some(g => a.groupId === g)
          : a.id !== action.payload.messageId,
      );

      return {
        ...state,
        messages: remainMessages,
      };

    case TextChannelActions.UPDATE_MESSAGE:
      if (action.payload.groupId) {
        const groupMessages = state.messages.filter(a => action.payload.message.groupId === a.groupId);

        const groupIds = groupMessages.length === 1 ? [] : groupMessages.slice(1).map(a => a.id);

        return {
          ...state,
          messages: state.messages
            .filter(a => !groupIds.some(g => g === a.id))
            .map(m => {
              return m.groupId === action.payload.message.groupId
                ? {
                    ...m,
                    text: action.payload.message.text,
                    originalTextLanguage: action.payload.message.originalTextLanguage,
                  }
                : m;
            }),
        };
      } else {
        return {
          ...state,
          messages: state.messages.map(m => {
            return m.id === action.payload.message.id
              ? {
                  ...m,
                  text: action.payload.message.text,
                  originalTextLanguage: action.payload.message.originalTextLanguage,
                }
              : m;
          }),
        };
      }

    case TextChannelActions.ADD_MESSAGE_REACTION:
      const updatedMessages = state.messages.map(message => {
        const reactedMessage = action.payload.messageId
          ? message.id === action.payload.messageId
          : message.groupId === action.payload.groupId;

        if (reactedMessage) {
          const updatedReactions = message.reactions
            ? {
                ...message.reactions,
                [action.payload.emoji]: [
                  ...(message.reactions[action.payload.emoji] || []),
                  {
                    groupId: action.payload.groupId,
                    createdBy: action.payload.createdBy,
                    messageId: action.payload.messageId,
                  },
                ],
              }
            : {
                [action.payload.emoji]: [
                  {
                    groupId: action.payload.groupId,
                    createdBy: action.payload.createdBy,
                    messageId: action.payload.messageId,
                  },
                ],
              };

          return {
            ...message,
            reactions: updatedReactions,
          };
        }

        return message;
      });

      return {
        ...state,
        messages: updatedMessages,
      };
    case TextChannelActions.REMOVE_MESSAGE_REACTION:
      const updatedMessagesAfterRemove = state.messages.map(message => {
        const reactedMessage = !!action.payload.messageId
          ? message.id === action.payload.messageId
          : message.groupId === action.payload.groupId;

        if (reactedMessage && message.reactions) {
          const updatedReactions = { ...message.reactions };

          if (updatedReactions[action.payload.emoji]) {
            updatedReactions[action.payload.emoji] = updatedReactions[action.payload.emoji].filter(
              reaction =>
                (action.payload.messageId
                  ? reaction.messageId !== action.payload.messageId
                  : reaction.groupId !== action.payload.groupId) || reaction.createdBy !== action.payload.createdBy,
            );

            if (updatedReactions[action.payload.emoji].length === 0) {
              delete updatedReactions[action.payload.emoji];
            }

            return {
              ...message,
              reactions: updatedReactions,
            };
          }
        }

        return message;
      });

      return {
        ...state,
        messages: updatedMessagesAfterRemove,
      };

    default:
      return state;
  }
}

function calcTsOnMessage(message: ChatMessage, myself: boolean) {
  return message.speechRecognition
    ? {
        ...message,
        speechRecognition: {
          ...message.speechRecognition,
          localTs:
            myself && message.speechRecognition.localTimestamp
              ? Date.now() - message.speechRecognition.localTimestamp
              : undefined,
        },
      }
    : message;
}
