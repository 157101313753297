import { createSelector } from "reselect";
import { State } from "../state";

export const getVirtualOfficeState = (state: State) => state.virtualOffice;

export const isVirtualOfficeLoaded = createSelector(
  getVirtualOfficeState,
  virtualOfficeState => !virtualOfficeState.loading,
);

export const getVirtualOfficeObjects = createSelector(
  getVirtualOfficeState,
  virtualOfficeState => virtualOfficeState.objects,
);

export const getVirtualOfficeObjectById = (objectId: string) =>
  createSelector(getVirtualOfficeState, virtualOfficeState =>
    virtualOfficeState.objects.find(obj => obj.id === objectId),
  );

export const getObjectSpawn = createSelector(
  getVirtualOfficeState,
  virtualOfficeState => virtualOfficeState.objectSpawn,
);

export const getVirtualOfficeFullScreenObject = createSelector(getVirtualOfficeState, virtualOfficeState =>
  virtualOfficeState.objects.find(o => o.id === virtualOfficeState.fullscreenObjectId),
);

export const getMyPrevLocation = createSelector(
  getVirtualOfficeState,
  virtualOfficeState => virtualOfficeState.myPrevLocation,
);

export const getShouldJumpBack = createSelector(
  getVirtualOfficeState,
  virtualOfficeState => virtualOfficeState.shouldJumpBack,
);

export const getScreenShareObjects = createSelector(getVirtualOfficeState, vitualOfficeState => {
  return vitualOfficeState.objects.filter(el => el.type === "screen-share");
});

export const getSharedScreenAccessibilityStatus = (userId: number) =>
  createSelector(getVirtualOfficeState, virtualOfficeState => {
    return !!virtualOfficeState.objects.find(
      el => el.ownerUserId === userId && el.type === "screen-share" && el.properties.content.screenIndex === 0,
    );
  });

export const getFocusAvatar = (userId: number) =>
  createSelector(getVirtualOfficeState, virtualOfficeState => {
    return virtualOfficeState.orderedObjects.findIndex(el => el.userId === userId) + 1;
  });

export const getFocusVirtualObject = (objectId: string) =>
  createSelector(getVirtualOfficeState, virtualOfficeState => {
    return virtualOfficeState.orderedObjects.findIndex(el => el.objectId === objectId) + 1;
  });

export const isScreenSharedFromElectron = (userId: number) =>
  createSelector(getVirtualOfficeState, virtualOfficeState => {
    return !!virtualOfficeState.objects.find(
      el => el.ownerUserId === userId && el.type === "screen-share" && el.properties.content.screenIndex >= 0,
    );
  });

export const getActionStackByObjectId = (objectId: string) =>
  createSelector(getVirtualOfficeState, virtualOfficeState =>
    virtualOfficeState.actionStack.find(a => a.objectId === objectId),
  );

export const getSelectedObject = createSelector(getVirtualOfficeState, virtualOfficeState =>
  virtualOfficeState.selectedObjectId
    ? virtualOfficeState.objects.find(obj => obj.id === virtualOfficeState.selectedObjectId)
    : undefined,
);

export const getDraggedObjectId = createSelector(
  getVirtualOfficeState,
  virtualOfficeState => virtualOfficeState.draggedObjectId,
);

export const getCursorsByObjectId = (objectId: string) =>
  createSelector(getVirtualOfficeState, virtualOfficeState =>
    virtualOfficeState.cursors.filter(cursor => cursor.objectId === objectId),
  );
