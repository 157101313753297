import { Workspace } from "../types";
import { WorkspaceActionTypes, WorkspaceActions } from "./actions";

export const workspaceInitialState = {
  workspaces: [] as Workspace[],
  joining: false,
};

export type WorkspaceState = typeof workspaceInitialState;

export function workspacesReducer(state: WorkspaceState = workspaceInitialState, action: WorkspaceActionTypes) {
  switch (action.type) {
    case WorkspaceActions.SWITCH_WORKSPACE:
      return { ...state, joining: true };
    case WorkspaceActions.SWITCHED_WORKSPACE:
      return { ...state, joining: false };
    case WorkspaceActions.NEW_WORKSPACE:
      return { ...state, workspaces: [...state.workspaces, action.payload.workspace] };
    case WorkspaceActions.GOT_WORKSPACES:
      return { ...state, workspaces: action.payload.workspaces as Workspace[] };
    case WorkspaceActions.DELETE_WORKSPACE:
      return { ...state, workspaces: state.workspaces.filter(w => w.id !== action.payload.workspaceId) };
    case WorkspaceActions.WORKSPACE_UPDATED:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              name: action.payload.workspaceName ?? w.name,
              timeTrackerSlackChannelId:
                action.payload.timeTrackerSlackChannelId !== undefined
                  ? action.payload.timeTrackerSlackChannelId
                  : w.timeTrackerSlackChannelId,
              chatworkGroupId:
                action.payload.chatworkGroupId !== undefined ? action.payload.chatworkGroupId : w.chatworkGroupId,
              idleDuration: action.payload.idleDuration ?? w.idleDuration,
              enableActiveApp: action.payload.enableActiveApp ?? w.enableActiveApp,
              enableTimeTracking: action.payload.enableTimeTracking ?? w.enableTimeTracking,
              enableWebApp: action.payload.enableWebApp ?? w.enableWebApp,
              enableTextChatSaving: action.payload.enableTextChatSaving ?? w.enableTextChatSaving,
              audibleRange: action.payload.audibleRange ?? w.audibleRange,
              userVideoCircleSize: action.payload.userVideoCircleSize ?? w.userVideoCircleSize,
              broadcasterVideoCircleSize: action.payload.broadcasterVideoCircleSize ?? w.broadcasterVideoCircleSize,
              enableIndirectTranslation: action.payload.enableIndirectTranslation ?? w.enableIndirectTranslation,
              screenshareVisibility: action.payload.screenshareVisibility ?? w.screenshareVisibility,
              userAvatarCircleSize: action.payload.userAvatarCircleSize ?? w.userAvatarCircleSize,
              balloonFontSize: action.payload.balloonFontSize ?? w.balloonFontSize,
              displayNameFontSize: action.payload.displayNameFontSize ?? w.displayNameFontSize,
              enableBusyStatusReset: action.payload.enableBusyStatusReset ?? w.enableBusyStatusReset,
              enableGuestWebApp: action.payload.enableGuestWebApp ?? w.enableGuestWebApp,
              enableSpeechToText: action.payload.enableSpeechToText ?? w.enableSpeechToText,
              speechToTextLanguage: action.payload.speechToTextLanguage ?? w.speechToTextLanguage,
              enableMicForMeeting: action.payload.enableMicForMeeting ?? w.enableMicForMeeting,
              enableWebCamForMeeting: action.payload.enableWebCamForMeeting ?? w.enableWebCamForMeeting,
              enableAutoMeetingRecording: action.payload.enableAutoMeetingRecording ?? w.enableAutoMeetingRecording,
              enableDeleteAllData: action.payload.enableDeleteAllData ?? w.enableDeleteAllData,
              enableRectangularView: action.payload.enableRectangularView ?? w.enableRectangularView,
              enableEmailTokenFlag: action.payload.enableEmailTokenFlag ?? w.enableEmailTokenFlag,
              transcriptSlackChannelId:
                action.payload.transcriptSlackChannelId !== undefined
                  ? action.payload.transcriptSlackChannelId
                  : w.transcriptSlackChannelId,
              meetingApps: action.payload.meetingApps !== undefined ? action.payload.meetingApps : w.meetingApps,
              transcriptChatworkGroupId:
                action.payload.transcriptChatworkGroupId !== undefined
                  ? action.payload.transcriptChatworkGroupId
                  : w.transcriptChatworkGroupId,
              transcriptDiscordUrl:
                action.payload.transcriptDiscordUrl !== undefined
                  ? action.payload.transcriptDiscordUrl
                  : w.transcriptDiscordUrl,
              timerDiscordUrl:
                action.payload.timerDiscordUrl !== undefined ? action.payload.timerDiscordUrl : w.timerDiscordUrl,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_WORKSPACE_LOGO_URL:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              logoUrl: action.payload.logoUrl,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.LEFT_WORKSPACE:
      return {
        ...state,
        workspaces: state.workspaces.filter(u => u.id !== action.payload.workspaceId),
      };

    case WorkspaceActions.UPDATE_SPEECH_TO_TEXT_MINUTES:
      return {
        ...state,
        workspaces: state.workspaces.map(w =>
          w.id === action.payload.workspaceId ? { ...w, speechToTextCurrentUsage: action.payload.minutesSpent } : w,
        ),
      };
    case WorkspaceActions.UPDATE_MEETING_RECORDING_COUNT:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              numberOfMeetingsRecorded: action.payload.numberOfMeetingsRecorded,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_LANGUAGE_OPTIONS:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              languageOptions: action.payload.languageOptions,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_WORKSPACE_PHRASES:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            if (w.workspacePhrases?.some(wp => wp.language === action.payload.language)) {
              return {
                ...w,
                workspacePhrases: w.workspacePhrases?.map(wp =>
                  wp.language === action.payload.language ? action.payload : wp,
                ),
              };
            } else {
              if (w.workspacePhrases) {
                return {
                  ...w,
                  workspacePhrases: [...w.workspacePhrases, action.payload],
                };
              } else {
                return {
                  ...w,
                  workspacePhrases: [action.payload],
                };
              }
            }
          }

          return w;
        }),
      };

    case WorkspaceActions.SET_WORKSPACE_PHRASES:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspacePhrases[0]?.workspaceId) {
            return {
              ...w,
              workspacePhrases: action.payload.workspacePhrases,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.SET_WORKSPACE_LANGUAGES:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              workspaceLanguages: action.payload.workspaceLanguages,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_ENABLE_CONSECUTIVE_TRANSLATION:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              enableConsecutiveTranslation: action.payload.isConsecutiveTranslation,
            };
          }

          return w;
        }),
      };
    case WorkspaceActions.UPDATE_PLAN_DETAILS:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              plan: action.payload.planDetails,
              stripeCustomerId: action.payload.stripeCustomerId,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.SET_WORKSPACE_INVITEES:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              invitees: action.payload.invitees,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_WORKSPACE_INVITEE:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              invitees: w.invitees.map(i => (i.id === action.payload.invitee.id ? action.payload.invitee : i)),
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_VP_STT_SERVER:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              vpSttServerId: action.payload.vpSttServerId,
            };
          }

          return w;
        }),
      };

    case WorkspaceActions.UPDATE_WORKSPACE_TRANSLATION_FREQUENCY:
      return {
        ...state,
        workspaces: state.workspaces.map(w => {
          if (w.id === action.payload.workspaceId) {
            return {
              ...w,
              translationFrequency: action.payload.translationFrequency,
            };
          }

          return w;
        }),
      };
    default:
      return state;
  }
}
