import React from "react";
import cx from "classnames";
import { Button as AntdButton } from "antd";
import type { ButtonHTMLType } from "antd/lib/button/button";

import "./Button.scss";

type ButtonSizes = "small" | "middle" | "large";
export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary" | "outline";
  color?: "default" | "negative" | "positive" | "white" | "green";
  size?: ButtonSizes;
  disabled?: boolean;
  htmlType?: ButtonHTMLType;
  onClick?: React.MouseEventHandler<HTMLElement>;
  icon?: React.ReactNode;
  block?: boolean;
  loading?: boolean;
}

export function Button({
  disabled,
  children,
  htmlType = "button",
  size = "middle",
  variant = "primary",
  color = "default",
  className,
  onClick,
  icon,
  block = false,
  loading = false,
}: ButtonProps) {
  return (
    <AntdButton
      className={cx(
        "voiceping-button",
        `voiceping-button--${color === "white" || color === "green" ? "outline" : variant}-${color}`,
        `voiceping-button--${size}`,
        className,
      )}
      disabled={disabled}
      size={size}
      htmlType={htmlType}
      icon={icon}
      onClick={onClick}
      block={block}
      loading={loading}
    >
      {children}
    </AntdButton>
  );
}
