import { createSelector } from "reselect";

import { State } from "../state";
import { AppNotificationState } from "./reducer";

export function getAppNotificationState(state: State): AppNotificationState {
  return state.appNotification;
}

export const getAppNotifications = createSelector(getAppNotificationState, ({ notifications }) => notifications);
