import {
  channelCreated,
  channelUpdated,
  channelDeleted,
  channelJoined,
  channelLeft,
  voiceChannelInviteReceive,
  voiceChannelInviteGotResponse,
  InviteResponseOption,
  duplicatedJoin,
  muteChangeReceive,
  silenceChangeReceive,
  screenShareReceive,
  channelNoExist,
  gotResponse,
  voiceChannelMeetingReminderReceive,
  gotLivekitToken,
  setKnocker,
  receiveAnswerToKnock,
  receiveKnockingChannelIsDeleted,
  removeKnock,
  fallBackGuest,
  handleInvalidKnockResponse,
  switchSubscribeKNockNotificationResponse,
  noPermissionChannelJoin,
} from "./actions";
import { SendNotificationResult } from "../appNotification/types";
import { autoRecordingMeetingFeatureAvailedStatusUpdate, channelLastMeetingUpdate, KnockState, RequestTypes } from ".";
import { CHANNEL_EVENT_TYPE_PREFIX, CHANNEL_EVENT_TYPES } from "./actions-enum";
import { addEmojiReaction, removeListener, resetEmojiAction, setOnlineUsersInVoiceChannel } from "../users";
import { updateWorkspaceMeetingRecordingCount } from "../workspace";
import { MemberData, Position, ScheduleMeetingParticipant, ScheduleMeetingSlot } from "../types";
import { EmojiReactions } from "../../components/core/Emoji";
import { addFloorResponse } from "../member";
import { setRoomConnection } from "../livekit";
import { ConnectionState } from "livekit-client";
import { RecordingStatusInWorkspace, setRecordingStatus } from "../audioExtraction";

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(CHANNEL_EVENT_TYPE_PREFIX)) {
    return;
  }

  const {
    workspaceId,
    textChannelId,
    voiceChannelId,
    voiceChannelName,
    voiceChannelShortId,
    listenerId,
    userId,
    option,
    inviteFrom,
    inviteTo,
    isTemporary,
    speechToTextLanguage,
    mute,
    silence,
    invitationId,
    invitedByUserId,
    invitedByUserPosition,
    invitedUserId,
    joinLaterTimeout,
    initiator,
    socketId,
    notifyUser,
    isAccepted,
    requestType,
    requestId,
    requestorUserId,
    requestedUserId,
    requestorSocketId,
    voiceChannelsJoinCount,
    parentVoiceChannelId,
    isPrivate,
    ownerUserId,
    isGetCloserInvite,
    lastMeetingAt,
    speechToTextEnabled,
    textToSpeechEnabled,
    guestEnabled,
    guestPassword,
    listenerEnabled,
    listenerMicEnabled,
    listenerPassword,
    workspaceBackgroundId,
    isMeetingRecording,
    isAutoMeetingRecordingEnabled,
    isAutoMeetingRecordingFeatureAvailed,
    numberOfMeetingRecorded,
    isPositionLock,
    isDisableFloorAccess,
    isDefaultFloor,
    isEnableRectangularView,
    isScheduledMeeting,
    meetingParticipants,
    meetingSlots,
    reminderInterval,
    timezoneOffset,
    isEnableMicForMtg,
    isEnableWebCamForMtg,
    isDeleteAllData,
    voiceChannelUsers,
    accessToken,
    assignedMemberIds,
    knockerId,
    knockerName,
    userIds,
    knockers,
    knock,
    userName,
    isSubscriber,
    subscriberIds,
    message,
    isListener,
    recordingStatusInWorkspace,
    showOriginalTextEnabledAsDefault,
  }: {
    workspaceId: number;
    textChannelId: number;
    voiceChannelId: number;
    voiceChannelName: string;
    voiceChannelShortId: string;
    listenerId: string;
    userId: number;
    option: InviteResponseOption;
    isTemporary: boolean;
    speechToTextLanguage: string;
    inviteFrom?: number;
    inviteTo?: number;
    mute: boolean;
    silence: boolean;
    invitationId: string;
    invitedByUserId: number;
    invitedByUserPosition: Position;
    invitedUserId: number;
    joinLaterTimeout: number;
    initiator: number;
    socketId: string;
    notificationResult: SendNotificationResult;
    notifyUser: boolean;
    isAccepted: boolean;
    requestType: RequestTypes;
    requestId: string;
    requestorUserId: number;
    requestedUserId: number;
    requestorSocketId: string;
    voiceChannelsJoinCount: number;
    parentVoiceChannelId: number | null;
    isPrivate: boolean;
    ownerUserId: number;
    isGetCloserInvite: boolean;
    lastMeetingAt: string | null;
    speechToTextEnabled: boolean;
    textToSpeechEnabled: boolean;
    guestEnabled: boolean;
    guestPassword: string | null;
    listenerEnabled: boolean;
    listenerMicEnabled: boolean;
    listenerPassword: string | null;
    workspaceBackgroundId: number;
    isMeetingRecording: boolean;
    isAutoMeetingRecordingEnabled: boolean;
    isAutoMeetingRecordingFeatureAvailed: boolean;
    numberOfMeetingRecorded: number | null;
    isPositionLock: boolean;
    isDisableFloorAccess: boolean;
    isDefaultFloor: boolean;
    isEnableRectangularView: boolean;
    isScheduledMeeting: boolean;
    meetingParticipants?: ScheduleMeetingParticipant[];
    meetingSlots?: ScheduleMeetingSlot[];
    reminderInterval?: number;
    timezoneOffset?: number;
    isEnableMicForMtg: boolean;
    isEnableWebCamForMtg: boolean;
    isDeleteAllData: boolean;
    voiceChannelUsers: MemberData[];
    accessToken: string;
    assignedMemberIds: number[];
    knockerId: number;
    knockerName: string;
    userIds: number[];
    knockers: { userId: number; userName: string }[];
    knock: KnockState | undefined;
    userName: string;
    isSubscriber: boolean;
    subscriberIds: number[];
    message: string;
    isListener?: boolean;
    recordingStatusInWorkspace: RecordingStatusInWorkspace;
    showOriginalTextEnabledAsDefault: boolean;
  } = payload;

  switch (type) {
    case CHANNEL_EVENT_TYPES.CHANNEL_CREATE:
      store.dispatch(
        channelCreated({
          workspaceId,
          textChannelId,
          voiceChannelId,
          voiceChannelName,
          voiceChannelShortId,
          listenerId,
          socketId,
          parentVoiceChannelId,
          isTemporary,
          speechToTextLanguage,
          inviteFrom,
          inviteTo,
          isPrivate,
          ownerUserId,
          speechToTextEnabled,
          textToSpeechEnabled,
          workspaceBackgroundId,
          guestEnabled,
          guestPassword,
          listenerEnabled,
          listenerMicEnabled,
          listenerPassword,
          isAutoMeetingRecordingEnabled,
          isPositionLock,
          isDisableFloorAccess,
          isDefaultFloor,
          isEnableRectangularView,
          isScheduledMeeting,
          meetingParticipants,
          meetingSlots,
          reminderInterval,
          timezoneOffset,
          isEnableMicForMtg,
          isEnableWebCamForMtg,
          isDeleteAllData,
          assignedMemberIds,
          showOriginalTextEnabledAsDefault,
        }),
      );
      if (!payload.parentVoiceChannelId) {
        store.dispatch(
          addFloorResponse({
            id: voiceChannelId,
            workspaceId: workspaceId,
            name: voiceChannelName,
            shortId: voiceChannelShortId,
          }),
        );
      }

      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_INVITE:
      store.dispatch(
        voiceChannelInviteReceive({
          workspaceId,
          voiceChannelId,
          invitationId,
          invitedByUserId,
          invitedByUserPosition,
          invitedUserId,
          isTemporary,
          notifyUser,
          isGetCloserInvite,
        }),
      );
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_SCHEDULED_MEETING_REMINDER:
      store.dispatch(
        voiceChannelMeetingReminderReceive({
          workspaceId,
          voiceChannelId,
          voiceChannelName,
          reminderInterval,
        }),
      );
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_INVITE_RESPONSE:
      store.dispatch(
        voiceChannelInviteGotResponse({
          workspaceId,
          voiceChannelId,
          invitationId,
          option,
          invitedByUserId,
          invitedUserId,
          joinLaterTimeout,
          isGetCloserInvite,
        }),
      );
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_UPDATE:
      store.dispatch(
        channelUpdated({
          workspaceId,
          voiceChannelId,
          voiceChannelName,
          speechToTextLanguage,
          socketId,
          isPrivate,
          speechToTextEnabled,
          textToSpeechEnabled,
          workspaceBackgroundId,
          guestEnabled,
          guestPassword,
          listenerEnabled,
          listenerMicEnabled,
          listenerPassword,
          isAutoMeetingRecordingEnabled,
          isPositionLock,
          isDisableFloorAccess,
          isEnableRectangularView,
          isScheduledMeeting,
          meetingParticipants,
          meetingSlots,
          reminderInterval,
          timezoneOffset,
          isDeleteAllData,
          isEnableMicForMtg,
          isEnableWebCamForMtg,
          assignedMemberIds,
          showOriginalTextEnabledAsDefault,
        }),
      );
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_DELETE:
      store.dispatch(channelDeleted({ workspaceId, voiceChannelId, isTemporary, initiator }));
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_JOIN:
      store.dispatch(channelLastMeetingUpdate({ voiceChannelId, lastMeetingAt }));
      store.dispatch(setOnlineUsersInVoiceChannel(voiceChannelUsers));
      store.dispatch(setRecordingStatus(recordingStatusInWorkspace));

      voiceChannelUsers.forEach(u => {
        if (u.raiseHand) {
          store.dispatch(
            addEmojiReaction({
              userId: u.id,
              voiceChannelId,
              emoji: EmojiReactions.RAISED_HAND,
              persistent: true,
            }),
          );
        } else {
          store.dispatch(resetEmojiAction({ userId: u.id, persistent: true }));
        }
      });
      store.dispatch(
        channelJoined({
          workspaceId,
          voiceChannelId,
          textChannelId,
          userId,
          voiceChannelsJoinCount,
          isMeetingRecording,
          knockers,
          knock,
          showOriginalTextEnabledAsDefault,
        }),
      );
      break;
    case CHANNEL_EVENT_TYPES.IN_LIVEKIT_TOKEN_RESPONSE:
      store.dispatch(gotLivekitToken({ accessToken, voiceChannelId }));
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_LEAVE:
      store.dispatch(channelLeft({ workspaceId, voiceChannelId, userId }));
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_LEAVE_FOR_LISTENER:
      store.dispatch(removeListener({ userId }));
      break;
    case CHANNEL_EVENT_TYPES.DUPLICATED_JOIN:
      store.dispatch(duplicatedJoin({ workspaceId, voiceChannelId }));
      break;
    case CHANNEL_EVENT_TYPES.REQUEST_MUTE_CHANGE:
      store.dispatch(muteChangeReceive({ workspaceId, voiceChannelId, userId, mute, invitationId, requestorSocketId }));
      break;
    case CHANNEL_EVENT_TYPES.REQUEST_SILENCE_CHANGE:
      store.dispatch(
        silenceChangeReceive({ workspaceId, voiceChannelId, userId, silence, invitationId, requestorSocketId }),
      );
      break;
    case CHANNEL_EVENT_TYPES.REQUEST_SCREENSHARE:
      store.dispatch(screenShareReceive({ workspaceId, voiceChannelId, userId, invitationId, requestorSocketId }));
      break;
    case CHANNEL_EVENT_TYPES.CHANNEL_NO_EXIST:
      store.dispatch(channelNoExist({ workspaceId, voiceChannelId }));
      break;
    case CHANNEL_EVENT_TYPES.RESPONSE_FOR_REQUEST:
      store.dispatch(
        gotResponse({
          requestType,
          workspaceId,
          voiceChannelId,
          requestId,
          isAccepted,
          requestorUserId,
          requestedUserId,
          requestorSocketId,
        }),
      );
      break;
    case CHANNEL_EVENT_TYPES.IN_AUTO_MEETING_RECORDING_FEATURE_AVAILED_STATUS_UPDATED:
      store.dispatch(
        autoRecordingMeetingFeatureAvailedStatusUpdate({
          status: isAutoMeetingRecordingFeatureAvailed,
          voiceChannelId,
        }),
      );

      if (numberOfMeetingRecorded)
        store.dispatch(updateWorkspaceMeetingRecordingCount(numberOfMeetingRecorded, workspaceId));

      break;

    case CHANNEL_EVENT_TYPES.RESPONSE_KNOCK_PRIVATE_MEETING:
      store.dispatch(setKnocker(voiceChannelId, knockerId, knockerName));
      break;
    case CHANNEL_EVENT_TYPES.RESPONSE_ANSWER_TO_KNOCK:
      store.dispatch(receiveAnswerToKnock(voiceChannelId, isAccepted, knockerId, userName, userIds, isListener));
      break;
    case CHANNEL_EVENT_TYPES.DELETE_KNOCK_BY_CHANNEL_DELETE:
      store.dispatch(receiveKnockingChannelIsDeleted(knockerId, voiceChannelName));
      break;

    case CHANNEL_EVENT_TYPES.REMOVE_KNOCK:
      store.dispatch(removeKnock(knockerId, voiceChannelId));
      break;
    case CHANNEL_EVENT_TYPES.REQUEST_RESEND_KNOCK_PRIVATE_MEETING:
      store.dispatch(setKnocker(voiceChannelId, knockerId, knockerName));
      break;
    case CHANNEL_EVENT_TYPES.RESPONSE_VOICE_CHANNEL_JOIN_FALLBACK_GUEST:
      store.dispatch(fallBackGuest());
      break;
    case CHANNEL_EVENT_TYPES.RESPONSE_INVALID_KNOCK:
      store.dispatch(handleInvalidKnockResponse(voiceChannelId, knockerId));
      break;
    case CHANNEL_EVENT_TYPES.RESPONSE_SWITCH_KNOCK_SUBSCRIBE_STATUS:
      store.dispatch(switchSubscribeKNockNotificationResponse(voiceChannelId, userId, subscriberIds, isSubscriber));
      break;
    case CHANNEL_EVENT_TYPES.NO_PERMISSION_CHANNEL:
      store.dispatch(setRoomConnection(ConnectionState.Disconnected));

      store.dispatch(noPermissionChannelJoin(message, voiceChannelId));
      break;
    default:
      break;
  }
};
