import { createSelector } from "reselect";
import { RemoteDesktopControlState } from ".";

import { State } from "../state";

export function getRemoteDesktopControlState(state: State): RemoteDesktopControlState {
  return state.remoteDesktopControl;
}

export const getRemoteDesktopControllingStatus = createSelector(
  getRemoteDesktopControlState,
  (state: RemoteDesktopControlState) => !!state.remoteDesktop?.isControlledByRemoteUser,
);

export const getRemoteDesktopControlDetails = createSelector(
  getRemoteDesktopControlState,
  (state: RemoteDesktopControlState) => state.remoteDesktop,
);

export const getMyDesktopControlDetails = createSelector(
  getRemoteDesktopControlState,
  (state: RemoteDesktopControlState) => state.myDesktop,
);

export const getMySkyWayPeerId = createSelector(getRemoteDesktopControlState, (state: RemoteDesktopControlState) => {
  return state?.myDesktop?.mySkyWayPeerId;
});

export const getRemoteUserPeerId = createSelector(getRemoteDesktopControlState, (state: RemoteDesktopControlState) => {
  return state?.remoteDesktop?.remoteUserSkyWayPeerId;
});

export const getConnectedStatusWithSkyWay = createSelector(
  getRemoteDesktopControlState,
  (state: RemoteDesktopControlState) => {
    return state?.myDesktop?.isConnectedWithSkyWay;
  },
);
