import { combineReducers } from "redux";

import { activeAppReducer } from "./activeApp/reducer";
import { activityDetectionReducer } from "./activityDetection/reducer";
import { BackgroundChecksState } from "./state";

export const backgroundChecksReducer = combineReducers<BackgroundChecksState>({
  activeApp: activeAppReducer,
  activityDetection: activityDetectionReducer,
});
