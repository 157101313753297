import { SocketActions } from "./actions";

export const socketInitialState: { id: string | null; connected: boolean } = {
  id: null,
  connected: false,
};

export type SocketState = typeof socketInitialState;

export function socketReducer(
  state = socketInitialState,
  action: { type: string; connected: boolean; id: string | null },
) {
  switch (action.type) {
    case SocketActions.CONNECTION_CHANGED:
      return {
        ...state,
        connected: action.connected,
        id: action.id,
        isError: false,
      };
    default:
      return state;
  }
}
