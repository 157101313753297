import { WorkspaceBackgroundActionTypes } from "./actions";
import { WorkspaceBackgroundActions } from "./actions-enum";

export interface WorkspaceBackground {
  id: number;
  workspaceId: number | null;
  url: string;
  enName: string | null;
  jaName: string | null;
  koName: string | null;
  zhName: string | null;
  type: string;
  width: number;
  height: number;
  isBase: boolean;
  isDefaultTemplate: boolean;
  isDeployed: boolean;
  templateId: number;
}

export const workspaceBackgroundInitialState = {
  workspaceBackgrounds: [] as WorkspaceBackground[],
  loading: false,
  creating: false,
  deleting: false,
};

export type WorkspaceBackgroundState = typeof workspaceBackgroundInitialState;

export function workspaceBackgroundReducer(
  state: WorkspaceBackgroundState = workspaceBackgroundInitialState,
  action: WorkspaceBackgroundActionTypes,
): WorkspaceBackgroundState {
  switch (action.type) {
    case WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_CREATE:
      return { ...state, loading: true, creating: true };
    case WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        workspaceBackgrounds: [...state.workspaceBackgrounds, action.payload],
      };
    case WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_DELETE:
      return { ...state, loading: true, deleting: true };
    case WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleting: false,
        workspaceBackgrounds: state.workspaceBackgrounds.filter(o => o.id !== action.payload.id),
      };
    case WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_GET:
      return { ...state, loading: true };
    case WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        workspaceBackgrounds: action.workspaceBackgrounds,
      };
    default:
      return state;
  }
}
