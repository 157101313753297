import { PIPNotifications, TextChatMessageReaction } from "../types";
import {
  UpdateBroadcastActiveNotification,
  UserScreenActiveNotification,
  UserWhiteBoardOrTextEditorCreateNotification,
} from "../users";
import {
  InviteGotResponseVoiceChannel,
  InviteRequestVoiceChannel,
  ReminderMeetingVoiceChannel,
  SubscribingKnockPipData,
} from "../voiceChannels";
import { NotificationsWindowActions, NotificationsWindowActionTypes } from "./actions";

export const notificationsWindowInitialState = {
  notificationWindowLoaded: false,
  notifications: [] as PIPNotifications[],
  notificationKeys: [] as string[],
};

export type NotificationsWindowState = typeof notificationsWindowInitialState;

export function NotificationsWindowReducer(
  state: NotificationsWindowState = notificationsWindowInitialState,
  action: NotificationsWindowActionTypes,
): NotificationsWindowState {
  switch (action.type) {
    case NotificationsWindowActions.SET_LOAD_CALL_NOTIFICATIONS:
      return {
        ...state,
        notificationWindowLoaded:
          action.payload.frameName === "notification-window" ? action.payload.isLoaded : state.notificationWindowLoaded,
      };
    case NotificationsWindowActions.ADD_NOTIFICATION:
      if (action.payload.notificationsType === "screen-share-notification") {
        const screenShareNotificationPayload = action.payload.data as UserScreenActiveNotification[];

        let screenShareNotifications = state.notifications.find(
          el => el.notificationsType === action.payload.notificationsType,
        );

        const data = screenShareNotifications
          ? (screenShareNotifications.data as UserScreenActiveNotification[])
          : ([] as UserScreenActiveNotification[]);

        data.push(screenShareNotificationPayload[0]);

        screenShareNotifications = { ...action.payload, data };

        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, screenShareNotifications] };
      }

      if (
        action.payload.notificationsType === "white-board-notification" ||
        action.payload.notificationsType === "text-editor-notification"
      ) {
        const whiteBoardOrTextEditorNotificationPayload = action.payload
          .data as UserWhiteBoardOrTextEditorCreateNotification[];

        let whiteBoardOrTextEditorNotifications = state.notifications.find(
          el => el.notificationsType === action.payload.notificationsType,
        );

        const data = whiteBoardOrTextEditorNotifications
          ? (whiteBoardOrTextEditorNotifications.data as UserWhiteBoardOrTextEditorCreateNotification[])
          : ([] as UserWhiteBoardOrTextEditorCreateNotification[]);

        data.push(whiteBoardOrTextEditorNotificationPayload[0]);

        whiteBoardOrTextEditorNotifications = { ...action.payload, data };

        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, whiteBoardOrTextEditorNotifications] };
      }

      if (action.payload.notificationsType === "text-message-reaction") {
        let reactionsNotifications = state.notifications.filter(
          el => el.notificationsType === action.payload.notificationsType,
        );

        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, ...reactionsNotifications, action.payload] };
      }

      if (action.payload.notificationsType === "broadcast-active") {
        const broadcastPayload = action.payload.data as UpdateBroadcastActiveNotification[];

        let broadcastNotifications = state.notifications.find(
          el => el.notificationsType === action.payload.notificationsType,
        );

        const data = broadcastNotifications
          ? (broadcastNotifications.data as UpdateBroadcastActiveNotification[])
          : ([] as UpdateBroadcastActiveNotification[]);

        data.push(broadcastPayload[0]);

        broadcastNotifications = { ...action.payload, data };

        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, broadcastNotifications] };
      }

      if (action.payload.notificationsType === "meeting-reminder-request") {
        const meetingReminderPayload = action.payload.data as ReminderMeetingVoiceChannel[];

        let meetingReminderNotifications = state.notifications.find(
          el => el.notificationsType === action.payload.notificationsType,
        );

        const data = meetingReminderNotifications
          ? (meetingReminderNotifications.data as ReminderMeetingVoiceChannel[])
          : ([] as ReminderMeetingVoiceChannel[]);

        data.push(meetingReminderPayload[0]);

        meetingReminderNotifications = { ...action.payload, data };

        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, meetingReminderNotifications] };
      }

      if (
        state.notifications.find(el => el.notificationsType === action.payload.notificationsType) &&
        action.payload.notificationsType === "remote-access-request"
      ) {
        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, action.payload] };
      }

      const invitationResponsePayload = action.payload.data as InviteGotResponseVoiceChannel;

      if (action.payload.notificationsType === "invitaion-responses") {
        const notifications = state.notifications.filter(({ data }) => {
          const invitationResponse = data as InviteGotResponseVoiceChannel;

          if (invitationResponse?.invitationId) {
            return invitationResponse?.invitationId !== invitationResponsePayload.invitationId;
          } else {
            const invitationResponse = data as InviteRequestVoiceChannel;

            return (
              invitationResponse?.invitedUsersIds &&
              !invitationResponse?.invitedUsersIds.includes(invitationResponsePayload.invitedUserId || 0) &&
              invitationResponse?.voiceChannelId === invitationResponsePayload.voiceChannelId
            );
          }
        });

        const generalNotifications = state.notifications.filter(({ notificationsType }) => {
          return (
            notificationsType !== "invitaion-responses" &&
            notificationsType !== "call-invite-request" &&
            notificationsType !== "meeting-request" &&
            notificationsType !== "get-closer-request"
          );
        });

        return {
          ...state,
          notifications:
            invitationResponsePayload?.option === "join-now" ||
            (action.payload.myId === invitationResponsePayload.invitedUserId &&
              invitationResponsePayload?.option === "decline") ||
            (action.payload.myId === invitationResponsePayload?.invitedByUserId &&
              invitationResponsePayload?.option === "cancel")
              ? [...notifications, ...generalNotifications]
              : [...notifications, ...generalNotifications, action.payload],
        };
      } else if (action.payload.notificationsType === "call-invite-request") {
        const existingRequests = state.notifications.find(el => JSON.stringify(el) === JSON.stringify(action.payload));

        if (existingRequests) return state;
      }

      if (action.payload.notificationsType === "missed-call") {
        const remainNotifications = state.notifications.filter(a => a.notificationsType !== "missed-call");

        const other: PIPNotifications[] = [];

        state.notifications
          .filter(a => a.notificationsType === "missed-call")
          .forEach((a, index) => {
            if (
              !other.some(
                o =>
                  (o.data as InviteGotResponseVoiceChannel).invitationId ===
                  (a.data as InviteGotResponseVoiceChannel).invitationId,
              )
            ) {
              other.push(a);
            }
          });

        return { ...state, notifications: [...remainNotifications, ...other, action.payload] };
      }

      if (action.payload.notificationsType === "subscribing-knock") {
        const subscribingKnockNotificationsData = action.payload.data as SubscribingKnockPipData[];

        let subscribingKnockNotifications = state.notifications.find(
          el => el.notificationsType === action.payload.notificationsType,
        );

        if (subscribingKnockNotifications) {
          let data = subscribingKnockNotifications.data as SubscribingKnockPipData[];
          const voiceChannelIds = data.map(d => d.voiceChannelId);

          if (voiceChannelIds.includes(subscribingKnockNotificationsData[0].voiceChannelId)) {
            data = data.map(d => {
              if (d.voiceChannelId === subscribingKnockNotificationsData[0].voiceChannelId) {
                return { ...d, knockers: subscribingKnockNotificationsData[0].knockers };
              }

              return d;
            });
          } else {
            data.push(subscribingKnockNotificationsData[0]);
          }

          return {
            ...state,
            notifications: [
              ...state.notifications.map(notification => {
                if (notification.notificationsType === "subscribing-knock") {
                  return { ...notification, data: data };
                }

                return notification;
              }),
            ],
          };
        } else {
          return {
            ...state,
            notifications: [...state.notifications, action.payload],
          };
        }
      }

      if (
        state.notifications.find(el => el.notificationsType === action.payload.notificationsType) &&
        action.payload.notificationsType !== "call-invite-request"
      ) {
        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== action.payload.notificationsType,
        );

        return { ...state, notifications: [...filteredNotifications, action.payload] };
      }

      if (action.payload.notificationsType === "knocker-list") {
        const prevNotificationTypes = state.notifications.map(notification => notification.notificationsType);

        return {
          ...state,
          notifications: prevNotificationTypes.includes("knocker-list")
            ? [
                ...state.notifications.map(notification => {
                  if (notification.notificationsType === "knocker-list") {
                    notification.data = action.payload.data;
                    return { ...notification, data: action.payload.data };
                  }

                  return notification;
                }),
              ]
            : [...state.notifications, action.payload],
        };
      }

      return { ...state, notifications: [...state.notifications, action.payload] };
    case NotificationsWindowActions.REMOVE_NOTIFICATION:
      if (action.payload.type === "text-message-reaction") {
        if (!action.payload.emoji) {
          return {
            ...state,
            notifications: state.notifications.filter(a => a.notificationsType !== "text-message-reaction"),
          };
        }

        return {
          ...state,
          notifications: state.notifications.filter(a => {
            const data = a.data as TextChatMessageReaction;

            if (data.createdBy === action.payload.reactedUserId && data.emoji === action.payload.emoji) {
              return false;
            } else {
              return true;
            }
          }),
        };
      }

      if (action.payload.type === "broadcast-active") {
        let broadcastNotifications = state.notifications.find(el => el.notificationsType === action.payload.type);

        const data = broadcastNotifications
          ? (broadcastNotifications.data as UpdateBroadcastActiveNotification[])
          : ([] as UpdateBroadcastActiveNotification[]);
        const filteredNotifications = state.notifications.filter(el => el.notificationsType !== action.payload.type);

        if (action.payload.broadcastId) {
          const removedBroadcastNotiifcations = data.filter(el => el.broadcastId !== action.payload.broadcastId);

          if (removedBroadcastNotiifcations.length > 0) {
            return {
              ...state,
              notifications: [
                ...filteredNotifications,
                {
                  notificationsType: action.payload.type,
                  data: removedBroadcastNotiifcations,
                },
              ],
            };
          }
        }

        return { ...state, notifications: filteredNotifications };
      }

      if (action.payload.type === "screen-share-notification") {
        let screenActiveNotifications = state.notifications.find(
          el => el.notificationsType === "screen-share-notification",
        );

        if (!screenActiveNotifications) return state;

        const notificationsData = screenActiveNotifications?.data as UserScreenActiveNotification[];
        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== "screen-share-notification",
        );

        if (action.payload.screenSharerUserId) {
          const remainingScreenActiveNotifications = notificationsData.filter(
            el => el.userId !== action.payload.screenSharerUserId,
          );

          if (remainingScreenActiveNotifications.length > 0) {
            screenActiveNotifications = {
              notificationsType: action.payload.type,
              data: remainingScreenActiveNotifications,
            };
            return { ...state, notifications: [...filteredNotifications, screenActiveNotifications] };
          }
        }

        return { ...state, notifications: [...filteredNotifications] };
      }

      if (action.payload.type === "white-board-notification" || action.payload.type === "text-editor-notification") {
        let activeNotifications = state.notifications.find(el => el.notificationsType === action.payload.type);

        if (!activeNotifications) return state;

        const notificationsData = activeNotifications?.data as UserWhiteBoardOrTextEditorCreateNotification[];
        const filteredNotifications = state.notifications.filter(el => el.notificationsType !== action.payload.type);

        if (action.payload.screenSharerUserId) {
          const remainingactiveNotifications = notificationsData.filter(
            el => el.userId !== action.payload.screenSharerUserId,
          );

          if (remainingactiveNotifications.length > 0) {
            activeNotifications = {
              notificationsType: action.payload.type,
              data: remainingactiveNotifications,
            };
            return { ...state, notifications: [...filteredNotifications, activeNotifications] };
          }
        }

        return { ...state, notifications: [...filteredNotifications] };
      }

      if (action.payload.type === "meeting-reminder-request") {
        let meetingRemindersNotifications = state.notifications.find(
          el => el.notificationsType === "meeting-reminder-request",
        );

        if (!meetingRemindersNotifications) return state;

        const notificationsData = meetingRemindersNotifications?.data as ReminderMeetingVoiceChannel[];
        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== "meeting-reminder-request",
        );

        if (action.payload.voiceChannelId) {
          const remainingMeetingRemindersNotifications = notificationsData.filter(
            el => el.voiceChannelId !== action.payload.voiceChannelId,
          );

          if (remainingMeetingRemindersNotifications.length > 0) {
            meetingRemindersNotifications = {
              notificationsType: action.payload.type,
              data: remainingMeetingRemindersNotifications,
            };
            return { ...state, notifications: [...filteredNotifications, meetingRemindersNotifications] };
          }
        }

        return { ...state, notifications: [...filteredNotifications] };
      }

      if (action.payload.type === "remote-access-request") {
        let remoteAccessNotifications = state.notifications.find(
          el => el.notificationsType === "remote-access-request",
        );

        if (!remoteAccessNotifications) return state;

        const filteredNotifications = state.notifications.filter(
          el => el.notificationsType !== "remote-access-request",
        );

        return { ...state, notifications: [...filteredNotifications] };
      }

      if (action.payload.type === "knocker-list") {
        const filteredNotifications = state.notifications.filter(el => el.notificationsType !== "knocker-list");

        return { ...state, notifications: [...filteredNotifications] };
      }

      if (action.payload.type === "invitaion-responses") {
        const invitationResponses = state.notifications.filter(({ data, notificationsType }) => {
          const invitationResponse = data as InviteGotResponseVoiceChannel;

          if (
            notificationsType === "invitaion-responses" ||
            notificationsType === "call-invite-request" ||
            notificationsType === "meeting-request" ||
            notificationsType === "get-closer-request"
          )
            return invitationResponse?.invitationId !== action.payload.invitationId;

          return false;
        });

        const invitations = state.notifications.filter(
          invitation => invitation.notificationsType !== "invitaion-responses",
        );

        return { ...state, notifications: [...invitations, ...invitationResponses] };
      } else if (action.payload.type === "call-invite-request" && action.payload.invitedUserId) {
        const notifications = state.notifications.filter(({ data }) => {
          const invitationResponse = data as InviteRequestVoiceChannel;

          return (
            invitationResponse?.invitedUsersIds &&
            !invitationResponse?.invitedUsersIds.includes(action.payload.invitedUserId || 0) &&
            invitationResponse?.voiceChannelId === action.payload.voiceChannelId
          );
        });

        return { ...state, notifications };
      }

      if (action.payload.type === "missed-call") {
        const remainNotifications = state.notifications.filter(a => {
          const data = a.data as InviteGotResponseVoiceChannel;

          if (a.notificationsType === "missed-call") {
            if (action.payload.invitationId !== data.invitationId) {
              return true;
            } else {
              return false;
            }
          }

          return false;
        });

        return { ...state, notifications: remainNotifications };
      }

      if (action.payload.type === "subscribing-knock") {
        const subscribingKnockState = state.notifications.find(
          notif => notif.notificationsType === action.payload.type,
        );

        if (!subscribingKnockState) return state;

        const prevSubscribingKnockData = subscribingKnockState.data as SubscribingKnockPipData[];
        const newSubscribingKnockData = prevSubscribingKnockData.filter(
          prevData => prevData.voiceChannelId !== action.payload.voiceChannelId,
        );

        if (newSubscribingKnockData.length === 0) {
          return {
            ...state,
            notifications: state.notifications.filter(notif => notif.notificationsType !== action.payload.type),
          };
        } else {
        }

        return {
          ...state,
          notifications: state.notifications.map(notif => {
            if (notif.notificationsType === action.payload.type) {
              return { ...notif, data: newSubscribingKnockData };
            }

            return notif;
          }),
        };
      }

      const notifications = state.notifications.filter(
        notification => notification.notificationsType !== action.payload.type && notification.data,
      );

      return { ...state, notifications };
    case NotificationsWindowActions.CLEAR_NOTIFICATIONS:
      return { ...state, notificationWindowLoaded: false, notifications: [] };
    case NotificationsWindowActions.ADD_NOTIFICATION_KEY:
      return { ...state, notificationKeys: [...state.notificationKeys, action.payload.notificationKey] };
    case NotificationsWindowActions.REMOVE_NOTIFICATION_KEY:
      return {
        ...state,
        notificationKeys: [...state.notificationKeys.filter(a => a !== action.payload.notificationKey)],
      };
    case NotificationsWindowActions.CLEAR_NOTIFICATIONS_KEY:
      return { ...state, notificationKeys: [] };

    default:
      return state;
  }
}
