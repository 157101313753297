import { history, WORKSPACE_BASE_ROUTE } from "../routes";

export function getWorkspaceChannelParams(path?: string) {
  if ((path || history.location.pathname).startsWith(WORKSPACE_BASE_ROUTE)) {
    const [workspaceShortId, voiceChannelShortId] = (path || history.location.pathname)
      .replace(`${WORKSPACE_BASE_ROUTE}/`, "")
      .split("/");

    return { workspaceShortId, voiceChannelShortId };
  } else {
    return { workspaceShortId: null, voiceChannelShortId: null };
  }
}
