import React from "react";
import RaisedHand from "../../../assets/images/emojis/raised_hand.png";
import PartyPopper from "../../../assets/images/emojis/party_popper.png";
import RaisingHands from "../../../assets/images/emojis/raising_hands.png";
import RedHeart from "../../../assets/images/emojis/red_heart.png";
import SmilingFaceSmilingEye from "../../../assets/images/emojis/similing_face_smiling_eye.png";
import ThumbsUp from "../../../assets/images/emojis/thumbs_up.png";
import WavingHand from "../../../assets/images/emojis/waving_hand.png";
import FaceBlowingKiss from "../../../assets/images/emojis/face_blowing_kiss.png";
import Fire from "../../../assets/images/emojis/fire.png";
import FoldedHands from "../../../assets/images/emojis/folded_hands.png";
import LoudlyCryingFace from "../../../assets/images/emojis/loudly_crying_face.png";
import RollingLaughing from "../../../assets/images/emojis/rolliing_laughing.png";
import SmilingFaceHearts from "../../../assets/images/emojis/smiling_face_hearts.png";
import WhiteCheckMark from "../../../assets/images/emojis/WHITE_CHECK_MARK.png";
import Eyes from "../../../assets/images/emojis/EYES.png";
import OkHand from "../../../assets/images/emojis/OK_HAND.png";

import "./Emoji.scss";

export enum EmojiReactions {
  RAISED_HAND = "RAISED_HAND",
  WAVING_HAND = "WAVING_HAND",
  RAISING_HANDS = "RAISING_HANDS",
  PARTY_POPPER = "PARTY_POPPER",
  THUMBS_UP = "THUMBS_UP",
  RED_HEART = "RED_HEART",
  SMILE_FACE_SMILE_EYE = "SMILE_FACE_SMILE_EYE",
  FACE_BLOWING_KISS = "FACE_BLOWING_KISS",
  FIRE = "FIRE",
  FOLDED_HANDS = "FOLDED_HANDS",
  LOUDLY_CRYING_FACE = "LOUDLY_CRYING_FACE",
  ROLLING_LAUGHING = "ROLLING_LAUGHING",
  SMILING_FACE_HEARTS = "SMILING_FACE_HEARTS",
  WHITE_CHECK_MARK = "WHITE_CHECK_MARK",
  EYES = "EYES",
  OK_HAND = "OK_HAND",
}

export enum RandomEmojiReactions {
  PARTY_POPPER = "PARTY_POPPER",
  THUMBS_UP = "THUMBS_UP",
  SMILE_FACE_SMILE_EYE = "SMILE_FACE_SMILE_EYE",
  FACE_BLOWING_KISS = "FACE_BLOWING_KISS",
  FIRE = "FIRE",
  FOLDED_HANDS = "FOLDED_HANDS",
  LOUDLY_CRYING_FACE = "LOUDLY_CRYING_FACE",
  ROLLING_LAUGHING = "ROLLING_LAUGHING",
  SMILING_FACE_HEARTS = "SMILING_FACE_HEARTS",
}

export type EmojiReactionsType = keyof typeof EmojiReactions;
export type EmojiReactionElement = {
  component: JSX.Element;
  persistent: boolean;
};
export const emojiReactionKeys: Partial<Record<EmojiReactionsType, EmojiReactionElement>> = {
  [EmojiReactions.RAISED_HAND]: { component: <img src={RaisedHand} alt="raise_hand" />, persistent: true },
  [EmojiReactions.WAVING_HAND]: { component: <img src={WavingHand} alt="waving_hand" />, persistent: false },
  [EmojiReactions.RAISING_HANDS]: {
    component: <img src={RaisingHands} alt="raising_hands" />,
    persistent: false,
  },
  [EmojiReactions.PARTY_POPPER]: { component: <img src={PartyPopper} alt="party_popper" />, persistent: false },
  [EmojiReactions.THUMBS_UP]: { component: <img src={ThumbsUp} alt="thumbs_up" />, persistent: false },
  [EmojiReactions.RED_HEART]: { component: <img src={RedHeart} alt="red_heart" />, persistent: false },
  [EmojiReactions.SMILE_FACE_SMILE_EYE]: {
    component: <img src={SmilingFaceSmilingEye} alt="similing_face_smiling_eye" />,
    persistent: false,
  },
  [EmojiReactions.FACE_BLOWING_KISS]: {
    component: <img src={FaceBlowingKiss} alt="face_blowing_kiss" />,
    persistent: false,
  },
  [EmojiReactions.FIRE]: { component: <img src={Fire} alt="fire" />, persistent: false },
  [EmojiReactions.FOLDED_HANDS]: { component: <img src={FoldedHands} alt="folded_hands" />, persistent: false },
  [EmojiReactions.LOUDLY_CRYING_FACE]: {
    component: <img src={LoudlyCryingFace} alt="loudly_crying_face" />,
    persistent: false,
  },
  [EmojiReactions.ROLLING_LAUGHING]: {
    component: <img src={RollingLaughing} alt="rolling_laughing" />,
    persistent: false,
  },
  [EmojiReactions.SMILING_FACE_HEARTS]: {
    component: <img src={SmilingFaceHearts} alt="smiling_face_hearts" />,
    persistent: false,
  },
  [EmojiReactions.OK_HAND]: {
    component: <img src={OkHand} alt="ok_hand" />,
    persistent: false,
  },
  [EmojiReactions.WHITE_CHECK_MARK]: {
    component: <img src={WhiteCheckMark} alt="white_check_mark" />,
    persistent: false,
  },
  [EmojiReactions.EYES]: {
    component: <img src={Eyes} alt="eyes" />,
    persistent: false,
  },
};

export default function Emoji({
  emoji,
  className,
  handleSelectEmoji,
}: {
  emoji: EmojiReactionsType;
  className?: string;
  handleSelectEmoji?: (emoji: EmojiReactionsType, persistent: boolean) => void;
}) {
  const onClickEmoji = () => {
    if (handleSelectEmoji && emojiReactionKeys[emoji]) {
      handleSelectEmoji(emoji, emojiReactionKeys[emoji]!.persistent);
    }
  };

  return (
    <span className={className} onClick={onClickEmoji}>
      {emojiReactionKeys[emoji]?.component}
    </span>
  );
}
