import { stopWorkingRequest } from "../timeTracker/actions";
import { captureScreenshot, updatedUserCaptureScreenshot, updatedUserTimeTracking } from "../users/actions";

export const SCREENSHOT_EVENT_TYPES = {
  ENABLE_TIMETRACKING: "screenshot/show-timeTracking",
  CAPTURE_SCREENSHOT: "screenshot/capture-screenShot",
};

export const handleIncomingWorkspaceMessage = (store: any) => (type: string, payload: any) => {
  switch (type) {
    case SCREENSHOT_EVENT_TYPES.ENABLE_TIMETRACKING:
      if (payload.value === true) {
        store.dispatch(updatedUserTimeTracking(payload));
      }

      store.dispatch(updatedUserTimeTracking(payload));
      store.dispatch(stopWorkingRequest("Clicking STOP button of Time Tracker Contrller"));
      break;

    case SCREENSHOT_EVENT_TYPES.CAPTURE_SCREENSHOT:
      store.dispatch(updatedUserCaptureScreenshot(payload));
      store.dispatch(captureScreenshot(payload));

      break;
  }
};
