import { WorkspaceBackground } from ".";
import { AppThunk } from "../types";
import { getCurrentWorkspaceId } from "../users";
import { WorkspaceBackgroundActions, WORKSPACE_BACKGROUND_EVENT_TYPES } from "./actions-enum";
import { sendMessage } from "../socket";

interface CreateWorkspaceBackground {
  url: string;
  width: number;
  height: number;
  type: string;
}

interface DeleteWorkspaceBackground {
  id: number;
}

export type WorkspaceBackgroundActionTypes =
  | ReturnType<typeof createWorkspaceBackgroundAction>
  | ReturnType<typeof deleteWorkspaceBackgroundAction>
  | ReturnType<typeof getAllWorkspaceBackgroundAction>
  | ReturnType<typeof createdWorkspaceBackgroundAction>
  | ReturnType<typeof deletedWorkspaceBackgroundAction>
  | ReturnType<typeof gotAllWorkspaceBackgroundAction>;

function createWorkspaceBackgroundAction(payload: CreateWorkspaceBackground) {
  return {
    type: WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_CREATE,
    payload: { workspaceBackground: payload },
  } as const;
}

export function createWorkspaceBackground(payload: CreateWorkspaceBackground): AppThunk {
  return (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState())!;

    dispatch(createWorkspaceBackgroundAction(payload));
    dispatch(sendMessage(WORKSPACE_BACKGROUND_EVENT_TYPES.WORKSPACE_BACKGROUND_CREATE, { workspaceId, ...payload }));
  };
}

export function createdWorkspaceBackgroundAction(payload: WorkspaceBackground) {
  return {
    type: WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_CREATE_SUCCESS,
    payload,
  } as const;
}

export function createdWorkspaceBackground(payload: WorkspaceBackground): AppThunk {
  return dispatch => {
    dispatch(createdWorkspaceBackgroundAction(payload));
  };
}

function deleteWorkspaceBackgroundAction(payload: DeleteWorkspaceBackground) {
  return {
    type: WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_DELETE,
    payload,
  } as const;
}

export function deleteWorkspaceBackground(payload: DeleteWorkspaceBackground): AppThunk {
  return dispatch => {
    dispatch(deleteWorkspaceBackgroundAction(payload));
    dispatch(
      sendMessage(WORKSPACE_BACKGROUND_EVENT_TYPES.WORKSPACE_BACKGROUND_DELETE, {
        id: payload.id,
      }),
    );
  };
}

export function deletedWorkspaceBackgroundAction(id: number) {
  return {
    type: WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_DELETE_SUCCESS,
    payload: { id },
  } as const;
}

export function deletedWorkspaceBackground(id: number): AppThunk {
  return dispatch => {
    dispatch(deletedWorkspaceBackgroundAction(id));
  };
}

function getAllWorkspaceBackgroundAction(workspaceId: number) {
  return {
    type: WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_GET,
    payload: { workspaceId },
  } as const;
}

export function getAllWorkspaceBackgrounds(workspaceId: number): AppThunk {
  return (dispatch, getState) => {
    dispatch(getAllWorkspaceBackgroundAction(workspaceId));
    dispatch(
      sendMessage(WORKSPACE_BACKGROUND_EVENT_TYPES.WORKSPACE_BACKGROUND_GET, {
        workspaceId,
      }),
    );
  };
}

export function gotAllWorkspaceBackgroundAction(workspaceBackgrounds: WorkspaceBackground[]) {
  return {
    type: WorkspaceBackgroundActions.WORKSPACE_BACKGROUND_GET_SUCCESS,
    workspaceBackgrounds,
  } as const;
}

export function gotAllWorkspaceBackgrounds(workspaceBackgrounds: WorkspaceBackground[]): AppThunk {
  return dispatch => {
    dispatch(gotAllWorkspaceBackgroundAction(workspaceBackgrounds));
  };
}
