import { NOTIFICATION_WARN_TIMEOUT } from "../constant";
import logError from "../utils/logError";
import { channels } from "./channels";
import { sendMessageOverIPC } from "./sendMessageOverIPC";
import logoImage from "../assets/images/logo192.png";
import localData from "../localStorageKeys";
import { getDeviceInfo } from "../utils/helpers";
interface NotificationProps {
  title: string;
  body?: string;
}

export function sendElectronNotification({ title, body }: NotificationProps) {
  const nativeNotification = localData.fetch("settings.workspace.nativeNotification");
  const { os } = getDeviceInfo();

  if (window.electron && nativeNotification === "true") {
    try {
      const notification = new Notification(title, {
        body: body,
        icon: os === "macos" ? undefined : logoImage,
        silent: true,
      });

      notification.onclick = async () => {
        await sendMessageOverIPC(channels.SHOW_APP);
      };

      window.setTimeout(() => {
        notification.close();
      }, NOTIFICATION_WARN_TIMEOUT);
    } catch {
      logError("Notifications not supported");
    }
  }
}
