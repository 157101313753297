import React, { lazy, Suspense } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import { PrivateRoute, PublicRoute, history } from ".";
import * as routes from "./constants";

import { AppLoader } from "../components/containers";
import { useInternetStatus } from "../utils/hooks/useInternetStatus";

const SignUp = lazy(() => import("../screens/SignUp"));
const SignUpMobile = lazy(() => import("../screens/SignUpMobile"));
const MobileInstall = lazy(() => import("../screens/MobileInstall"));
const LoginCheck = lazy(() => import("../screens/LoginCheck"));
const Login = lazy(() => import("../screens/Login"));
const LoginDesktop = lazy(() => import("../screens/LoginDesktop"));
const Dashboard = lazy(() => import("../screens/Dashboard/ConnectedDashboard"));
const DashboardConfirmEmailChange = lazy(() => import("../screens/Dashboard/DashboardConfirmEmailChange"));
const NoMatch = lazy(() => import("../screens/NoMatch"));
const CreateWorkspace = lazy(() => import("../screens/CreateWorkspace"));
const ConfirmMail = lazy(() => import("../screens/ConfirmMail"));
const JoinWorkspace = lazy(() => import("../screens/JoinWorkspace"));
const SlackOAuth = lazy(() => import("../screens/SlackOAuth"));
const ConfirmAccountDeleted = lazy(() => import("../screens/ConfirmAction/ConfirmAccountDeleted"));
const ConfirmWorkspaceDeleted = lazy(() => import("../screens/ConfirmAction/ConfirmWorkspaceDeleted"));
const ConfirmLeftWorkspace = lazy(() => import("../screens/ConfirmAction/ConfirmLeftWorkspace"));
const ResetPasswordRequest = lazy(() => import("../screens/ResetPasswordRequest"));
const ResetPassword = lazy(() => import("../screens/ResetPassword"));
const ResetPasswordCheckMail = lazy(() => import("../screens/ResetPasswordCheckMail"));
const GoogleOAuth = lazy(() => import("../screens/GoogleOAuth"));
const AzureOAuth = lazy(() => import("../screens/AzureOAuth"));
const MeetingLogs = lazy(() => import("../screens/reports/MeetingLogs"));
const OnlineLogs = lazy(() => import("../screens/reports/OnlineLogs"));
const WorkingLogs = lazy(() => import("../screens/reports/WorkingLogs"));
const DownloadApp = lazy(() => import("../screens/DownloadApp"));
const ManageTeam = lazy(() => import("../screens/reports/ManageTeam"));
const EventLog = lazy(() => import("../screens/reports/EventLogs"));
const Billing = lazy(() => import("../screens/reports/Billings"));
const CustomOffice = lazy(() => import("../screens/CMS/CustomOffice"));
const ProfileAvatar = lazy(() => import("../screens/CMS/ProfileAvatar"));
const CheckPasswordScreen = lazy(() => import("../screens/CMS/CheckPassword"));
const AutoRedirectToCustomOfficeCMS = lazy(() => import("../screens/CMS/RedirectToCustomOffice"));
const CmsEventLog = lazy(() => import("../screens/CMS/CmsEventLog"));
const CmsPermission = lazy(() => import("../screens/CMS/CmsPermission"));
const AutoRedirectToOnlineLog = lazy(() => import("../screens/reports/RedirectToOnlineLog"));
const NotificationsWindow = lazy(() => import("../screens/NotificationsWindow"));
const ChatworkOAuth = lazy(() => import("../screens/ChatworkOAuth"));
const SigningOut = lazy(() => import("../screens/SigningOut"));
const EmailVerification = lazy(() => import("../screens/EmailVerification"));
const AppErrors = lazy(() => import("../screens/AppErrors"));
const StopSharingWindow = lazy(() => import("../screens/StopSharingWindow"));
const WorkspaceReferral = lazy(() => import("../screens/WorkspaceReferral"));
const CreateInvoices = lazy(() => import("../screens/reports/WorkingLogs/Invoices/CreateInvoicesScreen"));
const StopRemoteControlWindow = lazy(() => import("../screens/StopRemoteControlWindow"));
const ScreenshareOverlayWindow = lazy(() => import("../screens/ScreenshareOverlayWindow"));
const GuestWaitingPage = lazy(() => import("../screens/GuestWaitingPage"));
const TextChatWindow = lazy(() => import("../screens/TextChatWindow"));
const LoginWindow = lazy(() => import("../screens/LoginWindow"));
const TextChatWindowForAudioExtraction = lazy(() => import("../screens/TextChatWindowForAudioExtraction"));
const SessionExistLandingPage = lazy(() => import("../screens/Dashboard/SessionExist"));
const Subtitle = lazy(() => import("../screens/Subtitle"));

// Guest routers
const GuestJoin = lazy(() => import("../screens/GuestLandingPage"));
const ListenerJoin = lazy(() => import("../screens/ListenerLandingPage"));
// Recording Join
const RecordingJoin = lazy(() => import("../screens/RedirectToRecording"));

export function AppRouter() {
  useInternetStatus();

  const Loader = (
    <div style={{ width: "100vw", height: "100vh" }}>
      <AppLoader />
    </div>
  );

  return (
    <Suspense fallback={Loader}>
      <Router history={history}>
        <Switch>
          {/* App Routes */}
          <PublicRoute exact path={routes.APP_SERVICE_UNAVAILABLE_ROUTE} component={AppErrors} redirect={false} />
          <PublicRoute exact path={routes.APP_NO_PERMISSION_ROUTE} component={AppErrors} redirect={false} />
          {/* User Routes */}
          <PublicRoute exact path={routes.USER_SIGNUP_STEP2_ROUTE_PATH} component={SignUp} step={true} />
          <PublicRoute exact path={routes.USER_SIGNUP_ROUTE} component={SignUp} />
          <PublicRoute exact path={routes.USER_SIGNUP_MOBILE_ROUTE} component={SignUpMobile} />
          <PrivateRoute exact path={routes.USER_MOBILE_INSTALLATION_ROUTE} component={MobileInstall} />
          <PrivateRoute exact path={routes.USER_LOGIN_CHECK_ROUTE} component={LoginCheck} />
          <PublicRoute exact path={routes.USER_LOGIN_ROUTE} component={Login} />
          <PublicRoute exact path={routes.USER_LOGIN_DESKTOP_ROUTE} component={LoginDesktop} />
          <PublicRoute exact path={routes.USER_JOIN_WORKSPACE_ROUTE} component={JoinWorkspace} redirect={false} />
          <PublicRoute exact path={routes.WORKSPACE_REFERRAL_ROUTE} component={WorkspaceReferral} redirect={false} />
          <PublicRoute exact path={routes.USER_CONFIRM_EMAIL_ROUTE} component={ConfirmMail} />
          <PublicRoute exact path={routes.USER_ACCOUNT_DELETED_ROUTE} component={ConfirmAccountDeleted} />
          <PublicRoute exact path={routes.USER_RESET_PASSWORD_REQUEST_ROUTE} component={ResetPasswordRequest} />
          <PublicRoute exact path={routes.USER_RESET_PASSWORD_CHECK_MAIL_ROUTE} component={ResetPasswordCheckMail} />
          <PublicRoute exact path={routes.USER_EMAIL_VERIFICATION} component={EmailVerification} />
          <Route exact path={routes.USER_RESET_PASSWORD_TOKEN_ROUTE} component={ResetPassword} />
          <PrivateRoute exact path={routes.SLACK_OAUTH_ROUTE} component={SlackOAuth} />
          <PublicRoute exact path={routes.GOOGLE_OAUTH_ROUTE} component={GoogleOAuth} redirect={false} />
          <PrivateRoute exact path={routes.CHATWORK_OAUTH_ROUTE} component={ChatworkOAuth} />
          <PrivateRoute exact path={routes.AZURE_OAUTH_ROUTE} component={AzureOAuth} />
          <PrivateRoute exact path={routes.SIGNING_OUT_ROUTE} component={SigningOut} />
          <PrivateRoute exact path={routes.GUEST_WAITING_PATH} component={GuestWaitingPage} canListenerAccess={true} />
          {/* Workspace Routes */}
          <Route exact path="/" render={() => <Redirect to={routes.WORKSPACE_BASE_ROUTE} />} />
          <PrivateRoute exact path={routes.WORKSPACE_NAMED_ROUTE} component={Dashboard} canListenerAccess={true} />
          <PrivateRoute
            exact
            path={routes.WORKSPACE_CONFIRM_EMAIL_CHANGE_ROUTE}
            component={DashboardConfirmEmailChange}
          />
          <PrivateRoute exact path={routes.WORKSPACE_CREATE_ROUTE} component={CreateWorkspace} />
          <PrivateRoute exact path={routes.WORKSPACE_DELETED_ROUTE} component={ConfirmWorkspaceDeleted} />
          <PrivateRoute exact path={routes.WORKSPACE_LEFT_ROUTE} component={ConfirmLeftWorkspace} />
          {/* Reports Routes */}
          <PrivateRoute exact path={routes.REPORTS_BASE_ROUTE} component={AutoRedirectToOnlineLog} />
          <PrivateRoute exact path={routes.REPORTS_WORKING_LOGS_WEEKLY_ROUTE} component={WorkingLogs} />
          <PrivateRoute exact path={routes.REPORTS_WORKING_LOGS_DAILY_ROUTE} component={WorkingLogs} />
          <PrivateRoute exact path={routes.REPORTS_WORKING_LOGS_EXPORT_ROUTE} component={WorkingLogs} />
          <PrivateRoute exact path={routes.REPORTS_WORKING_LOGS_MANUAL_LOG_ROUTE} component={WorkingLogs} />
          <PrivateRoute exact path={routes.REPORTS_INVOICES_ROUTE} component={WorkingLogs} />
          <PrivateRoute exact path={routes.REPORTS_INVOICES_CREATE_ROUTE} component={CreateInvoices} />
          <PrivateRoute exact path={routes.REPORTS_INVOICES_EDIT_ROUTE} component={CreateInvoices} />
          <PrivateRoute exact path={routes.REPORTS_ONLINE_LOGS_EXPORT_ROUTE} component={OnlineLogs} />
          <PrivateRoute exact path={routes.REPORTS_ONLINE_LOGS_ROUTE} component={OnlineLogs} />
          <PrivateRoute exact path={routes.REPORTS_TRANSCRIPTS_CONTENT_ROUTE} component={MeetingLogs} />
          {/* Manage Teams */}
          <PrivateRoute exact path={routes.REPORTS_MANAGE_TEAM_MEMBER_BASE_ROUTE} component={ManageTeam} />
          <PrivateRoute exact path={routes.REPORTS_MANAGE_TEAM_PROJECT_BASE_ROUTE} component={ManageTeam} />
          <PrivateRoute exact path={routes.REPORTS_MANAGE_TEAM_GROUP_BASE_ROUTE} component={ManageTeam} />
          <PrivateRoute
            exact
            path={routes.REPORTS_MANAGE_TEAM_TIMETRACKING_SETTING_BASE_ROUTE}
            component={ManageTeam}
          />
          <PrivateRoute path={routes.REPORTS_MANAGE_TEAM_IP_WHITE_LISTBASE_ROUTE} component={ManageTeam} />
          <PrivateRoute path={routes.REPORTS_MANAGE_TEAM_ENTERPRISE_MANAGEMENT_ROUTE} component={ManageTeam} />
          {/* Event Log */}
          <PrivateRoute exact path={routes.REPORTS_EVENT_LOG_ROUTE} component={EventLog} />
          {/* Billing */}
          <PrivateRoute exact path={routes.REPORTS_BILLING_ROUTE} component={Billing} />
          {/* Custom Office */}
          <PrivateRoute exact path={routes.CMS_BASE_ROUTE} component={AutoRedirectToCustomOfficeCMS} />
          <PrivateRoute exact path={routes.CMS_CHECK_PASSWORD_ROUTE} component={CheckPasswordScreen} />
          <PrivateRoute exact path={routes.CMS_CUSTOM_OFFICE_ROUTE} component={CustomOffice} />{" "}
          <PrivateRoute exact path={routes.CMS_CUSTOM_OFFICE_OBJECT_ROUTE} component={CustomOffice} />
          <PrivateRoute exact path={routes.CMS_CUSTOM_OFFICE_BACKGROUND_ROUTE} component={CustomOffice} />
          <PrivateRoute exact path={routes.CMS_CUSTOM_OFFICE_TEMPLATE_ROUTE} component={CustomOffice} />
          <PrivateRoute exact path={routes.CMS_CUSTOM_OFFICE_CATEGORY_ROUTE} component={CustomOffice} />
          <PrivateRoute exact path={routes.CMS_PROFILE_AVATAR} component={ProfileAvatar} />
          <PrivateRoute exact path={routes.CMS_PROFILE_AVATAR_CATEGORY_ROUTE} component={ProfileAvatar} />
          <PrivateRoute exact path={routes.CMS_EVENT_LOG_BASE_ROUTE} component={CmsEventLog} />
          <PrivateRoute exact path={routes.CMS_PERMISSION_BASE_ROUTE} component={CmsPermission} />
          <PrivateRoute exact path={routes.CMS_PERMISSION_GLOBAL_ROUTE} component={CmsPermission} />
          <PrivateRoute exact path={routes.CMS_PERMISSION_WORKSPACE_BASE_ROUTE} component={CmsPermission} />
          <PrivateRoute exact path={routes.CMS_PERMISSION_WORKSPACE_ROUTE} component={CmsPermission} />
          {/* Electron component routes*/}
          <PrivateRoute exact path={routes.NOTIFICATIONS_WINDOW_ROUTE} component={NotificationsWindow} />
          <PrivateRoute exact path={routes.STOP_SHARING_WINDOW_ROUTE} component={StopSharingWindow} />
          <PrivateRoute exact path={routes.STOP_REMOTE_CONTROL_WINDOW} component={StopRemoteControlWindow} />
          <PrivateRoute exact path={routes.SCREENSHARE_OVERLAY_WINDOW} component={ScreenshareOverlayWindow} />
          <PrivateRoute exact path={routes.TEXT_CHAT_WINDOW_ROUTE} component={TextChatWindow} />
          <PrivateRoute exact path={routes.LOGIN_WINDOW_ROUTE} component={LoginWindow} />
          <PrivateRoute
            exact
            path={routes.TEXT_CHAT_WINDOW_FOR_AUDIO_EXTRACTION_ROUTE}
            component={TextChatWindowForAudioExtraction}
          />
          <PrivateRoute exact path={routes.SUBTITLE_WINDOW_ROUTE} component={Subtitle} />
          {/* Promote Desktop App Route */}
          <PrivateRoute exact path={routes.PROMOTE_APP} component={DownloadApp} />
          {/* Guest Join Route */}
          <PrivateRoute path={routes.GUEST_SESSION_EXISTING_PAGE} component={SessionExistLandingPage} />
          <PrivateRoute path={routes.LISTENER_SESSION_EXISTING_PAGE} component={SessionExistLandingPage} />
          <PublicRoute exact path={routes.GUEST_JOIN_ROUTE} component={GuestJoin} />
          <PublicRoute exact path={routes.LISTENER_JOIN_ROUTE} component={ListenerJoin} />
          <Route exact path={routes.REDIRECT_RECORDING_ROUTE} component={RecordingJoin} />
          {/* 404 */}
          <Route>
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}
