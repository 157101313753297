import {
  LocalParticipant,
  LocalTrackPublication,
  RemoteTrack,
  RemoteParticipant,
  RemoteTrackPublication,
  Participant,
  Room,
  DisconnectReason,
  TrackPublication,
  RoomEvent,
  Track,
  ConnectionState,
  VideoPresets43,
} from "livekit-client";
import {
  EGRESS_SCREEN_FRAME_RATE,
  MEDIASOUP_AUDIO_SAMPLE_RATE,
  REQUEST_LIMIT,
  SCREEN_FRAME_RATE,
  USE_JITSI_MEET_AUDIO_CONSTRAINTS,
} from "../constant";
import {
  actions,
  getCurrentAutoGainStatus,
  getDeviceSettings,
  getDevicesList,
  getLocalAudioStream,
  updateDeviceSetting,
  updateLocalAudioStream,
} from "../store/devices";
import { store } from "../store";
import { LogCategory, log } from "./log";
import { connectNoiseSuppressorNode } from "./rnnoise/connectNoiseSuppressorNode";
// import { filteredAudioStream } from "./filtered-audiostream";
import logError from "./logError";
import { LivekitActions } from "../store/livekit";
import { RoomOptions, RoomConnectOptions } from "livekit-client/dist/src/options";
import { LivekitParticipant } from "../store/types";
import {
  createBlackCanvas,
  delay,
  getExtractedAudioStream,
  getUserInfoFromParticipantId,
  getVoiceChannelShortId,
  useNoiseReductionConfig,
} from "./helpers";
import dayjs from "dayjs";
import { listDevices } from "./devices";
import {
  cancelRecordAudioExtraction,
  getIsLoadingRecordAudioExtraction,
  getIsRecordingAudioExtraction,
  getIsStartingRecordAudioExtraction,
  publishEgressTrack,
  sendScreenConnectionAlert,
  setAudioTrackId,
  setVideoTrackId,
} from "../store/audioExtraction";
import { getMyMember, updateUserScreenActiveSend } from "../store/users";
import { getScreenshare, showStopSharingWindow } from "../store/screenshare";
import * as ipc from "../electron/ipc";

const AUDIO_BIT_RATE = 12_000;
const RETRY_ATTEMPT = 3;
const EGRESS_SCREEN_SHARE_RETRY = 10;

const logE = (event: string, ...args: any[]) => log(LogCategory.Egress, "##### " + event, ...args);

export interface LivekitPayload {
  loading?: boolean;
  participant?: LivekitParticipant;
  participants?: LivekitParticipant[];
  reason?: DisconnectReason;
  forceDisconnect?: boolean;
  voiceChannelShortId?: string;
  reconnection?: boolean;
  source?: Track.Source;
}

export default class Livekit {
  private _stream: MediaStream;
  private _stream_cloned: MediaStream;
  private _extracted_stream?: MediaStream;
  private autoSubscribe = false;
  private onMessage: (event: string, payload?: LivekitPayload) => void;
  private room: Room | null = null;
  private audioContext: AudioContext;
  private audioSource: MediaStreamAudioSourceNode;
  private audioSourceNoiseReduction: MediaStreamAudioSourceNode;
  private peer: MediaStreamAudioDestinationNode;
  private peerNoiseReduction: MediaStreamAudioDestinationNode;
  private _noiseReduction: boolean;
  private participant: LocalParticipant | undefined = undefined;
  private audioPublication: LocalTrackPublication | undefined = undefined;
  private screensharePublication: LocalTrackPublication | undefined = undefined;
  private remoteParticipants: Map<string, RemoteParticipant> = new Map();
  private isUpdatingMicDevice: boolean = false;
  private isUpdatingWebcam: boolean = false;
  private isUpdatingScreenshare: boolean = false;
  private forceDisconnect: boolean = false;
  private _rnnoiseProcessor: AudioWorkletNode | null;

  constructor(onMessage: (event: string, payload?: LivekitPayload) => void) {
    this.onMessage = onMessage;
    this.initializeLivekit();
  }

  private initializeLivekit() {
    this._stream?.getTracks().forEach(t => t.stop());
    this._stream_cloned?.getTracks().forEach(t => t.stop());
    this._extracted_stream?.getTracks().forEach(t => t.stop());
    this._rnnoiseProcessor = null;
    this.closeContext();
    this.room = null;
    this._noiseReduction = false;
    this.participant = undefined;
    this.audioPublication = undefined;
    this.screensharePublication = undefined;
    this.remoteParticipants = new Map();
    this.isUpdatingMicDevice = false;
    this.isUpdatingWebcam = false;
    this.isUpdatingScreenshare = false;
    this.forceDisconnect = false;
  }

  public connectRoom = async ({
    url,
    token,
    autoSubscribe,
    noiseReduction,
    iceTransportPolicy,
  }: {
    url: string;
    token: string;
    autoSubscribe: boolean;
    noiseReduction: boolean;
    iceTransportPolicy: boolean;
  }) => {
    this._noiseReduction = noiseReduction;
    this.autoSubscribe = autoSubscribe;
    await this.disconnectRoom();
    this.initializeLivekit();
    this.room = new Room();

    this.addEventListner();
    await this.joinRoom(url, token, iceTransportPolicy);
    await this.handleConnection(false);
  };

  private async joinRoom(url: string, token: string, iceTransportPolicy: boolean) {
    if (this.room) {
      type roomOption = RoomOptions & RoomConnectOptions;

      for (let tries = 0; tries < RETRY_ATTEMPT; tries++) {
        try {
          let roomOptions: roomOption = {
            adaptiveStream: false,
            stopLocalTrackOnUnpublish: false,
            autoSubscribe: this.autoSubscribe,
            maxRetries: 6,
            peerConnectionTimeout: 15000,
          };

          if (iceTransportPolicy) {
            roomOptions.rtcConfig = { iceTransportPolicy: "relay" };
          }

          return await this.room.connect(`wss://${url}`, token, roomOptions);
        } catch (err) {
          console.error("Failed to connect to room", err);

          if (tries < RETRY_ATTEMPT) console.warn('join is retrying [attempt:"%s"]', tries);
          else {
            this.onMessage(RoomEvent.Reconnecting, {});
            return;
          }
        }
      }
    }
  }

  private async handleConnection(reconnection: boolean) {
    this.onMessage(LivekitActions.LIVEKIT_LOADING, {
      loading: false,
    });
    this.participant = undefined;

    if (reconnection === false) {
      this.screensharePublication = undefined;
      this.audioPublication = undefined;
    }

    this.remoteParticipants = new Map();
    this.isUpdatingMicDevice = false;
    this.isUpdatingWebcam = false;
    this.isUpdatingScreenshare = false;

    if (this.room) {
      this.participant = this.room?.localParticipant;
      const livekitParticipants = this.getLatestRemoteParticipants(this.room.participants);

      this.onMessage(RoomEvent.SignalConnected, {
        voiceChannelShortId: getVoiceChannelShortId(this.room.name),
        participants: [
          ...livekitParticipants,
          {
            identity: this.participant.identity,
            audioPublication: this.participant.getTrack(Track.Source.Microphone),
            videoPublication: this.participant.getTrack(Track.Source.Camera),
            screensharePublication: this.participant.getTrack(Track.Source.ScreenShare),
          },
        ],
        reconnection,
      });
    }
  }

  private getLatestRemoteParticipants(remoteParticipants: Map<string, RemoteParticipant>) {
    let livekitParticipants: LivekitParticipant[] = [];

    for (const participant of Array.from(remoteParticipants.values())) {
      if (participant.metadata !== this.participant?.metadata && participant.metadata) {
        const remotePariticipantUserInfo = getUserInfoFromParticipantId(
          this.remoteParticipants.get(participant.metadata)?.identity,
        );
        const participantUserInfo = getUserInfoFromParticipantId(participant.identity);

        if (
          !remotePariticipantUserInfo.userId ||
          parseInt(participantUserInfo.timestamp!) > parseInt(remotePariticipantUserInfo.timestamp)
        ) {
          this.remoteParticipants.set(participant.metadata, participant as RemoteParticipant);

          const screenshareTrack = participant.getTrack(Track.Source.ScreenShare);

          if (screenshareTrack) {
            screenshareTrack.setSubscribed(true);
          }
        }
      }
    }

    for (const participant of Array.from(this.remoteParticipants.values())) {
      livekitParticipants = [
        ...livekitParticipants,
        {
          identity: participant.identity,
          audioPublication: participant.getTrack(Track.Source.Microphone),
          videoPublication: participant.getTrack(Track.Source.Camera),
          screensharePublication: participant.getTrack(Track.Source.ScreenShare),
        },
      ];
    }

    return livekitParticipants;
  }

  public disconnectRoom = async (force?: boolean) => {
    this._stream?.getTracks().forEach(t => t.stop());
    this._stream_cloned?.getTracks().forEach(t => t.stop());
    this._extracted_stream?.getTracks().forEach(t => t.stop());
    this.closeContext();

    this.forceDisconnect = !!force;

    if ((this.room && this.room.state !== ConnectionState.Disconnected) || force) {
      await this.room?.disconnect(true);
    }
  };

  public async updateMic(noiseReduction?: boolean) {
    if (noiseReduction !== undefined) {
      console.log("noise reduction track...");
      this._noiseReduction = noiseReduction;
    }

    if (this.audioPublication && this.audioContext && this.audioContext.state !== "closed") {
      await this.publishAudioTrack();
    }
  }

  public async publishAudioTrack() {
    if (this.room && this.room?.state === ConnectionState.Connected && !this.isUpdatingMicDevice) {
      this.isUpdatingMicDevice = true;
      this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
        loading: true,
      });

      // await this.stopAudioTrack();

      let newAudioTrack: MediaStreamTrack;

      try {
        const { normalTrack, noiseReductionTrack } = await this.fetchStream();

        newAudioTrack = !normalTrack ? noiseReductionTrack : normalTrack;

        if (noiseReductionTrack) {
          store.dispatch(updateLocalAudioStream(noiseReductionTrack) as any);
        } else {
          store.dispatch(updateLocalAudioStream(normalTrack) as any);
        }
      } catch (err) {
        this.isUpdatingMicDevice = false;
        this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
          loading: false,
        });

        return;
      }

      for (let tries = 0; tries < REQUEST_LIMIT && this.room?.state === ConnectionState.Connected; tries++) {
        try {
          if (this.participant?.getTrack(Track.Source.Microphone)) {
            await this.participant?.getTrack(Track.Source.Microphone)?.audioTrack?.replaceTrack(newAudioTrack, true);
          } else {
            await this.participant?.publishTrack(newAudioTrack, {
              audioBitrate: AUDIO_BIT_RATE,
              dtx: false,
              source: Track.Source.Microphone,
              stopMicTrackOnMute: true,
            });
          }

          setTimeout(() => {
            this.isUpdatingMicDevice = false;
            this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
              loading: false,
            });
          }, 350);

          return;
        } catch (err) {
          console.error("Failed to publish audio track", err);
          console.log("Trying to publish audio track again[attempt:%s]", tries);
          this._stream?.getTracks().forEach(t => t.stop());
          this._stream_cloned?.getTracks().forEach(t => t.stop());
          this.closeContext();
          this.isUpdatingMicDevice = false;
          this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
            loading: false,
          });

          if (tries === REQUEST_LIMIT - 1) {
            this.onMessage(RoomEvent.Reconnecting, {});
            return;
          }
        }
      }
    }
  }

  public async stopAudioTrack() {
    const audioTrackPublication = this.participant?.getTrack(Track.Source.Microphone);

    if (audioTrackPublication && audioTrackPublication.audioTrack) {
      await this.participant?.unpublishTrack(audioTrackPublication.audioTrack.mediaStreamTrack, true);
      this.audioPublication = undefined;
    }
  }

  public async mute() {
    if (this.audioPublication && !this.audioPublication?.isMuted && !this.isUpdatingMicDevice) {
      try {
        this.isUpdatingMicDevice = true;
        this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
          loading: true,
        });

        const localAudioTrack = getLocalAudioStream(store.getState());
        const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());

        localAudioTrack?.stop();

        this._stream?.getTracks().forEach(t => t.stop());
        this._stream_cloned?.getTracks().forEach(t => t.stop());

        if (!isRecordingAudioExtraction) {
          this.closeContext();

          await this.audioPublication?.mute();
        }

        store.dispatch(updateLocalAudioStream(undefined) as any);

        setTimeout(() => {
          this.isUpdatingMicDevice = false;
          this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
            loading: false,
          });
        }, 350);
      } catch (err) {
        console.log("Mute status error:", err);
        this.isUpdatingMicDevice = false;
        this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
          loading: false,
        });
      }
    }
  }

  public async unmute() {
    if (!this.audioPublication) {
      this.publishAudioTrack();
    } else if (this.audioPublication && !!this.audioPublication?.isMuted && !this.isUpdatingMicDevice) {
      try {
        this.isUpdatingMicDevice = true;
        this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
          loading: true,
        });

        const { normalTrack, noiseReductionTrack } = await this.fetchStream();
        const newAudioTrack = !normalTrack ? noiseReductionTrack : normalTrack;

        log(LogCategory.Livekit, "Checking for noiseReductionTrack", noiseReductionTrack);
        if (noiseReductionTrack) {
          log(LogCategory.Livekit, "Using noiseReductionTrack for upadting localAudioStream", noiseReductionTrack);
          store.dispatch(updateLocalAudioStream(noiseReductionTrack) as any);
        } else {
          log(LogCategory.Livekit, "Using normalTrack for upadting localAudioStream", normalTrack);
          store.dispatch(updateLocalAudioStream(normalTrack) as any);
        }

        await this.participant?.getTrack(Track.Source.Microphone)?.audioTrack?.replaceTrack(newAudioTrack, true);
        await this.audioPublication.unmute();

        setTimeout(() => {
          this.isUpdatingMicDevice = false;
          this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
            loading: false,
          });
        }, 350);
      } catch (err) {
        console.log("Unmute status error:", err);
        this.isUpdatingMicDevice = false;
        this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
          loading: false,
        });
      }
    }
  }

  public async publishWebcamTrack(stream: MediaStream) {
    if (this.room && this.room.state === ConnectionState.Connected && !this.isUpdatingWebcam) {
      this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
        loading: true,
      });
      this.isUpdatingWebcam = true;

      for (
        let tries = 0;
        tries < REQUEST_LIMIT && this.room && this.room.state === ConnectionState.Connected;
        tries++
      ) {
        const track = stream.getVideoTracks()[0];
        const currentDevice = getDeviceSettings(store.getState());

        try {
          if (this.participant?.getTrack(Track.Source.Camera)) {
            await this.participant?.getTrack(Track.Source.Camera)?.mute();
            await this.participant?.getTrack(Track.Source.Camera)?.videoTrack?.replaceTrack(track, true);
            await this.participant?.getTrack(Track.Source.Camera)?.unmute();
          } else {
            await this.participant?.publishTrack(track, {
              source: Track.Source.Camera,
              simulcast: true,
              videoSimulcastLayers: [VideoPresets43.h120, VideoPresets43.h240],
              videoCodec: "vp8",
            });
          }

          const currentDeviceId = track.getSettings().deviceId;

          setTimeout(async () => {
            this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
              loading: false,
            });
            this.isUpdatingWebcam = false;
            if (currentDevice.videoinput.id === "" && stream.id) {
              // when video input permission is granted for the first time
              const devicesList = await listDevices("videoinput");

              store.dispatch(actions.updateVideoInputDevices(devicesList));

              if (currentDeviceId === devicesList[0].deviceId) {
                store.dispatch(
                  updateDeviceSetting({
                    videoinput: {
                      id: devicesList[0].deviceId,
                      label: devicesList[0].label,
                      groupId: devicesList[0].groupId,
                    },
                  }) as any,
                );
              } else if (currentDeviceId) {
                store.dispatch(
                  updateDeviceSetting({
                    videoinput: {
                      id: currentDeviceId,
                      label: "",
                      groupId: "",
                    },
                  }) as any,
                );
              }
            }
          }, 350);

          return;
        } catch (err) {
          console.error("Failed to publish video track", err);
          console.log("Trying to publish video track again[attempt:%s]", tries);
          this._stream?.getTracks().forEach(t => t.stop());
          this.isUpdatingWebcam = false;
          this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
            loading: false,
          });

          if (tries === REQUEST_LIMIT - 1) {
            this.onMessage(RoomEvent.Reconnecting, {});

            return;
          }
        }
      }
    }
  }

  public async stopWebcamTrack() {
    try {
      if (!this.participant?.getTrack(Track.Source.Camera)) {
        this.isUpdatingWebcam = false;
        this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
          loading: false,
        });

        return;
      }

      if (
        this.participant?.getTrack(Track.Source.Camera) &&
        this.participant.getTrack(Track.Source.Camera)?.track &&
        !this.isUpdatingWebcam
      ) {
        this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
          loading: true,
        });
        this.isUpdatingWebcam = true;
        await this.participant?.getTrack(Track.Source.Camera)?.mute();
      }

      setTimeout(() => {
        this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
          loading: false,
        });
        this.isUpdatingWebcam = false;
      }, 350);
    } catch (err) {
      this.isUpdatingWebcam = false;
      this.onMessage(LivekitActions.LIVEKIT_VIDEO_LOADING, {
        loading: false,
      });
    }
  }

  public async publishScreenshareTrack(electronSourceId: string) {
    console.log("publishScreenshareTrack");
    console.log(!this.isUpdatingScreenshare);
    console.log(this.room);

    if (this.room && this.room.state === ConnectionState.Connected && !this.isUpdatingScreenshare) {
      this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
        loading: true,
      });
      this.isUpdatingScreenshare = true;

      console.log(this.screensharePublication);

      if (this.screensharePublication && this.screensharePublication.videoTrack) {
        await this.participant?.unpublishTrack(this.screensharePublication.videoTrack);
        this.screensharePublication = undefined;
      }

      const isDrawingAllowed = false; //Disable drawing by default;

      let track;

      try {
        let stream: MediaStream;

        if (electronSourceId) {
          // Example from https://www.electronjs.org/docs/api/desktop-capturer
          stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              // @ts-expect-error
              mandatory: {
                chromeMediaSource: "desktop",
                chromeMediaSourceId: electronSourceId,
                frameRate: SCREEN_FRAME_RATE,
                bitrate: 4000,
              },
            },
          });
        } else {
          stream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: true,
          });
        }

        // May mean cancelled (in some implementations).
        if (!stream) {
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
          return;
        }

        track = stream.getVideoTracks()[0];

        const mediaConstraints: MediaTrackConstraints = {
          width: { ideal: window.screen.width },
          height: { ideal: window.screen.height },
          frameRate: { ideal: 5 },
        };

        console.log("mediaConstraints", mediaConstraints);

        await track.applyConstraints(mediaConstraints);

        console.log(track);

        if (track) {
          await this.participant?.publishTrack(track, {
            source: Track.Source.ScreenShare,
            name: `${electronSourceId}:${isDrawingAllowed}:${dayjs().unix()}`,
            simulcast: false,
            videoCodec: "vp8",
          });
        }

        setTimeout(() => {
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
        }, 350);
      } catch (error) {
        logError("enableShare() | failed", error);
        this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
          loading: false,
        });
        this.isUpdatingScreenshare = false;
        if (track) track.stop();
      }
    }
  }

  public async updateScreenshareConstraints(mediaConstraints: MediaTrackConstraints) {
    if (
      this.screensharePublication &&
      this.screensharePublication.videoTrack &&
      this.participant?.getTrack(Track.Source.ScreenShare)
    ) {
      console.log("Updating screenshare track");
      try {
        this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
          loading: true,
        });

        this.isUpdatingScreenshare = true;

        const newTrack = await this.participant
          ?.getTrack(Track.Source.ScreenShare)
          ?.videoTrack?.mediaStreamTrack.applyConstraints(mediaConstraints);

        if (newTrack) {
          await this.participant?.publishTrack(newTrack, {
            source: Track.Source.ScreenShare,
            name: `screenshare-${dayjs().unix()}`,
            simulcast: false,
            videoCodec: "vp8",
          });
        }

        setTimeout(() => {
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
        }, 1000);
      } catch (err) {
        console.log("Screenshare Constraint update failed:", err);
        this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
          loading: false,
        });
        this.isUpdatingScreenshare = false;
      }
    }
  }

  public async stopScreenshareTrack() {
    if (this.screensharePublication && this.screensharePublication.videoTrack) {
      try {
        this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
          loading: true,
        });
        this.isUpdatingScreenshare = true;
        await this.participant?.unpublishTrack(this.screensharePublication.videoTrack);

        this.screensharePublication = undefined;
        setTimeout(() => {
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
        }, 1000);
      } catch (err) {
        console.log("Stop screenshare error:", err);
        this.onMessage(LivekitActions.LIVEKIT_LOADING, {
          loading: false,
        });
        this.isUpdatingScreenshare = false;
      }
    }
  }

  public async replaceScreenshareTrack() {
    if (
      this.screensharePublication &&
      this.screensharePublication.videoTrack &&
      this.participant?.getTrack(Track.Source.ScreenShare)
    ) {
      console.log("replaceScreenshareTrack");
      try {
        this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
          loading: true,
        });
        this.isUpdatingScreenshare = true;

        const canvas = createBlackCanvas(1920, 1280);
        const blackCanvasStream = canvas.captureStream();
        const blackCanvasTrack = blackCanvasStream.getVideoTracks()[0];

        await this.participant?.getTrack(Track.Source.ScreenShare)?.videoTrack?.replaceTrack(blackCanvasTrack, true);

        setTimeout(() => {
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
        }, 1000);
      } catch (err) {
        console.log("Replace screenshare error:", err);
        this.onMessage(LivekitActions.LIVEKIT_LOADING, {
          loading: false,
        });
        this.isUpdatingScreenshare = false;
      }
    }
  }

  async getAudioStream(deviceId: string, sampleRate?: number, enableAutoGainStatus?: boolean) {
    const autoGainControlValue = enableAutoGainStatus ? enableAutoGainStatus : true;

    const jitsiMeetAudioConstraints = {
      autoGainControl: true,
      echoCancellation: true,
      noiseSuppression: true,
    };

    const constraints = {
      audio: USE_JITSI_MEET_AUDIO_CONSTRAINTS
        ? {
            ...jitsiMeetAudioConstraints,
            deviceId,
            sampleRate,
          }
        : {
            deviceId,
            echoCancellation: true,
            echoCancellationType: { ideal: " system " },
            channelCount: 1,
            sampleRate: sampleRate,
            sampleSize: 16,
            noiseSuppression: true,
            autoGainControl: autoGainControlValue,
            googEchoCancellation: true,
            googAutoGainControl: autoGainControlValue,
            googExperimentalAutoGainControl: true,
            googNoiseSuppression: true,
            googExperimentalNoiseSuppression: true,
            googHighpassFilter: true,
            googTypingNoiseDetection: true,
            googBeamforming: false,
            googArrayGeometry: false,
            googAudioMirroring: true,
            googNoiseReduction: true,
            mozNoiseSuppression: true,
            mozAutoGainControl: false,
            latency: 0.01,
          },
      video: false,
    };

    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    return stream;
  }

  private updateLocalPublication(publication: LocalTrackPublication) {
    switch (publication.source) {
      case Track.Source.Microphone:
        this.audioPublication = publication;
        break;
      case Track.Source.ScreenShare:
        this.screensharePublication = publication;
        break;
      default:
        break;
    }
  }

  private clearLocalPublication(publication: LocalTrackPublication) {
    switch (publication.source) {
      case Track.Source.Microphone:
        this.audioPublication = undefined;
        break;
      case Track.Source.ScreenShare:
        this.screensharePublication = undefined;
        break;
      default:
        break;
    }
  }

  private addParticipant(participant: Participant) {
    if (participant.metadata) {
      this.remoteParticipants.set(participant.metadata, participant as RemoteParticipant);
    }

    participant.on(
      RoomEvent.TrackStreamStateChanged,
      (publication: RemoteTrackPublication, streamState: Track.StreamState) => {
        console.log("Check Track stream state changed", participant.identity, publication.source, streamState);
      },
    );
  }

  private addEventListner() {
    this.room!.on(RoomEvent.ConnectionStateChanged, (connectionState: ConnectionState) => {
      console.log("Livekit Room ConnectionStateChanged", connectionState);
      if (connectionState === "connected") {
        const isStartingRecordAudioExtraction = getIsStartingRecordAudioExtraction(store.getState());

        if (isStartingRecordAudioExtraction) {
          store.dispatch(publishEgressTrack(this.room?.name) as any);
        }

        this.handleConnection(true);

        if (this.participant?.isCameraEnabled) {
          this.participant?.getTrack(Track.Source.Camera)?.mute();
          this.participant?.getTrack(Track.Source.Camera)?.unmute();
        }
      }
    });

    this.room!.on(RoomEvent.Disconnected, (reason?: DisconnectReason | undefined) => {
      // console.log("Livekit Room Disconnected", reason, this.forceDisconnect);
      this.onMessage(RoomEvent.Disconnected, {
        reason,
        forceDisconnect: !!this.forceDisconnect,
      });
      this.initializeLivekit();
    });
    this.room!.on(
      RoomEvent.LocalTrackPublished,
      (publication: LocalTrackPublication, participant: LocalParticipant) => {
        const livekitParticipant = {
          identity: participant.identity,
          audioPublication: participant.getTrack(Track.Source.Microphone),
          videoPublication: participant.getTrack(Track.Source.Camera),
          screensharePublication: participant.getTrack(Track.Source.ScreenShare),
        };

        this.participant = participant;
        this.updateLocalPublication(publication);
        this.onMessage(RoomEvent.LocalTrackPublished, { participant: livekitParticipant });

        const isLoading = getIsLoadingRecordAudioExtraction(store.getState());

        if (isLoading) {
          if (publication.source === Track.Source.Microphone) {
            store.dispatch(setAudioTrackId(publication.track?.sid));
          } else if (publication.source === Track.Source.ScreenShare) {
            store.dispatch(setVideoTrackId(publication.track?.sid));
          }
        }
      },
    );
    this.room!.on(
      RoomEvent.LocalTrackUnpublished,
      async (publication: LocalTrackPublication, participant: LocalParticipant) => {
        const isRecording = getIsRecordingAudioExtraction(store.getState());

        if (publication.source === Track.Source.ScreenShare && isRecording) {
          const res = await this.autoRepublishEgressScreenshareTrack();

          if (res) return;
        }

        console.log("Livekit LocalTrackUnpublished", participant.identity, publication.source);
        this.participant = participant;
        this.clearLocalPublication(publication);
        this.onMessage(RoomEvent.LocalTrackUnpublished, {
          participant: {
            identity: participant.identity,
            audioPublication: participant.getTrack(Track.Source.Microphone),
            videoPublication: participant.getTrack(Track.Source.Camera),
            screensharePublication: participant.getTrack(Track.Source.ScreenShare),
          },
        });
      },
    );

    this.room!.on(RoomEvent.TrackPublished, (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
      console.log(
        "Livekit TrackPublished",
        participant.identity,
        publication.source,
        this.remoteParticipants.get(participant.metadata!)?.identity,
        participant.metadata,
        publication.isSubscribed,
      );
      if (
        !participant.metadata ||
        this.remoteParticipants.get(participant.metadata)?.identity !== participant.identity
      ) {
        return;
      }

      if (publication.source === Track.Source.ScreenShare && !publication.isSubscribed) {
        publication.setSubscribed(true);
      }

      const livekitParticipant = {
        identity: participant.identity,
        audioPublication: participant.getTrack(Track.Source.Microphone),
        videoPublication: participant.getTrack(Track.Source.Camera),
        screensharePublication: participant.getTrack(Track.Source.ScreenShare),
      };

      this.onMessage(RoomEvent.TrackPublished, {
        participant: livekitParticipant,
      });
    });

    this.room!.on(RoomEvent.TrackUnpublished, (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
      console.log("Livekit RemoteTrackUnpublished", participant.identity, publication.source, publication.trackSid);
      if (
        !participant.metadata ||
        this.remoteParticipants.get(participant.metadata)?.identity !== participant.identity
      ) {
        return;
      }

      this.onMessage(RoomEvent.TrackUnpublished, {
        participant: {
          identity: participant.identity,
          audioPublication: participant.getTrack(Track.Source.Microphone),
          videoPublication: participant.getTrack(Track.Source.Camera),
          screensharePublication: participant.getTrack(Track.Source.ScreenShare),
        },
      });
    });

    this.room!.on(RoomEvent.ParticipantConnected, (participant: RemoteParticipant) => {
      console.log("Livekit Room user ParticipantConnected", participant.identity, participant.metadata);
      const userInfo = getUserInfoFromParticipantId(participant.identity);
      const remoteParticipantUserInfo = getUserInfoFromParticipantId(
        this.remoteParticipants.get(participant.metadata!)?.identity,
      );

      if (
        this.participant?.metadata === participant.metadata ||
        parseInt(remoteParticipantUserInfo.timestamp!) >= parseInt(userInfo.timestamp!)
      ) {
        return;
      }

      console.log("-------Adding participant", participant.identity);
      this.addParticipant(participant);
      this.onMessage(RoomEvent.ParticipantConnected, {
        participant: {
          identity: participant.identity,
        },
      });
    });

    this.room!.on(RoomEvent.ParticipantDisconnected, (participant: RemoteParticipant) => {
      console.log("Livekit Room user ParticipantDisconnected", participant.identity);
      if (
        this.participant?.metadata === participant.metadata ||
        this.remoteParticipants.get(participant.metadata!)?.identity !== participant.identity
      ) {
        return;
      }

      console.log("------Removing participant", participant.identity);

      if (participant.metadata) {
        this.remoteParticipants.delete(participant.metadata);
      }

      this.onMessage(RoomEvent.ParticipantDisconnected, {
        participant: {
          identity: participant.identity,
        },
      });
    });

    this.room!.on(RoomEvent.Reconnected, () => {
      console.log("Livekit Room Reconnected");
      // this.handleConnection(true);
    });

    this.room!.on(RoomEvent.Reconnecting, async () => {
      console.log("Livekit Room Reconnecting ");
      // this._stream?.getTracks().forEach(t => t.stop());
      // this._stream = null;
      // this._stream_cloned?.getTracks().forEach(t => t.stop());
      // this._stream_cloned = null;
      // this.closeContext();
      // this.onMessage(RoomEvent.Reconnecting, {});
    });

    this.room!.on(RoomEvent.TrackMuted, (publication: TrackPublication, participant: Participant) => {
      console.log("Livekit Room TrackMuted", participant.identity, publication.source, publication.trackSid);
      if (participant.identity === this.participant?.identity) {
        this.participant = participant as LocalParticipant;

        if (publication.source === Track.Source.Microphone) {
          this.audioPublication = publication as LocalTrackPublication;
        }
      } else if (
        participant.metadata &&
        this.remoteParticipants?.get(participant.metadata)?.identity === participant.identity
      ) {
        this.remoteParticipants.set(participant.metadata, participant as RemoteParticipant);
      }

      this.onMessage(RoomEvent.TrackMuted, {
        participant: {
          identity: participant.identity,
          audioPublication: participant.getTrack(Track.Source.Microphone),
          videoPublication: participant.getTrack(Track.Source.Camera),
          screensharePublication: participant.getTrack(Track.Source.ScreenShare),
        },
        source: publication.source,
      });
    });
    this.room!.on(RoomEvent.TrackSubscriptionFailed, (trackSid: string, participant: RemoteParticipant) => {
      console.log("Livekit Room TrackSubscriptionFailed", trackSid, participant.identity);
    });
    this.room!.on(
      RoomEvent.TrackSubscriptionStatusChanged,
      (pub: RemoteTrackPublication, status: TrackPublication.SubscriptionStatus, participant: RemoteParticipant) => {
        console.log("Livekit Room TrackSubscriptionStatusChanged", pub.source, status, participant.identity);
        if (pub.source === Track.Source.ScreenShare && status === "desired") {
          pub.setSubscribed(true);
        }
      },
    );
    this.room!.on(
      RoomEvent.TrackSubscribed,
      (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
        console.log("Livekit Room TrackSubscribed", participant.identity, publication.source, publication.trackSid);
        if (
          !participant.metadata ||
          this.remoteParticipants.get(participant.metadata)?.identity !== participant.identity
        ) {
          return;
        }

        this.remoteParticipants.set(participant.metadata!, participant);
        this.onMessage(RoomEvent.TrackSubscribed, {
          participant: {
            identity: participant.identity,
            audioPublication: participant.getTrack(Track.Source.Microphone),
            videoPublication: participant.getTrack(Track.Source.Camera),
            screensharePublication: participant.getTrack(Track.Source.ScreenShare),
          },
          source: publication.source,
        });
      },
    );
    this.room!.on(RoomEvent.TrackUnmuted, (publication: TrackPublication, participant: Participant) => {
      console.log("Livekit Room TrackUnmuted", participant.identity, publication.source, publication.trackSid);
      if (participant.identity === this.participant?.identity) {
        this.participant = participant as LocalParticipant;

        if (publication.source === Track.Source.Microphone) {
          this.audioPublication = publication as LocalTrackPublication;
        }
      } else if (
        participant.metadata &&
        this.remoteParticipants?.get(participant.metadata)?.identity === participant.identity
      ) {
        this.remoteParticipants.set(participant.metadata, participant as RemoteParticipant);
      }

      this.onMessage(RoomEvent.TrackUnmuted, {
        participant: {
          identity: participant.identity,
          audioPublication: participant.getTrack(Track.Source.Microphone),
          videoPublication: participant.getTrack(Track.Source.Camera),
          screensharePublication: participant.getTrack(Track.Source.ScreenShare),
        },
        source: publication.source,
      });
    });
    this.room!.on(
      RoomEvent.TrackUnsubscribed,
      (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
        console.log("Livekit Room TrackUnsubscribed", participant.identity, publication.source, publication.trackSid);
        if (
          !participant.metadata ||
          this.remoteParticipants.get(participant.metadata)?.identity !== participant.identity
        ) {
          return;
        }

        this.onMessage(RoomEvent.TrackUnsubscribed, {
          participant: {
            identity: participant.identity,
            audioPublication: participant.getTrack(Track.Source.Microphone),
            videoPublication: participant.getTrack(Track.Source.Camera),
            screensharePublication: participant.getTrack(Track.Source.ScreenShare),
          },
          source: publication.source,
        });
      },
    );
  }

  createContext() {
    this.closeContext();

    return new AudioContext(USE_JITSI_MEET_AUDIO_CONSTRAINTS ? {} : { sampleRate: MEDIASOUP_AUDIO_SAMPLE_RATE });
  }

  closeContext() {
    this._rnnoiseProcessor?.disconnect();
    this._rnnoiseProcessor = null;

    if (this.audioContext && this.audioContext.state !== "closed") {
      this.audioContext.close();
    }
  }

  async wireUpAudioContext() {
    if (this._stream) {
      this._stream.getTracks().forEach(track => track.stop());
    }

    if (this._stream_cloned) {
      this._stream_cloned.getTracks().forEach(track => track.stop());
    }

    this.closeContext();

    const currentDevice = getDeviceSettings(store.getState());
    const isMicDeviceBluetooth = currentDevice.audioinput.label.includes("Bluetooth");
    const autoGainStatus = getCurrentAutoGainStatus(store.getState());
    const enableAutoGainStatus = autoGainStatus || !isMicDeviceBluetooth;

    this._stream = await this.getAudioStream(
      currentDevice.audioinput.id !== "" ? currentDevice.audioinput.id : "default",
      USE_JITSI_MEET_AUDIO_CONSTRAINTS ? undefined : MEDIASOUP_AUDIO_SAMPLE_RATE,
      enableAutoGainStatus,
    );

    if (currentDevice.audioinput.id === "" && this._stream.id) {
      // when audio input permission is granted for the first time
      store.dispatch(getDevicesList() as any);

      const track = this._stream.getAudioTracks()[0];
      const currentDeviceId = track.getSettings().deviceId;

      if (currentDeviceId) {
        store.dispatch(
          updateDeviceSetting({
            audioinput: {
              id: currentDeviceId,
              label: "",
              groupId: "",
            },
          }) as any,
        );
        store.dispatch(
          updateDeviceSetting({
            audiooutput: {
              id: currentDeviceId,
              label: "",
              groupId: "",
            },
          }) as any,
        );
      }
    }

    this.audioContext = this.createContext();
    this.audioSource = this.audioContext.createMediaStreamSource(this._stream);
    this._stream_cloned = this._stream.clone();
    this.audioSourceNoiseReduction = this.audioContext.createMediaStreamSource(this._stream_cloned);
    this.peerNoiseReduction = this.audioContext.createMediaStreamDestination();
    log(LogCategory.NoiseReduction, "Noise cancellation status", this._noiseReduction);
  }

  async checkIfAudioContextAlive() {
    if (this.audioContext.state === "closed") {
      await this.wireUpAudioContext();
    }
  }

  async fetchStream() {
    let normalTrack: MediaStreamTrack;
    let noiseReductionTrack: MediaStreamTrack | undefined = undefined;

    await this.wireUpAudioContext();
    await this.checkIfAudioContextAlive();

    if (useNoiseReductionConfig) {
      log(LogCategory.Livekit, "With noise cancellation");
      const rnnoiseProcessor = await connectNoiseSuppressorNode(this.audioContext, this.audioSource);

      if (rnnoiseProcessor) {
        rnnoiseProcessor.connect(this.peerNoiseReduction);
        noiseReductionTrack = this.peerNoiseReduction.stream.getAudioTracks()[0];

        if (this._noiseReduction) {
          return {
            noiseReductionTrack,
          };
        }
      }
    }

    log(LogCategory.Livekit, "Without noise cancellation");
    normalTrack = this._stream?.getAudioTracks()[0]
      ? this._stream?.getAudioTracks()[0]
      : this.peer.stream.getAudioTracks()[0];

    return {
      normalTrack,
      noiseReductionTrack,
    };
  }

  checkIsOldParticipant(metadata: string, identity: string) {
    const userInfo = getUserInfoFromParticipantId(identity);
    const remoteParticipantUserInfo = getUserInfoFromParticipantId(this.remoteParticipants.get(metadata)?.identity);

    if (remoteParticipantUserInfo.userId) {
      return parseInt(remoteParticipantUserInfo.timestamp) < parseInt(userInfo.timestamp!);
    }

    return false;
  }

  public async publishEgressAudioTrack(noiseReduction?: boolean, mute?: boolean) {
    logE("publishEgressAudioTrack mute:", mute);

    if (noiseReduction !== undefined) {
      logE("noise reduction track...");
      this._noiseReduction = noiseReduction;
    }

    if (this.room && this.room.state === ConnectionState.Connected && !this.isUpdatingMicDevice) {
      this.isUpdatingMicDevice = true;
      this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
        loading: true,
      });

      let track: MediaStreamTrack | undefined;

      try {
        const me = getMyMember(store.getState());
        const isMute = mute === undefined ? me?.mute : mute;
        const extractedStream = await getExtractedAudioStream(
          USE_JITSI_MEET_AUDIO_CONSTRAINTS ? undefined : MEDIASOUP_AUDIO_SAMPLE_RATE,
        );

        if (this._extracted_stream) {
          this._extracted_stream.getTracks().forEach(track => track.stop());
        }

        this._extracted_stream = extractedStream;

        if (isMute) {
          track = extractedStream ? extractedStream.getAudioTracks()[0] : undefined;
        } else {
          const { normalTrack, noiseReductionTrack } = await this.fetchStream();

          if (noiseReductionTrack) {
            store.dispatch(updateLocalAudioStream(noiseReductionTrack) as any);
          } else {
            store.dispatch(updateLocalAudioStream(normalTrack) as any);
          }

          const userAudioStream = noiseReductionTrack ? this.peerNoiseReduction.stream : this._stream;

          if (extractedStream && (normalTrack || noiseReductionTrack)) {
            const source1 = this.audioContext.createMediaStreamSource(extractedStream);
            const source2 = this.audioContext.createMediaStreamSource(userAudioStream);

            const destination = this.audioContext.createMediaStreamDestination();

            source1.connect(destination);
            source2.connect(destination);

            track = destination.stream.getAudioTracks()[0];
          } else if (extractedStream) {
            track = extractedStream.getAudioTracks()[0];
          } else {
            track = noiseReductionTrack ? noiseReductionTrack : normalTrack;
          }
        }

        if (track) {
          if (this.participant?.getTrack(Track.Source.Microphone)) {
            await this.participant?.getTrack(Track.Source.Microphone)?.audioTrack?.replaceTrack(track, true);
          } else {
            await this.participant?.publishTrack(track, {
              audioBitrate: AUDIO_BIT_RATE,
              dtx: false,
              source: Track.Source.Microphone,
              stopMicTrackOnMute: true,
            });
          }
        }

        setTimeout(() => {
          this.isUpdatingMicDevice = false;
          this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
            loading: false,
          });
        }, 350);
      } catch (error) {
        logError("Error publishEgressAudioTrack", error);
        this._stream?.getTracks().forEach(t => t.stop());
        this._stream_cloned?.getTracks().forEach(t => t.stop());
        this._extracted_stream?.getTracks().forEach(t => t.stop());
        this.closeContext();

        this.isUpdatingMicDevice = false;
        this.onMessage(LivekitActions.LIVEKIT_MIC_LOADING, {
          loading: false,
        });
      }
    }
  }

  public async publishEgressScreenshareTrack(electronSourceId: string) {
    logE("publishEgressScreenshareTrack");

    if (this.room && this.room.state === ConnectionState.Connected && !this.isUpdatingScreenshare) {
      this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
        loading: true,
      });
      this.isUpdatingScreenshare = true;

      const isDrawingAllowed = false; //Disable drawing by default;
      let track;

      try {
        let stream: MediaStream;

        if (electronSourceId) {
          // Example from https://www.electronjs.org/docs/api/desktop-capturer
          stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              // @ts-expect-error
              mandatory: {
                chromeMediaSource: "desktop",
                chromeMediaSourceId: electronSourceId,
                frameRate: EGRESS_SCREEN_FRAME_RATE,
                // minWidth: 1280,
                // maxWidth: 1280,
                // minHeight: 720,
                // maxHeight: 720,
              },
            },
          });
        } else {
          stream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
              width: window.screen.width,
              height: window.screen.height,
              frameRate: EGRESS_SCREEN_FRAME_RATE,
            },
          });
        }

        // May mean cancelled (in some implementations).
        if (!stream) {
          store.dispatch(cancelRecordAudioExtraction() as any);
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
          return;
        }

        track = stream.getVideoTracks()[0];

        if (track) {
          if (this.participant?.getTrack(Track.Source.ScreenShare)) {
            await this.participant?.getTrack(Track.Source.ScreenShare)?.videoTrack?.replaceTrack(track, true);

            const me = getMyMember(store.getState());

            if (me) {
              const screnshare = getScreenshare(store.getState());

              store.dispatch(
                updateUserScreenActiveSend({
                  workspaceId: me.workspaceId,
                  userId: me.id,
                  screenActive: true,
                  screenIndex: screnshare.mySharedScreen?.screenIndex,
                  isDrawingAllowed: false, //Default drawing
                }) as any,
              );
              store.dispatch(showStopSharingWindow() as any);
            }
          } else {
            await this.participant?.publishTrack(track, {
              source: Track.Source.ScreenShare,
              name: `${electronSourceId}:${isDrawingAllowed}:${dayjs().unix()}`,
              simulcast: false,
              videoCodec: "h264",
            });
          }
        }

        setTimeout(() => {
          this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
            loading: false,
          });
          this.isUpdatingScreenshare = false;
        }, 350);
      } catch (error) {
        store.dispatch(cancelRecordAudioExtraction() as any);
        logError("enableShare() | failed", error);
        this.onMessage(LivekitActions.LIVEKIT_SCREENSHARE_LOADING, {
          loading: false,
        });
        this.isUpdatingScreenshare = false;
        if (track) track.stop();
      }
    }
  }

  public async autoRepublishEgressScreenshareTrack() {
    try {
      for (let i = 1; i <= EGRESS_SCREEN_SHARE_RETRY; i++) {
        logE("Republishing ScreenShare Track: ", i);

        await delay(2000);

        const screnshare = getScreenshare(store.getState());
        const sharingOptionsList = await ipc.getScreenWindowSharingOptions();
        const sharingOptions = sharingOptionsList.find(sl => sl.name === screnshare.mySharedScreen.name);

        if (sharingOptions) {
          await this.publishEgressScreenshareTrack(sharingOptions.id);
          return true;
        }
      }

      store.dispatch(sendScreenConnectionAlert() as any);

      return false;
    } catch (e) {
      console.log(e);

      return false;
    }
  }
}
