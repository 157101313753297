import { showNotification, hideNotification } from "./actions";

const NOTIFICATION_EVENT_TYPE_PREFIX = "notification/";

export const NOTIFICATION_EVENT_TYPES = {
  SHOW_NOTIFICATION: `${NOTIFICATION_EVENT_TYPE_PREFIX}show`,
  HIDE_NOTIFICATION: `${NOTIFICATION_EVENT_TYPE_PREFIX}hide`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(NOTIFICATION_EVENT_TYPE_PREFIX)) {
    return;
  }

  switch (type) {
    case NOTIFICATION_EVENT_TYPES.SHOW_NOTIFICATION:
      store.dispatch(showNotification(payload.content));
      break;
    case NOTIFICATION_EVENT_TYPES.HIDE_NOTIFICATION:
      store.dispatch(hideNotification(payload.id));
      break;
  }
};
