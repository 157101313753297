import { createSelector } from "reselect";

import { State } from "../state";
import { SocketState } from "./reducer";

function getSocketState(state: State) {
  return state.socket;
}

export const getSocket = createSelector(getSocketState, (socket: SocketState) => {
  return socket;
});

export const getSocketConnectivityStatus = createSelector(getSocketState, (socket: SocketState) => {
  return socket.connected;
});
