import { createSelector } from "reselect";
import { getMyMember } from "../users/selectors";
import type { State } from "../state";
import type { WorkspaceState } from "./reducer";
import { getLanguage } from "../account/selectors";
import { USER_RADIUS } from "../../constant";
import { getAvailableWorkspaceLanguages } from "../../utils/helpers";

export function getWorkspacesState(state: State) {
  return state.workspaces;
}

export const checkIsWorkspaceJoining = createSelector(
  getWorkspacesState,
  (workspaceState: WorkspaceState) => workspaceState.joining,
);

export const getAllWorkspaces = createSelector(getWorkspacesState, (workspaceState: WorkspaceState) => {
  return workspaceState.workspaces;
});

export const getCurrentWorkspace = createSelector(
  getMyMember,
  getAllWorkspaces,
  (me, workspacesList) => workspacesList.find(c => c.id === me?.workspaceId) || null,
);

export const getCurrentWorkspaceByWorkspaceId = (workspaceId: number) =>
  createSelector(getAllWorkspaces, workspaces => workspaces.find(w => w.id === workspaceId) || null);

export const getInviteeListByWorkspaceId = (workspaceId: number) =>
  createSelector(getCurrentWorkspaceByWorkspaceId(workspaceId), workspace => {
    if (workspace) {
      return workspace.invitees;
    } else {
      return [];
    }
  });

export const getCurrentWorkspaceId = createSelector(getCurrentWorkspace, workspace => workspace?.id);

export const getCurrentWorkspacePlan = createSelector(getCurrentWorkspace, workspace => workspace?.plan);

export const getCurrentWorkspacePlanName = createSelector(
  getLanguage,
  getCurrentWorkspacePlan,
  (language, plan) => plan?.name[language],
);

export const getCurrentWorkspaceEnPlanName = createSelector(getCurrentWorkspacePlan, plan => plan?.name["en"]);

export const isCurrentWorkspacePlanUpgradable = createSelector(getCurrentWorkspacePlan, plan => plan?.upgradable);

export const isCurrentWorkspaceFreePlan = createSelector(getCurrentWorkspacePlan, plan => plan?.name["en"] === "Free");

export const isCurrentWorkspaceSmallPlan = createSelector(
  getCurrentWorkspacePlan,
  plan => plan?.name["en"] === "Small",
);

export const isCurrentWorkspacePremiumPlan = createSelector(
  getCurrentWorkspacePlan,
  plan => plan?.name["en"] === "Premium",
);

export const isCurrentWorkspaceEnterprisePlan = createSelector(
  getCurrentWorkspacePlan,
  plan => plan?.name["en"] === "Enterprise",
);

export const getMyWorkspacesCount = createSelector(getMyMember, getAllWorkspaces, (me, workspaces) => {
  const allMyWorkspaces = workspaces.filter(w => w.createdById === me?.id);

  return allMyWorkspaces.length;
});

export const getSpeechToTextCurrentUsage = createSelector(getCurrentWorkspace, workspace =>
  workspace
    ? {
        current: workspace.speechToTextCurrentUsage,
        total: workspace.plan.maxSpeechToTextMinutes,
      }
    : undefined,
);

export const getSttLimitReached = createSelector(getSpeechToTextCurrentUsage, currentUsage => {
  if (currentUsage && currentUsage.total === null) {
    return false;
  } else {
    return currentUsage && currentUsage.current >= currentUsage.total!;
  }
});

export const isIceTransportPolicyEnabled = createSelector(
  getCurrentWorkspace,
  workspace => workspace?.iceTransportPolicy,
);

export const isTextChatSavingEnabled = createSelector(
  getCurrentWorkspace,
  workspace => workspace?.enableTextChatSaving,
);

export const getCurrentWorkspaceLanguages = createSelector(getCurrentWorkspace, workspace =>
  getAvailableWorkspaceLanguages(workspace?.workspaceLanguages),
);

export const getWorkspaceAudibleRange = createSelector(getCurrentWorkspace, currentWorkspace =>
  currentWorkspace?.audibleRange ? currentWorkspace?.audibleRange * USER_RADIUS : USER_RADIUS,
);

export const getWorkspaceWebcamSize = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.userVideoCircleSize ?? 1,
);

export const getWorkspaceBroadcasterWebcamSize = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.broadcasterVideoCircleSize ?? 2,
);

export const getWorkspaceUserAvatarSize = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.userAvatarCircleSize ?? 1,
);

export const getWorkspaceBalloonTextSize = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.balloonFontSize ?? 1,
);

export const getWorkspaceDisplayNameTextSize = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.displayNameFontSize ?? 1,
);

export const getWorkspacePhrases = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.workspacePhrases,
);

export const getWorkspacePhrasesByLanguage = (language: string | 0 | null | undefined) =>
  createSelector(
    getCurrentWorkspace,
    currentWorkspace =>
      language && currentWorkspace?.workspacePhrases?.find(el => el.language === language.substring(0, 2)),
  );

export const getWorkspacePhrasesByWorkspaceId = (workspaceId: number) =>
  createSelector(getCurrentWorkspaceByWorkspaceId(workspaceId), workspace => {
    return workspace?.workspacePhrases;
  });

export const getWorkspaceEnableIndirectTranslation = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.enableIndirectTranslation,
);

export const getWorkspaceScreenshareVisibility = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.screenshareVisibility,
);

export const getWorkspaceInvitees = createSelector(getCurrentWorkspace, currentworkspace => {
  return currentworkspace?.invitees;
});

export const getLivekitUrl = createSelector(getCurrentWorkspace, currentWorkspace => currentWorkspace?.livekitUrl);

export const getWorkspaceById = (workspaceId: number) =>
  createSelector(getAllWorkspaces, workspaces => workspaces.find(w => w.id === workspaceId));

export const isStripeCustomer = createSelector(
  getCurrentWorkspace,
  isCurrentWorkspaceFreePlan,
  (currentWorkspace, isFreePlan) => currentWorkspace?.stripeCustomerId || isFreePlan,
);

export const isEmailTokenFlagEnabled = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.enableEmailTokenFlag ?? false,
);

export const getWorkspaceTranslationFrequency = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.translationFrequency,
);
