import { Action, Dispatch } from "redux";
import { history, WORKSPACE_BASE_ROUTE, WORKSPACE_CREATE_ROUTE } from "../../routes";
import {
  DEFAULT_TRANSLATION_FREQUENCY,
  HIGH_TRANSLATION_FREQUENCY,
  LOGOUT_PATH,
  LOW_TRANSLATION_FREQUENCY,
  STT_GET_MINUTES_SPENT,
} from "../../constant";
import logError from "../../utils/logError";
import { getWorkspaceChannelParams } from "../../utils/parserUrl";
import { State } from "../../store/state";
import {
  logout,
  getAccountState,
  getAuthenticatedAccount,
  setUserWorkspaceOnSentry,
  fetchAccountProjects,
  getUserProjects,
  getIsMeetingRecording,
  getLanguage,
  fetchAccountTimeTrackerLimit,
  getSttEndpoints,
  setAccountIntegrationData,
  checkIsListener,
  InvitedEnv,
  setInvitedEnv,
} from "../../store/account";
import { connectSocket, sendMessage } from "../socket";
import {
  setChannels,
  channelJoin,
  getAllVoiceChannels,
  updateVoiceChannelSttLanguage,
  getVoiceChannelById,
  channelJoinAction,
} from "../voiceChannels";
import {
  addUser,
  addListener,
  removeUser,
  getMyMember,
  updateUserWorkspace,
  setNativeNotification,
  getWorkspaceUsers,
  updateUserSTTSettings,
  setWorkspaceUsers,
  updateUserOnline,
  setWorkspaceListeners,
  getWorkspaceListeners,
  updateListener,
} from "../users";
import {
  AppThunk,
  Workspace,
  MemberData,
  ChannelData,
  WorkspacePhrase,
  LanguageOption,
  PlanDetails,
  ListenerData,
  TranslationDictionary,
} from "../types";
import { WORKSPACE_EVENT_TYPES } from "./middleware";
import { getAllWorkspaces, getCurrentWorkspace, getCurrentWorkspaceByWorkspaceId, getWorkspaceById } from "./selectors";
import { convertLanguages, getActualRedirectURL, getWorkspaceDashboardUrl } from "../../utils/helpers";
import { setAccountWorkspace } from "../account";
import { createRequest, postJson } from "../../utils/network";
import { checkActiveWindow } from "../backgroundChecks/activeApp/actions";
import { stopWorkingRequest, updateDescription } from "../timeTracker/actions";
import { doStopScreenShare } from "../screenshare";
import { notification } from "antd";
import { Toast } from "../../components/antd/Toast";
import { intl } from "../../i18n";
import {
  hydrateVBG,
  hydrateWorkspacePhraseLanguage,
  reloadApp,
  setDuplicatedLogin,
  setWorkspaceUpdating,
} from "../app";
import { NotificationCategory, notifyOnElectron } from "../../electron/notification";
import { getAllWorkspaceBackgrounds } from "../workspaceBackground";
import { InviteeData } from "../../api/workspace";
import { getWorkingState } from "../timeTracker/selectors";
import localData, { PrevLocationLocalStorageClient } from "../../localStorageKeys";
import { updateMyPrevLocation } from "../virtualOffice/actions";

export enum WorkspaceActions {
  NEW_WORKSPACE = "workspace/NEW_WORKSPACE",

  GOT_WORKSPACES = "workspace/GOT_WORKSPACES",

  DELETE_WORKSPACE = "workspace/DELETE_WORKSPACE",

  SWITCH_WORKSPACE = "workspace/SWITCH_WORKSPACE",
  SWITCHED_WORKSPACE = "workspace/SWITCHED_WORKSPACE",

  SET_WORKSPACE_LANGUAGES = "workspace/SET_WORKSPACE_LANGUAGES",

  WORKSPACE_UPDATED = "workspace/WORKSPACE_UPDATED",

  UPDATE_SPEECH_TO_TEXT_MINUTES = "workspace/UPDATE_SPEECH_TO_TEXT_MINUTES",

  LEAVE_WORKSPACE = "workspace/LEAVE_WORKSPACE",
  LEFT_WORKSPACE = "workspace/LEFT_WORKSPACE",

  UPDATE_WORKSPACE_LOGO_URL = "workspace/UPDATE_WORKSPACE_LOGO_URL",

  UPDATE_WORKSPACE_USER_CHATWORK_GROUP = "workspace/UPDATE_WORKSPACE_USER_CHATWORK_GROUP",
  UPDATE_MEETING_RECORDING_COUNT = "workspace/UPDATE_MEETING_RECORDING_COUNT",

  UPDATE_LANGUAGE_OPTIONS = "workspace/UPDATE_LANGUAGE_OPTIONS",
  UPDATE_WORKSPACE_PHRASES = "workspace/UPDATE_WORKSPACE_PHRASES",
  SET_WORKSPACE_PHRASES = "workspace/SET_WORKSPACE_PHRASES",

  UPDATE_ENABLE_CONSECUTIVE_TRANSLATION = "workspace/UPDATE_ENABLE_CONSECUTIVE_TRANSLATION",

  UPDATE_PLAN_DETAILS = "workspace/UPDATE_PLAN_DETAILS",
  UPDATE_MEMBER_ENABLE_WEB_APP = "workspace/UPDATE_MEMBER_ENABLE_WEB_APP",
  SET_WORKSPACE_INVITEES = "workspace/SET_INVITEES",
  UPDATE_WORKSPACE_INVITEE = "workspace/UPDATE_INVITEE",
  UPDATE_WORKSPACE_HAS_MOBILE_APP = "workspace/UPDATE_WORKSPACE_HAS_MOBILE_APP",
  UPDATE_WORKSPACE_TRANSLATION_FREQUENCY = "workspace/UPDATE_WORKSPACE_TRANSLATION_FREQUENCY",

  UPDATE_VP_STT_SERVER = "workspace/UPDATE_VP_STT_SERVER",
}

export interface GotWorkspaces {
  readonly workspaces: Workspace[];
}

interface GotWorkspacesAction extends Action {
  readonly type: WorkspaceActions.GOT_WORKSPACES;
  readonly payload: GotWorkspaces;
}

export interface SwitchWorkspace {
  readonly workspaceId: number;
}

export interface WorkspaceUserSttSetting {
  userId: number;
  workspaceId: number;
  speechToText: { displayLanguage: string | null; speakingLanguage: string | null };
}

export interface VoiceChannelSttSetting {
  id: number;
  workspaceId: number;
  speechToTextLanguage: string;
}

interface SwitchWorkspaceAction extends Action {
  readonly type: WorkspaceActions.SWITCH_WORKSPACE;
  readonly payload: SwitchWorkspace;
}

export interface SwitchedWorkspace {
  readonly workspaceId: number;
}

interface SwitchedWorkspaceAction extends Action {
  readonly type: WorkspaceActions.SWITCHED_WORKSPACE;
}

export interface UpdateStatus {
  readonly workspaceId: number;
  readonly userId: number;
  readonly status: string;
  readonly online: boolean;
}

export interface UpdateWorkspaceLocation {
  readonly workspaceId: number;
  readonly regionId: number;
}

export interface LeaveWorkspace {
  workspaceId: number;
  userId: number;
}

interface LeaveWorkspaceAction extends Action {
  readonly type: WorkspaceActions.LEAVE_WORKSPACE;
  readonly payload: LeaveWorkspace;
}

interface LeftWorkspaceAction extends Action {
  readonly type: WorkspaceActions.LEFT_WORKSPACE;
  readonly payload: LeaveWorkspace;
}

export interface UpdateWorkspaceLogo {
  readonly workspaceId: number;
  readonly logoUrl: string;
}

export interface UpdateAvatarAction extends Action {
  type: WorkspaceActions.UPDATE_WORKSPACE_LOGO_URL;
  payload: UpdateWorkspaceLogo;
}

export interface UpdateIceTransportPolicy {
  workspaceId: number;
  iceTransportPolicy: boolean;
}

export interface UpdateLanguageOptions {
  workspaceId: number;
  languageOptions: string[];
}

export interface UpdatedLanguageOptions {
  workspaceId: number;
  languageOptions: string;
}

export interface UpdateEnableConsecutiveTranslationAction {
  type: WorkspaceActions.UPDATE_ENABLE_CONSECUTIVE_TRANSLATION;
  payload: { isConsecutiveTranslation: boolean; workspaceId: number };
}

export interface UpdatePlanDetailsAction extends Action {
  type: WorkspaceActions.UPDATE_PLAN_DETAILS;
  payload: { planDetails: PlanDetails; workspaceId: number; stripeCustomerId: string | null };
}

export interface SetworkspaceInviteesAction extends Action {
  type: WorkspaceActions.SET_WORKSPACE_INVITEES;
  payload: { workspaceId: number; invitees: InviteeData[] };
}

export interface UpdateWorkspaceInviteeAction extends Action {
  type: WorkspaceActions.UPDATE_WORKSPACE_INVITEE;
  payload: { workspaceId: number; invitee: InviteeData };
}

export interface UpdateWorkspaceHasMobileAppAction extends Action {
  type: WorkspaceActions.UPDATE_WORKSPACE_HAS_MOBILE_APP;
  payload: { workspaceId: number; userId: number; hasMobileApp: boolean };
}

export type WorkspaceActionTypes =
  | ReturnType<typeof newWorkspacesAction>
  | ReturnType<typeof updateSpeechToTextMinutesAction>
  | ReturnType<typeof deletedWorkspaceAction>
  | GotWorkspacesAction
  | SwitchWorkspaceAction
  | SwitchedWorkspaceAction
  | ReturnType<typeof workspaceUpdatedAction>
  | LeaveWorkspaceAction
  | LeftWorkspaceAction
  | UpdateAvatarAction
  | ReturnType<typeof updateWorkspaceMeetingRecordingCountAction>
  | ReturnType<typeof updatedLanguageOptionsAction>
  | ReturnType<typeof updateWorkspacePhrasesAction>
  | ReturnType<typeof setWorkspacePhrasesAction>
  | ReturnType<typeof setCurrentWorkspceLanguagesAction>
  | UpdateEnableConsecutiveTranslationAction
  | UpdatePlanDetailsAction
  | SetworkspaceInviteesAction
  | UpdateWorkspaceInviteeAction
  | UpdateWorkspaceHasMobileAppAction
  | ReturnType<typeof updatedWorkspaceVpSttServer>
  | ReturnType<typeof updatedWorkspaceTranslationFrequency>;
// ACTION CREATORS

function newWorkspacesAction(workspace: Workspace) {
  return { type: WorkspaceActions.NEW_WORKSPACE, payload: { workspace } } as const;
}

export function newWorkspace(workspace: Workspace): AppThunk {
  return (dispatch: Function) => {
    dispatch(newWorkspacesAction(workspace));
  };
}

function gotWorkspaceAction(payload: GotWorkspaces): GotWorkspacesAction {
  return { type: WorkspaceActions.GOT_WORKSPACES, payload };
}

export function logoutIpValidation(payload: any): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    alert(
      intl.formatMessage(
        {
          id: "ip-restriction/invalid-workspace-error",
          defaultMessage: "Your IP address {ip} is not allowed",
        },
        {
          ip: payload.requestIp,
        },
      ),
    );

    const { response } = await createRequest(LOGOUT_PATH, { method: "DELETE" });

    if (response.success) {
      dispatch(logout());
    }
  };
}

export function allowdedWorkspace(payload: any): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    alert(
      intl.formatMessage(
        {
          id: "ip-restriction/invalid-workspace-error",
          defaultMessage: "Your IP address {ip} is not allowed",
        },
        {
          ip: payload.requestIp,
        },
      ),
    );

    dispatch(switchWorkspace(payload.firstAvailableWorkspaceId));
  };
}

export function gotWorkspaces(workspaces: Workspace[]): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const account = getAccountState(getState());

    dispatch(gotWorkspaceAction({ workspaces }));

    if (account.user.id && account.user.authenticated && !account.user.isGuest && workspaces.length === 0) {
      //user has no workspaces
      history.push(WORKSPACE_CREATE_ROUTE);
    }
  };
}

export function initialWorkspace(
  initialAccessWorksapceShortId?: string,
  initialAccessChannelShortId?: string,
): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const { user } = getAccountState(getState());
    const path = await getActualRedirectURL();

    if (!user.workspaces) return;

    const workspaces = user.workspaces;

    dispatch(gotWorkspaces(user.workspaces));

    // Not a workspace route, abort initialisation
    if (!path.startsWith(WORKSPACE_BASE_ROUTE)) {
      return;
    }

    let workspace: "" | Workspace | undefined | null;

    if (initialAccessWorksapceShortId) {
      workspace = workspaces?.find(w => w.shortId === initialAccessWorksapceShortId);
      if (!workspace && !user.isGuest) {
        Toast.error(
          intl.formatMessage({
            id: "workspace/link-for-unaffiliated-workspace",
            defaultMessage:
              "You backed to the workspace you already joined. You are not member of the workspace you tried to join. Please signout and join as guest, or get invitation from that workspace.",
          }),
        );
        const { workspaceShortId } = getWorkspaceChannelParams(path);

        workspace = workspaceShortId && workspaces?.find(w => w.shortId === workspaceShortId);
      }
    } else {
      const { workspaceShortId } = getWorkspaceChannelParams(path);

      workspace = workspaceShortId && workspaces?.find(w => w.shortId === workspaceShortId);
    }

    // User requested /:workspace url and is allowed to access it
    if (workspace) {
      dispatch(setAccountWorkspace(workspace.id));
      if (initialAccessChannelShortId) {
        dispatch(switchWorkspace(workspace.id, initialAccessChannelShortId));
      } else {
        dispatch(switchWorkspace(workspace.id));
      }

      return;
    }

    // User requested /:workspace url but does not have member access,
    // show warning prompt and switching into users last known workspace
    if (user.workspaceId && workspaces.some(el => el.id === user.workspaceId)) {
      dispatch(setAccountWorkspace(user.workspaceId));
      dispatch(switchWorkspace(user.workspaceId));
      return;
    }

    if (workspaces && workspaces.length > 0) {
      dispatch(setAccountWorkspace(workspaces[0].id));
      dispatch(switchWorkspace(workspaces[0].id));
    }
  };
}

function switchWorkspaceAction(payload: SwitchWorkspace): SwitchWorkspaceAction {
  return {
    type: WorkspaceActions.SWITCH_WORKSPACE,
    payload,
  };
}

export function joinVoiceChannelFromURL(workspaceId: number): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const path = await getActualRedirectURL();
    const { voiceChannelShortId } = getWorkspaceChannelParams(path);
    const voiceChannels = getAllVoiceChannels(getState());
    const voiceChannel = voiceChannelShortId && voiceChannels.find(c => c.shortId === voiceChannelShortId);

    if (voiceChannelShortId && !voiceChannel) {
      history.push(WORKSPACE_BASE_ROUTE);
      return;
    }

    const me = getMyMember(getState());

    if (!me?.online) {
      dispatch(setDuplicatedLogin(false));
      dispatch(connectSocket());
      return;
    }

    if (voiceChannel) {
      dispatch(channelJoin({ workspaceId, voiceChannelId: voiceChannel.id }));
    }
  };
}

export function workspaceChangesBasedOnURL(workspaceShortId: string, voiceChannelShortId?: string | null): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const me = getMyMember(getState());
    const workspaces = getAllWorkspaces(getState());
    const workspaceId = workspaces.find(w => w.shortId === workspaceShortId)?.id ?? null;

    if (workspaceId) {
      if (me?.workspaceId === workspaceId) {
        dispatch(joinVoiceChannelFromURL(workspaceId));
      } else if (workspaceId) {
        dispatch(switchWorkspace(workspaceId, voiceChannelShortId));
      }
    } else {
      logError(
        `Invalid input URL data found ${workspaceShortId} workspaceid: ${workspaceId} voiceChannelId: ${voiceChannelShortId}`,
      );
    }
  };
}

export function switchWorkspace(workspaceId: number, voiceChannelShortId?: string | null): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const { user } = getAccountState(getState());
    const workspace = getWorkspaceById(workspaceId)(getState());
    const isWorking = getWorkingState(getState());
    const { workspaceShortId: currentWorkspaceShortId } = getWorkspaceChannelParams();
    const nextWorkspaceShortId = workspace?.shortId ?? currentWorkspaceShortId;

    dispatch(hydrateVBG());
    notification.close("reconnect");
    isWorking && dispatch(stopWorkingRequest("Stop time tracker before switch workspace"));

    if (workspaceId && nextWorkspaceShortId) {
      const timezoneOffset = new Date().getTimezoneOffset();
      const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

      dispatch(switchWorkspaceAction({ workspaceId }));
      dispatch(
        sendMessage(WORKSPACE_EVENT_TYPES.OUT_WORKSPACE_INITIAL_LOAD_REQUEST, {
          workspaceShortId: nextWorkspaceShortId,
          timezoneOffset,
          timezoneName,
        }),
      );
    }

    if (
      !user.isListener &&
      !user.isMeetingRecording &&
      nextWorkspaceShortId &&
      nextWorkspaceShortId !== currentWorkspaceShortId
    ) {
      dispatch(doStopScreenShare());
      const nextDashboardURL = getWorkspaceDashboardUrl(nextWorkspaceShortId);
      const nextWorkspaceURL = voiceChannelShortId ? nextDashboardURL + "/" + voiceChannelShortId : nextDashboardURL;

      history.push(nextWorkspaceURL);
    }
  };
}

export function switchedWorkspaceAction(): SwitchedWorkspaceAction {
  return {
    type: WorkspaceActions.SWITCHED_WORKSPACE,
  };
}

export function setCurrentWorkspceLanguagesAction(workspaceId: number, workspaceLanguages: LanguageOption[]) {
  return {
    type: WorkspaceActions.SET_WORKSPACE_LANGUAGES,
    payload: { workspaceId, workspaceLanguages },
  } as const;
}

export function setCurrentWorkspaceLanguages(
  workspaceId: number,
  planName?: string,
  languageOptions?: string | null,
): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    let languages = languageOptions ? convertLanguages(languageOptions.split(","), planName) : [];
    const sttEndPoints = getSttEndpoints(getState());

    if (sttEndPoints) {
      languages = languages.map(el => {
        const sttEndpointsByCode = sttEndPoints.filter(st => st.language === el.code);

        return {
          ...el,
          sttVariations: [
            ...el.sttVariations,
            ...sttEndpointsByCode.map(st => ({
              custom: true,
              code: st.code,
              name: {
                origin: el.code === "ja" ? st.jpDisplayName : st.enDisplayName,
                en: st.enDisplayName,
                ja: st.jpDisplayName,
                vi: st.viDisplayName,
                ko: st.koDisplayName,
              },
            })),
          ],
        };
      });
    }

    dispatch(setCurrentWorkspceLanguagesAction(workspaceId, languages));
  };
}

export function switchedWorkspace(
  workspaceId: number,
  workspaceMembersData: MemberData[],
  workspaceListeners: ListenerData[],
  voiceChannels: ChannelData[],
  workspacePhrases?: WorkspacePhrase[],
  message?: string,
  invitedEnv?: InvitedEnv,
): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const account = getAuthenticatedAccount(getState());
    const isMeetingRecording = getIsMeetingRecording(getState());
    const language = getLanguage(getState());

    if (message) {
      Toast.error(
        intl.formatMessage({
          id: "user-status/outlook-automatically-disconnection",
          defaultMessage: "failed to refresh token and disconnected from Outlook. please integrate again if you want.",
        }),
      );
    }

    const currentUser = workspaceMembersData.find(a => a.id === account.id);
    const currentUserIntegrationData = currentUser
      ? {
          connectedAzure: currentUser.connectedAzure!,
          connectedChatwork: currentUser.connectedChatwork!,
          connectedGoogle: currentUser.connectedGoogle!,
        }
      : null;

    dispatch(setAccountWorkspace(workspaceId));
    currentUserIntegrationData && dispatch(setAccountIntegrationData(currentUserIntegrationData));
    dispatch(setChannels(voiceChannels));
    dispatch(channelJoinAction(false));
    dispatch(setWorkspaceUsers(workspaceMembersData));
    dispatch(setWorkspaceListeners(workspaceListeners));
    dispatch(hydrateWorkspacePhraseLanguage(workspaceId));
    dispatch(fetchAccountProjects());
    dispatch(fetchAccountTimeTrackerLimit());
    dispatch(setNativeNotification());
    dispatch(getAllWorkspaceBackgrounds(workspaceId));
    dispatch(setInvitedEnv(invitedEnv));

    if (workspacePhrases) {
      dispatch(setWorkspacePhrasesAction(workspacePhrases));
    }

    const path = await getActualRedirectURL();
    const me = getMyMember(getState());
    const accountProject = getUserProjects(getState());
    const existenceOfProject = accountProject?.some(p => p.id === me?.project?.id);
    const assignedFloor = workspaceMembersData.find(el => el.id === account.id)?.assignedFloors ?? [];

    if (me && !isMeetingRecording) {
      dispatch(updateDescription(me.workingMemo));
    }

    const { voiceChannelShortId } = getWorkspaceChannelParams(path);

    const voiceChannel =
      assignedFloor && assignedFloor.length > 0
        ? (voiceChannelShortId && voiceChannels.find(c => c.shortId === voiceChannelShortId)) ||
          (me?.voiceChannelId && voiceChannels.find(c => c.id === me?.voiceChannelId)) ||
          voiceChannels.find(c => c.id === assignedFloor[0])
        : (voiceChannelShortId && voiceChannels.find(c => c.shortId === voiceChannelShortId)) ||
          (me?.voiceChannelId && voiceChannels.find(c => c.id === me?.voiceChannelId)) ||
          voiceChannels[0];

    if (!isMeetingRecording && voiceChannelShortId && !voiceChannel) {
      history.push(WORKSPACE_BASE_ROUTE);
      return;
    }

    const currentWorkspace = getCurrentWorkspace(getState());

    //hydrate speaking languages
    dispatch(
      setCurrentWorkspaceLanguages(
        workspaceId,
        currentWorkspace?.plan.name[language],
        currentWorkspace?.languageOptions,
      ),
    );

    //hydrate translation frequency
    if (currentWorkspace) {
      dispatch(confirmWorkspaceTranslationFrequency(currentWorkspace.translationFrequency, currentWorkspace.id));
    }

    if (!isMeetingRecording && me?.id && !account.isGuest && me.project?.id && existenceOfProject) {
      localData.set("timeTracker.stopped.userAway", JSON.stringify(false));
    }

    const prevLocationClient = new PrevLocationLocalStorageClient(workspaceId);
    const prevLocation = prevLocationClient.loadData();

    if (prevLocation) {
      dispatch(updateMyPrevLocation(prevLocation));
    } else {
      dispatch(updateMyPrevLocation(undefined));
    }

    if (voiceChannel) {
      dispatch(
        channelJoin({
          workspaceId,
          voiceChannelId: voiceChannel!.id,
        }),
      );
    }

    currentWorkspace && dispatch(setUserWorkspaceOnSentry(currentWorkspace!.id, currentWorkspace!.name));
  };
}

export interface UpdateWorkspaceDetailsPayload {
  workspaceId: number;
  workspaceName?: string;
  timeTrackerSlackChannelId?: string | null;
  chatworkGroupId?: number | null;
  idleDuration?: number;
  enableActiveApp?: boolean;
  enableTimeTracking?: boolean;
  enableTextChatSaving?: boolean;
  audibleRange?: number;
  userVideoCircleSize?: number;
  broadcasterVideoCircleSize?: number;
  enableIndirectTranslation?: boolean;
  screenshareVisibility?: boolean;
  userAvatarCircleSize?: number;
  balloonFontSize?: number;
  displayNameFontSize?: number;
  enableBusyStatusReset?: boolean;
  enableGuestWebApp?: boolean;
  enableSpeechToText?: boolean;
  speechToTextLanguage?: string;
  enableMicForMeeting?: boolean;
  enableWebCamForMeeting?: boolean;
  enableAutoMeetingRecording?: boolean;
  enableDeleteAllData?: boolean;
  enableRectangularView?: boolean;
  enableEmailTokenFlag?: boolean;
  transcriptSlackChannelId?: string | null;
  meetingApps?: string[] | null;
  transcriptChatworkGroupId?: number | null;
  transcriptDiscordUrl?: string | null;
  timerDiscordUrl?: string | null;
}

export function updateWorkspaceDetails(payload: UpdateWorkspaceDetailsPayload): AppThunk {
  return (dispatch: Dispatch) => {
    dispatch(sendMessage(WORKSPACE_EVENT_TYPES.UPDATE_WORKSPACE, payload));
  };
}

function workspaceUpdatedAction(payload: {
  workspaceId: number;
  workspaceName?: string;
  timeTrackerSlackChannelId?: string | null;
  chatworkGroupId?: number | null;
  idleDuration?: number;
  enableActiveApp?: boolean;
  enableTimeTracking?: boolean;
  enableWebApp?: boolean;
  enableTextChatSaving?: boolean;
  audibleRange?: number;
  userVideoCircleSize?: number;
  broadcasterVideoCircleSize?: number;
  enableIndirectTranslation?: boolean;
  screenshareVisibility?: boolean;
  userAvatarCircleSize?: number;
  balloonFontSize?: number;
  displayNameFontSize?: number;
  enableBusyStatusReset?: boolean;
  enableGuestWebApp?: boolean;
  enableSpeechToText?: boolean;
  speechToTextLanguage?: string;
  enableMicForMeeting?: boolean;
  enableWebCamForMeeting?: boolean;
  enableAutoMeetingRecording?: boolean;
  enableDeleteAllData?: boolean;
  enableRectangularView?: boolean;
  enableEmailTokenFlag?: boolean;
  transcriptSlackChannelId?: string | null;
  meetingApps?: string[] | null;
  transcriptChatworkGroupId?: number | null;
  transcriptDiscordUrl?: string | null;
  timerDiscordUrl?: string | null;
}) {
  return { type: WorkspaceActions.WORKSPACE_UPDATED, payload } as const;
}

export function workspaceUpdated(
  workspaceId: number,
  workspaceName: string | undefined,
  timeTrackerSlackChannelId: string | null | undefined,
  chatworkGroupId: number | null,
  idleDuration: number | undefined,
  enableActiveApp: boolean | undefined,
  enableTimeTracking: boolean | undefined,
  enableWebApp: boolean | undefined,
  enableTextChatSaving: boolean | undefined,
  audibleRange: number | undefined,
  userVideoCircleSize: number | undefined,
  broadcasterVideoCircleSize: number | undefined,
  enableIndirectTranslation: boolean | undefined,
  screenshareVisibility: boolean | undefined,
  userAvatarCircleSize: number | undefined,
  balloonFontSize: number | undefined,
  displayNameFontSize: number | undefined,
  enableBusyStatusReset: boolean | undefined,
  enableGuestWebApp: boolean | undefined,
  enableSpeechToText: boolean | undefined,
  speechToTextLanguage: string | undefined,
  enableMicForMeeting: boolean | undefined,
  enableWebCamForMeeting: boolean | undefined,
  enableAutoMeetingRecording: boolean | undefined,
  enableDeleteAllData: boolean | undefined,
  enableRectangularView: boolean | undefined,
  enableEmailTokenFlag: boolean | undefined,
  transcriptSlackChannelId: string | null | undefined,
  meetingApps: string[] | null | undefined,
  transcriptChatworkGroupId: number | null,
  transcriptDiscordUrl: string | null,
  timerDiscordUrl: string | null,
): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const currentWorkspace = getCurrentWorkspace(getState());

    dispatch(
      workspaceUpdatedAction({
        workspaceId,
        workspaceName,
        timeTrackerSlackChannelId,
        chatworkGroupId,
        idleDuration,
        enableActiveApp,
        enableTimeTracking,
        enableWebApp,
        enableTextChatSaving,
        audibleRange,
        userVideoCircleSize,
        broadcasterVideoCircleSize,
        enableIndirectTranslation,
        screenshareVisibility,
        userAvatarCircleSize,
        balloonFontSize,
        displayNameFontSize,
        enableBusyStatusReset,
        enableGuestWebApp,
        enableSpeechToText,
        speechToTextLanguage,
        enableMicForMeeting,
        enableWebCamForMeeting,
        enableAutoMeetingRecording,
        enableDeleteAllData,
        enableRectangularView,
        enableEmailTokenFlag,
        transcriptSlackChannelId,
        meetingApps,
        transcriptChatworkGroupId,
        transcriptDiscordUrl,
        timerDiscordUrl,
      }),
    );

    Toast.success(
      intl.formatMessage({ id: "workspace/updated-success", defaultMessage: "Workspace successfully updated!" }),
    );

    dispatch(setWorkspaceUpdating(false));

    if (currentWorkspace?.enableActiveApp !== enableActiveApp) {
      dispatch(checkActiveWindow());
    }
  };
}

function leaveWorkspaceAction(payload: LeaveWorkspace) {
  return { type: WorkspaceActions.LEAVE_WORKSPACE, payload };
}

export function leaveWorkspace(workspaceId: number, userId: number): AppThunk {
  return (dispatch: Dispatch) => {
    dispatch(leaveWorkspaceAction({ workspaceId, userId }));
    dispatch(sendMessage(WORKSPACE_EVENT_TYPES.LEAVE_WORKSPACE, { workspaceId, userId }));
  };
}

function leftWorkspaceAction(payload: LeaveWorkspace) {
  return { type: WorkspaceActions.LEFT_WORKSPACE, payload };
}

/**
 * 1. Manager removed user from the workspace successfully
 * 2. Users got the event for left other user from the workspace
 * 3. This user was removed from the workspace.
 */
export function leftWorkspace(userId: number, workspaceId: number): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const me = getMyMember(getState());
    const workspaces = getAllWorkspaces(getState());
    const currentWorkspace = getCurrentWorkspace(getState());

    if (me?.id === userId) {
      const remainWorkspaces = workspaces.filter(el => el.id !== workspaceId);

      if (remainWorkspaces.length > 0) {
        dispatch(leftWorkspaceAction({ workspaceId, userId }));

        if (currentWorkspace?.id === workspaceId) {
          dispatch(updateUserWorkspace({ workspaceId: remainWorkspaces[0].id, userId }));
          dispatch(setAccountWorkspace(-1));
          dispatch(switchWorkspace(remainWorkspaces[0].id));
          dispatch(stopWorkingRequest("Stop time tracker before user left workspace"));
        }
      } else {
        dispatch(logout());
      }
    } else {
      // Manager removed user from the workspace or Users got the event for left other user from the workspace
      dispatch(removeUser({ userId }));
    }
  };
}

export const updateWorkspaceLogoUrl = (payload: UpdateWorkspaceLogo): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    dispatch({ type: WorkspaceActions.UPDATE_WORKSPACE_LOGO_URL, payload });
  };
};

export function postUpdatedWorkpaceLogoUrl(workspaceId: number, logoUrl: string | null) {
  return sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_LOGO, { workspaceId, logoUrl });
}

export function joinedWorkspace(user: MemberData): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const isListener = checkIsListener(getState());
    const workspace = getCurrentWorkspace(getState());
    const workspaceMembers = getWorkspaceUsers(getState());
    const workspaceListeners = getWorkspaceListeners(getState());

    if (user.role === "listener") {
      const listenerData: ListenerData = {
        id: user.id,
        name: user.name,
        avatarUrl: user.avatarUrl,
        online: user.online,
        workspaceId: user.workspaceId,
        voiceChannelId: user.voiceChannelId,
        connections: user.connections,
        guestJoinId: user.guestJoinId,
        speechToText: user.speechToText,
        joinShortId: user.joinShortId,
      };

      if (workspaceListeners.find(wl => wl.id === listenerData.id)) {
        dispatch(updateListener(listenerData));
      } else {
        dispatch(addListener(listenerData));
      }

      return;
    }

    if (workspace?.id === user.workspaceId) {
      if (!workspaceMembers.some(el => el.id === user.id)) {
        dispatch(addUser(user));

        if (workspaceMembers.length !== 0 && !isListener) {
          notifyOnElectron(NotificationCategory.WorkspaceEvents, "Join", user.name);
          Toast.info(
            intl.formatMessage(
              { id: "workspace-join-notification", defaultMessage: "A new user {user} just joined this workspace." },
              { user: user.name },
            ),
          );
        }
      } else {
        dispatch(
          updateUserOnline({
            workspaceId: user.workspaceId,
            online: true,
            userId: user.id,
            connections: user.connections,
            lastActiveAt: new Date(),
          }),
        );
      }
    }
  };
}

export function updateWorkspaceLocation(workspaceId: number, regionId: number): AppThunk {
  return (dispatch: Dispatch) => {
    dispatch(sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_LOCATION, { workspaceId, regionId }));
  };
}

export function updateWorkspaceVpSttServer(workspaceId: number, vpSttServerId: number): AppThunk {
  return (dispatch: Dispatch) => {
    dispatch(sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_VP_STT_SERVER, { workspaceId, vpSttServerId }));
  };
}

export function updatedWorkspaceVpSttServer(payload: { workspaceId: number; vpSttServerId: number }) {
  return {
    type: WorkspaceActions.UPDATE_VP_STT_SERVER,
    payload,
  } as const;
}

function updateSpeechToTextMinutesAction(workspaceId: number, minutesSpent: number) {
  return { type: WorkspaceActions.UPDATE_SPEECH_TO_TEXT_MINUTES, payload: { workspaceId, minutesSpent } } as const;
}

export function fetchSpeechToTextMinutesSpent(): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const workspace = getCurrentWorkspace(getState());

    if (workspace) {
      const { response } = await postJson(STT_GET_MINUTES_SPENT, { workspaceId: workspace.id });

      if (response.success) {
        const { minutesSpent } = response.data;

        dispatch(updateSpeechToTextMinutesAction(workspace.id, minutesSpent));
      }
    }
  };
}

function deletedWorkspaceAction(workspaceId: number) {
  return { type: WorkspaceActions.DELETE_WORKSPACE, payload: { workspaceId } } as const;
}

export function deletedWorkspace(workspaceId: number): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const currentWorkspace = getCurrentWorkspace(getState());

    dispatch(deletedWorkspaceAction(workspaceId));

    if (currentWorkspace && currentWorkspace.id === workspaceId) {
      dispatch(reloadApp());
    }
  };
}

function updateWorkspaceMeetingRecordingCountAction(numberOfMeetingsRecorded: number, workspaceId: number) {
  return {
    type: WorkspaceActions.UPDATE_MEETING_RECORDING_COUNT,
    payload: { numberOfMeetingsRecorded, workspaceId },
  } as const;
}

export function updateWorkspaceMeetingRecordingCount(numberOfMeetingsRecorded: number, workspaceId: number): AppThunk {
  return (dispatch: Function) => {
    dispatch(updateWorkspaceMeetingRecordingCountAction(numberOfMeetingsRecorded, workspaceId));
  };
}

export function updateWorkspaceLanguageOptions(payload: UpdateLanguageOptions, planName: string | undefined): AppThunk {
  return (dispatch: Function) => {
    dispatch(
      sendMessage(WORKSPACE_EVENT_TYPES.OUT_UPDATE_LANG_REQUEST, {
        workspaceId: payload.workspaceId,
        languageOptions: convertLanguages(payload.languageOptions, planName),
      }),
    );
  };
}

function updatedLanguageOptionsAction(payload: UpdatedLanguageOptions) {
  return { type: WorkspaceActions.UPDATE_LANGUAGE_OPTIONS, payload } as const;
}

export function updatedLanguageOptions(
  workspaceId: number,
  languageOptions: string,
  workspaceUserSttSettings: WorkspaceUserSttSetting[],
  voiceChannelSttSettings: VoiceChannelSttSetting[],
): AppThunk {
  return (dispatch: Function, getState: () => State) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const language = getLanguage(getState());

    dispatch(updatedLanguageOptionsAction({ workspaceId, languageOptions }));
    dispatch(setCurrentWorkspaceLanguages(workspaceId, currentWorkspace?.plan.name[language], languageOptions));
    workspaceUserSttSettings.forEach(el => {
      dispatch(updateUserSTTSettings({ userId: el.userId, speechToText: el.speechToText }));
    });
    voiceChannelSttSettings.forEach(el => {
      const voiceChannel = getVoiceChannelById(el.id)(getState());

      if (voiceChannel?.speechToTextLanguage !== el.speechToTextLanguage)
        dispatch(
          updateVoiceChannelSttLanguage({ voiceChannelId: el.id, speechToTextLanguage: el.speechToTextLanguage }),
        );
    });
  };
}

function updateWorkspacePhrasesAction(
  workspaceId: number,
  language: string,
  phrases: string,
  translationDictionary: TranslationDictionary,
) {
  return {
    type: WorkspaceActions.UPDATE_WORKSPACE_PHRASES,
    payload: { workspaceId, language, phrases, translationDictionary },
  } as const;
}

export function updateWorkspacePhrase(
  workspaceId: number,
  language: string,
  phrases: string,
  translationDictionary: TranslationDictionary,
): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const currentWorkspaceByWorkspaceId = getCurrentWorkspaceByWorkspaceId(workspaceId)(getState());
    const workspace = currentWorkspace || currentWorkspaceByWorkspaceId;

    if (workspace && workspace.id === workspaceId) {
      dispatch(updateWorkspacePhrasesAction(workspace.id, language, phrases, translationDictionary));
      Toast.success(
        intl.formatMessage({
          id: "workspace-setting/phrases-updated",
          defaultMessage: "STT phrases was updated successfully",
        }),
      );
    }
  };
}

export function setWorkspacePhrasesAction(workspacePhrases: WorkspacePhrase[]) {
  return { type: WorkspaceActions.SET_WORKSPACE_PHRASES, payload: { workspacePhrases } } as const;
}

export function updateEnableConsecutiveTranslation(payload: {
  isConsecutiveTranslation: boolean;
  workspaceId: number;
}): AppThunk {
  return async (dispatch: Function) => {
    dispatch(
      sendMessage(WORKSPACE_EVENT_TYPES.UPDATE_ENABLE_CONSECUTIVE_TRANSLATION, {
        workspaceId: payload.workspaceId,
        isConsecutiveTranslation: payload.isConsecutiveTranslation,
      }),
    );
    dispatch(updateEnableConsecutiveTranslationAction(payload));
  };
}

export function updateWorkspaceEnableConsecutiveTranslation(payload: {
  isConsecutiveTranslation: boolean;
  workspaceId: number;
}) {
  return async (dispatch: Function) => {
    dispatch(
      updateEnableConsecutiveTranslationAction({
        isConsecutiveTranslation: payload.isConsecutiveTranslation,
        workspaceId: payload.workspaceId,
      }),
    );

    Toast.success(
      intl.formatMessage({
        id: "workspace/updated-success",
        defaultMessage: "Workspace successfully updated!",
      }),
    );
  };
}

export const updateEnableConsecutiveTranslationAction = (payload: {
  isConsecutiveTranslation: boolean;
  workspaceId: number;
}) => {
  return { type: WorkspaceActions.UPDATE_ENABLE_CONSECUTIVE_TRANSLATION, payload: payload };
};

export const updatePlanDetailsAction = (payload: {
  planDetails: PlanDetails;
  workspaceId: number;
  stripeCustomerId: string | null;
}) => {
  return { type: WorkspaceActions.UPDATE_PLAN_DETAILS, payload: payload };
};

export function setWorkspaceInviteesAction(payload: { workspaceId: number; invitees: InviteeData[] }) {
  return {
    type: WorkspaceActions.SET_WORKSPACE_INVITEES,
    payload: payload,
  } as const;
}

export function updateWorkspaceInviteeAction(payload: { workspaceId: number; invitee: InviteeData }) {
  return {
    type: WorkspaceActions.UPDATE_WORKSPACE_INVITEE,
    payload: payload,
  } as const;
}

export function updateWorkspaceHasMobileAppAction(payload: {
  workspaceId: number;
  userId: number;
  hasMobileApp: boolean;
}) {
  return {
    type: WorkspaceActions.UPDATE_WORKSPACE_HAS_MOBILE_APP,
    payload: payload,
  } as const;
}

export function UpdateWorkspaceInvitationRequest(payload: {
  workspaceId: number;
  invitationId: number;
  timeTracker?: boolean;
  screenShotCapture?: boolean;
  webApp?: boolean;
}) {
  return async (dispatch: Function) => {
    dispatch(
      sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_INVITATION_REQUEST, {
        workspaceId: payload.workspaceId,
        invitationId: payload.invitationId,
        timeTracker: payload.timeTracker,
        screenShotCapture: payload.screenShotCapture,
        webApp: payload.webApp,
      }),
    );
  };
}

export function deleteWorkspaceInvitationRequest(payload: { workspaceId: number; invitationId: number }) {
  return async (dispatch: Function) => {
    dispatch(
      sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_DELETE_INVITATION_REQUEST, {
        workspaceId: payload.workspaceId,
        invitationId: payload.invitationId,
      }),
    );
  };
}

export function updateWorkspaceInvitationList(payload: { workspaceId: number; invitees: InviteeData[] }): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const workspaces = getAllWorkspaces(getState());
    const workspace = currentWorkspace ?? workspaces.find(w => w.id === payload.workspaceId);

    if (workspace && workspace.invitees) {
      dispatch(setWorkspaceInviteesAction(payload));
      Toast.success("successed to update invitation list");
    }
  };
}

export function updateWorkspaceInvitation(payload: { workspaceId: number; invitee: InviteeData }): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    const currentWorkspace = getCurrentWorkspace(getState());

    if (currentWorkspace && currentWorkspace.invitees) {
      dispatch(updateWorkspaceInviteeAction(payload));
      Toast.success("successed to update invitation list");
    }
  };
}

export function updateWorkspaceTranslationFrequency(payload: {
  workspaceId: number;
  translationFrequency: number;
}): AppThunk {
  return async (dispatch: Function) => {
    dispatch(
      sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_TRANSLATION_FREQUENCY_REQUEST, {
        workspaceId: payload.workspaceId,
        translationFrequency: payload.translationFrequency,
      }),
    );
  };
}

export function updatedWorkspaceTranslationFrequency(payload: { workspaceId: number; translationFrequency: number }) {
  return {
    type: WorkspaceActions.UPDATE_WORKSPACE_TRANSLATION_FREQUENCY,
    payload: { workspaceId: payload.workspaceId, translationFrequency: payload.translationFrequency },
  } as const;
}

export function confirmWorkspaceTranslationFrequency(currentTranslationFrequency: number | null, workspaceId: number) {
  console.log("TRANSLATION FREQUENCY CONFIRMATION is running", currentTranslationFrequency, workspaceId);
  return (dispatch: Function) => {
    if (
      currentTranslationFrequency &&
      ![LOW_TRANSLATION_FREQUENCY, DEFAULT_TRANSLATION_FREQUENCY, HIGH_TRANSLATION_FREQUENCY].includes(
        currentTranslationFrequency,
      )
    ) {
      dispatch(
        sendMessage(WORKSPACE_EVENT_TYPES.WORKSPACE_UPDATE_TRANSLATION_FREQUENCY_REQUEST, {
          workspaceId: workspaceId,
          translationFrequency: DEFAULT_TRANSLATION_FREQUENCY,
        }),
      );
    }
  };
}
