import { AppThunk } from "../types";
import { initialWorkspace } from "../workspace";
import { log, LogCategory } from "../../utils/log";
import { State } from "../state";
import { isAuthenticated } from "../account";
import { showModal } from "../../screens/Dashboard/state";
import { RECONNECT_CHANNEL_MODAL_ID } from "../../screens/Dashboard/constants";
import { getDuplicatedLoginStatus, getInitialAccessWorkpsaceChannelShortId } from "../app";
import { disconnectRoom } from "../livekit";
import { setWorkspaceUsers } from "../users";

export enum SocketActions {
  CONNECTION_CHANGED = "SOCKET_CONNECTION_CHANGED",
  CONNECT_SOCKET = "CONNECT_SOCKET",
  DISCONNECT_SOCKET = "DISCONNECT_SOCKET",
  DISCONNECTED_SOCKET = "DISCONNECTED_SOCKET",
  SEND_MESSAGE = "SEND_MESSAGE",
  FORCE_LOGOUT = "user/logout",
}

export const connectionChanged = (isConnected: boolean, id: string | null): AppThunk => {
  return (dispatch: Function, getState: () => State) => {
    const authenticated = isAuthenticated(getState());
    const isDuplicatedLogin = getDuplicatedLoginStatus(getState());
    const initialAccessWorkspaceChannelId = getInitialAccessWorkpsaceChannelShortId(getState());
    const initialAccessWorksapceShortId = initialAccessWorkspaceChannelId?.split("/")[0];
    const initialAccessChannelShortId = initialAccessWorkspaceChannelId?.split("/")[1];

    dispatch(connectionChangedAction(isConnected, id));
    log(LogCategory.Socket, "Socket connection change", isConnected);

    if (isConnected === false) {
      // If user lost connection with socket server, need to close the connection with livekit server.
      // Then we can  avoid the issue that can speak users when main server was down
      dispatch(disconnectRoom(true));
      // If user didn't intend to disconnect (i.e. via routing to another page) then notify the user
      // of connectivity issue
      if (!!window.location.pathname.startsWith("/dashboard") && authenticated && !isDuplicatedLogin) {
        log(LogCategory.Socket, "check show alert for connection lost");
        dispatch(showModal({ id: RECONNECT_CHANNEL_MODAL_ID, show: true }));
      }
    } else {
      dispatch(showModal({ id: RECONNECT_CHANNEL_MODAL_ID, show: false }));
      // Switch into initial workspace
      dispatch(setWorkspaceUsers([]));
      dispatch(initialWorkspace(initialAccessWorksapceShortId, initialAccessChannelShortId));
    }
  };
};

function connectionChangedAction(connected: boolean, id: string | null) {
  return {
    type: SocketActions.CONNECTION_CHANGED,
    connected,
    id,
  };
}

export const connectSocket = () => {
  return {
    type: SocketActions.CONNECT_SOCKET,
  };
};

export const disconnectSocket = () => {
  return {
    type: SocketActions.DISCONNECT_SOCKET,
  };
};

export const sendMessage = (type: string, payload?: any) => {
  return {
    type: SocketActions.SEND_MESSAGE,
    payload: {
      event: type,
      data: payload,
    },
  };
};

export const forceLogout = () => {
  return {
    type: SocketActions.FORCE_LOGOUT,
  };
};
