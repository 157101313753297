export enum ChannelActions {
  CHANNEL_CREATE = "channels/CHANNEL_CREATE",
  CHANNEL_CREATE_SUCCESS = "channels/CHANNEL_CREATE_SUCCESS",

  CHANNEL_UPDATE = "channels/CHANNEL_UPDATE",
  CHANNEL_UPDATE_SUCCESS = "channels/CHANNEL_UPDATE_SUCCESS",
  CHANNEL_BACKGROUND_UPDATE_SUCCESS = "channels/CHANNEL_BACKGROUND_UPDATE_SUCCESS",

  CHANNEL_DELETE = "channels/CHANNEL_DELETE",
  CHANNEL_DELETE_SUCCESS = "channels/CHANNEL_DELETE_SUCCESS",

  SET_CHANNELS = "channels/SET_CHANNELS",

  CHANNEL_JOIN = "channels/CHANNEL_JOIN",

  CHANNEL_LEAVE = "channels/CHANNEL_LEAVE",
  CHANNEL_LEFT = "channels/CHANNEL_LEFT",

  CHANNEL_LAST_MEETING_UPDATE = "channels/CHANNEL_LAST_MEETING_UPDATE",

  VOICE_CHANNEL_INVITE_REQUEST = "voiceChannels/VOICE_CHANNEL_INVITE_REQUEST",
  VOICE_CHANNEL_INVITE_RECEIVE = "voiceChannels/VOICE_CHANNEL_INVITE_RECEIVE",
  VOICE_CHANNEL_INVITE_RESPONSE_ACCEPT = "voiceChannels/VOICE_CHANNEL_INVITE_RESPONSE_ACCEPT",
  VOICE_CHANNEL_INVITE_RESPONSE_DECLINE = "voiceChannels/VOICE_CHANNEL_INVITE_RESPONSE_DECLINE",
  VOICE_CHANNEL_INVITE_RESPONSE_LATER = "voiceChannels/VOICE_CHANNEL_INVITE_RESPONSE_LATER",
  VOICE_CHANNEL_UPDATE_PENDING_INVITATIONS = "voiceChannels/VOICE_CHANNEL_UPDATE_PENDING_INVITATIONS",
  VOICE_CHANNEL_INVITATION_RESPONSE = "voiceChannels/VOICE_CHANNEL_INVITATION_RESPONSE",
  VOICE_CHANNEL_INVITE_RESPONSE_CANCEL = "voiceChannels/VOICE_CHANNEL_INVITE_RESPONSE_CANCEL",
  VOICE_CHANNEL_UPDATE_STT_LANGUAGE = "voiceChannels/VOICE_CHANNEL_UPDATE_STT_LANGUAGE",
  VOICE_CHANNEL_INVITE_REMOVE = "voiceChannels/VOICE_CHANNEL_INVITE_REMOVE",

  RESET_LOADING_STATE = "voiceChannel/RESET_LOADING_STATE",
  VOICE_CHANNEL_SPEAKING_WHILE_MUTED = "voiceChannel/VOICE_CHANNEL_SPEAKING_WHILE_MUTED",
  VOICE_CHANNEL_RESTART_VAD = "voiceChannel/VOICE_CHANNEL_RESTART_VAD",
  GET_CLOSER_INVITE_REQUEST = "voiceChannel/GET_CLOSER_INVITE_REQUEST",
  CLEAR_GET_CLOSER_INVITE_REQUEST = "voiceChannel/CLEAR_GET_CLOSER_INVITE_REQUEST",
  INTIATE_GET_CLOSER_INVITE = "voiceChannel/INTIATE_GET_CLOSER_INVITE",
  AUTO_MEETING_RECORDING_FEATURE_AVAILED_STATUS_UPDATED = "voiceChannel/AUTO_MEETING_RECORDING_FEATURE_AVAILED_STATUS_UPDATED",
  UPDATE_COLLAPSED_VOICE_CHANNEL = "voiceChannel/UPDATE_COLLAPSED_VOICE_CHANNEL",
  UPDATE_IS_FROM_CUSTOM_BACKGROUND = "voiceChannel/UPDATE_IS_FROM_CUSTOM_BACKGROUND",
  UPDATE_KNOCK_SUBSCRIBER = "voiceChannel/UPDATE_KNOCK_SUBSCRIBER",

  SWITCH_GET_CLOSER_WAS_ACCEPTED = "voiceChannel/SWITCH_GET_CLOSER_WAS_ACCEPTED",

  SET_KNOCKER = "voiceChannel/SET_KNOCKER",
  SET_KNOCKERS = "voiceChannel/SET_KNOCKERS",
  REMOVE_KNOCKER = "voiceChannel/REMOVE_KNOCKER",
  SET_MY_KNOCK = "voiceChannel/SET_MY_KNOCK",
  REMOVE_MY_KNOCK = "voiceChannel/REMOVE_MY_KNOCK",
  REQUEST_RESEND_KNOCK_PRIVATE_MEETING = "voiceChannel/request-resend-knock-private-meeting",
}

export const CHANNEL_EVENT_TYPE_PREFIX = "voiceChannel/";

export const CHANNEL_EVENT_TYPES = {
  // TODO: rename object keys. Didn't do it now to avoid conflicts with unmerged PR.
  CHANNEL_CREATE: `${CHANNEL_EVENT_TYPE_PREFIX}new`,
  CHANNEL_UPDATE: `${CHANNEL_EVENT_TYPE_PREFIX}update`,
  CHANNEL_DELETE: `${CHANNEL_EVENT_TYPE_PREFIX}delete`,
  CHANNEL_JOIN: `${CHANNEL_EVENT_TYPE_PREFIX}join`,
  CHANNEL_LEAVE: `${CHANNEL_EVENT_TYPE_PREFIX}leave`,
  CHANNEL_LEAVE_FOR_LISTENER: `${CHANNEL_EVENT_TYPE_PREFIX}leave-for-listener`,
  CHANNEL_NO_EXIST: `${CHANNEL_EVENT_TYPE_PREFIX}no-exist`,
  CHANNEL_INVITE: `${CHANNEL_EVENT_TYPE_PREFIX}invite`,
  CHANNEL_SCHEDULED_MEETING_REMINDER: `${CHANNEL_EVENT_TYPE_PREFIX}scheduledMeetingReminder`,
  CHANNEL_CANCEL: `${CHANNEL_EVENT_TYPE_PREFIX}cancel`,
  CHANNEL_INVITE_RESPONSE: `${CHANNEL_EVENT_TYPE_PREFIX}inviteResponse`,
  CHANNEL_INVITE_RESPONSE_JOIN_LATER_TIMED_OUT: `${CHANNEL_EVENT_TYPE_PREFIX}inviteResponseJoinLaterTimedOut`,
  DUPLICATED_JOIN: `${CHANNEL_EVENT_TYPE_PREFIX}join-duplicated`,
  MUTE: `${CHANNEL_EVENT_TYPE_PREFIX}mute`,
  REQUEST_MUTE_CHANGE: `${CHANNEL_EVENT_TYPE_PREFIX}request-mute-change`,
  REQUEST_SILENCE_CHANGE: `${CHANNEL_EVENT_TYPE_PREFIX}request-silence-change`,
  REQUEST_SCREENSHARE: `${CHANNEL_EVENT_TYPE_PREFIX}request-screenshare`,
  RESPONSE_FOR_REQUEST: `${CHANNEL_EVENT_TYPE_PREFIX}response`,
  IN_AUTO_MEETING_RECORDING_FEATURE_AVAILED_STATUS_UPDATED: `${CHANNEL_EVENT_TYPE_PREFIX}auto-meeting-recording-feature-avaialed-status-update`,
  OUT_LIVEKIT_TOKEN_REQUEST: `${CHANNEL_EVENT_TYPE_PREFIX}request-livekit-token`,
  IN_LIVEKIT_TOKEN_RESPONSE: `${CHANNEL_EVENT_TYPE_PREFIX}response-livekit-token`,
  REQUEST_KNOCK_PRIVATE_MEETING: `${CHANNEL_EVENT_TYPE_PREFIX}request-knock-private-meeting`,
  RESPONSE_KNOCK_PRIVATE_MEETING: `${CHANNEL_EVENT_TYPE_PREFIX}response-knock-private-meeting`,
  REQUEST_ANSWER_TO_KNOCK: `${CHANNEL_EVENT_TYPE_PREFIX}request-answer-to-knock`,
  RESPONSE_ANSWER_TO_KNOCK: `${CHANNEL_EVENT_TYPE_PREFIX}response-answer-to-knock`,
  REQUEST_REMOVE_USER_FROM_CHANNEL: `${CHANNEL_EVENT_TYPE_PREFIX}request-remove-user-from-channel`,
  DELETE_KNOCK_BY_CHANNEL_DELETE: `${CHANNEL_EVENT_TYPE_PREFIX}delete-knock-by-channel-delete`,
  REMOVE_KNOCK: `${CHANNEL_EVENT_TYPE_PREFIX}remove-knock`,
  REQUEST_RESEND_KNOCK_PRIVATE_MEETING: `${CHANNEL_EVENT_TYPE_PREFIX}request-resend-knock-private-meeting`,
  REQUEST_CANCEL_KNOCK: `${CHANNEL_EVENT_TYPE_PREFIX}request-cancel-knock`,
  RESPONSE_VOICE_CHANNEL_JOIN_FALLBACK_GUEST: `${CHANNEL_EVENT_TYPE_PREFIX}join-fallback-guest`,
  RESPONSE_INVALID_KNOCK: `${CHANNEL_EVENT_TYPE_PREFIX}response-invalid-knock`,
  REQUEST_SWITCH_KNOCK_SUBSCRIBE_STATUS: `${CHANNEL_EVENT_TYPE_PREFIX}request-switch-knock-subscribe-status`,
  RESPONSE_SWITCH_KNOCK_SUBSCRIBE_STATUS: `${CHANNEL_EVENT_TYPE_PREFIX}response-switch-knock-subscribe-status`,
  NO_PERMISSION_CHANNEL: `${CHANNEL_EVENT_TYPE_PREFIX}no-permission-channel`,
};
