import { MacPermission } from "../store/types";
import { channels } from "./channels";
import { sendMessageOverIPC } from "./sendMessageOverIPC";
import { ElectronWindowsSettings } from "./windows";

export interface ScreenShareWindowSource {
  id: string;
  type: "screen" | "window";
  name: string;
  appIconDataURI: string | null;
  thumbnailDataURI: string | null;
}

export interface ActiveWindowSource {
  name: string;
  appIconDataURI: string | null;
}

export async function getScreenWindowSharingOptions(): Promise<ScreenShareWindowSource[]> {
  if (!window.electron) {
    throw new Error("Not available on browser, use native screenshare instead.");
  }

  const sources: ScreenShareWindowSource[] = await sendMessageOverIPC(channels.GET_SCREENSHARE_SOURCES);

  if (!Array.isArray(sources) || sources.length === 0) {
    throw new Error("Error fetching the list of screens and windows to share.");
  }

  return sources;
}

export async function checkForPermission(permission: MacPermission): Promise<boolean> {
  if (!window.electron) {
    throw new Error("Not available on browser, use native screenshare instead.");
  }

  const granted = await sendMessageOverIPC(channels.CHECK_PERMISSION, permission);

  if (typeof granted !== "boolean") {
    throw new Error("Invalid type on IPC result");
  }

  return granted;
}

export async function openElectronWindow(windowSettings: ElectronWindowsSettings) {
  if (!window.electron) {
    return;
  }

  await sendMessageOverIPC(channels.OPEN_WINDOW, windowSettings);
}

export async function closeElectronWindow(windowId: number, isRequestFromPiP: boolean, isAppCloseRequest: boolean) {
  if (!window.electron) {
    return;
  }

  sendMessageOverIPC(channels.CLOSE_WINDOW, { windowId, isRequestFromPiP: !!isRequestFromPiP, isAppCloseRequest });
}
