import React from "react";
import cx from "classnames";
import "./index.scss";

// Search for more icons on https://fonts.google.com/icons
import { ReactComponent as MicOffSVGIconOutlined } from "@material-design-icons/svg/filled/mic_off.svg";
import { ReactComponent as AccountCircleSVGIconOutlined } from "@material-design-icons/svg/outlined/account_circle.svg";
import { ReactComponent as AddSVGIconOutlined } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as ArrowBackSVGIconOutlined } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as ArrowBackIOSSVGIconOutlined } from "@material-design-icons/svg/outlined/arrow_back_ios.svg";
import { ReactComponent as ArrowDownwardSVGIconOutlined } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import { ReactComponent as ArrowDropDownSVGIconFilled } from "@material-design-icons/svg/filled/arrow_drop_down.svg";
import { ReactComponent as ArrowDropUpSVGIconFilled } from "@material-design-icons/svg/outlined/arrow_drop_up.svg";
import { ReactComponent as ArrowForwardSVGIconOutlined } from "@material-design-icons/svg/outlined/arrow_forward.svg";
import { ReactComponent as ArrowRightSVGIconFilled } from "@material-design-icons/svg/outlined/arrow_right.svg";
import { ReactComponent as ArrowUpwardSVGIconOutlined } from "@material-design-icons/svg/outlined/arrow_upward.svg";
import { ReactComponent as BusinessCenterSVGIconOutlined } from "@material-design-icons/svg/outlined/business_center.svg";
import { ReactComponent as CallSVGIconFilled } from "@material-design-icons/svg/filled/call.svg";
import { ReactComponent as CallSVGIconOutlined } from "@material-design-icons/svg/outlined/call.svg";
import { ReactComponent as CallEndSVGIconFilled } from "@material-design-icons/svg/filled/call_end.svg";
import { ReactComponent as CancelPresentationSVGIconOutlined } from "@material-design-icons/svg/outlined/cancel_presentation.svg";
import { ReactComponent as ChatBubbleSVGIconOutlined } from "@material-design-icons/svg/outlined/chat_bubble.svg";
import { ReactComponent as ChatSVGIconOutlined } from "@material-design-icons/svg/outlined/chat.svg";
import { ReactComponent as CheckSVGIconOutlined } from "@material-design-icons/svg/outlined/check.svg";
import { ReactComponent as CheckCircleSVGIconOutlined } from "@material-design-icons/svg/outlined/check_circle.svg";
import { ReactComponent as ChevronLeftSVGIconFilled } from "@material-design-icons/svg/filled/chevron_left.svg";
import { ReactComponent as ChevronRightSVGIconFilled } from "@material-design-icons/svg/filled/chevron_right.svg";
import { ReactComponent as CloseSVGIconOutlined } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as CloudOffSVGIconOutlined } from "@material-design-icons/svg/outlined/cloud_off.svg";
import { ReactComponent as ComputerSVGIconOutlined } from "@material-design-icons/svg/outlined/computer.svg";
import { ReactComponent as ContactSupportSVGIconOutlined } from "@material-design-icons/svg/outlined/contact_support.svg";
import { ReactComponent as ContentCopySVGIconOutlined } from "@material-design-icons/svg/outlined/content_copy.svg";
import { ReactComponent as DeleteSVGIconOutlined } from "@material-design-icons/svg/outlined/delete.svg";
import { ReactComponent as DrawSVGIconOutlined } from "@material-design-icons/svg/outlined/draw.svg";
import { ReactComponent as DragIndicatorSVGIconOutlined } from "@material-design-icons/svg/outlined/drag_indicator.svg";
import { ReactComponent as EditSVGIconFilled } from "@material-design-icons/svg/filled/edit.svg";
import { ReactComponent as EditNoteSVGIconFilled } from "@material-design-icons/svg/filled/edit_note.svg";
import { ReactComponent as ErrorOutlineSVGIconOutlined } from "@material-design-icons/svg/outlined/error_outline.svg";
import { ReactComponent as EventSVGIconFilled } from "@material-design-icons/svg/filled/event.svg";
import { ReactComponent as ExpandLessSVGIconFilled } from "@material-design-icons/svg/filled/expand_less.svg";
import { ReactComponent as ExpandMoreSVGIconFilled } from "@material-design-icons/svg/filled/expand_more.svg";
import { ReactComponent as FavoriteSVGIconFilled } from "@material-design-icons/svg/filled/favorite.svg";
import { ReactComponent as FileDownloadSVGIconOutlined } from "@material-design-icons/svg/outlined/file_download.svg";
import { ReactComponent as FileUploadSVGIconOutlined } from "@material-design-icons/svg/outlined/file_upload.svg";
import { ReactComponent as FormatShapesSVGIconOutlined } from "@material-design-icons/svg/outlined/format_shapes.svg";
import { ReactComponent as ForumSVGIconOutlined } from "@material-design-icons/svg/outlined/forum.svg";
import { ReactComponent as FullscreenSVGIconOutlined } from "@material-design-icons/svg/outlined/fullscreen.svg";
import { ReactComponent as FullscreenExitSVGIconOutlined } from "@material-design-icons/svg/outlined/fullscreen_exit.svg";
import { ReactComponent as GroupSVGIconOutlined } from "@material-design-icons/svg/outlined/group.svg";
import { ReactComponent as GroupsSVGIconOutlined } from "@material-design-icons/svg/outlined/groups.svg";
import { ReactComponent as HelpSVGIconOutlined } from "@material-design-icons/svg/outlined/help.svg";
import { ReactComponent as HighlightAltSVGIconOutlined } from "@material-design-icons/svg/outlined/highlight_alt.svg";
import { ReactComponent as InfoSVGIconOutlined } from "@material-design-icons/svg/outlined/info.svg";
import { ReactComponent as LaptopSVGIconOutlined } from "@material-design-icons/svg/outlined/laptop.svg";
import { ReactComponent as LinkSVGIconOutlined } from "@material-design-icons/svg/filled/link.svg";
import { ReactComponent as LogoutSVGIconOutlined } from "@material-design-icons/svg/outlined/logout.svg";
import { ReactComponent as MailSVGIconOutlined } from "@material-design-icons/svg/outlined/mail.svg";
import { ReactComponent as ManageAccountsSVGIconOutlined } from "@material-design-icons/svg/outlined/manage_accounts.svg";
import { ReactComponent as MeetingRoomSVGIconOutlined } from "@material-design-icons/svg/outlined/meeting_room.svg";
import { ReactComponent as MicSVGIconFilled } from "@material-design-icons/svg/filled/mic.svg";
import { ReactComponent as MoreHorizSVGIconOutlined } from "@material-design-icons/svg/outlined/more_horiz.svg";
import { ReactComponent as MoreVertSVGIconOutlined } from "@material-design-icons/svg/outlined/more_vert.svg";
import { ReactComponent as NewReleaseSVGIconFilled } from "@material-design-icons/svg/filled/new_releases.svg";
import { ReactComponent as NotiifcationsSVGIconOutlined } from "@material-design-icons/svg/outlined/notifications.svg";
import { ReactComponent as OpenInNewSVGIconOutlined } from "@material-design-icons/svg/outlined/open_in_new.svg";
import { ReactComponent as PaletteSVGIconOutlined } from "@material-design-icons/svg/outlined/palette.svg";
import { ReactComponent as PasswordSVGIconOutlined } from "@material-design-icons/svg/outlined/password.svg";
import { ReactComponent as PeopleAltSVGIconOutlined } from "@material-design-icons/svg/outlined/people_alt.svg";
import { ReactComponent as PersonAddSVGIconOutlined } from "@material-design-icons/svg/outlined/person_add.svg";
import { ReactComponent as PhoneInTalkSVGIconOutlined } from "@material-design-icons/svg/outlined/phone_in_talk.svg";
import { ReactComponent as PictureInPictureAltSVGIconFilled } from "@material-design-icons/svg/filled/picture_in_picture_alt.svg";
import { ReactComponent as PlayCircleSVGIconFilled } from "@material-design-icons/svg/filled/play_circle.svg";
import { ReactComponent as PlayCircleOutlineSVGIconFilled } from "@material-design-icons/svg/filled/play_circle_outline.svg";
import { ReactComponent as PresentToAllSVGIconOutlined } from "@material-design-icons/svg/outlined/present_to_all.svg";
import { ReactComponent as PriorityHighSVGIconOutlined } from "@material-design-icons/svg/outlined/priority_high.svg";
import { ReactComponent as PushPinSVGIconOutlined } from "@material-design-icons/svg/outlined/push_pin.svg";
import { ReactComponent as RedoSVGIconOutlined } from "@material-design-icons/svg/outlined/redo.svg";
import { ReactComponent as RefreshSVGIconOutlined } from "@material-design-icons/svg/outlined/refresh.svg";
import { ReactComponent as RemoveCircleSVGIconOutlined } from "@material-design-icons/svg/outlined/remove_circle_outline.svg";
import { ReactComponent as SendSVGIconFilled } from "@material-design-icons/svg/filled/send.svg";
import { ReactComponent as SensorsSVGIconOutlined } from "@material-design-icons/svg/outlined/sensors.svg";
import { ReactComponent as SettingsSVGIconFilled } from "@material-design-icons/svg/filled/settings.svg";
import { ReactComponent as SettingsSVGIconOutlined } from "@material-design-icons/svg/outlined/settings.svg";
import { ReactComponent as SettingsEthernetSVGIconOutlined } from "@material-design-icons/svg/outlined/settings_ethernet.svg";
import { ReactComponent as SignalCellularAltSVGIconOutlined } from "@material-design-icons/svg/outlined/signal_cellular_alt.svg";
import { ReactComponent as SmartDisplaySVGIconOutlined } from "@material-design-icons/svg/outlined/smart_display.svg";
import { ReactComponent as SocialDistanceSVGIconOutlined } from "@material-design-icons/svg/outlined/social_distance.svg";
import { ReactComponent as StopCircleSVGIconOutlined } from "@material-design-icons/svg/outlined/stop_circle.svg";
import { ReactComponent as SubjectSVGIconOutlined } from "@material-design-icons/svg/outlined/subject.svg";
import { ReactComponent as SubscriptionSVGIconOutlined } from "@material-design-icons/svg/outlined/subscriptions.svg";
import { ReactComponent as SyncSVGIconOutlined } from "@material-design-icons/svg/outlined/sync.svg";
import { ReactComponent as TagSVGIconOutlined } from "@material-design-icons/svg/outlined/tag.svg";
import { ReactComponent as ThumbUpSVGIconOutlined } from "@material-design-icons/svg/outlined/thumb_up.svg";
import { ReactComponent as TimerSVGIconOutlined } from "@material-design-icons/svg/outlined/timer.svg";
import { ReactComponent as TranslateSVGIconFilled } from "@material-design-icons/svg/filled/translate.svg";
import { ReactComponent as TuneSVGIconOutlined } from "@material-design-icons/svg/outlined/tune.svg";
import { ReactComponent as UndoSVGIconOutlined } from "@material-design-icons/svg/outlined/undo.svg";
import { ReactComponent as UpdateSVGIconOutlined } from "@material-design-icons/svg/outlined/update.svg";
import { ReactComponent as VideocamSVGIconFilled } from "@material-design-icons/svg/filled/videocam.svg";
import { ReactComponent as VideocamSVGIconOutlined } from "@material-design-icons/svg/outlined/videocam.svg";
import { ReactComponent as VideocamOffSVGIconOutlined } from "@material-design-icons/svg/outlined/videocam_off.svg";
import { ReactComponent as VolumeOffSVGIconOutlined } from "@material-design-icons/svg/outlined/volume_off.svg";
import { ReactComponent as VolumeUpSVGIconOutlined } from "@material-design-icons/svg/outlined/volume_up.svg";
import { ReactComponent as WarningAmberSVGIconOutlined } from "@material-design-icons/svg/outlined/warning_amber.svg";
import { ReactComponent as WarningSVGIconFilled } from "@material-design-icons/svg/filled/warning.svg";
import { ReactComponent as WidgetsSVGIconOutlined } from "@material-design-icons/svg/outlined/widgets.svg";
import { ReactComponent as SpeakerNotesSVGIconOutlined } from "@material-design-icons/svg/outlined/speaker_notes.svg";
import { ReactComponent as AssignmentSVGIconOutlined } from "@material-design-icons/svg/outlined/assignment.svg";
import { ReactComponent as CodeSVGIconOutlined } from "@material-design-icons/svg/outlined/code.svg";
import { ReactComponent as SearchSVGIconOutlined } from "@material-design-icons/svg/outlined/search.svg";
import { ReactComponent as HistorySVGIconOutlined } from "@material-design-icons/svg/outlined/history.svg";
import { ReactComponent as PodcastsSVGIconOutlined } from "@material-design-icons/svg/outlined/podcasts.svg";
import { ReactComponent as DataObjectSVGIconOutlined } from "@material-design-icons/svg/outlined/data_object.svg";
import { ReactComponent as VideoFileSVGIconOutlined } from "@material-design-icons/svg/outlined/video_file.svg";
import { ReactComponent as LockSVGIconOutlined } from "@material-design-icons/svg/outlined/lock.svg";
import { ReactComponent as TrendingUpSVGIconOutlined } from "@material-design-icons/svg/outlined/trending_up.svg";
import { ReactComponent as PhoneCallbackSVGIconOutlined } from "@material-design-icons/svg/outlined/phone_callback.svg";
import { ReactComponent as PhoneForwardedSVGIconOutlined } from "@material-design-icons/svg/outlined/phone_forwarded.svg";
import { ReactComponent as CancelSVGIconFilled } from "@material-design-icons/svg/filled/cancel.svg";
import { ReactComponent as PersonSearchSVGIconOutlined } from "@material-design-icons/svg/outlined/person_search.svg";
import { ReactComponent as PinDropSVGIconOutlined } from "@material-design-icons/svg/outlined/pin_drop.svg";
import { ReactComponent as ReplyAllSVGIconOutlined } from "@material-design-icons/svg/outlined/reply_all.svg";
import { ReactComponent as ReplySVGIconOutlined } from "@material-design-icons/svg/outlined/reply.svg";
import { ReactComponent as AddReactionSVGIconOutlined } from "@material-design-icons/svg/outlined/add_reaction.svg";
import { ReactComponent as AlignHorizontalLeftSVGIconOutlined } from "@material-design-icons/svg/outlined/align_horizontal_left.svg";
import { ReactComponent as AlignHorizontalCenterSVGIconOutlined } from "@material-design-icons/svg/outlined/align_horizontal_center.svg";
import { ReactComponent as AlignHorizontalRightSVGIconOutlined } from "@material-design-icons/svg/outlined/align_horizontal_right.svg";
import { ReactComponent as AlignVerticalTopSVGIconOutlined } from "@material-design-icons/svg/outlined/align_vertical_top.svg";
import { ReactComponent as AlignVerticalCenterSVGIconOutlined } from "@material-design-icons/svg/outlined/align_vertical_center.svg";
import { ReactComponent as AlignVerticalBottomSVGIconOutlined } from "@material-design-icons/svg/outlined/align_vertical_bottom.svg";
import { ReactComponent as CloudUploadSVGIconOutlined } from "@material-design-icons/svg/filled/cloud_upload.svg";
import { ReactComponent as RotateRightSVGIconOutlined } from "@material-design-icons/svg/outlined/rotate_right.svg";
import { ReactComponent as RotateLeftSVGIconOutlined } from "@material-design-icons/svg/outlined/rotate_left.svg";
import { ReactComponent as FlipToFrontSVGIconOutlined } from "@material-design-icons/svg/outlined/flip_to_front.svg";
import { ReactComponent as FlipToBackSVGIconOutlined } from "@material-design-icons/svg/outlined/flip_to_back.svg";
import { ReactComponent as FormatBoldSVGIconOutlined } from "@material-design-icons/svg/outlined/format_bold.svg";
import { ReactComponent as FormatUnderlineSVGIconOutlined } from "@material-design-icons/svg/outlined/format_underlined.svg";
import { ReactComponent as FormatStrikethroughSVGIconOutlined } from "@material-design-icons/svg/outlined/format_strikethrough.svg";
import { ReactComponent as FormatItalicSVGIconOutlined } from "@material-design-icons/svg/outlined/format_italic.svg";
import { ReactComponent as FormatColorTextSVGIconOutlined } from "@material-design-icons/svg/outlined/format_color_text.svg";
import { ReactComponent as StrikethroughSVGIconOutlined } from "@material-design-icons/svg/outlined/strikethrough_s.svg";
import { ReactComponent as RemoveSVGIconOutlined } from "@material-design-icons/svg/outlined/remove.svg";
import { ReactComponent as ReportSVGIconFilled } from "@material-design-icons/svg/filled/report.svg";
import { ReactComponent as EditOffSVGIconFilled } from "@material-design-icons/svg/filled/edit_off.svg";
import { ReactComponent as PhotoSVGIconOutlined } from "@material-design-icons/svg/outlined/photo.svg";
import { ReactComponent as CheckBoxSVGIconOutlined } from "@material-design-icons/svg/outlined/check_box.svg";
import { ReactComponent as LockOpenSVGIconOutlined } from "@material-design-icons/svg/outlined/lock_open.svg";
import { ReactComponent as CropSVGIconOutlined } from "@material-design-icons/svg/outlined/crop.svg";
import { ReactComponent as DoneSVGIconOutlined } from "@material-design-icons/svg/outlined/done.svg";
import { ReactComponent as PauseCircleSVGIconFilled } from "@material-design-icons/svg/filled/pause_circle.svg";
import { ReactComponent as WallpaperSVGIconOutlined } from "@material-design-icons/svg/outlined/wallpaper.svg";
import { ReactComponent as GridOffSVGIconOutlined } from "@material-design-icons/svg/outlined/grid_off.svg";
import { ReactComponent as GridOnSVGIconOutlined } from "@material-design-icons/svg/outlined/grid_on.svg";
import { ReactComponent as SelectAllSVGIconOutlined } from "@material-design-icons/svg/outlined/select_all.svg";
import { ReactComponent as DeselectSVGIconOutlined } from "@material-design-icons/svg/outlined/deselect.svg";
import { ReactComponent as PersonSVGIconOutlined } from "@material-design-icons/svg/outlined/person_outline.svg";
import { ReactComponent as FormatSizeSVGIconFilled } from "@material-design-icons/svg/filled/format_size.svg";
import { ReactComponent as HearingSVGIconFilled } from "@material-design-icons/svg/filled/hearing.svg";
import { ReactComponent as SpeedSVGIconFilled } from "@material-design-icons/svg/filled/speed.svg";
import { ReactComponent as CampaignSVGIconOutlined } from "@material-design-icons/svg/outlined/campaign.svg";
import { ReactComponent as KeyboardSVGIconOutlined } from "@material-design-icons/svg/outlined/keyboard.svg";
import { ReactComponent as CropSquareSVGIconOutlined } from "@material-design-icons/svg/outlined/crop_square.svg";
import { ReactComponent as InterestsSVGIconOutlined } from "@material-design-icons/svg/outlined/interests.svg";
import { ReactComponent as CircleSVGIconOutlined } from "@material-design-icons/svg/outlined/panorama_fish_eye.svg";
import { ReactComponent as TriangleSVGIconOutlined } from "@material-design-icons/svg/outlined/change_history.svg";
import { ReactComponent as LineSVGIconOutlined } from "@material-design-icons/svg/outlined/horizontal_rule.svg";
import { ReactComponent as ArrowSVGIconOutlined } from "@material-design-icons/svg/outlined/arrow_forward.svg";
import { ReactComponent as PanToolSVGIconOutlined } from "@material-design-icons/svg/outlined/pan_tool.svg";
import { ReactComponent as RocketLaunchSVGIconOutlined } from "@material-design-icons/svg/outlined/rocket_launch.svg";
import { ReactComponent as SmsSVGIconOutlined } from "@material-design-icons/svg/outlined/sms.svg";
import { ReactComponent as ShareSVGIconOutlined } from "@material-design-icons/svg/outlined/share.svg";
import { ReactComponent as ZoomInSVGIconOutlined } from "@material-design-icons/svg/outlined/zoom_in.svg";
import { ReactComponent as ZoomOutSVGIconOutlined } from "@material-design-icons/svg/outlined/zoom_out.svg";
import { ReactComponent as PublishSVGIconOutlined } from "@material-design-icons/svg/outlined/publish.svg";
import { ReactComponent as MenuBookSVGIconOutlined } from "@material-design-icons/svg/outlined/menu_book.svg";
import { ReactComponent as ImportContractsSVGIconOutlined } from "@material-design-icons/svg/filled/import_contacts.svg";

export const AccountCircleIconOutlined = createIconComponent(AccountCircleSVGIconOutlined, "outlined");
export const AddIconOutlined = createIconComponent(AddSVGIconOutlined, "outlined");
export const ArrowBackIconOutlined = createIconComponent(ArrowBackSVGIconOutlined, "outlined");
export const ArrowBackIosIconOutlined = createIconComponent(ArrowBackIOSSVGIconOutlined, "outlined");
export const ArrowDownwardIconOutlined = createIconComponent(ArrowDownwardSVGIconOutlined, "outlined");
export const ArrowDropDownIconFilled = createIconComponent(ArrowDropDownSVGIconFilled, "filled");
export const ArrowDropUpIconFilled = createIconComponent(ArrowDropUpSVGIconFilled, "filled");
export const ArrowForwardIconOutlined = createIconComponent(ArrowForwardSVGIconOutlined, "outlined");
export const ArrowRightIconFilled = createIconComponent(ArrowRightSVGIconFilled, "filled");
export const ArrowUpwardIconOutlined = createIconComponent(ArrowUpwardSVGIconOutlined, "outlined");
export const BusinessCenterIconOutlined = createIconComponent(BusinessCenterSVGIconOutlined, "outlined");
export const CallIconFilled = createIconComponent(CallSVGIconFilled, "filled");
export const CallIconOutlined = createIconComponent(CallSVGIconOutlined, "outlined");
export const CallEndIconFilled = createIconComponent(CallEndSVGIconFilled, "filled");
export const CancelPresentationIconOutlined = createIconComponent(CancelPresentationSVGIconOutlined, "outlined");
export const ChatBubbleIconOutlined = createIconComponent(ChatBubbleSVGIconOutlined, "outlined");
export const ChatIconOutlined = createIconComponent(ChatSVGIconOutlined, "outlined");
export const CheckIconOutlined = createIconComponent(CheckSVGIconOutlined, "outlined");
export const CheckCircleIconOutlined = createIconComponent(CheckCircleSVGIconOutlined, "outlined");
export const ChevronLeftIconFilled = createIconComponent(ChevronLeftSVGIconFilled, "filled");
export const ChevronRightIconFilled = createIconComponent(ChevronRightSVGIconFilled, "filled");
export const CloseIconOutlined = createIconComponent(CloseSVGIconOutlined, "outlined");
export const CloudOffIconOutlined = createIconComponent(CloudOffSVGIconOutlined, "outlined");
export const ComputerIconOutlined = createIconComponent(ComputerSVGIconOutlined, "outlined");
export const ContactSupportIconOutlined = createIconComponent(ContactSupportSVGIconOutlined, "outlined");
export const ContentCopyIconOutlined = createIconComponent(ContentCopySVGIconOutlined, "outlined");
export const DeleteIconOutlined = createIconComponent(DeleteSVGIconOutlined, "outlined");
export const DrawIconOutlined = createIconComponent(DrawSVGIconOutlined, "outlined");
export const DragIndicatorIconOutlined = createIconComponent(DragIndicatorSVGIconOutlined, "outlined");
export const EditIconFilled = createIconComponent(EditSVGIconFilled, "filled");
export const EditNoteIconFilled = createIconComponent(EditNoteSVGIconFilled, "filled");
export const ErrorOutlineIconOutlined = createIconComponent(ErrorOutlineSVGIconOutlined, "outlined");
export const EventIconFilled = createIconComponent(EventSVGIconFilled, "filled");
export const ExpandLessIconFilled = createIconComponent(ExpandLessSVGIconFilled, "filled");
export const ExpandMoreIconFilled = createIconComponent(ExpandMoreSVGIconFilled, "filled");
export const FavoriteIconFilled = createIconComponent(FavoriteSVGIconFilled, "filled");
export const FileDownloadIconOutlined = createIconComponent(FileDownloadSVGIconOutlined, "outlined");
export const FileUploadIconOutlined = createIconComponent(FileUploadSVGIconOutlined, "outlined");
export const FormatShapesIconOutlined = createIconComponent(FormatShapesSVGIconOutlined, "outlined");
export const ForumIconOutlined = createIconComponent(ForumSVGIconOutlined, "outlined");
export const FullscreenIconOutlined = createIconComponent(FullscreenSVGIconOutlined, "outlined");
export const FullscreenExitIconOutlined = createIconComponent(FullscreenExitSVGIconOutlined, "outlined");
export const GroupIconOutlined = createIconComponent(GroupSVGIconOutlined, "outlined");
export const GroupsIconOutlined = createIconComponent(GroupsSVGIconOutlined, "outlined");
export const HelpIconOutlined = createIconComponent(HelpSVGIconOutlined, "outlined");
export const HighlightAltIconOutlined = createIconComponent(HighlightAltSVGIconOutlined, "outlined");
export const InfoIconOutlined = createIconComponent(InfoSVGIconOutlined, "outlined");
export const LaptopIconOutlined = createIconComponent(LaptopSVGIconOutlined, "outlined");
export const LinkIconFilled = createIconComponent(LinkSVGIconOutlined, "filled");
export const LockIconOutlined = createIconComponent(LockSVGIconOutlined, "outlined");
export const LogoutIconOutlined = createIconComponent(LogoutSVGIconOutlined, "outlined");
export const MailIconOutlined = createIconComponent(MailSVGIconOutlined, "outlined");
export const ManageAccountsIconOutlined = createIconComponent(ManageAccountsSVGIconOutlined, "outlined");
export const MeetingRoomIconOutlined = createIconComponent(MeetingRoomSVGIconOutlined, "outlined");
export const MicIconFilled = createIconComponent(MicSVGIconFilled, "filled");
export const MicOffIconOutlined = createIconComponent(MicOffSVGIconOutlined, "outlined");
export const MoreHorizIconOutlined = createIconComponent(MoreHorizSVGIconOutlined, "outlined");
export const MoreVertIconOutlined = createIconComponent(MoreVertSVGIconOutlined, "outlined");
export const NewReleasesIconFilled = createIconComponent(NewReleaseSVGIconFilled, "filled");
export const NotificationsIconOutlined = createIconComponent(NotiifcationsSVGIconOutlined, "outlined");
export const OpenInNewIconOutlined = createIconComponent(OpenInNewSVGIconOutlined, "outlined");
export const PaletteIconOutlined = createIconComponent(PaletteSVGIconOutlined, "outlined");
export const PasswordIconOutlined = createIconComponent(PasswordSVGIconOutlined, "outlined");
export const PeopleAltIconOutlined = createIconComponent(PeopleAltSVGIconOutlined, "outlined");
export const PersonAddAltIconOutlined = createIconComponent(PersonAddSVGIconOutlined, "outlined");
export const PersonAddIconOutlined = createIconComponent(PersonAddSVGIconOutlined, "outlined");
export const PhoneInTalkIconOutlined = createIconComponent(PhoneInTalkSVGIconOutlined, "outlined");
export const PictureInPictureAltIconFilled = createIconComponent(PictureInPictureAltSVGIconFilled, "filled");
export const PlayCircleIconFilled = createIconComponent(PlayCircleSVGIconFilled, "filled");
export const PlayCircleIconOutlined = createIconComponent(PlayCircleOutlineSVGIconFilled, "outlined");
export const PresentToAllIconOutlined = createIconComponent(PresentToAllSVGIconOutlined, "outlined");
export const PriorityHighIconOutlined = createIconComponent(PriorityHighSVGIconOutlined, "outlined");
export const PushPinIconOutlined = createIconComponent(PushPinSVGIconOutlined, "outlined");
export const RedoIconOutlined = createIconComponent(RedoSVGIconOutlined, "outlined");
export const RefreshIconOutlined = createIconComponent(RefreshSVGIconOutlined, "outlined");
export const RemoveCircleOutlineIconOutlined = createIconComponent(RemoveCircleSVGIconOutlined, "outlined");
export const SendIconFilled = createIconComponent(SendSVGIconFilled, "filled");
export const SensorsIconOutlined = createIconComponent(SensorsSVGIconOutlined, "outlined");
export const SettingsIconFilled = createIconComponent(SettingsSVGIconFilled, "filled");
export const SettingsIconOutlined = createIconComponent(SettingsSVGIconOutlined, "outlined");
export const SettingsEthernetIconOutlined = createIconComponent(SettingsEthernetSVGIconOutlined, "outlined");
export const SignalCellularAltIconOutlined = createIconComponent(SignalCellularAltSVGIconOutlined, "outlined");
export const SmartDisplayIconOutlined = createIconComponent(SmartDisplaySVGIconOutlined, "outlined");
export const SocialDistanceIconOutlined = createIconComponent(SocialDistanceSVGIconOutlined, "outlined");
export const StopCircleIconOutlined = createIconComponent(StopCircleSVGIconOutlined, "outlined");
export const SubjectWhilteIconOutlined = createIconComponent(SubjectSVGIconOutlined, "outlined");
export const SubscriptionIconOutlined = createIconComponent(SubscriptionSVGIconOutlined, "outlined");
export const SyncIconOutlined = createIconComponent(SyncSVGIconOutlined, "outlined");
export const TagIconOutlined = createIconComponent(TagSVGIconOutlined, "outlined");
export const ThumbUpIconOutlined = createIconComponent(ThumbUpSVGIconOutlined, "outlined");
export const TimerIconOutlined = createIconComponent(TimerSVGIconOutlined, "outlined");
export const TranslateIconFilled = createIconComponent(TranslateSVGIconFilled, "filled");
export const TuneIconOutlined = createIconComponent(TuneSVGIconOutlined, "outlined");
export const UndoIconOutlined = createIconComponent(UndoSVGIconOutlined, "outlined");
export const UpdateIconOutlined = createIconComponent(UpdateSVGIconOutlined, "outlined");
export const VideocamIconFilled = createIconComponent(VideocamSVGIconFilled, "filled");
export const VideocamIconOutlined = createIconComponent(VideocamSVGIconOutlined, "outlined");
export const VideocamOffIconOutlined = createIconComponent(VideocamOffSVGIconOutlined, "outlined");
export const VolumeOffIconOutlined = createIconComponent(VolumeOffSVGIconOutlined, "outlined");
export const VolumeUpIconOutlined = createIconComponent(VolumeUpSVGIconOutlined, "outlined");
export const WarningAmberIconOutlined = createIconComponent(WarningAmberSVGIconOutlined, "outlined");
export const WarningIconFilled = createIconComponent(WarningSVGIconFilled, "filled");
export const WidgetsIconOutlined = createIconComponent(WidgetsSVGIconOutlined, "outlined");
export const AddOutlined = createIconComponent(AddSVGIconOutlined, "outlined");
export const SpeakerNotesOutlined = createIconComponent(SpeakerNotesSVGIconOutlined, "outlined");
export const AssignmentOutlined = createIconComponent(AssignmentSVGIconOutlined, "outlined");
export const CodeOutlined = createIconComponent(CodeSVGIconOutlined, "outlined");
export const SearchOutlined = createIconComponent(SearchSVGIconOutlined, "outlined");
export const HistoryOutlined = createIconComponent(HistorySVGIconOutlined, "outlined");
export const PodcastsOutlined = createIconComponent(PodcastsSVGIconOutlined, "outlined");
export const DataObjectOutlined = createIconComponent(DataObjectSVGIconOutlined, "outlined");
export const VideoFileOutlined = createIconComponent(VideoFileSVGIconOutlined, "outlined");
export const LockOutlined = createIconComponent(LockSVGIconOutlined, "outlined");
export const TrendingUpOutlined = createIconComponent(TrendingUpSVGIconOutlined, "outlined");
export const PhoneCallbackOutlined = createIconComponent(PhoneCallbackSVGIconOutlined, "outlined");
export const PhoneForwardedOutlined = createIconComponent(PhoneForwardedSVGIconOutlined, "outlined");
export const CancelIconFilled = createIconComponent(CancelSVGIconFilled, "filled");
export const PersonSearchOutlined = createIconComponent(PersonSearchSVGIconOutlined, "outlined");
export const PinDropOutlined = createIconComponent(PinDropSVGIconOutlined, "outlined");
export const ReplyAllOutlined = createIconComponent(ReplyAllSVGIconOutlined, "outlined");
export const ReplyOutlined = createIconComponent(ReplySVGIconOutlined, "outlined");
export const AddReactionOutlined = createIconComponent(AddReactionSVGIconOutlined, "outlined");
export const AlignHorizontalLeftOutlined = createIconComponent(AlignHorizontalLeftSVGIconOutlined, "outlined");
export const AlignHorizontalCenterOutlined = createIconComponent(AlignHorizontalCenterSVGIconOutlined, "outlined");
export const AlignHorizontalRightOutlined = createIconComponent(AlignHorizontalRightSVGIconOutlined, "outlined");
export const AlignVerticalTopOutlined = createIconComponent(AlignVerticalTopSVGIconOutlined, "outlined");
export const AlignVerticalCenterOutlined = createIconComponent(AlignVerticalCenterSVGIconOutlined, "outlined");
export const AlignVerticalBottomOutlined = createIconComponent(AlignVerticalBottomSVGIconOutlined, "outlined");
export const CloudUploadFilled = createIconComponent(CloudUploadSVGIconOutlined, "filled");
export const RotateRightIconOutlined = createIconComponent(RotateRightSVGIconOutlined, "outlined");
export const RotateLeftIconOutlined = createIconComponent(RotateLeftSVGIconOutlined, "outlined");
export const FlipToFrontIconOutlined = createIconComponent(FlipToFrontSVGIconOutlined, "outlined");
export const FlipToBackIconOutlined = createIconComponent(FlipToBackSVGIconOutlined, "outlined");
export const FormatBoldIconOutlined = createIconComponent(FormatBoldSVGIconOutlined, "outlined");
export const FormatUnderlinedIconOutlined = createIconComponent(FormatUnderlineSVGIconOutlined, "outlined");
export const FormatStrikethroughIconOutlined = createIconComponent(FormatStrikethroughSVGIconOutlined, "outlined");
export const FormatItalicIconOutlined = createIconComponent(FormatItalicSVGIconOutlined, "outlined");
export const FormatColorTextIconOutlined = createIconComponent(FormatColorTextSVGIconOutlined, "outlined");
export const StrikethroughIconOutlined = createIconComponent(StrikethroughSVGIconOutlined, "outlined");
export const RemoveOutlined = createIconComponent(RemoveSVGIconOutlined, "outlined");
export const ReportIcon = createIconComponent(ReportSVGIconFilled, "filled");
export const EditOffIconFilled = createIconComponent(EditOffSVGIconFilled, "filled");
export const PhotoIconOutlined = createIconComponent(PhotoSVGIconOutlined, "outlined");
export const CheckBoxIconOutlined = createIconComponent(CheckBoxSVGIconOutlined, "outlined");
export const LockOpenIconOutlined = createIconComponent(LockOpenSVGIconOutlined, "outlined");
export const CropIconOutlined = createIconComponent(CropSVGIconOutlined, "outlined");
export const DoneIconOutlined = createIconComponent(DoneSVGIconOutlined, "outlined");
export const PauseCircleIconFilled = createIconComponent(PauseCircleSVGIconFilled, "filled");
export const WallpaperIconOutlined = createIconComponent(WallpaperSVGIconOutlined, "outlined");
export const GridOffIconOutlined = createIconComponent(GridOffSVGIconOutlined, "outlined");
export const GridOnIconOutlined = createIconComponent(GridOnSVGIconOutlined, "outlined");
export const SelectAllIconOutlined = createIconComponent(SelectAllSVGIconOutlined, "outlined");
export const DeselectIconOutlined = createIconComponent(DeselectSVGIconOutlined, "outlined");
export const PersonIconOutlined = createIconComponent(PersonSVGIconOutlined, "outlined");
export const FormatSizeIconFilled = createIconComponent(FormatSizeSVGIconFilled, "filled");
export const HearingIconFilled = createIconComponent(HearingSVGIconFilled, "filled");
export const SpeedIconFilled = createIconComponent(SpeedSVGIconFilled, "filled");
export const CampaignIconOutlined = createIconComponent(CampaignSVGIconOutlined, "outlined");
export const KeyboardIconOutlined = createIconComponent(KeyboardSVGIconOutlined, "outlined");
export const CropSquareIconOutlined = createIconComponent(CropSquareSVGIconOutlined, "outlined");
export const ShapesIconOutlined = createIconComponent(InterestsSVGIconOutlined, "outlined");
export const CircleIconOutlined = createIconComponent(CircleSVGIconOutlined, "outlined");
export const TriangleIconOutlined = createIconComponent(TriangleSVGIconOutlined, "outlined");
export const LineIconOutlined = createIconComponent(LineSVGIconOutlined, "outlined");
export const ArrowIconOutlined = createIconComponent(ArrowSVGIconOutlined, "outlined");
export const PanToolIconOutlined = createIconComponent(PanToolSVGIconOutlined, "outlined");
export const RocketLaunchIconOutlined = createIconComponent(RocketLaunchSVGIconOutlined, "outlined");
export const SmsIconOutlined = createIconComponent(SmsSVGIconOutlined, "outlined");
export const ShareIconOutlined = createIconComponent(ShareSVGIconOutlined, "outlined");
export const ZoomInIconOutlined = createIconComponent(ZoomInSVGIconOutlined, "outlined");
export const ZoomOutIconOutlined = createIconComponent(ZoomOutSVGIconOutlined, "outlined");
export const PublishIconOutlined = createIconComponent(PublishSVGIconOutlined, "outlined");
export const MenuBookIconOutlined = createIconComponent(MenuBookSVGIconOutlined, "outlined");
export const ImportContractsIconOutlined = createIconComponent(ImportContractsSVGIconOutlined, "outlined");

type MaterialTheme = "filled" | "outlined"; // not used: "rounded" | "sharp" | "two-tone";
// To use other themes, need to include the font on index.html

type IconProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  disabled?: boolean;
  style?: React.CSSProperties;
};

function createIconComponent(
  svgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  theme: MaterialTheme,
): React.FunctionComponent<IconProps> {
  // This is the default class applied to all material icons
  const defaultClass = "material-icon";
  const Icon = svgIcon as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

  // These are the theme classes applied based on the chosen theme
  const materialThemeClasses = {
    filled: "material-icons",
    outlined: "material-icons-outlined",
  };

  return ({ className, onClick, disabled, style }: IconProps) => (
    <span
      className={cx(
        {
          [defaultClass]: true,
          [`${defaultClass}--clickable`]: onClick && !disabled,
          [`${defaultClass}--disabled`]: disabled,
        },
        materialThemeClasses[theme],
        className,
      )}
      onClick={!disabled ? onClick : undefined}
      style={style}
    >
      <Icon />
    </span>
  );
}
