import { createRequest, getJson, postJson } from "../utils/network";
import {
  WORKSPACE_API_BASE,
  WORKSPACE_MEMBERS_PATH,
  DEFAULT_BACKGRUND,
  PRELOADED_BACKGROUNDS,
  GET_WORKSPACE_PATH,
  WORKSPACE_PLAN_PATH,
  VOICE_CHANNELS_IN_WORKSPACE,
  SEARCH_QUERY_PARAM_NAME,
  DELETE_WORKSPACE_BY_NAME,
  USER_UPDATE_COMPANY_PATH,
  INCLUDED_DELETED_MEMBERS,
  GET_WORKSPACE_FOR_CMS,
  ENABLE_WORKSPACE_IP_FILTERING,
  VALIDATE_IP,
  INVITE_EMAILS,
  GET_INVITATION_LIST,
  RESEND_INVITATION,
  GET_WORKSPACE_ALL_EMAILS,
  WORKSPACE_MEMBER,
  GET_WORKSPACE_FIRST_FLOOR,
  WORKSPACE_CREATE_USER_BY_CSV,
  GET_ENABLED_WEB_APP_IN_VOICE_CHANNEL,
  POST_DELETE_WORKSPACE_INVITATION,
  GET_MULTIPLE_WORKSPACE_MEMBER_COUNT,
  GET_WORKSPACE_SIGNED_URL,
} from "../constant";
import { CreateOrReadResponseBody, UpdateOrDeleteResponseBody } from ".";
import { Role, Workspace } from "../store/types";
import { WORKSPACE_CREATE_USER_BY_MANUAL } from "../constant";
import {
  UserBelogsToSingleWorkspaceCount,
  UserBelongsToMultipleWorkspacesCount,
} from "../screens/reports/ManageTeam/ManageMultipleWorkspace/ManageMultipleWorkspace";
export interface WorkspaceMember {
  id: number;
  name: string;
  avatar: string;
  role: Role;
  projectId?: number | null;
  workingMemo?: string;
  lastActiveAt?: Date;
  email: string | null;
  hasMobileApp?: boolean;
}

type WorkspaceMembersResponse = CreateOrReadResponseBody<{
  users: WorkspaceMember[];
}>;

type WorkspacesResponse = CreateOrReadResponseBody<{
  workspaces: Workspace[];
}>;

export type WorkspaceManager = {
  id: number;
  name: string;
};

export async function getWorkspaceMembers(
  workspaceId: number,
  search?: string | null,
  deletedMembers?: boolean | null,
) {
  let path = WORKSPACE_MEMBERS_PATH(workspaceId) + "?";

  if (deletedMembers) {
    path = path + `${INCLUDED_DELETED_MEMBERS}=${deletedMembers}&`;
  }

  if (search && search !== "") {
    path = path + `${SEARCH_QUERY_PARAM_NAME}=${search}`;
  }

  const { response } = await getJson<WorkspaceMembersResponse>(path);

  if (response.success) {
    return response.data.users;
  } else {
    console.log(response);
    // alert(response.errors[0].message);
    return [];
  }
}

export async function postCreateWorkspace(name: string, enableWebApp: boolean, languageOptions: any) {
  const { response } = await postJson<CreateOrReadResponseBody>(WORKSPACE_API_BASE, {
    name: name,
    map: PRELOADED_BACKGROUNDS[DEFAULT_BACKGRUND.mainFloor_jp],
    enableWebApp,
    languageOptions,
  });

  return response;
}

export async function getWorkspaceMember(workspaceId: number) {
  const { response } = await getJson(WORKSPACE_MEMBER(workspaceId));

  return response.data;
}

export async function getWorkspaces() {
  const path = GET_WORKSPACE_PATH;
  const { response } = await getJson<WorkspacesResponse>(path);

  if (response.success) {
    return response.data.workspaces;
  } else {
    throw new Error("Error when fetching workspaces data");
  }
}

export type GetAllWorkspacesResponse = CreateOrReadResponseBody<{
  allWorkspaces: { id: number; name: string }[];
}>;

export type GetWorkspaceForCMSResponse = CreateOrReadResponseBody<{
  workspace: { id: number; shortId: string; name: string };
}>;

export async function getWorkspaceForCMS() {
  const path = GET_WORKSPACE_FOR_CMS;
  const { response } = await getJson<GetWorkspaceForCMSResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error("Error when fetching workspace detail data");
  }
}

export async function getWorkspacePlan(workspaceId: number) {
  const path = WORKSPACE_PLAN_PATH(workspaceId);

  const { response } = await getJson(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error("Error when getting workspace plan");
  }
}

export async function getVoiceChannelsInWorkspace(workspaceId: number) {
  const path = VOICE_CHANNELS_IN_WORKSPACE(workspaceId);

  const { response } = await getJson(path);

  if (response.success) {
    return response.data;
  } else {
    alert(response.errors[0].message);
    return { voiceChannels: [] };
  }
}

export async function deleteWorkspace(workspaceId: number) {
  const { response } = await createRequest(DELETE_WORKSPACE_BY_NAME(workspaceId), {
    method: "DELETE",
  });

  return response;
}

export interface CompanyInfo {
  company?: string;
  size?: string;
  role?: string;
  phone?: string;
}

export async function updateUserCompany(companyInfo: CompanyInfo) {
  const { response } = await createRequest(USER_UPDATE_COMPANY_PATH, {
    method: "POST",
    body: JSON.stringify(companyInfo),
  });

  return response;
}

export async function enableWorkspaceIpFiltering(workspaceId: number, toggle: boolean) {
  const data = { workspaceId, toggle };

  const path = ENABLE_WORKSPACE_IP_FILTERING;

  const { response } = await postJson<any>(path, data);

  return response;
}

export async function enableValidateIp(workspaceId: number) {
  const path = VALIDATE_IP(workspaceId);

  const { response } = await getJson<any>(path);

  return response.data;
}

type InviteByEmailRequest = {
  workspaceId: number;
  emails: string[];
  permissionData: string;
};

type InviteByEmailResponse = UpdateOrDeleteResponseBody;

export async function inviteByEmail(wokrspaceId: number, shortId: string, data: InviteByEmailRequest) {
  const path = INVITE_EMAILS(shortId);

  const { response } = await postJson<InviteByEmailResponse>(path, data);

  if (response.success) {
    return true;
  } else {
    return response.errors;
  }
}

export type InviteeData = {
  id: number;
  email: string;
  role: "member" | "manager";
  projects: string[];
  groups: string[];
  timeTracker: boolean;
  screenShotCapture: boolean;
  webApp: boolean;
  floors: string[];
  status: boolean;
  revoked: boolean;
};

type GetInvitationListResponse = CreateOrReadResponseBody<{
  invitees: InviteeData[];
}>;

export async function getInvitationList(workspaceId: number) {
  const path = GET_INVITATION_LIST(workspaceId);
  const { response } = await getJson<GetInvitationListResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

interface ResendInvitationRequest {
  invitationId: number;
}

export async function resendInvitation(data: ResendInvitationRequest) {
  const path = RESEND_INVITATION;
  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, data);

  if (response.success) {
    return true;
  } else {
    return false;
  }
}

type GetWorkspaceAllEmails = CreateOrReadResponseBody<{
  emails: string[];
}>;

export async function getWorkspaceAllEmails(workspaceId: number) {
  const path = GET_WORKSPACE_ALL_EMAILS(workspaceId);
  const { response } = await getJson<GetWorkspaceAllEmails>(path);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

export async function getWorksapceFirstFloor(workspaceId: number) {
  const path = GET_WORKSPACE_FIRST_FLOOR(workspaceId);
  const { response } = await getJson<CreateOrReadResponseBody<{ name: string }>>(path);

  if (response.success) {
    return response.data.name;
  } else {
    return false;
  }
}

type PostCreateUserByCsvRequest = {
  csv: string | ArrayBuffer | null;
  userId: number;
};

type PostCreateUserByCsvResponse = CreateOrReadResponseBody<{
  succeededUserEmails: string[];
  failedUserEmails: string[];
}>;
export async function postCreateUserByCsv(data: PostCreateUserByCsvRequest, workspaceId: number) {
  const path = WORKSPACE_CREATE_USER_BY_CSV(workspaceId);
  const { response } = await postJson<PostCreateUserByCsvResponse>(path, data);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type PostCreateUserByArrayRequest = {
  users: {
    email: string;
    name: string;
  }[];
  userId: number;
};

type PostCreateUserByArrayResponse = CreateOrReadResponseBody<{
  succeededUserEmails: string[];
  failedUserEmails: string[];
}>;

export async function postCreateUserByArray(data: PostCreateUserByArrayRequest, workspaceId: number) {
  const path = WORKSPACE_CREATE_USER_BY_MANUAL(workspaceId);
  const { response } = await postJson<PostCreateUserByArrayResponse>(path, data);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type GetEnabledWebAppInVoiceChannelResponse = CreateOrReadResponseBody<{
  guestEnabled: boolean;
  listenerEnabled: boolean;
  listenerId: string;
}>;

export async function getEnabledWebAppInVoiceChannel(channelShortId: string) {
  const path = GET_ENABLED_WEB_APP_IN_VOICE_CHANNEL(channelShortId);
  const { response } = await getJson<GetEnabledWebAppInVoiceChannelResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    return false;
  }
}

type PostDeleteWorkspaceInvitationResponse = CreateOrReadResponseBody<{
  invitees: InviteeData[];
}>;

export async function PostDeleteWorkspaceInvitation(data: { workspaceId: number; invitationId: number }) {
  const path = POST_DELETE_WORKSPACE_INVITATION;
  const { response } = await postJson<PostDeleteWorkspaceInvitationResponse>(path, data);

  if (response.success) {
    return response.data.invitees;
  } else {
    return null;
  }
}

type GetMultipleWorkspaceMemberCountResponse = CreateOrReadResponseBody<{
  count: UserBelogsToSingleWorkspaceCount[];
  duplicate: UserBelongsToMultipleWorkspacesCount[];
}>;

export async function getMultipleWorkspaceMember(workspaceId: number) {
  const path = GET_MULTIPLE_WORKSPACE_MEMBER_COUNT(workspaceId);
  const { response } = await getJson<GetMultipleWorkspaceMemberCountResponse>(path);

  if (response.success) {
    return response.data;
  } else {
    return null;
  }
}

export async function getWorkspaceSignedUrl(workspaceId: number, filename: string) {
  const { response } = await getJson(GET_WORKSPACE_SIGNED_URL(workspaceId, filename));

  return response.data.url;
}
