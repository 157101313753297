import { delay } from "../../../utils/helpers";
import type { LocalTimeSlot } from "./offlineTrackingService";

/**
 * Offline work storage logic
 */
export const offlineTrackingStorage = {
  async insert(timeSlot: LocalTimeSlot) {
    executeAtomicOperation(timeSlot.userId, timeSlot.projectId, timeSlot.workspaceId, data => {
      data[timeSlot.clientTimeTrackingId] = timeSlot;
    });

    // Delay just to simulate async behavior, because this function will need to be replaced by an async storage soon
    await delay(Math.round(Math.random() * 50));
  },

  async delete(timeSlot: LocalTimeSlot) {
    executeAtomicOperation(timeSlot.userId, timeSlot.projectId, timeSlot.workspaceId, data => {
      delete data[timeSlot.clientTimeTrackingId];
    });

    // Delay just to simulate async behavior, because this function will need to be replaced by an async storage soon
    await delay(Math.round(Math.random() * 50));
  },

  async update(timeSlot: LocalTimeSlot) {
    executeAtomicOperation(timeSlot.userId, timeSlot.projectId, timeSlot.workspaceId, data => {
      data[timeSlot.clientTimeTrackingId] = timeSlot;
    });

    // Delay just to simulate async behavior, because this function will need to be replaced by an async storage soon
    await delay(Math.round(Math.random() * 50));
  },

  async get(userId: number, projectId: number, workspaceId: number, clientTimeTrackingId: string) {
    return await getById(userId, projectId, workspaceId, clientTimeTrackingId);
  },

  async listAll(userId: number, projectId: number, workspaceId: number) {
    return await listWithCondition(userId, projectId, workspaceId, () => true);
  },

  async listStartingFrom(userId: number, projectId: number, workspaceId: number, startingFrom: string) {
    return await listWithCondition(userId, projectId, workspaceId, slot => slot.startTime >= startingFrom);
  },

  async listAllUnfinished(userId: number, projectId: number, workspaceId: number) {
    return await listWithCondition(userId, projectId, workspaceId, slot => slot.endTime === null);
  },
};

const storageKey = (userId: number, projectId: number, workspaceId: number) =>
  `offline-work-${userId}-${projectId}-${workspaceId}`;

function executeAtomicOperation(
  userId: number,
  projectId: number,
  workspaceId: number,
  operation: (data: Record<string, LocalTimeSlot>) => void,
) {
  const data = loadDataSync(userId, projectId, workspaceId);

  const result = operation(data) as any;

  if (result || result?.then) {
    throw new Error(
      "This data store implementation is based 'localStorage', so the write operation should be synchronous to avoid inconsistencies caused by race conditions.",
    );
  }

  saveDataSync(userId, projectId, workspaceId, data);
}

function loadDataSync(userId: number, projectId: number, workspaceId: number): Record<string, LocalTimeSlot> {
  const key = storageKey(userId, projectId, workspaceId);

  const storedValues = localStorage.getItem(key) ?? "{}";

  return JSON.parse(storedValues) as Record<string, LocalTimeSlot>;
}

function saveDataSync(userId: number, projectId: number, workspaceId: number, newData: Record<string, LocalTimeSlot>) {
  const key = storageKey(userId, projectId, workspaceId);

  const newStoredValues = JSON.stringify(newData);

  localStorage.setItem(key, newStoredValues);
}

async function listWithCondition(
  userId: number,
  projectId: number,
  workspaceId: number,
  condition: (timeSlot: LocalTimeSlot) => boolean,
) {
  const storedObject = loadDataSync(userId, projectId, workspaceId);

  return Object.values(storedObject).filter(slot => condition(slot));
}

async function getById(userId: number, projectId: number, workspaceId: number, id: string) {
  const storedObject = loadDataSync(userId, projectId, workspaceId);

  return storedObject[id];
}
