import { log, LogCategory } from "../../utils/log";
import {
  LivekitActions,
  setRoomConnection,
  setRoomConneted,
  setLoading,
  clearParticipants,
  removeParticipant,
  setParticipants,
  disconnectRoom,
  setVideoLoading,
  setMicLoading,
  setParticipant,
  setAudioPermission,
  setScreenshareLoading,
} from "./actions";
import Livekit, { LivekitPayload } from "../../utils/livekit";
import { RoomEvent, Track, ConnectionState, DisconnectReason } from "livekit-client";
import {
  getMyMember,
  setUserParticipantIdentity,
  updateUserMicActive,
  updateUserScreenActiveSend,
  updateUserSpeakerActive,
  updateUserVideoActiveAction,
  USER_EVENT_TYPES,
} from "../users";
import {
  setParticipantsToGlobal,
  setParticipantToGlobal,
  clearParticipantFromGlobal,
  getUserInfoFromParticipantId,
  removeParticipantFromGlobal,
} from "../../utils/helpers";
import { doStopScreenShare, getScreenshare, showStopSharingWindow } from "../screenshare";
import { disconnectSocket, getSocketConnectivityStatus, sendMessage } from "../socket";
import { showModal } from "../../screens/Dashboard/state";
import { RECONNECT_CHANNEL_MODAL_ID } from "../../screens/Dashboard/constants";
import { channelJoined, getIsChannelJoining } from "../voiceChannels";
import { checkIsWorkspaceJoining } from "../workspace";
import { getHasAudioPermission } from "./selectors";
import { getDuplicatedLoginStatus } from "../app";
import {
  getAudioExtractionDefaultMicStatus,
  getIsLoadingRecordAudioExtraction,
  getIsRecordingAudioExtraction,
  getIsStartingRecordAudioExtraction,
  getIsStoppingRecordAudioExtraction,
  updateStoppingRecordAudioExtractionAction,
} from "../audioExtraction";
import { SCREEN_SHARE_MEDIA_CONSTRAINTS } from "../../constant";

export const livekitMiddleware = (store: any) => {
  function onIncomingMessage(type: string, payload?: LivekitPayload) {
    log(LogCategory.Livekit, "Incoming message", { type, payload });
    switch (type) {
      case LivekitActions.LIVEKIT_SCREENSHARE_LOADING:
        store.dispatch(setScreenshareLoading(payload!.loading!));
        break;
      case LivekitActions.LIVEKIT_MIC_LOADING:
        store.dispatch(setMicLoading(payload!.loading!));
        break;
      case LivekitActions.LIVEKIT_VIDEO_LOADING:
        store.dispatch(setVideoLoading(payload!.loading!));
        break;
      case LivekitActions.LIVEKIT_LOADING:
        store.dispatch(setLoading(payload!.loading!));
        break;
      case RoomEvent.SignalConnected:
        const { voiceChannelShortId, participants, reconnection } = payload!;
        // const me = getMyMember(store.getState());

        // console.log(`${dayjs().format("YYYY-MM-DD HH:mm:ss.SSS Z")} - Connected livekit`);

        const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(store.getState());
        const isStoppingRecordAudioExtraction = getIsStoppingRecordAudioExtraction(store.getState());

        store.dispatch(showModal({ id: RECONNECT_CHANNEL_MODAL_ID, show: false }));

        if (reconnection) {
          store.dispatch(setRoomConnection(ConnectionState.Connected));
        } else if (!isLoadingRecordAudioExtraction) {
          store.dispatch(setRoomConneted(voiceChannelShortId!));
        }

        clearParticipantFromGlobal();

        if (participants) {
          store.dispatch(setParticipants(participants));
          setParticipantsToGlobal(participants);

          for (const remoteParticipant of participants) {
            const userInfo = getUserInfoFromParticipantId(remoteParticipant!.identity);

            store.dispatch(
              setUserParticipantIdentity({
                userId: userInfo.userId!,
                identity: remoteParticipant.identity,
              }),
            );
            if (remoteParticipant?.audioPublication?.track) {
              store.dispatch(
                updateUserMicActive({
                  userId: userInfo.userId!,
                  mute: !!remoteParticipant?.audioPublication.isMuted,
                }),
              );
            }

            // if (remoteParticipant?.videoPublication && me) {
            //   store.dispatch(
            //     updateUserVideoActiveAction({
            //       userId: userInfo.userId!,
            //       workspaceId: me.workspaceId,
            //       videoActive: true,
            //     }),
            //   );
            // }
          }
        }

        if (!reconnection && isStoppingRecordAudioExtraction) {
          store.dispatch(updateStoppingRecordAudioExtractionAction(false));
        }

        break;
      case RoomEvent.Reconnecting: {
        const me = getMyMember(store.getState());

        if (me) {
          store.dispatch(setUserParticipantIdentity({ userId: me.id, identity: undefined }));
        }

        store.dispatch(disconnectRoom(true));
        break;
      }

      case RoomEvent.Disconnected: {
        const { reason, forceDisconnect } = payload!;
        const isJoiningChannel = getIsChannelJoining(store.getState());
        const isJoiningWorkspace = checkIsWorkspaceJoining(store.getState());
        const me = getMyMember(store.getState());
        const socketConnectionStatus = getSocketConnectivityStatus(store.getState());
        const isDuplicated = getDuplicatedLoginStatus(store.getState());

        if (me) {
          store.dispatch(setUserParticipantIdentity({ userId: me.id, identity: undefined }));
        }

        clearParticipantFromGlobal();
        store.dispatch(clearParticipants());
        store.dispatch(setRoomConnection(ConnectionState.Disconnected));
        store.dispatch(doStopScreenShare());

        // console.log(
        //   "Check disconnect status: ",
        //   isJoiningChannel,
        //   isJoiningWorkspace,
        //   reason,
        //   socketConnectionStatus,
        //   forceDisconnect,
        // );
        if (!isJoiningChannel && !isJoiningWorkspace) {
          if (reason !== DisconnectReason.CLIENT_INITIATED && socketConnectionStatus) {
            //  console.log("Disconnected from livekit server. Disconnecting socket");
            store.dispatch(showModal({ id: RECONNECT_CHANNEL_MODAL_ID, show: true }));
            store.dispatch(disconnectSocket());
          } else if (reason === DisconnectReason.CLIENT_INITIATED && !socketConnectionStatus && !isDuplicated) {
            store.dispatch(showModal({ id: RECONNECT_CHANNEL_MODAL_ID, show: true }));
          } else if (
            reason === DisconnectReason.CLIENT_INITIATED &&
            socketConnectionStatus &&
            forceDisconnect &&
            !isDuplicated
          ) {
            store.dispatch(showModal({ id: RECONNECT_CHANNEL_MODAL_ID, show: true }));
          }
        }

        break;
      }

      case RoomEvent.LocalTrackPublished: {
        const { participant } = payload!;
        const me = getMyMember(store.getState());
        const screnshare = getScreenshare(store.getState());
        const hasAudioPermission = getHasAudioPermission(store.getState());

        store.dispatch(setParticipant(participant!));
        setParticipantToGlobal(participant!);

        if (participant?.audioPublication?.track) {
          if (!hasAudioPermission) {
            store.dispatch(setAudioPermission(true));
          }

          store.dispatch(
            sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
              workspaceId: me!.workspaceId,
              mute: participant.audioPublication.isMuted,
            }),
          );
          store.dispatch(
            updateUserMicActive({
              userId: me!?.id,
              mute: participant.audioPublication.isMuted,
            }),
          );
        }

        if (participant?.screensharePublication?.videoTrack && me) {
          store.dispatch(
            updateUserScreenActiveSend({
              workspaceId: me.workspaceId,
              userId: me.id,
              screenActive: true,
              screenIndex: screnshare.mySharedScreen?.screenIndex,
              isDrawingAllowed: false, //Default drawing
            }),
          );
          store.dispatch(showStopSharingWindow());
        }

        break;
      }
      case RoomEvent.LocalTrackUnpublished: {
        const { participant } = payload!;
        const me = getMyMember(store.getState());

        if (!participant?.screensharePublication && me) {
          const screnshare = getScreenshare(store.getState());

          store.dispatch(
            updateUserScreenActiveSend({
              workspaceId: me.workspaceId,
              userId: me.id,
              screenActive: false,
              screenIndex: screnshare.mySharedScreen?.screenIndex,
              isDrawingAllowed: false,
            }),
          );
        }

        setParticipantToGlobal(participant!);
        store.dispatch(setParticipant(participant!));
        break;
      }
      case RoomEvent.TrackPublished: {
        const { participant } = payload!;
        const userInfo = getUserInfoFromParticipantId(participant!.identity);
        const me = getMyMember(store.getState());

        store.dispatch(setParticipant(participant!));
        setParticipantToGlobal(participant!);

        if (participant?.audioPublication?.track) {
          store.dispatch(
            updateUserMicActive({
              userId: userInfo.userId!,
              mute: !!participant.audioPublication.isMuted,
            }),
          );
        }

        if (participant?.videoPublication?.track && me) {
          store.dispatch(
            updateUserVideoActiveAction({
              userId: userInfo.userId!,
              workspaceId: me!.workspaceId,
              videoActive: true,
            }),
          );
        }

        break;
      }
      case RoomEvent.TrackUnpublished: {
        const me = getMyMember(store.getState());
        const { participant } = payload!;
        const userInfo = getUserInfoFromParticipantId(participant!.identity);

        if (!participant?.videoPublication?.track && me) {
          store.dispatch(
            updateUserVideoActiveAction({
              userId: userInfo.userId!,
              workspaceId: me.workspaceId,
              videoActive: false,
            }),
          );
        }

        setParticipantToGlobal(participant!);
        store.dispatch(setParticipant(participant!));
        break;
      }
      case RoomEvent.ParticipantConnected: {
        const { participant } = payload!;
        const me = getMyMember(store.getState());
        const userInfo = getUserInfoFromParticipantId(participant!.identity);

        store.dispatch(setUserParticipantIdentity({ userId: userInfo.userId!, identity: participant!.identity }));
        store.dispatch(setParticipant(participant!));
        setParticipantToGlobal(participant!);

        if (me) {
          store.dispatch(
            channelJoined({
              workspaceId: me.workspaceId,
              voiceChannelId: me.voiceChannelId!,
              userId: userInfo.userId!,
            }),
          );
        }

        break;
      }
      case RoomEvent.ParticipantDisconnected: {
        const { participant } = payload!;
        const userInfo = getUserInfoFromParticipantId(participant!.identity);

        store.dispatch(
          setUserParticipantIdentity({
            userId: userInfo.userId!,
            identity: undefined,
          }),
        );
        store.dispatch(removeParticipant(participant!.identity));
        removeParticipantFromGlobal(participant!.identity);
        break;
      }
      case RoomEvent.TrackMuted: {
        const { participant, source } = payload!;
        const me = getMyMember(store.getState());
        const userInfo = getUserInfoFromParticipantId(participant!.identity);

        store.dispatch(setParticipant(participant!));
        setParticipantToGlobal(participant!);

        if (me && participant?.audioPublication?.track && source === Track.Source.Microphone) {
          if (me?.role !== "meeting-recording-bot" && me.id === userInfo.userId!) {
            store.dispatch(
              sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
                workspaceId: me.workspaceId,
                mute: participant.audioPublication.isMuted,
              }),
            );
            store.dispatch(
              updateUserMicActive({
                userId: userInfo.userId!,
                mute: participant.audioPublication.isMuted,
              }),
            );
          }

          store.dispatch(
            updateUserSpeakerActive({
              userId: userInfo.userId!,
              workspaceId: me.workspaceId,
              speakerActive: false,
            }),
          );
        }

        if (
          me?.workspaceId &&
          me?.id !== userInfo.userId! &&
          participant?.videoPublication?.track &&
          source === Track.Source.Camera
        ) {
          store.dispatch(
            updateUserVideoActiveAction({
              userId: userInfo.userId!,
              workspaceId: me?.workspaceId,
              videoActive: !participant?.videoPublication.isMuted,
            }),
          );
        }

        break;
      }
      case RoomEvent.TrackSubscribed: {
        const participant = payload!.participant!;
        const userInfo = getUserInfoFromParticipantId(participant!.identity);
        const me = getMyMember(store.getState());

        if (participant?.audioPublication?.track) {
          store.dispatch(
            updateUserMicActive({
              userId: userInfo.userId!,
              mute: !!participant?.audioPublication.isMuted,
            }),
          );
        }

        if (participant.videoPublication?.track && me) {
          store.dispatch(
            updateUserVideoActiveAction({
              userId: userInfo.userId!,
              workspaceId: me!.workspaceId,
              videoActive: !participant?.videoPublication.isMuted,
            }),
          );
        }

        store.dispatch(setParticipant(participant));
        setParticipantToGlobal(participant!);
        break;
      }
      case RoomEvent.TrackUnmuted: {
        const { participant, source } = payload!;
        const me = getMyMember(store.getState());
        const userInfo = getUserInfoFromParticipantId(participant!.identity);

        store.dispatch(setParticipant(participant!));
        setParticipantToGlobal(participant!);

        if (participant?.audioPublication?.track && source === Track.Source.Microphone) {
          if (me && me?.role !== "meeting-recording-bot" && me.id === userInfo.userId!) {
            store.dispatch(
              sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
                workspaceId: me.workspaceId,
                mute: participant.audioPublication.isMuted,
              }),
            );
            store.dispatch(
              updateUserMicActive({
                userId: userInfo.userId!,
                mute: participant.audioPublication.isMuted,
              }),
            );
          }
        }

        if (
          me?.workspaceId &&
          me?.id !== userInfo.userId! &&
          participant?.videoPublication?.track &&
          source === Track.Source.Camera
        ) {
          store.dispatch(
            updateUserVideoActiveAction({
              userId: userInfo.userId!,
              workspaceId: me?.workspaceId,
              videoActive: !participant?.videoPublication.isMuted,
            }),
          );
        }

        break;
      }
      case RoomEvent.TrackUnsubscribed: {
        const participant = payload!.participant!;
        const userInfo = getUserInfoFromParticipantId(participant!.identity);
        const me = getMyMember(store.getState());

        if (participant?.videoPublication?.track && me) {
          store.dispatch(
            updateUserVideoActiveAction({
              userId: userInfo.userId!,
              workspaceId: me.workspaceId,
              videoActive: !participant?.videoPublication.isMuted,
            }),
          );
        }

        store.dispatch(setParticipant(participant));
        setParticipantToGlobal(participant!);
        break;
      }
      default:
        break;
    }
  }

  const livekit = new Livekit(onIncomingMessage);

  return (next: any) => async (action: any) => {
    const { type, payload } = action;

    if (type === LivekitActions.CONNECT_LIVEKIT_ROOM) {
      log(LogCategory.Livekit, "Connect to Livekit room");
      store.dispatch(setRoomConnection(ConnectionState.Connecting));
      livekit.connectRoom(payload);
    }

    if (type === LivekitActions.DISCONNECT_LIVEKIT_ROOM) {
      log(LogCategory.Livekit, "Disconnect Livekit room");

      await livekit.disconnectRoom(action.payload.force);
    }

    if (type === LivekitActions.ENABLE_MUTE) {
      log(LogCategory.Livekit, "Enable mute Livekit room");
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());
      const isLoadingRecordAudioExtraction = getIsLoadingRecordAudioExtraction(store.getState());

      if (isRecordingAudioExtraction || isLoadingRecordAudioExtraction) {
        await livekit.publishEgressAudioTrack(undefined, true);

        const me = getMyMember(store.getState());

        if (me) {
          store.dispatch(
            sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
              workspaceId: me.workspaceId,
              mute: true,
            }),
          );
          store.dispatch(
            updateUserMicActive({
              userId: me.id,
              mute: true,
            }),
          );
        }
      } else {
        await livekit.mute();
      }
    }

    if (type === LivekitActions.ENABLE_MIC) {
      log(LogCategory.Livekit, "Publish audio track Livekit room");
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());

      if (isRecordingAudioExtraction) {
        livekit.publishEgressAudioTrack();
      } else {
        await livekit.publishAudioTrack();
      }
    }

    if (type === LivekitActions.DISABLE_MUTE) {
      log(LogCategory.Livekit, "Disable mute Livekit room");
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());

      if (isRecordingAudioExtraction) {
        await livekit.publishEgressAudioTrack(undefined, false);

        const me = getMyMember(store.getState());

        if (me) {
          store.dispatch(
            sendMessage(USER_EVENT_TYPES.USER_OUT_MUTE_REQUEST, {
              workspaceId: me.workspaceId,
              mute: false,
            }),
          );
          store.dispatch(
            updateUserMicActive({
              userId: me.id,
              mute: false,
            }),
          );
        }
      } else {
        livekit.unmute();
      }
    }

    if (type === LivekitActions.ENABLE_VIDEO) {
      log(LogCategory.Livekit, "Enable video Livekit room");
      livekit.publishWebcamTrack(payload.stream);
    }

    if (type === LivekitActions.DISABLE_VIDEO) {
      log(LogCategory.Livekit, "Disable video Livekit room");
      livekit.stopWebcamTrack();
    }

    if (type === LivekitActions.ENABLE_SCREENSHARE) {
      log(LogCategory.Livekit, "Enable screenshare Livekit room", payload.sourceId);
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());
      const isStartingRecordAudioExtraction = getIsStartingRecordAudioExtraction(store.getState());

      if (isRecordingAudioExtraction || isStartingRecordAudioExtraction) {
        await livekit.publishEgressScreenshareTrack(payload.sourceId);
      } else {
        await livekit.publishScreenshareTrack(payload.sourceId);
      }
    }

    if (type === LivekitActions.UPDATE_SCREENSHARE_CONSTRAINTS) {
      log(LogCategory.Livekit, "Update Screenshare Constraints", payload.resolution);

      await livekit.updateScreenshareConstraints(SCREEN_SHARE_MEDIA_CONSTRAINTS[payload.resolution]);
    }

    if (type === LivekitActions.DISABLE_SCREENSHARE) {
      log(LogCategory.Livekit, "Disable screenshare Livekit room");
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());

      if (isRecordingAudioExtraction) {
        await livekit.replaceScreenshareTrack();

        const me = getMyMember(store.getState());

        if (me) {
          const screnshare = getScreenshare(store.getState());

          store.dispatch(
            updateUserScreenActiveSend({
              workspaceId: me.workspaceId,
              userId: me.id,
              screenActive: false,
              screenIndex: screnshare.mySharedScreen?.screenIndex,
              isDrawingAllowed: false,
            }),
          );
        }
      } else {
        livekit.stopScreenshareTrack();
      }
    }

    if (type === LivekitActions.SWITCH_DEVICE) {
      log(LogCategory.Livekit, "Switch audio device Livekit room");
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());

      if (isRecordingAudioExtraction) {
        livekit.publishEgressAudioTrack();
      } else {
        livekit.updateMic();
      }
    }

    if (type === LivekitActions.UPDATE_NOISE_REDUCTION) {
      log(LogCategory.Livekit, "Update noise reduction Livekit room");
      const isRecordingAudioExtraction = getIsRecordingAudioExtraction(store.getState());

      if (isRecordingAudioExtraction) {
        livekit.publishEgressAudioTrack(payload.noiseReduction);
      } else {
        livekit.updateMic(payload.noiseReduction);
      }
    }

    if (type === LivekitActions.ENABLE_EGRESS) {
      log(LogCategory.Livekit, "Publish Egress track to Livekit room");
      const defaultMic = getAudioExtractionDefaultMicStatus(store.getState());

      await livekit.stopAudioTrack();
      await livekit.publishEgressAudioTrack(undefined, !defaultMic);
    }

    return next(action);
  };
};
