import dataUriToBuffer from "data-uri-to-buffer";
import sha1 from "sha1";

export function getIconHashAndType(iconDataURI: string | null) {
  if (!iconDataURI) {
    return null;
  }

  const icon = dataUriToBuffer(iconDataURI);
  const hash = sha1(icon);

  return {
    hash,
    type: icon.type,
  };
}
