import {
  approvedRemoteDesktopControlResponse,
  declinedRemoteDesktopControlResponse,
  requestRemoteDesktopControlResponse,
  ScreenSize,
  stopControllingMyDesktopResponse,
  stopControllingRemoteDesktopResponse,
} from "./actions";

const REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX = "remote-desktop-control/";

export const EVENT_TYPES = {
  IN_REQUEST_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}control-request-response`,
  OUT_REQUEST_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}control-request-request`,
  IN_REQUEST_APPROVED_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}approve-response`,
  OUT_REQUEST_APPROVED_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}approve-request`,
  IN_REQUEST_DELCINE_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}decline-response`,
  OUT_REQUEST_DECLINE_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}decline-request`,
  IN_STOP_MY_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}stop-my-desktop-control-response`,
  OUT_STOP_MY_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}stop-my-desktop-control-request`,
  IN_STOP_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}stop-remote-desktop-control-response`,
  OUT_STOP_REMOTE_DESKTOP_CONTROL: `${REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX}stop-remote-desktop-control-request`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(REMOTE_DESKTOP_CONTROL_EVENT_TYPE_PREFIX)) {
    return;
  }

  const {
    requestFromName,
    requestFromUserId,
    requestToName,
    requestToUserId,
    remoteUserSkyWayPeerId,
    isAlreadyControlledByAnotherUser,
    remoteUserScreenSize,
  }: {
    requestToUserId: number;
    requestFromUserId: number;
    requestFromName: string;
    requestToName: string;
    position: {
      x: number;
      y: number;
    };
    buttonType: "left" | "right";
    toggleType: "up" | "down";
    key: string;
    state: "up" | "down";
    remoteUserSkyWayPeerId: string;
    isAlreadyControlledByAnotherUser: boolean;
    remoteUserScreenSize: ScreenSize;
  } = payload;

  switch (type) {
    case EVENT_TYPES.IN_REQUEST_REMOTE_DESKTOP_CONTROL:
      store.dispatch(
        requestRemoteDesktopControlResponse({
          requestFromName,
          requestFromUserId,
          requestToName,
          requestToUserId,
        }),
      );
      break;
    case EVENT_TYPES.IN_REQUEST_APPROVED_REMOTE_DESKTOP_CONTROL:
      store.dispatch(
        approvedRemoteDesktopControlResponse({
          requestFromName,
          requestFromUserId,
          requestToName,
          requestToUserId,
          remoteUserSkyWayPeerId,
          remoteUserScreenSize,
        }),
      );
      break;
    case EVENT_TYPES.IN_REQUEST_DELCINE_REMOTE_DESKTOP_CONTROL:
      store.dispatch(declinedRemoteDesktopControlResponse(isAlreadyControlledByAnotherUser));
      break;
    case EVENT_TYPES.IN_STOP_MY_DESKTOP_CONTROL:
      store.dispatch(stopControllingMyDesktopResponse());
      break;
    case EVENT_TYPES.IN_STOP_REMOTE_DESKTOP_CONTROL:
      store.dispatch(stopControllingRemoteDesktopResponse());
      break;
  }
};
