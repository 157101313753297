import * as React from "react";
import "./LoaderWithDescription.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { sendMessageOverIPC } from "../../../electron/sendMessageOverIPC";
import { channels } from "../../../electron/channels";
import { LOADER_TIMEOUT_DURATION } from "../../../constant";
import { isMobile } from "../../../utils/helpers";

interface LoaderWithDescriptionProps {
  reloadOnTimeout?: boolean;
  className?: string;
}
export function LoaderWithDescription({ reloadOnTimeout, className }: LoaderWithDescriptionProps) {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  React.useEffect(() => {
    let timeout: number = 0;

    if (reloadOnTimeout) {
      timeout = window.setTimeout(async () => {
        if (window.electron) {
          await sendMessageOverIPC(channels.RELOAD_APP);
        } else {
          window.location.href = "/dashboard";
        }
      }, LOADER_TIMEOUT_DURATION);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [reloadOnTimeout]);
  return (
    <div className={`loader ${className} `}>
      <span className="loader-description">
        <FormattedMessage id="loading" defaultMessage="Loading" />
      </span>
      <Spin indicator={antIcon} />
      <div className="loading-help-text">
        {isMobile.Android() || isMobile.iOS() || isMobile.iPad() ? (
          <FormattedMessage
            id="mobile-loading-help-text"
            defaultMessage="Refresh the page if loading doesn’t finish."
          />
        ) : (
          <FormattedMessage id="loading-help-text" defaultMessage="Press F5 if loading does not finish" />
        )}
      </div>
    </div>
  );
}
