import Cookies from "js-cookie";

const ETHERPAD_SERVER_API_URL = process.env.REACT_APP_ETHERPAD_SERVER_API_URL;
const ETHERPAD_API_KEY = process.env.REACT_APP_ETHERPAD_API_KEY;
const ETHERPAD_COOKIE_DOMAIN = process.env.REACT_APP_ETHERPAD_COOKIE_DOMAIN;

const createUrlWithQueryParams = (apiUrl: String, params: any) => {
  const apiEndpoint = `${ETHERPAD_SERVER_API_URL}${apiUrl}`;

  params.apikey = ETHERPAD_API_KEY;
  const url = new URLSearchParams(params);

  return `${apiEndpoint}?${url.toString()}`;
};

const defaultHeader = {
  Accept: "application/json",
  "Content-Type": "application/x-www-form-urlencoded",
};

export async function deleteEtherpadSession(etherpadSessionId: string) {
  try {
    const params = {
      sessionID: etherpadSessionId,
    };
    const urlDeleteSession = createUrlWithQueryParams("/deleteSession", params);
    const response = await fetch(urlDeleteSession, {
      method: "POST",
      headers: defaultHeader,
    });

    if (response.status === 200) {
      const result = await response.json();

      if (result.code === 0) {
        Cookies.remove("currentEtherpadSessionID");
        Cookies.remove("sessionID");
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export async function createEtherpadSession(etherpadGroupId: string, etherpadAuthorId: string) {
  try {
    const params = {
      groupID: etherpadGroupId,
      authorID: etherpadAuthorId,
      validUntil: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 365 * 3,
    };
    const urlCreateSession = createUrlWithQueryParams("/createSession", params);
    const response = await fetch(urlCreateSession, {
      method: "POST",
      headers: defaultHeader,
    });

    if (response.status === 200) {
      const result = await response.json();

      if (result.code === 0) {
        const oldSessionId = Cookies.get("currentEtherpadSessionID");

        if (oldSessionId) {
          await deleteEtherpadSession(oldSessionId);
        }

        Cookies.set("currentEtherpadSessionID", result.data.sessionID, { expires: 365 * 100 });
        Cookies.set("sessionID", result.data.sessionID, { expires: 365 * 100, domain: ETHERPAD_COOKIE_DOMAIN });
        return result.data.sessionID;
      } else {
        return false;
      }
    } else {
      throw new Error(
        `Can't create Etherpad SESSION with "GroupID: ${etherpadGroupId}, AuthorID: ${etherpadAuthorId}". Error: ${response.status}`,
      );
    }
  } catch (err) {
    console.error(err);
  }
}

export async function appendEtherpadText(padId: string, username: string, appendText: string) {
  try {
    const params = {
      padID: padId,
      text: `${username}: ${appendText}\n`,
    };
    const urlAppendText = createUrlWithQueryParams("/appendText", params);
    const response = await fetch(urlAppendText, {
      method: "POST",
      headers: defaultHeader,
    });

    if (response.status === 200) {
      const result = await response.json();

      if (result.code === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      throw new Error(`Can't append text to "PadID: ${padId}". Error: ${response.status}`);
    }
  } catch (err) {
    console.error(err);
  }
}

export async function getRevisionCount(padID: string) {
  try {
    const urlAppendText = createUrlWithQueryParams("/getRevisionsCount", { padID });
    const response = await fetch(urlAppendText, {
      method: "GET",
      headers: defaultHeader,
    });

    if (response.status === 200) {
      const result = await response.json();

      if (result.code === 0) {
        return result.data.revisions;
      } else {
        return -1;
      }
    } else {
      throw new Error(`Can't append text to "PadID: ${padID}". Error: ${response.status}`);
    }
  } catch (err) {
    console.error(err);
  }
}
