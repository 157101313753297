import "core-js";
import "mdn-polyfills/Object.assign";
import "es6-promise/auto";
import cssVars from "css-vars-ponyfill";

if (!("PluralRules" in Intl)) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/ja"); // Add locale data for ja
}

if (!("RelativeTimeFormat" in Intl)) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/ja"); // Add locale data for ja
}

//@ts-ignore
if (!window.crypto) window.crypto = window.msCrypto;

cssVars({
  // Targets
  rootElement: document,
  shadowDOM: false,

  // Sources
  include: "link[rel=stylesheet],style",
  exclude: "",
  variables: {},

  // Options
  onlyLegacy: true,
  preserveStatic: true,
  preserveVars: false,
  silent: false,
  updateDOM: true,
  updateURLs: true,
  watch: false,
});
