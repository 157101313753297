import { TimeTrackerAction as Action, TimeTrackerActionType as ActionType } from "./actions";

export { reducer as timeTrackerReducer, initialState as timeTrackerInitialState };
export type { State as TimeTrackerState };

type TimeTrackerSession = {
  userId: number;
  projectId: number;
  workspaceId: number;
  clientTimeTrackingId: string;
  serverTimeTrackingId: number | null;
  pingInterval: number;
  startTime: string;
};

type TotalWorkedTime = {
  timezoneOffset: number;
  totalSecondsPending: number;
  totalSecondsSynced: number;
};

const initialState = {
  working: false,
  offline: false,
  description: "",
  project: undefined as number | undefined,
  localClockMillisecondsSlowness: undefined as number | undefined,
  currentSession: undefined as TimeTrackerSession | undefined,
  currentPeriod: {
    timezoneOffset: 0,
    totalSecondsPending: 0,
    totalSecondsSynced: 0,
  } as TotalWorkedTime,
  loading: true,
  editingDescription: false,
  isPingOnFlying: false,
  todayTotalSecond: 0 as number,
};

type State = typeof initialState;

function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ActionType.LOADED_INITIAL_DATA:
      return {
        ...state,
        loading: false,
        localClockMillisecondsSlowness:
          action.payload.localClockMillisecondsSlowness ?? state.localClockMillisecondsSlowness,
        currentPeriod: {
          timezoneOffset: action.payload.timezoneOffset,
          totalSecondsSynced: action.payload.totalSecondsSynced ?? 0,
          totalSecondsPending: action.payload.totalSecondsPending ?? 0,
        },
        currentSession:
          state.currentSession && action.payload.startTime
            ? {
                ...state.currentSession,
                startTime: action.payload.startTime,
              }
            : state.currentSession,
        todayTotalSecond: action.payload.todayTotalSecond ?? 0,
      };
    case ActionType.UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description,
      };

    case ActionType.UPDATE_PROJECT:
      return {
        ...state,
        project: action.payload.projectId,
      };
    case ActionType.START_WORKING_REQUEST:
      return {
        ...state,
        loading: !state.offline,
        working: true,
        currentSession: {
          serverTimeTrackingId: null,
          clientTimeTrackingId: action.payload.clientTimeTrackingId,
          userId: action.payload.userId,
          projectId: action.payload.projectId,
          workspaceId: action.payload.workspaceId,
          pingInterval: action.payload.pingInterval,
          startTime: action.payload.startTime,
        },
      };
    case ActionType.START_WORKING_RESPONSE:
      return {
        ...state,
        loading: false,
        currentSession: {
          ...state.currentSession!,
          serverTimeTrackingId: action.payload.serverTimeTrackingId,
        },
      };
    case ActionType.SWITCH_TO_ONLINE_MODE:
      return {
        ...state,
        offline: false,
        currentSession:
          state.currentSession && action.payload.clientTimeTrackingId
            ? {
                ...state.currentSession,
                clientTimeTrackingId: action.payload.clientTimeTrackingId,
                serverTimeTrackingId: null,
              }
            : state.currentSession,
      };
    case ActionType.SWITCH_TO_OFFLINE_MODE:
      return {
        ...state,
        offline: true,
        loading: false,
        currentSession:
          state.currentSession && action.payload.clientTimeTrackingId
            ? {
                ...state.currentSession,
                clientTimeTrackingId: action.payload.clientTimeTrackingId,
                serverTimeTrackingId: null,
              }
            : state.currentSession,
      };
    case ActionType.STOP_WORKING_REQUEST:
      return {
        ...state,
        loading: !state.offline,
        working: false,
        currentSession: undefined,
        currentPeriod: {
          ...state.currentPeriod!,
          totalSecondsPending: state.currentPeriod!.totalSecondsPending + action.payload.sessionTotalSeconds,
        },
      };
    case ActionType.STOP_WORKING_RESPONSE:
      return {
        ...state,
        loading: false,
      };
    case ActionType.PING_WORKING_REQUEST:
      return state.currentSession && state.currentSession.clientTimeTrackingId !== action.payload.clientTimeTrackingId
        ? {
            ...state,
            currentSession: {
              ...state.currentSession,
              clientTimeTrackingId: action.payload.clientTimeTrackingId,
              serverTimeTrackingId: null,
            },
          }
        : state;
    case ActionType.PING_WORKING_RESPONSE:
      return state.currentSession &&
        (state.currentSession.serverTimeTrackingId !== action.payload.serverTimeTrackingId ||
          state.currentSession.clientTimeTrackingId !== action.payload.clientTimeTrackingId)
        ? {
            ...state,
            currentSession: {
              ...state.currentSession,
              serverTimeTrackingId: action.payload.serverTimeTrackingId,
              clientTimeTrackingId: action.payload.clientTimeTrackingId || state.currentSession.clientTimeTrackingId,
            },
          }
        : state;
    case ActionType.TIMER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.UPDATE_IS_PING_ON_FLYING:
      return {
        ...state,
        isPingOnFlying: action.payload.status,
      };
    default:
      return state;
  }
}
