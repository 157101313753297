import dayjs from "dayjs";
import { nanoid } from "nanoid";
import { offlineTrackingStorage as storage } from "./offlineTrackingStorage";

export interface LocalTimeSlot {
  userId: number;
  projectId: number;
  workspaceId: number;
  clientTimeTrackingId: string;
  serverTimeTrackingId: number[];
  online: boolean;
  description: string;
  startTime: string;
  endTime: string | null;
  pingRequestCount: number;
  pingResponseCount: number;
  lastPingRequestTime: string | null;
  lastPingResponseTime: string | null;
  syncErrorCount: number;
  syncErrorMessages: string[];
}

export interface OfflineTimeSlotToSync {
  adjacentSlots: {
    previous?: AdjacentSlot;
    next?: AdjacentSlot;
  };
  syncReason: SyncReason;
  clientTimeTrackingId: string;
  description: string;
  localStartTime: string;
  localEndTime: string;
  pingCount: number;
}

interface AdjacentSlot {
  serverId?: number;
  clientId: string;
}

type SyncReason = "tracked-offline" | "missing-server-id";

export const PING_INTERVAL_VALUE = 60_000;
export const PING_RESPONSE_DELAY = 5_000;
const ALLOWED_LATE_PING_THRESHOLD = 20_000;

/**
 * Offline work business logic
 */
export const offlineTrackingService = {
  async closeOpenedSlots(userId: number, projectId: number, workspaceId: number) {
    const allUnfinishedSlots = await storage.listAllUnfinished(userId, projectId, workspaceId);

    await Promise.all(
      allUnfinishedSlots.map(async slot => {
        if (slot.lastPingRequestTime === null) {
          // record didn't have the first ping, we should discard
          await storage.delete(slot);
        } else {
          slot.endTime = slot.lastPingRequestTime;
          await storage.update(slot);
        }
      }),
    );
  },

  async updateServerIdsOnSyncedOfflineSlots(
    userId: number,
    projectId: number,
    workspaceId: number,
    serverIdsOfSyncedSlots: Record<string, number[]>,
    syncError: string | undefined,
  ) {
    const allData = await storage.listAll(userId, projectId, workspaceId);
    const allDataById = Object.fromEntries(allData.map(slot => [slot.clientTimeTrackingId, slot]));

    for (const [clientId, serverIds] of Object.entries(serverIdsOfSyncedSlots)) {
      const slot = allDataById[clientId];

      slot.serverTimeTrackingId = serverIds;

      if (syncError !== undefined) {
        slot.syncErrorMessages.push(syncError);
        slot.syncErrorCount++;
      }

      await storage.update(slot);
    }
  },

  async listOfflineDataToSync(
    userId: number,
    projectId: number,
    workspaceId: number,
  ): Promise<OfflineTimeSlotToSync[]> {
    const allData = await storage.listAll(userId, projectId, workspaceId);

    // Group slots by working session
    // Keeps sub-arrays that all elements have:
    //  - an `endTime` equal to the next element `startTime`
    const groups = allData
      .reduce(([currentGroup, ...previousGroups], currentSlot) => {
        if (!currentGroup) {
          return [[currentSlot]];
        }

        const lastSlotOnCurrentGroup = currentGroup[currentGroup.length - 1];
        const currentSlotBelongsToCurrentGroup = lastSlotOnCurrentGroup.endTime === currentSlot.startTime;

        return currentSlotBelongsToCurrentGroup
          ? [[...currentGroup, currentSlot], ...previousGroups]
          : [[currentSlot], currentGroup, ...previousGroups];
      }, [] as LocalTimeSlot[][])
      .reverse();

    const result: OfflineTimeSlotToSync[] = [];

    for (const group of groups) {
      for (let i = 0; i < group.length; i++) {
        const slot = group[i];
        const onlineWithServerId = slot.online && slot.serverTimeTrackingId.length > 0;
        const alreadySynced = slot.serverTimeTrackingId.length > 0;
        const tooManyErrors = slot.syncErrorCount >= 3;

        if (onlineWithServerId || alreadySynced || tooManyErrors) {
          continue;
        }

        if (!slot.endTime) {
          // This should never happen. There is a call to `closeOpenedSlots` to avoid this.
          throw new Error("Invalid time slot without the endTime field.");
        }

        const syncReason: SyncReason | undefined = !slot.online
          ? "tracked-offline"
          : slot.serverTimeTrackingId.length === 0
          ? "missing-server-id"
          : undefined;

        if (!syncReason) {
          // This should never happen. We should only sync slots that are:
          // - off-line
          // - on-line, but doesn't have the server id
          throw new Error("Invalid sync reason.");
        }

        const previousSlot = i - 1 >= 0 ? group[i - 1] : undefined;
        const nextSlot = i + 1 < group.length ? group[i + 1] : undefined;

        result.push({
          adjacentSlots: {
            previous: previousSlot && {
              serverId: previousSlot.serverTimeTrackingId?.[previousSlot.serverTimeTrackingId.length - 1],
              clientId: previousSlot.clientTimeTrackingId,
            },
            next: nextSlot && {
              serverId: nextSlot.serverTimeTrackingId?.[0],
              clientId: nextSlot.clientTimeTrackingId,
            },
          },
          syncReason,
          clientTimeTrackingId: slot.clientTimeTrackingId,
          description: slot.description,
          localStartTime: slot.startTime,
          localEndTime: slot.endTime,
          pingCount: slot.pingRequestCount,
        });
      }
    }

    return result;
  },

  async getSlotStartTime(userId: number, projectId: number, workspaceId: number, clientTimeTrackingId: string) {
    const currentSlot = await storage.get(userId, projectId, workspaceId, clientTimeTrackingId);

    return currentSlot?.startTime;
  },

  async loadInitialData(
    userId: number,
    projectId: number,
    workspaceId: number,
    timezoneOffset: number,
    currentClientId: string | undefined,
  ) {
    const startOfCurrentDay = dayjs()
      .utc()
      .subtract(timezoneOffset, "minutes")
      .startOf("day")
      .add(timezoneOffset, "minutes")
      .toISOString();

    const dataForCurrentDay = await storage.listStartingFrom(userId, projectId, workspaceId, startOfCurrentDay);

    const totalSecondsPerSlot = (slot: LocalTimeSlot) => {
      if (!slot.endTime) {
        if (slot.clientTimeTrackingId === currentClientId) {
          return 0;
        }

        throw new Error("It should be calling closeOpenedSlots before loading initial data.");
      }

      return dayjs(slot.endTime).diff(slot.startTime, "milliseconds") / 1000;
    };

    const totalSecondsPending = dataForCurrentDay
      .filter(slot => !slot.online && slot.serverTimeTrackingId.length === 0)
      .map(slot => totalSecondsPerSlot(slot))
      .reduce((sum, curr) => sum + curr, 0);

    const totalSecondsSynced = dataForCurrentDay
      .filter(slot => slot.online || slot.serverTimeTrackingId.length > 0)
      .map(slot => totalSecondsPerSlot(slot))
      .reduce((sum, curr) => sum + curr, 0);

    const lastDescription = dataForCurrentDay.length
      ? dataForCurrentDay.reduce((prev, curr) => (prev.startTime < curr.startTime ? curr : prev)).description
      : "";

    return {
      totalSecondsPending,
      totalSecondsSynced,
      lastDescription,
    };
  },

  async startWorking(userId: number, projectId: number, workspaceId: number, description: string) {
    const startTime = dayjs().utc().toISOString();
    const newTimeSlot = createNewTimeSlot(userId, projectId, workspaceId, description, startTime);

    await storage.insert(newTimeSlot);

    return { clientTimeTrackingId: newTimeSlot.clientTimeTrackingId };
  },

  async startWorkingServerId(
    userId: number,
    projectId: number,
    workspaceId: number,
    clientTimeTrackingId: string,
    serverTimeTrackingId: number,
  ) {
    const slot = await storage.get(userId, projectId, workspaceId, clientTimeTrackingId);

    slot.serverTimeTrackingId.push(serverTimeTrackingId);
    slot.online = true;

    await storage.update(slot);
  },

  async switchOnlineOfflineMode(
    userId: number,
    projectId: number,
    workspaceId: number,
    clientTimeTrackingId: string,
    online: boolean,
  ) {
    const currentSlot = await storage.get(userId, projectId, workspaceId, clientTimeTrackingId);
    const hasDisconnected = currentSlot.online && !online;
    const hasReconnected = !currentSlot.online && online;

    if (hasDisconnected || hasReconnected) {
      const splitTime = dayjs().utc().toISOString();

      currentSlot.endTime = getConsistentEndTime(currentSlot, splitTime);
      await storage.update(currentSlot);

      const newTimeSlot = createNewTimeSlot(userId, projectId, workspaceId, currentSlot.description, splitTime);

      newTimeSlot.online = online;
      await storage.insert(newTimeSlot);

      return { clientTimeTrackingId: newTimeSlot.clientTimeTrackingId };
    } else {
      return {};
    }
  },

  async pingWorkingRequestSent(
    userId: number,
    projectId: number,
    workspaceId: number,
    clientTimeTrackingId: string,
    online: boolean,
    description: string,
  ) {
    const currentSlot = await storage.get(userId, projectId, workspaceId, clientTimeTrackingId);
    const splitTime = dayjs().utc().toISOString();
    const consistentEndTime = getConsistentEndTime(currentSlot, splitTime);
    const localMidnightCrossOffline = !online && dayjs(splitTime).date() !== dayjs(currentSlot.startTime).date();

    if (currentSlot.description !== description || consistentEndTime !== splitTime || localMidnightCrossOffline) {
      let endTime = consistentEndTime;
      let startTime = splitTime;

      if (localMidnightCrossOffline && consistentEndTime === splitTime) {
        endTime = startTime = dayjs(splitTime).startOf("day").toISOString();
      }

      currentSlot.endTime = endTime;
      await storage.update(currentSlot);

      const newTimeSlot = createNewTimeSlot(userId, projectId, workspaceId, description, startTime);

      newTimeSlot.online = online;
      newTimeSlot.pingRequestCount = 1;
      newTimeSlot.lastPingRequestTime = newTimeSlot.startTime;
      await storage.insert(newTimeSlot);

      return {
        clientTimeTrackingId: newTimeSlot.clientTimeTrackingId,
        newDescription: description,
        localMidnightCrossOffline,
      };
    } else {
      currentSlot.pingRequestCount = currentSlot.pingRequestCount + 1;
      currentSlot.lastPingRequestTime = dayjs().utc().toISOString();

      await storage.update(currentSlot);

      const newDescription = currentSlot.description;

      return { clientTimeTrackingId, newDescription, localMidnightCrossOffline: false };
    }
  },

  async pingWorkingResponseReceived(
    userId: number,
    projectId: number,
    workspaceId: number,
    clientTimeTrackingId: string,
    serverTimeTrackingId: number,
    serverMidnightCrossOnline: boolean,
  ) {
    const currentSlot = await storage.get(userId, projectId, workspaceId, clientTimeTrackingId);

    currentSlot.pingResponseCount = currentSlot.pingResponseCount + 1;
    currentSlot.lastPingResponseTime = dayjs().utc().toISOString();
    currentSlot.online = true;

    let splitTime: string | null = null;

    if (serverMidnightCrossOnline) {
      splitTime = dayjs.utc(currentSlot.startTime).local().add(1, "day").startOf("day").utc().toISOString();
    } else if (currentSlot.serverTimeTrackingId.lastIndexOf(serverTimeTrackingId) === -1) {
      currentSlot.serverTimeTrackingId.push(serverTimeTrackingId);
    }

    currentSlot.endTime = splitTime;

    await storage.update(currentSlot);

    if (splitTime) {
      const newTimeSlot = createNewTimeSlot(userId, projectId, workspaceId, currentSlot.description, splitTime);

      newTimeSlot.serverTimeTrackingId = [serverTimeTrackingId];
      newTimeSlot.online = true;
      newTimeSlot.pingRequestCount = 1;
      newTimeSlot.lastPingRequestTime = newTimeSlot.startTime;
      await storage.insert(newTimeSlot);

      return { clientTimeTrackingId: newTimeSlot.clientTimeTrackingId };
    } else {
      return { clientTimeTrackingId };
    }
  },

  async stopWorking(userId: number, projectId: number, workspaceId: number, clientTimeTrackingId: string) {
    const currentSlot = await storage.get(userId, projectId, workspaceId, clientTimeTrackingId);
    const stopTime = dayjs().utc().toISOString();

    currentSlot.endTime = getConsistentEndTime(currentSlot, stopTime);

    await storage.update(currentSlot);

    return { clientTimeTrackingId };
  },
};

function getConsistentEndTime(slot: LocalTimeSlot, desiredEndTime: string): string {
  const lastPingTime = slot.lastPingRequestTime || slot.startTime;
  const lastPingDiff = dayjs(desiredEndTime).utc().diff(lastPingTime, "ms");
  const isEndTimeConsistent = lastPingDiff <= PING_INTERVAL_VALUE + ALLOWED_LATE_PING_THRESHOLD;

  return isEndTimeConsistent ? desiredEndTime : lastPingTime;
}

function createNewTimeSlot(
  userId: number,
  projectId: number,
  workspaceId: number,
  description: string,
  startTime: string,
): LocalTimeSlot {
  return {
    userId,
    projectId,
    workspaceId,
    clientTimeTrackingId: nanoid(),
    serverTimeTrackingId: [],
    online: false,
    description,
    startTime,
    endTime: null,
    pingRequestCount: 0,
    pingResponseCount: 0,
    lastPingRequestTime: null,
    lastPingResponseTime: null,
    syncErrorCount: 0,
    syncErrorMessages: [],
  };
}
