import { createIntl, createIntlCache, IntlShape } from "react-intl";

import { displayLanguages } from "../utils/languages";
import en from "./languages/en.json";
import ja from "./languages/ja.json";
import vi from "./languages/vi.json";
import ko from "./languages/ko.json";

type Message = typeof en[number];

function convertToKeyValuePair(strings: Message[]) {
  return Object.fromEntries(strings.map(str => [str.id, str.defaultMessage]));
}

export const messages = {
  en: convertToKeyValuePair(en),
  ja: convertToKeyValuePair(ja),
  vi: convertToKeyValuePair(vi),
  ko: convertToKeyValuePair(ko),
};

export type Language = keyof typeof messages;
export const defaultLanguage = displayLanguages.default;

const intlCache = createIntlCache();

export let intl: IntlShape;

export function makeIntl(language: Language) {
  intl = createIntl(
    {
      locale: language,
      messages: messages[language],
    },
    intlCache,
  );
  return intl;
}
