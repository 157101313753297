import { LINUX_DOWNLOAD, WINDOW_DOWNLOAD, MAC_DOWNLOAD, S_BUCKET_URL } from "../constant";
import { getCurrentEnvironment } from "./environment";
import { getDeviceInfo } from "./helpers";

export function downloadApp() {
  const URL = getDownloadAppLink();

  if (URL) {
    if (window.electron) {
      window.electron.shell.openExternal(URL);
    } else {
      window.location.href = URL;
    }
  }
}

export function getDownloadAppLink() {
  let { os } = getDeviceInfo();

  if (os) {
    let executableName;

    if (os === "windows") {
      executableName = WINDOW_DOWNLOAD;
    } else if (os === "macos") {
      executableName = MAC_DOWNLOAD;
    } else if (os === "linux") {
      executableName = LINUX_DOWNLOAD;
    } else {
      return;
    }

    const environment = getCurrentEnvironment() === "production" ? "production" : "development";

    return `${S_BUCKET_URL}/${environment}/${executableName}`;
  }
}
