import React, { ReactNode } from "react";
import { NotificationVariant } from "./Notification";

interface Props {
  type: NotificationVariant;
  description: ReactNode;
  knockers: string[] | undefined;
}
export function NotificationDescription({ type, description, knockers }: Props) {
  if (type === "knock") {
    return (
      <>
        <div className="name-line hide" id="knocker-names">
          <p className="knocker-list">{knockers && knockers.join(",")}</p>
        </div>
        <div className="description show" id="knock-list">
          {description}
        </div>
      </>
    );
  } else {
    return <>{description}</>;
  }
}
