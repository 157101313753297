import { AppThunk } from "../types";
import { ActionsUnion, createAction } from "../../utils/redux";
import { FloorObjectDetail } from "../../screens/Dashboard/OfficeBackgroundCustomize/Components/UploadButton";
import { WORK_CHANNEL_EVENT_TYPES } from "./middleware";
import { sendMessage } from "../socket";
import { getWorkChannelBaseInfo } from "../../api/workChannel";
import { getCurrentWorkspaceId, getMyMember } from "../users";
import {
  createdWorkspaceBackground,
  createdWorkspaceBackgroundAction,
  WorkspaceBackground,
} from "../workspaceBackground";
import {
  CroppingImageInfo,
  Direction,
  FloorObject,
  FloorObjectCategory,
  PanelType,
  PreviewType,
  WorkChannel,
  WorkChannelEditHistory,
  WorkChannelHistoryAcitonType,
  WorkChannelSubversion,
  WorkChannelSubversionDetail,
  WorkChannelTemplate,
  WorkChannelTemplateDetail,
} from "./reducer";
import {
  ImageWorkChannelObject,
  Position,
  SelectionArea,
  Size,
  TextWorkChannelObject,
  WorkChannelObject,
  WorkChannelObjectPermission,
  WorkChannelObjectPermissions,
  WorkChannelObjectType,
} from "./types";
import {
  getCroppingImageInfo,
  getCurrentWorkChannel,
  getCurrentWorkChannelId,
  getFirstRevertedEditHistory,
  getFloorObjectById,
  getIsRedoingOrUndoing,
  getLastActiveEditHistory,
  getMultiDraggingDelta,
  getPreviewDetail,
  getSelectedWorkChannelObjects,
  getWorkChannelObjectById,
  getWorkChannelObjectIds,
  getWorkChannelObjectsMaxOrder,
} from "./selectors";
import { nanoid } from "nanoid";
import { setIsCustomizingOfficeBackground } from "../app";
import { channelBackgroundUpdated, updateIsFromCustomBackground } from "../voiceChannels";
import {
  WORK_CHANNEL_CUSTOM_CATEGORY_ID,
  WORK_CHANNEL_FONT_SIZE_LIMIT,
  WORK_CHANNEL_TEXT_OBJECT_DEFAULT_SIZE,
} from "../../constant";
import { Toast } from "../../components/antd/Toast";
import { intl } from "../../i18n";
import { convertSizeBySideWay } from "../../utils/helpers";

enum ActionType {
  START_LOADING = "workChannel/START_LOADING",
  END_LOADING = "workChannel/END_LOADING",
  SET_BASE_INFO = "workChannel/SET_BASE_INFO",
  CREATED_CUSTOM_OBJECT = "workChannel/CREATED_CUSTOM_OBJECT",
  DELETED_CUSTOM_OBJECT = "workChannel/DELETED_CUSTOM_OBJECT",
  CREATED_CUSTOM_BACKGROUND = "workChannel/CREATED_CUSTOM_BACKGROUND",
  DELETED_CUSTOM_BACKGROUND = "workChannel/DELETED_CUSTOM_BACKGROUND",
  JOINED_WORK_CHANNEL = "workChannel/JOINED",
  UPDATE_SELECTED_FLOOR_OBJECT_ID = "workChannel/UPDATE_SELECTED_FLOOR_OBJECT_ID",
  UPDATE_SELECTED_BASE_WORKSPACE_BACKGROUND_ID = "workChannel/UPDATE_SELECTED_BASE_WORKSPACE_BACKGROUND_ID",
  UPDATE_CURRENT_PANEL = "workChannel/UPDATE_CURRENT_PANEL",
  ADD_WORK_CHANNEL_OBJECT = "workChannel/ADD_WORK_CHANNEL_OBJECT",
  UPDATE_WORK_CHANNEL_OBJECT = "workChannel/UPDATE_WORK_CHANNEL_OBJECT",
  DELETE_WORK_CHANNEL_OBJECT = "workChannel/DELETE_WORK_CHANNEL_OBJECT",
  ADD_TO_SELECTED_WORK_CHANNEL_OBJECT_IDS = "workChannel/ADD_TO_SELECTED_WORK_CHANNEL_OBJECT_IDS",
  UPDATE_SELECTED_WORK_CHANNEL_OBJECT_ID = "workChannel/UPDATE_SELECTED_WORK_CHANNEL_OBJECT_ID",
  UPDATE_SELECTED_WORK_CHANNEL_OBJECT_IDS = "workChannel/UPDATE_SELECTED_WORK_CHANNEL_OBJECT_IDS",
  CLEAR_SELECTED_WORK_CHANNEL_OBJECT_IDS = "workChannel/CLEAR_SELECTED_WORK_CHANNEL_OBJECT_IDS",
  UPDATE_BASE_WORKSPACE_BACKGROUND = "workChannel/UPDATE_BASE_WORKSPACE_BACKGROUND",
  UPDATE_DRAGGING_AND_RESIZING_OBJECT = "workChannel/UPDATE_DRAGGING_AND_RESIZING_OBJECT",
  UPDATE_NEAR_BORDER_EXTENDS = "workChannel/UPDATE_NEAR_BORDER_EXTENDS",
  ADD_MULTI_WORK_CHANNEL_OBJECTS = "workChannel/ADD_MULTI_WORK_CHANNEL_OBJECTS",
  DELETE_MULTI_WORK_CHANNEL_OBJECTS = "workChannel/DELETE_MULTI_WORK_CHANNEL_OBJECTS",
  UPDATE_MULTI_WORK_CHANNEL_OBJECTS = "workChannel/UPDATE_MULTI_WORK_CHANNEL_OBJECTS",
  ADD_EDIT_HISTORY = "workChannel/ADD_EDIT_HISTORY",
  UPDATE_EDIT_HISTORY = "workChannel/UPDATE_EDIT_HISTORY",
  ADD_WORK_CHANNEL_SUBVERSION = "workChannel/ADD_WORK_CHANNEL_SUBVERSION",
  DELETE_WORK_CHANNEL_SUBVERSION = "workChannel/DELETE_WORK_CHANNEL_SUBVERSION",
  RESTORE_WORK_CHANNEL_SUBVERSION = "workChannel/RESTORE_WORK_CHANNEL_SUBVERSION",
  PREVIEW_WORK_CHANNEL_SUBVERSION = "workChannel/PREVIEW_WORK_CHANNEL_SUBVERSION",
  UPDATE_IS_PREVIEW = "workChannel/UPDATE_IS_PREVIEW",
  UPDATE_ARROW_KEY_PRESS = "workChannel/UPDATE_ARROW_KEY_PRESS",
  CLEARE_ARROW_KEY_PRESS_DELTA = "workChannel/CLEARE_ARROW_KEY_PRESS_DELTA",
  UPDATE_MULTI_DRAGGING_DELTA = "workChannel/UPDATE_MULTI_DRAGGING_DELTA",
  APPEND_MULTI_DRAGGING_DELTA = "workChannel/APPEND_MULTI_DRAGGING_DELTA",
  FINISH_MULTI_DRAGGING = "workChannel/FINISH_MULTI_DRAGGING",
  INIT_WORK_CHANNEL_STATE = "workChannel/INIT_WORK_CHANNEL_STATE",
  IS_TEXT_EDITING = "workChannel/IS_TEXT_EDITING",
  IS_FONT_SIZE_CHANGING = "workChannel/IS_FONT_SIZE_CHANGING",
  UPDATE_SELECTION_AREA = "workChannel/UPDATE_SELECTION_AREA",
  APPEND_SELECTION_AREA_SIZE = "workChannel/APPEND_SELECTION_AREA_SIZE",
  SAVED_NEW_WORK_CHANNEL_TEMPLATE = "workChannel/SAVED_NEW_WORK_CHANNEL_TEMPLATE",
  DELETE_WORK_CHANNEL_TEMPLATE = "workChannel/DELETE_WORK_CHANNEL_TEMPLATE",
  APPLY_WORK_CHANNEL_TEMPLATE = "workChannel/APPLY_WORK_CHANNEL_TEMPLATE",
  PREVIEW_WORK_CHANNEL_TEMPLATE = "workChannel/PREVIEW_WORK_CHANNEL_TEMPLATE",
  UPDATE_IS_REDOING_OR_UNDOING = "workChannel/UPDATE_IS_REDOING_OR_UNDOING",
  UPDATE_IS_FONT_SIZE_UPDATED = "workChannel/UPDATE_IS_FONT_SIZE_UPDATED",
  UPDATE_CROPPING_IMAGE = "workChannel/UPDATE_CROPPING_IMAGE",
  CLEAR_CROPPING_IMAGE = "workChannel/CLEAR_CROPPING_IMAGE",
  UPDATE_IS_SAVING_AS_TEMPLATE = "workChannel/UPDATE_IS_SAVING_AS_TEMPLATE",
  UPDATE_IS_DEPLOYING = "workChannel/UPDATE_IS_DEPLOYING",
  UPDATE_CROPPING_DIRECTION = "workChannel/UPDATE_CROPPING_DIRECTION",
  UPDATE_IS_RED_LINE_ENABLED = "workChannel/UPDATE_IS_RED_LINE_ENABLED",
  UPDATE_IS_GRID_ENABLED = "workChannel/UPDATE_IS_GRID_ENABLED",
  UPDATE_GRID_GAP = "workChannel/UPDATE_GRID_GAP",
  UPDATE_NEAR_GRIDS = "workChannel/UPDATE_NEAR_GRIDS",
}

type Action = ActionsUnion<typeof actions>;

const actions = {
  startLoading: () => createAction(ActionType.START_LOADING),
  endLoading: () => createAction(ActionType.END_LOADING),
  setBaseInfo: (
    floorObjectCategories: FloorObjectCategory[],
    floorObjects: FloorObject[],
    customBackgrounds: WorkspaceBackground[],
    workChannelSubversions: WorkChannelSubversion[],
    workChannelTemplates: WorkChannelTemplate[],
  ) =>
    createAction(ActionType.SET_BASE_INFO, {
      floorObjectCategories,
      floorObjects,
      customBackgrounds,
      workChannelSubversions,
      workChannelTemplates,
    }),
  createdCustomObject: (object: FloorObject) => createAction(ActionType.CREATED_CUSTOM_OBJECT, { object }),
  deletedCustomObject: (objectId: number) => createAction(ActionType.DELETED_CUSTOM_OBJECT, { objectId }),
  createdCustomBackground: (object: WorkspaceBackground) =>
    createAction(ActionType.CREATED_CUSTOM_BACKGROUND, { object }),
  deletedCustomBackground: (workspaceBackgroundId: number) =>
    createAction(ActionType.DELETED_CUSTOM_BACKGROUND, { workspaceBackgroundId }),
  joinedWorkChannel: (workChannel: WorkChannel, workChannelObjects: WorkChannelObject[]) =>
    createAction(ActionType.JOINED_WORK_CHANNEL, { workChannel, workChannelObjects }),
  updateSelectedFloorObjectId: (floorObjectId: number | "text" | undefined) =>
    createAction(ActionType.UPDATE_SELECTED_FLOOR_OBJECT_ID, { floorObjectId }),
  updateSelectedBaseWorkspaceBackgroundId: (workspaceBackgroundId: number | undefined) =>
    createAction(ActionType.UPDATE_SELECTED_BASE_WORKSPACE_BACKGROUND_ID, { workspaceBackgroundId }),
  updateCurrentPanel: (activeKey: PanelType) => createAction(ActionType.UPDATE_CURRENT_PANEL, { activeKey }),
  addWorkChannelObject: (object: WorkChannelObject) => createAction(ActionType.ADD_WORK_CHANNEL_OBJECT, { object }),
  addMultiWorkChannelObjects: (newObjects: WorkChannelObject[]) =>
    createAction(ActionType.ADD_MULTI_WORK_CHANNEL_OBJECTS, { newObjects }),
  updateWorkChannelObject: (object: WorkChannelObject) =>
    createAction(ActionType.UPDATE_WORK_CHANNEL_OBJECT, { object }),
  updateMultiWorkChannelObjects: (objects: WorkChannelObject[]) =>
    createAction(ActionType.UPDATE_MULTI_WORK_CHANNEL_OBJECTS, { objects }),
  deleteWorkChannelObject: (objectId: string) => createAction(ActionType.DELETE_WORK_CHANNEL_OBJECT, { objectId }),
  deleteMultiWorkChannelObjects: (objectIds: string[]) =>
    createAction(ActionType.DELETE_MULTI_WORK_CHANNEL_OBJECTS, { objectIds }),
  addToSelectedWorkChannelObjectIds: (objectId: string) =>
    createAction(ActionType.ADD_TO_SELECTED_WORK_CHANNEL_OBJECT_IDS, { objectId }),
  updateSelectedWorkChannelObjectId: (objectId: string) =>
    createAction(ActionType.UPDATE_SELECTED_WORK_CHANNEL_OBJECT_ID, { objectId }),
  updateSelectedWorkChannelObjectIds: (objectIds: string[]) =>
    createAction(ActionType.UPDATE_SELECTED_WORK_CHANNEL_OBJECT_IDS, { objectIds }),
  clearSelectedWorkChannelObjectIds: () => createAction(ActionType.CLEAR_SELECTED_WORK_CHANNEL_OBJECT_IDS),
  updateBaseWorkspaceBackground: (workspaceBackgroundId: number, workChannelObjects: WorkChannelObject[]) =>
    createAction(ActionType.UPDATE_BASE_WORKSPACE_BACKGROUND, { workspaceBackgroundId, workChannelObjects }),
  updateDraggingAndResizingObject: (detail: { id: string; position: Position; size: Size } | undefined) =>
    createAction(ActionType.UPDATE_DRAGGING_AND_RESIZING_OBJECT, { detail }),
  updateNearBorderExtends: (payload: {
    vCenter?: number[];
    top?: number[];
    bottom?: number[];
    hCenter?: number[];
    left?: number[];
    right?: number[];
  }) =>
    createAction(ActionType.UPDATE_NEAR_BORDER_EXTENDS, {
      vCenter: payload.vCenter,
      top: payload.top,
      bottom: payload.bottom,
      hCenter: payload.hCenter,
      left: payload.left,
      right: payload.right,
    }),
  addEditHistoryAction: (payload: WorkChannelEditHistory) =>
    createAction(ActionType.ADD_EDIT_HISTORY, { newAction: payload }),
  updateEditHistoryAction: (payload: { id: number; type: WorkChannelHistoryAcitonType }) =>
    createAction(ActionType.UPDATE_EDIT_HISTORY, payload),
  addWorkChannelSubversionAction: (payload: WorkChannelSubversion) =>
    createAction(ActionType.ADD_WORK_CHANNEL_SUBVERSION, { newSubversion: payload }),
  deleteWorkChannelSubversionAction: (subversionId: number) =>
    createAction(ActionType.DELETE_WORK_CHANNEL_SUBVERSION, { subversionId }),
  restoreWorkChannelSubversionAction: (payload: {
    newSubversion: WorkChannelSubversion | undefined;
    workChannel: WorkChannel;
    workChannelObjects: WorkChannelObject[];
  }) => createAction(ActionType.RESTORE_WORK_CHANNEL_SUBVERSION, payload),
  previewWorkChannelSubversionAction: (subversionDetail: WorkChannelSubversionDetail) =>
    createAction(ActionType.PREVIEW_WORK_CHANNEL_SUBVERSION, { subversionDetail }),
  updateIsPreviewAction: (state: PreviewType) => createAction(ActionType.UPDATE_IS_PREVIEW, { state }),
  updateArrowKeyPressAction: (keyDetail: {
    up: boolean;
    down: boolean;
    left: boolean;
    right: boolean;
    deltaX: number;
    deltaY: number;
  }) => createAction(ActionType.UPDATE_ARROW_KEY_PRESS, { keyDetail }),
  clearArrowKeyPressDeltaAction: () => createAction(ActionType.CLEARE_ARROW_KEY_PRESS_DELTA),
  updateMultiDraggingDeltaAction: (draggingDelta: { id: string; deltaX: number; deltaY: number } | undefined) =>
    createAction(ActionType.UPDATE_MULTI_DRAGGING_DELTA, { draggingDelta }),
  appendMultiDraggingDeltaAction: (draggingDelta: { id: string; deltaX: number; deltaY: number }) =>
    createAction(ActionType.APPEND_MULTI_DRAGGING_DELTA, { draggingDelta }),
  finishMultiDraggingAction: (updatedObjects: WorkChannelObject[]) =>
    createAction(ActionType.FINISH_MULTI_DRAGGING, { updatedObjects }),
  initWorkChannelStateAction: () => createAction(ActionType.INIT_WORK_CHANNEL_STATE),
  updateIsTextEditingAction: (isTextEditing: boolean) => createAction(ActionType.IS_TEXT_EDITING, { isTextEditing }),
  updateIsFontSizeChangingAction: (isFontSizeChanging: boolean) =>
    createAction(ActionType.IS_FONT_SIZE_CHANGING, { isFontSizeChanging }),
  updateSelectionAreaAction: (selectionArea: SelectionArea | undefined) =>
    createAction(ActionType.UPDATE_SELECTION_AREA, { selectionArea }),
  appendSelectionAreaSizeAction: (deltaSize: Size) =>
    createAction(ActionType.APPEND_SELECTION_AREA_SIZE, { deltaSize }),
  savedNewWorkChannelTemplateAction: (newTemplate: WorkChannelTemplate) =>
    createAction(ActionType.SAVED_NEW_WORK_CHANNEL_TEMPLATE, { newTemplate }),
  deletedWorkChannelTemplateAction: (templateId: number) =>
    createAction(ActionType.DELETE_WORK_CHANNEL_TEMPLATE, { templateId }),
  appliedWorkChannelTemplateAction: (payload: { workChannel: WorkChannel; workChannelObjects: WorkChannelObject[] }) =>
    createAction(ActionType.APPLY_WORK_CHANNEL_TEMPLATE, payload),
  previewWorkChannelTemplateAction: (templateDetail: WorkChannelTemplateDetail) =>
    createAction(ActionType.PREVIEW_WORK_CHANNEL_TEMPLATE, { templateDetail }),
  updateIsRedoingOrUndoingAction: (status: boolean) =>
    createAction(ActionType.UPDATE_IS_REDOING_OR_UNDOING, { status }),
  updateIsFontSizeUpdatedAction: (state: boolean) => createAction(ActionType.UPDATE_IS_FONT_SIZE_UPDATED, { state }),
  updateCroppingImageAction: (data: CroppingImageInfo) => createAction(ActionType.UPDATE_CROPPING_IMAGE, { data }),
  clearCroppingImageAction: () => createAction(ActionType.CLEAR_CROPPING_IMAGE),
  updateIsSavingAsTemplateAction: (state: boolean) => createAction(ActionType.UPDATE_IS_SAVING_AS_TEMPLATE, { state }),
  updateIsDeployingAction: (state: boolean) => createAction(ActionType.UPDATE_IS_DEPLOYING, { state }),
  updateCroppingDirectionAction: (direction: Direction | undefined) =>
    createAction(ActionType.UPDATE_CROPPING_DIRECTION, { direction }),
  updateIsRedLineEnabledAction: (state: boolean) => createAction(ActionType.UPDATE_IS_RED_LINE_ENABLED, { state }),
  updateIsGridEnabledAction: (state: boolean) => createAction(ActionType.UPDATE_IS_GRID_ENABLED, { state }),
  updateGridGapAction: (state: number) => createAction(ActionType.UPDATE_GRID_GAP, { state }),
  updateNearGridsAction: (payload: { top?: number; bottom?: number; left?: number; right?: number }) =>
    createAction(ActionType.UPDATE_NEAR_GRIDS, {
      top: payload.top,
      bottom: payload.bottom,
      left: payload.left,
      right: payload.right,
    }),
};

export type { Action as WorkChannelAction };
export { ActionType as WorkChannelActionType };

export function createCustomObjectRequest(obj: FloorObjectDetail): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_CREATE_CUSTOM_OBJECT_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          object: obj,
        }),
      );
    }
  };
}

export function createCustomObjectBroadcast(newFloorObject: FloorObject): AppThunk {
  return async dispatch => {
    dispatch(actions.createdCustomObject(newFloorObject));
  };
}

export function deleteCustomObjectRequest(objectId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_DELETE_CUSTOM_OBJECT_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          objectId,
        }),
      );
    }
  };
}

export function deleteCustomObjectBroadcast(deletedFloorObjectId: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.deletedCustomObject(deletedFloorObjectId));
  };
}

export function createCustomBackgroundRequest(obj: FloorObjectDetail): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_CREATE_CUSTOM_BACKGROUND_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          background: obj,
        }),
      );
    }
  };
}

export function createCustomBackgroundBroadcast(newCustomBackground: WorkspaceBackground): AppThunk {
  return async dispatch => {
    dispatch(actions.createdCustomBackground(newCustomBackground));
    dispatch(createdWorkspaceBackgroundAction(newCustomBackground));
  };
}

export function deleteCustomBackgroundRequest(workspaceBackgroundId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_DELETE_CUSTOM_BACKGROUND_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          workspaceBackgroundId,
        }),
      );
    }
  };
}

export function deleteCustomBackgroundBroadcast(deletedCustomBackgroundId: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.deletedCustomBackground(deletedCustomBackgroundId));
  };
}

export function fetchWorkChannelBaseInfo(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.workspaceId && me.voiceChannelId) {
      dispatch(actions.startLoading());
      const workChannelBaseInfo = await getWorkChannelBaseInfo(me.workspaceId, me.voiceChannelId);

      dispatch(
        actions.setBaseInfo(
          workChannelBaseInfo.floorObjectCategories,
          workChannelBaseInfo.floorObjects,
          workChannelBaseInfo.customBackgrounds,
          workChannelBaseInfo.workChannelSubversions,
          workChannelBaseInfo.workChannelTemplates,
        ),
      );

      dispatch(actions.endLoading());
    }
  };
}

export function joinWorkChannel(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me?.workspaceId && me.voiceChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_JOIN_WORK_CHANNEL_REQUEST, {
          workspaceId: me.workspaceId,
          voiceChannelId: me.voiceChannelId,
        }),
      );
    }
  };
}

export function joinWorkChannelResponse(workChannel: WorkChannel, workChannelObjects: WorkChannelObject[]): AppThunk {
  return async (dispatch, getState) => {
    dispatch(
      actions.joinedWorkChannel(
        workChannel,
        workChannelObjects.filter(el => el.properties),
      ),
    );
    dispatch(setIsCustomizingOfficeBackground(true));
  };
}

export function leaveWorkChannel(): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkChannelId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_LEAVE_WORK_CHANNEL_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
        }),
      );
    }
  };
}

export function leaveWorkChannelResponse(workChannelId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkChannelId === workChannelId) {
      dispatch(updateIsFromCustomBackground(true));
      dispatch(clearSelectedWorkChannelObjectIds());
      dispatch(setIsCustomizingOfficeBackground(false));
      dispatch(initWorkChannelState());
    }
  };
}

export function updateSelectedFloorObjectId(floorObjectId: number | "text" | undefined): AppThunk {
  return async dispatch => {
    dispatch(actions.updateSelectedFloorObjectId(floorObjectId));
  };
}

export function updateBaseWorkspaceBackgroundIdRequest(
  workspaceId: number,
  workChannelId: number,
  workspaceBackgroundId: number,
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(
      sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_BASE_WORKSPACE_BACKGROUND_UPDATE_REQUEST, {
        workspaceId,
        workChannelId,
        workspaceBackgroundId,
      }),
    );
  };
}

export function updateBaseWorkspaceBackgroundIdBroadcast(
  workChannelId: number,
  workspaceBackgroundId: number,
  workChannelObjects: WorkChannelObject[],
): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkChannel = getCurrentWorkChannel(getState());

    if (currentWorkChannel?.id === workChannelId) {
      dispatch(actions.updateBaseWorkspaceBackground(workspaceBackgroundId, workChannelObjects));
    }
  };
}

export function updateCurrentPanel(activeKey: PanelType): AppThunk {
  return async dispatch => {
    dispatch(actions.updateCurrentPanel(activeKey));
  };
}

export function addImageWorkChannelObject(workChannelId: number, position: Position, floorObjectId: number): AppThunk {
  return async (dispatch, getState) => {
    const floorObject = getFloorObjectById(floorObjectId)(getState());
    const isCustomObject = floorObject?.category === WORK_CHANNEL_CUSTOM_CATEGORY_ID;
    const maxOrder = getWorkChannelObjectsMaxOrder(getState());

    if (floorObject) {
      const { properties, permissions } = {
        properties: {
          content: {
            url: floorObject.url,
            objectId: floorObjectId,
            baseSize: isCustomObject ? { width: floorObject.width, height: floorObject.height } : undefined,
            crop: isCustomObject ? { top: 0, bottom: 0, left: 0, right: 0 } : undefined,
          },
          position: { x: position.x - floorObject.width / 2, y: position.y - floorObject.height / 2 },
          rotation: 0,
          order: maxOrder + 1,
          size: { width: floorObject.width, height: floorObject.height },
        },
        permissions: {
          list: WorkChannelObjectPermission.All,
          read: WorkChannelObjectPermission.All,
          write: WorkChannelObjectPermission.All,
          move: WorkChannelObjectPermission.All,
          rotate: WorkChannelObjectPermission.All,
          reorder: WorkChannelObjectPermission.All,
          resize: WorkChannelObjectPermission.All,
          remove: WorkChannelObjectPermission.All,
          lock: WorkChannelObjectPermission.All,
          changePermission: WorkChannelObjectPermission.WorkspaceManager,
        },
      };

      dispatch(createWorkChannelObjectRequest("image", properties, permissions, workChannelId));
    }
  };
}

export function addCustomImageWorkChannelObject(
  workChannelId: number,
  position: Position,
  size: Size,
  url: string,
): AppThunk {
  return async (dispatch, getState) => {
    const maxOrder = getWorkChannelObjectsMaxOrder(getState());
    const { properties, permissions } = {
      properties: {
        content: {
          url: url,
          objectId: 0,
          type: "custom",
          baseSize: size,
          crop: { top: 0, bottom: 0, left: 0, right: 0 },
        },
        position: { x: position.x - size.width / 2, y: position.y - size.height / 2 },
        rotation: 0,
        order: maxOrder + 1,
        size,
      },
      permissions: {
        list: WorkChannelObjectPermission.All,
        read: WorkChannelObjectPermission.All,
        write: WorkChannelObjectPermission.All,
        move: WorkChannelObjectPermission.All,
        rotate: WorkChannelObjectPermission.All,
        reorder: WorkChannelObjectPermission.All,
        resize: WorkChannelObjectPermission.All,
        remove: WorkChannelObjectPermission.All,
        lock: WorkChannelObjectPermission.All,
        changePermission: WorkChannelObjectPermission.WorkspaceManager,
      },
    };

    dispatch(createWorkChannelObjectRequest("image", properties, permissions, workChannelId));
  };
}

export function addTextWorkChannelObject(workChannelId: number, position: Position): AppThunk {
  return async (dispatch, getState) => {
    const maxOrder = getWorkChannelObjectsMaxOrder(getState());

    const { properties, permissions } = {
      properties: {
        content: {
          text: "",
          fontFamily: "NotoSansJpRegular",
          fontSize: WORK_CHANNEL_FONT_SIZE_LIMIT.default,
          color: "black",
        },
        position: {
          x: position.x - WORK_CHANNEL_TEXT_OBJECT_DEFAULT_SIZE.width / 2,
          y: position.y - WORK_CHANNEL_TEXT_OBJECT_DEFAULT_SIZE.height / 2,
        },
        rotation: 0,
        order: maxOrder + 1,
        size: WORK_CHANNEL_TEXT_OBJECT_DEFAULT_SIZE,
      },
      permissions: {
        list: WorkChannelObjectPermission.All,
        read: WorkChannelObjectPermission.All,
        write: WorkChannelObjectPermission.All,
        move: WorkChannelObjectPermission.All,
        rotate: WorkChannelObjectPermission.All,
        reorder: WorkChannelObjectPermission.All,
        resize: WorkChannelObjectPermission.All,
        remove: WorkChannelObjectPermission.All,
        lock: WorkChannelObjectPermission.All,
        changePermission: WorkChannelObjectPermission.WorkspaceManager,
      },
    } as Partial<TextWorkChannelObject>;

    dispatch(createWorkChannelObjectRequest("text", properties!, permissions!, workChannelId));
  };
}

export function copyImageWorkChannelObject(object: ImageWorkChannelObject): AppThunk {
  return async (dispatch, getState) => {
    const maxOrder = getWorkChannelObjectsMaxOrder(getState());

    const { properties, permissions } = {
      properties: {
        ...object.properties,
        position: { x: object.properties.position.x + 10, y: object.properties.position.y + 10 },
        order: maxOrder + 1,
      },
      permissions: { ...object.permissions },
    };

    dispatch(createWorkChannelObjectRequest("image", properties, permissions, object.workChannelId));
  };
}

export function copyTextWorkChannelObject(object: TextWorkChannelObject): AppThunk {
  return async (dispatch, getState) => {
    const maxOrder = getWorkChannelObjectsMaxOrder(getState());

    const { properties, permissions } = {
      properties: {
        ...object.properties,
        position: { x: object.properties.position.x + 10, y: object.properties.position.y + 10 },
        order: maxOrder + 1,
      },
      permissions: { ...object.permissions },
    };

    dispatch(createWorkChannelObjectRequest("text", properties, permissions, object.workChannelId));
  };
}

export function copyMultiWorkChannelObjects(objectIds: string[], centerPosition: Position): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const workChannelObjects = objectIds
      .filter(objectId => getWorkChannelObjectById(objectId)(getState()))
      .map(objectId => getWorkChannelObjectById(objectId)(getState()));
    const maxOrder = getWorkChannelObjectsMaxOrder(getState());

    const top = Math.min(...workChannelObjects.map(obj => obj!.properties.position.y));
    const bottom = Math.max(...workChannelObjects.map(obj => obj!.properties.position.y + obj!.properties.size.height));
    const left = Math.min(...workChannelObjects.map(obj => obj!.properties.position.x));
    const right = Math.max(...workChannelObjects.map(obj => obj!.properties.position.x + obj!.properties.size.width));
    const oldCenter = { x: left + (right - left) / 2, y: top + (bottom - top) / 2 };
    const centerDeltaX = centerPosition.x - oldCenter.x;
    const centerDeltaY = centerPosition.y - oldCenter.y;

    if (workChannelObjects.length > 0) {
      const newWorkChannelObjects = workChannelObjects.map((oldObject, idx) => {
        const newObject = {
          type: oldObject?.type,
          workChannelId: oldObject?.workChannelId,
          id: nanoid(),
          ownerUserId: me?.id,
          properties: {
            ...oldObject?.properties,
            isLocked: false,
            position: {
              x: oldObject!.properties.position.x + centerDeltaX,
              y: oldObject!.properties.position.y + centerDeltaY,
            },
            order: maxOrder + idx + 1,
          },
          permissions: oldObject?.permissions,
        } as WorkChannelObject;

        return newObject;
      });

      dispatch(createMultiWorkChannelObjectRequest(newWorkChannelObjects));
    }
  };
}

export function addEditHistory(payload: WorkChannelEditHistory): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me?.id === payload.editorId) {
      dispatch(actions.addEditHistoryAction(payload));
    }
  };
}

export function createWorkChannelObjectRequest(
  type: "text",
  properties: TextWorkChannelObject["properties"],
  permissions: WorkChannelObjectPermissions,
  workChannelId?: number,
  objectId?: string,
  ownerUserId?: number,
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk;
export function createWorkChannelObjectRequest(
  type: "image",
  properties: ImageWorkChannelObject["properties"],
  permissions: WorkChannelObjectPermissions,
  workChannelId?: number,
  objectId?: string,
  ownerUserId?: number,
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk;
export function createWorkChannelObjectRequest(
  type: WorkChannelObjectType,
  properties: any,
  permissions: WorkChannelObjectPermissions,
  workChannelId?: number,
  objectId?: string,
  ownerUserId?: number,
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const voiceChannel = getCurrentWorkChannel(getState());

    if (!me || !currentWorkspaceId || !voiceChannel) return;

    const object = {
      type,
      workChannelId,
      id: objectId ?? nanoid(),
      ownerUserId: ownerUserId ?? me.id,
      properties,
      permissions,
    } as WorkChannelObject;

    if (currentWorkspaceId && object.ownerUserId && object.type) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_OBJECT_CREATE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId,
          object,
          historyAction: historyId && historyAction ? { id: historyId, type: historyAction } : undefined,
        }),
      );
    }
  };
}

export function createWorkChannelObjectBroadcast(
  object: WorkChannelObject,
  historyId: number,
  editorId: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(actions.addWorkChannelObject(object));
    if (me && object.ownerUserId === me.id) {
      dispatch(updateSelectedWorkChannelObjectId(object.id));
      if (object.type === "text") {
        dispatch(updateIsTextEditing(true));
      }
    }

    if (historyAction) {
      dispatch(actions.updateEditHistoryAction({ id: historyId, type: historyAction }));
      dispatch(updateIsRedoingOrUndoing(false));
    } else if (me?.id === editorId) {
      dispatch(
        addEditHistory({
          historyId,
          objectId: object.id,
          objectType: object.type,
          ownerUserId: object.ownerUserId,
          workChannelId: object.workChannelId,
          status: "active",
          actionType: "create",
          newProperties: object.properties,
          newPermissions: object.permissions,
          editorId,
        }),
      );
    }
  };
}

export function createMultiWorkChannelObjectRequest(
  newObjects: WorkChannelObject[],
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_MULTI_WORK_CHANNEL_OBJECTS_CREATE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          newObjects,
          historyAction: historyId && historyAction ? { id: historyId, type: historyAction } : undefined,
        }),
      );
    }
  };
}

export function createMultiWorkChannelObjectBroadcast(
  newObjects: WorkChannelObject[],
  historyId: number,
  editorId: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(actions.addMultiWorkChannelObjects(newObjects));
    if (me && editorId === me.id) {
      dispatch(actions.updateSelectedWorkChannelObjectIds(newObjects.map(o => o.id)));
    }

    if (historyAction) {
      dispatch(actions.updateEditHistoryAction({ id: historyId, type: historyAction }));
      dispatch(updateIsRedoingOrUndoing(false));
    } else if (me?.id === editorId) {
      dispatch(
        addEditHistory({
          historyId,
          editorId,
          status: "active",
          actionType: "create_group",
          newGroupData: newObjects!.map(obj => {
            return {
              objectId: obj.id,
              objectType: obj.type,
              workChannelId: obj.workChannelId,
              ownerUserId: obj.ownerUserId,
              properties: obj.properties,
              permissions: obj.permissions,
            };
          }),
        }),
      );
    }
  };
}

export function updateWorkChannelObjectPropertiesRequest<T extends WorkChannelObject>(
  object: T,
  properties: Partial<T["properties"]>,
  permissions?: Partial<WorkChannelObjectPermissions>,
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());

    if (currentWorkspaceId) {
      dispatch(
        // @ts-ignore
        actions.updateWorkChannelObject({
          ...object,
          properties: { ...object.properties, ...properties },
          permissions: { ...object.permissions, ...permissions },
        }),
      );

      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_OBJECT_UPDATE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: object.workChannelId,
          objectId: object.id,
          properties,
          permissions: permissions,
          historyAction: historyId && historyAction ? { id: historyId, type: historyAction } : undefined,
        }),
      );
    }
  };
}

export function updateWorkChannelObjectById<T extends WorkChannelObject>(
  objectId: string,
  properties: Partial<T["properties"]>,
  permissions?: Partial<WorkChannelObjectPermissions>,
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object) {
      dispatch(updateWorkChannelObjectPropertiesRequest(object, properties, permissions, historyId, historyAction));
    }
  };
}

export function updateWorkChannelObjectBroadcast(
  object: WorkChannelObject,
  historyId: number,
  editorId: number,
  historyAction?: WorkChannelHistoryAcitonType,
  oldData?: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"],
  newData?: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"],
): AppThunk {
  return async (dispatch, getState) => {
    const oldObject = getWorkChannelObjectById(object.id)(getState());
    const me = getMyMember(getState());

    dispatch(actions.updateWorkChannelObject(object));

    if (oldObject) {
      if (historyAction) {
        dispatch(actions.updateEditHistoryAction({ id: historyId, type: historyAction }));
        dispatch(updateIsRedoingOrUndoing(false));
      } else if (me?.id === editorId) {
        dispatch(
          addEditHistory({
            historyId,
            editorId,
            objectId: object.id,
            objectType: object.type,
            workChannelId: object.workChannelId,
            ownerUserId: object.ownerUserId,
            status: "active",
            actionType: "update",
            oldProperties: oldData,
            oldPermissions: oldObject.permissions,
            newProperties: newData,
            newPermissions: object.permissions,
          }),
        );
      }
    }
  };
}

export function updateMultiWorkChannelObjectPropertiesRequest<T extends WorkChannelObject>(
  requestData: {
    object: T;
    properties: Partial<T["properties"]>;
    permissions?: Partial<WorkChannelObjectPermissions>;
  }[],
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      for (const item of requestData) {
        dispatch(
          // @ts-ignore
          actions.updateWorkChannelObject({
            ...item.object,
            properties: { ...item.object.properties, ...item.properties },
            permissions: { ...item.object.permissions, ...item.permissions },
          }),
        );
      }

      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_MULTI_WORK_CHANNEL_OBJECTS_UPDATE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          updateData: requestData.map(el => ({
            objectId: el.object.id,
            properties: el.properties,
            permissions: el.permissions,
          })),
          historyAction: historyId && historyAction ? { id: historyId, type: historyAction } : undefined,
        }),
      );
    }
  };
}

export function updateMultiWorkChannelObjectsBroadcast(
  objects: WorkChannelObject[],
  historyId: number,
  editorId: number,
  historyAction?: WorkChannelHistoryAcitonType,
  oldGroupData?: {
    objectId: string;
    properties: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"];
  }[],
  newGroupData?: {
    objectId: string;
    properties: ImageWorkChannelObject["properties"] | TextWorkChannelObject["properties"];
  }[],
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(actions.updateMultiWorkChannelObjects(objects));

    if (historyAction) {
      dispatch(actions.updateEditHistoryAction({ id: historyId, type: historyAction }));
      dispatch(updateIsRedoingOrUndoing(false));
    } else if (me?.id === editorId) {
      dispatch(
        addEditHistory({
          historyId,
          editorId,
          status: "active",
          actionType: "update_group",
          oldGroupData: oldGroupData!.map(obj => {
            const object = getWorkChannelObjectById(obj.objectId)(getState());

            return {
              objectId: obj.objectId,
              objectType: object!.type,
              workChannelId: object!.workChannelId,
              ownerUserId: object!.ownerUserId,
              properties: obj.properties,
              permissions: object!.permissions,
            };
          }),
          newGroupData: newGroupData!.map(obj => {
            const object = getWorkChannelObjectById(obj.objectId)(getState());

            return {
              objectId: obj.objectId,
              objectType: object!.type,
              workChannelId: object!.workChannelId,
              ownerUserId: object!.ownerUserId,
              properties: obj.properties,
              permissions: object!.permissions,
            };
          }),
        }),
      );
    }
  };
}

export function deleteWorkChannelObjectRequest(
  object: WorkChannelObject,
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_OBJECT_DELETE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          object,
          historyAction: historyId && historyAction ? { id: historyId, type: historyAction } : undefined,
        }),
      );
    }
  };
}

export function deleteWorkChannelObjectBroadcast(
  objectId: string,
  historyId: number,
  editorId: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());
    const me = getMyMember(getState());

    dispatch(actions.deleteWorkChannelObject(objectId));

    if (object) {
      if (historyAction) {
        dispatch(actions.updateEditHistoryAction({ id: historyId, type: historyAction }));
        dispatch(updateIsRedoingOrUndoing(false));
      } else if (me?.id === editorId) {
        dispatch(
          addEditHistory({
            historyId,
            editorId,
            objectId: object.id,
            objectType: object.type,
            workChannelId: object.workChannelId,
            ownerUserId: object.ownerUserId,
            status: "active",
            actionType: "delete",
            oldProperties: object.properties,
            oldPermissions: object.permissions,
          }),
        );
      }
    }
  };
}

export function deleteMultiWorkChannelObjectRequest(
  objectIds: string[],
  historyId?: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_MULTI_WORK_CHANNEL_OBJECTS_DELETE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          objectIds,
          historyAction: historyId && historyAction ? { id: historyId, type: historyAction } : undefined,
        }),
      );
    }
  };
}

export function deleteMultiWorkChannelObjectBroadcast(
  objectIds: string[],
  historyId: number,
  editorId: number,
  historyAction?: WorkChannelHistoryAcitonType,
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const oldObjects = objectIds.map(objectId => getWorkChannelObjectById(objectId)(getState()));

    dispatch(actions.deleteMultiWorkChannelObjects(objectIds));

    if (historyAction) {
      dispatch(actions.updateEditHistoryAction({ id: historyId, type: historyAction }));
      dispatch(updateIsRedoingOrUndoing(false));
    } else if (me?.id === editorId) {
      dispatch(
        addEditHistory({
          historyId,
          editorId,
          status: "active",
          actionType: "delete_group",
          oldGroupData: oldObjects.map(obj => {
            return {
              objectId: obj!.id,
              objectType: obj!.type,
              workChannelId: obj!.workChannelId,
              ownerUserId: obj!.ownerUserId,
              properties: obj!.properties,
              permissions: obj?.permissions,
            };
          }),
        }),
      );
    }
  };
}

export function updateSelectedWorkChannelObjectId(objectId: string): AppThunk {
  return async dispatch => {
    dispatch(actions.clearCroppingImageAction());
    dispatch(actions.updateSelectedWorkChannelObjectId(objectId));
  };
}

export function updateSelectedWorkChannelObjectIds(objectIds: string[]): AppThunk {
  return async dispatch => {
    dispatch(actions.clearCroppingImageAction());
    dispatch(actions.updateSelectedWorkChannelObjectIds(objectIds));
  };
}

export function addToSelectedWorkChannelObjectIds(objectId: string): AppThunk {
  return async dispatch => {
    dispatch(actions.clearCroppingImageAction());
    dispatch(actions.addToSelectedWorkChannelObjectIds(objectId));
  };
}

export function clearSelectedWorkChannelObjectIds(): AppThunk {
  return async dispatch => {
    dispatch(actions.clearCroppingImageAction());
    dispatch(actions.clearSelectedWorkChannelObjectIds());
  };
}

export function initWorkChannelState(): AppThunk {
  return async dispatch => {
    dispatch(actions.initWorkChannelStateAction());
  };
}

export function updateDraggingAndResizingObject(
  detail: { id: string; position: Position; size: Size } | undefined,
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateDraggingAndResizingObject(detail));
  };
}

export function updateNearBorderExtends(payload: {
  vCenter?: number[];
  top?: number[];
  bottom?: number[];
  hCenter?: number[];
  left?: number[];
  right?: number[];
}): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateNearBorderExtends(payload));
  };
}

export function alignLeftGroupObjectRequest(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const leftMostX = Math.min(...selectedObjects.map(o => o.properties.position.x));
    const requestData = selectedObjects.map(obj => ({
      object: obj,
      properties: { position: { x: leftMostX, y: obj.properties.position.y } },
    }));

    dispatch(updateMultiWorkChannelObjectPropertiesRequest(requestData));
  };
}

export function alignRightGroupObjectRequest(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const rightMostX = Math.max(...selectedObjects.map(obj => obj.properties.position.x + obj.properties.size.width));
    const requestData = selectedObjects.map(obj => ({
      object: obj,
      properties: { position: { x: rightMostX - obj.properties.size.width, y: obj.properties.position.y } },
    }));

    dispatch(updateMultiWorkChannelObjectPropertiesRequest(requestData));
  };
}

export function alignHorizontalCenterGroupObjectRequest(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const leftMostX = Math.min(...selectedObjects.map(o => o.properties.position.x));
    const rightMostX = Math.max(...selectedObjects.map(obj => obj.properties.position.x + obj.properties.size.width));
    const centerX = (leftMostX + rightMostX) / 2;

    const requestData = selectedObjects.map(obj => ({
      object: obj,
      properties: {
        position: { x: Math.floor(centerX - obj.properties.size.width / 2), y: obj.properties.position.y },
      },
    }));

    dispatch(updateMultiWorkChannelObjectPropertiesRequest(requestData));
  };
}

export function alignTopGroupObjectRequest(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const topMostY = Math.min(...selectedObjects.map(o => o.properties.position.y));
    const requestData = selectedObjects.map(obj => ({
      object: obj,
      properties: { position: { x: obj.properties.position.x, y: topMostY } },
    }));

    dispatch(updateMultiWorkChannelObjectPropertiesRequest(requestData));
  };
}

export function alignBottomGroupObjectRequest(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const bottomMostY = Math.max(...selectedObjects.map(obj => obj.properties.position.y + obj.properties.size.height));
    const requestData = selectedObjects.map(obj => ({
      object: obj,
      properties: { position: { x: obj.properties.position.x, y: bottomMostY - obj.properties.size.height } },
    }));

    dispatch(updateMultiWorkChannelObjectPropertiesRequest(requestData));
  };
}

export function alignVerticalCenterGroupObjectRequest(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const topMostY = Math.min(...selectedObjects.map(o => o.properties.position.y));
    const bottomMostY = Math.max(...selectedObjects.map(obj => obj.properties.position.y + obj.properties.size.height));
    const centerY = Math.floor((topMostY + bottomMostY) / 2);

    const requestData = selectedObjects.map(obj => ({
      object: obj,
      properties: {
        position: { x: obj.properties.position.x, y: Math.floor(centerY - obj.properties.size.height / 2) },
      },
    }));

    dispatch(updateMultiWorkChannelObjectPropertiesRequest(requestData));
  };
}

export function handleWorkChannelUndoShortKey(): AppThunk {
  return async (dispatch, getState) => {
    const lastActiveEditHistory = getLastActiveEditHistory(getState());
    const isRedoingOrUndoing = getIsRedoingOrUndoing(getState());

    if (isRedoingOrUndoing) return;

    if (lastActiveEditHistory) {
      dispatch(updateIsRedoingOrUndoing(true));
      switch (lastActiveEditHistory.actionType) {
        case "create":
          {
            const workChannelObject = getWorkChannelObjectById(lastActiveEditHistory.objectId!)(getState());

            workChannelObject &&
              dispatch(deleteWorkChannelObjectRequest(workChannelObject, lastActiveEditHistory.historyId, "undo"));
          }
          break;
        case "delete":
          if (lastActiveEditHistory) {
            if (lastActiveEditHistory.objectType === "image") {
              dispatch(
                createWorkChannelObjectRequest(
                  "image",
                  lastActiveEditHistory.oldProperties as ImageWorkChannelObject["properties"],
                  lastActiveEditHistory.oldPermissions as WorkChannelObjectPermissions,
                  lastActiveEditHistory.workChannelId,
                  lastActiveEditHistory.objectId,
                  lastActiveEditHistory.ownerUserId,
                  lastActiveEditHistory.historyId,
                  "undo",
                ),
              );
            }

            if (lastActiveEditHistory.objectType === "text") {
              dispatch(
                createWorkChannelObjectRequest(
                  "text",
                  lastActiveEditHistory.oldProperties as TextWorkChannelObject["properties"],
                  lastActiveEditHistory.oldPermissions as WorkChannelObjectPermissions,
                  lastActiveEditHistory.workChannelId,
                  lastActiveEditHistory.objectId,
                  lastActiveEditHistory.ownerUserId,
                  lastActiveEditHistory.historyId,
                  "undo",
                ),
              );
            }
          }

          break;
        case "update":
          {
            const workChannelObject = getWorkChannelObjectById(lastActiveEditHistory.objectId!)(getState());

            if (workChannelObject && lastActiveEditHistory.oldProperties) {
              if (workChannelObject.type === "image") {
                dispatch(
                  updateWorkChannelObjectPropertiesRequest(
                    workChannelObject,
                    lastActiveEditHistory.oldProperties as ImageWorkChannelObject["properties"],
                    undefined,
                    lastActiveEditHistory.historyId,
                    "undo",
                  ),
                );
              }

              if (workChannelObject.type === "text") {
                dispatch(
                  updateWorkChannelObjectPropertiesRequest(
                    workChannelObject,
                    lastActiveEditHistory.oldProperties as TextWorkChannelObject["properties"],
                    undefined,
                    lastActiveEditHistory.historyId,
                    "undo",
                  ),
                );
              }
            }
          }
          break;
        case "update_group":
          if (lastActiveEditHistory && lastActiveEditHistory.oldGroupData)
            dispatch(
              updateMultiWorkChannelObjectPropertiesRequest(
                lastActiveEditHistory.oldGroupData.map(el => {
                  const workChannelObject = getWorkChannelObjectById(el.objectId!)(getState());

                  return { object: workChannelObject!, properties: el.properties };
                }),
                lastActiveEditHistory.historyId,
                "undo",
              ),
            );

          break;
        case "create_group":
          if (lastActiveEditHistory && lastActiveEditHistory.newGroupData) {
            dispatch(
              deleteMultiWorkChannelObjectRequest(
                lastActiveEditHistory.newGroupData?.map(el => el.objectId),
                lastActiveEditHistory.historyId,
                "undo",
              ),
            );
          }

          break;
        case "delete_group":
          if (lastActiveEditHistory && lastActiveEditHistory.oldGroupData) {
            dispatch(
              createMultiWorkChannelObjectRequest(
                lastActiveEditHistory.oldGroupData.map(el => {
                  if (el.objectType === "text") {
                    return {
                      id: el.objectId,
                      workChannelId: el.workChannelId,
                      type: el.objectType,
                      ownerUserId: el.ownerUserId,
                      properties: el.properties,
                      permissions: el.permissions,
                    } as TextWorkChannelObject;
                  } else {
                    return {
                      id: el.objectId,
                      workChannelId: el.workChannelId,
                      type: el.objectType,
                      ownerUserId: el.ownerUserId,
                      properties: el.properties,
                      permissions: el.permissions,
                    } as ImageWorkChannelObject;
                  }
                }),
                lastActiveEditHistory.historyId,
                "undo",
              ),
            );
          }

          break;

        default:
          break;
      }
    }
  };
}

export function handleWorkChannelRedoShortKey(): AppThunk {
  return async (dispatch, getState) => {
    const firstRevertedEditHistory = getFirstRevertedEditHistory(getState());
    const isRedoingOrUndoing = getIsRedoingOrUndoing(getState());

    if (isRedoingOrUndoing) return;

    if (firstRevertedEditHistory) {
      dispatch(updateIsRedoingOrUndoing(true));
      switch (firstRevertedEditHistory.actionType) {
        case "create":
          if (firstRevertedEditHistory) {
            if (firstRevertedEditHistory.objectType === "image") {
              dispatch(
                createWorkChannelObjectRequest(
                  "image",
                  firstRevertedEditHistory.newProperties as ImageWorkChannelObject["properties"],
                  firstRevertedEditHistory.newPermissions as WorkChannelObjectPermissions,
                  firstRevertedEditHistory.workChannelId,
                  firstRevertedEditHistory.objectId,
                  firstRevertedEditHistory.ownerUserId,
                  firstRevertedEditHistory.historyId,
                  "redo",
                ),
              );
            }

            if (firstRevertedEditHistory.objectType === "text") {
              dispatch(
                createWorkChannelObjectRequest(
                  "text",
                  firstRevertedEditHistory.newProperties as TextWorkChannelObject["properties"],
                  firstRevertedEditHistory.newPermissions as WorkChannelObjectPermissions,
                  firstRevertedEditHistory.workChannelId,
                  firstRevertedEditHistory.objectId,
                  firstRevertedEditHistory.ownerUserId,
                  firstRevertedEditHistory.historyId,
                  "redo",
                ),
              );
            }
          }

          break;
        case "delete":
          {
            const workChannelObject = getWorkChannelObjectById(firstRevertedEditHistory.objectId!)(getState());

            workChannelObject &&
              dispatch(deleteWorkChannelObjectRequest(workChannelObject, firstRevertedEditHistory.historyId, "redo"));
          }

          break;
        case "update":
          {
            const workChannelObject = getWorkChannelObjectById(firstRevertedEditHistory.objectId!)(getState());

            if (workChannelObject && firstRevertedEditHistory.newProperties) {
              if (workChannelObject.type === "image") {
                dispatch(
                  updateWorkChannelObjectPropertiesRequest(
                    workChannelObject,
                    firstRevertedEditHistory.newProperties as ImageWorkChannelObject["properties"],
                    undefined,
                    firstRevertedEditHistory.historyId,
                    "redo",
                  ),
                );
              }

              if (workChannelObject.type === "text") {
                dispatch(
                  updateWorkChannelObjectPropertiesRequest(
                    workChannelObject,
                    firstRevertedEditHistory.newProperties as TextWorkChannelObject["properties"],
                    undefined,
                    firstRevertedEditHistory.historyId,
                    "redo",
                  ),
                );
              }
            }
          }
          break;
        case "create_group":
          if (firstRevertedEditHistory && firstRevertedEditHistory.newGroupData) {
            dispatch(
              createMultiWorkChannelObjectRequest(
                firstRevertedEditHistory.newGroupData.map(el => {
                  if (el.objectType === "text") {
                    return {
                      id: el.objectId,
                      workChannelId: el.workChannelId,
                      type: el.objectType,
                      ownerUserId: el.ownerUserId,
                      properties: el.properties,
                      permissions: el.permissions,
                    } as TextWorkChannelObject;
                  } else {
                    return {
                      id: el.objectId,
                      workChannelId: el.workChannelId,
                      type: el.objectType,
                      ownerUserId: el.ownerUserId,
                      properties: el.properties,
                      permissions: el.permissions,
                    } as ImageWorkChannelObject;
                  }
                }),
                firstRevertedEditHistory.historyId,
                "redo",
              ),
            );
          }

          break;
        case "delete_group":
          if (firstRevertedEditHistory && firstRevertedEditHistory.oldGroupData) {
            dispatch(
              deleteMultiWorkChannelObjectRequest(
                firstRevertedEditHistory.oldGroupData?.map(el => el.objectId),
                firstRevertedEditHistory.historyId,
                "redo",
              ),
            );
          }

          break;
        case "update_group":
          if (firstRevertedEditHistory && firstRevertedEditHistory.newGroupData)
            dispatch(
              updateMultiWorkChannelObjectPropertiesRequest(
                firstRevertedEditHistory.newGroupData.map(el => {
                  const workChannelObject = getWorkChannelObjectById(el.objectId!)(getState());

                  return { object: workChannelObject!, properties: el.properties };
                }),
                firstRevertedEditHistory.historyId,
                "redo",
              ),
            );

          break;
        default:
          break;
      }
    }
  };
}

export function addWorkChannelSubversionRequest(): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_SUBVERSION_CREATE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
        }),
      );
    }
  };
}

export function addWorkChannelSubversionBroadcast(
  id: number,
  workChannelId: number,
  userId: number,
  createdAt: Date,
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.addWorkChannelSubversionAction({ id, workChannelId, userId, createdAt }));
  };
}

export function deleteWorkChannelSubversionRequest(subversionId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_SUBVERSION_DELETE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          subversionId,
        }),
      );
    }
  };
}

export function deleteWorkChannelSubversionBroadcast(id: number): AppThunk {
  return async (dispatch, getState) => {
    const { previewDetail } = getPreviewDetail(getState());

    if (previewDetail?.id === id) {
      dispatch(updateIsPreview(undefined));
    }

    dispatch(actions.deleteWorkChannelSubversionAction(id));
  };
}

export function restoreWorkChannelSubversionRequest(subversionId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_SUBVERSION_RESTORE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          subversionId,
        }),
      );
    }
  };
}

export function restoreWorkChannelSubversionBroadcast(
  newSubversion: WorkChannelSubversion | undefined,
  workChannel: WorkChannel,
  workChannelObjects: WorkChannelObject[],
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(updateIsPreview(undefined));
    dispatch(
      actions.restoreWorkChannelSubversionAction({
        newSubversion,
        workChannel,
        workChannelObjects,
      }),
    );
  };
}

export function previewWorkChannelSubversionRequest(subversionId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannel(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_SUBVERSION_PREVIEW_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          subversionId,
        }),
      );
    }
  };
}

export function updateIsPreview(state: PreviewType): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsPreviewAction(state));
  };
}

export function previewWorkChannelSubversionResponse(subversionDetail: WorkChannelSubversionDetail): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.previewWorkChannelSubversionAction(subversionDetail));
    dispatch(updateIsPreview("subversion"));
  };
}

export function handleWorkChannelDeleteShoutKey(): AppThunk {
  return async (dispatch, getState) => {
    const selectedObjects = getSelectedWorkChannelObjects(getState());
    const selectedObjectIds = selectedObjects.filter(obj => !obj.properties.isLocked).map(el => el.id);

    if (selectedObjectIds.length > 0) {
      dispatch(deleteMultiWorkChannelObjectRequest(selectedObjectIds));
      dispatch(clearSelectedWorkChannelObjectIds());
    }
  };
}

export function handleWorkChannelSelectAllShoutKey(): AppThunk {
  return async (dispatch, getState) => {
    const workChannelObjectIds = getWorkChannelObjectIds(getState());

    dispatch(actions.updateSelectedWorkChannelObjectIds(workChannelObjectIds));
  };
}

export function updateArrowKeyPress(keyDetail: {
  up: boolean;
  down: boolean;
  left: boolean;
  right: boolean;
}): AppThunk {
  return async (dispatch, getState) => {
    const deltaX =
      keyDetail.left && keyDetail.right
        ? 0
        : keyDetail.left && !keyDetail.right
        ? -1
        : !keyDetail.left && keyDetail.right
        ? 1
        : 0;
    const deltaY =
      keyDetail.up && keyDetail.down
        ? 0
        : keyDetail.up && !keyDetail.down
        ? -1
        : !keyDetail.up && keyDetail.down
        ? 1
        : 0;

    dispatch(actions.updateArrowKeyPressAction({ ...keyDetail, deltaX, deltaY }));
  };
}

export function clearArrowKeyPressDelta(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.clearArrowKeyPressDeltaAction());
  };
}

export function updateMultiDraggingDelta(
  draggingDelta: { id: string; deltaX: number; deltaY: number } | undefined,
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateMultiDraggingDeltaAction(draggingDelta));
  };
}

export function appendMultiDraggingDelta(draggingDelta: { id: string; deltaX: number; deltaY: number }): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.appendMultiDraggingDeltaAction(draggingDelta));
  };
}

export function finishMultiDragging(): AppThunk {
  return async (dispatch, getState) => {
    const multiDraggingDelta = getMultiDraggingDelta(getState());
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());
    const selectedObjects = getSelectedWorkChannelObjects(getState());

    if (multiDraggingDelta && currentWorkspaceId && currentWorkChannelId) {
      const updatedObjects = selectedObjects.map(obj => ({
        ...obj,
        properties: {
          ...obj.properties,
          position: {
            x: obj.properties.position.x + Math.floor(multiDraggingDelta?.deltaX),
            y: obj.properties.position.y + Math.floor(multiDraggingDelta?.deltaY),
          },
        },
      })) as WorkChannelObject[];

      dispatch(actions.finishMultiDraggingAction(updatedObjects));

      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_MULTI_WORK_CHANNEL_OBJECTS_UPDATE_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          updateData: updatedObjects.map(el => ({
            objectId: el.id,
            properties: el.properties,
          })),
        }),
      );
    }
  };
}

export function updateIsTextEditing(isEditing: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsTextEditingAction(isEditing));
  };
}

export function updateIsFontSizeChanging(isFontSizeChanging: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsFontSizeChangingAction(isFontSizeChanging));
  };
}

export function updateSelectionArea(selectionArea: SelectionArea | undefined): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateSelectionAreaAction(selectionArea));
  };
}

export function appendSelectionAreaSize(deltaSize: Size): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.appendSelectionAreaSizeAction(deltaSize));
  };
}

export function deployCustomBackgroundToFloorRequest(url: string, width: number, height: number): AppThunk {
  return (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    if (currentWorkChannelId && currentWorkspaceId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_DEPLOY_CUSTOM_BACKGROUND_TO_FLOOR_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          customBackgroundUrl: url,
          size: { width, height },
        }),
      );
    }
  };
}

export function deployCustomBackgroundToFloorBroadcast(
  voiceChannelId: number,
  newCustomBackground: WorkspaceBackground,
): AppThunk {
  return dispatch => {
    dispatch(createdWorkspaceBackground(newCustomBackground));
    dispatch(channelBackgroundUpdated(voiceChannelId, newCustomBackground.id));
    dispatch(updateIsDeploying(false));
  };
}

export function saveAsTemplateRequest(subversionId: number, thumbUrl: string): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    dispatch(
      sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_SAVE_AS_TEMPLATE_REQUEST, {
        workspaceId: currentWorkspaceId,
        workChannelId: currentWorkChannelId,
        subversionId,
        thumbUrl,
      }),
    );
  };
}

export function saveCurrentStatusAsTemplateRequest(thumbUrl: string): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    dispatch(
      sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_SAVE_CURRENT_STATUS_AS_TEMPLATE_REQUEST, {
        workspaceId: currentWorkspaceId,
        workChannelId: currentWorkChannelId,
        thumbUrl,
      }),
    );
  };
}

export function saveAsTemplateBroadcast(newTemplate: WorkChannelTemplate): AppThunk {
  return async dispatch => {
    dispatch(actions.savedNewWorkChannelTemplateAction(newTemplate));
    Toast.success(
      intl.formatMessage({ id: "floor/template-saved-success", defaultMessage: "Template is successfully saved!" }),
    );
    dispatch(updateIsSavingAsTemplate(false));
  };
}

export function deleteWorkChannelTemplateRequest(templateId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    dispatch(
      sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_DELETE_TEMPLATE_REQUEST, {
        workspaceId: currentWorkspaceId,
        workChannelId: currentWorkChannelId,
        templateId,
      }),
    );
  };
}

export function deletedWorkChannelTemplateBroadcast(templateId: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.deletedWorkChannelTemplateAction(templateId));
  };
}

export function applyWorkChannelTemplateRequest(templateId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannelId(getState());

    dispatch(
      sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_APPLY_TEMPLATE_REQUEST, {
        workspaceId: currentWorkspaceId,
        workChannelId: currentWorkChannelId,
        templateId,
      }),
    );
  };
}

export function appliedWorkChannelTemplateBroadcast(
  workChannel: WorkChannel,
  workChannelObjects: WorkChannelObject[],
): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.appliedWorkChannelTemplateAction({ workChannel, workChannelObjects }));
    dispatch(actions.updateIsPreviewAction(undefined));
  };
}

export function previewWorkChannelTemplateRequest(templateId: number): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());
    const currentWorkChannelId = getCurrentWorkChannel(getState());

    if (currentWorkspaceId && currentWorkChannelId) {
      dispatch(
        sendMessage(WORK_CHANNEL_EVENT_TYPES.OUT_WORK_CHANNEL_TEMPLATE_PREVIEW_REQUEST, {
          workspaceId: currentWorkspaceId,
          workChannelId: currentWorkChannelId,
          templateId,
        }),
      );
    }
  };
}

export function previewWorkChannelTemplateResponse(templateDetail: WorkChannelTemplateDetail): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.previewWorkChannelTemplateAction(templateDetail));
    dispatch(updateIsPreview("template"));
  };
}

export function updateIsRedoingOrUndoing(status: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsRedoingOrUndoingAction(status));
  };
}

export function updateIsFontSizeUpdated(state: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsFontSizeUpdatedAction(state));
  };
}

export function initCroppingImage(object: ImageWorkChannelObject): AppThunk {
  return async (dispatch, getState) => {
    dispatch(
      actions.updateCroppingImageAction({
        id: object.id,
        size: object.properties.size,
        baseSize: object.properties.content.baseSize!,
        position: object.properties.position,
        crop: object.properties.content.crop!,
      }),
    );
  };
}

export function updateCroppingImage(data: CroppingImageInfo): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateCroppingImageAction(data));
  };
}

export function clearCroppingImage(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.clearCroppingImageAction());
  };
}

export function applyCroppingImage(): AppThunk {
  return async (dispatch, getState) => {
    const croppingImageInfo = getCroppingImageInfo(getState());

    if (croppingImageInfo) {
      const croppingObject = getWorkChannelObjectById(croppingImageInfo?.id)(getState());

      if (croppingObject && croppingObject.type === "image")
        dispatch(
          updateWorkChannelObjectPropertiesRequest(croppingObject, {
            position: croppingImageInfo?.position,
            size: croppingImageInfo?.size,
            content: { ...croppingObject.properties.content, crop: croppingImageInfo?.crop },
          }),
        );

      dispatch(actions.clearCroppingImageAction());
    }
  };
}

export function updateIsSavingAsTemplate(state: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsSavingAsTemplateAction(state));
  };
}

export function updateIsDeploying(state: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsDeployingAction(state));
  };
}

export function updateCroppingDirection(direction: Direction | undefined): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateCroppingDirectionAction(direction));
  };
}

export function updateIsRedLineEnabled(state: boolean): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.updateIsRedLineEnabledAction(state));
  };
}

export function updateIsGridEnabled(state: boolean): AppThunk {
  return async dispatch => {
    dispatch(actions.updateIsGridEnabledAction(state));
  };
}
export function updateGridGap(state: number): AppThunk {
  return async dispatch => {
    dispatch(actions.updateGridGapAction(state));
  };
}

export function updateNearGrids(payload: { top?: number; bottom?: number; left?: number; right?: number }): AppThunk {
  return async dispatch => {
    dispatch(actions.updateNearGridsAction(payload));
  };
}

export function updateOrderOfWorkChannelObject(objectId: string, newOrder: number): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object) {
      dispatch(updateWorkChannelObjectPropertiesRequest(object, { order: newOrder }));
    }
  };
}

export function toggleIsLockedOfWorkChannelObject(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object) {
      dispatch(updateWorkChannelObjectPropertiesRequest(object, { isLocked: !object.properties.isLocked }));
    }
  };
}

export function deleteWorkChannelObjectById(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object) {
      dispatch(deleteWorkChannelObjectRequest(object));
    }
  };
}

export function updateFontFamilyOfWorkChannelObject(objectId: string, fontFamily: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, fontFamily },
        }),
      );

      dispatch(updateIsFontSizeUpdated(true));
    }
  };
}

export function updateFontSizeOfWorkChannelObject(objectId: string, fontSize: number): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, fontSize },
        }),
      );

      dispatch(updateIsFontSizeUpdated(true));
    }
  };
}

export function updateColorOfWorkChannelObject(objectId: string, color: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, color },
        }),
      );
    }
  };
}

export function toggleBoldOfWorkChannelObject(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, isBold: !object.properties.content.isBold },
        }),
      );
    }
  };
}

export function toggleItalicOfWorkChannelObject(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, isItalic: !object.properties.content.isItalic },
        }),
      );
    }
  };
}

export function toggleUnderlineOfWorkChannelObject(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, isUnderline: !object.properties.content.isUnderline },
        }),
      );
    }
  };
}

export function toggleStrikethroughOfWorkChannelObject(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          content: { ...object.properties.content, isStrikethrough: !object.properties.content.isStrikethrough },
        }),
      );
    }
  };
}

export function cloneWorkChannelObjectById(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "text") {
      dispatch(copyTextWorkChannelObject(object));
    }

    if (object && object.type === "image") {
      dispatch(copyImageWorkChannelObject(object));
    }
  };
}

export function initCroppingImageById(objectId: string): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "image") {
      dispatch(initCroppingImage(object));
    }
  };
}

export function rotateImageWorkChannelObjectById(objectId: string, direction: "left" | "right"): AppThunk {
  return async (dispatch, getState) => {
    const object = getWorkChannelObjectById(objectId)(getState());

    if (object && object.type === "image") {
      const beforeSize = convertSizeBySideWay({
        rotation: object.properties.rotation,
        width: object.properties.size.width,
        height: object.properties.size.height,
      });
      const afterSize = convertSizeBySideWay({
        rotation:
          direction === "left"
            ? object.properties.rotation > 0
              ? object.properties.rotation - 45
              : 315
            : object.properties.rotation < 315
            ? object.properties.rotation + 45
            : 0,
        ...object.properties.size,
      });
      const deltaX = (beforeSize.width - afterSize.width) / 2;
      const deltaY = (beforeSize.height - afterSize.height) / 2;

      dispatch(
        updateWorkChannelObjectPropertiesRequest(object, {
          rotation: object.properties.rotation > 0 ? object.properties.rotation - 45 : 315,
          position: {
            x: object.properties.position.x + Math.floor(deltaX),
            y: object.properties.position.y + Math.floor(deltaY),
          },
        }),
      );
    }
  };
}
