import {
  updateUserStatus,
  updateUserOnline,
  updateAvatar,
  updateName,
  UpdateAvatar,
  logoutUser,
  UpdateNameResponse,
  updateUserCalendarStatus,
  updateUserBroadcastActive,
  addEmojiReaction,
  resetUserSelectedProject,
  updateUserSpeakerActive,
  updateUserMicActive,
  userHeartbeat,
  updateUserSilenceActive,
  updateUserScreenActiveReceive,
  updateUserActiveWindowByBatch,
  updateUserCalendarScheduleEvents,
  validateIp,
  resetBusyStatusToAvaiable,
  updatedAzureIntegrationValue,
  updatedGoogleIntegrationValue,
  syncCalendarMemoAction,
  sendSignOut,
  receiveMakeMoveToFloor,
  receivedLanguageInconsistencyAlert,
  receivedLanguageInconsistencyAlertsVerbose,
  updateLanguageInconsistencyAlert,
  updateUserAudioExtraction,
  updateUserWebFCMTokenAction,
} from "./actions";
import {
  ActiveWindowData,
  DeviceType,
  LanguageInconsistencyAlert,
  MemberStatus,
  Role,
  SpeechToTextSettings,
  StatusProvider,
} from "../types";
import { getMyMember } from "./selectors";
import {
  doStopScreenShareReceive,
  updatedUserWorkingMemo,
  updateUserRole,
  updateUserSTTSettings,
  ScheduleEvent,
} from ".";
import { updateAccountWorkspaceRole } from "../account";
import { EmojiReactionsType } from "../../components/core/Emoji";
import { getScreenshare, handleViewer } from "../screenshare";

export const USER_EVENT_TYPES = {
  USER_OUT_UPDATE_STATUS_REQUEST: "user/update-status-request",
  USER_IN_UPDATE_STATUS_BROADCAST: "user/update-status-broadcast",
  USER_IN_UPDATE_CALENDAR_STATUS_REPONSE: "user/update-calendar-status-response",
  USER_OUT_MUTE_REQUEST: "user/update-mute-request",
  USER_IN_MUTE_BROADCAST: "user/update-mute-broadcast",
  USER_SILENCE: "user/silence",
  USER_HEARTBEAT: "heartbeat",
  USER_SPEAKER_ACTIVE: "user/speakerActive",
  USER_SCREEN_ACTIVE: "user/screenActive",
  USER_ONLINE: "user/online",
  USER_AVATAR: "user/avatar",
  USER_NAME: "user/name",
  USER_BROADCAST_ACTIVE_REQUEST: "user/update-broadcast-active-request",
  USER_BROADCAST_ACTIVE_BROADCAST: "user/update-broadcast-active-broadcast",
  USER_VIDEO_ACTIVE: "user/videoActive",
  USER_STOP_SCREEN_SHARE: "user/doStopScreenShare",
  USER_NATIVE_NOTIFICATION: "user/nativeNotification",
  USER_ACTIVE_WINDOW: "user/activeWindow",
  USER_ACTIVE_WINDOW_BATCH: "user/activeWindow-batch",
  FORCE_LOGOUT: "user/logout",
  USER_STT_SETTING_BROADCAST: "user/update-sttSetting-broadcast",
  USER_ROLE_UPDATE_BROADCAST: "user/update-user-role-broadcast",
  USER_WORKING_MEMO_UPDATE_REQUEST: "user/update-user-working-memo-request",
  USER_WORKING_MEMO_UPDATE_BROADCAST: "user/update-user-working-memo-broadcast",
  USER_CHAT_DISPLAY_LANGUAGE_REQUEST: "user/update-chat-display-language-request",
  USER_CHAT_DISPLAY_LANGUAGE_BROADCAST: "user/update-chat-display-language-broadcast",
  USER_PERSONAL_SPEAKING_LANGUAGE_REQUEST: "user/update-personal-speaking-language-request",
  USER_PERSONAL_SPEAKING_LANGUAGE_BROADCAST: "user/update-personal-speaking-language-broadcast",
  USER_SPEECH_TO_TEXT_ENABLED_REQUEST: "user/update-speech-to-text-enabled-request",
  USER_SPEECH_TO_TEXT_ENABLED_BROADCAST: "user/update-speech-to-text-enabled-broadcast",
  OUT_USER_REACTION_REQUEST: "user/user-reaction-request",
  IN_USER_REACTION_BROADCAST: "user/user-reaction-broadcast",
  IN_RESET_USER_SELECTED_PROJECT: "user/reset-user-selected-project",
  OUT_USER_SYNC_CALENDAR_WORKING_MEMO_REQUEST: "user/user-sync-calendar-working-memo-request",
  VALIDATE_IP: "user/validate-ip",
  RESET_BUSY_STATUS_RESPONSE: "user/reset-busy-status-response",
  USER_OUT_CALENDAR_EVENTS_REQUEST: "user/calendar-events-request",
  USER_IN_UPDATE_CALENDAR_EVENTS_REPONSE: "user/calendar-events-response",
  USER_AZURE_INTEGRATION_UPDATE_BROADCAST: "users/azure-integration-update-broadcast",
  USER_GOOGLE_INTEGRATION_UPDATE_BROADCAST: "users/google-integration-update-broadcast",
  USER_SYNC_WORKING_MEMO_CALENDAR_UPDATE_BROADCAST: "users/sync-working-memo-with-calendar-update-broadcast",
  USER_MAKE_SIGNOUT_BROADCAST: `users/make-signout-broadcast`,
  USER_MAKE_MOVE_TO_FLOOR_BROADCAST: `users/make-move-to-floor-broadcast`,
  IN_USER_LANGUAGE_INCONSISTENCY_ALERT_RESPONE: "user/language-inconsistency-alert",
  IN_USER_LANGUAGE_INCONSISTENCY_ALERTS_VERBOSE_RESPONE: "user/language-inconsistency-alerts-verbose",
  USER_PERSONAL_LANGUAGE_INCONSISTENCY_ALERT_REQUEST: "user/update-personal-language-inconsistency-alert-request",
  USER_PERSONAL_LANGUAGE_INCONSISTENCY_ALERT_RESPONSE: "user/update-personal-language-inconsistency-alert-response",
  USER_APP_START_UP_PREFERENCE: "user/app-start-up-preference",
  USER_APP_SOUND_EFFECT_PREFERENCE: "user/app-sound-effect-preference",
  USER_IN_UPDATE_AUDIO_EXTRACTION: "user/update-audio-extraction",
  USER_UPDATE_WEB_DEVICE_TOKEN: "user/update-web-device-token",
  USER_WEB_NOTIFICATION_PERMISSION_CHANGE: "user/update-web-notification-permission",
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  const {
    workspaceId,
    userId,
    status,
    googleStatus,
    azureStatus,
    statusProvider,
    online,
    activeWindowDataInFloor,
    connections,
    speechToText,
    role,
    workingMemo,
    broadcastActive,
    voiceChannelId,
    lastActiveAt,
    broadcastId,
    emoji,
    persistent,
    speakerActive,
    mute,
    users,
    silence,
    screenActive,
    isDrawingAllowed,
    message,
    scheduleEvents,
    connectedAzure,
    connectedGoogle,
    syncCalendar,
    languageInconsistencyAlert,
    languageInconsistencyAlertsVerbose,
    enabledLanguageInconsistencyAlert,
    isExtractingAudio,
    webDeviceToken,
  }: {
    workspaceId: number;
    userId: number;
    status: MemberStatus;
    googleStatus: MemberStatus;
    azureStatus: MemberStatus;
    statusProvider: StatusProvider;
    online: boolean;
    users: string[];
    activeWindowName: string;
    activeWindowIcon: string;
    activeWindowDataInFloor: ActiveWindowData[];
    connections: { from: DeviceType; version: string }[];
    speechToText: SpeechToTextSettings;
    role: Role;
    workingMemo: string;
    broadcastActive: boolean;
    voiceChannelId: number;
    reason: string;
    lastActiveAt: Date;
    broadcastId: string;
    emoji: EmojiReactionsType;
    persistent: boolean;
    speakerActive: boolean;
    mute: boolean;
    silence: boolean;
    screenActive: boolean;
    isDrawingAllowed: boolean;
    message: string | undefined;
    scheduleEvents: ScheduleEvent[];
    connectedGoogle: boolean;
    connectedAzure: boolean;
    syncCalendar: boolean;
    languageInconsistencyAlert: LanguageInconsistencyAlert;
    languageInconsistencyAlertsVerbose: any;
    enabledLanguageInconsistencyAlert: boolean;
    isExtractingAudio: boolean;
    webDeviceToken: string | null;
  } = payload;

  const me = getMyMember(store.getState());
  const screenshare = getScreenshare(store.getState());

  switch (type) {
    case USER_EVENT_TYPES.USER_SILENCE:
      if (me?.id !== userId) {
        store.dispatch(updateUserSilenceActive({ workspaceId, userId, silence }));
      }

      break;
    case USER_EVENT_TYPES.FORCE_LOGOUT:
      store.dispatch(logoutUser());
      break;
    case USER_EVENT_TYPES.USER_BROADCAST_ACTIVE_BROADCAST:
      store.dispatch(updateUserBroadcastActive({ broadcastId, userId, voiceChannelId, broadcastActive }));
      break;
    case USER_EVENT_TYPES.USER_IN_UPDATE_STATUS_BROADCAST:
      store.dispatch(
        updateUserStatus({
          userId,
          status,
          googleStatus,
          azureStatus,
          statusProvider,
          workingMemo,
          lastActiveAt,
        }),
      );
      break;
    case USER_EVENT_TYPES.USER_SPEAKER_ACTIVE:
      store.dispatch(updateUserSpeakerActive({ workspaceId, userId, speakerActive }));
      break;

    case USER_EVENT_TYPES.VALIDATE_IP:
      store.dispatch(validateIp());
      break;

    case USER_EVENT_TYPES.USER_HEARTBEAT:
      store.dispatch(userHeartbeat(users));
      break;

    case USER_EVENT_TYPES.USER_ONLINE: {
      store.dispatch(updateUserOnline({ workspaceId, userId, online, connections, lastActiveAt }));
      break;
    }

    case USER_EVENT_TYPES.USER_SCREEN_ACTIVE:
      store.dispatch(updateUserScreenActiveReceive({ workspaceId, userId, screenActive, isDrawingAllowed }));
      break;

    case USER_EVENT_TYPES.USER_STOP_SCREEN_SHARE:
      store.dispatch(doStopScreenShareReceive());
      break;
    case USER_EVENT_TYPES.USER_AVATAR:
      store.dispatch(updateAvatar(payload as UpdateAvatar));
      break;
    case USER_EVENT_TYPES.USER_NAME:
      store.dispatch(updateName(payload as UpdateNameResponse));
      break;
    case USER_EVENT_TYPES.USER_IN_MUTE_BROADCAST:
      store.dispatch(updateUserMicActive({ userId, mute: Boolean(Number(mute)) }));
      break;
    case USER_EVENT_TYPES.USER_ACTIVE_WINDOW_BATCH:
      if (me?.workspaceId === workspaceId) {
        const activeWiondowDataWithoutMe = activeWindowDataInFloor.filter(d => d.userId !== me?.id);

        store.dispatch(updateUserActiveWindowByBatch(activeWiondowDataWithoutMe));

        if (screenshare.mySharedScreen?.id) {
          activeWiondowDataWithoutMe.forEach(d => {
            if (d.activeWindowName) {
              store.dispatch(handleViewer({ userId: d.userId, activeWindowName: d.activeWindowName }));
            }
          });
        }
      }

      break;
    case USER_EVENT_TYPES.USER_ROLE_UPDATE_BROADCAST:
      store.dispatch(updateUserRole({ userId, role }));
      if (me?.id === userId) {
        store.dispatch(updateAccountWorkspaceRole(role));
      }

      break;
    case USER_EVENT_TYPES.USER_WORKING_MEMO_UPDATE_BROADCAST:
      store.dispatch(updatedUserWorkingMemo({ userId, workingMemo }));
      break;
    case USER_EVENT_TYPES.USER_STT_SETTING_BROADCAST:
    case USER_EVENT_TYPES.USER_CHAT_DISPLAY_LANGUAGE_BROADCAST:
    case USER_EVENT_TYPES.USER_PERSONAL_SPEAKING_LANGUAGE_BROADCAST:
    case USER_EVENT_TYPES.USER_SPEECH_TO_TEXT_ENABLED_BROADCAST:
      store.dispatch(updateUserSTTSettings({ userId, speechToText }));
      break;
    case USER_EVENT_TYPES.USER_IN_UPDATE_CALENDAR_STATUS_REPONSE:
      store.dispatch(
        updateUserCalendarStatus({
          workspaceId,
          googleStatus,
          azureStatus,
          workingMemo,
          statusProvider,
          status,
          lastActiveAt,
          userId,
          message,
        }),
      );
      break;
    case USER_EVENT_TYPES.USER_IN_UPDATE_CALENDAR_EVENTS_REPONSE:
      store.dispatch(updateUserCalendarScheduleEvents(scheduleEvents, userId));
      break;
    case USER_EVENT_TYPES.IN_USER_REACTION_BROADCAST:
      store.dispatch(addEmojiReaction({ userId, voiceChannelId, emoji, persistent }));
      break;
    case USER_EVENT_TYPES.IN_RESET_USER_SELECTED_PROJECT:
      store.dispatch(resetUserSelectedProject(workspaceId, userId));
      break;
    case USER_EVENT_TYPES.RESET_BUSY_STATUS_RESPONSE:
      store.dispatch(resetBusyStatusToAvaiable(workspaceId));
      break;
    case USER_EVENT_TYPES.USER_GOOGLE_INTEGRATION_UPDATE_BROADCAST:
      store.dispatch(updatedGoogleIntegrationValue({ connectedGoogle, userId, workspaceId }));
      break;
    case USER_EVENT_TYPES.USER_AZURE_INTEGRATION_UPDATE_BROADCAST:
      store.dispatch(updatedAzureIntegrationValue({ connectedAzure, userId, workspaceId }));
      break;
    case USER_EVENT_TYPES.USER_SYNC_WORKING_MEMO_CALENDAR_UPDATE_BROADCAST:
      store.dispatch(syncCalendarMemoAction({ syncCalendar, userId }));
      break;
    case USER_EVENT_TYPES.USER_MAKE_SIGNOUT_BROADCAST:
      store.dispatch(sendSignOut(userId));
      break;
    case USER_EVENT_TYPES.USER_MAKE_MOVE_TO_FLOOR_BROADCAST:
      store.dispatch(receiveMakeMoveToFloor(userId, workspaceId, voiceChannelId));
      break;
    case USER_EVENT_TYPES.IN_USER_LANGUAGE_INCONSISTENCY_ALERT_RESPONE:
      store.dispatch(receivedLanguageInconsistencyAlert(languageInconsistencyAlert));
      break;
    case USER_EVENT_TYPES.IN_USER_LANGUAGE_INCONSISTENCY_ALERTS_VERBOSE_RESPONE:
      store.dispatch(receivedLanguageInconsistencyAlertsVerbose(languageInconsistencyAlertsVerbose));
      break;
    case USER_EVENT_TYPES.USER_PERSONAL_LANGUAGE_INCONSISTENCY_ALERT_RESPONSE:
      store.dispatch(updateLanguageInconsistencyAlert({ userId, enabledLanguageInconsistencyAlert }));
      break;
    case USER_EVENT_TYPES.USER_IN_UPDATE_AUDIO_EXTRACTION:
      store.dispatch(updateUserAudioExtraction({ userId, isExtractingAudio }));
      break;
    case USER_EVENT_TYPES.USER_UPDATE_WEB_DEVICE_TOKEN:
      store.dispatch(updateUserWebFCMTokenAction({ userId, webDeviceToken }));
      break;
    default:
      break;
  }
};
