import { DeviceSetting, getDeviceSettings } from ".";
import localData from "../../localStorageKeys";
import { log, LogCategory } from "../../utils/log";
import { ActionsUnion, createAction, ActionWithPayload } from "../../utils/redux";
import { AppThunk } from "../types";
import { getMyMember } from "../users";
import { listDevices } from "../../utils/devices";
import { features } from "../../features";
import { getCurrentDevicesList, getIsVirtualBackgroundOn, getNoiseReductionSetting } from "./selectors";
import {
  getMicLivekitLoading,
  getVideoLivekitLoading,
  setVideoLoading,
  switchDevice,
  updateNoiseReduction,
  updateScreenshareConstraints,
} from "../livekit";

enum ActionType {
  UPDATE_VIDEO_INPUT_DEVICES = "UPDATE_VIDEO_INPUT_DEVICES",
  UPDATE_AUDIO_INPUT_DEVICES = "UPDATE_AUDIO_INPUT_DEVICES",
  UPDATE_AUDIO_OUTPUT_DEVICES = "UPDATE_AUDIO_OUTPUT_DEVICES",
  UPDATE_DEVICE_SETTINGS = "UPDATE_DEVICE_SETTINGS",
  UPDATE_NOISE_REDUCTION = "UPDATE_NOISE_REDUCTION",
  OPEN_AUDIO_OUTPUT_DEVICE_SETTING = "OPEN_AUDIO_OUTPUT_DEVICE_SETTING",
  CLEAR_OPEN_AUDIO_OUTPUT_DEVICE_SETTING = "CLEAR_OPEN_AUDIO_OUTPUT_DEVICE_SETTING",
  TOGGLE_AUTO_GAIN_STATUS = "TOGGLE_AUTO_GAIN_STATUS",
  UPDATE_IS_VIRTUAL_BACKGROUND_ON = "UPDATE_IS_VIRTUAL_BACKGROUND_ON",
  UPDATE_RESOLUTION = "UPDATE_RESOLUTION",
  UPDATE_LOCAL_AUDIO_STREAM = "UPDATE_LOCAL_AUDIO_STREAM",
  SET_GUEST_DEVICE_PERMISSION = "SET_GUEST_DEVICE_PERMISSION",
}

export type VideoStreamDeviceInputType = "camera" | "screen";

export const actions = {
  updateVideoInputDevices: (devices: MediaDeviceInfo[]) =>
    createAction(ActionType.UPDATE_VIDEO_INPUT_DEVICES, { devices }),
  updateAudioInputDevices: (devices: MediaDeviceInfo[]) =>
    createAction(ActionType.UPDATE_AUDIO_INPUT_DEVICES, { devices }),
  updateAudioOutputDevices: (devices: MediaDeviceInfo[]) =>
    createAction(ActionType.UPDATE_AUDIO_OUTPUT_DEVICES, { devices }),
  updateDeviceSetting: (deviceSetting: DeviceSetting) =>
    createAction(ActionType.UPDATE_DEVICE_SETTINGS, { deviceSetting }),
  updateNoiseReduction: (noiseReduction: boolean) =>
    createAction(ActionType.UPDATE_NOISE_REDUCTION, { noiseReduction }),
  updateAutoGainStatusAction: (autoGainStatus: boolean) =>
    createAction(ActionType.TOGGLE_AUTO_GAIN_STATUS, { autoGainStatus }),
  updateResolutionAction: (resolution: string, type: VideoStreamDeviceInputType) =>
    createAction(ActionType.UPDATE_RESOLUTION, { resolution, type }),
  updateIsVirtualBackgroundOnAction: (isVirtualBackgroundOn: boolean) =>
    createAction(ActionType.UPDATE_IS_VIRTUAL_BACKGROUND_ON, { isVirtualBackgroundOn }),
  openAudioOutputDeviceSetting: () => createAction(ActionType.OPEN_AUDIO_OUTPUT_DEVICE_SETTING),
  clearOpenAudioOutputDeviceSetting: () => createAction(ActionType.CLEAR_OPEN_AUDIO_OUTPUT_DEVICE_SETTING),
  updateLocalAudioStream: (localAudioStream?: MediaStreamTrack) =>
    createAction(ActionType.UPDATE_LOCAL_AUDIO_STREAM, { localAudioStream }),
  setGuestDevicePermissionAction: (guestDeviceAccessPermission: Boolean) =>
    createAction(ActionType.SET_GUEST_DEVICE_PERMISSION, { guestDeviceAccessPermission }),
};

type Action = ActionsUnion<typeof actions>;

export type { Action as DevicesAction };
export { ActionType as DevicesActionType };

export function getDevicesList(): AppThunk {
  return dispatch => {
    dispatch(updateVideoInputDevices());
    dispatch(updateAudioInputDevices());
    dispatch(updateAudioOutputDevices());
  };
}

export function updateResolution(resolution: string, type: VideoStreamDeviceInputType): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(actions.updateResolutionAction(resolution, type));

    localData.set(`settings.${type}.resolution`, resolution);

    if (type === "screen" && me?.screenActive) {
      dispatch(updateScreenshareConstraints(resolution));
    }
  };
}

export function updateDeviceSetting(payload: Partial<DeviceSetting>): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());

    if (payload.videoinput) {
      localData.set("settings.device.video.input.id", payload.videoinput.id);
      localData.set("settings.device.video.input.label", payload.videoinput.label);
      localData.set("settings.device.video.input.groupId", payload.videoinput.groupId);
    }

    if (payload.audioinput) {
      localData.set("settings.device.audio.input.id", payload.audioinput.id);
      localData.set("settings.device.audio.input.label", payload.audioinput.label);
      localData.set("settings.device.audio.input.groupId", payload.audioinput.groupId);
    }

    if (payload.audiooutput) {
      localData.set("settings.device.audio.output.id", payload.audiooutput.id);
      localData.set("settings.device.audio.output.label", payload.audiooutput.label);
      localData.set("settings.device.audio.output.groupId", payload.audiooutput.groupId);
    }

    const deviceSetting = getDeviceSettings(getState());
    const newAudioInputDevice =
      payload.audioinput &&
      payload.audioinput.label !== deviceSetting.audioinput.label &&
      payload.audioinput.groupId !== deviceSetting.audioinput.groupId;

    dispatch(actions.updateDeviceSetting(payload as DeviceSetting));

    if (me?.voiceChannelId && newAudioInputDevice) {
      log(LogCategory.Devices, "Update mic being called from device switcher", payload);
      dispatch(switchDevice());
    }
  };
}

export function updateNoiseReductionSetting(): AppThunk {
  return (dispatch, getState) => {
    const noiseReductionEnabled = getNoiseReductionSetting(getState());
    const isLoading = getMicLivekitLoading(getState());
    const me = getMyMember(getState());

    if (isLoading || !me) {
      return;
    }

    localData.set("settings.device.audio.noiseReduction", JSON.stringify(!noiseReductionEnabled));
    dispatch(actions.updateNoiseReduction(!noiseReductionEnabled));
    dispatch(updateNoiseReduction(!noiseReductionEnabled));
  };
}

function getDeviceSettingFromStorage() {
  const payload: DeviceSetting = {
    videoinput: {
      id: localData.fetch("settings.device.video.input.id"),
      label: localData.fetch("settings.device.video.input.label"),
      groupId: localData.fetch("settings.device.video.input.groupId"),
    },
    audioinput: {
      id: localData.fetch("settings.device.audio.input.id"),
      label: localData.fetch("settings.device.audio.input.label"),
      groupId: localData.fetch("settings.device.audio.input.groupId"),
    },
    audiooutput: {
      id: localData.fetch("settings.device.audio.output.id"),
      label: localData.fetch("settings.device.audio.output.label"),
      groupId: localData.fetch("settings.device.audio.output.groupId"),
    },
  };

  return payload;
}

export function hydrateDevices(): AppThunk {
  return async dispatch => {
    const payload = getDeviceSettingFromStorage();

    dispatch(actions.updateDeviceSetting(payload));

    const autoGainStatus = localData.fetch("settings.device.audio.input.autoGainStatus");
    const isVirtualBackgroundOn = localData.fetch("settings.device.video.isVirtualBackgroundOn");
    const noiseReduction =
      features.enabled("noiseReduction") && localData.enabled("settings.device.audio.noiseReduction");
    const cameraResolution = localData.fetch("settings.camera.resolution");
    // const screenResolution = localData.fetch("settings.screen.resolution");

    dispatch(actions.updateAutoGainStatusAction(JSON.parse(autoGainStatus)));
    dispatch(actions.updateIsVirtualBackgroundOnAction(JSON.parse(isVirtualBackgroundOn)));
    dispatch(actions.updateResolutionAction(cameraResolution, "camera"));
    // dispatch(actions.updateResolutionAction(screenResolution, "screen"));

    dispatch(updateVideoInputDevices());
    dispatch(updateAudioInputDevices());
    dispatch(updateAudioOutputDevices());

    dispatch(actions.updateNoiseReduction(noiseReduction));
  };
}

export function updateVideoInputDevices(): AppThunk {
  return async dispatch => {
    dispatch(updateDevicesListByType("videoinput", actions.updateVideoInputDevices));
  };
}

export function updateAudioInputDevices(): AppThunk {
  return dispatch => {
    dispatch(updateDevicesListByType("audioinput", actions.updateAudioInputDevices));
  };
}

export function updateAudioOutputDevices(): AppThunk {
  return dispatch => {
    dispatch(updateDevicesListByType("audiooutput", actions.updateAudioOutputDevices));
  };
}

export function callClearOpenAudioOutputDeviceSetting(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(actions.clearOpenAudioOutputDeviceSetting());
  };
}

function updateDevicesListByType(
  kind: MediaDeviceKind,
  updateListAction: (devices: MediaDeviceInfo[]) => ActionWithPayload<any, { devices: MediaDeviceInfo[] }>,
): AppThunk {
  return async (dispatch, getState) => {
    const devicesList = await listDevices(kind);
    const currentDevicesList = getCurrentDevicesList(kind)(getState());
    const currentDeviceSetting = getDeviceSettingFromStorage();

    if (devicesList.length > 0) {
      const isDeviceUpdated = JSON.stringify(devicesList) !== JSON.stringify(currentDevicesList);

      if (isDeviceUpdated && kind === "audiooutput" && currentDevicesList.length > 0) {
        dispatch(actions.openAudioOutputDeviceSetting());
      }

      let nextDevice = null;

      if (isDeviceUpdated) {
        const newDevices = devicesList.filter(
          device =>
            !currentDevicesList
              ?.filter(cd => cd.deviceId !== "")
              .map(cd => cd.groupId)
              .includes(device.groupId),
        );
        const bluetoothDeviceIndex = newDevices.findIndex(el => el.label.toLowerCase().includes("bluetooth"));
        const currentDevice = devicesList.find(el => el.deviceId === currentDeviceSetting[kind].id);

        nextDevice = currentDevice
          ? currentDevice
          : bluetoothDeviceIndex > -1
          ? newDevices[bluetoothDeviceIndex]
          : newDevices.length > 0
          ? newDevices[0]
          : devicesList[0];
      }

      if (nextDevice !== null) {
        dispatch(
          updateDeviceSetting({
            [kind]: { id: nextDevice!.deviceId, label: nextDevice!.label, groupId: nextDevice!.groupId },
          }),
        );
      }
    } else {
      dispatch(
        updateDeviceSetting({
          [kind]: { id: "", label: "", groupId: "" },
        }),
      );
    }

    dispatch(updateListAction(devicesList));
  };
}

export function toggleAutoGainStatus(autoGainStatus: boolean): AppThunk {
  return dispatch => {
    localData.set("settings.device.audio.input.autoGainStatus", JSON.stringify(autoGainStatus));
    dispatch(actions.updateAutoGainStatusAction(autoGainStatus));
  };
}

export function toggleIsVirtualBarckgroundOn(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const isVirtualBackgroundOn = getIsVirtualBackgroundOn(getState());
    const isVideoLoading = getVideoLivekitLoading(getState());

    if (!me || isVideoLoading) return;

    localData.set("settings.device.video.isVirtualBackgroundOn", JSON.stringify(!isVirtualBackgroundOn));

    if (me.videoActive) {
      dispatch(setVideoLoading(true));
    }

    dispatch(actions.updateIsVirtualBackgroundOnAction(!isVirtualBackgroundOn));
  };
}

export function updateLocalAudioStream(localAudioStream?: MediaStreamTrack): AppThunk {
  return (dispatch, getState) => {
    dispatch(actions.updateLocalAudioStream(localAudioStream));
  };
}

export function setGuestDevicePermission(guestDeviceAccessPermission: boolean): AppThunk {
  return dispatch => {
    dispatch(actions.setGuestDevicePermissionAction(guestDeviceAccessPermission));
  };
}
