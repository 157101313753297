export const CREATE_WORKSPACE_MODAL_ID = "create-workspace-modal";
export const PLAN_LIMIT_MODAL_ID = "plan-limit-modal";
export const ADD_COMPANY_MODAL_ID = "add-company-modal";
export const ADD_IP_ADDRESS_MODAL = "add-ip-address-modal";
export const INVITE_USER_MODAL_ID = "invite-user-modal";
export const HANDLE_MANAGE_TEAM_MODAL_ID = "handle-manage-teams-modal";
export const INVITE_USER_CHANNEL_MODAL_ID = "invite-user-voice-channel-modal";
export const CREATE_CHANNEL_MODAL_ID = "create-voice-channel-modal";
export const CREATE_NEW_GROUP_MODAL_ID = "create-new-group-modal-id";
export const UPDATE_CHANNEL_MODAL_ID = "update-voice-channel-modal";
export const DELETE_CHANNEL_MODAL_ID = "delete-voice-channel-modal";
export const SETTINGS_MODAL_ID = "settings-modal";
export const DELETE_ACCOUNT_MODAL_ID = "delete-account-modal";
export const CROP_IMAGE_MODAL_ID = "crop-image-modal";
export const DELETE_WORKSPACE_MODAL_ID = "delete-workspace-modal";
export const LEAVE_WORKSPACE_MODAL_ID = "leave-workspace-modal";
export const DELETE_OBJECT_MODAL_ID = "delete-object-modal";
export const SETTINGS_MODAL_SECTION = "modal-section";
export const DISCONNECT_APP_MODAL_ID = "disconnect-app-modal";
export const ELECTRON_SCREEN_SELECTOR_MODAL_ID = "electron-screen-selector-modal";
export const ELECTRON_REQUIRED_MAC_PERMISSION_MODAL_ID = "electron-required-mac-permission-modal";
export const TRACKER_STOPPED_ALERT_MODAL = "tracker-stopped-alert-modal";
export const QUIT_APP_WHILE_TRACKER_RUNNING_MODAL_ID = "quit-app-while-tracker-running-modal";
export const NEW_VERSION_RELEASE_MODAL_ID = "new-version-release-modal";
export const SIGNOUT_CONFIRMATION_MODAL_ID = "signout-confirmation-modal";
export const RECONNECT_CHANNEL_MODAL_ID = "reconnect-channel-modal";
export const DUPLICATE_LOGIN_MODAL_ID = "duplicate-login-modal";
export const INSTRUCTION_MANUAL_URL = "https://docs.voice-ping.com";
export const INSTRUCTION_MANUAL_URL_EN = "https://support.voice-ping.com";
export const DELETE_BACKGROUND_MODAL_ID = "delete-background-modal";
export const STOP_MEETING_RECORDING_MODAL_ID = "stop-meeting-recording-modal";
export const RECORDING_PLAN_LIMITATION_MODAL_ID = "recording-plan-limitation-modal";
export const WORKSPACE_RECORDING_PLAN_LIMITATION_MODAL_ID = "workspace-recording-plan-limitation-modal";
export const VIRTUAL_OFFICE_URL_OBJECT_MODAL = "virtual-office-url-object-modal";
export const ADD_MANUAL_TIME_MODAL_ID = "add-manual-time-modal";
export const DELETE_TIME_MODAL_ID = "delete-time-modal";
export const SEND_CALL_REQUEST_MODAL_ID = "send-call-request-modal";
export const BROADCAST_START_MODAL_ID = "broadcast-start-modal";
export const REQUEST_REMOTE_DESKTOP_CONTROL_MODAL_ID = "request-desktop-remote-control-modal";
export const APPROVE_REMOTE_DESKTOP_CONTROL_MODAL_ID = "approve-remote-desktop-control-modal";
export const DELCINED_REMOTE_DESKTOP_CONTROL_MODAL_ID = "declined-remote-desktop-control-modal";
export const DELETE_INVOICE_MODAL_ID = "delete-invoice-modal";
export const LEAVE_INVOICE_MODAL_ID = "leave-invoice-modal";
export const VIRTUAL_BACKGROUND_SETTING_MODAL_ID = "virtual-background-setting-modal";
export const DELETE_WORK_CHANNEL_SUBVERSION_MODAL_ID = "delete-work-channel-subversion-modal";
export const RESTORE_WORK_CHANNEL_SUBVERSION_MODAL_ID = "restore-work-channel-subversion-modal";
export const DELETE_CUSTOM_OBJECT_MODAL_ID = "delete-custom-object-modal";
export const DELETE_CUSTOM_BACKGROUND_MODAL_ID = "delete-custom-background-modal";
export const DELETE_WORK_CHANNEL_TEMPLATE_MODAL_ID = "delete-work-channel-template-modal";
export const APPLY_WORK_CHANNEL_TEMPLATE_MODAL_ID = "apply-work-channel-template-modal";
export const MONTH_SELECT_INVOICE_MODAL_ID = "month-select-invoice-modal";
export const DELETE_INVITE_MODAL = "delete-invite-modal";
export const RESEND_INVITE_MODAL = "resend-invite-modal";
export const RECONNECTION_WAITING_MODAL = "reconnection-waiting-modal";
export const QRCODE_MODAL_ID = "qrcode-modal";
export const STOP_AUDIO_EXTRACTION_MODAL = "stop-audio-extraction-modal";
export const WORKSPACE_TIME_TRACKER_LIMITATION_MODAL_ID = "workspace-time-tracker-limitation-modal";
export const CUSTOM_PHRASE_MODAL_ID = "custom-phrase-modal";
export const TRIAL_PLAN_MODAL_ID = "trial-plan-modal";
export const PAID_PLAN_CONFIRMATION_MODAL_ID = "paid-plan-confirmation-modal";
