import { PaymentActionTypes, PaymentActions } from "./action";
import { SubscriptionPlan } from "./type";
import { PaymentMethod } from "../types";

export const paymentInitialState = {
  plans: [] as SubscriptionPlan[],
  currency: "JPY" as "JPY" | "USD",
  paymentMethods: null as PaymentMethod[] | null,
  currentPlan: null as SubscriptionPlan | null,
};

export type PaymentState = typeof paymentInitialState;

export function paymentReducer(state: PaymentState = paymentInitialState, action: PaymentActionTypes): PaymentState {
  switch (action.type) {
    case PaymentActions.SET_STRIPE_PLAN_LIST:
      return {
        ...state,
        plans: action.payload.subscriptionPlans,
        currency: action.payload.currency,
        paymentMethods: action.payload.paymentMethods,
        currentPlan: action.payload.currentPlan,
      };
    case PaymentActions.UPDATE_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
      };
    case PaymentActions.UPDATE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
      };
    default:
      return state;
  }
}
