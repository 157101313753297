import { createdWorkspaceBackground, deletedWorkspaceBackground, gotAllWorkspaceBackgrounds } from "./actions";

import { WORKSPACE_BACKGROUND_EVENT_TYPE_PREFIX, WORKSPACE_BACKGROUND_EVENT_TYPES } from "./actions-enum";

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(WORKSPACE_BACKGROUND_EVENT_TYPE_PREFIX)) {
    return;
  }

  switch (type) {
    case WORKSPACE_BACKGROUND_EVENT_TYPES.WORKSPACE_BACKGROUND_CREATE:
      store.dispatch(createdWorkspaceBackground(payload.workspaceBackground));
      break;
    case WORKSPACE_BACKGROUND_EVENT_TYPES.WORKSPACE_BACKGROUND_DELETE:
      store.dispatch(deletedWorkspaceBackground(payload.id));
      break;
    case WORKSPACE_BACKGROUND_EVENT_TYPES.WORKSPACE_BACKGROUND_GET:
      store.dispatch(gotAllWorkspaceBackgrounds(payload.workspaceBackgrounds));
      break;
  }
};
