import logError from "./logError";

export function zohoManage({
  action,
  email,
  name,
  language,
  userId,
  workspaceId,
}: {
  action: "set" | "reset" | "update";
  email?: string;
  name?: string;
  language?: string;
  userId?: number;
  workspaceId?: number;
}) {
  const zoho = (window as any).$zoho;

  if (!zoho || !zoho.salesiq) return;

  const payload: {
    [key: string]: string | boolean | number | undefined;
    email?: string;
    name?: string;
    language?: string;
    unsubscribed: boolean;
    hardbounce: boolean;
    user_id?: number;
    workspace_id?: number;
  } = {
    email: email,
    name: name,
    language: language,
    unsubscribed: false,
    hardbounce: false,
    user_id: userId,
    workspace_id: workspaceId,
  };

  // remove keys with undefined value
  Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);

  switch (action) {
    case "set":
      // check if already set
      if (zoho.salesiq.values.email) break;

      try {
        if (email && zoho.salesiq.visitor && zoho.salesiq.visitor.email) zoho.salesiq.visitor.email(email);

        if (name && zoho.salesiq.visitor && zoho.salesiq.visitor.name) zoho.salesiq.visitor.name(name);

        if (userId && zoho.salesiq.visitor && zoho.salesiq.visitor.id) zoho.salesiq.visitor.id(userId.toString());

        if (zoho.salesiq.visitor && zoho.salesiq.visitor.info) zoho.salesiq.visitor.info(payload);
      } catch (error) {
        logError("Error setting zoho salesiq", error);
      }
      break;
    case "update":
      try {
        if (email && zoho.salesiq.visitor && zoho.salesiq.visitor.email) zoho.salesiq.visitor.email(email);

        if (name && zoho.salesiq.visitor && zoho.salesiq.visitor.name) zoho.salesiq.visitor.name(name);

        if (zoho.salesiq.visitor && zoho.salesiq.visitor.info) zoho.salesiq.visitor.info(payload);
      } catch (error) {
        logError("Error updating zoho salesiq", error);
      }
      break;
    case "reset":
      try {
        if (zoho.salesiq.reset) zoho.salesiq.reset();
      } catch (error) {
        logError("Error resetting zoho salesiq", error);
      }
      break;
  }
}

export function zohoToggleWindow(action: "show" | "hide") {
  const zoho = (window as any).$zoho;

  if (!zoho || !zoho.salesiq) return;

  try {
    if (zoho.salesiq.floatwindow && zoho.salesiq.floatwindow.visible) {
      zoho.salesiq.floatwindow.visible(action);
    }
  } catch (error) {
    logError("Error opening zoho salesiq window", error);
  }
}
