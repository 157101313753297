import { LivekitParticipant } from "../types";
import { LivekitActions, LivekitRequestsActionTypes } from "./actions";

export type ConnectionStatus = "connected" | "connecting" | "disconnected" | "failed";
export const livekitInitialState: {
  connectionStatus: ConnectionStatus;
  voiceChannelShortId?: string;
  loading: boolean;
  micLoading: boolean;
  videoLoading: boolean;
  screnshareLoading: boolean;
  participants: LivekitParticipant[];
  subscribers: string[];
  broadcasters: string[];
  hasAudioPermission: boolean;
} = {
  connectionStatus: "disconnected",
  voiceChannelShortId: undefined,
  loading: false,
  micLoading: false,
  videoLoading: false,
  screnshareLoading: false,
  participants: [],
  subscribers: [],
  broadcasters: [],
  hasAudioPermission: false,
};

export type LivekitState = typeof livekitInitialState;

export function livekitReducer(state = livekitInitialState, action: LivekitRequestsActionTypes) {
  switch (action.type) {
    case LivekitActions.SET_AUDIO_PERMISSION:
      return { ...state, hasAudioPermission: action.payload.hasAudioPermission };
    case LivekitActions.LIVEKIT_LOADING:
      return { ...state, loading: action.payload.loading };
    case LivekitActions.LIVEKIT_MIC_LOADING:
      return { ...state, micLoading: action.payload.loading };
    case LivekitActions.LIVEKIT_SCREENSHARE_LOADING:
      return { ...state, screnshareLoading: action.payload.loading };
    case LivekitActions.LIVEKIT_VIDEO_LOADING:
      return { ...state, videoLoading: action.payload.loading };
    case LivekitActions.CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: action.payload.connectionStatus,
        voiceChannelShortId: action.payload.voiceChannelShortId,
        subscribers: [],
        broadcasters: [],
        participants: [],
        videoLoading: false,
        micLoading: false,
      };
    case LivekitActions.SET_PARTICIPANTS:
      return { ...state, participants: [...state.participants, ...action.payload.participants] };
    case LivekitActions.SET_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.some(el => el.identity === action.payload.participant.identity)
          ? state.participants.map(el =>
              el.identity === action.payload.participant.identity ? action.payload.participant : el,
            )
          : [...state.participants, action.payload.participant],
      };
    case LivekitActions.REMOVE_PARTICIPANT:
      return {
        ...state,
        participants: state.participants.filter(el => el.identity !== action.payload.identity),
      };
    case LivekitActions.CLEAR_PARTICIPANTS:
      return { ...state, participants: [] };
    case LivekitActions.UPDATE_SUBSCRIBERS:
      return { ...state, subscribers: action.payload.identities };
    case LivekitActions.UPDATE_BROADCASTERS:
      return { ...state, broadcasters: action.payload.identities };
    default:
      return state;
  }
}
