import {
  gotTextToSpeech,
  gotMessages,
  newMessage,
  sendNewTextNotification,
  translatedMessage,
  workspaceSpeechToTextLimitReached,
  updateReachedSttUsage,
  deleteMessageResponse,
  updateMessageResponse,
  addMessageReactionResponse,
  removeMessageReactionResponse,
} from "./actions";

export const TEXT_CHANNEL_EVENT_TYPES = {
  GET_MESSAGES_REQUEST: "textChannel/get-messages-request",
  GET_MESSAGES_RESPONSE: "textChannel/get-messages-response",
  NEW_MESSAGE_REQUEST: "textChannel/new-message-request",
  NEW_MESSAGE_RESPONSE: "textChannel/new-message-response",
  TRANSLATE_MESSAGE_REQUEST: "textChannel/translate-message-request",
  TRANSLATE_MESSAGE_RESPONSE: "textChannel/translate-message-response",
  STT_SESSION_END_REQUEST: "textChannel/stt-session-end-request",

  SPEECH_TO_TEXT_SPEND: "speechToText/spend",
  SPEECH_TO_TEXT_LIMIT_REACHED: "speechToText/limitReached",

  TEXT_TO_SPEECH_GET_REQUEST: "textToSpeech/get-request",
  TEXT_TO_SPEECH_GET_RESPONSE: "textToSpeech/get-response",

  DELETE_MESSAGE_REQUEST: "textChannel/delete-message-request",
  DELETE_MESSAGE_RESPONSE: "textChannel/delete-message-response",

  UPDATE_MESSAGE_REQUEST: "textChannel/update-message-request",
  UPDATE_MESSAGE_RESPONSE: "textChannel/update-message-response",

  ADD_TEXT_MESSAGE_REACTION_REQUEST: "textChannel/add-text-message-reaction-request",
  ADD_TEXT_MESSAGE_REACTION_RESPONSE: "textChannel/add-text-message-reaction-response",

  REMOVE_TEXT_MESSAGE_REACTION_REQUEST: "textChannel/remove-text-message-reaction-request",
  REMOVE_TEXT_MESSAGE_REACTION_RESPONSE: "textChannel/remove-text-message-reaction-response",
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  switch (type) {
    case TEXT_CHANNEL_EVENT_TYPES.GET_MESSAGES_RESPONSE:
      store.dispatch(
        gotMessages({
          textChannelId: payload.textChannelId,
          messages: payload.messages,
          hasMore: payload.hasMore,
          currentOldestMessageId: payload.currentOldestMessageId,
        }),
      );
      break;
    case TEXT_CHANNEL_EVENT_TYPES.NEW_MESSAGE_RESPONSE:
      !payload.stt && store.dispatch(sendNewTextNotification(payload.message));
      store.dispatch(
        newMessage({
          textChannelId: payload.textChannelId,
          message: payload.message,
          stt: payload.stt,
        }),
      );
      break;
    case TEXT_CHANNEL_EVENT_TYPES.SPEECH_TO_TEXT_LIMIT_REACHED:
      store.dispatch(updateReachedSttUsage(true));
      store.dispatch(workspaceSpeechToTextLimitReached(true, true));
      break;
    case TEXT_CHANNEL_EVENT_TYPES.TRANSLATE_MESSAGE_RESPONSE:
      store.dispatch(translatedMessage(payload));
      break;
    case TEXT_CHANNEL_EVENT_TYPES.TEXT_TO_SPEECH_GET_RESPONSE:
      store.dispatch(gotTextToSpeech(payload));
      break;
    case TEXT_CHANNEL_EVENT_TYPES.DELETE_MESSAGE_RESPONSE:
      store.dispatch(deleteMessageResponse(payload));
      break;
    case TEXT_CHANNEL_EVENT_TYPES.UPDATE_MESSAGE_RESPONSE:
      store.dispatch(updateMessageResponse(payload));
      break;
    case TEXT_CHANNEL_EVENT_TYPES.ADD_TEXT_MESSAGE_REACTION_RESPONSE:
      store.dispatch(addMessageReactionResponse(payload));
      break;
    case TEXT_CHANNEL_EVENT_TYPES.REMOVE_TEXT_MESSAGE_REACTION_RESPONSE:
      store.dispatch(removeMessageReactionResponse(payload));
      break;
  }
};
