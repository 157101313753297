import { createSelector } from "reselect";

import { State } from "../../store/state";
import { VersionState } from "./reducer";

export function getVersion(state: State) {
  return state.version;
}

export const getWebVersion = createSelector(getVersion, (version: VersionState) => version.gitInfo.web);

export const getDesktopAppVersion = createSelector(
  getVersion,
  (version: VersionState) => version.desktopAppVersion.current,
);

export const getDesktopAppLatestVersion = createSelector(
  getVersion,
  (version: VersionState) => version.desktopAppVersion.latest,
);

export const downloadInProgress = createSelector(
  getVersion,
  (version: VersionState) => version.desktopAppVersion.downloadProgress,
);

export const notifyPendingVersionUpdate = createSelector(
  getVersion,
  (version: VersionState) =>
    version.desktopAppVersion.latest &&
    !version.desktopAppVersion.downloadProgress &&
    !(version.desktopAppVersion.current === version.desktopAppVersion.latest),
);

export function getIsDesktopLatestVersion(state: State) {
  return state.version.desktopAppVersion.current === state.version.desktopAppVersion.latest;
}

export const getWebAppLatestCommitHash = createSelector(
  getVersion,
  (version: VersionState) => version.latestWebCommitHash,
);

export const newWebVersionExists = createSelector(getWebVersion, getWebAppLatestCommitHash, (current, latest) => {
  return current.loaded && !!latest && current.commit !== latest;
});
