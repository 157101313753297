import { Dispatch } from "redux";
import { SubscriptionPlan } from "./type";
import { PaymentMethod } from "../types";

export enum PaymentActions {
  SET_STRIPE_PLAN_LIST = "payment/SET_STRIPE_PLAN_LIST",
  UPDATE_CURRENCY = "payment/UPDATE_CURRENCY",
  UPDATE_PAYMENT_METHODS = "payment/UPDATE_PAYMENT_METHODS",
}

export type PaymentActionTypes =
  | ReturnType<typeof setStripePlanListAction>
  | ReturnType<typeof updateCurrencyAction>
  | ReturnType<typeof updatePaymentMethodsAction>;

function setStripePlanListAction(payload: {
  subscriptionPlans: SubscriptionPlan[];
  currency: "JPY" | "USD";
  paymentMethods: PaymentMethod[];
  currentPlan: SubscriptionPlan;
}) {
  return {
    type: PaymentActions.SET_STRIPE_PLAN_LIST,
    payload: { ...payload },
  } as const;
}

export function setStripePlanList(payload: {
  subscriptionPlans: SubscriptionPlan[];
  currency: "JPY" | "USD";
  paymentMethods: PaymentMethod[];
  currentPlan: SubscriptionPlan;
}) {
  return (dispatch: Dispatch) => {
    dispatch(setStripePlanListAction(payload));
  };
}

function updateCurrencyAction(payload: { currency: "JPY" | "USD" }) {
  return { type: PaymentActions.UPDATE_CURRENCY, payload: { ...payload } } as const;
}

export function updateCurrency(payload: { currency: "JPY" | "USD" }) {
  return (dispatch: Dispatch) => {
    dispatch(updateCurrencyAction(payload));
  };
}

function updatePaymentMethodsAction(payload: { paymentMethods: PaymentMethod[] }) {
  return { type: PaymentActions.UPDATE_PAYMENT_METHODS, payload: { ...payload } } as const;
}

export function updatePaymentMethods(payload: { paymentMethods: PaymentMethod[] }) {
  return (dispatch: Dispatch) => {
    dispatch(updatePaymentMethodsAction(payload));
  };
}
