import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import { WORKSPACE_BASE_ROUTE, generateListenerSessionExistPage } from "./constants";
import { RouterProps } from "./types";
import { checkIsListener, isAuthenticated } from "../store/account";
import { LoaderWithDescription } from "../components/core/Loader/LoaderWithDescription";
import useUserAuth from "../utils/hooks/useUserAuth";
import { useQuery } from "../utils/hooks/useQuery";

export interface PublicRouterProps extends RouterProps {
  redirect?: boolean;
  step?: boolean;
}

export function PublicRoute({ component: Component, redirect = true, step = false, ...rest }: PublicRouterProps) {
  const authenticated = useSelector(isAuthenticated);
  const isListener = useSelector(checkIsListener);
  const userLoaded = useUserAuth();

  const location = useLocation();
  const path = location.pathname;

  const isListnerURL = path.startsWith("/listener/join");
  const listenerId = path.split("/")[3];
  const query = useQuery();
  const listnerMode = query.get("mode");

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!userLoaded) {
          return <LoaderWithDescription />;
        }

        if (authenticated && isListnerURL) {
          return (
            <Redirect
              to={{
                pathname: generateListenerSessionExistPage(listenerId, listnerMode),
              }}
            />
          );
        }

        if (authenticated && redirect && !isListener) {
          return (
            <Redirect
              to={{
                pathname: WORKSPACE_BASE_ROUTE,
                state: { from: location },
              }}
            />
          );
        }

        if (step) {
          return <Component step />;
        }

        return <Component />;
      }}
    />
  );
}
