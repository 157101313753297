import { MEETING_RECORDING_EVENT_TYPES } from ".";
import { Toast } from "../../components/antd/Toast";
import { NotificationCategory, notifyOnElectron } from "../../electron/notification";
import { intl } from "../../i18n";
import {
  STOP_MEETING_RECORDING_MODAL_ID,
  WORKSPACE_RECORDING_PLAN_LIMITATION_MODAL_ID,
} from "../../screens/Dashboard/constants";
import { showModal } from "../../screens/Dashboard/state";
import { sendMessage } from "../socket";
import { AppThunk, MeetingRecording } from "../types";
import { getMyMember } from "../users";
import { autoRecordingMeetingFeatureAvailedStatusUpdate, getCurrentVoiceChannel } from "../voiceChannels";
import { getMeetingRecordingStatus } from "./selectors";

export enum MeetingRecordingActions {
  GET_MEETING_RECORDINGS = "meeting-recording/GET_MEETING_RECORDING",
  GOT_MEETING_RECORDINGS = "meeting-recording/GOT_MEETING_RECORDING",
  MEETING_RECORDING_START_REQUEST = "meeting-recording/MEETING_RECORDING_START_REQUEST",
  MEETING_RECORDING_STOP_REQUEST = "meeting-recording/MEETING_RECORDING_STOP_REQUEST",
  SET_MEETING_RECORDING_ON_OFF_STATUS = "meeting-recording/SET_MEETING_RECORDING_STATUS",
  SET_LOADING_STATUS = "meeting-recording/SET_LOADING_STATUS",
}

export type MeetingRecordingActionTypes =
  | ReturnType<typeof gotMeetinRecordingsAction>
  | ReturnType<typeof setMeetinRecordingStatusAction>
  | ReturnType<typeof setMeetingRecordingLoadingStatusAction>;

interface GotMeetingRecordings {
  readonly meetingRecordings: MeetingRecording[];
}

export interface MeetinRecordingRequest {
  workspaceId: number;
  voiceChannelId: number;
  meetingRecordingApiEndPoint?: string;
}

export interface StartMeetingRecordingRequest extends MeetinRecordingRequest {
  url: string;
}

interface StartMeetingRecordingResponse extends MeetinRecordingRequest {
  response: boolean;
  isAutoMeetingRecordingFeatureAvailed: boolean;
}

function gotMeetinRecordingsAction(payload: GotMeetingRecordings) {
  return {
    type: MeetingRecordingActions.GOT_MEETING_RECORDINGS,
    payload,
  } as const;
}

export function gotMeetinRecordings(payload: GotMeetingRecordings): AppThunk {
  return dispatch => {
    dispatch(gotMeetinRecordingsAction(payload));
  };
}

export function setMeetinRecordingStatusAction(payload: { status: boolean }) {
  return {
    type: MeetingRecordingActions.SET_MEETING_RECORDING_ON_OFF_STATUS,
    payload,
  } as const;
}

export function startMeetingRecording(payload: StartMeetingRecordingRequest): AppThunk {
  return dispatch => {
    dispatch(sendMessage(MEETING_RECORDING_EVENT_TYPES.OUT_MEETING_RECORDING_START_REQUEST, payload));
  };
}

export function stopMeetingRecording(payload: MeetinRecordingRequest): AppThunk {
  return dispatch => {
    dispatch(sendMessage(MEETING_RECORDING_EVENT_TYPES.OUT_MEETING_RECORDING_STOP_REQUEST, payload));
  };
}

function setMeetingRecordingLoadingStatusAction(payload: { status: boolean }) {
  return {
    type: MeetingRecordingActions.SET_LOADING_STATUS,
    payload,
  } as const;
}

export function setMeetingRecordingLoadingStatus(status: boolean): AppThunk {
  return dispatch => {
    dispatch(setMeetingRecordingLoadingStatusAction({ status }));
  };
}

export function startMeetingRecordingResponse(payload: StartMeetingRecordingResponse): AppThunk {
  return (dispatch, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const me = getMyMember(getState());

    dispatch(setMeetingRecordingLoadingStatus(false));

    dispatch(setMeetinRecordingStatusAction({ status: payload.response }));

    payload.isAutoMeetingRecordingFeatureAvailed &&
      dispatch(
        autoRecordingMeetingFeatureAvailedStatusUpdate({
          status: payload.isAutoMeetingRecordingFeatureAvailed,
          voiceChannelId: payload.voiceChannelId,
        }),
      );

    if (me?.role !== "meeting-recording-bot") {
      notifyOnElectron(
        NotificationCategory.MeetingRecordingEvent,
        payload.response ? "start" : "error",
        currentVoiceChannel!.name,
      );

      payload.response
        ? Toast.success(
            intl.formatMessage({
              id: "meeting-recording/recording-started",
              defaultMessage: "You are recording this meeting!",
            }),
          )
        : Toast.error(
            intl.formatMessage({
              id: "meeting-recording/error",
              defaultMessage: "Not able to proceed your request, please try again!",
            }),
          );
    }
  };
}

export function stopMeetingRecordingRequestProcessing(): AppThunk {
  return dispatch => {
    Toast.success(
      intl.formatMessage({
        id: "meeting-recording/recording-request-sent",
        defaultMessage: "Recording stop request has been sent succesfully.",
      }),
    );
    dispatch(setMeetinRecordingStatusAction({ status: false }));
  };
}

export function stopMeetingRecordingResponse(payload: { response: boolean }): AppThunk {
  return (dispatch, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());

    dispatch(setMeetingRecordingLoadingStatus(false));
    notifyOnElectron(
      NotificationCategory.MeetingRecordingEvent,
      payload.response ? "stop" : "error",
      currentVoiceChannel!.name,
    );

    payload.response
      ? Toast.success(
          intl.formatMessage({
            id: "meeting-recording/recording-stopped",
            defaultMessage: "Recording has been stopped, and the recording file will be created soon",
          }),
        )
      : Toast.error(
          intl.formatMessage({
            id: "meeting-recording/error",
            defaultMessage: "Not able to proceed your request, please try again!",
          }),
        );

    dispatch(showModal({ id: STOP_MEETING_RECORDING_MODAL_ID, show: false }));
  };
}

export function meetingRecordingMaxTimeLimitReached(): AppThunk {
  return dispatch => {
    dispatch(setMeetinRecordingStatusAction({ status: false }));

    Toast.success(
      intl.formatMessage({
        id: "meeting-recording/recording-time-limit-reached",
        defaultMessage:
          "Recording has been stopped due to max recording time limit reached and the recording file will be created soon.",
      }),
    );
  };
}

export function duplicateMeetingRecordingRequest(): AppThunk {
  return dispatch => {
    dispatch(setMeetingRecordingLoadingStatus(false));

    dispatch(setMeetinRecordingStatusAction({ status: true }));
  };
}

export function workspaceMaxRecordingLimitReached(recordingLimit: number): AppThunk {
  return dispatch => {
    dispatch(setMeetingRecordingLoadingStatus(false));
    dispatch(showModal({ id: WORKSPACE_RECORDING_PLAN_LIMITATION_MODAL_ID, show: true, data: { recordingLimit } }));
  };
}

export function meetingRecordingErrorResponse(): AppThunk {
  return (dispatch, getState) => {
    const recordingStatus = getMeetingRecordingStatus(getState());

    if (!recordingStatus) return;

    dispatch(setMeetingRecordingLoadingStatus(false));
    dispatch(setMeetinRecordingStatusAction({ status: false }));

    Toast.error(
      intl.formatMessage({
        id: "meeting-recording/error-response",
        defaultMessage: "Something went wrong with meeting recording, please restart recording to record this meeting",
      }),
    );
  };
}

export function noRecordingFileCreatedResponse(): AppThunk {
  return dispatch => {
    dispatch(setMeetingRecordingLoadingStatus(false));
    dispatch(setMeetinRecordingStatusAction({ status: false }));

    Toast.error(
      intl.formatMessage({
        id: "meeting-recording/no-recording-file",
        defaultMessage: "Recording stopped successfully, but recording was too short to generate recording file.",
      }),
    );
  };
}

export function debugStartMeetingRecording(payload: StartMeetingRecordingRequest): AppThunk {
  return dispatch => {
    dispatch(sendMessage(MEETING_RECORDING_EVENT_TYPES.OUT_DEBUG_MEETING_RECORDING_START_REQUEST, payload));
  };
}
