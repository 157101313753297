import { nanoid } from "nanoid";
import { sendMessage } from "../socket/actions";
import { VIRTUAL_OFFICE_EVENT_TYPES as EVENT_TYPES } from "./middleware";
import { AppThunk, FrontObject, PrevLocation } from "../types";
import {
  getCurrentWorkspaceId,
  getMyMember,
  getOnlineUserById,
  getWorkspaceMemberById,
  showUserWhiteBoardOrTextEditorCreateNotification,
  updateUserAvatarPosition,
} from "../users";
import { ActionsUnion, createAction } from "../../utils/redux";
import {
  VirtualOfficeObject,
  VirtualOfficeObjectType,
  WhiteBoardVirtualOfficeObject,
  YoutubeVideoVirtualOfficeObject,
  VirtualOfficeObjectPermissions,
  Position,
  ScreenShareVirtualOfficeObject,
  TextEditorVirtualOfficeObject,
  VirtualOfficeObjectPermission as Permission,
  VirtualObjectDeltaUpdate,
  MeetingRoomVirtualOfficeObject,
  UrlVirtualOfficeObject,
  Size,
  VirtualObjectDeltaStack,
  CursorObject,
} from "./types";
import { channelJoin, getCurrentVoiceChannel } from "../voiceChannels";
import { doStopScreenShare } from "../screenshare";
import {
  getVirtualOfficeObjects,
  getObjectSpawn,
  getVirtualOfficeFullScreenObject,
  getMyPrevLocation,
  getVirtualOfficeObjectById,
  getActionStackByObjectId,
  getScreenShareObjects,
} from "./selector";
import { Toast } from "../../components/antd/Toast";
import { intl } from "../../i18n";
import { AvatarPositionLocalStorageClient, PrevLocationLocalStorageClient } from "../../localStorageKeys";
import { checkUserInAudibleArea, checkUserIsBroadcaster, getScreensharePublicationByUser } from "../livekit";
import { notification } from "antd";
import { createEtherpadSession, getRevisionCount } from "../../api/etherpad";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { channels } from "../../electron/channels";
import { getAuthenticatedAccount } from "../account";
import { getCurrentWorkspace } from "../workspace";

enum ActionType {
  START_LOADING = "virutalOffice/START_LOADING",
  OBJECTS_LOADED = "virutalOffice/OBJECTS_LOADED",
  CREATE_OBJECT = "virutalOffice/CREATE_OBJECT",
  UPDATE_OBJECT = "virutalOffice/UPDATE_OBJECT",
  DELETE_OBJECT = "virutalOffice/DELETE_OBJECT",
  RECOVER_OBJECT = "virutalOffice/RECOVER_OBJECT",
  BRING_OBJECT_TO_FRONT = "virutalOffice/BRING_OBJECT_TO_FRONT",
  REMOVE_ORDERED_OBJECT = "virutalOffice/REMOVE_ORDERED_OBJECT",
  CLEAR_ORDERED_OBJECTS = "virutalOffice/CLEAR_ORDERED_OBJECTS",
  UPDATE_SPAWN_OBJECT_OFFSET = "virtualOffice/UPDATE_SPAWN_OBJECT_OFFSET",
  UPDATE_FULLSCREEN_OBJECT_ID = "virtualOffice/UPDATE_FULLSCREEN_OBJECT_ID",
  UPDATE_MY_PREV_LOCATION = "virtualOffice/UPDATE_MY_PREV_LOCATION",
  UPDATE_SHOULD_JUMP_BACK = "virtualOffice/UPDATE_SHOULD_JUMP_BACK",
  UPDATE_SCREEN_OVERLAY_DRAWING_STATUS = "virtualOffice/UPDATE_SCREEN_OVERLAY_DRAWING_STATUS",
  ADD_ACTION_TO_STACK = "virtualOffice/ADD_ACTION_TO_STACK",
  APPLY_UNDO_ACTION_TO_STACK = "virtualOffice/APPLY_UNDO_ACTION_TO_STACK",
  APPLY_REDO_ACTION_TO_STACK = "virtualOffice/APPLY_REDO_ACTION_TO_STACK",
  UPDATE_SELECTED_OBJECT_ID = "virtualOffice/UPDATE_SELECTED_OBJECT_ID",
  UPDATE_DRAGGED_OBJECT_ID = "virtualOffice/UPDATE_DRAGGED_OBJECT_ID",
  UPDATE_CURSORS = "virtualOffice/UPDATE_CURSORS",
  REMOVE_CURSOR = "virtualOffice/REMOVE_CURSOR",
}

export const VIRTUAL_OBJECT_DEFAULT_SIZE = { width: 750, height: 420 };
const YOUTUBE_OBJECT_DEFAULT_SIZE = { width: 380, height: 61 };

export const VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE = { width: 500, height: 280 };

type Action = ActionsUnion<typeof actions>;

export const actions = {
  startLoading: () => createAction(ActionType.START_LOADING),
  objectsLoaded: (objects: VirtualOfficeObject[]) => createAction(ActionType.OBJECTS_LOADED, { objects }),
  createObject: (object: VirtualOfficeObject) => createAction(ActionType.CREATE_OBJECT, { object }),
  updateObject: (object: VirtualOfficeObject) => createAction(ActionType.UPDATE_OBJECT, { object }),
  deleteObject: (objectId: string) => createAction(ActionType.DELETE_OBJECT, { objectId }),
  recoverObject: (object: VirtualOfficeObject) => createAction(ActionType.RECOVER_OBJECT, { object }),
  bringObjectToFront: (frontObject: FrontObject) => createAction(ActionType.BRING_OBJECT_TO_FRONT, frontObject),
  removeOrderedObject: (frontObject: FrontObject) => createAction(ActionType.REMOVE_ORDERED_OBJECT, frontObject),
  clearOrderedObjects: () => createAction(ActionType.CLEAR_ORDERED_OBJECTS),
  updateSpawnObjectOffset: (position: { x: number; y: number }, offset: number, limit: number) =>
    createAction(ActionType.UPDATE_SPAWN_OBJECT_OFFSET, { position, offset, limit }),
  updateFullscreenObjectId: (objectId: string | undefined) =>
    createAction(ActionType.UPDATE_FULLSCREEN_OBJECT_ID, { objectId }),
  updateMyPrevLocation: (location: PrevLocation | undefined) =>
    createAction(ActionType.UPDATE_MY_PREV_LOCATION, { location }),
  updateShouldJumpBack: (shouldJumpBack: boolean) =>
    createAction(ActionType.UPDATE_SHOULD_JUMP_BACK, { shouldJumpBack }),
  updateScreenOverlayDrawingStatus: (isDrawingAllowed: boolean, userId: number) =>
    createAction(ActionType.UPDATE_SCREEN_OVERLAY_DRAWING_STATUS, { isDrawingAllowed, userId }),
  addActionToStack: (objectId: string, stack: VirtualObjectDeltaStack<any>) =>
    createAction(ActionType.ADD_ACTION_TO_STACK, { objectId, stack }),
  applyUndoToStack: (objectId: string) => createAction(ActionType.APPLY_UNDO_ACTION_TO_STACK, { objectId }),
  applyRedoToStack: (objectId: string) => createAction(ActionType.APPLY_REDO_ACTION_TO_STACK, { objectId }),
  updateSelectedObjectId: (objectId: string | undefined) =>
    createAction(ActionType.UPDATE_SELECTED_OBJECT_ID, { objectId }),
  updateDraggedObjectId: (objectId: string | undefined) =>
    createAction(ActionType.UPDATE_DRAGGED_OBJECT_ID, { objectId }),
  updateCursors: (cursor: CursorObject) => createAction(ActionType.UPDATE_CURSORS, { cursor }),
  deleteCursor: (userId: number) => createAction(ActionType.REMOVE_CURSOR, { userId }),
};

export type { Action as VirtualOfficeAction };
export { ActionType as VirtualOfficeActionType };

const updateAvatarPositionRestrainment = {
  minInterval: 1_000, //  Need to wait at least 1,000 ms betweeneach update call
  lastTimeout: undefined as number | undefined,
  lastPosition: undefined as Position | undefined,
};

export function updateAvatarPositionRequest(position: Position): AppThunk {
  return async (dispatch, getState) => {
    updateAvatarPositionRestrainment.lastPosition = position;

    if (updateAvatarPositionRestrainment.lastTimeout) return;

    const workspaceId = getCurrentWorkspaceId(getState());
    const me = getMyMember(getState());

    if (workspaceId && me) {
      dispatch(updateUserAvatarPosition({ workspaceId, userId: me.id, position }));
    }

    updateAvatarPositionRestrainment.lastTimeout = window.setTimeout(() => {
      updateAvatarPositionRestrainment.lastTimeout = undefined;

      const workspaceId = getCurrentWorkspaceId(getState());
      const currentVoiceChannel = getCurrentVoiceChannel(getState());

      if (workspaceId && updateAvatarPositionRestrainment.lastPosition) {
        dispatch(
          sendMessage(EVENT_TYPES.OUT_AVATAR_MOVE_REQUEST, {
            workspaceId,
            voiceChannelId: currentVoiceChannel?.id,
            position: updateAvatarPositionRestrainment.lastPosition,
          }),
        );
      }
    }, updateAvatarPositionRestrainment.minInterval);
  };
}

export function createObjectRequest(
  type: "meeting-room",
  properties: MeetingRoomVirtualOfficeObject["properties"],
  permissions: VirtualOfficeObjectPermissions,
): AppThunk;
export function createObjectRequest(
  type: "white-board",
  properties: WhiteBoardVirtualOfficeObject["properties"],
  permissions: VirtualOfficeObjectPermissions,
): AppThunk;
export function createObjectRequest(
  type: "youtube-video",
  properties: YoutubeVideoVirtualOfficeObject["properties"],
  permissions: VirtualOfficeObjectPermissions,
): AppThunk;
export function createObjectRequest(
  type: "text-editor",
  properties: TextEditorVirtualOfficeObject["properties"],
  permissions: VirtualOfficeObjectPermissions,
): AppThunk;
export function createObjectRequest(
  type: "screen-share",
  properties: ScreenShareVirtualOfficeObject["properties"],
  permissions: VirtualOfficeObjectPermissions,
): AppThunk;
export function createObjectRequest(
  type: "url-object",
  properties: UrlVirtualOfficeObject["properties"],
  permissions: VirtualOfficeObjectPermissions,
): AppThunk;
export function createObjectRequest(
  type: VirtualOfficeObjectType,
  properties: any,
  permissions: VirtualOfficeObjectPermissions,
  voiceChannelId?: number,
): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const workspaceId = getCurrentWorkspaceId(getState());
    const voiceChannel = getCurrentVoiceChannel(getState());

    if (!me || !workspaceId || !voiceChannel) return;

    const object = {
      type,
      voiceChannelId: voiceChannelId ? voiceChannelId : voiceChannel.id,
      id: nanoid(),
      ownerUserId: me.id,
      properties,
      permissions,
    } as VirtualOfficeObject;

    if (object.ownerUserId && object.type) {
      dispatch(sendMessage(EVENT_TYPES.OUT_OBJECT_CREATE_REQUEST, { workspaceId, object }));
    }
  };
}

export function recoverObjectRequest(type: "white-board", object: WhiteBoardVirtualOfficeObject): AppThunk;
export function recoverObjectRequest(type: "text-editor", object: TextEditorVirtualOfficeObject): AppThunk;
export function recoverObjectRequest(type: VirtualOfficeObjectType, object: VirtualOfficeObject): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const workspaceId = getCurrentWorkspaceId(getState());
    const voiceChannel = getCurrentVoiceChannel(getState());

    if (!me || !workspaceId || !voiceChannel) return;

    if (object.ownerUserId && object.type) {
      dispatch(sendMessage(EVENT_TYPES.OUT_OBJECT_RECOVER_REQUEST, { workspaceId, object }));
    }
  };
}

export function updateObjectPropertiesRequest<T extends VirtualOfficeObject>(
  object: T,
  properties: Partial<T["properties"]>,
  permissions?: Partial<VirtualOfficeObjectPermissions>,
): AppThunk {
  return async (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    if (!workspaceId) return;

    dispatch(
      // @ts-ignore
      actions.updateObject({
        ...object,
        properties: { ...object.properties, ...properties },
        permissions: { ...object.permissions, ...permissions },
      }),
    );

    dispatch(
      sendMessage(EVENT_TYPES.OUT_OBJECT_UPDATE_REQUEST, {
        workspaceId,
        voiceChannelId: object.voiceChannelId,
        objectId: object.id,
        type: object.type,
        properties,
        permissions,
      }),
    );
  };
}

export function updateObjectWithDeltaRequest<T extends { id: string }>(
  object: VirtualOfficeObject,
  deltas: VirtualObjectDeltaUpdate<T>[],
): AppThunk {
  return async (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    if (!workspaceId) return;

    switch (object.type) {
      case "white-board":
      case "screen-share":
        dispatch(addActionToStack(object.id, extractStachInfo(object, deltas)));
        break;
      default:
        break;
    }

    await dispatch(
      sendMessage(EVENT_TYPES.OUT_OBJECT_UPDATE_DELTA_REQUEST, {
        workspaceId,
        voiceChannelId: object.voiceChannelId,
        objectId: object.id,
        type: object.type,
        deltas,
      }),
    );
  };
}

export function undoStackAction(object: VirtualOfficeObject): AppThunk {
  return (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    if (!workspaceId) return;

    switch (object.type) {
      case "white-board":
      case "screen-share":
        const actionStack = getActionStackByObjectId(object.id)(getState());

        if (actionStack && actionStack.undoStack.length > 0) {
          const lastAction = actionStack.undoStack[actionStack.undoStack.length - 1];
          const undoDeltas = [
            ...lastAction.add.map(e => ({ remove: e })),
            ...lastAction.remove.map(e => ({ add: e })),
            ...lastAction.update.map(e => ({ update: e.before })),
          ];

          dispatch(actions.applyUndoToStack(object.id));
          dispatch(updateLocalObjectWithDelta(object, undoDeltas));
          dispatch(
            sendMessage(EVENT_TYPES.OUT_OBJECT_UPDATE_DELTA_REQUEST, {
              workspaceId,
              voiceChannelId: object.voiceChannelId,
              objectId: object.id,
              type: object.type,
              deltas: undoDeltas,
            }),
          );
        }

        break;
      default:
        break;
    }
  };
}

export function redoStackAction(object: VirtualOfficeObject): AppThunk {
  return (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    if (!workspaceId) return;

    switch (object.type) {
      case "white-board":
      case "screen-share":
        const actionStack = getActionStackByObjectId(object.id)(getState());

        if (actionStack && actionStack.redoStack.length > 0) {
          const lastAction = actionStack.redoStack[actionStack.redoStack.length - 1];
          const redoDeltas = [
            ...lastAction.add.map(e => ({ remove: e })),
            ...lastAction.remove.map(e => ({ add: e })),
            ...lastAction.update.map(e => ({ update: e.before })),
          ];

          dispatch(actions.applyRedoToStack(object.id));
          dispatch(updateLocalObjectWithDelta(object, redoDeltas));
          dispatch(
            sendMessage(EVENT_TYPES.OUT_OBJECT_UPDATE_DELTA_REQUEST, {
              workspaceId,
              voiceChannelId: object.voiceChannelId,
              objectId: object.id,
              type: object.type,
              deltas: redoDeltas,
            }),
          );
        }

        break;
      default:
        break;
    }
  };
}

function extractStachInfo<T extends { id: string }>(
  object: VirtualOfficeObject,
  deltas: VirtualObjectDeltaUpdate<T>[],
): VirtualObjectDeltaStack<T> {
  switch (object.type) {
    case "white-board":
    case "screen-share":
      const deltasAdd = deltas.filter(delta => "add" in delta) as { add: T }[];
      const deltasUpdate = deltas.filter(delta => "update" in delta) as { update: T }[];
      const deltasRemove = deltas.filter(delta => "remove" in delta) as { remove: T }[];

      deltasRemove.forEach(element => {
        delete (element.remove as any).__corner;
        delete (element.remove as any).__originalState;
        delete (element.remove as any).__removed;
        delete (element.remove as any).__version;
        delete (element.remove as any)._cacheCanvas;
        delete (element.remove as any)._cacheContext;
        delete (element.remove as any)._cacheProperties;
        delete (element.remove as any).dirty;
        delete (element.remove as any).evented;
        delete (element.remove as any).isMoving;
        delete (element.remove as any).selectable;
        delete (element.remove as any).zoomX;
        delete (element.remove as any).zoomY;
        delete (element.remove as any).ownCaching;

        delete (element.remove as any).cacheTranslationX;
        delete (element.remove as any).cacheTranslationY;
        delete (element.remove as any).cacheWidth;
        delete (element.remove as any).cacheHeight;
        delete (element.remove as any).cacheHeight;
        delete (element.remove as any).pathOffset;
        delete (element.remove as any)._originalElement;
        delete (element.remove as any)._element;
        delete (element.remove as any).cacheKey;
      });

      const addedObjects = deltasAdd.map(delta => delta.add);
      const updateObjects = deltasUpdate.map(delta => ({
        before: object.properties.content.sketch.objects.find(obj => obj.id === delta.update.id),
        after: delta.update,
      }));
      const removedObjects = deltasRemove.map(delta => delta.remove);

      return {
        add: addedObjects,
        remove: removedObjects,
        update: updateObjects,
      };
    default:
      throw new Error(`Delta updates not supported for '${object.type}'`);
  }
}

export function updateLocalObjectWithDelta<T extends { id: string }>(
  object: VirtualOfficeObject,
  deltas: VirtualObjectDeltaUpdate<T>[],
): AppThunk {
  return async dispatch => {
    switch (object.type) {
      case "white-board":
      case "screen-share":
        await dispatch(updateLocalObjectWithDeltaWhiteBoard(object as WhiteBoardVirtualOfficeObject, deltas));
        break;
      default:
        throw new Error(`Delta updates not supported for '${object.type}'`);
    }
  };
}

function updateLocalObjectWithDeltaWhiteBoard<T extends { id: string }>(
  object: WhiteBoardVirtualOfficeObject,
  deltas: VirtualObjectDeltaUpdate<T>[],
): AppThunk {
  return async dispatch => {
    await dispatch(
      actions.updateObject({
        ...object,
        properties: {
          ...object.properties,
          content: {
            ...object.properties.content,
            sketch: {
              ...object.properties.content.sketch,
              objects: applyDelta(object.properties.content.sketch.objects, deltas),
            },
          },
        },
      }),
    );
  };
}

function applyDelta<T extends { id: string }>(array: T[], deltas: VirtualObjectDeltaUpdate<T>[]): T[] {
  const deltasAdd = deltas.filter(delta => "add" in delta) as { add: T }[];
  const deltasUpdate = deltas.filter(delta => "update" in delta) as { update: T }[];
  const deltasRemove = deltas.filter(delta => "remove" in delta) as { remove: T }[];

  const addedObjects = deltasAdd.map(delta => delta.add);
  const updatedObjects = Object.fromEntries(deltasUpdate.map(delta => [delta.update.id, delta.update]));
  const removedObjects = new Set(deltasRemove.map(delta => delta.remove.id));

  return [
    ...array
      .map(o => updatedObjects[o.id] ?? o) // update existing objects
      .filter(o => !removedObjects.has(o.id)), // remove existing objects
    ...addedObjects, // add new objects
  ];
}

export function deleteObjectRequest(object: VirtualOfficeObject): AppThunk {
  return async (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());
    const me = getMyMember(getState());
    const screensharePublication = getScreensharePublicationByUser(me?.identity)(getState());

    if (!workspaceId) return;

    dispatch(sendMessage(EVENT_TYPES.OUT_OBJECT_DELETE_REQUEST, { workspaceId, object }));

    if (object.type === "screen-share" && !!screensharePublication) {
      dispatch(doStopScreenShare());
    }
  };
}

export function createObjectBroadcast(object: VirtualOfficeObject): AppThunk {
  return async (dispatch, getState) => {
    const voiceChannel = getCurrentVoiceChannel(getState());
    const me = getMyMember(getState());
    const user = getOnlineUserById(object.ownerUserId)(getState());
    const isSubscribed = checkUserInAudibleArea(user?.identity)(getState());
    const isBroadcaster = checkUserIsBroadcaster(user?.identity)(getState());

    if (voiceChannel?.id !== object.voiceChannelId) return;

    await dispatch(actions.createObject(object));
    dispatch(bringToFront({ objectId: object.id, type: "object" }));
    dispatch(updateSelectedObjectId(object.id));

    if (
      window.electron &&
      (object.type === "white-board" || object.type === "text-editor") &&
      object.ownerUserId !== me?.id
    ) {
      dispatch(
        showUserWhiteBoardOrTextEditorCreateNotification({
          object: object,
          userId: object.ownerUserId,
          workspaceId: voiceChannel.workspaceId,
        }),
      );
    }

    setTimeout(() => {
      if (
        (object.ownerUserId !== me?.id &&
          (object.type === "screen-share" || object.type === "white-board" || object.type === "text-editor") &&
          (isSubscribed || isBroadcaster)) ||
        (object.ownerUserId === me?.id && (object.type === "text-editor" || object.type === "white-board"))
      ) {
        dispatch(toggleFullscreenObjectId(object));
      }
    }, 1000);
  };
}

export function recoverObjectBroadcast(object: VirtualOfficeObject, userId: number): AppThunk {
  return async (dispatch, getState) => {
    const voiceChannel = getCurrentVoiceChannel(getState());
    const user = getWorkspaceMemberById(userId)(getState());

    if (voiceChannel?.id !== object.voiceChannelId) return;

    await dispatch(actions.recoverObject(object));

    if (object?.type === "white-board" || object?.type === "text-editor") {
      notification.close(object.id);
      switch (object.type) {
        case "white-board":
          Toast.success(
            intl.formatMessage(
              {
                id: "voice-channel-member/recover-white-board-success-message",
                defaultMessage: "{userName} recovers white board successfully.",
              },
              { userName: user?.name },
            ),
          );
          break;
        case "text-editor":
          Toast.success(
            intl.formatMessage(
              {
                id: "voice-channel-member/recover-text-editor-success-message",
                defaultMessage: "{userName} recovers text editor successfully.",
              },
              { userName: user?.name },
            ),
          );
          break;
        default:
          break;
      }
    }
  };
}

export function updateObjectPropertiesBroadcast(object: VirtualOfficeObject): AppThunk {
  return async (dispatch, getState) => {
    const voiceChannel = getCurrentVoiceChannel(getState());

    if (voiceChannel?.id !== object.voiceChannelId) return;

    await dispatch(actions.updateObject(object));
  };
}

export function updateObjectWithDeltaBroadcast<T extends { id: string }>(
  objectId: string,
  deltas: VirtualObjectDeltaUpdate<T>[],
): AppThunk {
  return (dispatch, getState) => {
    const allObjects = getVirtualOfficeObjects(getState());
    const updatedObject = allObjects.find(o => o.id === objectId);

    if (!updatedObject) return;

    dispatch(updateLocalObjectWithDelta(updatedObject, deltas));
  };
}

export function deleteObjectBroadcast(objectId: string, userId: number): AppThunk {
  return async (dispatch, getState) => {
    const object = getVirtualOfficeObjectById(objectId)(getState());
    const user = getWorkspaceMemberById(userId)(getState());
    const me = getMyMember(getState());

    dispatch(removeFromOrderedObject({ objectId, type: "object" }));
    dispatch(actions.deleteObject(objectId));

    if (object?.type === "white-board" || object?.type === "text-editor") {
      switch (object.type) {
        case "white-board":
          const isNotEdited =
            object.properties.content.sketch.objects.length === 0 &&
            object.properties.content.sketch.background === "white" &&
            !object.properties.content.sketch.backgroundImage;

          if (!isNotEdited) {
            Toast.success(
              intl.formatMessage(
                {
                  id: "voice-channel-member/delete-white-board-success-message",
                  defaultMessage: "{userName} deleted white board.",
                },
                { userName: user?.name },
              ),
              me?.role === "owner" || me?.role === "manager" || me?.role === "member"
                ? {
                    title: intl.formatMessage({ id: "undo", defaultMessage: "Undo" }),
                    handler: () => {
                      dispatch(recoverObjectRequest("white-board", object));
                    },
                  }
                : undefined,
              objectId,
            );
          }

          break;
        case "text-editor":
          const revisions = await getRevisionCount(object.properties.content.groupPadId);

          if (revisions > 0) {
            Toast.success(
              intl.formatMessage(
                {
                  id: "voice-channel-member/delete-text-editor-success-message",
                  defaultMessage: "{userName} deleted text editor.",
                },
                { userName: user?.name },
              ),
              me?.role === "owner" || me?.role === "manager" || me?.role === "member"
                ? {
                    title: intl.formatMessage({ id: "undo", defaultMessage: "Undo" }),
                    handler: () => {
                      dispatch(recoverObjectRequest("text-editor", object));
                    },
                  }
                : undefined,
              objectId,
            );
          }

          break;
        default:
          break;
      }
    }
  };
}

export function startLoadingVirtualOffice(): AppThunk {
  return async dispatch => {
    dispatch(actions.startLoading());
  };
}

export function listObjectsResponse(objects: VirtualOfficeObject[]): AppThunk {
  return async (dispatch, getState) => {
    const currentWorkspace = getCurrentWorkspace(getState());
    const account = getAuthenticatedAccount(getState());
    const etherpadAuthorId = account.etherpadAuthorId;

    if (etherpadAuthorId && currentWorkspace && currentWorkspace.etherpadGroupId) {
      createEtherpadSession(currentWorkspace.etherpadGroupId, etherpadAuthorId);
    }

    dispatch(actions.objectsLoaded(objects));

    const screenshareObject = objects.find(el => el.type === "screen-share");

    if (screenshareObject) {
      dispatch(bringToFront({ objectId: screenshareObject.id, type: "object" }));
    }
  };
}

export function toggleFullscreenObjectId(object: VirtualOfficeObject): AppThunk {
  return async (dispatch, getState) => {
    const objectId = object.id;
    const currentFullscreenObject = getVirtualOfficeFullScreenObject(getState());

    dispatch(actions.updateFullscreenObjectId(objectId !== currentFullscreenObject?.id ? objectId : undefined));
  };
}

export function bringToFront(frontObject: FrontObject): AppThunk {
  return async dispatch => {
    dispatch(actions.bringObjectToFront(frontObject));
  };
}

export function removeFromOrderedObject(frontObject: FrontObject): AppThunk {
  return async dispatch => {
    dispatch(actions.removeOrderedObject(frontObject));
  };
}

export function clearOrderedObjects(): AppThunk {
  return async dispatch => {
    dispatch(actions.clearOrderedObjects());
  };
}

export function resetSpawnObject(position: { x: number; y: number }, limit: number): AppThunk {
  return async dispatch => {
    dispatch(actions.updateSpawnObjectOffset(position, 0, limit));
  };
}

export function incrementSpawnObjectOffset(): AppThunk {
  return async (dispatch, getState) => {
    const objectSpawn = getObjectSpawn(getState());

    dispatch(
      actions.updateSpawnObjectOffset(
        objectSpawn.position,
        objectSpawn.offset > objectSpawn.limit / 2 - 50 ? 0 : objectSpawn.offset + 50,
        objectSpawn.limit,
      ),
    );
  };
}

function addWhiteBoard(position: Position, size: Size): AppThunk {
  return async dispatch => {
    const { properties, permissions } = {
      properties: {
        content: {
          sketch: { objects: [], background: "white" },
          originCanvasSize: { width: size.width! - 2, height: size.height! - 50 },
        },
        position,
        size,
      },
      permissions: {
        list: Permission.All,
        read: Permission.All,
        write: Permission.All,
        move: Permission.All,
        resize: Permission.All,
        remove: Permission.All,
        changePermission: Permission.ObjectOwner,
      },
    };

    dispatch(createObjectRequest("white-board", properties, permissions));
  };
}

function addYoutubeVideo(position: Position, size: Size): AppThunk {
  return async dispatch => {
    const { properties, permissions } = {
      properties: {
        content: { url: null, offset: 0, startedAt: null },
        position,
        size,
      },
      permissions: {
        list: Permission.All,
        read: Permission.All,
        write: Permission.All,
        move: Permission.All,
        resize: Permission.None,
        remove: Permission.All,
        changePermission: Permission.ObjectOwner,
      },
    };

    dispatch(createObjectRequest("youtube-video", properties, permissions));
  };
}

export function limitSizeForImageObject(objectSize?: Size) {
  let size = VIRTUAL_OBJECT_DEFAULT_SIZE;

  if (objectSize) {
    if (objectSize.width! >= objectSize.height! && objectSize.width! >= VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.width) {
      size.width = VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.width;
      size.height = (VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.width * objectSize.height!) / objectSize.width!;
    } else if (
      objectSize.width! <= objectSize.height! &&
      objectSize.height! >= VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.height
    ) {
      size.height = VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.height;
      size.width = (VIRTUAL_IMAGE_OBJECT_DEFAULT_SIZE.height * objectSize.width!) / objectSize.height!;
    } else {
      size = { width: objectSize.width!, height: objectSize.height! };
    }
  }

  return size;
}

export function addUrlObject(content: {
  iframe?: string;
  url?: string;
  name?: string;
  image?: string;
  file?: string;
  originalFile?: string;
  objectSize: Size;
  positionLock: boolean;
  transparent: boolean;
  dropPosition?: Position;
}): AppThunk {
  return async (dispatch, getState) => {
    const objectSpawn = getObjectSpawn(getState());
    const position = content.dropPosition ?? {
      x: objectSpawn.position.x + objectSpawn.offset - content.objectSize.width! / 2,
      y: objectSpawn.position.y + objectSpawn.offset - content.objectSize.height! / 2,
    };

    const { properties, permissions } = {
      properties: {
        content,
        position,
        size: content.objectSize,
        hideFrame: !content.iframe,
      },
      permissions: {
        list: Permission.All,
        read: Permission.All,
        write: Permission.All,
        move: content.positionLock ? Permission.None : Permission.All,
        resize: content.positionLock ? Permission.None : Permission.All,
        remove: Permission.All,
        changePermission: Permission.All,
      },
    };

    dispatch(createObjectRequest("url-object", properties, permissions));
  };
}

function addTextEditor(position: Position, size: Size): AppThunk {
  return async dispatch => {
    const { properties, permissions } = {
      properties: {
        content: { groupPadId: "" },
        position,
        size,
      },
      permissions: {
        list: Permission.All,
        read: Permission.All,
        write: Permission.All,
        move: Permission.All,
        resize: Permission.All,
        remove: Permission.All,
        changePermission: Permission.ObjectOwner,
      },
    };

    dispatch(createObjectRequest("text-editor", properties, permissions));
  };
}

export function addVirtualOfficeObject(objectType?: VirtualOfficeObjectType): AppThunk {
  return async (dispatch, getState) => {
    const objectSpawn = getObjectSpawn(getState());

    const size = objectType === "youtube-video" ? YOUTUBE_OBJECT_DEFAULT_SIZE : VIRTUAL_OBJECT_DEFAULT_SIZE;

    const position = {
      x: objectSpawn.position.x + objectSpawn.offset - size.width / 2,
      y: objectSpawn.position.y + objectSpawn.offset - size.height / 2,
    };

    dispatch(incrementSpawnObjectOffset());

    switch (objectType) {
      case "white-board":
        dispatch(addWhiteBoard(position, size));
        break;
      case "youtube-video":
        dispatch(addYoutubeVideo(position, size));
        break;
      case "text-editor":
        dispatch(addTextEditor(position, size));
        break;
      default:
        throw new Error("Invalid object creation request");
    }
  };
}

export function updateMyPrevLocation(location: PrevLocation | undefined, showToast?: boolean): AppThunk {
  return async dispatch => {
    dispatch(actions.updateMyPrevLocation(location));
    if (showToast) {
      Toast.success(
        intl.formatMessage({
          id: "voice-channel-member/save-location-success-message",
          defaultMessage: "Your current locations is saved successfully!",
        }),
      );
    }
  };
}

export function updateShouldJumpBack(shouldJumpBack: boolean): AppThunk {
  return async dispatch => {
    dispatch(actions.updateShouldJumpBack(shouldJumpBack));
  };
}

export function handleSaveLocationShoutKey(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.workspaceId && me.voiceChannelId) {
      const prevLocationClient = new PrevLocationLocalStorageClient(me.workspaceId);

      prevLocationClient.setData({ voiceChannelId: me.voiceChannelId, position: me.position });
      dispatch(updateMyPrevLocation({ voiceChannelId: me.voiceChannelId, position: me.position }, true));
    }
  };
}

export function handleJumpBackShoutKey(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const myPrevLocation = getMyPrevLocation(getState());
    const isAbleToJumpBack = myPrevLocation && myPrevLocation.voiceChannelId && myPrevLocation.position;

    if (isAbleToJumpBack && me?.workspaceId && myPrevLocation && currentVoiceChannel?.id) {
      const positionClient = new AvatarPositionLocalStorageClient(currentVoiceChannel.id);

      positionClient.setData(myPrevLocation.position);
      if (me?.voiceChannelId !== myPrevLocation.voiceChannelId) {
        dispatch(updateShouldJumpBack(true));
        dispatch(channelJoin({ workspaceId: me?.workspaceId!, voiceChannelId: myPrevLocation.voiceChannelId }));
        dispatch(doStopScreenShare());
      } else {
        dispatch(updateShouldJumpBack(true));
        dispatch(updateAvatarPositionRequest(myPrevLocation.position));
      }
    }
  };
}

export function setremoteScreenShareToFullScreen(object: VirtualOfficeObject): AppThunk {
  return async (dispatch, getState) => {
    const objectId = object.id;

    dispatch(actions.updateFullscreenObjectId(objectId));
  };
}

export function updateScreenOverlayDrawingStatus(
  isDrawingAllowed: boolean,
  voiceChannelId: number,
  objectId: string,
): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(EVENT_TYPES.OUT_UPDATE_SCREEN_OVERLAY_DRAWING_STATUS, {
        isDrawingAllowed,
        voiceChannelId,
        objectId,
      }),
    );
  };
}

export function updateScreenOverlayDrawingStatusResponse({
  isDrawingAllowed,
  userId,
}: {
  isDrawingAllowed: boolean;
  userId: number;
}): AppThunk {
  return (dispatch, getState) => {
    dispatch(actions.updateScreenOverlayDrawingStatus(isDrawingAllowed, userId));
  };
}

export function addActionToStack<T extends { id: string }>(
  objectId: string,
  stack: VirtualObjectDeltaStack<T>,
): AppThunk {
  return (dispatch, getState) => {
    dispatch(actions.addActionToStack(objectId, stack));
  };
}

export function updateSelectedObjectId(objectId: string | undefined): AppThunk {
  return (dispatch, getState) => {
    dispatch(actions.updateSelectedObjectId(objectId));
  };
}

export function requestToEnableDrawing(userId: number): AppThunk {
  return (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(EVENT_TYPES.OUT_ENABLE_SCREEN_DRAWING_REQUEST, {
        userId,
        workspaceId,
      }),
    );
  };
}

export function responseToEnableDrawing(): AppThunk {
  return (dispatch, getState) => {
    const me = getMyMember(getState());
    const sharedScreenObjects = getScreenShareObjects(getState());
    const myScreenshareObject = sharedScreenObjects.find(
      s => s.ownerUserId === me?.id && s.type === "screen-share",
    ) as ScreenShareVirtualOfficeObject;

    if (myScreenshareObject && me?.voiceChannelId && !myScreenshareObject.properties.content.isDrawingAllowed) {
      sendMessageOverIPC(channels.ALLOW_DRAWING_ON_OVERLAY_WINDOW, {
        desiredScreenIndex: myScreenshareObject.properties.content.screenIndex,
      });

      dispatch(updateScreenOverlayDrawingStatus(true, me.voiceChannelId, myScreenshareObject.id));
    }
  };
}

export function updateDraggedObjectId(objectId: string | undefined): AppThunk {
  return (dispatch, getState) => {
    dispatch(actions.updateDraggedObjectId(objectId));
  };
}

export function updateCursorPosition(userId: number, userName: string, objectId: string, position: Position): AppThunk {
  return dispatch => {
    const payload = {
      userId,
      userName,
      objectId,
      position,
    } as CursorObject;

    dispatch(actions.updateCursors(payload));
  };
}

export function removeCursor(userId: number): AppThunk {
  return dispatch => {
    dispatch(actions.deleteCursor(userId));
  };
}

export function requestCursorMoveOnObject(
  userIds: number[],
  userName: string,
  objectId: string,
  position: Position,
): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(EVENT_TYPES.OUT_OBJECT_CURSOR_MOVE_REQUEST, {
        userIds,
        userName,
        objectId,
        position,
      }),
    );
  };
}

export function requestCursorRemoveOnObject(userIds: number[]): AppThunk {
  return dispatch => {
    dispatch(
      sendMessage(EVENT_TYPES.OUT_OBJECT_CURSOR_REMOVE_REQUEST, {
        userIds,
      }),
    );
  };
}
