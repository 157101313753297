import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import { RouterProps } from "./types";
import { USER_LOGIN_ROUTE_PATH, generateListenerSessionExistPage } from "./constants";
import { checkIsListener, isAuthenticated, setRedirectPath } from "../store/account";
import useUserAuth from "../utils/hooks/useUserAuth";
import { AppLoader } from "../components/containers";
import { useQuery } from "../utils/hooks/useQuery";

export function PrivateRoute({ component: Component, ...rest }: RouterProps) {
  const dispatch = useDispatch();
  const authenticated = useSelector(isAuthenticated);
  const userLoaded = useUserAuth();
  const isListener = useSelector(checkIsListener);
  const location = useLocation();
  const path = location.pathname;
  const isListnerURL = path.startsWith("/listener/join");
  const listenerId = path.split("/")[3];
  const query = useQuery();
  const listnerMode = query.get("mode");

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!userLoaded) {
          return <AppLoader />;
        }

        // added 2024-05-23 for session handling
        if (isListener) {
          return <Component />;
        }

        if (!authenticated || (isListener && !rest.canListenerAccess)) {
          dispatch(setRedirectPath(rest.location ? `${rest.location.pathname}${rest.location.search}` : null));

          return (
            <Redirect
              to={{
                pathname: USER_LOGIN_ROUTE_PATH,
                state: { from: location },
              }}
            />
          );
        }

        if (authenticated && isListnerURL) {
          window.location.href = generateListenerSessionExistPage(listenerId, listnerMode);
        }

        return <Component />;
      }}
    />
  );
}
