import { ActivityDetectionActionTypes as ActionTypes, ActivityDetectionActions as Actions } from "./actions";

export type { State as ActivityDetectionState };
export { initialState as activityDetectionInitialState, reducer as activityDetectionReducer };

export type reasonsForBeingSetAway = "idle" | "lock" | "sleep";
export type reasonsForBeingSetResume = "unlock" | "resume";

const initialState = {
  reasonForBeingSetAway: "idle",
  reasonForBeingSetResume: "resume",
  isWorkingBeforeAway: false,
};

type State = typeof initialState;

function reducer(state: State = initialState, action: ActionTypes): State {
  switch (action.type) {
    case Actions.REASON_FOR_AWAY:
      return {
        ...state,
        reasonForBeingSetAway: action.payload.reasonForBeingSetAway,
      };
    case Actions.REASON_FOR_RESUME:
      return {
        ...state,
        reasonForBeingSetResume: action.payload.reasonForBeingSetResume,
      };
    case Actions.IS_WORKING_BEFORE_AWAY:
      return {
        ...state,
        isWorkingBeforeAway: action.payload.isWorkingBeforeAway,
      };
    default:
      return state;
  }
}
