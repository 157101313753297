import { Dispatch } from "redux";
import { AppThunk } from "../types";
import { ApiError } from "../../utils/hooks/useApi";
import { createRequest } from "../../utils/network";
import { CHECK_INVITATION_TOKEN } from "../../constant";

export enum InvitationJoinActions {
  SHOW_LOADER = "invitationJoin/SHOW_LOADER",
  SET_ERRORS = "invitationJoin/SET_ERRORS",
  SET_INVITED_USER_EMAIL = "invitationJoin/SET_INVITED_USER_EMAIL",
  SET_TOKEN_RESPONSE = "invitationJoin/SET_TOKEN_RESPONSE",
}

export type InvitationJoinActionsTypes =
  | ReturnType<typeof showLoader>
  | ReturnType<typeof setErrors>
  | ReturnType<typeof setTokenResponse>;

export function validateInvitationToken(token: string | undefined): AppThunk {
  return async (dispatch: Dispatch) => {
    if (!token) {
      return;
    }

    dispatch(showLoader(true));
    const { response } = await createRequest(CHECK_INVITATION_TOKEN(token), {
      method: "GET",
    });

    if (response.data.email) {
      dispatch(
        setTokenResponse({ invitedUserEmail: response.data.email, isUserRegistered: !!response.data.isUserRegistered }),
      );
    }

    dispatch(setErrors(response.errors));
    dispatch(showLoader(false));
  };
}

function setErrors(payload: ApiError<any>[]) {
  return { type: InvitationJoinActions.SET_ERRORS, payload } as const;
}

function showLoader(payload: boolean) {
  return { type: InvitationJoinActions.SHOW_LOADER, payload } as const;
}

function setTokenResponse(payload: { invitedUserEmail: string; isUserRegistered: boolean }) {
  return { type: InvitationJoinActions.SET_TOKEN_RESPONSE, payload } as const;
}
