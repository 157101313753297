import { updateAccountAssignedFloors, updateAccountProjectsBroadCast, updateMemberRole } from "../account";
import { FloorDetailData, ProjectDetailData, Role } from "../types";
import { updateUsersAssignedFloors } from "../users";
import { addGroupResponse, addProjectResponse } from "./actions";
import { GroupData, ProjectData } from "./types";

const MEMBER_EVENT_TYPE_PREFIX = "member/";

export const MEMBER_EVENT_TYPES = {
  PROJECT_OUT_CHANGE_BROADCAST: `${MEMBER_EVENT_TYPE_PREFIX}project-changed-broadcast`,
  FLOOR_OUT_CHANGE_BROADCAST: `${MEMBER_EVENT_TYPE_PREFIX}floor-changed-broadcast`,
  ROLE_OUT_CHANGE_BROADCAST: `${MEMBER_EVENT_TYPE_PREFIX}role-changed-broadcast`,
  ADD_PROJECT_RESPONSE: `${MEMBER_EVENT_TYPE_PREFIX}add-project-response`,
  ADD_GROUP_RESPONSE: `${MEMBER_EVENT_TYPE_PREFIX}add-group-response`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(MEMBER_EVENT_TYPE_PREFIX)) {
    return;
  }

  const {
    projects,
    floors,
    workspaceId,
    userId,
    role,
    project,
    group,
  }: {
    projects: ProjectDetailData[];
    floors: FloorDetailData[];
    workspaceId: number;
    userId: number;
    role: Role;
    project: ProjectData;
    group: GroupData;
  } = payload;

  switch (type) {
    case MEMBER_EVENT_TYPES.PROJECT_OUT_CHANGE_BROADCAST:
      store.dispatch(updateAccountProjectsBroadCast(projects));
      break;
    case MEMBER_EVENT_TYPES.FLOOR_OUT_CHANGE_BROADCAST:
      store.dispatch(updateAccountAssignedFloors(floors));
      store.dispatch(updateUsersAssignedFloors(floors));
      break;
    case MEMBER_EVENT_TYPES.ROLE_OUT_CHANGE_BROADCAST:
      store.dispatch(updateMemberRole(workspaceId, userId, role));
      break;
    case MEMBER_EVENT_TYPES.ADD_GROUP_RESPONSE:
      store.dispatch(addGroupResponse(group));
      break;
    case MEMBER_EVENT_TYPES.ADD_PROJECT_RESPONSE:
      store.dispatch(addProjectResponse(project));
      break;
  }
};
