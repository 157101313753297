import * as Sentry from "@sentry/browser";

import {
  USER_LOGIN_CHECK_ROUTE,
  USER_LOGIN_ROUTE_PATH,
  WORKSPACE_BASE_ROUTE,
  SIGNING_OUT_ROUTE,
  generateBillingWithParam,
  generateListenerJoinWithParam,
  generateListenerSharingChatWithParam,
} from "../../routes/constants";
import { createRequest, postJson, getJson } from "../../utils/network";
import { history } from "../../routes";
import { intl, Language } from "../../i18n";
import {
  SETTINGS_UPDATE_PROFILE_PATH,
  USER_PATH,
  LOGOUT_PATH,
  SETTINGS_SLACK_OAUTH_PATH,
  SETTINGS_GOOGLE_OAUTH_PATH,
  SETTINGS_GOOGLE_OAUTH_DISCONNECT_PATH,
  SETTINGS_SLACK_AUTH_DISCONNECT_PATH,
  CHECK_TOKEN_PATH,
  SETTINGS_CHATWORK_AUTH_DISCONNECT_PATH,
  SETTINGS_CHATWORK_OAUTH_PATH,
  SETTINGS_AZURE_OAUTH_PATH,
  SETTINGS_AZURE_OAUTH_DISCONNECT_PATH,
  SETTINGS_APPLE_OAUTH_PATH,
  TEXT_FONT_SIZE,
} from "../../constant";
import { closeExternalWindows, hydrate, setLoading, setWorkspaceUpdating } from "../app";
import { resetStore } from "../actions";
import {
  postUpdatedName,
  removeListenersFromSharing,
  updateUserRole,
  updateUsersSelectedProject,
} from "../users/actions";
import { PlanDetails, FloorDetailData, Role, ProjectDetailData, AiModelsEndpoint, Workspace } from "../types";
import { State } from "../state";
import { AppThunk } from "../types";
import { disconnectSocket, getSocketConnectivityStatus, sendMessage } from "../socket";
import { USER_EVENT_TYPES, getMyMember, getCurrentWorkspaceId, changeUserStatus } from "../users";
import { isMobile, openPath, openURL, redirectToDesktop } from "../../utils/helpers";
import { channels } from "../../electron/channels";
import { Config } from "../../electron/config";
import { sendMessageOverIPC } from "../../electron/sendMessageOverIPC";
import { stopWorkingRequest } from "../timeTracker/actions";
import { reloadApp } from "../app/actions";
import { Toast } from "../../components/antd/Toast";
import { getCurrentWorkspace, isStripeCustomer } from "../workspace";
import { stopCheckingActiveWindow } from "../backgroundChecks/activeApp/actions";
import { updateUserWorkspace } from "../../api/user";
import {
  ACCOUNT_EVENT_TYPES,
  InvitedEnv,
  getAccountState,
  getAuthenticatedAccount,
  getIsAppIdle,
  getMyShortId,
  getSharingPassword,
  isWorkspaceManager,
} from ".";
import { Dispatch } from "redux";
import { postUpdatedAuthentication, postStatusProvider } from "../../api/account";
import logError from "../../utils/logError";
import { channelJoin, getCurrentVoiceChannel, getVoiceChannelById } from "../voiceChannels";
import { UserCompanyInfoPayload } from "../../screens/SignUp";
import { disconnectRoom } from "../livekit";
import { doStopScreenShare } from "../screenshare";
import { checkIsGuest } from "./selectors";
import localData from "../../localStorageKeys";
import { resetModals } from "../../screens/Dashboard/state";
import { postIsPermittedChannel } from "../../api/guest";
import { zohoManage, zohoToggleWindow } from "../../utils/zoho";
import { getProtocol } from "../../utils/environment";

export enum AccountTypes {
  RELOAD_ACCOUNT = "account/RELOAD_ACCOUNT",
  FETCH_ACCOUNT = "account/FETCH_ACCOUNT",
  SET_ACCOUNT = "account/SET_ACCOUNT",
  SET_ACCOUNT_WORKSPACE = "account/SET_ACCOUNT_WORKSPACE",
  SET_ACCOUNT_ENABLE_WEB_APP = "account/SET_ACCOUNT_ENABLE_WEB_APP",
  ACCEPT_INVITATION = "account/ACCEPT_INVITATION",
  SET_ACCOUNT_COMPANY = "account/SET_ACCOUNT_COMPANY",
  SET_ACCOUNT_AUDIO_TRACK = "account/SET_ACCOUNT_AUDIO_TRACK",
  SET_LANGUAGE = "account/SET_LANGUAGE",
  UPDATE_PROFILE = "account/UPDATE_PROFILE",
  UPDATED_PASSWORD = "account/UPDATED_PASSWORD",
  LOGOUT = "account/LOGOUT",
  SET_REDIRECT_PATH = "account/SET_REDIRECT_PATH",
  SLACK_MISSING_SCOPES = "account/SLACK_MISSING_SCOPES",
  SLACK_INTEGRATION_UPDATE = "account/SLACK_INTEGRATION_UPDATE",
  GOOGLE_INTEGRATION_UPDATE = "account/GOOGLE_INTEGRATION_UPDATE",
  CHATWORK_INTEGRATION_UPDATE = "users/CHATWORK_INTEGRATION_UPDATE",
  CHATWORK_GROUP_ID_UPDATE = "users/CHATWORK_GROUP_ID_UPDATE",
  AZURE_INTEGRATION_UPDATE = "users/AZURE_INTEGRATION_UPDATE",
  UPDATE_ACCOUNT_PROJECTS = "account/UPDATE_ACCOUNT_PROJECTS",
  UPDATE_ACCOUNT_ASSIGNED_FLOORS = "account/UPDATE_ACCOUNT_ASSIGNED_FLOORS",
  UPDATE_ACCOUNT_WORKSPACE_ROLE = "account/UPDATE_ACCOUNT_WORKSPACE_ROLE",
  UPDATE_ACCOUNT_SELECTED_PROJECT = "account/UPDATE_ACCOUNT_SELECTED_PROJECT",
  UPDATE_ACCOUNT_EMAIL_TOKEN_FLAG = "account/UPDATE_ACCOUNT_EMAIL_TOKEN_FLAG",
  UPDATE_ACCOUNT_SHOW_ORIGINAL_MESSAGE = "account/UPDATE_ACCOUNT_SHOW_ORIGINAL_MESSAGE",
  UPDATE_ACCOUNT_TEXT_TRANSLATION = "account/UPDATE_ACCOUNT_TEXT_TRANSLATION",
  UPDATE_CMS_LOGGED_IN = "account/UPDATE_CMS_LOGGED_IN",
  UPDATE_CMS_LOGIN_AT = "account/UPDATE_CMS_LOGIN_AT",
  SET_IS_APP_IDLE = "account/SET_IS_APP_IDLE",
  SET_ACCOUNT_TIME_TRACKER_LIMIT = "account/SET_ACCOUNT_TIME_TRACKER_LIMIT",
  UPDATE_ACCOUNT_TIME_TRACKER_LIMIT = "account/UPDATE_ACCOUNT_TIME_TRACKER_LIMIT",
  SET_ACCOUNT_INTEGRATION_DATA = "account/SET_ACCOUNT_INTEGRATION_DATA",
  UPDATE_PIN_VOICE_CHANNELS = "account/UPDATE_PIN_VOICE_CHANNELS",
  UPDATE_PIN_USERS = "account/UPDATE_PIN_USERS",
  UPDATE_TEXT_FONT_SIZE = "textChannel/UPDATE_TEXT_FONT_SIZE",
  UPDATE_PLAY_SPEED = "textChannel/UPDATE_PLAY_SPEED",
  UPDATE_TIME_TRACKING_INITIAL_DATA = "account/UPDATE_TIME_TRACKING_INITIAL_DATA",
  UPDATE_SHARING = "account/UPDATE_SHARING",
  UPDATE_SHARE_STT_PASSWORD = "account/UPDATE_SHARE_STT_PASSWORD",
  SET_INVITED_ENV = "account/SET_INVITED_ENV",
  UPDATE_PLAN_CANCEL_STATUS = "account/UPDATE_PLAN_CANCEL_STATUS",
  UPDATE_MAIL_NOTIFICATION_SETTINGS = "account/UPDATE_MAIL_NOTIFICATION_SETTINGS",
}

export interface AccountProjectData {
  id: number;
  workspaceId: number;
  name: string;
  isDefault: boolean;
}
export interface AccountGroupData {
  id: number;
  workspaceId: number;
  name: string;
}

export interface TimeTrackerLimitData {
  id: number;
  enableTimeTrackerLimit: boolean;
  monthlyLimit?: number;
  weeklyLimit?: number;
  dailyLimit?: number;
  enableShift?: boolean;
  timezoneOffset?: number;
  days?: string;
  slots?: {
    id: number;
    startTime: string;
    endTime: string;
  }[];
  currentMonthTime?: number;
  currentWeekTime?: number;
  todayTime?: number;
}

export type OAuthProvider = "google" | "facebook" | "twitter" | "apple" | null;

export interface TimeTrackingIntialData {
  projectId: number;
  description: string;
  totalSeconds: number;
  serverClock: string;
  timezoneOffset: number;
  todayTotalSecond: number;
}

export interface Account {
  id: number;
  name: string;
  planDetails?: PlanDetails;
  fullName: string;
  displayName: string;
  avatar?: string;
  projectId?: number | null;
  voiceChannelId: number | null;
  email?: string;
  company?: string | null;
  size?: string | null;
  role?: string | null;
  phone?: string | null;
  etherpadAuthorId: string | null;
  unconfirmedEmail?: string | null;
  language: Language;
  country?: string | null;
  authenticated: boolean;
  connectedGoogle?: boolean;
  workspaceId: number | null;
  workspaces?: Workspace[];
  projects?: AccountProjectData[];
  slack?: {
    connected: boolean;
    missingScopes: {
      botScopes: string[];
      userScopes: string[];
    };
  };
  signupWithPassword?: boolean;
  settings?: {
    notification: {
      browser: boolean;
      sound: boolean;
      teammateJoinVoice: boolean;
      teammateJoinWorkspace: boolean;
    };
  };
  oAuthProvider?: OAuthProvider;
  chatworkGroupId?: number | null;
  connectedChatwork?: boolean;
  connectedAzure?: boolean;
  statusProvider?: string;
  knowUs: string | null;
  emailTokenFlag: boolean;
  assignedFloor: number[];
  isGuest: boolean;
  isListener: boolean;
  isMeetingRecording: boolean;
  skyWayAPIKey: string | null;
  showOriginalMessage: boolean;
  textTranslation: boolean;
  textFontSize: number;
  playSpeed: number;
  enableWebApp: boolean;
  enableTimeTrackerLimit: boolean;
  monthlyLimit?: number;
  weeklyLimit?: number;
  dailyLimit?: number;
  enableShift?: boolean;
  timezoneOffset?: number;
  days?: string | number[];
  slots?: {
    id: number;
    startTime: string;
    endTime: string;
  }[];
  currentMonthTime?: number;
  currentWeekTime?: number;
  todayTime?: number;
  aiModelInfo?: AiModelsEndpoint[];
  pinVoiceChannels: number[];
  pinUsers: number[];
  timeTracking?: boolean;
  personalSpeakingLanguage?: string | null;
  timetrackingInitialData?: TimeTrackingIntialData;
  shortId: string;
  enableSharing: boolean;
  sharingPassword: string | null;
  enableSharingTextToSpeech: boolean;
  enableSharingMic: boolean;
  isMarketingMail: boolean;
  isSystemMail: boolean;
}

export interface ProfileUpdate {
  userId: number;
  workspaceId: number;
  fullName: string;
  displayName: string;
  language: Language;
}

export interface ProjectUpdate {}

export type AccountActions =
  | ReturnType<typeof setAccountAction>
  | ReturnType<typeof setLanguage>
  | ReturnType<typeof setAccountWorkspace>
  | ReturnType<typeof setAccountEnableWebApp>
  | ReturnType<typeof updatedProfileAction>
  | ReturnType<typeof updatedPasswordAction>
  | ReturnType<typeof logoutAction>
  | ReturnType<typeof setRedirectPath>
  | ReturnType<typeof acceptWorkspaceInvitation>
  | ReturnType<typeof setAccountCompany>
  | ReturnType<typeof slackIntegrationStatusUpdate>
  | ReturnType<typeof azureIntegrationStatusUpdate>
  | ReturnType<typeof slackMissingScopes>
  | ReturnType<typeof reloadAccount>
  | ReturnType<typeof chatworkIntegrationStatusUpdate>
  | ReturnType<typeof updateAccountProjectsAction>
  | ReturnType<typeof updatedChatworkGroupId>
  | ReturnType<typeof updateAccountWorkspaceRoleAction>
  | ReturnType<typeof updateAccountSelectedProjectAction>
  | ReturnType<typeof updateAccountEmailTokenFlagAction>
  | ReturnType<typeof updateAccountAssignedFloorsAction>
  | ReturnType<typeof googleIntegrationStatusUpdateAction>
  | ReturnType<typeof updateShowOriginalMessage>
  | ReturnType<typeof updateTextTranslationAction>
  | ReturnType<typeof updateCmsLoginAtAction>
  | ReturnType<typeof setIsAppIdleAction>
  | ReturnType<typeof setAccountTimeTrackerLimitAction>
  | ReturnType<typeof updateAccountTimeTrackerLimitAction>
  | ReturnType<typeof setAccountIntegrationData>
  | ReturnType<typeof updatePinVoiceChannelsAction>
  | ReturnType<typeof updatePinUsersAction>
  | ReturnType<typeof updateTextFontSizeAction>
  | ReturnType<typeof updatePlaySpeedAction>
  | ReturnType<typeof updateTimeTrackingInitialDataAction>
  | ReturnType<typeof updateSharingAction>
  | ReturnType<typeof setInvitedEnv>
  | ReturnType<typeof updateAccountWorkspacePlanCancelStatusAction>
  | ReturnType<typeof updateMailNotificationSettingsAction>;

export function reloadAccount() {
  return { type: AccountTypes.RELOAD_ACCOUNT } as const;
}

export function fetchAuthenticatedAccount(forceReloadIfRequestFailed: boolean = false): AppThunk {
  return async (dispatch: Function, getState) => {
    dispatch(setLoading(true));

    try {
      const localTimezone = new Date().getTimezoneOffset();

      const { response } = await getJson(USER_PATH, { localTimezone });

      if (!response.success && forceReloadIfRequestFailed) {
        dispatch(reloadApp());
      } else {
        if (window.electron) await sendMessageOverIPC(channels.I18N_SETUP, response.data.language);

        localStorage.setItem("language", response.data.language);

        dispatch(setAccount(response.data));
      }

      dispatch(setLoading(false));
    } catch (err) {
      dispatch(reloadApp());
      dispatch(setLoading(false));
    }
  };
}

export function redirectWorkspace(url: string): AppThunk {
  return async (dispatch: Function, getState) => {
    const account = getAuthenticatedAccount(getState());
    const redirectPath = url.replace("/dashboard/", "");
    const initialAccessWorkspaceShortId = redirectPath?.split("/")[0];
    const initialAccessChannelShortId = redirectPath?.split("/")[1];

    const workspace = initialAccessWorkspaceShortId
      ? account.workspaces?.find(el => el.shortId === initialAccessWorkspaceShortId)
      : account.workspaces?.find(el => el.id === account.workspaceId);

    if (account.workspaceId) {
      await updateUserWorkspace(account.workspaceId);
    }

    console.log("Check redirection workspace", workspace);
    initialAccessWorkspaceShortId && initialAccessChannelShortId
      ? redirectToDesktop("dashboard", `/dashboard/${url}`)
      : redirectToDesktop("dashboard", `/dashboard/${workspace?.shortId}`);
  };
}

function setAccountAction(account: Account) {
  return {
    type: AccountTypes.SET_ACCOUNT,
    payload: { user: account },
  } as const;
}

export function setAccount(account: Account): AppThunk {
  return (dispatch: Function) => {
    const pathName = window.location.pathname;

    sendMessageOverIPC(channels.SET_USER, account);

    if (!pathName.startsWith("/guest/waiting/")) {
      dispatch(resetModals());
    }

    dispatch(setAccountAction(account));
    dispatch(
      setUserOnSentry(account.id, account.email ? account.email : "", account.displayName, account.authenticated),
    );
  };
}

export function setUserOnSentry(userId: number, email: string, displayName: string, isAuthenticated: boolean) {
  return () => {
    if (isAuthenticated) {
      Sentry.setUser({
        id: userId.toString(),
        email: email,
        username: displayName,
      });
      Sentry.setTags({
        "user.email": email,
        "user.username": displayName,
      });
    }
  };
}

export function setUserWorkspaceOnSentry(workspaceId: number, workspaceName: string) {
  return () => {
    Sentry.setTags({
      "workspace.id": workspaceId.toString(),
      "workspace.name": workspaceName,
    });
  };
}

export function setUserVoiceChannelOnSentry(voiceChannelId: number, voiceChannelName: string) {
  return () => {
    Sentry.setTags({
      "voicecChannel.id": voiceChannelId.toString(),
      "voicecChannel.name": voiceChannelName,
    });
  };
}

export function setAccountWorkspace(workspaceId: number) {
  return {
    type: AccountTypes.SET_ACCOUNT_WORKSPACE,
    payload: { workspaceId },
  } as const;
}

export function setAccountEnableWebApp(enableWebApp: boolean) {
  return {
    type: AccountTypes.SET_ACCOUNT_ENABLE_WEB_APP,
    payload: { enableWebApp },
  } as const;
}

export function acceptWorkspaceInvitation(acceptedInvitation: boolean) {
  return {
    type: AccountTypes.ACCEPT_INVITATION,
    payload: { acceptedInvitation },
  } as const;
}

export function setAccountCompany(company: string, size: string, role: string, phone: string) {
  return {
    type: AccountTypes.SET_ACCOUNT_COMPANY,
    payload: { company, size, role, phone },
  } as const;
}

export function setLanguage(payload: { language: Language }) {
  return {
    type: AccountTypes.SET_LANGUAGE,
    payload,
  } as const;
}

export function updatedProfileAction(payload: ProfileUpdate) {
  return {
    type: AccountTypes.UPDATE_PROFILE,
    payload,
  } as const;
}

export function updatedProfile(payload: ProfileUpdate): AppThunk {
  return dispatch => {
    dispatch(updatedProfileAction(payload));
    zohoManage({ action: "update", name: payload.displayName, language: payload.language });
  };
}

export function setAccountIntegrationData(data: {
  connectedChatwork: boolean;
  connectedAzure: boolean;
  connectedGoogle: boolean;
}) {
  return {
    type: AccountTypes.SET_ACCOUNT_INTEGRATION_DATA,
    payload: { ...data },
  } as const;
}

export function updatedPasswordAction() {
  return {
    type: AccountTypes.UPDATED_PASSWORD,
  } as const;
}

export function updatedPassword(): AppThunk {
  return dispatch => {
    dispatch(updatedPasswordAction());
  };
}

export function updateProfile(payload: ProfileUpdate, languageUpdated = false): AppThunk {
  return async (dispatch: Function) => {
    const { response } = await createRequest(SETTINGS_UPDATE_PROFILE_PATH, {
      method: "POST",
      body: JSON.stringify({
        ...payload,
      }),
    });

    if (response.success) {
      dispatch(updatedProfile(payload));
      if (window.electron) await sendMessageOverIPC(channels.I18N_SETUP, payload.language);

      dispatch(
        postUpdatedName({ workspaceId: payload.workspaceId, userId: payload.userId, displayName: payload.displayName }),
      );

      Toast.success(
        languageUpdated
          ? intl.formatMessage({
              id: "workspace-settings/language-success",
              defaultMessage: "Language successfully updated!",
            })
          : intl.formatMessage({
              id: "workspace-settings/profile-success",
              defaultMessage: "Profile successfully updated!",
            }),
      );
    }

    dispatch(setWorkspaceUpdating(false));
  };
}

export function logout(
  shortId?: string,
  isGuest?: boolean,
  isListener?: boolean,
  listenerModeQuery?: string | null,
): AppThunk {
  return async (dispatch: Function, getState: () => State) => {
    zohoManage({ action: "reset" });
    const socketConnected = getSocketConnectivityStatus(getState());

    if (socketConnected) {
      dispatch(closeExternalWindows());
      dispatch(stopWorkingRequest("User Logout"));
      dispatch(disconnectSocket());
    }

    dispatch(resetModals());
    console.log("LOG:SESSION HANDLING", listenerModeQuery);
    dispatch(doLogout(shortId, isGuest, isListener, listenerModeQuery));
  };
}

export function doLogout(
  shortId?: string,
  guest?: boolean,
  listener?: boolean,
  listenerModeQuery?: string | null,
): AppThunk {
  return async (dispatch, getState) => {
    const isGuest = checkIsGuest(getState());
    const account = getAccountState(getState());
    const me = getMyMember(getState());
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const voiceChannelSelector = getVoiceChannelById(currentVoiceChannel?.parentVoiceChannelId as number);
    const parentMeeting = voiceChannelSelector(getState());
    const isMobileDevice = !!(isMobile.Android() || isMobile.iOS() || isMobile.iPad());

    dispatch(setLoading(true));
    // await dispatch(setUserLogout());
    localData.unset("settings.activeVBG.id");
    localData.set("openInBrowser", "false");
    localData.unset("setting.workspace.id");

    const { response } = await createRequest(LOGOUT_PATH, {
      method: "DELETE",
      body: JSON.stringify({ web: !window.electron, workspaceId: me?.workspaceId }),
    });

    dispatch(setLoading(false));

    if (response.success) {
      dispatch(logoutAction()); //added 2024-05-23 for turn false account authenticated status
      if (guest && shortId) {
        window.location.href = `/join/${shortId}`;
        sessionStorage.removeItem("isDeviceSettingPageDisplayed");
        return;
      }

      if (listener && shortId) {
        window.location.href = `/listener/join/${shortId}`;
        sessionStorage.removeItem("isDeviceSettingPageDisplayed");
        return;
      }

      if (account.user.isListener || listener) {
        let redirectShortId = me?.joinShortId || currentVoiceChannel?.listenerId || shortId;

        if (redirectShortId === undefined) {
          history.push(USER_LOGIN_ROUTE_PATH);
        } else if ((redirectShortId === currentVoiceChannel?.listenerId || shortId) && !listenerModeQuery) {
          history.push(generateListenerJoinWithParam(redirectShortId));
        } else {
          history.push(generateListenerSharingChatWithParam(redirectShortId, isMobileDevice));
        }

        sessionStorage.removeItem("isDeviceSettingPageDisplayed");
        return;
      } else if (isGuest || guest) {
        let redirectShortId = currentVoiceChannel?.shortId;

        if (!shortId) {
          history.push(USER_LOGIN_ROUTE_PATH);
          sessionStorage.removeItem("isDeviceSettingPageDisplayed");
          return;
        }

        if (shortId) redirectShortId = shortId;

        const isFloorJoined =
          me?.guestJoinId !== currentVoiceChannel?.id && me?.guestJoinId === currentVoiceChannel?.parentVoiceChannelId;

        if (isFloorJoined && currentVoiceChannel?.parentVoiceChannelId) {
          if (parentMeeting?.guestEnabled) redirectShortId = parentMeeting?.shortId;
        }

        window.location.href = `/join/${redirectShortId}`;
        sessionStorage.removeItem("isDeviceSettingPageDisplayed");
        return;
      } else {
        history.push(USER_LOGIN_ROUTE_PATH);
        sessionStorage.removeItem("isDeviceSettingPageDisplayed");
      }

      if (window.electron) {
        dispatch(redirectToSigningOutRoute());
      }
    }
  };
}

function logoutAction() {
  return {
    type: AccountTypes.LOGOUT,
  } as const;
}

export function setUserLogout(): AppThunk {
  return async (dispatch, getState) => {
    const socketConnected = getSocketConnectivityStatus(getState());

    dispatch(logoutAction());
    if (socketConnected) {
      dispatch(disconnectRoom());
      dispatch(stopCheckingActiveWindow());
      dispatch(sendMessage(USER_EVENT_TYPES.FORCE_LOGOUT, {}));
      dispatch(disconnectSocket());
    }

    dispatch(resetStore());
  };
}

export function connectSlackAccount(): AppThunk {
  return async (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());

    if (!workspace) {
      return;
    }

    const workspaceId = workspace.id;
    const { response } = await getJson(SETTINGS_SLACK_OAUTH_PATH, { workspaceId });

    if (response.success) {
      const url = response.data;

      openURL(url, true);
    }
  };
}

export function connectGoogleAccount(
  type: "signin" | "signup" | "calendar",
  workspaceId?: number,
  invitationToken?: string,
  companyInfo?: UserCompanyInfoPayload,
  redirectPath?: string,
  isMobileOnWeb?: boolean,
  isMailAcceptable?: boolean,
): AppThunk {
  return async () => {
    let country = "";

    const { response } = await getJson(SETTINGS_GOOGLE_OAUTH_PATH, {
      type,
      workspaceId,
      country,
      invitationToken: invitationToken ?? "",
      redirectPath: redirectPath ?? "",
      company: companyInfo ? companyInfo.company : "",
      role: companyInfo ? companyInfo.role : "",
      size: companyInfo ? companyInfo.size : "",
      knowUs: companyInfo ? companyInfo.knowUs : "",
      usecase: companyInfo ? companyInfo.usecase : "",
      isMobileOnWeb: isMobileOnWeb,
      isMailAcceptable: !!invitationToken
        ? false
        : isMailAcceptable
        ? isMailAcceptable
        : companyInfo && typeof companyInfo.isMailAcceptable === "boolean"
        ? companyInfo.isMailAcceptable
        : false,
    });

    if (response.success) {
      const url = response.data;

      openURL(url, type === "calendar");
    }
  };
}
export function connectAppleAccount(
  type: "signin",
  idToken: string,
  redirectPath: string | null,
  invitationToken?: string,
): AppThunk {
  return async () => {
    const { response } = await postJson(SETTINGS_APPLE_OAUTH_PATH, {
      idToken,
      state: JSON.stringify({
        type: type,
        invitationToken: invitationToken,
        redirectPath: redirectPath,
      }),
    });

    if (response.success) {
      const url = response.data;

      openURL(url, false);
    }
  };
}

export function disconnectGoogleAccount(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    const { response } = await postJson(SETTINGS_GOOGLE_OAUTH_DISCONNECT_PATH, {
      workspaceId: me?.workspaceId,
      userId: me?.id,
    });

    if (response.success) {
      //Dont show anything in this case, button turns to connect
      if (me) {
        dispatch(changeUserStatus({ workspaceId: me.workspaceId, status: "available", statusProvider: "" }));
      }
    } else {
      Toast.warning(
        intl.formatMessage({
          id: "disconnect/error",
          defaultMessage: "Unexpected error occurred. Please try to disconnect again.",
        }),
      );
    }
  };
}
export function setRedirectPath(redirectPath: string | null) {
  return {
    type: AccountTypes.SET_REDIRECT_PATH,
    payload: { redirectPath },
  } as const;
}

export function slackMissingScopes(payload: { botScopes: string[]; userScopes: string[] }) {
  return {
    type: AccountTypes.SLACK_MISSING_SCOPES,
    payload,
  } as const;
}

export function slackIntegrationStatusUpdate(payload: { userId: number; connectedSlack: boolean }) {
  return {
    type: AccountTypes.SLACK_INTEGRATION_UPDATE,
    payload,
  } as const;
}

function googleIntegrationStatusUpdateAction(payload: { userId: number; connectedGoogle: boolean }) {
  return {
    type: AccountTypes.GOOGLE_INTEGRATION_UPDATE,
    payload,
  } as const;
}

export function googleIntegrationStatusUpdate(payload: { userId: number; connectedGoogle: boolean }): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && !payload.connectedGoogle && me?.statusProvider !== "Google") {
      dispatch(changeUserStatus({ workspaceId: me.workspaceId, status: me.status, statusProvider: "" }));
    }

    dispatch(googleIntegrationStatusUpdateAction(payload));
  };
}

export function disconnectSlackAccount(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    const { response } = await postJson(SETTINGS_SLACK_AUTH_DISCONNECT_PATH, {
      workspaceId: me?.workspaceId,
      userId: me?.id,
    });

    if (response.success) {
      //Dont show anything in this case, button turns to connect
    } else {
      Toast.warning(
        intl.formatMessage({
          id: "disconnect/error",
          defaultMessage: "Unexpected error occurred. Please try to disconnect again.",
        }),
      );
    }
  };
}

export function desktopSigninOnBrowser(): AppThunk {
  return async dispatch => {
    if (!window.electron) {
      return;
    }

    const { ipcRenderer } = window.electron;

    const config: Config = await sendMessageOverIPC(channels.CONFIG_LOAD);
    const url =
      config.environment === "production"
        ? USER_LOGIN_CHECK_ROUTE
        : `${USER_LOGIN_CHECK_ROUTE}?protocol=${config.appProtocol}`;

    console.log("Check sign in redirection url from desktop to web", url);
    openPath(url);

    ipcRenderer.once(channels.CHECK_TOKEN, async (_event, arg: { token: string }) => {
      console.log("Check the token for redrection from web to desktop app", arg.token);
      const { response } = await postJson<{ success: boolean; data: Account }>(CHECK_TOKEN_PATH, { token: arg.token });

      console.log("Token auth result", arg.token);
      if (!response.success) {
        throw new Error("Failing when trying to sign-in the desktop app");
      }

      const user = response.data;

      dispatch(setAccount(user));
      dispatch(hydrate());

      console.log("Redirect to the dashboard");

      history.push(WORKSPACE_BASE_ROUTE);
    });
  };
}

export function redirectToSigningOutRoute(): AppThunk {
  return () => {
    openPath(SIGNING_OUT_ROUTE);
  };
}

export function connectChatwork(): AppThunk {
  return async (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());

    if (!workspace) {
      return;
    }

    const workspaceId = workspace.id;
    const { response } = await getJson(SETTINGS_CHATWORK_OAUTH_PATH, { workspaceId });

    if (response.success) {
      const url = response.data;

      openURL(url, true);
    }
  };
}

export function chatworkIntegrationStatusUpdate(payload: { userId: number; connectedChatwork: boolean }) {
  return {
    type: AccountTypes.CHATWORK_INTEGRATION_UPDATE,
    payload,
  } as const;
}

export function updatedChatworkGroupId(payload: { userId: number; chatworkGroupId: number | null }) {
  return {
    type: AccountTypes.CHATWORK_GROUP_ID_UPDATE,
    payload,
  } as const;
}

export function updateChatworkGroupId(workspaceId: number, chatworkGroupId: number | null): AppThunk {
  return (dispatch: Dispatch) => {
    dispatch(
      sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_CHATWORK_GROUP_ID_UPDATE_REQUEST, {
        workspaceId,
        chatworkGroupId,
      }),
    );
  };
}

export function disconnectChatworkAccount(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    const { response } = await postJson(SETTINGS_CHATWORK_AUTH_DISCONNECT_PATH, {
      workspaceId: me?.workspaceId,
      userId: me?.id,
    });

    if (response.success) {
      //Dont show anything in this case, button turns to connect
    } else {
      Toast.warning(
        intl.formatMessage({
          id: "disconnect/error",
          defaultMessage: "Unexpected error occurred. Please try to disconnect again.",
        }),
      );
    }
  };
}

export function azureIntegrationStatusUpdate(payload: { userId: number; connectedAzure: boolean }) {
  return {
    type: AccountTypes.AZURE_INTEGRATION_UPDATE,
    payload,
  } as const;
}

export function disconnectAzureAccount(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    const { response } = await postJson(SETTINGS_AZURE_OAUTH_DISCONNECT_PATH, {
      workspaceId: me?.workspaceId,
      userId: me?.id,
    });

    if (response.success) {
      if (me && me.statusProvider === "Outlook") {
        dispatch(changeUserStatus({ workspaceId: me.workspaceId, status: "available", statusProvider: "" }));
      }
    } else {
      Toast.warning(
        intl.formatMessage({
          id: "disconnect/error",
          defaultMessage: "Unexpected error occurred. Please try to disconnect again.",
        }),
      );
    }
  };
}

export function updateAccountProjectsAction(projects: AccountProjectData[]) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_PROJECTS,
    payload: { projects },
  } as const;
}

export function setAccountTimeTrackerLimitAction(timeTrackerData: TimeTrackerLimitData) {
  return {
    type: AccountTypes.SET_ACCOUNT_TIME_TRACKER_LIMIT,
    payload: { timeTrackerData },
  } as const;
}

export function updateAccountTimeTrackerLimitAction(timeTrackerData: TimeTrackerLimitData) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_TIME_TRACKER_LIMIT,
    payload: { timeTrackerData },
  } as const;
}

export function updateAccountProjects(projects: AccountProjectData[]): AppThunk {
  return async dispatch => {
    dispatch(updateAccountProjectsAction(projects));
  };
}

export function setAccountTimeTrackerLimit(timeTrackerData: TimeTrackerLimitData): AppThunk {
  return async dispatch => {
    dispatch(setAccountTimeTrackerLimitAction(timeTrackerData));
  };
}

export function updateAccountTimeTrackerLimit(timeTrackerData: TimeTrackerLimitData): AppThunk {
  return async dispatch => {
    dispatch(updateAccountTimeTrackerLimitAction(timeTrackerData));
  };
}

export function updateAccountProjectsBroadCast(projects: ProjectDetailData[]): AppThunk {
  return async (dispatch, getState) => {
    const accountState = getAccountState(getState());

    dispatch(
      updateAccountProjectsAction(
        projects
          .filter(a => a.members.some(m => m.id === accountState.user.id))
          .map(p => ({
            id: p.id,
            isDefault: p.isDefault,
            name: p.name,
            workspaceId: p.workspaceId,
          })),
      ),
    );
  };
}

export function updateAccountAssignedFloorsAction(floors: number[]) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_ASSIGNED_FLOORS,
    payload: { floors },
  } as const;
}

export function updateAccountAssignedFloors(floors: FloorDetailData[]): AppThunk {
  return async (dispatch, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const authenticatedAccount = getAuthenticatedAccount(getState());
    const currentFloor =
      currentVoiceChannel?.parentVoiceChannelId === null
        ? currentVoiceChannel.id
        : currentVoiceChannel?.parentVoiceChannelId;
    const assignedFloors = floors.filter(f => f.members.some(m => m === authenticatedAccount.id));

    if (assignedFloors.length > 0 && !assignedFloors.some(f => f.id === currentFloor)) {
      dispatch(doStopScreenShare());
      dispatch(channelJoin({ workspaceId: assignedFloors[0].workspaceId, voiceChannelId: assignedFloors[0].id }));
    }

    await dispatch(updateAccountAssignedFloorsAction(assignedFloors.map(el => el.id)));
  };
}

export function updateMemberRole(workspaceId: number, userId: number, role: Role): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me?.workspaceId === workspaceId) {
      dispatch(updateUserRole({ userId, role }));
      if (me.id === userId) {
        dispatch(updateAccountWorkspaceRole(role));
      }
    }
  };
}

export function fetchAccountProjects(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.workspaceId) {
      dispatch(sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_PROJECTS_FETCH_REQUEST, { workspaceId: me.workspaceId }));
    }
  };
}

export function fetchAccountTimeTrackerLimit(): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.workspaceId) {
      dispatch(sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_TIME_TRACKER_LIMIT_REQUEST, { workspaceId: me.workspaceId }));
    }
  };
}

export function updateAccountWorkspaceRoleAction(workspaceId: number, role: string) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_WORKSPACE_ROLE,
    payload: { workspaceId, role },
  } as const;
}

export function updateAccountWorkspaceRole(role: string): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (me && me.workspaceId) {
      dispatch(updateAccountWorkspaceRoleAction(me.workspaceId, role));
    }
  };
}

export function updateAccountSelectedProjectRequest(workspaceId: number, projectId: number): AppThunk {
  return async dispatch => {
    await dispatch(
      sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_SELECTED_PROJECT_UPDATE_REQUEST, { workspaceId, projectId }),
    );
  };
}

export function updateAccountSelectedProjectAction(projectId: number | null) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_SELECTED_PROJECT,
    payload: { projectId },
  } as const;
}

export function updateAccountSelectedProject(payload: {
  userId: number;
  workspaceId: number;
  projectId: number;
  projectName?: string;
}): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    if (payload.userId === me?.id) {
      dispatch(updateAccountSelectedProjectAction(payload.projectId));
    }

    dispatch(updateUsersSelectedProject(payload.workspaceId, payload.userId, payload.projectId, payload.projectName));
  };
}

export function updateAccountEmailTokenFlagAction(emailTokenFlag: boolean) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_EMAIL_TOKEN_FLAG,
    payload: { emailTokenFlag },
  } as const;
}

export function updateAccountEmailTokenFlag(emailTokenFlag: boolean): AppThunk {
  return async dispatch => {
    try {
      await postUpdatedAuthentication({ emailTokenFlag });

      dispatch(updateAccountEmailTokenFlagAction(emailTokenFlag));
    } catch (err) {
      logError("Error for auth update", err);
    }
  };
}

export function connectAzure(): AppThunk {
  return async (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());
    const { response } = await getJson(SETTINGS_AZURE_OAUTH_PATH, { workspaceId });

    if (response.success) {
      const url = response.data;

      openURL(url, true);
    }
  };
}

export function updateStatusProvider(statusProvider: string): AppThunk {
  return async (dispatch, getState) => {
    const workspaceId = getCurrentWorkspaceId(getState());

    try {
      workspaceId && (await postStatusProvider({ statusProvider, workspaceId }));
    } catch (err) {
      logError("Error for colProvider update", err);
    }
  };
}

export function updateShowOriginalMessage(showOriginalMessage: boolean) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_SHOW_ORIGINAL_MESSAGE,
    payload: { showOriginalMessage },
  } as const;
}

export function updateTextTranslationAction(textTranslation: boolean) {
  return {
    type: AccountTypes.UPDATE_ACCOUNT_TEXT_TRANSLATION,
    payload: { textTranslation },
  } as const;
}

export function updateTextTranslation(textTranslation: boolean): AppThunk {
  return async (dispatch, getState) => {
    const me = getMyMember(getState());

    dispatch(updateTextTranslationAction(textTranslation));

    if (me?.workspaceId && me?.role !== "guest" && me?.role !== "listener") {
      dispatch(
        sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_TEXT_TRANSLATION, {
          textTranslation,
          workspaceId: me?.workspaceId,
        }),
      );
    }
  };
}

export function updateCmsLoginAt(workspaceId: number, cmsLoginAt: Date): AppThunk {
  return async (dispatch, getState) => {
    dispatch(updateCmsLoginAtAction({ workspaceId, cmsLoginAt }));
  };
}

export function updateCmsLoginAtAction(payload: { workspaceId: number; cmsLoginAt: Date }) {
  return {
    type: AccountTypes.UPDATE_CMS_LOGIN_AT,
    payload,
  } as const;
}

export function setIsAppIdleAction(payload: { isAppIdle: boolean }) {
  return {
    type: AccountTypes.SET_IS_APP_IDLE,
    payload,
  } as const;
}

export function updateIsAppIdle(isAppIdle: boolean): AppThunk {
  return async (dispatch: Function, getState) => {
    const currentVoiceChannel = getCurrentVoiceChannel(getState());
    const currentIsAppIdle = getIsAppIdle(getState());

    if (!currentVoiceChannel || currentIsAppIdle === isAppIdle) return;

    dispatch(setIsAppIdleAction({ isAppIdle }));

    await dispatch(
      sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_IS_APP_IDLE_REQUEST, {
        workspaceId: currentVoiceChannel.workspaceId,
        voiceChannelId: currentVoiceChannel.id,
        isAppIdle,
      }),
    );
  };
}

export function updatePinVoiceChannelsAction(payload: { voiceChannels: number[] }) {
  return {
    type: AccountTypes.UPDATE_PIN_VOICE_CHANNELS,
    payload,
  } as const;
}

export function updatePinUsersAction(payload: { userIds: number[] }) {
  return {
    type: AccountTypes.UPDATE_PIN_USERS,
    payload,
  } as const;
}

export function pinVoiceChannel(voiceChannelId: number): AppThunk {
  return (dispatch, getState) => {
    const pinnedVoiceChannels = JSON.parse(localData.fetch("sidebar.voiceChannel.pinnedVoiceChannels")) as number[];

    pinnedVoiceChannels.push(voiceChannelId);

    localData.set("sidebar.voiceChannel.pinnedVoiceChannels", JSON.stringify(pinnedVoiceChannels));

    dispatch(updatePinVoiceChannelsAction({ voiceChannels: pinnedVoiceChannels }));
  };
}

export function unPinVoiceChannel(voiceChannelIds: number[]): AppThunk {
  return (dispatch, getState) => {
    const pinnedVoiceChannels = JSON.parse(localData.fetch("sidebar.voiceChannel.pinnedVoiceChannels")) as number[];

    const updatedList = pinnedVoiceChannels.filter(a => !voiceChannelIds.some(v => v === a));

    localData.set("sidebar.voiceChannel.pinnedVoiceChannels", JSON.stringify(updatedList));

    dispatch(updatePinVoiceChannelsAction({ voiceChannels: updatedList }));
  };
}

export function pinUser(userId: number): AppThunk {
  return (dispatch, getState) => {
    const pinnedUsers = JSON.parse(localData.fetch("sidebar.voiceChannel.pinnedUsers")) as number[];

    pinnedUsers.push(userId);

    localData.set("sidebar.voiceChannel.pinnedUsers", JSON.stringify(pinnedUsers));

    dispatch(updatePinUsersAction({ userIds: pinnedUsers }));
  };
}

export function unPinUser(userIds: number[]): AppThunk {
  return (dispatch, getState) => {
    const pinnedUsers = JSON.parse(localData.fetch("sidebar.voiceChannel.pinnedUsers")) as number[];

    const updatedList = pinnedUsers.filter(a => !userIds.some(v => v === a));

    localData.set("sidebar.voiceChannel.pinnedUsers", JSON.stringify(updatedList));

    dispatch(updatePinUsersAction({ userIds: updatedList }));
  };
}

export function openUpgradeInfo(): AppThunk {
  return async (dispatch, getState) => {
    const workspace = getCurrentWorkspace(getState());
    const isMeWorkspaceManager = isWorkspaceManager(workspace ? workspace.id : -1)(getState());
    const isMeStripeCustomer = isStripeCustomer(getState());

    if (!isMeWorkspaceManager || !workspace) {
      return;
    }

    if (isMeStripeCustomer) {
      const protocol = getProtocol();

      openURL(`${protocol}${window.location.host}${generateBillingWithParam(String(workspace.id))}`, true);
    } else {
      zohoToggleWindow("show");
    }
  };
}

export function updateTextFontSize(payload: { textFontSize: number }): AppThunk {
  return (dispath, getState) => {
    if (payload.textFontSize < TEXT_FONT_SIZE.min) {
      payload.textFontSize = TEXT_FONT_SIZE.min;
    }

    if (payload.textFontSize > TEXT_FONT_SIZE.max) {
      payload.textFontSize = TEXT_FONT_SIZE.max;
    }

    dispath(updateTextFontSizeAction(payload));
    localStorage.setItem("textFontSize", String(payload.textFontSize));
  };
}

export function updateTextFontSizeAction(payload: { textFontSize: number }) {
  return {
    type: AccountTypes.UPDATE_TEXT_FONT_SIZE,
    payload,
  } as const;
}

export function updatePlaySpeedAction(payload: { playSpeed: number }) {
  return {
    type: AccountTypes.UPDATE_PLAY_SPEED,
    payload,
  } as const;
}

export function checkCurrentSession(workspaceShortId?: string, channelShortId?: string): AppThunk {
  return async (dispatch, getState) => {
    const user = getAuthenticatedAccount(getState());

    if (user.isGuest) {
      const permission = await postIsPermittedChannel({ workspaceShortId, channelShortId });

      if (permission) {
        if (!permission.isPermitted) {
          dispatch(logout(channelShortId, true));
        } else {
        }
      } else {
        dispatch(logout());
      }
    }
  };
}

export function addWebPushNotification(payload: any, type: string): AppThunk {
  return (dispatch, getState) => {
    const notifications = JSON.parse(localData.fetch("webPushNotifications")) as [{ payload?: any; type?: string }];

    notifications.push({ payload, type });
    localData.set("webPushNotifications", JSON.stringify(notifications));
  };
}

export function updateTimeTrackingInitialDataAction(payload: TimeTrackingIntialData) {
  return {
    type: AccountTypes.UPDATE_TIME_TRACKING_INITIAL_DATA,
    payload,
  } as const;
}

export function updateSharingAction(payload: {
  enableSharing: boolean;
  sharingPassword: string | null;
  enableSharingTextToSpeech: boolean;
  enableSharingMic: boolean;
}) {
  return { type: AccountTypes.UPDATE_SHARING, payload } as const;
}

export function updatedSharing(payload: {
  enableSharing: boolean;
  sharingPassword: string | null;
  enableSharingTextToSpeech: boolean;
  enableSharingMic: boolean;
}): AppThunk {
  return async (dispatch: Function, getState) => {
    const myShortId = getMyShortId(getState());
    const currentPassword = getSharingPassword(getState());

    if (!payload.enableSharing || (payload.sharingPassword !== null && currentPassword !== payload.sharingPassword)) {
      dispatch(
        removeListenersFromSharing({
          myShortId,
        }),
      );
    }

    dispatch(updateSharingAction(payload));
  };
}

export function updateSharing({
  enableSharing,
  sharingPassword,
  enableSharingTextToSpeech,
  enableSharingMic,
}: {
  enableSharing?: boolean;
  sharingPassword?: string | null;
  enableSharingTextToSpeech?: boolean;
  enableSharingMic?: boolean;
}): AppThunk {
  return async (dispatch: Function, getState) => {
    const currentWorkspaceId = getCurrentWorkspaceId(getState());

    dispatch(
      sendMessage(ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_SHARING_REQUEST, {
        workspaceId: currentWorkspaceId,
        enableSharing,
        sharingPassword,
        enableSharingTextToSpeech,
        enableSharingMic,
      }),
    );
  };
}

export function setInvitedEnv(payload: InvitedEnv | undefined) {
  return { type: AccountTypes.SET_INVITED_ENV, payload } as const;
}

export function updateAccountWorkspacePlanCancelStatusAction(payload: {
  willPlanBeCanceled: boolean;
  workspaceId: number;
}) {
  return { type: AccountTypes.UPDATE_PLAN_CANCEL_STATUS, payload } as const;
}

export function updateAccountWorkspacePlanCancelStatus(payload: {
  willPlanBeCanceled: boolean;
  workspaceId: number;
}): AppThunk {
  return dispatch => {
    dispatch(updateAccountWorkspacePlanCancelStatusAction(payload));
  };
}

export function updateMailNotificationSettingsAction(payload: { isMarketingMail: boolean; isSystemMail: boolean }) {
  return { type: AccountTypes.UPDATE_MAIL_NOTIFICATION_SETTINGS, payload } as const;
}
