import { CreateOrReadResponseBody, UpdateOrDeleteResponseBody } from ".";
import {
  GET_PLAN_DETAIL,
  SETTINGS_UPDATE_PASSWORD_URL,
  SIGNUP_PATH,
  CREATE_TOKEN_URL,
  SETTINGS_UPDATE_AUTHENTICATION,
  LOGIN_URL,
  VERIFY_TOKEN,
  RESEND_TOKEN,
  SETTINGS_UPDATE_CAL_PROVIDER,
} from "../constant";
import { UserSignInPayload } from "../screens/Login";
import { getJson, postJson } from "../utils/network";

interface CreateAccountRequestData {
  name: string;
  email: string;
  password: string;
  isMailAcceptable: boolean;
  invitationToken?: string;
  company?: string;
  size?: string;
  role?: string;
  country?: string | null;
  knowUs?: string | null;
  otherDetails?: string | null;
  referralToken?: string | null;
  usecase?: string | null;
}

export type CreateAccountResponse = CreateOrReadResponseBody<{
  requireEmailConfirmation: boolean;
  isEnableEmailTokenFlagOnWorkspace: boolean;
}>;

export async function authenticateUser(data: UserSignInPayload) {
  const path = LOGIN_URL;

  const { response } = await postJson(path, data);

  return response;
}

export async function verifyEmailToken({ token }: { token: string }) {
  const path = VERIFY_TOKEN;

  const { response } = await postJson(path, { token });

  return response;
}

export async function resendEmailToken() {
  const path = RESEND_TOKEN;

  const { response } = await postJson(path);

  return response;
}

export async function createToken() {
  const path = CREATE_TOKEN_URL;

  const { response } = await postJson(path);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when creat the token: ${JSON.stringify(response.errors)}`);
  }
}

export async function postUpdatedPassword({
  currentPassword,
  newPassword,
  browser,
  country,
  platform,
}: {
  currentPassword?: string;
  newPassword: string;
  browser: string;
  platform: string;
  country: string;
}) {
  const path = SETTINGS_UPDATE_PASSWORD_URL;

  const { response } = await postJson<UpdateOrDeleteResponseBody>(path, {
    currentPassword,
    newPassword,
    browser,
    country,
    platform,
  });

  return response;
}

export async function createAccount(data: CreateAccountRequestData) {
  const path = SIGNUP_PATH;

  const { response } = await postJson<CreateAccountResponse>(path, data);

  return response;
}

export async function getPlanDetail() {
  const { response } = await getJson(GET_PLAN_DETAIL);

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when get plan detail: ${JSON.stringify(response.errors)}`);
  }
}

export async function postUpdatedAuthentication({ emailTokenFlag }: { emailTokenFlag: boolean }) {
  const path = SETTINGS_UPDATE_AUTHENTICATION;

  const { response } = await postJson(path, { emailTokenFlag });

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when update authentication: ${JSON.stringify(response.errors)}`);
  }
}

export async function postStatusProvider({
  statusProvider,
  workspaceId,
}: {
  statusProvider: string;
  workspaceId: number;
}) {
  const path = SETTINGS_UPDATE_CAL_PROVIDER;

  const { response } = await postJson(path, { statusProvider, workspaceId });

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`Error when update statusProvider: ${JSON.stringify(response.errors)}`);
  }
}
