import Socket from "../../utils/socket";
import { log, LogCategory } from "../../utils/log";
import { intl } from "../../i18n";
import { connectionChanged, SocketActions } from "./actions";
import { handleIncomingMessage as handleIncomingAppMessages, setWorkspaceUpdating } from "../app";
import { handleIncomingMessage as handleIncomingAppNotificationMessages } from "../appNotification";
import { handleIncomingMessage as handleIncomingWorkspaceMessages } from "../workspace";
import { handleIncomingMessage as handleIncomingChannelMessages } from "../voiceChannels";
import { handleIncomingMessage as handleIncomingTextChannelMessages } from "../textChannel";
import { handleIncomingMessage as handleIncomingUserMessages } from "../users";
import { checkIsListener, handleIncomingMessage as handleIncomingAccountMessages } from "../account";
import { handleIncomingMessage as handleIncomingMemberMessages } from "../member";
import { handleIncomingMessage as handleIncomingWorkspaceBackgroundMessages } from "../workspaceBackground";
import { handleIncomingTimeTrackerMessage } from "../timeTracker/middleware";
import { handleIncomingVirtualOfficeMessage } from "../virtualOffice/middleware";
import { getCurrentEnvironment } from "../../utils/environment";
import { Toast } from "../../components/antd/Toast";
import { getWebVersion } from "../version";
import { handleIncomingMeetingRecordingMessage } from "../MeetingRecording";
import { handleIncomingMessage as handleIncomingRemoteDesktopControlRequest } from "../remoteDesktopControl";
import { handleIncomingWorkChannelMessage } from "../workChannel";
import { handleIncomingWorkspaceMessage } from "../screenShots/middleware";
import { showModal } from "../../screens/Dashboard/state";
import { RECONNECTION_WAITING_MODAL } from "../../screens/Dashboard/constants";
import { ALLOWED_SOCKET_EVENT_FOR_LISTENER } from "../../constant";
import { handleIncomingMessage as handleIncomingAudioExtractionMessages } from "../audioExtraction";

export const socketMiddleware = (store: any) => {
  const onIncomingAppMessages = handleIncomingAppMessages(store);
  const onIncomingAppNotificationMessages = handleIncomingAppNotificationMessages(store);
  const onIncomingWorkspaceMessages = handleIncomingWorkspaceMessages(store);
  const onIncomingChannelMessages = handleIncomingChannelMessages(store);
  const onIncomingTextChannelMessages = handleIncomingTextChannelMessages(store);
  const onIncomingUserMessages = handleIncomingUserMessages(store);
  const onIncomingAccountMessages = handleIncomingAccountMessages(store);
  const onIncomingTimeTrackerMessage = handleIncomingTimeTrackerMessage(store);
  const onIncomingVirtualOfficeMessage = handleIncomingVirtualOfficeMessage(store);
  const onIncomingMemberMessages = handleIncomingMemberMessages(store);
  const onIncomingWorkspaceBackgroundMessages = handleIncomingWorkspaceBackgroundMessages(store);
  const onIncomingMeetingRecordingMessages = handleIncomingMeetingRecordingMessage(store);
  const onIncomingRemoteDesktopControlRequest = handleIncomingRemoteDesktopControlRequest(store);
  const onIncomingWorkChannelMessage = handleIncomingWorkChannelMessage(store);
  const onIncomingWorkSpaceMessage = handleIncomingWorkspaceMessage(store);
  const onIncomingAudioExtractionMessage = handleIncomingAudioExtractionMessages(store);

  const onConnectionChange = (isConnected: boolean, id: string | null) => {
    store.dispatch(connectionChanged(isConnected, id));
  };

  function onIncomingMessage(event: string) {
    const { type, payload } = JSON.parse(event);
    const { error } = payload;

    log(LogCategory.Socket, "Incoming message", { type, payload });

    if (error) {
      Toast.error(
        getCurrentEnvironment() !== "production" || error.type === "business"
          ? error.message
          : intl.formatMessage({
              id: "generic-error-message",
              defaultMessage: "Something went wrong, please try again later!",
            }),
      );

      store.dispatch(setWorkspaceUpdating(false));

      return;
    }

    onIncomingAppMessages(type, payload);
    onIncomingAppNotificationMessages(type, payload);
    onIncomingWorkspaceMessages(type, payload);
    onIncomingChannelMessages(type, payload);
    onIncomingTextChannelMessages(type, payload);
    onIncomingUserMessages(type, payload);
    onIncomingAccountMessages(type, payload);
    onIncomingTimeTrackerMessage(type, payload);
    onIncomingVirtualOfficeMessage(type, payload);
    onIncomingMemberMessages(type, payload);
    onIncomingWorkspaceBackgroundMessages(type, payload);
    onIncomingMeetingRecordingMessages(type, payload);
    onIncomingRemoteDesktopControlRequest(type, payload);
    onIncomingWorkChannelMessage(type, payload);
    onIncomingWorkSpaceMessage(type, payload);
    onIncomingAudioExtractionMessage(type, payload);
  }

  const socket = new Socket(onConnectionChange, onIncomingMessage);

  return (next: any) => async (action: any) => {
    const { type, payload } = action;
    const webVersion = getWebVersion(store.getState());

    if (type === SocketActions.CONNECT_SOCKET) {
      log(LogCategory.Socket, "Connect socket");
      const pathname = window.location.pathname;

      if (pathname.startsWith("/guest/waiting/")) {
        store.dispatch(showModal({ id: RECONNECTION_WAITING_MODAL, show: false }));
      }

      socket.connect(webVersion);
    }

    if (type === SocketActions.DISCONNECT_SOCKET) {
      log(LogCategory.Socket, "Disconnect socket");
      const pathname = window.location.pathname;

      socket.disconnect();
      if (pathname.startsWith("/guest/waiting/")) {
        store.dispatch(showModal({ id: RECONNECTION_WAITING_MODAL, show: true }));
      }
    }

    if (type === SocketActions.SEND_MESSAGE) {
      const isListener = checkIsListener(store.getState());

      if (isListener && !ALLOWED_SOCKET_EVENT_FOR_LISTENER.some(ev => ev === payload.event)) {
        return;
      }

      log(LogCategory.Socket, "Send message", payload);
      socket.sendMessage(payload.event, payload.data);
    }

    if (type === SocketActions.FORCE_LOGOUT) {
      socket.logout();
    }

    return next(action);
  };
};
