export enum WorkspaceBackgroundActions {
  WORKSPACE_BACKGROUND_CREATE = "workspaceBackgrounds/WORKSPACE_BACKGROUND_CREATE",
  WORKSPACE_BACKGROUND_CREATE_SUCCESS = "workspaceBackgrounds/WORKSPACE_BACKGROUND_CREATE_SUCCESS",
  WORKSPACE_BACKGROUND_DELETE = "workspaceBackgrounds/WORKSPACE_BACKGROUND_DELETE",
  WORKSPACE_BACKGROUND_DELETE_SUCCESS = "workspaceBackgrounds/WORKSPACE_BACKGROUND_DELETE_SUCCESS",
  WORKSPACE_BACKGROUND_GET = "workspaceBackgrounds/WORKSPACE_BACKGROUND_GET",
  WORKSPACE_BACKGROUND_GET_SUCCESS = "workspaceBackgrounds/WORKSPACE_BACKGROUND_GET_SUCCESS",
}

export const WORKSPACE_BACKGROUND_EVENT_TYPE_PREFIX = "workspaceBackground/";

export const WORKSPACE_BACKGROUND_EVENT_TYPES = {
  WORKSPACE_BACKGROUND_CREATE: `${WORKSPACE_BACKGROUND_EVENT_TYPE_PREFIX}create`,
  WORKSPACE_BACKGROUND_DELETE: `${WORKSPACE_BACKGROUND_EVENT_TYPE_PREFIX}delete`,
  WORKSPACE_BACKGROUND_GET: `${WORKSPACE_BACKGROUND_EVENT_TYPE_PREFIX}get-all`,
};
