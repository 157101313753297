/*****************************************************************************
 * FEATURE FLAGS
 *
 * We use this system to define features that we can easily turn on and off,
 * for testing or to put something temporarily on hold.
 *
 * To define one, add it to the `defaults` object. The value **must** be a
 * boolean.
 *
 * Use it in TypeScript code:
 * ```typescript
 * import features from "../features";
 *
 * function foo() {
 *   if (features.enabled("bar")) {
 *     doSomething();
 *   }
 * }
 * ```
 *
 * Use it in TSX:
 * ```typescript
 * <div>
 *   {features.enabled("bar") && <Something />}
 * </div>
 * ```
 *
 * Use it in JavaScript code:
 * ```javascript
 * import features from "../features";
 *
 * function foo() {
 *   if (features.bar)) {
 *     doSomething();
 *   }
 * }
 * ```
 *
 * To set or unset them, open devtools, and:
 * ```javascript
 * features.foo = true;
 * features.bar = false;
 * features.unset('piyo'); // resets to default
 * ```
 * Then you have to reload the page or restart the app. Intentionally the
 * values are not changed on the fly, to avoid bugs with IPC and the desktop
 * app, React update headaches, etc.
 */

import { getCurrentEnvironment } from "./utils/environment";

const env = getCurrentEnvironment();

const defaults = {
  "settings.notifications": false,
  "settings.leaveWorkspace": false,
  "settings.deleteWorkspace": false,
  "settings.multipleRegions": false,
  tracker: true,
  "tracker.debugLocalData": false,
  "tracker.highlightActiveUsers": true,
  devTools: env !== "production",
  noiseReduction: true,
  "debugger.reduxStore": env !== "production",
  versionOnActivityCard: env !== "production",
  reduxDevtools: env === "local",
  perfRadar: env === "local",
  meetingTimer: env !== "production",
  chatwork: true,
  meetingRecording: true,
  screenShareViewers: true,
  autoMeetingRecording: true,
  remoteDesktopControl: true,
  screenOverlayDrawing: true,
  idlingForOfficeObjects: true,
};

type FeatureKey = keyof typeof defaults;

class FeatureFlagService {
  _current: Map<string, boolean>;

  constructor(defaults: any) {
    Object.defineProperty(this, "_current", {
      enumerable: false,
      value: new Map(),
    });
    for (const key of Object.keys(defaults)) {
      this._current.set(key, defaults[key]);
      Object.defineProperty(this, key, {
        enumerable: true,
        get: () => this._current.get(key),
        set: (value: boolean) => this.set(key as FeatureKey, value),
      });
    }
    this.load();
  }

  /** Load values from storage */
  load() {
    for (const key of this._current.keys()) {
      const stored = localStorage.getItem(`featureFlags.${key}`);

      if (stored !== null) {
        this._current.set(key, stored === "true");
      }
    }
  }

  /** Set stored value, does *not* take effect immediately */
  set(key: FeatureKey, value: boolean = true) {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    if (value !== true && value !== false) throw new TypeError("Value must be boolean");

    localStorage.setItem(`featureFlags.${key}`, JSON.stringify(value));
  }

  /** Remove from storage, revert to the default; does *not* take effect immediately */
  unset(key: FeatureKey) {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    localStorage.removeItem(`featureFlags.${key}`);
  }

  /** Check if flag is set */
  // (just for happier TS)
  enabled(key: FeatureKey): boolean {
    if (!this._current.has(key)) throw new RangeError("Invalid key");

    return this._current.get(`${key}`)! as boolean;
  }

  /** Dump to json, for debugging */
  toJSON() {
    const o: any = {};

    for (const key of this._current.keys()) o[key] = this._current.get(key);
    return o;
  }

  /** Dump to string, for debugging */
  toString() {
    return JSON.stringify(this);
  }
}

export const features = new FeatureFlagService(defaults);
export default features;
// expose to devtools
Object.assign(window, { features });
