import connectedAudio from "../assets/audios/connected.mp3";
import disconnectedAudio from "../assets/audios/disconnected.mp3";
import incomingCallAudio from "../assets/audios/incomingcall.wav";
import acceptIncomingCallAudio from "../assets/audios/acceptincomingcall.wav";
import declineIncomingCallAudio from "../assets/audios/declineincomingcall.wav";
import startRecording from "../assets/audios/startrecording.mp3";
import stopRecording from "../assets/audios/stoprecording.mp3";
import failRecording from "../assets/audios/failrecording.mp3";

export enum PlaySoundEffectTypes {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  INCOMING_CALL = "incoming-call",
  ACCEPT_INCOMING_CALL = "accept-incoming-call",
  DECLINE_INCOMING_CALL = "decline-incoming-call",
  INVITE_USER = "invite-user",
  KNOCK = "knock",
  NEW_USER_JOIN = "new-user-join",
  USER_LEAVE = "user-leave",
  JOIN_LATER = "join-later",
  SCREEN_SHARE = "request-screen-share",
  START_RECORDING = "start-recording",
  STOP_RECORDING = "stop-recording",
  FAIL_RECORDING = "fail-recording",
}

export function getAudioSource(type: PlaySoundEffectTypes) {
  let currentAudio = null;

  switch (type) {
    case PlaySoundEffectTypes.CONNECTED:
    case PlaySoundEffectTypes.NEW_USER_JOIN:
      currentAudio = connectedAudio;
      break;
    case PlaySoundEffectTypes.DISCONNECTED:
      currentAudio = disconnectedAudio;
      break;
    case PlaySoundEffectTypes.INCOMING_CALL:
      currentAudio = incomingCallAudio;
      break;
    case PlaySoundEffectTypes.ACCEPT_INCOMING_CALL:
    case PlaySoundEffectTypes.JOIN_LATER:
    case PlaySoundEffectTypes.INVITE_USER:
    case PlaySoundEffectTypes.SCREEN_SHARE:
    case PlaySoundEffectTypes.KNOCK:
      currentAudio = acceptIncomingCallAudio;
      break;
    case PlaySoundEffectTypes.DECLINE_INCOMING_CALL:
    case PlaySoundEffectTypes.USER_LEAVE:
      currentAudio = declineIncomingCallAudio;
      break;
    case PlaySoundEffectTypes.START_RECORDING:
      currentAudio = startRecording;
      break;
    case PlaySoundEffectTypes.STOP_RECORDING:
      currentAudio = stopRecording;
      break;
    case PlaySoundEffectTypes.FAIL_RECORDING:
      currentAudio = failRecording;
      break;
    default:
      break;
  }

  return currentAudio;
}
