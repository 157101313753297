import { PcSidebarLocalStorageClient } from "../../localStorageKeys";
import { isMobile } from "../helpers";

export function useIsSidebarCollapased() {
  const isPc = !(isMobile.iOS() || isMobile.Android());

  if (isPc) {
    const sidebarClient = new PcSidebarLocalStorageClient();
    const isCollapsed = sidebarClient.loadData();

    return typeof isCollapsed === "boolean" ? isCollapsed : false;
  } else {
    return true;
  }
}
