import {
  googleIntegrationStatusUpdate,
  slackIntegrationStatusUpdate,
  slackMissingScopes,
  updatedPassword,
  updatedProfile,
  chatworkIntegrationStatusUpdate,
  updatedChatworkGroupId,
  updateAccountProjects,
  updateAccountSelectedProject,
  updatedSharing,
  setInvitedEnv,
} from "../account";
import {
  azureIntegrationStatusUpdate,
  fetchAccountProjects,
  setAccountTimeTrackerLimit,
  updateAccountTimeTrackerLimit,
  setIsAppIdleAction,
} from "./actions";

const ACCOUNT_EVENT_TYPE_PREFIX = "account/";

export const ACCOUNT_EVENT_TYPES = {
  PROFILE_UPDATE: `${ACCOUNT_EVENT_TYPE_PREFIX}profile-update`,
  PROFILE_PASSWORD_UPDATE: `${ACCOUNT_EVENT_TYPE_PREFIX}profile-password-update`,
  ACCOUNT_SLACK_MISSING_SCOPES: `${ACCOUNT_EVENT_TYPE_PREFIX}slack-missing-scopes`,
  SLACK_INTEGRATION_UPDATE: `${ACCOUNT_EVENT_TYPE_PREFIX}slack-integration-update`,
  GOOGLE_INTEGRATION_UPDATE: `${ACCOUNT_EVENT_TYPE_PREFIX}google-integration-update`,
  ACCOUNT_CHATWORK_INTEGRATION_UPDATE_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}chatwork-integration-update-response`,
  ACCOUNT_CHATWORK_GROUP_ID_UPDATE_REQUEST: `${ACCOUNT_EVENT_TYPE_PREFIX}update-chatwork-group-id-request`,
  ACCOUNT_CHATWORK_GROUP_ID_UPDATE_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}update-chatwork-group-id-response`,
  ACCOUNT_AZURE_INTEGRATION_UPDATE_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}azure-integration-update-response`,
  ACCOUNT_PROJECTS_FETCH_REQUEST: `${ACCOUNT_EVENT_TYPE_PREFIX}projects-fetch-request`,
  ACCOUNT_PROJECTS_FETCH_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}projects-fetch-response`,
  ACCOUNT_SELECTED_PROJECT_UPDATE_REQUEST: `${ACCOUNT_EVENT_TYPE_PREFIX}selected-project-update-request`,
  ACCOUNT_SELECTED_PROJECT_UPDATE_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}selected-project-update-response`,
  ACCOUNT_PROJECTS_UPDATE_BROADCAST: `${ACCOUNT_EVENT_TYPE_PREFIX}projects-update-broadcast`,
  ACCOUNT_UPDATE_IS_APP_IDLE_REQUEST: `${ACCOUNT_EVENT_TYPE_PREFIX}update-is-app-idle-request`,
  ACCOUNT_TIME_TRACKER_LIMIT_REQUEST: `${ACCOUNT_EVENT_TYPE_PREFIX}time-tracker-limit-request`,
  ACCOUNT_TIME_TRACKER_LIMIT_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}time-tracker-limit-response`,
  ACCOUNT_UPDATE_TIME_TRACKER_LIMIT_BROADCAST: `${ACCOUNT_EVENT_TYPE_PREFIX}update-time-tracker-limit`,
  ACCOUNT_UPDATE_IS_APP_IDLE_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}update-is-app-idle-response`,
  ACCOUNT_UPDATE_TEXT_TRANSLATION: `${ACCOUNT_EVENT_TYPE_PREFIX}update-text-translation`,
  ACCOUNT_UPDATE_SHARING_REQUEST: `${ACCOUNT_EVENT_TYPE_PREFIX}update-sharing-request`,
  ACCOUNT_UPDATE_SHARING_RESPONSE: `${ACCOUNT_EVENT_TYPE_PREFIX}update-sharing-response`,
  ACCOUNT_UPDATE_INVITED_ENV: `${ACCOUNT_EVENT_TYPE_PREFIX}update-invited-env`,
};

export const handleIncomingMessage = (store: any) => (type: string, payload: any) => {
  if (!type.startsWith(ACCOUNT_EVENT_TYPE_PREFIX)) {
    return;
  }

  switch (type) {
    case ACCOUNT_EVENT_TYPES.PROFILE_UPDATE:
      store.dispatch(updatedProfile(payload));
      break;
    case ACCOUNT_EVENT_TYPES.PROFILE_PASSWORD_UPDATE:
      store.dispatch(updatedPassword());
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_SLACK_MISSING_SCOPES:
      store.dispatch(slackMissingScopes(payload));
      break;
    case ACCOUNT_EVENT_TYPES.SLACK_INTEGRATION_UPDATE:
      store.dispatch(slackIntegrationStatusUpdate(payload));
      break;
    case ACCOUNT_EVENT_TYPES.GOOGLE_INTEGRATION_UPDATE:
      store.dispatch(googleIntegrationStatusUpdate(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_CHATWORK_INTEGRATION_UPDATE_RESPONSE:
      store.dispatch(chatworkIntegrationStatusUpdate(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_CHATWORK_GROUP_ID_UPDATE_RESPONSE:
      store.dispatch(updatedChatworkGroupId(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_AZURE_INTEGRATION_UPDATE_RESPONSE:
      store.dispatch(azureIntegrationStatusUpdate(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_PROJECTS_FETCH_RESPONSE:
      store.dispatch(updateAccountProjects(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_SELECTED_PROJECT_UPDATE_RESPONSE:
      store.dispatch(updateAccountSelectedProject(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_PROJECTS_UPDATE_BROADCAST:
      store.dispatch(fetchAccountProjects());
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_TIME_TRACKER_LIMIT_RESPONSE:
      store.dispatch(setAccountTimeTrackerLimit(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_TIME_TRACKER_LIMIT_BROADCAST:
      store.dispatch(updateAccountTimeTrackerLimit(payload.timeTrackerLimitData));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_IS_APP_IDLE_RESPONSE:
      console.log("===== DEBUG IDLE STATUS: isAppIdle is updateing to", payload.isAppIdle);
      store.dispatch(setIsAppIdleAction(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_SHARING_RESPONSE:
      store.dispatch(updatedSharing(payload));
      break;
    case ACCOUNT_EVENT_TYPES.ACCOUNT_UPDATE_INVITED_ENV:
      store.dispatch(setInvitedEnv(payload));
      break;
  }
};
