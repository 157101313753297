import ReactGA from "react-ga";

declare global {
  interface Window {
    Analytics: Record<AnalyticsCategoryName, boolean>;
  }
}

export enum AnalyticsCategory {
  Channel,
  User,
  SpeechRecognition,
  Protoo,
  Workspace,
}

type AnalyticsCategoryName = keyof typeof AnalyticsCategory;

export function Analytics(category: AnalyticsCategory, message: string, label?: string) {
  const categoryKey = AnalyticsCategory[category];

  ReactGA.event({
    category: categoryKey,
    action: message,
    label: label ?? categoryKey,
  });
}
